import { Box } from '@mui/material';

import { useAppSelector } from 'store/hook';
import { isExternalUserOrAdmin } from 'utils/validators/role.validation';
import { CustomerDisplay } from './components/CustomerDisplay';
import { BreadCrumb } from './components/BreadCrumb';
import { isDarkTheme } from 'utils/theme';
import { ThemePalette } from 'mui.theme';

export const TopBar = () => {
  const { role, customer: customerExternal } = useAppSelector(
    (state) => state.authReducer
  );

  const { customer: customerInternal } = useAppSelector(
    (state) => state.persistedReducer
  );

  if (role === null) {
    return <></>;
  }

  // External users use the value in authReducer because their value comes from the DB
  // Internal users use the value in persistentReducer because their value is dynamic
  const customer = isExternalUserOrAdmin(role)
    ? customerExternal
    : customerInternal;

  const { theme } = customerExternal;
  const enableDarkTheme = isDarkTheme(theme);

  return (
    <Box
      sx={{
        backgroundColor: enableDarkTheme
          ? ThemePalette.dark.topBar
          : ThemePalette.light.topBar,
        height: '64px',
        width: '100%',
        boxShadow: '0px 0px 3px #00000029',
        borderRadius: '0px 0px 5px 5px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        color: enableDarkTheme
          ? ThemePalette.typography.white
          : ThemePalette.typography.black,
      }}
    >
      <BreadCrumb></BreadCrumb>

      <CustomerDisplay {...customer} />
    </Box>
  );
};
