import {
  Box,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';
import { SWTextField } from 'components/Form/Input/SWTextField';
import { Selector } from 'components/Form/Selectors/Selector';
import { InteractiveModal } from 'components/Modals/InteractiveModal/BaseModal';
import CustomerHandler from 'handlers/customer.handler';
import { useEffect, useState } from 'react';
import { modalInputFieldStyle } from 'styles/global.css';
import { type ICreateCustomerPayload } from 'types/payloads/customer.payload.types';
import { CUSTOMER_TYPE_OPTIONS } from 'utils/enums';
import { createOrUpdateCustomerUserRequestBodySchema } from 'utils/schemas/customer.schema';
import { validateYupSchema } from 'utils/validators/schema.validator';
import { ThemePalette } from 'mui.theme';

interface Props {
  title: string;
  open: boolean;
  handleAbort: () => void;
  enableDarkTheme: boolean;
}

export const CreateCustomerModal = ({
  title,
  open,
  handleAbort,
  enableDarkTheme = false,
}: Props) => {
  const [isValidForm, setFormValidity] = useState(false);
  const [customer, setCustomer] = useState<ICreateCustomerPayload>({
    customerName: '',
    customerCode: '',
    customerType: 'external-customer',
    email: '',
    contactFirstName: '',
    contactLastName: '',
    phoneNumber: '',
    internalAccountingLink: '',
    unitSystem: 'metric',
  });

  const customerHandler = new CustomerHandler();

  // Validate form as user fills out fields
  useEffect(() => {
    const validateForm = async () =>
      await validateYupSchema(
        customer,
        createOrUpdateCustomerUserRequestBodySchema
      );

    void validateForm().then((isValid) => {
      setFormValidity(isValid);
    });
  }, [customer]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setCustomer({
      ...customer,
      [name]: name === 'customerCode' ? value.toLowerCase() : value,
    });
  };

  const handleSuccess = async () => {
    await customerHandler.createCustomer(customer);
    // this open/close setter will not be abstracted out because it is specific to this view
    handleAbort();
  };

  return (
    <InteractiveModal
      initialState={open}
      SuccessButtonProps={{
        disabled: !isValidForm,
        onClick: handleSuccess,
        label: 'Create customer account',
      }}
      handleAbort={handleAbort}
      title={title}
      confirmCancellation
      enableDarkTheme={enableDarkTheme}
    >
      {/* First Name TextField */}
      <SWTextField
        key="customer-name"
        name="customerName"
        label="Company Name*"
        TextFieldProps={{
          name: 'customerName',
          value: customer.customerName,
          onChange: handleChange,
          InputProps: {
            sx: modalInputFieldStyle,
          },
        }}
        enableDarkTheme={enableDarkTheme}
      />
      {/* Customer Code TextField */}
      <SWTextField
        key="customer-code"
        placeholder="Minimum of 3 characters"
        name="customerCode"
        label="Company Code*"
        TextFieldProps={{
          name: 'customerCode',
          value: customer.customerCode,
          onChange: handleChange,
          InputProps: {
            sx: modalInputFieldStyle,
          },
        }}
        enableDarkTheme={enableDarkTheme}
      />

      {/* Department selector drop down single */}
      <Box key="customer-type">
        <Typography
          variant="bodyBold"
          display="block"
          mb={'4px'}
          sx={{
            color: enableDarkTheme
              ? ThemePalette.typography.white
              : ThemePalette.typography.black,
          }}
        >
          Customer Type*
        </Typography>
        <Selector
          value={customer.customerType}
          onChange={(event, child) => {
            setCustomer({
              ...customer,
              customerType:
                event.target.value.toString() as ICreateCustomerPayload['customerType'],
            });
          }}
          selectorOptions={CUSTOMER_TYPE_OPTIONS}
          enableDarkTheme={enableDarkTheme}
        />
      </Box>
      <Box key="unit-system">
        <Typography
          variant="bodyBold"
          display="block"
          mb={'4px'}
          sx={{
            color: enableDarkTheme
              ? ThemePalette.typography.white
              : ThemePalette.typography.black,
          }}
        >
          Unit System*
        </Typography>
        <RadioGroup
          row
          aria-labelledby="unitSystem"
          name="unitSystem"
          onChange={handleChange}
          defaultValue="metric"
        >
          <FormControlLabel
            value="metric"
            control={
              <Radio
                sx={{
                  color: enableDarkTheme
                    ? ThemePalette.typography.cornflowerBlue
                    : ThemePalette.typography.primary,
                  '&.Mui-checked': {
                    color: enableDarkTheme
                      ? ThemePalette.typography.cornflowerBlue
                      : ThemePalette.typography.primary,
                  },
                }}
              />
            }
            label={
              <Typography
                variant="body2"
                style={{ fontSize: '12px' }}
                sx={{
                  color: enableDarkTheme
                    ? ThemePalette.typography.white
                    : ThemePalette.typography.black,
                }}
              >
                Metric (Kms/Kgs/Litres)
              </Typography>
            }
          />
          <FormControlLabel
            value="imperial"
            control={
              <Radio
                sx={{
                  color: enableDarkTheme
                    ? ThemePalette.typography.cornflowerBlue
                    : ThemePalette.typography.primary,
                  '&.Mui-checked': {
                    color: enableDarkTheme
                      ? ThemePalette.typography.cornflowerBlue
                      : ThemePalette.typography.primary,
                  },
                }}
              />
            }
            label={
              <Typography
                variant="body2"
                style={{ fontSize: '12px' }}
                sx={{
                  color: enableDarkTheme
                    ? ThemePalette.typography.white
                    : ThemePalette.typography.black,
                }}
              >
                Imperial (Miles/Pounds/Gallons)
              </Typography>
            }
          />
        </RadioGroup>
      </Box>

      <Grid2 xs={12} lg={12} padding={'15px'}>
        <Box
          sx={{
            height: '35px',
            backgroundColor: '#7485A2',
            display: 'flex',
            alignItems: 'center',
            paddingLeft: '14px',
            paddingTop: '8px',
            paddingBottom: '8px',
          }}
        >
          <Typography
            color="white"
            variant="bodyBold"
            sx={{
              color: enableDarkTheme
                ? ThemePalette.typography.white
                : ThemePalette.typography.black,
            }}
          >
            Company Contact Information
          </Typography>
        </Box>
      </Grid2>

      {/* First Name TextField */}
      <SWTextField
        key="contact-first-name"
        name="contactFirstName"
        label="First Name*"
        TextFieldProps={{
          name: 'contactFirstName',
          value: customer.contactFirstName,
          onChange: handleChange,
          InputProps: {
            sx: modalInputFieldStyle,
          },
        }}
        enableDarkTheme={enableDarkTheme}
      />
      {/* Last Name TextField */}
      <SWTextField
        key="contact-last-name"
        name="contactLastName"
        label="Last Name*"
        TextFieldProps={{
          name: 'contactLastName',
          value: customer.contactLastName,
          onChange: handleChange,
          InputProps: {
            sx: modalInputFieldStyle,
          },
        }}
        enableDarkTheme={enableDarkTheme}
      />

      {/* Email  TextField */}
      <Box key="email">
        <Typography
          variant="bodyBold"
          display="block"
          mb={'4px'}
          sx={{
            color: enableDarkTheme
              ? ThemePalette.typography.white
              : ThemePalette.typography.black,
          }}
        >
          Email Address*
        </Typography>

        <TextField
          name="email"
          onChange={handleChange}
          value={customer.email}
          InputProps={{
            sx: modalInputFieldStyle,
          }}
        />
      </Box>

      {/* Phone Number TextField */}
      <Box key="phone-number">
        <Typography
          variant="bodyBold"
          display="block"
          mb={'4px'}
          sx={{
            color: enableDarkTheme
              ? ThemePalette.typography.white
              : ThemePalette.typography.black,
          }}
        >
          Phone Number*
        </Typography>

        <TextField
          name="phoneNumber"
          onChange={handleChange}
          value={customer.phoneNumber}
          InputProps={{
            sx: modalInputFieldStyle,
          }}
        />
      </Box>

      {/* Phone Number TextField */}
      <Box key="internal-accounting-number">
        <Typography
          variant="bodyBold"
          display="block"
          mb={'4px'}
          sx={{
            color: enableDarkTheme
              ? ThemePalette.typography.white
              : ThemePalette.typography.black,
          }}
        >
          Internal Accounting Link
        </Typography>

        <TextField
          name="internalAccountingLink"
          onChange={handleChange}
          value={customer.internalAccountingLink}
          InputProps={{
            sx: modalInputFieldStyle,
          }}
        />
      </Box>
    </InteractiveModal>
  );
};
