import { Box, TextField, Typography } from '@mui/material';
import { FlexBox } from 'components/Containers/FlexBox';
import { InteractiveModal } from 'components/Modals/InteractiveModal/BaseModal';
import { MultiSelector } from 'components/Form/Selectors/MultiSelector';
import { Selector } from 'components/Form/Selectors/Selector';
import UserHandler from 'handlers/internal.user.handler';
import { useEffect, useState } from 'react';
import { modalInputFieldStyle } from 'styles/global.css';
import { type InternalUser } from 'types/user.types';
import { INTERNAL_USER_DOMAIN, USER_DEPARTMENT_OPTIONS } from 'utils/enums';
import { createOrUpdateInternalUserRequestBodySchema } from 'utils/schemas/user.schema';
import { validateYupSchema } from 'utils/validators/schema.validator';
import {
  handleSelectCustomerAll,
  handleSelectCustomerSingle,
} from '../../helpers/common';
import { SWTextField } from 'components/Form/Input/SWTextField';
import { safeReplace } from 'utils/helpers/string.manipulation';
import { type IUpdateInternalUserPayload } from 'types/payloads/user.payload.types';
import { useAppSelector } from 'store/hook';
import { type Customer } from 'types/customer.types';
import { formatDate } from 'utils/helpers/dateTime';

interface Props {
  title: string;
  open: boolean;
  userData: InternalUser;
  handleAbort: () => void;
}

export const UpdateInternalUserModal = ({
  title,
  handleAbort,
  open,
  userData,
}: Props) => {
  const userHandler = new UserHandler();

  const customers = useAppSelector((state) => state.customerReducer.customers);
  const [isValidForm, setFormValidity] = useState(false);
  // We don't update the isActive field or the userId field
  const [internalUser, setInternalUser] = useState<IUpdateInternalUserPayload>({
    firstName: userData.firstName,
    lastName: userData.lastName,
    // example: johndoe@symboticware.com -> johndoe
    email: safeReplace(userData.email, INTERNAL_USER_DOMAIN),
    department: userData.department,
    userCustomerAssociations: userData.userCustomerAssociations,
  });

  const customerState: InternalUser['userCustomerAssociations'] = customers.map(
    (customer: Customer) => {
      return {
        id: customer.customerId,
        display: customer.customerName,
      };
    }
  );

  // Validate form as user fills out fields
  useEffect(() => {
    const validateForm = async () =>
      await validateYupSchema(
        internalUser,
        createOrUpdateInternalUserRequestBodySchema
      );

    void validateForm().then((isValid) => {
      setFormValidity(isValid);
    });
  }, [internalUser]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    setInternalUser({
      ...internalUser,
      [name]: value,
    });
  };

  const handleSuccess = async () => {
    await userHandler.updateInternalUser(userData.userId, internalUser);
    // this open/close setter will not be abstracted out because it is specific to this view
    handleAbort();
  };

  /**
   * This is the form that will be rendered in the modal
   *
   * First name
   * Last name
   * Email
   * Department (dropdown)
   * Associated Customer Accounts (dropdown)
   * userId (non-editable)
   * login acount (non-editable)
   * last login (non-editable)
   */
  return (
    <InteractiveModal
      initialState={open}
      SuccessButtonProps={{
        disabled: !isValidForm,
        onClick: handleSuccess,
        label: 'SAVE EDITS AND RETURN',
      }}
      handleAbort={handleAbort}
      title={title}
      confirmCancellation
    >
      {/* First Name TextField */}
      <SWTextField
        key="first-name"
        name="firstName"
        label="First Name*"
        TextFieldProps={{
          name: 'firstName',
          value: internalUser.firstName,
          onChange: handleChange,
          InputProps: {
            sx: modalInputFieldStyle,
          },
        }}
      />

      {/* Last Name TextField */}
      <SWTextField
        key="last-name"
        name="lastName"
        label="Last Name*"
        TextFieldProps={{
          name: 'lastName',
          value: internalUser.lastName,
          onChange: handleChange,
          InputProps: {
            sx: modalInputFieldStyle,
          },
        }}
      />

      {/* Email  TextField */}
      <Box key="email">
        <Typography variant="bodyBold" display="block" mb={'4px'}>
          Email Address*
        </Typography>
        <FlexBox>
          <TextField
            name="email"
            onChange={handleChange}
            value={internalUser.email} // will have to omit domain
            InputProps={{
              sx: {
                height: modalInputFieldStyle.height,
                minWidth: '131px',
              },
            }}
          />
          <Typography
            variant="body2"
            fontSize="12px"
            display="flex"
            alignItems="center"
          >
            {INTERNAL_USER_DOMAIN}
          </Typography>
        </FlexBox>
      </Box>

      {/* Department selector drop down single */}
      <Box key="department">
        <Typography variant="bodyBold" display="block" mb={'4px'}>
          Department*
        </Typography>
        <Selector
          value={internalUser.department}
          onChange={(event, child) => {
            setInternalUser({
              ...internalUser,
              department: event.target.value.toString(),
            });
          }}
          selectorOptions={USER_DEPARTMENT_OPTIONS}
        />
      </Box>

      {/* Customer account multi selector */}
      <Box key="customer-accounts">
        <Typography variant="bodyBold" display="block" mb={'4px'}>
          Associated Customer Accounts
        </Typography>
        <MultiSelector
          value={internalUser.userCustomerAssociations}
          renderValue={(selected) => {
            return `${selected?.length || 0} Customer(s) Selected`;
          }}
          handleSelectOne={(event) => {
            handleSelectCustomerSingle(event, internalUser, setInternalUser);
          }}
          handleSelectAll={() => {
            handleSelectCustomerAll(
              customerState,
              internalUser,
              setInternalUser
            );
          }}
          /*
        This stubbed response be replaced with real customer accounts
        */
          selectorOptions={customerState}
        />
      </Box>

      <FlexBox></FlexBox>

      {/* Non updateable customer data starts here */}
      <Box key="user-id" mt="40px">
        <Typography variant="bodyBold" display="block" mb="4px">
          User ID #
        </Typography>
        <Typography variant="small" display="block">
          {userData.userId}
        </Typography>
      </Box>
      <Box key="login-count" mt="40px">
        <Typography variant="bodyBold" display="block" mb="4px">
          Number of Logins
        </Typography>
        <Typography variant="small" display="block">
          {userData.loginCount}
        </Typography>
      </Box>
      <Box key="last-login" mt="40px">
        <Typography variant="bodyBold" display="block" mb="4px">
          Last Login
        </Typography>
        <Typography variant="small" display="block">
          {userData.lastLogin ? formatDate(userData.lastLogin) : null}
        </Typography>
      </Box>
    </InteractiveModal>
  );
};
