import { createSlice } from '@reduxjs/toolkit';
import type { Customer } from 'types/customer.types';

export interface CustomerState {
  customers: Customer[];
}

const initialState: CustomerState = {
  customers: [],
};

export const customerSlice = createSlice({
  name: 'customers',
  initialState,
  reducers: {
    getAll(state, { payload }) {
      return {
        customers: payload,
      };
    },

    appendCustomer(state, { payload }) {
      state.customers.push(payload);
    },

    resetCustomerState() {
      return {
        customers: [],
      };
    },
  },
});

export const { getAll, appendCustomer, resetCustomerState } =
  customerSlice.actions;
export default customerSlice.reducer;
