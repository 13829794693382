import { updateLoadingState } from 'store/app.slice';
import { delay } from 'utils/helpers/general';
import { BaseHandler } from './base.handler';
import { type ICreateOperatorPayload } from 'types/payloads/operator.payload.types';
import OperatorAPI from 'api/operator.api';
import {
  appendOperator,
  setOperators,
  updateOperatorList,
} from 'store/operator.slice';
import HandlerUtils from './util/handlerUtils.handler';

export default class OperatorHandler extends BaseHandler {
  private readonly api: OperatorAPI;
  private readonly handlerUtils: HandlerUtils;

  constructor() {
    super();

    this.api = new OperatorAPI();
    this.handlerUtils = new HandlerUtils();
  }

  // create operator using api
  async createOperator(payload: ICreateOperatorPayload): Promise<void> {
    this.dispatch(updateLoadingState(true));

    try {
      const createOperator = await this.api.create(payload);
      // refetch all assets to get the updated device details
      await this.handlerUtils.updateAssets();
      this.dispatch(appendOperator(createOperator));

      this.handleSuccess('A new Operator has been added.');
    } catch (_) {
      // show snackbar error
      this.handleError('An error occurred while adding an operator.');
    }
  }

  // get all operator using api
  async getAll() {
    this.dispatch(updateLoadingState(true));
    try {
      const operators = await this.api.getAll();

      this.dispatch(setOperators(operators));
      this.handleSuccess();
    } catch (error: any) {
      let msg: string = '';

      // Extract the error message from AxiosErrors
      // TODO: Look into other errors that can be thrown
      if (error.isAxiosError) {
        msg = error.response.data.message as string;
      }

      this.handleError(
        'An error occured while retrieving the operator list: ' + msg
      );
    }
  }

  async updateOperator(
    assetId: string,
    payload: ICreateOperatorPayload
  ): Promise<void> {
    this.dispatch(updateLoadingState(true));

    try {
      const updatedOperator = await this.api.update(assetId, payload);
      // refetch all assets to get the updated device details
      await this.handlerUtils.updateAssets();
      this.dispatch(updateOperatorList(updatedOperator));

      this.handleSuccess('Operator has been updated.');
    } catch (_) {
      // show snackbar error
      this.handleError('An error occurred while updating the Operator.');
    }
  }

  async activationOperator(
    operatorId: string,
    isActive: boolean
  ): Promise<void> {
    try {
      const updatedOperator = await this.api.activationOperator(
        operatorId,
        isActive
      );

      // refetch all assets to get the updated device details
      await this.handlerUtils.updateAssets();

      this.dispatch(updateOperatorList(updatedOperator));
      this.dispatch(updateLoadingState(true));

      if (JSON.stringify(updatedOperator) !== '{}') {
        const message = isActive ? 'deactivated' : 'activated';
        this.handleSuccess(`${operatorId} has been ${message}.`);
      } else {
        // show snackbar error
        this.handleError(
          'An error occurred while updating the operator status.'
        );
      }
    } catch (_) {
      // show snackbar error
      this.handleError('An error occurred while updating the operator status.');
    }
  }
}
