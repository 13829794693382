import { Typography } from '@mui/material';
import { InteractiveModal } from 'components/Modals/InteractiveModal/BaseModal';
import ExternalUserHandler from 'handlers/external.user.handler';

export {};

/**
 *
 * The activation modal is a modal that is used to activate an External user.
 */

interface Props {
  open: boolean;
  userId: string;
  firstName: string;
  lastName: string;
  handleAbort: () => void;
}

export const ExternalUserActivationModal = ({
  open,
  userId,
  firstName,
  lastName,
  handleAbort,
}: Props) => {
  const userHandler = new ExternalUserHandler();
  const title = 'Confirm User Reactivation';

  return (
    <InteractiveModal
      initialState={open}
      title={title}
      handleAbort={handleAbort}
      SuccessButtonProps={{
        onClick: async () => {
          await userHandler.activateExternalUser(userId, firstName, lastName);
          handleAbort();
        },
        disabled: false,
        label: 'Reactivate User',
      }}
      ModalContentProps={{
        gridItemProps: {
          lg: 12,
        },
      }}
    >
      <Typography key="blurb-1" variant="medium">
        Are you sure you want to reactivate {firstName} {lastName}?
      </Typography>
      <Typography key="blurb-2" variant="medium"></Typography>
    </InteractiveModal>
  );
};
