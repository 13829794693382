import { createSlice } from '@reduxjs/toolkit';
import type {
  NonHaulTruckFuelEfficiencyItem,
  NonHaulTruckFuelEventItem,
} from 'types/fuelAnalytics';

export interface NonHaulTruckFuelAnalyticsState {
  fuelEfficiency: {
    data: NonHaulTruckFuelEfficiencyItem[];
    sortedBottom5: NonHaulTruckFuelEfficiencyItem[];
    sortedTop5: NonHaulTruckFuelEfficiencyItem[];
    fuelConsumptionAverage: number;
    engineHoursAverage: number;
  };
  fuelNoneIdleEvents: {
    data: NonHaulTruckFuelEventItem[];
    sortedBottom5: NonHaulTruckFuelEventItem[];
    sortedTop5: NonHaulTruckFuelEventItem[];
    fuelConsumptionAverage: number;
  };
}

const initialState: NonHaulTruckFuelAnalyticsState = {
  fuelEfficiency: {
    data: [],
    sortedBottom5: [],
    sortedTop5: [],
    fuelConsumptionAverage: 0,
    engineHoursAverage: 0,
  },
  fuelNoneIdleEvents: {
    data: [],
    sortedBottom5: [],
    sortedTop5: [],
    fuelConsumptionAverage: 0,
  },
};

export const nonHaulTruckfuelAnalyticsSlice = createSlice({
  name: 'nonHaulTruckfuelAnalytics',
  initialState,
  reducers: {
    set(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
    clear(state) {
      return initialState;
    },
  },
});

export const { set, clear } = nonHaulTruckfuelAnalyticsSlice.actions;
export default nonHaulTruckfuelAnalyticsSlice.reducer;
