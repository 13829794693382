import { type SxProps } from '@mui/material';
import { styled } from '@mui/material/styles';

export const paperCardStyle: SxProps = {
  padding: '7px',
  marginBottom: '7px',
  backgroundColor: '#FFFFFF',
  height: '80px',
  color: '#000',
  alignContent: 'center',
};

export const paperCardStyleDark: SxProps = {
  padding: '7px',
  marginBottom: '7px',
  backgroundColor: '#172A46',
  height: '80px',
  color: '#fff',
  alignContent: 'center',
};

export const paperTabCardStyle: SxProps = {
  padding: '7px',
  marginBottom: '7px',
  backgroundColor: '#FFFFFF',
  height: '120px',
  color: '#000',
  alignContent: 'center',
  borderRadius: '5px',
  border: 'solid rgba(0, 0, 0, 0.1) 1px',
  width: '30%',
};

export const paperTabCardStyleDark: SxProps = {
  padding: '7px',
  marginBottom: '7px',
  backgroundColor: '#172A46',
  height: '120px',
  color: '#fff',
  alignContent: 'center',
  borderRadius: '5px',
  border: 'solid rgba(225, 225, 225, 0.1) 1px',
  width: '30%',
};
interface StyledSpanProps {
  enableDarkTheme?: boolean;
}

export const StyledSpan = styled('span', {
  shouldForwardProp: (prop) => prop !== 'enableDarkTheme',
})<StyledSpanProps>(({ theme, enableDarkTheme }) => ({
  color: enableDarkTheme ? '#b1bccc' : '#58595b',
  fontSize: '13px',
  padding: '5px',
}));
