import { SVG } from 'components/Asset/SVG';
import { theme } from 'mui.theme';
import { type Notification } from 'types/notification.types';
import { NotificationDelivery } from './NotificationList.styled';
import { IdSpan } from 'components/AgGrid/CellRenderer/ListView.styled';
import { StatusWrapper } from 'components/AgGrid/CellRenderer/CellRenderer';
import { formatDateToString } from 'utils/helpers/dateTime';
import { formatConditions } from '../helpers/common';
import { NOTIFICATION_LEVEL } from 'utils/enums';
import { useAppSelector } from 'store/hook';

const { svgIcons } = theme.palette;

interface Props {
  value: string;
  data: Notification;
  handleRuleNameClick: (ruleId: string) => void;
  handleRuleStatusClick: (ruleId: string) => void;
}

export const DeleteNotification = ({
  handleRuleStatusClick,
  data: { ruleId },
}: Props) => {
  return (
    <span
      onClick={() => {
        handleRuleStatusClick(ruleId);
      }}
    >
      <SVG
        name="deleteIcon"
        style={{
          paddingTop: '9px',
          ...svgIcons?.primary,
        }}
      />
    </span>
  );
};

export const DeactivateNotification = ({
  handleRuleStatusClick,
  data: { ruleId },
}: Props) => {
  return (
    <span
      onClick={() => {
        handleRuleStatusClick(ruleId);
      }}
    >
      <SVG
        name="resetIcon"
        style={{
          paddingTop: '9px',
          ...svgIcons?.primary,
        }}
      />
    </span>
  );
};

export const Rule = ({
  handleRuleNameClick,
  data: { ruleId, ruleName },
}: Props) => {
  return (
    <IdSpan
      onClick={() => {
        handleRuleNameClick(ruleId);
      }}
    >
      {ruleName}
    </IdSpan>
  );
};

export const Status = ({ data: { isActive } }: Props) => {
  return <StatusWrapper isActive={isActive} />;
};

export const AlertDelivery = ({
  data: { alertByEmail, alertByNotificationCenter, alertByUI },
}: Props) => {
  const alertDeliveryArray = [];

  if (alertByEmail) {
    alertDeliveryArray.push('Email');
  }

  if (alertByNotificationCenter) {
    alertDeliveryArray.push('Notification Centre');
  }

  if (alertByUI) {
    alertDeliveryArray.push('Application UI');
  }

  return (
    <NotificationDelivery>{alertDeliveryArray.join(', ')}</NotificationDelivery>
  );
};

export function alertDeliveryValueGetter(params: any) {
  const { alertByEmail, alertByNotificationCenter, alertByUI } = params.data;
  const alertDeliveryArray = [];
  if (alertByEmail) {
    alertDeliveryArray.push('Email');
  }

  if (alertByNotificationCenter) {
    alertDeliveryArray.push('Notification Centre');
  }

  if (alertByUI) {
    alertDeliveryArray.push('Application UI');
  }

  return alertDeliveryArray.join(', ');
}

export const LastEdited = ({ data: { updatedAt } }: Props) => {
  return <span>{formatDateToString(updatedAt, 'MMM DD, YYYY')}</span>;
};

export const NotificationConditions = ({
  data: { conditions, notificationLevel },
}: Props) => {
  const unitSystem = useAppSelector((state) => state.persistedReducer).customer
    .unitSystem;
  const formattedConditions = formatConditions(conditions, unitSystem);
  const notificationLevelString = NOTIFICATION_LEVEL.find(
    (item) => item.id === notificationLevel
  )?.display;
  return (
    <span>
      {formattedConditions}, {notificationLevelString}
    </span>
  );
};

export function notificationsConditionsValueGetter(
  params: any,
  unitSystem: string
) {
  const { conditions, notificationLevel } = params.data;
  const formattedConditions = formatConditions(conditions, unitSystem);
  const notificationLevelString = NOTIFICATION_LEVEL.find(
    (item) => item.id === notificationLevel
  )?.display;
  if (formattedConditions && notificationLevelString) {
    return `${formattedConditions}, ${notificationLevelString}`;
  }
  return '';
}

export const AppliesTo = ({ data: { bumperNumbers } }: Props) => {
  const formattedRules = bumperNumbers?.map((bumperNumber) => {
    return bumperNumber;
  });
  return <span>{formattedRules?.join(', ')}</span>;
};

export const SeverityDisplay = ({ data: { notificationLevel } }: Props) => {
  const notificationLevelObj = NOTIFICATION_LEVEL.find(
    (item) => item.id === notificationLevel
  );
  const notificationLevelString = notificationLevelObj?.display;
  const stringColor = notificationLevelObj?.color;
  return (
    <span
      style={{
        color: stringColor,
      }}
    >
      {notificationLevelString?.replace('Severity', '')}
    </span>
  );
};
