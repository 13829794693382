import React, { useEffect, useRef, useState } from 'react';
import {
  PieChart,
  Pie,
  Cell,
  Tooltip,
  ResponsiveContainer,
  Legend,
  Sector,
} from 'recharts';
import type { Asset } from '../utils';
import { pastelColorMap, generateRandomColor } from 'utils/helpers/general';
import { handleFocusRemoveSelectOutline } from '../../Assets/Helper/pieChart.Helper';
import { ThemePalette } from 'mui.theme';
import { Typography } from '@mui/material';

const renderActiveShape = (props: any) => {
  const RADIAN = Math.PI / 180;
  const {
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    startAngle,
    endAngle,
    fill,
    percent,
    value,
  } = props;
  const sin = Math.sin(-RADIAN * midAngle);
  const cos = Math.cos(-RADIAN * midAngle);
  const sx = Number(cx) + (Number(outerRadius) + 10) * cos;
  const sy = Number(cy) + (Number(outerRadius) + 10) * sin;
  const mx = Number(cx) + (Number(outerRadius) + 30) * cos;
  const my = Number(cy) + (Number(outerRadius) + 30) * sin;
  const ex = mx + (cos >= 0 ? 1 : -1) * 22;
  const ey = my;
  const textAnchor = cos >= 0 ? 'start' : 'end';
  return (
    <g>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={Number(outerRadius) + 6}
        outerRadius={Number(outerRadius) + 10}
        fill={fill}
      />
      <path
        d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
        stroke={fill}
        fill="none"
      />
      <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" />
      <text
        x={Number(ex) + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
        dy={-12}
        textAnchor={textAnchor}
        fill={fill}
        fontSize={10}
      >{`Value:`}</text>
      <text
        x={Number(ex) + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
        textAnchor={textAnchor}
        fill={fill}
        fontSize={14}
      >{`${value}`}</text>
      <text
        x={Number(ex) + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
        dy={15}
        textAnchor={textAnchor}
        fill="#999"
        fontSize={10}
      >
        {`Rate`}
      </text>
      <text
        x={Number(ex) + (cos >= 0 ? 1 : -1) * 12}
        y={ey}
        dy={27}
        textAnchor={textAnchor}
        fill="#999"
        fontSize={14}
      >
        {`${(percent * 100).toFixed(0)}%`}
      </text>
    </g>
  );
};

const CustomTooltip: React.FC<{
  active?: boolean;
  payload?: any;
  label?: string;
  enableDarkTheme: boolean;
}> = ({ active, payload, label, enableDarkTheme = false }) => {
  if (active && payload && payload.length) {
    const hoverCell = payload[0].payload;

    return (
      <div className="custom-tooltip">
        <Typography
          variant="body2"
          sx={{
            color: enableDarkTheme
              ? ThemePalette.typography.white
              : ThemePalette.typography.black,
          }}
        >
          Asset Name: {hoverCell.name}
        </Typography>
        <Typography
          variant="body2"
          sx={{
            color: enableDarkTheme
              ? ThemePalette.typography.white
              : ThemePalette.typography.black,
          }}
        >
          Total Asset Cost: {hoverCell.total_asset_cost}
        </Typography>
      </div>
    );
  }

  return null;
};

export const ComponentsPieChart = ({
  data,
  handlePieClick,
  enableDarkTheme = false,
}: {
  data: Asset[];
  handlePieClick: any;
  enableDarkTheme: boolean;
}) => {
  const [activeIndices, setActiveIndices] = useState<Record<number, boolean>>(
    {}
  );
  const prevDataRef = useRef<Asset[]>([]);

  useEffect(() => {
    const hasDataChanged = !isEqual(prevDataRef.current, data);

    if (hasDataChanged) {
      setActiveIndices({});
    }
    // Update prevDataRef with current data for next comparison
    prevDataRef.current = data;
  }, [data]); // Reset when 'data' prop changes

  const onPieClick = (data: any, index: number) => {
    setActiveIndices((prevState) => ({
      ...prevState,
      [index]: !prevState[index], // Toggle the active state for the clicked segment
    }));
    handlePieClick(data);
  };
  return (
    <ResponsiveContainer width="100%" height="100%">
      <div tabIndex={0} onFocus={handleFocusRemoveSelectOutline}>
        <PieChart width={400} height={300}>
          <Pie
            dataKey="total_asset_cost"
            isAnimationActive={true}
            data={data}
            cx="50%"
            cy="50%"
            innerRadius={60}
            outerRadius={80}
            fill="#8884d8"
            onClick={onPieClick}
            labelLine={false}
            activeIndex={Object.keys(activeIndices)
              .map(Number)
              .filter((i) => activeIndices[i])} // Pass active indices
            activeShape={renderActiveShape}
          >
            {data.map((entry: any, index: number) => (
              <Cell key={`cell-${index}`} fill={getColourByIndex(index)} />
            ))}
          </Pie>
          <Legend />
          <Tooltip
            content={<CustomTooltip enableDarkTheme={enableDarkTheme} />}
          />
        </PieChart>
      </div>
    </ResponsiveContainer>
  );
};

function getColourByIndex(index: number) {
  return pastelColorMap[index] || generateRandomColor();
}

const isEqual = (arr1: Asset[], arr2: Asset[]): boolean => {
  if (arr1.length !== arr2.length) return false;
  for (let i = 0; i < arr1.length; i++) {
    if (JSON.stringify(arr1[i]) !== JSON.stringify(arr2[i])) {
      return false;
    }
  }
  return true;
};
