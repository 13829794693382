import BaseAPI from './base.api';
import { type Notification } from 'types/notification.types';
import { type ICreateRulePayload } from 'types/payloads/rule.payload.types';

export default class RuleAPI extends BaseAPI {
  create = async (payload: ICreateRulePayload) => {
    const response = await this.API.post('rule', payload);
    return response.data;
  };

  getAllRules = async (): Promise<Notification[]> => {
    const response = await this.API.get('rule');
    return response.data;
  };

  update = async (
    ruleId: string,
    payload: { ruleName: string }
  ): Promise<Notification> => {
    const response = await this.API.put(`rule/${ruleId}`, payload);
    return response.data;
  };

  updateStatus = async (ruleId: string): Promise<Notification> => {
    const response = await this.API.patch(`rule/${ruleId}`);
    return response.data;
  };

  softDelete = async (ruleId: string): Promise<Notification> => {
    const response = await this.API.patch(`rule/${ruleId}/delete`);
    return response.data;
  };
}
