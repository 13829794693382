import { type FC } from 'react';
import {
  ComposedChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
  CartesianGrid,
  Cell,
} from 'recharts';
import Colors from '../styles';
import { ThemePalette } from 'mui.theme';
import Typography from '@mui/material/Typography';

interface ChartProps {
  data: any[];
  barKeys: Array<{ name: string; color: string; label: string }>;
  xAxisDataKey: string;
  xAxisLabel: string;
  yAxisLabel: string;
  isBarColorsSolid?: boolean;
  sort?: string;
  enableDarkTheme: boolean;
}

export const ChartComponent: FC<ChartProps> = ({
  data,
  barKeys,
  xAxisDataKey,
  xAxisLabel,
  yAxisLabel,
  isBarColorsSolid = true,
  sort = 'desc',
  enableDarkTheme,
}) => {
  interface TickProps {
    x: number;
    y: number;
    stroke: string;
    payload: {
      value: string;
    };
  }

  const CustomTick = ({ x, y, payload }: TickProps) => {
    const angle = -45; // Angle of rotation

    // Split the label into words and position each word on a new line
    const words = payload.value.split(' ');
    const lineHeight = 14; // Adjust line height as needed

    return (
      <g transform={`translate(${x},${y})`}>
        {words.map((word, index) => (
          <text
            key={index}
            x={0}
            y={lineHeight * index}
            dy={13}
            textAnchor="end"
            transform={`rotate(${angle})`}
            fontSize={12}
            fontWeight="Bold"
            fill={
              enableDarkTheme
                ? ThemePalette.typography.white
                : ThemePalette.typography.black
            }
          >
            {word}
          </text>
        ))}
      </g>
    );
  };

  const getColor = (index: number) => {
    if (index === 0) return Colors.red;
    if (index >= 1 && index <= 3) return Colors.yellow;
    return Colors.blue;
  };

  const sortedData = [...data].sort((a, b) => {
    if (sort === 'desc') {
      return b[barKeys[0].name] - a[barKeys[0].name]; // Sort in descending order
    } else {
      return a[barKeys[0].name] - b[barKeys[0].name]; // Sort in ascending order
    }
  });

  const getMaxValue = () => {
    const maxValues = data.map((entry) =>
      Math.max(
        ...barKeys
          .filter((bk) => entry[bk.name] !== undefined) // Filter out undefined properties
          .map((bk) => entry[bk.name])
      )
    );
    const maxValue = Math.max(...maxValues);
    if (maxValue <= 1000) {
      // Handle small values
      return Math.ceil(maxValue / 100) * 100; // Round up to the nearest hundred
    } else {
      // Handle large values
      const magnitude = Math.pow(10, Math.floor(Math.log10(maxValue)) - 1);
      return Math.ceil(maxValue / magnitude) * magnitude; // Round up to the nearest magnitude
    }
  };

  const calculateTicks = (maxValue: number) => {
    const tickCount = 6; // Desired number of ticks
    const rawStep = maxValue / (tickCount - 1);
    const magnitude = Math.pow(10, Math.floor(Math.log10(rawStep))); // Find the order of magnitude of the step
    const refinedStep = Math.ceil(rawStep / magnitude) * magnitude; // Refine step to a 'nicer' value

    return Array.from({ length: tickCount }, (_, i) => i * refinedStep);
  };

  const maxValue = getMaxValue();
  const ticks = calculateTicks(maxValue);

  return (
    <ResponsiveContainer height={600}>
      <ComposedChart
        data={sortedData}
        margin={{ top: 5, right: 30, left: 20, bottom: 4 }} // Increase bottom margin
      >
        <CartesianGrid stroke="#f5f5f5" />

        <XAxis
          dataKey={xAxisDataKey}
          fontSize={12}
          label={{
            value: xAxisLabel,
            dy: 10,
            fill: enableDarkTheme
              ? ThemePalette.typography.white
              : ThemePalette.typography.black,
          }}
          interval={0} // Add this line"preserveStartEnd" | "preserveStart" | "preserveEnd" | "equidistantPreserveStart"
          height={170} // Increase the height to accommodate wrapped text
          tick={CustomTick}
        />

        <YAxis
          interval={'preserveStartEnd'}
          label={{
            value: yAxisLabel,
            angle: -90,
            position: 'insideLeft',
            fontSize: 12,
            textAnchor: 'middle',
            dy: 170,
            fill: enableDarkTheme
              ? ThemePalette.typography.white
              : ThemePalette.typography.black,
          }}
          tick={{
            fontSize: 12,
            fontWeight: 'Bold',
            dy: 2,
          }}
          yAxisId="cost"
          domain={[0, ticks[ticks.length - 1]]}
          ticks={ticks}
        />
        <Tooltip />
        <Legend />
        {barKeys.map((bk, index) => (
          <Bar
            key={bk.name}
            dataKey={bk.name}
            barSize={20}
            legendType="none"
            name={bk.label}
            yAxisId="cost"
          >
            {data.map((entry, entryIndex) => (
              <Cell
                key={`cell-${entryIndex}`}
                fill={isBarColorsSolid ? bk.color : getColor(entryIndex)}
              />
            ))}
          </Bar>
        ))}
      </ComposedChart>
    </ResponsiveContainer>
  );
};
