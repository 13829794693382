import styled from 'styled-components';
import { CardHeader } from '@mui/material';
import { type InteractiveColor } from 'types/theme.types';
import { COLORS } from 'utils/enums';

export const AssetCardsDiv = styled.div`
  opacity: 1;
  margin: 1%;
  padding: 1%;
  height: 800px;
  overflow: scroll;
  background: '#0A192D';
  .card {
    min-width: 200px;
    box-shadow: -8px 10px 9px -7px rgba(0, 0, 0, 0.65);
    border: 2px solid rgba(0, 0, 0, 0.04);
    padding: 0;
    border-radius: 5px;
  }

  .MuiCardHeader-subheader {
    color: #fff;
    font-weight: 700;
    font-size: 15px;
    text-decoration: underline;

    &:hover {
      cursor: pointer;
      color: rgba(255, 255, 255, 0.7);
    }
  }

  .MuiCardContent-root {
    padding: 0;
  }

  .summary {
    line-height: 10px;
    font-weight: bold;
    font-size: 14px;
  }

  .summary span {
    font-weight: normal;
  }

  .image {
    padding-top: 10px;
  }
`;

export const CardHeaderDiv = styled(CardHeader)<{
  backgroundcolor?: InteractiveColor;
}>`
  background: ${(props) => props.backgroundcolor};
  color: #fff;
  height: 5px;
`;

export const CardSummary = styled.div`
  background-color: #f3f4f6;
  margin: 0;
  padding: 4px;
  display: flex;
  justify-content: space-around;

  &:hover {
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.1);
  }
`;

export const CardDetails = styled.div`
  padding: 5px 10px;
`;

export const CardFooter = styled.div<{ $error?: boolean }>`
  padding: 0.1px 10px 10px 10px;
  background-color: ${(props) => (props.$error ? COLORS.red : '#f3f4f6')};
  height: 30px;
`;
