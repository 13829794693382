import { PaddedListWrapper } from 'components/AgGrid/CellRenderer/ListView.styled';
import * as React from 'react';
import { useAppSelector } from 'store/hook';
import { selectUniqByActiveShowOnTableTags } from 'store/tag.slice';
import type { ChartProps } from 'types/FleetOverview.types';
import AssetCard from '../components/AssetCards';
import { AssetList } from '../components/AssetList';
import { FilterViewBar } from '../components/FilterViewBar';
import AssetMap from '../components/AssetMap';
import { Base } from 'components/Containers/BaseContainer';
import AssetCardV2 from '../components/AssetCardsV2';

export interface TagDisplay {
  id: string;
  display: string;
  unit?: string;
  tagName?: string;
}

/**
 * Dashboard display for assets (lists, cards, maps)
 */
export const AssetTable = ({
  filteredAssets,
  enableDarkTheme,
  shouldShowNewDashboard = false,
  isLoadingAllAssets = false,
}: ChartProps) => {
  const [searchValue, setSearchValue] = React.useState<string>('');

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };

  const [view, setView] = React.useState<string>('card');
  const [tags, setTags] = React.useState<TagDisplay[]>([]);

  // all tags "active" in our system
  const activeTags = useAppSelector(selectUniqByActiveShowOnTableTags);
  // convert tags to selector options
  const tagSelectionOptions = activeTags.map((tag) => {
    return {
      id: tag.tagName,
      display: tag.tagAlias || tag.tagName,
      unit: tag.unit,
    };
  });
  const sortedTagOptions = tagSelectionOptions
    .slice()
    .sort((a, b) => a.display.localeCompare(b.display));

  const setSelectedTag = (selectedTag: TagDisplay) => {
    let assetTags = [...tags];

    const tagExists = assetTags.find((tag) => tag.id === selectedTag.id);

    if (tagExists) {
      assetTags = assetTags.filter((tag) => tag.id !== selectedTag.id);
    } else {
      assetTags.push(selectedTag);
    }

    setTags([...assetTags]);
    sessionStorage.setItem('assetSelectedTags', JSON.stringify(assetTags));
  };

  const [exportTo, setExportTo] = React.useState(false);
  const [dateComparer, setDateComparer] = React.useState('day');
  const [cardSort, setCardSort] = React.useState('severity');

  const handleExportClick = (val: boolean) => {
    setExportTo(val);
  };

  React.useEffect(() => {
    const defaultTagsToShow = activeTags
      .filter((item) => item.showOnFleetOverviewTable)
      .map((tg) => {
        return {
          id: tg.tagName,
          display: tg.tagAlias || tg.tagName,
          unit: tg.unit,
        };
      });

    let assetTagsData = [];
    const storageData = sessionStorage.getItem('assetSelectedTags');

    if (storageData) {
      assetTagsData = JSON.parse(storageData);
    }

    const mergedTags = [...defaultTagsToShow, ...assetTagsData];

    const uniqueTags = removeDuplicates(mergedTags);

    setTags(uniqueTags);
  }, [activeTags]);

  return (
    <Base enableDarkTheme={enableDarkTheme}>
      <FilterViewBar
        setSearchValue={onChange}
        setView={setView}
        view={view}
        tags={sortedTagOptions}
        handleSelectTag={setSelectedTag}
        selectedTags={tags}
        handleExport={handleExportClick}
        setSelectedTags={setTags}
        enableDarkTheme={enableDarkTheme}
        shouldShowNewDashboard={shouldShowNewDashboard}
        dateComparer={dateComparer}
        setDateComparer={setDateComparer}
        cardSort={cardSort}
        setCardSort={setCardSort}
      />
      <PaddedListWrapper>
        {view === 'card' ? (
          /**
           * The tags here need to be added to the asset data object itself
           * Only tags with show in card view should be added
           */
          <>
            {shouldShowNewDashboard ? (
              <AssetCardV2
                filteredAssets={filteredAssets}
                searchValue={searchValue}
                enableDarkTheme={enableDarkTheme}
                dateComparer={dateComparer}
                cardSort={cardSort}
                isLoadingAllAssets={isLoadingAllAssets}
              />
            ) : (
              <AssetCard
                filteredAssets={filteredAssets}
                searchValue={searchValue}
                exportTo={exportTo}
                handleExport={handleExportClick}
                enableDarkTheme={enableDarkTheme}
              />
            )}
          </>
        ) : view === 'list' ? (
          <AssetList
            searchValue={searchValue}
            filteredAssets={filteredAssets}
            tags={tags}
            exportTo={exportTo}
            handleExport={handleExportClick}
          />
        ) : (
          <AssetMap filteredAssets={filteredAssets} searchValue={searchValue} />
        )}
      </PaddedListWrapper>
    </Base>
  );
};

interface DataItem {
  id: string;
  display: string;
  unit: string;
}

function removeDuplicates(data: DataItem[]): DataItem[] {
  const uniqueIds = new Set<string>();
  const uniqueData: DataItem[] = [];

  for (const item of data) {
    if (!uniqueIds.has(item.id)) {
      uniqueIds.add(item.id);
      uniqueData.push(item);
    }
  }

  return uniqueData;
}
