import { createSlice } from '@reduxjs/toolkit';

export interface EngineHoursState {
  data: any[];
  engineHoursLoaded?: boolean;
  deviceEngineHour: any[];
}

const initialState: EngineHoursState = {
  data: [],
  engineHoursLoaded: false,
  deviceEngineHour: [],
};

export const engineHoursSlice = createSlice({
  name: 'engineHours',
  initialState,
  reducers: {
    getAll(state, { payload }) {
      return {
        ...state,
        data: payload,
      };
    },
    setEngineHoursLoaded(state, { payload }) {
      state.engineHoursLoaded = payload;
    },
    setDeviceEngineHour(state, { payload }) {
      state.deviceEngineHour = payload;
    },
    clearDeviceEngineHour(state) {
      state.deviceEngineHour = [];
    },
  },
});

export const {
  getAll,
  setEngineHoursLoaded,
  setDeviceEngineHour,
  clearDeviceEngineHour,
} = engineHoursSlice.actions;
export default engineHoursSlice.reducer;
