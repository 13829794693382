import { Selector } from 'components/Form/Selectors/Selector';
import { useAppDispatch, useAppSelector } from 'store/hook';
import { updateCustomer } from 'store/persisted.slice';
import { resetAssets } from 'store/asset.slice';
import { resetDevices } from 'store/device.slice';

/**
 * Customer select component used in the top bar to allow
 * users to change the current customer.
 *
 * @returns  JSX.Element
 */
export const CustomerSelector = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const { persistedReducer, customerReducer } = useAppSelector(
    (state) => state
  );
  const { latestSensorPollingId, notificationPollingId } = useAppSelector(
    (state) => state.appReducer
  );
  const customerId = persistedReducer.customer.id || '';

  const selectorOptions = customerReducer.customers.map((customer) => {
    return {
      id: customer.customerId,
      display: customer.customerName,
      code: customer.customerCode,
      unitSystem: customer.unitSystem,
    };
  });

  return (
    <Selector
      inputProps={{
        sx: {
          fontSize: '18px',
          fontWeight: 'medium',
        },
      }}
      value={customerId}
      onChange={(event) => {
        const selectedCustomerId = event.target.value.toString();
        // find customer
        const selectedCustomerName = selectorOptions.find((option) => {
          if (option.id === selectedCustomerId) {
            return option.display;
          }

          return undefined;
        });
        const selectedCustomerCode = selectorOptions.find((option) => {
          if (option.id === selectedCustomerId) {
            return option.code;
          }

          return undefined;
        });
        const selectedCustomerUnitSystem = selectorOptions.find((option) => {
          if (option.id === selectedCustomerId) {
            return option.unitSystem;
          }

          return undefined;
        });

        if (!selectedCustomerName) return undefined;
        if (!selectedCustomerCode) return undefined;
        if (!selectedCustomerUnitSystem) return undefined;

        if (latestSensorPollingId) {
          // stopping the getDeviceLatestSensorDataAll call for the prev customer
          clearTimeout(latestSensorPollingId);
        }
        if (notificationPollingId) {
          // stopping the getAllLatest notifcation call for the prev customer
          clearTimeout(notificationPollingId);
        }

        dispatch(resetAssets());
        dispatch(resetDevices());
        sessionStorage.removeItem('assetSelectedTags');
        sessionStorage.removeItem('selectedVehicleStatusTags');
        sessionStorage.removeItem('datePickerSelection');

        dispatch(
          updateCustomer({
            id: selectedCustomerId,
            name: selectedCustomerName.display,
            code: selectedCustomerCode.code,
            unitSystem: selectedCustomerCode.unitSystem,
          })
        );
      }}
      selectorOptions={selectorOptions}
    ></Selector>
  );
};
