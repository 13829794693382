import { Typography } from '@mui/material';
import CallMadeIcon from '@mui/icons-material/CallMade';
import CallReceivedIcon from '@mui/icons-material/CallReceived';
import { type Asset } from 'types/asset.types';
import LoadingDots from '../../LoadingDots';
import { truncateTo2dp } from 'utils/helpers/general';
import { getFuelUsageDiff } from '../../utils';
import { useNavigate } from 'react-router-dom';
import { SWToolTip } from 'components/SWToolTip';
import { ThemePalette } from 'mui.theme';

interface Props {
  asset: Asset;
  enableDarkTheme: boolean;
  isLoadingAllAssets: boolean;
  dateComparer: string;
}

const AssetCardProductivity = ({
  asset,
  enableDarkTheme = false,
  isLoadingAllAssets,
  dateComparer,
}: Props) => {
  const assetDiff = getFuelUsageDiff(
    asset.fuelUsage,
    asset.utilization,
    asset.productivity,
    dateComparer
  );
  const navigate = useNavigate();
  return (
    <SWToolTip
      title={`Click for drill-down to view production for ${asset.bumperNumber}`}
    >
      <div
        onClick={() => {
          navigate(`/production/${asset.assetVin}`, {
            state: { type: asset.assetType },
          });
        }}
        style={{
          cursor: 'pointer',
        }}
      >
        <Typography
          sx={{
            fontSize: '11px',
          }}
        >
          Productivity
        </Typography>
        <Typography
          style={{
            fontSize: '16px',
          }}
        >
          {asset.productivity?.prod_score ? (
            <>{truncateTo2dp(asset.productivity.prod_score)}%</>
          ) : (
            <>
              {isLoadingAllAssets ? (
                <LoadingDots enableDarkTheme={enableDarkTheme} />
              ) : (
                <>n/a</>
              )}
            </>
          )}
        </Typography>
        <Typography
          sx={{
            fontSize: '12px',
          }}
        >
          {assetDiff?.productivity ? (
            <>
              {assetDiff?.productivity < 0 ? (
                <CallReceivedIcon
                  sx={{
                    paddingTop: '3px',
                    paddingRight: '5px',
                    fontSize: '12px',
                    color: ThemePalette.typography.lightDanger,
                  }}
                />
              ) : (
                <CallMadeIcon
                  sx={{
                    paddingTop: '3px',
                    paddingRight: '5px',
                    fontSize: '12px',
                    color: ThemePalette.typography.lightSuccess,
                  }}
                />
              )}
              <span
                style={{
                  color:
                    assetDiff.productivity < 0
                      ? ThemePalette.typography.lightDanger
                      : ThemePalette.typography.lightSuccess,
                }}
              >
                {truncateTo2dp(Math.abs(assetDiff.productivity))}%
              </span>
            </>
          ) : (
            <>
              {isLoadingAllAssets ? (
                <LoadingDots enableDarkTheme={enableDarkTheme} />
              ) : (
                <>n/a</>
              )}
            </>
          )}
        </Typography>
      </div>
    </SWToolTip>
  );
};

export default AssetCardProductivity;
