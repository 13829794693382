import { Box } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';

interface Props {
  children: JSX.Element[];
  gridItemProps?: {
    xs?: number;
    sm?: number;
    md?: number;
    lg?: number;
    xl?: number;
  };
}
export const ModalContent = ({ children, gridItemProps }: Props) => {
  return (
    <Box component={'form'}>
      <Grid2 container columnSpacing="60px" rowSpacing="28px">
        {children.map((element) => {
          return (
            <Grid2
              key={element.key}
              lg={6}
              {...gridItemProps}
              {...element.props}
            >
              {element}
            </Grid2>
          );
        })}
      </Grid2>
    </Box>
  );
};
