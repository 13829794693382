import { SVG } from 'components/Asset/SVG';
import {
  IdSpan,
  OperationalStatusText,
} from 'components/AgGrid/CellRenderer/ListView.styled';
import { theme } from 'mui.theme';
import { type Asset } from 'types/asset.types';
import { StyledIcon } from 'components/AgGrid/CellRenderer/CellRenderer.styled';
import { ASSET_OPERATIONAL_STATUS } from 'utils/enums';
import { resolveAssetTypeDisplay } from 'utils/helpers/assets';
import { checkDeviceStatus } from 'store/utils';
import { type Device as DeviceType } from 'types/device.types';
import { handleAssetStatus } from '../helpers/common';
import { notificationLevel } from 'views/FleetOverview/helpers/asset.helpers';
import { capitalizeFirstLetter } from 'utils/helpers/string.manipulation';
import { type RuleNotifications } from 'types/notification.types';
const { svgIcons } = theme.palette;

export interface AssetListRendererProp {
  value: string;
  data: Asset;
  device: DeviceType & Record<string, number>;
  handleIdClick: (
    asset_id: string,
    assetVin?: string,
    make?: string,
    model?: string,
    bumperNumber?: string
  ) => void;
  handleAlertClick: (
    notifications: RuleNotifications[] | undefined,
    bumperNumber: string
  ) => void;
  handleDeleteAsset: (asset_id: string) => void;
  handleOperatorClick: (asset_id: string) => void;
  handleTagClick: (asset_id: string) => void;
  handleDeviceClick: (asset_id: string) => void;
  handleNoDeviceClick: (arg0: string) => void;
  type: string;
  colDef: any;
}

export interface AlertListRendererProp {
  value: string;
  data: Asset;
  handleAlertClick: (
    notifications: RuleNotifications[] | undefined,
    bumperNumber: string
  ) => void;
  type: string;
  colDef: any;
}

export const DeleteAsset = ({
  data: { assetId },
  handleDeleteAsset,
}: AssetListRendererProp) => {
  return (
    <span
      onClick={() => {
        handleDeleteAsset(assetId);
      }}
    >
      <SVG
        name="deleteIcon"
        style={{
          ...svgIcons?.primary,
        }}
      />
    </span>
  );
};

export const AssetId = ({
  handleIdClick,
  data: { assetVin, assetId },
}: Pick<AssetListRendererProp, 'handleIdClick' | 'data'>) => {
  return (
    <IdSpan
      onClick={() => {
        handleIdClick(assetId);
      }}
    >
      {assetVin}
    </IdSpan>
  );
};

export const Operators = ({
  handleOperatorClick,
  data: { assetId },
}: AssetListRendererProp) => {
  return (
    <IdSpan
      onClick={() => {
        handleOperatorClick(assetId);
      }}
    >
      View List
    </IdSpan>
  );
};

export const Tags = ({
  handleTagClick,
  data: { assetId },
}: AssetListRendererProp) => {
  return (
    <IdSpan
      onClick={() => {
        handleTagClick(assetId);
      }}
    >
      View List
    </IdSpan>
  );
};

export const MissingDevice = ({
  handleDeviceClick,
}: {
  handleDeviceClick: () => void;
}) => {
  return (
    <>
      <StyledIcon>
        <SVG
          name={'offlineIcon'}
          style={{
            ...svgIcons.error,
          }}
        />
      </StyledIcon>
      <IdSpan
        onClick={() => {
          handleDeviceClick();
        }}
      >
        No Device
      </IdSpan>
    </>
  );
};

export const DeviceStatus = ({
  handleDeviceClick,
  handleNoDeviceClick,
  data: { device, assetId, liveTagData },
}: Pick<
  AssetListRendererProp,
  'data' | 'handleDeviceClick' | 'handleNoDeviceClick'
>) => {
  const [isMissing, isActive, isXYFound] = checkDeviceStatus(device);

  if (isMissing) {
    return (
      <MissingDevice
        handleDeviceClick={() => {
          handleNoDeviceClick(assetId);
        }}
      />
    );
  }

  if (!isXYFound) {
    return (
      <>
        <StyledIcon>
          <SVG
            name={'offlineIcon'}
            style={{
              ...svgIcons.error,
            }}
          />
        </StyledIcon>
        <IdSpan
          onClick={() => {
            handleDeviceClick(assetId);
          }}
        >
          Missing X/Y
        </IdSpan>
      </>
    );
  }
  return (
    <span>
      <StyledIcon>
        <SVG
          name={
            device.deviceStatus === 'Online'
              ? 'greenIcon'
              : device.deviceStatus === 'Recently Online'
              ? 'activeIcon'
              : device.deviceStatus === 'Offline'
              ? 'offlineIcon'
              : 'redIcon'
          }
          style={{
            fill: device.deviceStatus === 'Online' ? 'green' : 'red',
          }}
        />
      </StyledIcon>
      {device.deviceStatus}
    </span>
  );
};

export const Device = ({
  handleDeviceClick,
  handleIdClick,
  data: { assetId, device, engineHoursOffset, odometerOffset },
}: AssetListRendererProp) => {
  /*
    A device is available to be associated if EITHER
    the engineHoursOffset or odometerOffset is greater than 0
  */

  const hasEssentialData = engineHoursOffset > 0 || odometerOffset > 0;

  if (!hasEssentialData) {
    return (
      <>
        <StyledIcon>
          <SVG
            name={'offlineIcon'}
            style={{
              ...svgIcons.error,
            }}
          />
        </StyledIcon>
        <IdSpan
          onClick={() => {
            // should open asset modal
            handleIdClick(assetId);
          }}
        >
          Missing Data
        </IdSpan>
      </>
    );
  }

  if (hasEssentialData && device?.deviceId) {
    return (
      <IdSpan
        onClick={() => {
          // should open device modal
          handleDeviceClick(assetId);
        }}
      >
        {device.deviceId}
      </IdSpan>
    );
  }
};

export const AssetType = ({
  data: { assetType },
}: Pick<AssetListRendererProp, 'data'>) => {
  return <>{resolveAssetTypeDisplay(assetType)}</>;
};

export const OperationalStatus = ({
  data: { isActive },
}: Pick<AssetListRendererProp, 'data'>) => {
  const operationalStatus = handleAssetStatus(isActive);
  const assetOperationalStatus = (ASSET_OPERATIONAL_STATUS as any)[
    operationalStatus
  ];
  if (!assetOperationalStatus) return null;
  const isOperational =
    assetOperationalStatus === ASSET_OPERATIONAL_STATUS.operational;
  return (
    <>
      <OperationalStatusText isOperational={isOperational}>
        {assetOperationalStatus}
      </OperationalStatusText>
    </>
  );
};

export const AlertStatus = ({
  data: { notifications },
}: Pick<AssetListRendererProp, 'data'>) => {
  const { color, warning } = notificationLevel(notifications);
  return (
    <IdSpan style={{ color }}>
      <>{warning}</>
    </IdSpan>
  );
};

export const DeviceIngestDate = ({
  data: { device },
}: Pick<AssetListRendererProp, 'data'>) => {
  return <span>{device.ingestDate?.toLocaleString()}</span>;
};

export const FuelSource = ({
  data: { fuelSource },
}: Pick<AssetListRendererProp, 'data'>) => {
  return <span>{capitalizeFirstLetter(fuelSource)}</span>;
};
