import { Typography } from '@mui/material';
import { ProgressBarWithArrow } from './ProgressBarWithArrow';
import { ThemePalette } from 'mui.theme';
import { useNavigate } from 'react-router-dom';
import { SWToolTip } from 'components/SWToolTip';

export const FleetOverviewV2GridItem = ({
  value = {},
  enableDarkTheme,
  type,
}: any) => {
  const navigate = useNavigate();
  return (
    <>
      <SWToolTip
        title={`Click for drill-down to view Fuel Usage for ${
          type === 'total' ? 'all Fleet' : type
        }`}
      >
        <Typography
          component="div"
          sx={{
            fontSize: '12px',
            textAlign: 'center',
            color: enableDarkTheme
              ? ThemePalette.typography.white
              : ThemePalette.typography.black,
            cursor: 'pointer',
          }}
          onClick={() => {
            if (type) {
              navigate(`/fuel-usage`, {
                state: {
                  type,
                  value: value.fuel,
                },
              });
            }
          }}
        >
          <ProgressBarWithArrow
            value={value.fuel}
            enableDarkTheme={enableDarkTheme}
          />
          Fuel Usage
        </Typography>
      </SWToolTip>

      <SWToolTip
        title={`Click for drill-down to view Utilization for ${
          type === 'total' ? 'all Fleet' : type
        }`}
      >
        <Typography
          component="div"
          sx={{
            fontSize: '12px',
            textAlign: 'center',
            color: enableDarkTheme
              ? ThemePalette.typography.white
              : ThemePalette.typography.black,
            cursor: 'pointer',
          }}
          onClick={() => {
            if (type) {
              navigate(`/utilization`, {
                state: {
                  type,
                },
              });
            }
          }}
        >
          <ProgressBarWithArrow
            value={value.utilization}
            enableDarkTheme={enableDarkTheme}
          />
          Utilization
        </Typography>
      </SWToolTip>

      <SWToolTip
        title={`Click for drill-down to view Production for ${
          type === 'total' ? 'all Fleet' : type
        }`}
      >
        <Typography
          component="div"
          sx={{
            fontSize: '12px',
            textAlign: 'center',
            color: enableDarkTheme
              ? ThemePalette.typography.white
              : ThemePalette.typography.black,
            cursor: 'pointer',
          }}
          onClick={() => {
            if (type) {
              navigate(`/production/:assetVin`, {
                state: {
                  type,
                },
              });
            }
          }}
        >
          {/** Others is dozer */}
          {type === 'Others' ? (
            <>{''}</>
          ) : (
            <>
              <ProgressBarWithArrow
                value={value.production}
                enableDarkTheme={enableDarkTheme}
              />
              Production
            </>
          )}
        </Typography>
      </SWToolTip>
    </>
  );
};
