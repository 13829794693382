import * as React from 'react';
import { BaseTableList } from 'components/AgGrid/AgGrid';
import { ListWrapper } from 'components/AgGrid/CellRenderer/ListView.styled';
import { StageProgressBarRenderer, formatDate } from '../Helper/helperFunction';

interface Props {
  workOrders: any[];
}

export const WorkOrdersHistory: React.FC<Props> = ({ workOrders }) => {
  const rowData = React.useMemo(() => workOrders, [workOrders]);

  const [columnDefs] = React.useState([
    {
      field: 'workorder_name',
      headerName: 'File Name',
      filter: 'agTextColumnFilter',
      sortable: true,
    },
    {
      field: 'created_ts',
      headerName: 'Created Timestamp',
      filter: 'agNumberColumnFilter',
      sort: 'desc' as const,
      sortable: true,
      cellRendererFramework: formatDate,
    },
    {
      field: 'completed_ts',
      headerName: 'Completed Timestamp',
      filter: 'agNumberColumnFilter',
      sortable: true,
      cellRendererFramework: formatDate,
    },
    {
      field: 'stage',
      headerName: 'Stage',
      filter: 'agTextColumnFilter',
      sortable: true,
      cellRendererFramework: StageProgressBarRenderer,
    },
  ]);

  const filterFunction = (node: any) => {
    return !!node.data?.isActive;
  };

  return (
    <ListWrapper>
      <BaseTableList
        rowData={rowData}
        columnDefs={columnDefs}
        filter={filterFunction}
        pagination={true}
        paginationPageSize={20}
      />
    </ListWrapper>
  );
};
