import * as yup from 'yup';

export const createOrUpdateAssetRequestBodySchema = yup
  .object({
    body: yup.object({
      assetVin: yup.string().required(),
      assetType: yup
        .string()
        .oneOf([
          'light_truck',
          'heavy_truck',
          'haul_truck',
          'dozer',
          'loader',
          'excavator',
          'drill',
          'bolter',
          'grader',
          'miscellaneous',
        ])
        .required(),
      isActive: yup.boolean().required(),
      make: yup.string().required(),
      model: yup.string().required(),
      bumperNumber: yup.string().required(),
      fuelSource: yup.string().nullable().optional(),
      externalId: yup.string().nullable().optional(),
      engineHoursOffset: yup
        .number()
        .min(0)
        .test(
          'engineHoursOffset',
          'At least one of engineHoursOffset or odometerOffset must be provided.',
          function (value = 0) {
            const odometerOffset = this.parent.odometerOffset;
            return value > 0 || odometerOffset > 0;
          }
        )
        .required(),
      odometerOffset: yup
        .number()
        .min(0)
        .test(
          'odometerOffset',
          'At least one of engineHoursOffset or odometerOffset must be provided.',
          function (value = 0) {
            const engineHoursOffset = this.parent.engineHoursOffset;
            return value > 0 || engineHoursOffset > 0;
          }
        )
        .required(),
    }),
  })
  .required();
