import type { Asset } from 'types/asset.types';
import { RuleConditionComponent } from './RuleCondition';
import { RuleNotificationComponent } from './RuleNotification';
import type { ICreateRulePayload } from 'types/payloads/rule.payload.types';
import { useAppSelector } from 'store/hook';

interface Items {
  rows: string[];
  newRule: ICreateRulePayload;
  addRow: (index: number, operator: string) => void;
  deleteRow: (index: number, id: string) => void;
  assets: Asset[];
  enableDarkTheme: boolean;
}

export const RelationalOperatorList = ({
  rows,
  newRule,
  addRow,
  deleteRow,
  assets,
  enableDarkTheme,
}: Items): JSX.Element => {
  const unitSystem = useAppSelector((state) => state.persistedReducer).customer
    .unitSystem;
  let ListComponent: React.ReactElement[] = [];

  if (rows.length) {
    ListComponent = rows.map((operator: string, index: number) => {
      if (operator === 'THEN') {
        return (
          <RuleNotificationComponent
            key={index.toString()}
            keyId={index + 1}
            operator={operator}
            deleteRow={deleteRow}
            newRule={newRule}
            rows={rows}
            enableDarkTheme={enableDarkTheme}
          />
        );
      }
      return (
        <RuleConditionComponent
          key={index.toString()}
          relationalOperator={operator}
          keyId={index + 1}
          deleteRow={deleteRow}
          addRow={addRow}
          rows={rows}
          newRule={newRule}
          assets={assets}
          enableDarkTheme={enableDarkTheme}
          unitSystem={unitSystem}
        />
      );
    });
  }

  return <>{ListComponent}</>;
};
