import {
  updateLoadingState,
  updateNotificationPollingState,
} from 'store/app.slice';
import { BaseHandler } from './base.handler';
import NotificationAPI from 'api/notification.api';
import {
  setNotifications,
  removeNotification,
  removeNotifications,
  readNotification,
  updateNotification,
} from 'store/ruleNotification.slice';

export default class NotificationHandler extends BaseHandler {
  private readonly api: NotificationAPI;
  constructor() {
    super();

    this.api = new NotificationAPI();
  }

  /**
   * Gets all notifications that the current user has access to
   */
  async getAll() {
    this.dispatch(updateLoadingState(true));
    try {
      const notifications = await this.api.getAllNotifications();

      this.dispatch(setNotifications(notifications));
      this.handleSuccess();
    } catch (error: any) {
      let msg: string = '';

      // Extract the error message from AxiosErrors
      // TODO: Look into other errors that can be thrown
      if (error.isAxiosError) {
        msg = error.response.data.message as string;
      }

      this.handleError(
        'An error occured while retrieving the notifications list: ' + msg
      );
    }
  }

  async clearNotifications(notificationIds: string[]) {
    this.dispatch(updateLoadingState(true));
    try {
      await this.api.clearNotifications({
        notificationIds,
      });

      this.dispatch(removeNotifications({ notificationIds }));
      // Remove from redux state
      this.handleSuccess(`Cleared selected Notifications`);
    } catch (error: any) {
      let msg: string = '';

      // Extract the error message from AxiosErrors
      // TODO: Look into other errors that can be thrown
      if (error.isAxiosError) {
        msg = error.response.data.message as string;
      }

      this.handleError('An error occured while clearing notifications: ' + msg);
    }
  }

  async clearNotification(notificationId: string) {
    this.dispatch(updateLoadingState(true));
    try {
      await this.api.clearNotification(notificationId);

      this.dispatch(removeNotification({ notificationId }));
      // Remove from redux state
      this.handleSuccess();
    } catch (error: any) {
      let msg: string = '';

      // Extract the error message from AxiosErrors
      // TODO: Look into other errors that can be thrown
      if (error.isAxiosError) {
        msg = error.response.data.message as string;
      }

      this.handleError('An error occured while clearing notification: ' + msg);
    }
  }

  async readNotification(notificationId: string, isRead: boolean) {
    this.dispatch(updateLoadingState(true));
    try {
      if (isRead) return;
      await this.api.readNotification(notificationId, true);

      this.dispatch(readNotification({ notificationId }));
      // Remove from redux state
      this.handleSuccess();
    } catch (error: any) {
      let msg: string = '';

      // Extract the error message from AxiosErrors
      // TODO: Look into other errors that can be thrown
      if (error.isAxiosError) {
        msg = error.response.data.message as string;
      }

      this.handleError('An error occured while clearing notification: ' + msg);
    }
  }

  async updateNotificationStatus(
    notificationId: string,
    isActive: boolean,
    ruleName: string,
    bumperNumber: string
  ) {
    this.dispatch(updateLoadingState(true));
    try {
      await this.api.updateNotificationStatus(notificationId, isActive);
      await this.dispatch(updateNotification({ notificationId, isActive }));
      // Remove from redux state
      this.handleSuccess(
        `Notification "${ruleName}" for Bumper ID "${bumperNumber}" is now ${
          isActive ? 'Activated' : 'Suspended'
        }`
      );
    } catch (error: any) {
      let msg: string = '';

      // Extract the error message from AxiosErrors
      // TODO: Look into other errors that can be thrown
      if (error.isAxiosError) {
        msg = error.response.data.message as string;
      }

      this.handleError('An error occured while updating notification: ' + msg);
    }
  }

  async getAllLatest() {
    let id: NodeJS.Timeout | null | undefined = null;
    this.dispatch(updateLoadingState(true));
    try {
      const fetchLatestNotifications = async () => {
        let notifications = await this.api.getAllNotifications();

        // Start short polling with a delay
        const pollData = async () => {
          notifications = await this.api.getAllNotifications();

          this.dispatch(setNotifications(notifications));
          // Fetch data again after 30 minutes
          id = setTimeout(pollData, 1800000);
          this.dispatch(updateNotificationPollingState(id));
        };

        // Initial of 30 min delay before first data fetch
        id = setTimeout(pollData, 1800000);
        this.dispatch(updateNotificationPollingState(id));

        return notifications;
      };

      const notifications = await fetchLatestNotifications();

      this.dispatch(setNotifications(notifications));
      this.handleSuccess();
    } catch (error: any) {
      let msg: string = '';

      if (error.isAxiosError) {
        msg = error.response.data.message as string;
      }

      this.handleError(
        'An error occured while retrieving the notifications list: ' + msg
      );
    }
  }
}
