import { type Asset } from 'types/asset.types';
import {
  type AssetsOverview,
  type ServiceView,
  type AssetDetail,
  type EolDates,
} from 'types/survivalAnalysis.types';
import { addLiveDataToAssets } from 'views/FleetOverview/helpers/asset.helpers';
export const processDataAssets = (
  items: AssetsOverview[],
  assets: Asset[],
  eolDates: EolDates[]
): ServiceView[] => {
  const result = items.reduce<Record<string, ServiceView>>((acc, item) => {
    const assetId = item.asset_id;
    const assetInfo: Asset[] = assets.filter(
      (asset) => asset.bumperNumber === assetId
    );

    if (assetInfo.length > 0) {
      const asset = assetInfo[0]; // Assuming asset_id is unique, so we take the first found asset

      if (!acc[assetId]) {
        acc[assetId] = {
          item_id: assetId,
          eol: 0,
          high: 0,
          med: 0,
          low: 0,
          componentsCategories: new Set<string>(),
          subComponentCategories: new Set<string>(),
          date_of_orders: item.date_of_orders,
          work_order_age_in_days: item.work_order_age_in_days,
          work_order_age_category: item.work_order_age_category,
          components_category: item.components_category,
          sub_component: '',
          type: asset.assetType,
          oem: asset.make,
          model: asset.model,
          lastConnected:
            asset.device.ingestDate instanceof Date
              ? asset.device.ingestDate.toISOString()
              : typeof asset.device.ingestDate === 'string'
              ? new Date(asset.device.ingestDate).toISOString()
              : '',
          componentsCategoryAssets: new Set<AssetDetail>(),
          eolDate: item.eolDate ?? '',
        };
      }

      const category = item.work_order_age_category;
      if (category && ['high', 'med', 'low'].includes(category)) {
        acc[assetId][category] = Number(acc[assetId][category] ?? 0) + 1;
      }

      // Check for EOL existence based on asset_id, components_category, and sub_components_category
      const eolExists = eolDates.some(
        (eolDate) =>
          eolDate.asset_id === assetId &&
          eolDate.components_category === item.components_category &&
          eolDate.sub_components_category === item.sub_component &&
          eolDate.eol // Ensure eol is defined
      );

      // Increment eol if exists
      if (eolExists && category && ['eol'].includes(category)) {
        acc[assetId].eol = Number(acc[assetId].eol ?? 0) + 1;
      }

      if (category !== 'eol' || eolExists) {
        if (acc[assetId].componentsCategories && item.components_category) {
          acc[assetId].componentsCategories.add(item.components_category);
        }
        if (acc[assetId].subComponentCategories && item.sub_component) {
          acc[assetId].subComponentCategories.add(item.sub_component);
        }
      }
    }

    return acc;
  }, {});

  return Object.values(result);
};

export const processAssetsWithLiveData = (
  assets: any[],
  latestTagData: any,
  notificationsData: any
): any[] => {
  const assetsWithLiveData = addLiveDataToAssets(
    assets,
    latestTagData,
    notificationsData
  );

  const mapAssetsWithLiveData = mapLiveDataToAssets(assets, assetsWithLiveData);

  return mapAssetsWithLiveData;
};

export const mapLiveDataToAssets = (
  assets: Asset[],
  liveData: any[]
): Asset[] => {
  const liveDataMap = liveData.reduce<Record<string, any>>((acc, data) => {
    acc[data.bumperNumber] = data;
    return acc;
  }, {});
  return assets.map((asset) => {
    const assetLiveData = liveDataMap[asset.bumperNumber];
    if (assetLiveData) {
      return {
        ...asset,
        device: {
          ...asset.device,
          ingestDate: assetLiveData.device.ingestDate,
          // Map any other relevant live data fields here
        },
      };
    }

    return asset;
  });
};

export const getComponentFromEol = (component: any, eolDates: EolDates[]) => {
  if (eolDates) {
    const filtered = eolDates.filter(
      (item) =>
        item.asset_id === component.asset_id &&
        item.components_category === component.components_category &&
        item.sub_components_category === component.sub_component
    );
    return filtered[0];
  }
};
