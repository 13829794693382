import React from 'react';
import { Box, LinearProgress, Typography } from '@mui/material';
import { ThemePalette } from 'mui.theme';

interface Props {
  enableDarkTheme: boolean;
}

export const FileRequirements: React.FC<Props> = ({
  enableDarkTheme = false,
}) => {
  return (
    <Box
      sx={{
        backgroundColor: enableDarkTheme
          ? ThemePalette.dark.pageBackground
          : ThemePalette.light.pageBackground,
        padding: '10px',
        marginTop: '10px',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Typography
        variant="h6"
        sx={{
          marginBottom: '10px',
          color: enableDarkTheme
            ? ThemePalette.typography.white
            : ThemePalette.typography.black,
        }}
      >
        File Upload and Mapping Process
      </Typography>
      <Typography
        sx={{
          color: enableDarkTheme
            ? ThemePalette.typography.white
            : ThemePalette.typography.black,
        }}
      >
        To ensure a smooth upload and mapping process, please follow these steps
        and requirements:
      </Typography>
      <ul
        style={{
          color: enableDarkTheme
            ? ThemePalette.typography.white
            : ThemePalette.typography.black,
        }}
      >
        <li>
          Ensure your CSV file is correctly formatted and contains headers.
        </li>
        <li>
          Check that all required fields are present in your CSV file. The
          required fields are:
        </li>
        <ul>
          <li>work_order_number: Unique identifier for each work order.</li>
          <li>
            asset_id: Identifier for the asset associated with the work order.
          </li>
          <li>total_cost: Total cost associated with the work order.</li>
          <li>
            date_of_orders: Date when the order was placed (Format: MM/DD/YYYY).
          </li>
          <li>
            date_of_completion: Actual completion date of the order (Format:
            MM/DD/YYYY).
          </li>
          <li>
            category: Category or classification of the work order (Optional).
          </li>
          <li>
            description: Detailed description of the work order (Optional).
          </li>
        </ul>
      </ul>
      <Typography
        sx={{
          color: enableDarkTheme
            ? ThemePalette.typography.white
            : ThemePalette.typography.black,
        }}
      >
        After selecting your file, you will map your file&apos;s headers to the
        required headers. Once mapped, a new CSV file with the updated headers
        and data will be created and sent to the backend.
      </Typography>
      <Typography
        sx={{
          color: enableDarkTheme
            ? ThemePalette.typography.white
            : ThemePalette.typography.black,
        }}
      >
        Please ensure that your file meets these requirements and follows the
        specified format to avoid upload errors or data processing issues.
      </Typography>
    </Box>
  );
};

export const downloadTemplate = () => {
  const csvContent =
    'data:text/csv;charset=utf-8,' + requiredHeaders.join(',') + '\r\n';

  const encodedUri = encodeURI(csvContent);
  const link = document.createElement('a');
  link.setAttribute('href', encodedUri);
  link.setAttribute('download', 'work_orders_template.csv');
  document.body.appendChild(link);

  link.click();
  document.body.removeChild(link);
};

export interface RequiredHeader {
  name: string;
  required: boolean;
}

export const requiredHeaders: RequiredHeader[] = [
  { name: 'work_order_number', required: true },
  { name: 'work_order_status', required: false },
  { name: 'asset_id', required: true },
  { name: 'labour_cost', required: false },
  { name: 'part_cost', required: false },
  { name: 'total_cost', required: true },
  { name: 'date_of_orders', required: true },
  { name: 'date_of_completion', required: false },
  { name: 'category', required: false },
  { name: 'description', required: true },
];

export const SuccessComponent = () => {
  const [showLoadingBar, setShowLoadingBar] = React.useState(true);

  React.useEffect(() => {
    const timer = setTimeout(() => {
      setShowLoadingBar(false); // Hide the loading bar after 4 seconds
    }, 4000);

    return () => {
      clearTimeout(timer);
    }; // Clean up the timer when the component unmounts or rerenders
  }, []);

  return (
    <Box
      sx={{
        backgroundColor: '#e8f5e9', // A light green background or any color you prefer
        padding: '20px',
        margin: '20px 0',
        borderRadius: '4px',
      }}
    >
      <Typography variant="h6" sx={{ color: '#2e7d32' }}>
        Upload Successful!
      </Typography>
      {showLoadingBar && (
        <Box sx={{ marginTop: '10px' }}>
          <LinearProgress />
        </Box>
      )}
    </Box>
  );
};

export const ErrorComponent = () => (
  <Box
    sx={{
      backgroundColor: '#ffebee', // A light red background or any color you prefer
      padding: '20px',
      margin: '20px 0',
      borderRadius: '4px',
    }}
  >
    <Typography variant="h6" sx={{ color: '#d32f2f' }}>
      Upload Failed
    </Typography>
    <Typography>
      There was a problem processing your file. Please check the format and try
      again.
    </Typography>
    <Typography>If the problem persists, please contact the admin.</Typography>
  </Box>
);
