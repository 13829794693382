import LightModeIcon from '@mui/icons-material/LightMode';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import { Switch } from '@mui/material';
import ExternalUserHandler from 'handlers/external.user.handler';

export const ThemeToggle = ({
  enableDarkTheme,
}: {
  enableDarkTheme: boolean;
}) => {
  const externalUserHandler = new ExternalUserHandler();
  /** Handle Click to update toggle */
  const handleClick = async () => {
    const themePayload = {
      theme: enableDarkTheme ? 'light' : 'dark',
    };
    await externalUserHandler.updateCurrentCustomerTheme(themePayload);
  };

  return (
    <>
      {enableDarkTheme ? (
        <>
          <LightModeIcon
            sx={{
              marginLeft: '2px',
              color: '#FFFFFF',
            }}
          />
        </>
      ) : (
        <></>
      )}
      <Switch
        onClick={handleClick}
        checked={!!enableDarkTheme}
        color="secondary"
      />
      {enableDarkTheme ? (
        <></>
      ) : (
        <>
          <DarkModeIcon
            sx={{
              marginLeft: '2px',
            }}
          />
        </>
      )}
    </>
  );
};
