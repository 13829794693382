/*
 *
 * @param {number} userCustomerCount
 * @param {number} totalCustomerCount
 * @returns {string}
 *
 * @description
 * Calculates the customer access level for a user based on the number of
 * customers they have access to and the number of customers they requested
 * access to.
 *
 * no-access if no customers or no requested customers
 * full-access if all customers are requested and userCustomerCount > 0 and
 * totalCustomerCount > 0
 * custom if userCustomerCount > 0 and totalCustomerCount > 0 and
 * totalCustomerCount < userCustomerCount
 */
export const calculateCustomerPermissionLevel = (
  userCustomerCount: number,
  totalCustomerCount: number
) => {
  if (userCustomerCount === 0 || totalCustomerCount === 0) {
    return 'no-access';
  }

  if (userCustomerCount === totalCustomerCount) {
    return 'full-access';
  }

  return 'custom-access';
};
