import { List, ListItem, Button } from '@mui/material';
import { ListItemText } from '../NotificationList.styled';
import { getModalHeaderStyle } from '../../helpers/common';
import { ThemePalette } from 'mui.theme';

interface Props {
  disabledPages: number[];
  enableDarkTheme: boolean;
}

export const RuleModalHeader = ({
  disabledPages,
  enableDarkTheme,
}: Props): JSX.Element => {
  const generalInfoStyle = getModalHeaderStyle(disabledPages, 1);
  const conditionsStyle = getModalHeaderStyle(disabledPages, 2);
  const confirmationStyle = getModalHeaderStyle(disabledPages, 3);

  return (
    <List
      style={{
        display: 'flex',
        flexDirection: 'row',
        padding: 0,
      }}
    >
      <ListItem>
        <Button
          disableRipple
          sx={{
            ...generalInfoStyle,
            ...(enableDarkTheme && {
              color: ThemePalette.typography.white,
            }),
          }}
        >
          1
        </Button>
        <ListItemText
          style={{
            ...(enableDarkTheme && {
              color: ThemePalette.typography.white,
            }),
          }}
        >
          {' '}
          General Info
        </ListItemText>
      </ListItem>
      <ListItem>
        <Button
          disableRipple
          sx={{
            ...conditionsStyle,
            ...(enableDarkTheme && {
              color: ThemePalette.typography.white,
            }),
          }}
        >
          2
        </Button>
        <ListItemText
          style={{
            ...(enableDarkTheme && {
              color: ThemePalette.typography.white,
            }),
          }}
        >
          {' '}
          Conditions
        </ListItemText>
      </ListItem>
      <ListItem>
        <Button
          disableRipple
          sx={{
            ...confirmationStyle,
            ...(enableDarkTheme && {
              color: ThemePalette.typography.white,
            }),
          }}
        >
          3
        </Button>
        <ListItemText
          style={{
            ...(enableDarkTheme && {
              color: ThemePalette.typography.white,
            }),
          }}
        >
          {' '}
          Confirm
        </ListItemText>
      </ListItem>
    </List>
  );
};
