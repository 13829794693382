import { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import { Box } from '@mui/material';
import ComposedChartComponent from 'components/Charts/ComposedChart';
import type {
  FuelConsumptionRangeAnalyticsItem,
  FuelConsumptionRangeAnalytics,
} from 'types/fuelAnalytics';
import NoChartData from 'views/FuelAnalytics/NoChart';
import FuelConsumptionRangeHeader from './FuelConsumptionRangeHeader';
import Colors from 'views/Settings/ExternalUsers/Squares/Helpers/Graphs/styles';
import { getQuarter } from './Utils';
import type { ResponsePrice } from 'types/fuelPrice.types';
import { useAppSelector } from 'store/hook';
import {
  fuelConsumptionLabels,
  getFuelLabelWrtUnitSystem,
} from 'utils/helpers/labels';

interface Props {
  data: FuelConsumptionRangeAnalytics;
  assetMakeModel: string;
  isLoading: boolean;
  selectedConsumptionRange: string;
  setSelectedConsumptionRange: any;
  fuelPriceData: ResponsePrice;
  enableDarkTheme: boolean;
}

const FuelConsumptionRange = ({
  data,
  isLoading,
  assetMakeModel,
  selectedConsumptionRange,
  setSelectedConsumptionRange,
  fuelPriceData,
  enableDarkTheme = false,
}: Props) => {
  const unitSystem = useAppSelector((state) => state.persistedReducer).customer
    .unitSystem;
  const assetMakeModelParts: string[] = assetMakeModel.split('!');
  const assetMakeModelPartString = `${assetMakeModelParts[0]} ${assetMakeModelParts[1]}`;
  const [unit, setUnit] = useState<boolean>(false);
  const [currentPrice, setCurrentPrice] = useState<{
    dieselPrice: number;
    gasPrice: number;
  }>({ dieselPrice: 0, gasPrice: 0 });
  const [chartData, setChartData] = useState<any>([]);
  const [barKeys] = useState<any>([
    {
      name: 'Total Consumption',
      color: Colors.green,
      label: `Total Fuel Consumption/ Mo (1000 ${
        unitSystem === 'metric' ? 'L/mo' : 'Gal/Mo'
      })`,
    },
  ]);

  const [extraBarKeys, setExtraBarKeys] = useState<any>([
    {
      name: `CO2 consumption`,
      color: '#818589',
      label: `CO2 consumption in ${getFuelLabelWrtUnitSystem(
        unitSystem,
        fuelConsumptionLabels.CO2
      )}`,
    },
  ]);

  useEffect(() => {
    if (data?.length) {
      setChartData(
        formatData(
          data,
          selectedConsumptionRange,
          assetMakeModelPartString,
          currentPrice,
          fuelPriceData,
          unitSystem
        )
      );
    }
  }, [data, currentPrice]);

  let xAxisDataKey = 'customer_month';
  if (selectedConsumptionRange.includes('quarter')) {
    xAxisDataKey = 'customer_quarter';
  }

  return (
    <Box
      sx={{
        marginRight: '4px',
        marginBottom: '10px',
        paddingTop: '20px',
      }}
    >
      <FuelConsumptionRangeHeader
        assetMakeModel={assetMakeModel}
        setExtraBarKeys={setExtraBarKeys}
        selectedConsumptionRange={selectedConsumptionRange}
        setSelectedConsumptionRange={setSelectedConsumptionRange}
        unit={unit}
        currentPrice={currentPrice}
        setUnit={setUnit}
        setCurrentPrice={setCurrentPrice}
        enableDarkTheme={enableDarkTheme}
      />
      <Box
        sx={{
          height: '400px',
        }}
      >
        {isLoading ? (
          <></>
        ) : chartData?.length ? (
          <ComposedChartComponent
            data={chartData}
            barKeys={[...barKeys, ...extraBarKeys]}
            lineKeys={[
              {
                name: `Average Consumption ${assetMakeModelPartString}`,
                axisName: 'Total Consumption',
                color: '#4249C9',
                strokeWidth: 2,
                strokeDashArray: '3 3',
              },
              {
                name: 'Fleet Average',
                axisName: 'Total Consumption',
                color: '#FF0000',
                strokeWidth: 2,
                strokeDashArray: '3 3',
              },
            ]}
            xAxis={xAxisDataKey}
            showLegend={true}
            enableDarkTheme={enableDarkTheme}
          />
        ) : (
          <NoChartData enableDarkTheme={enableDarkTheme} />
        )}
      </Box>
    </Box>
  );
};

export default FuelConsumptionRange;

function formatData(
  data: FuelConsumptionRangeAnalytics,
  selectedConsumptionRange: string,
  assetMakeModelPartString: string,
  currentPrice: { dieselPrice: number; gasPrice: number },
  fuelPriceData: ResponsePrice,
  unitSystem: string
) {
  const response = data.map((item: FuelConsumptionRangeAnalyticsItem) => {
    let assetCount = 0;
    if (item.num_devices) {
      assetCount = item.num_devices;
    }

    return {
      customer_month: dayjs(item.customer_month).format('MMM YYYY'),
      customer_quarter: getQuarter(
        dayjs(item.customer_quarter).format('MMM YYYY')
      ),
      'Total Consumption': item.sum_fuel,
      [`Average Consumption ${assetMakeModelPartString}`]: item.average_fuel,
      'Dollars spent on fuel':
        item.sum_fuel *
        getFuelPrice(
          currentPrice,
          fuelPriceData,
          item.fuelSource || 'Gas',
          item.customer_quarter ? 'quarter' : 'month',
          item.customer_month,
          item.customer_quarter
        ),
      'CO2 consumption': item.CO2,
      'Number of assets': item.num_devices,
      'Fleet Average': item.fleet_average_fuel,
    };
  });

  if (selectedConsumptionRange.includes('quarter')) {
    return response.sort((a: any, b: any) => {
      const dateA: any = new Date(a.customer_quarter);
      const dateB: any = new Date(b.customer_quarter);
      return dateA - dateB;
    });
  }
  return response.sort((a: any, b: any) => {
    const dateA: any = new Date(a.customer_month);
    const dateB: any = new Date(b.customer_month);
    return dateA - dateB;
  });
}

function getFuelPrice(
  userPrices: { dieselPrice: number; gasPrice: number },
  apiPrices: ResponsePrice,
  fuelType: string,
  duration: string,
  customerMonth: string,
  customerQuarter: string | undefined
) {
  let defaultPrice: number = 0;
  let monthToGet: string = dayjs(customerMonth).format('MMMM YYYY');
  if (customerQuarter) {
    monthToGet = dayjs(customerQuarter).endOf('quarter').format('MMMM YYYY');
  }

  let fuelTypePrice = apiPrices[monthToGet];

  if (!fuelTypePrice) {
    const firstKey = Object.keys(apiPrices)[0];
    fuelTypePrice = apiPrices[firstKey];
  }

  if (fuelType?.toLowerCase() === 'gas') {
    // Check user input price is 0
    const userInputPrice = userPrices.gasPrice;
    const apiInputPrice = duration.includes('quarter')
      ? fuelTypePrice?.gas?.averagePriceQuarter?.averageGasPrice
      : fuelTypePrice?.gas?.averagePriceMonth?.averageGasPrice;
    defaultPrice = userInputPrice !== 0 ? userInputPrice : apiInputPrice || 0;
  }

  if (fuelType?.toLowerCase() === 'diesel') {
    // Check user input price is 0
    const userInputPrice = userPrices.dieselPrice;
    const apiInputPrice = duration.includes('quarter')
      ? fuelTypePrice?.diesel?.averagePriceQuarter?.averageDieselPrice
      : fuelTypePrice?.diesel?.averagePriceMonth?.averageDieselPrice;
    defaultPrice = userInputPrice !== 0 ? userInputPrice : apiInputPrice || 0;
  }

  return defaultPrice;
}
