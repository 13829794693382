import { Typography, Button, Box, ButtonGroup } from '@mui/material';
import { type FC } from 'react';
import {
  createFilterInfo,
  prepareCsvFile,
  preparePdfFile,
} from './prepareFile';
import { type SelectOptions } from 'types/utils.types';
import { showNotification } from 'store/notification.slice';
import { updateLoadingState } from 'store/app.slice';
import { useDispatch } from 'react-redux';
import { type SelectedDates } from './customDatePicker';
import { ThemePalette } from 'mui.theme';

export const useNotificationHandler = () => {
  const dispatch = useDispatch();

  const handleError = (message: string) => {
    dispatch(updateLoadingState(false));
    dispatch(showNotification({ message, type: 'error' }));
  };

  const handleSuccess = (message: string) => {
    dispatch(updateLoadingState(false));
    if (message) {
      dispatch(showNotification({ message, type: 'success' }));
    }
  };

  return { handleError, handleSuccess };
};

interface TextWithIcon {
  text: string;
  color: string;
  enableDarkTheme: boolean;
}

export const TypographyWithIcon: FC<TextWithIcon> = ({
  text,
  color,
  enableDarkTheme = false,
}) => {
  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <div
        style={{
          width: '12px',
          height: '12px',
          backgroundColor: color,
          marginRight: '8px',
        }}
      ></div>
      <Typography
        variant={'body2'}
        paddingRight={1}
        sx={{
          color: enableDarkTheme
            ? ThemePalette.typography.white
            : ThemePalette.typography.black,
        }}
      >
        {text}
      </Typography>
    </div>
  );
};

interface AddToReportsProp {
  data: any[];
  selectedAssets?: { assets: SelectOptions[] } | string;
  interval?: SelectedDates;
  graphId: string;
  isCsv?: boolean;
  isPdf?: boolean;
  orientation?: string;
}

export const AddToReports: FC<AddToReportsProp> = ({
  data,
  selectedAssets,
  interval,
  graphId,
  isCsv = true,
  isPdf = true,
  orientation = '',
}) => {
  const { handleError } = useNotificationHandler();

  const downloadCsv = () => {
    const baseFileName = graphId;
    const fileCsv = prepareCsvFile(data, 'csv-' + baseFileName);

    if (!fileCsv) {
      handleError('An error occurred while creating the CSV file.');

      return;
    }
    downloadBlob(fileCsv, baseFileName + '.csv');
  };

  const downloadPdf = async () => {
    const baseFileName = graphId; // Replace with your desired base file name
    const title = graphId; // Add your title here
    let filterInfo = '';
    if (data && interval) {
      const filterAssets = createFilterInfo(data);
      if (!filterAssets) {
        filterInfo = `Interval: ${formatSelectedDates(interval)}`;
      } else {
        filterInfo = `Assets: ${filterAssets}, Interval: ${formatSelectedDates(
          interval
        )}`;
      }
      // Add your filter information here
      const filePdf = await preparePdfFile(
        graphId,
        'pdf-' + baseFileName,
        title,
        filterInfo
      );
      if (!filePdf) {
        handleError('An error occurred while creating the PDF file.');

        return;
      }
      downloadBlob(filePdf, baseFileName + '.pdf');
    }
  };

  const downloadBlob = (blob: Blob | MediaSource, filename: string) => {
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = filename || 'download';
    a.click();
    URL.revokeObjectURL(url);
  };
  return (
    <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
      <ButtonGroup
        variant="contained"
        aria-label="Vertical button group"
        orientation={orientation === 'vertical' ? 'vertical' : 'horizontal'}
      >
        {isCsv && (
          <Button
            sx={{
              backgroundColor: 'button.primary',
              fontSize: '10px',
              borderRadius: isPdf ? '4px 4px 4px 4px' : '4px', // round all corners if PDF button is not shown
            }}
            onClick={downloadCsv}
          >
            CSV
          </Button>
        )}
        {isPdf && (
          <Button
            sx={{
              backgroundColor: 'button.secondary', // Different color to visually split the button
              fontSize: '10px',
              borderRadius: isCsv ? '4px 4px 4px 4px' : '4px', // round all corners if CSV button is not shown
            }}
            onClick={downloadPdf}
          >
            PDF
          </Button>
        )}
      </ButtonGroup>
    </Box>
  );
};

const formatDate = (date: Date | null): string => {
  if (!date) return '';
  // Format date as YYYY-MM-DD
  return date.toISOString().split('T')[0];
};

const formatSelectedDates = (selectedDates: SelectedDates): string => {
  const { startDate, endDate } = selectedDates;
  const formattedStartDate = formatDate(startDate);
  const formattedEndDate = formatDate(endDate);

  // Handle cases where either date might be null
  if (!formattedStartDate && !formattedEndDate) return '';
  if (!formattedStartDate) return `-${formattedEndDate}`;
  if (!formattedEndDate) return `${formattedStartDate}-`;

  return `${formattedStartDate} / ${formattedEndDate}`;
};
