import React, { useState } from 'react';
import './GetIcons.css';
import { ThemePalette } from 'mui.theme';

export const GetIcons: React.FC<{
  icon: string;
  enableDarkTheme: boolean;
}> = ({ icon, enableDarkTheme }) => {
  return (
    <div className="image-container">
      <img
        src={icon}
        alt={icon}
        className="image"
        style={{
          filter: enableDarkTheme
            ? ThemePalette.typography.white
            : ThemePalette.typography.grey,
        }}
      />
    </div>
  );
};
