import { type SxProps, Tooltip } from '@mui/material';
import { useRef } from 'react';

interface Props {
  title: string;
  placement?:
    | 'bottom-end'
    | 'bottom-start'
    | 'bottom'
    | 'left-end'
    | 'left-start'
    | 'left'
    | 'right-end'
    | 'right-start'
    | 'right'
    | 'top-end'
    | 'top-start'
    | 'top';

  children: JSX.Element;
  sx?: SxProps;
}

export const SWToolTip = ({
  title,
  placement = 'top',
  children,
  sx,
}: Props) => {
  const ref = useRef(null);

  return (
    <Tooltip
      ref={ref}
      title={title}
      placement={placement}
      arrow
      sx={{
        borderRadius: '4px',
        padding: '5px',
        ...sx,
      }}
    >
      {children}
    </Tooltip>
  );
};
