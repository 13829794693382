import * as yup from 'yup';

export const updateDeviceRequestBodySchema = yup
  .object({
    body: yup.object({
      yOrientation: yup.string().required(),
      xOrientation: yup.string().required(),
      modelNumber: yup.string().nullable(),
      hardwareConfigurationCode: yup.string().nullable(),
    }),
  })
  .required();
