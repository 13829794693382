import React from 'react';
import ReactDOM from 'react-dom/client';
import { type AppState, Auth0Provider } from '@auth0/auth0-react';
import authClientOptions from 'utils/config/auth0.config';

import './index.css';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import '@fontsource/montserrat';
import '@fontsource/open-sans';
import '@fontsource/metrophobic';
import '@fontsource-variable/inter';

import App from 'App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { store, persistor } from 'store';
import { PersistGate } from 'redux-persist/integration/react';

import { BrowserRouter, useNavigate } from 'react-router-dom';
import { type BaseProps } from 'types/props.base';
import ThemeProvider from '@mui/material/styles/ThemeProvider';
import { theme } from 'mui.theme';
import ConditionalPageLoader from 'components/ConditionalPageLoader';

export const Auth0ProviderWithNavigate = ({ children }: BaseProps) => {
  const navigate = useNavigate();
  const { domain, clientId, redirectURI, audience } = authClientOptions;

  const onRedirectCallback = (appState?: AppState | undefined) => {
    navigate(appState?.returnTo ?? window.location.pathname, {
      replace: true,
    });
  };

  if (!(domain && clientId && redirectURI)) {
    return null;
  }

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      authorizationParams={{
        audience,
        redirect_uri: redirectURI,
        scope: 'profile email read:users',
      }}
      onRedirectCallback={onRedirectCallback}
      cacheLocation={(window as any).Cypress ? 'localstorage' : 'memory'}
    >
      {children}
    </Auth0Provider>
  );
};

document.addEventListener('DOMContentLoaded', function () {
  const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
  );

  root.render(
    <React.StrictMode>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <BrowserRouter>
            <Auth0ProviderWithNavigate>
              <ThemeProvider theme={theme}>
                <ConditionalPageLoader />
                <App />
              </ThemeProvider>
            </Auth0ProviderWithNavigate>
          </BrowserRouter>
        </PersistGate>
      </Provider>
    </React.StrictMode>
  );
});

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
