// create a user slice in src/store/user.slice.ts

import { createSlice } from '@reduxjs/toolkit';
import { type InternalUser } from 'types/user.types';

export interface InternalUserState {
  internalUserList: InternalUser[];
  error: string | null;
}

const initialState: InternalUserState = {
  internalUserList: [],
  error: null,
};

// creaet a user slice
export const internalUserSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setInternalUserList(state, { payload }: { payload: InternalUser[] }) {
      state.internalUserList = payload;
    },
    // add reducers here
    appendInternalUserList(state, { payload }: { payload: InternalUser }) {
      state.internalUserList.push(payload);
    },
    // update the user list with the payload
    // where the payload is an updated user
    updateInternalUserList(state, { payload }: { payload: InternalUser }) {
      const index = state.internalUserList.findIndex(
        (user) => user.userId === payload.userId
      );

      if (index === -1) {
        // do not update the state if the user is not found
        return;
      }

      state.internalUserList[index] = payload;
    },
    removeInternalUserFromList(state, { payload }: { payload: InternalUser }) {
      state.internalUserList = state.internalUserList.filter(
        // filter out the user that matches the payload
        (user) => user.userId !== payload.userId
      );
    },
    resetInternalUserState() {
      return initialState;
    },
  },
});

export const {
  resetInternalUserState,
  appendInternalUserList,
  setInternalUserList,
  updateInternalUserList,
  removeInternalUserFromList,
} = internalUserSlice.actions;

export default internalUserSlice.reducer;
