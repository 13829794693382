import { useAppSelector } from 'store/hook';

import useTheme from '@mui/material/styles/useTheme';
import FadeLoader from 'react-spinners/FadeLoader';
import { isDarkTheme } from 'utils/theme';
import { ThemePalette } from 'mui.theme';

export const PageLoader = () => {
  const {
    palette: { primary },
  } = useTheme();

  const { isLoading } = useAppSelector((state) => state.appReducer);
  const theme = useAppSelector((state) => state.authReducer).customer.theme;
  const enableDarkTheme = isDarkTheme(theme);

  if (!isLoading) {
    return null;
  }

  return (
    <div
      className="loader"
      style={{
        justifyContent: 'center',
        alignItems: 'center',
        justifyItems: 'center',
        display: 'flex',
        height: '100vh',
        backgroundColor: 'transparent',
        position: 'absolute',
        width: '100%',
        zIndex: 9999,
      }}
    >
      <FadeLoader
        color={enableDarkTheme ? ThemePalette.typography.white : primary.main}
        loading={isLoading}
      />
    </div>
  );
};
