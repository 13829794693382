import * as us from './helpers/unitSystem';

// import * as us from './unitSystem';
export const COLORS = {
  red: '#A03434',
  yellow: '#F1C232',
  grey: '#77859F',
  green: '#749A4A',
};

export const INTERNAL_USER_DOMAIN = '@symboticware.com';
export const USER_DEPARTMENT_OPTIONS = [
  {
    id: 'product',
    display: 'Product',
  },
  { id: 'sales', display: 'Sales' },
  { id: 'other', display: 'Other' },
  { id: 'customer_service', display: 'Customer Service' },
];

export const USER_PERMISSION_LEVEL_OPTIONS = [
  {
    id: 'external-user',
    display: 'User',
  },
  {
    id: 'external-admin',
    display: 'Admin',
  },
];

export const CUSTOMER_TYPE_OPTIONS = [
  {
    id: 'external-customer',
    display: 'External',
  },
  {
    id: 'internal-customer',
    display: 'Internal',
  },
  {
    id: 'third-party-customer',
    display: 'Third Party',
  },
];

export const USER_DEPARTMENT_MAP = {
  product: 'Product',
  sales: 'Sales',
  other: 'Other',
  customer_service: 'Customer Service',
};

export const ASSET_TYPES = [
  {
    id: 'light_truck',
    display: 'LT - Light Truck',
  },
  {
    id: 'heavy_truck',
    display: 'HT - Heavy Truck',
  },
  {
    id: 'haul_truck',
    display: 'HL - Haul Truck',
  },
  {
    id: 'dozer',
    display: 'DO - Dozer',
  },
  {
    id: 'grader',
    display: 'GR - Grader',
  },
  {
    id: 'loader',
    display: 'LO - Loader',
  },
  {
    id: 'excavator',
    display: 'EX - Excavator',
  },
  {
    id: 'drill',
    display: 'DR - Drill',
  },
  {
    id: 'bolter',
    display: 'BO - Bolter',
  },
  {
    id: 'miscellaneous',
    display: 'MI - Miscellaneous',
  },
];
export const FUEL_TYPES = [
  {
    id: 'diesel',
    display: 'Diesel',
  },
  {
    id: 'electric',
    display: 'Electric',
  },
  {
    id: 'unknown',
    display: 'Unknown',
  },
  {
    id: 'gas',
    display: 'Gas',
  },
];

export const ASSET_OPERATIONAL_STATUS = {
  operational: 'Operational',
  'out-of-service': 'Out Of Service',
};

export const DEVICE_ORIENTATION = [
  {
    id: 'front',
    display: 'Towards the front of the vehicle',
  },
  {
    id: 'back',
    display: 'Towards the back of the vehicle',
  },
  {
    id: 'left',
    display: 'Towards the left side',
  },
  {
    id: 'right',
    display: 'Towards the right side',
  },
  {
    id: 'ground',
    display: 'Towards the ground',
  },
  {
    id: 'sky',
    display: 'Towards the sky',
  },
];

export const TIME_UNIT_OPTIONS = [
  {
    id: 'seconds',
    display: 'Seconds',
  },
  {
    id: 'minutes',
    display: 'Minutes',
  },
  {
    id: 'hours',
    display: 'Hours',
  },
  {
    id: 'days',
    display: 'Days',
  },
];

export const TAG_OPERATORS = [
  {
    id: '>',
    display: '>',
  },
  {
    id: '>=',
    display: '>=',
  },
  {
    id: '=',
    display: '=',
  },
  {
    id: '<=',
    display: '<=',
  },
  {
    id: '<',
    display: '<',
  },
];

export const NOTIFICATION_LEVEL = [
  {
    id: '1',
    display: 'Low Severity',
    color: COLORS.grey,
  },
  {
    id: '2',
    display: 'Medium Severity',
    color: COLORS.yellow,
  },
  {
    id: '3',
    display: 'High Severity',
    color: COLORS.red,
  },
];

export const AVG_VAL = [
  {
    id: 'avg_val',
    display: 'Avg Value',
  },
  {
    id: 'max_val',
    display: 'Max Value',
  },
  {
    id: 'min_val',
    display: 'Min Value',
  },
];
export const NOTIFICATION_DELIVERY = {
  email: 'Email',
  applicationUi: 'Application UI',
  notificationCenter: 'Notification Center',
};

interface units {
  display: string;
  imperial: string;
  id: string;
  method?: any | null;
  toMetric?: any | null;
}

export function getUnits(units: units[], unitSystem: string): units[] {
  units.forEach((unit) => {
    if (unitSystem === 'imperial') {
      unit.display = unit.imperial;
    }
  });

  return units;
}

export const UNITS: units[] = [
  {
    display: 'N/A',
    imperial: 'N/A',
    id: ' ',
    method: null,
  },
  {
    display: 'KMH',
    imperial: 'MPH',
    id: 'KMH',
    method: us.convertKmsToMiles,
    toMetric: us.convertMilesToKms,
  },
  {
    display: 'BAR',
    imperial: 'PSI',
    id: 'BAR',
    method: us.convertBarToPsi,
    toMetric: us.convertPsiToBar,
  },
  {
    display: 'KPA',
    imperial: 'PSI',
    id: 'KPA',
    method: us.convertKpaToPsi,
    toMetric: us.convertPsiToKpa,
  },
  {
    display: 'C',
    imperial: 'F',
    id: 'C',
    method: us.convertCentToFahr,
    toMetric: us.convertFahrToCent,
  },
  {
    display: 'RPM',
    imperial: 'RPM',
    id: 'RPM',
  },
  {
    display: '%',
    imperial: '%',
    id: '%',
  },
  {
    display: 'L',
    imperial: 'Gal',
    id: 'L',
    method: us.convertLitersToGallons,
    toMetric: us.convertGallonsToLiters,
  },
  {
    display: 'LPH',
    imperial: 'GPM',
    id: 'LPH',
    method: us.convertLphToGpm,
    toMetric: us.convertGpmToLph,
  },
  {
    display: 'HR',
    imperial: 'HR',
    id: 'HR',
  },
  {
    display: 'KM',
    imperial: 'Miles',
    id: 'KM',
    method: us.convertKmsToMiles,
    toMetric: us.convertMilesToKms,
  },
  {
    display: 'MINUTES',
    imperial: 'MINUTES',
    id: 'MINUTES',
  },
  {
    display: 'COUNT',
    imperial: 'COUNT',
    id: 'COUNT',
  },
  {
    display: 'HRS',
    imperial: 'HRS',
    id: 'HRS',
  },
  {
    display: 'TON',
    imperial: 'TON',
    id: 'TON',
    method: us.convertMetricTonsToImperialTons,
    toMetric: us.convertImperialTonsToMetricTons,
  },
  {
    display: 'MTON',
    imperial: 'ITON',
    id: 'MTON',
    method: us.convertMetricTonsToImperialTons,
    toMetric: us.convertImperialTonsToMetricTons,
  },
  {
    display: 'PPM',
    imperial: 'PPM',
    id: 'PPM',
  },
  {
    display: 'AMPS',
    imperial: 'AMPS',
    id: 'A',
  },
  {
    display: 'VDC',
    imperial: 'VDC',
    id: 'VDC',
  },
  {
    display: 'DATE/TIME',
    imperial: 'DATE/TIME',
    id: 'DATE/TIME',
  },
  {
    display: 'DD/DM',
    imperial: 'DD/DM',
    id: 'DD/DM',
  },
  {
    display: 'DMS',
    imperial: 'DMS',
    id: 'DMS',
    // method: askLuci,
  },
  {
    display: 'M/S2',
    imperial: 'M/S2',
    id: 'M/S2',
    method: us.convertMs2ToFtS2,
    toMetric: us.convertFtS2ToMs2,
  },
  {
    display: 'CM/S2',
    imperial: 'CM/S2',
    id: 'CM/S2',
  },
  {
    display: 'MM/S2',
    imperial: 'MM/S2',
    id: 'MM/S2',
  },
  {
    display: 'HZ',
    imperial: 'HZ',
    id: 'HZ',
  },
  {
    display: 'SECONDS',
    imperial: 'SECONDS',
    id: 'SECONDS',
  },
];

export const CUSTOMERS_WITH_NO_GPS = [
  {
    id: 'aba03128-4cff-11ee-be56-0242ac120002',
    name: 'Mississippi Lime',
  },
  {
    id: '3f9879f0-5906-11ee-8c99-0242ac120002',
    name: 'Grupo Bacis',
  },
];
