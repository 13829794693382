/* eslint-disable @typescript-eslint/restrict-template-expressions */
import * as React from 'react';
import { Cartesian3, Color, Ion } from 'cesium';
import { Viewer, Entity, CameraFlyTo } from 'resium';

import { type Asset } from 'types/asset.types';
import {
  ASSET_TYPES,
  COLORS,
  CUSTOMERS_WITH_NO_GPS,
  NOTIFICATION_LEVEL,
} from 'utils/enums';
import NoChartData from 'views/FuelAnalytics/NoChart';
import { handleAssetStatus } from 'views/Settings/ExternalUsers/AssetView/helpers/common';
import { getEnvVariable } from 'utils/helpers/getEnvVariable';
import { useAppSelector } from 'store/hook';
import { truncateTo2dp } from 'utils/helpers/general';
import { selectAssetsWithExcessiveIdling } from 'store/asset.slice';
import { isDarkTheme } from 'utils/theme';
import useFullscreenChange from 'hooks/geoMaps';

interface Props {
  filteredAssets: Asset[];
  searchValue: string;
}
export const dummyCredit = document.createElement('div');

const AssetMap = ({ filteredAssets, searchValue }: Props) => {
  const customerId = useAppSelector((state) => state.persistedReducer).customer
    .id;

  const assetsWithExccessiveIdling = useAppSelector(
    selectAssetsWithExcessiveIdling
  );
  const theme = useAppSelector((state) => state.authReducer).customer.theme;
  const enableDarkTheme = isDarkTheme(theme);
  if (CUSTOMERS_WITH_NO_GPS.find((item) => item.id === customerId)) {
    return (
      <NoChartData
        message={
          'GPS mapping is not available for this site at this time. Please Contact 1-800-519-5496 for assistance.'
        }
        enableDarkTheme={enableDarkTheme}
      />
    );
  }
  const [filteredArray, setFilteredArray] = React.useState(filteredAssets);
  const viewerRef = React.useRef<any>(null);
  React.useEffect(() => {
    const filteredData = filteredAssets.filter(
      (asset) =>
        asset.assetType.toLowerCase().includes(searchValue.toLowerCase()) ||
        asset.make.toLowerCase().includes(searchValue.toLowerCase()) ||
        asset.model.toLowerCase().includes(searchValue.toLowerCase())
    );

    setFilteredArray(filteredData);
  }, [searchValue, filteredAssets]);

  useFullscreenChange(viewerRef);

  const assetList: Asset[] = [];

  Ion.defaultAccessToken = getEnvVariable(
    'REACT_APP_CESIUM_ION_DEFAULT_ACCESS_TOKEN'
  );

  filteredArray.map((asset) => {
    if (asset?.liveTagData?.gps_latitude && asset?.liveTagData?.gps_longitude) {
      return assetList.push(asset);
    } else {
      return assetList;
    }
  });

  if (assetList.length < 1) {
    return (
      <NoChartData
        message="No GPS data available for the listed assets"
        enableDarkTheme={enableDarkTheme}
      />
    );
  }

  const sumLongitude: number = assetList.reduce(
    (total: number, obj: Record<string, any>) => {
      return total + Number(obj?.liveTagData?.gps_longitude);
    },
    0
  );
  const sumLatitude: number = assetList.reduce(
    (total: number, obj: Record<string, any>) => {
      return total + Number(obj?.liveTagData?.gps_latitude);
    },
    0
  );
  const deviceCount = assetList.filter(
    (obj: Record<string, any>) => obj?.liveTagData?.gps_latitude !== undefined
  ).length;

  const averageLongitude = deviceCount > 0 ? sumLongitude / deviceCount : 0;
  const averageLatitude = deviceCount > 0 ? sumLatitude / deviceCount : 0;

  return (
    <Viewer
      style={{
        position: 'inherit',
        width: '100%',
        zIndex: 100,
      }}
      ref={viewerRef}
      creditContainer={dummyCredit}
      timeline={false}
      navigationHelpButton={false}
      homeButton={false}
      animation={false}
    >
      <CameraFlyTo
        // TODO: make this fly in only when opened and not everytime the data is updated
        destination={Cartesian3.fromDegrees(
          averageLongitude,
          averageLatitude,
          10000
        )}
        duration={0}
      />
      {assetList.map((device: Asset) => {
        if (
          !device.liveTagData?.gps_longitude ||
          !device.liveTagData?.gps_latitude
        ) {
          return undefined;
        }

        const tagsForMapView = device.device.tags.filter(
          (item: any) => item.showOnFleetOverviewMap
        );

        const tags: any = [];
        tagsForMapView.map((tag) => {
          const tagFromLiveTag = device?.liveTagData?.[`${tag.tagName}`];
          tags.push([
            `${tag.tagAlias || tag.tagName}`,
            `${tagFromLiveTag}`,
            `${tag.unit}`,
          ]);
          return null;
        });

        const longitude = Number(device.liveTagData.gps_longitude);
        const latitude = Number(device.liveTagData.gps_latitude);
        const assetStatus = handleAssetStatus(device.isActive);
        const upperCaseAssetStatus = `${assetStatus
          .charAt(0)
          .toUpperCase()}${assetStatus.slice(1)}`;
        const isExcessiveIdling = assetsWithExccessiveIdling.find(
          (asset) => asset.device === device.device.deviceId
        );
        const color = isExcessiveIdling
          ? new Color(
              0.8274509803921568,
              0.3549019607843137,
              0.23725490196078433
            ) // Orange warning
          : device.notifications && device.notifications.length <= 0
          ? new Color(
              0.0, // Red component
              1.0, // Green component
              1.0 // Blue component // Blue
            ) // Cyan
          : device?.notifications?.some(
              (el: any) =>
                el.rule.notificationLevel === NOTIFICATION_LEVEL[2].id
            )
          ? new Color(
              0.6274509803921569,
              0.20392156862745098,
              0.20392156862745098
            ) // Red #A03434
          : device?.notifications?.some(
              (el: any) =>
                el.rule.notificationLevel === NOTIFICATION_LEVEL[1].id
            )
          ? new Color(
              0.9607843137254902,
              0.8607843137254902,
              0.4549019607843137
            ) // Yellow #F5C274
          : new Color(
              0.4666666666666667,
              0.5215686274509804,
              0.6235294117647059
            ); // GREY #77859F
        return (
          <div
            data-tooltip-id="my-tooltip"
            key={device.device.deviceId}
            style={{ cursor: 'pointer' }}
          >
            <Entity
              name={`${
                ASSET_TYPES.find((e) => e.id === device.assetType)?.display
              } ${device.device.assetBumperNumber}`}
              position={Cartesian3.fromDegrees(longitude, latitude)}
              point={{
                pixelSize: 15,
                color,
                outlineColor: Color.BLACK,
              }}
              description={`<span style="color:grey;">Asset Status:</span> ${upperCaseAssetStatus} 
            <br> <span style="color:grey;">Device Status:</span>   ${
              device.device.deviceStatus
            } 
            <br> <span style="color:grey;"> Asset Make/Model:</span>   ${
              device.make
            } ${device.model}
             ${tags.map(
               (tag: any) =>
                 `<br> <span style="color:grey;"> ${
                   tag[0]
                 }:</span>  ${truncateTo2dp(tag[1])} ${tag[2]}`
             )}
             
             ${
               isExcessiveIdling
                 ? `<br> <span style="color:grey;">Excess Idling:</span> <span style="color:#D37423"> ${isExcessiveIdling.excess_idle} times</span>`
                 : ''
             }

             ${
               device.notifications && device.notifications.length <= 0
                 ? `<br> <span style="color:grey;">Alert Status:</span> <span style="color:${COLORS.green}">No Alerts</span>`
                 : device?.notifications?.some(
                     (el: any) =>
                       el.rule.notificationLevel === NOTIFICATION_LEVEL[2].id
                   )
                 ? `<br> <span style="color:grey;">Alert Status:</span> <span style="color:${COLORS.red}">High Severity</span>`
                 : device?.notifications?.some(
                     (el: any) =>
                       el.rule.notificationLevel === NOTIFICATION_LEVEL[1].id
                   )
                 ? `<br>  <span style="color:grey;">Alert Status:</span> <span style="color:${COLORS.yellow}">Medium Severity</span>`
                 : `<br> <span style="color:grey;">Alert Status:</span> <span style="color:${COLORS.grey}">Low Severity</span>`
             }
            `}
              id={device.device.deviceId}
            />
          </div>
        );
      })}
    </Viewer>
  );
};

export default AssetMap;
