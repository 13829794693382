import { Typography } from '@mui/material';
import { type ColDef } from 'ag-grid-community';
import { BaseTableList } from 'components/AgGrid/AgGrid';
import { CheckboxRenderer } from 'components/AgGrid/CellRenderer/CheckboxRenderer';
import { ListWrapper } from 'components/AgGrid/CellRenderer/ListView.styled';
import { SelectorRenderer } from 'components/AgGrid/CellRenderer/SelectorRenderer';
import { WithIconHeader } from 'components/AgGrid/HeaderComponents/WithIconHeader';
import { FlexBox } from 'components/Containers/FlexBox';
import { InteractiveModal } from 'components/Modals/InteractiveModal/BaseModal';
import TagHandler from 'handlers/tag.handler';
import { useEffect, useRef, useState } from 'react';
import { selectAssetByMakeModel } from 'store/asset.slice';
import { useAppSelector } from 'store/hook';
import { selectTags } from 'store/tag.slice';
import { type ITag } from 'types/tag.type';
import { getUnits, UNITS } from 'utils/enums';
import {
  capitalizeFirstLetter,
  safeReplace,
} from 'utils/helpers/string.manipulation';
import { TabHeader } from '../components/TabHeader';
import { disableSaveButton, setOrToggleField } from './helpers';
import { MediumText } from 'components/Typography/MediumText';
import { TextFieldRenderer } from 'components/AgGrid/CellRenderer/TextFieldRenderer';
import AssetHandler from 'handlers/asset.handler';
import { ThemePalette } from 'mui.theme';

export const TagAssetMakeModelView = ({
  enableDarkTheme,
}: {
  enableDarkTheme: boolean;
}): JSX.Element => {
  const unitSystem = useAppSelector((state) => state.persistedReducer).customer
    .unitSystem;
  const tagHandler = new TagHandler();
  const assetHandler = new AssetHandler();
  const assetMakeModelList = useAppSelector(selectAssetByMakeModel);
  const deviceTagListFull = useAppSelector(selectTags);

  // list of tags to show in the UI and to  set
  const [tagList, setTagList] = useState<any[]>([]);

  const [originalTagList, setOriginalTagList] = useState<any[]>([]);

  /*
   * list of devices to set the tags for. This is a list of deviceIds
   * we need to track devices because we need to set the tags for each device
   * we can't rely on tags only as duplicate tags will be removed in the ui
   */
  const [tagListFull, setTagListFull] = useState<ITag[]>([]);
  // keep track of what we need to update
  const [tagsToUpdate, setTagsToUpdate] = useState<string[]>([]);
  // controls confirmation modal
  const [showConfirmationModal, setShowConfirmationModal] =
    useState<boolean>(false);
  const [disableSaveEditButton, setEnableSaveEditButton] =
    useState<boolean>(false);

  const [tagChanged, setTagChanged] = useState<string[]>([]);

  const nextMakeModelSelection = useRef<string>('');

  const addTagToChangeList = (
    tagName: string,
    value?: string,
    isUnit?: boolean
  ) => {
    if (tagChanged.includes(tagName)) {
      const actualName = tagName.substring(0, tagName.lastIndexOf('_'));
      const originalTagData = originalTagList.find(
        (item) => item.tagName === actualName
      );
      // Handle if tag name has text change or unit has same change, confirm its not the original change
      if (value) {
        const shouldSkip = isUnit
          ? value !== originalTagData.unit
          : value !== originalTagData.tagAlias;
        if (shouldSkip) {
          return;
        }
      }
      const updatedList = tagChanged.filter((item) => item !== tagName);
      setTagChanged(updatedList);
    } else {
      setTagChanged([...tagChanged, tagName]);
    }
  };

  /*
   * Validate updates to tag and enable/disable save button
   */
  useEffect(() => {
    const shouldDisable = disableSaveButton(tagsToUpdate, tagList);

    setEnableSaveEditButton(shouldDisable);
  }, [tagsToUpdate, tagList]);

  /*
   * Keeps track of which tags to update by tagName
   */
  const updateTagsToUpdateList = (tagName: string) => {
    setTagsToUpdate((prev) => {
      // don't do anything if tag is already in list
      if (prev.includes(tagName)) return prev;

      return [...prev, tagName];
    });
  };

  /*
   * Determines if the field is permitted to update.
   * Currently we only monitor the following two tag/fields
   * - showOnFleetOverviewCard
   * - showOnFleetOverviewMap
   */
  const allowFieldUpdate = (field: string): boolean => {
    if (
      field === 'showOnFleetOverviewMap' ||
      field === 'showOnFleetOverviewCard' ||
      field === 'showOnFleetOverviewTable'
    ) {
      /**
       * A user is only allowed to select up to 6 tags to show on the map
       */

      const tagCount = tagList.filter((tag) => tag[field]);

      if (tagCount.length >= 6) {
        return false;
      }
    }

    // if all checks pass, allow update
    return true;
  };

  /*
   * Asset make/model options
   */
  const selectorOptions = Object.keys(assetMakeModelList).map((key) => {
    return {
      /**
       * remove delimiter and capitalize first letter
       * example: 'make!model' => 'Make model'
       */
      display: capitalizeFirstLetter(safeReplace(key, '!', ' ')),
      id: key,
    };
  });

  /*
   * Changing the make/model seletion will rerender a new tag list
   * in accordance to the selected make/model
   */
  const [selectedMakeModel, setSelectedMakeModel] = useState<string>(
    selectorOptions?.[0]?.id
  );

  useEffect(() => {
    // create tag list for this view based on selected make model
    const devices = assetMakeModelList[selectedMakeModel]
      ?.map((asset) => {
        return asset?.device?.deviceId;
      })
      ?.filter((e) => e);

    if (!devices?.length) return;

    // use devices to get the right tags
    const tagList: ITag[] = [];

    devices.forEach((deviceId: string) => {
      const deviceTags = deviceTagListFull[deviceId]?.tags;

      if (!deviceTags) return;

      tagList.push(...deviceTags);
    });

    /*
     remove duplicates and create unique list
     with values from the first tag
    */
    const uniqueTags: Record<string, ITag> = {};

    tagList.forEach((tag: ITag) => {
      if (uniqueTags[tag.tagName]) return;

      uniqueTags[tag.tagName] = tag;
    });

    // convert back to array
    const uniqueTagList = Object.values(uniqueTags);

    // set tags and devices
    setTagList(uniqueTagList);
    setTagListFull(tagList);
    setOriginalTagList(tagList);
  }, [selectedMakeModel]);

  /**
   * If no tags to update, just update the make model
   *
   * If there are tags to update, show confirmation modal
   * allowing the user to continue without saving or save updates
   * */
  const handleMakeModelChange = (value: string) => {
    if (!tagsToUpdate.length) {
      handleContinueWithoutUpdating();

      return;
    }

    setShowConfirmationModal(true);
  };

  const handleContinueWithoutUpdating = () => {
    setSelectedMakeModel(nextMakeModelSelection.current);
    setShowConfirmationModal(false);
    setTagsToUpdate([]);
  };

  const handleSaveUpdates = async () => {
    const didUpdate = await tagHandler.updateTags(
      // name of tags to update, strings
      tagsToUpdate,
      // list of tags with updated tag values
      tagList,
      // deep copy of original tag list to allow for mutations
      JSON.parse(JSON.stringify(tagListFull))
    );

    setTagChanged([]);

    if (!didUpdate) return;
    setTagsToUpdate([]);
    setShowConfirmationModal(false);

    await assetHandler.getAll();
  };

  const tagTableColDefs: ColDef[] = [
    {
      headerName: 'Tag Name',
      field: 'tagName',
      minWidth: 300,
      filter: 'agTextColumnFilter',
      sort: 'asc',
    },
    {
      headerName: 'Enable Tag',
      maxWidth: 150,
      field: 'isActive',
      cellRenderer: CheckboxRenderer,
      cellRendererParams: {
        field: 'isActive',
        // turn off checkbox if tag is not active
        onCheckboxChange: (data: ITag, rowId: string) => {
          setTagList((prev) => {
            return setOrToggleField(prev, data, 'isActive');
          });

          // update tags to update
          updateTagsToUpdateList(data.tagName);
          addTagToChangeList(`${data.tagName}_enable`);
        },
        enableDarkTheme,
      },
    },
    {
      field: 'unit',
      headerTooltip: 'This is a required field if the tag is enabled',
      headerComponent: WithIconHeader,
      headerComponentParams: {
        headerName: 'Unit of Measurement',
        iconName: 'infoTooltipIcon',
      },
      wrapHeaderText: true,
      minWidth: 300,
      cellRenderer: SelectorRenderer,
      cellStyle: {
        display: 'flex',
        alignItems: 'center',
      },
      cellRendererParams: {
        field: 'unit',
        name: 'unit',
        fallbackValue: 'Select Unit of Measurement',
        selectorOptions: getUnits(UNITS, unitSystem),
        onSelectorChange: (data: ITag, value: string, rowId: string) => {
          setTagList((prev) => {
            return setOrToggleField(prev, data, 'unit', value);
          });

          // update tags to update
          updateTagsToUpdateList(data.tagName);
          addTagToChangeList(`${data.tagName}_unit`, value, true);
        },
        enableDarkTheme,
      },
    },
    {
      field: 'tagAlias',
      minWidth: 300,
      cellRenderer: TextFieldRenderer,
      cellStyle: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
      cellRendererParams: {
        field: 'tagAlias',
        onChange: (data: ITag, value: string) => {
          setTagList((prev) => {
            return setOrToggleField(prev, data, 'tagAlias', value);
          });

          // update tags to update
          updateTagsToUpdateList(data.tagName);
          addTagToChangeList(`${data.tagName}_nameAlias`, value, false);
        },
        enableDarkTheme,
      },
    },
    {
      headerName: 'Display on Map (Select up to 6)',
      headerTooltip: 'Select up to 6 tags to display on the Fleet overview map',
      headerComponent: WithIconHeader,
      headerComponentParams: {
        headerName: 'Display on Map (Select up to 6)',
        iconName: 'infoTooltipIcon',
      },
      wrapHeaderText: true,
      minWidth: 190,
      cellRenderer: CheckboxRenderer,
      cellRendererParams: {
        field: 'showOnFleetOverviewMap',
        // turn off checkbox if tag is not active
        onCheckboxChange: (data: ITag, rowId: string) => {
          // is true, > 6 tags already selected for this field
          const allowUpdate = allowFieldUpdate('showOnFleetOverviewMap');

          /*
            if trying to update but limit is reached, don't update
            if data.showOnFleetOverviewMap is false,
            User is trying to toggle it ON
            if TRUE, user is trying to toggle it OFF. Therefore
            allow update even if limit is reached
          */
          if (!allowUpdate && !data.showOnFleetOverviewMap) return;

          setTagList((prev) => {
            return setOrToggleField(prev, data, 'showOnFleetOverviewMap');
          });

          // update tags to update
          updateTagsToUpdateList(data.tagName);
          addTagToChangeList(`${data.tagName}_fleetView`);
        },
        enableDarkTheme,
      },
    },
    {
      headerName: 'Display on Cards (Select up to 6)',
      headerTooltip:
        'Select up to 6 tags to display on the Fleet overview cards',
      headerComponent: WithIconHeader,
      headerComponentParams: {
        headerName: 'Display on Cards (Select up to 6)',
        iconName: 'infoTooltipIcon',
      },
      wrapHeaderText: true,
      minWidth: 190,
      cellRenderer: CheckboxRenderer,
      cellRendererParams: {
        field: 'showOnFleetOverviewCard',
        // turn off checkbox if tag is not active
        onCheckboxChange: (data: ITag, rowId: string) => {
          // is true, > 6 tags already selected for this field
          const allowUpdate = allowFieldUpdate('showOnFleetOverviewCard');

          /*
            if trying to update but limit is reached, don't update
            if data.showOnFleetOverviewCard is false,
            User is trying to toggle it ON
            if TRUE, user is trying to toggle it OFF. Therefore
            allow update even if limit is reached
          */
          if (!allowUpdate && !data.showOnFleetOverviewCard) return;

          setTagList((prev) => {
            return setOrToggleField(prev, data, 'showOnFleetOverviewCard');
          });

          // update tags to update
          updateTagsToUpdateList(data.tagName);
          addTagToChangeList(data.tagName);
        },
        enableDarkTheme,
      },
    },
    {
      headerName: 'Display on Table (Select up to 6)',
      headerTooltip:
        'Select up to 6 tags to display on the Fleet overview table',
      headerComponent: WithIconHeader,
      headerComponentParams: {
        headerName: 'Display on Table (Select up to 6)',
        iconName: 'infoTooltipIcon',
      },
      wrapHeaderText: true,
      minWidth: 190,
      cellRenderer: CheckboxRenderer,
      cellRendererParams: {
        field: 'showOnFleetOverviewTable',
        // turn off checkbox if tag is not active
        onCheckboxChange: (data: ITag, rowId: string) => {
          // is true, > 6 tags already selected for this field
          const allowUpdate = allowFieldUpdate('showOnFleetOverviewTable');

          /*
            if trying to update but limit is reached, don't update
            if data.showOnFleetOverviewTable is false,
            User is trying to toggle it ON
            if TRUE, user is trying to toggle it OFF. Therefore
            allow update even if limit is reached
          */
          if (!allowUpdate && !data.showOnFleetOverviewTable) return;

          setTagList((prev) => {
            return setOrToggleField(prev, data, 'showOnFleetOverviewTable');
          });

          // update tags to update
          updateTagsToUpdateList(data.tagName);
          addTagToChangeList(`${data.tagName}_tabelView`);
        },
        enableDarkTheme,
      },
    },
    {},
  ];

  const getRowStyle = (params: any) => {
    const isChanged = tagChanged.filter((item) =>
      item.includes(params.data.tagName)
    );
    if (isChanged.length > 0) {
      return { background: '#A7C7E7' }; // Change background color to red for the highlighted row
    }
    return null;
  };

  return (
    <>
      <InteractiveModal
        key={`update-tags-${showConfirmationModal.toString()}`}
        title="Save Changes"
        cancelButtonText="Cancel"
        initialState={showConfirmationModal && tagsToUpdate.length > 0}
        handleAbort={() => {
          setShowConfirmationModal(false);
        }}
        SuccessButtonProps={{
          disabled: disableSaveEditButton,
          onClick: async () => {
            void handleSaveUpdates();
          },
          label: 'Save Changes',
        }}
        SecondaryButtonProps={{
          disabled: false,
          onClick: () => {
            handleContinueWithoutUpdating();
          },
          label: 'Continue W/O Saving',
        }}
        ModalContentProps={{
          gridItemProps: {
            lg: 12,
          },
        }}
        modalContentMaxWidth={'25%'}
        enableDarkTheme={enableDarkTheme}
      >
        <Typography
          key={'blurb-1-cancel-confirmation'}
          sx={{
            color: enableDarkTheme
              ? ThemePalette.typography.white
              : ThemePalette.typography.black,
          }}
        >
          Any unsaved edits will be lost if you exit without saving.
        </Typography>
        <Typography
          key={'blurb-2-cancel-confirmation'}
          sx={{
            color: enableDarkTheme
              ? ThemePalette.typography.white
              : ThemePalette.typography.black,
          }}
        >
          Would you like to proceed without saving?
        </Typography>
      </InteractiveModal>
      <TabHeader
        key={selectedMakeModel}
        onChange={(value: string) => {
          nextMakeModelSelection.current = value;

          handleMakeModelChange(value);
        }}
        value={selectedMakeModel}
        selectorOptions={selectorOptions}
        handleSaveUpdates={handleSaveUpdates}
        disableSaveEditButton={disableSaveEditButton}
        enableDarkTheme={enableDarkTheme}
      />
      <ListWrapper>
        <BaseTableList
          key={selectedMakeModel}
          rowData={JSON.parse(JSON.stringify(tagList))}
          columnDefs={tagTableColDefs}
          cacheQuickFilter={true}
          pagination={true}
          paginationPageSize={20}
          getRowStyle={getRowStyle} // Apply row styles dynamically
        />
        <Typography
          sx={{
            color: 'red',
            textAlign: 'center',
          }}
        >
          {tagChanged.length > 0 ? (
            `*Rows highlighted have undergone changes. Will be modified on clicking
          "Save"`
          ) : (
            <></>
          )}
        </Typography>
      </ListWrapper>

      {disableSaveEditButton && (
        <FlexBox sx={{ justifyContent: 'right' }}>
          <MediumText
            sx={{
              color: 'darkRed',
            }}
            text="Some tags have incompatible settings"
          />
        </FlexBox>
      )}
    </>
  );
};
