import { type FC } from 'react';
import { ChartComponent } from './chart/Graph.composedChart';
import LoadingGraph from './chart/LoadingGraph.composedChart';
import NoDataGraph from './chart/NoData.composedChart';

interface SquaresComposedChartComponentProps {
  data: any[];
  barKeys: any[];
  xAxisDataKey: string;
  xAxisLabel: string;
  isBarColorsSolid?: boolean;
  sort?: string;
  isLoading: boolean;
  isNoData?: boolean;
  yAxisLabel: string;
  enableDarkTheme: boolean;
}

export const SquaresComposedChartComponent: FC<
  SquaresComposedChartComponentProps
> = ({
  data,
  barKeys,
  xAxisDataKey,
  xAxisLabel,
  isBarColorsSolid,
  sort,
  isLoading,
  isNoData,
  yAxisLabel,
  enableDarkTheme,
}) => {
  const noData = data === null;
  return (
    <div>
      {isLoading ? (
        <LoadingGraph />
      ) : noData || isNoData ? (
        <NoDataGraph />
      ) : (
        <ChartComponent
          data={data}
          barKeys={barKeys}
          xAxisDataKey={xAxisDataKey}
          xAxisLabel={xAxisLabel}
          isBarColorsSolid={isBarColorsSolid}
          sort={sort}
          yAxisLabel={yAxisLabel}
          enableDarkTheme={enableDarkTheme}
        />
      )}
    </div>
  );
};
