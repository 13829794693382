import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useLocation } from 'react-router-dom';
import {
  capitalizeFirstLetter,
  safeReplaceBredcrumb,
} from 'utils/helpers/string.manipulation';

export const BreadCrumb = () => {
  const { pathname } = useLocation();

  // split by / and filter '' out
  const breadCrumbs = pathname.split('/').filter((x) => x);

  return (
    <Box display="flex" margin="15px">
      {breadCrumbs.map((crumb, index) => {
        const isLastCrumb = index === breadCrumbs.length - 1;
        const decorator = isLastCrumb ? '' : '>';

        /* replace - with space and capitalize first letter */
        const formattedCrumb = capitalizeFirstLetter(
          safeReplaceBredcrumb(crumb, '-', ' ')
        );

        return (
          <Typography key={crumb} variant="subtitle1" mr={'5px'}>
            {`${formattedCrumb} ${decorator}`}
          </Typography>
        );
      })}
    </Box>
  );
};
