export interface AssetData {
  name: string;
  total_cost: number;
  total_hours: number;
  total_labour_cost: number;
  total_part_cost: number;
}

export interface TimeseriesData {
  name: string;
  asset_id: string;
  timestamp: string;
  work_order_number: string;
  total_cost_asset: number;
  total_labour_cost: number;
  total_part_cost: number;
}

export function extractWorkOrderData(data: Record<string, any>): AssetData[] {
  const result: AssetData[] = [];
  for (const key in data) {
    if (Object.hasOwnProperty.call(data, key)) {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      const { total_cost, total_hours, total_labour_cost, total_part_cost } =
        data[key];

      const newObj: AssetData = {
        name: key,
        total_cost, // align property names with the AssetData interface
        total_hours,
        total_labour_cost,
        total_part_cost,
      };

      result.push(newObj);
    }
  }

  result.sort((a, b) => b.total_cost - a.total_cost);
  return result;
}

export function getAssetsByKey(
  data: Record<string, any>,
  keys: string[]
): AssetData[] {
  if (!data) {
    return [];
  }
  const assetsWithNames: AssetData[] = [];

  Object.entries(data).forEach(([key, value]) => {
    if (keys.includes(key)) {
      assetsWithNames.push({
        ...value,
        name: key,
      });
    }
  });

  return assetsWithNames;
}

export function extractSelectedAssetsTimesSeries(
  dataset: Record<string, any[]>,
  selectedAssets: string[],
  selectedAssetsCostType: string[]
): any {
  if (!dataset) {
    return [];
  }
  const extractedData: Record<string, any[]> = {};

  // Filter the dataset for selected assets
  selectedAssets.forEach((assetId) => {
    if (dataset[assetId]) {
      const filteredData = dataset[assetId]
        .map((entry) => {
          // Create a new object potentially only with selected cost types
          const filteredEntry: any = {
            asset_id: entry.asset_id,
            timestamp: entry.timestamp,
            work_order_number: entry.work_order_number,
            total_cost_asset: entry.total_cost_asset,
            ...(selectedAssetsCostType.includes('total_labour_cost') && {
              total_labour_cost: entry.total_labour_cost,
            }),
            ...(selectedAssetsCostType.includes('total_part_cost') && {
              total_part_cost: entry.total_part_cost,
            }),
          };
          return filteredEntry;
        })
        // Sort entries by timestamp within each asset
        .sort((a, b) =>
          a.timestamp.toString().localeCompare(b.timestamp.toString())
        );

      extractedData[assetId] = filteredData;
    }
  });

  // Create an output array with asset data properly structured and sorted
  const output = Object.keys(extractedData).map((assetKey) => ({
    name: assetKey,
    data: extractedData[assetKey],
  }));
  return output;
}

export function getFirstAttribute(obj: any): string[] {
  if (!obj) {
    return [];
  }
  const arr = [];
  let response = [];
  // Check if the object is empty
  if (Object.keys(obj).length === 0) {
    response = [];
  }

  for (const key in obj) {
    arr.push({ [key]: obj[key].total_cost || 0 });
  }

  if (arr.length > 0) {
    const sortedArr = arr.sort((a, b) => {
      const costA = Object.values(a)[0];
      const costB = Object.values(b)[0];
      return costB - costA;
    });

    const highestKey = Object.keys(sortedArr[0])[0];
    response.push(highestKey);
  }
  return response;
}
