import { Typography, Paper, Grid } from '@mui/material';
import { ThemePalette } from 'mui.theme';
import { paperContainerStyle } from 'views/FleetOverview/components/styles';
import { Base } from 'components/Containers/BaseContainer';
import { useAppSelector } from 'store/hook';

interface DataMetrics {
  sumIdleFuelConsumption: number;
  sumIdleTimeHours: number;
  assetCount?: number;
}

export const CardSection = ({
  selectedData,
  selectedMakeModel,
  selectedDataMetrics,
  calculateDaysDifference,
  enableDarkTheme = false,
  isProductivity,
}: {
  selectedData: string | null;
  selectedMakeModel: string;
  selectedDataMetrics: DataMetrics | null;
  calculateDaysDifference: any;
  enableDarkTheme: boolean;
  isProductivity: boolean;
}) => {
  const unitSystem = useAppSelector((state) => state.persistedReducer).customer
    .unitSystem;

  return (
    <Grid item lg={2} md={12} sm={12} xs={12}>
      <Base
        sx={{
          ...paperContainerStyle,
          backgroundColor: 'transparent',
        }}
      >
        <Paper
          elevation={7}
          style={{
            padding: '7px',
            marginBottom: '7px',
            backgroundColor: enableDarkTheme
              ? ThemePalette.dark.modalBackground
              : ThemePalette.light.boxBackground,
            color: enableDarkTheme
              ? ThemePalette.typography.white
              : ThemePalette.typography.black,
            alignContent: 'center',
          }}
        >
          <div>
            <Typography variant="small">Asset(s) Selected</Typography>
            {selectedData ? (
              <Typography variant="h6">{selectedData}</Typography>
            ) : (
              <Typography
                variant="h6"
                sx={{
                  color: '#4A7BFA',
                }}
              >
                {formatMakeModel(selectedMakeModel)} (
                {selectedDataMetrics?.assetCount})
              </Typography>
            )}
          </div>
        </Paper>
        <Paper
          elevation={7}
          style={{
            padding: '7px',
            marginBottom: '7px',
            backgroundColor: enableDarkTheme
              ? ThemePalette.dark.modalBackground
              : ThemePalette.light.boxBackground,
            color: enableDarkTheme
              ? ThemePalette.typography.white
              : ThemePalette.typography.black,
            alignContent: 'center',
          }}
        >
          <div>
            <Typography variant="small">
              Fuel Consumed during {isProductivity ? 'production' : 'Idling'}
            </Typography>
            <Typography
              variant="h6"
              sx={{
                color: '#4A7BFA',
              }}
            >
              <>
                <span>
                  {selectedDataMetrics?.sumIdleFuelConsumption?.toFixed(2)}
                </span>
                <span
                  style={{
                    color: enableDarkTheme
                      ? ThemePalette.typography.white
                      : ThemePalette.typography.black,
                  }}
                >
                  {unitSystem === 'metric' ? ' Litres' : ' Gallons'}
                </span>
              </>
            </Typography>
          </div>
        </Paper>
        <Paper
          elevation={7}
          style={{
            padding: '7px',
            marginBottom: '7px',
            backgroundColor: enableDarkTheme
              ? ThemePalette.dark.modalBackground
              : ThemePalette.light.boxBackground,
            color: enableDarkTheme
              ? ThemePalette.typography.white
              : ThemePalette.typography.black,
          }}
        >
          <div>
            <Typography variant="small">
              {isProductivity ? 'Productive' : 'Idling'} Hours
            </Typography>
            <Typography
              variant="h6"
              sx={{
                color: '#4A7BFA',
              }}
            >
              <>
                <span>{selectedDataMetrics?.sumIdleTimeHours.toFixed(2)}</span>
                <span
                  style={{
                    color: enableDarkTheme
                      ? ThemePalette.typography.white
                      : ThemePalette.typography.black,
                  }}
                >
                  {' '}
                  Hrs
                </span>
              </>
            </Typography>
            {/* Add styling or additional components for meters if needed */}
          </div>
        </Paper>
        {!isProductivity ? (
          <Paper
            elevation={7}
            style={{
              padding: '8px',
              marginBottom: '7px',
              backgroundColor: enableDarkTheme
                ? ThemePalette.dark.modalBackground
                : ThemePalette.light.boxBackground,
              color: enableDarkTheme
                ? ThemePalette.typography.white
                : ThemePalette.typography.black,
            }}
          >
            <div>
              <Typography variant="small">
                Potential Fuel Savings for next 6 months
              </Typography>
              <Typography
                variant="h6"
                sx={{
                  color: '#4A7BFA',
                }}
              >
                {selectedDataMetrics && calculateDaysDifference() ? (
                  <>
                    <span>
                      {(
                        (Number(selectedDataMetrics.sumIdleFuelConsumption) /
                          calculateDaysDifference()) *
                        180
                      ).toFixed(2)}
                    </span>
                    <span
                      style={{
                        color: enableDarkTheme
                          ? ThemePalette.typography.white
                          : ThemePalette.typography.black,
                      }}
                    >
                      {unitSystem === 'metric' ? ' Litres' : ' Gallons'}
                    </span>
                  </>
                ) : (
                  'N/A'
                )}
              </Typography>
              {/* Add styling or additional components for meters if needed */}
            </div>
          </Paper>
        ) : (
          <></>
        )}
      </Base>
    </Grid>
  );
};

function formatMakeModel(makeModel: string) {
  // Split the makeModel string based on the exclamation mark (!)
  const parts = makeModel.split('!');

  // Capitalize each word in each part
  const formattedParts = parts.map((part) => capitalizeEachWord(part.trim()));

  // Join the parts back together with a space and return the formatted string
  return formattedParts.join(' ');
}

function capitalizeEachWord(str: string) {
  return str.toLowerCase().replace(/(^|\s)\S/g, function (match) {
    return match.toUpperCase();
  });
}
