import * as React from 'react';
import { Base } from 'components/Containers/BaseContainer';
import { UploadWorkOrdersViewModal } from './modals/UploadWorkOrders-modal';
import { WorkOrdersHistory } from './modals/WorkOrdersHistory';
import { ToolbarContainer } from 'components/Containers/ToolbarContainer';
import SWTab from 'components/Layout/Tabs/SWTab';
import { Tabs, TabList, TabPanel } from 'react-tabs';
import { useFetchAllWorkHistoryOrders } from './Helper/fetchData';
import { type WorkOrdersSquareHistory } from 'types/squares.type';
import { useDispatch } from 'react-redux';
import { updateLoadingState } from 'store/app.slice';
import { useAppSelector } from 'store/hook';
import { isDarkTheme } from 'utils/theme';

export const UploadWorkOrdersView = () => {
  const theme = useAppSelector((state) => state.authReducer).customer.theme;
  const enableDarkTheme = isDarkTheme(theme);

  const [tabIndex, setTabIndex] = React.useState(0); // State to manage active tab index
  const dispatch = useDispatch();

  const switchToHistoryTab = async () => {
    // Start showing the loading spinner
    dispatch(updateLoadingState(true));

    // Set a timeout to wait for 3 seconds before fetching new work orders
    setTimeout(async () => {
      // Fetch new work orders
      const orders = await fetchAllWorkHistoryOrders();
      setWorkHistoryOrders(orders);

      // Switch to the WorkOrdersHistory tab after fetching
      setTabIndex(1);

      // Stop showing the loading spinner
      dispatch(updateLoadingState(false));
    }, 5000); // Wait for 5 seconds
  };

  const fetchAllWorkHistoryOrders = useFetchAllWorkHistoryOrders();
  const [workHistoryOrders, setWorkHistoryOrders] = React.useState<
    WorkOrdersSquareHistory[]
  >([]);

  React.useEffect(() => {
    const fetchWorkOrders = async () => {
      const orders = await fetchAllWorkHistoryOrders();
      setWorkHistoryOrders(orders);
    };

    void fetchWorkOrders();
  }, [tabIndex]);

  return (
    <Base enableDarkTheme={enableDarkTheme}>
      <Tabs
        selectedIndex={tabIndex}
        onSelect={(index) => {
          setTabIndex(index);
        }}
      >
        <TabList>
          <ToolbarContainer
            enableDarkTheme={enableDarkTheme}
            style={{ paddingBottom: 0 }}
          >
            <SWTab enableDarkTheme={enableDarkTheme}>
              Upload a New Work Order
            </SWTab>
            <SWTab enableDarkTheme={enableDarkTheme}>Upload History</SWTab>
          </ToolbarContainer>
        </TabList>

        <TabPanel style={{ padding: '11px 21px 21px' }}>
          <UploadWorkOrdersViewModal
            switchToHistoryTab={switchToHistoryTab}
            workOrders={workHistoryOrders}
            enableDarkTheme={enableDarkTheme}
          />
        </TabPanel>

        <TabPanel style={{ padding: '11px 21px 21px' }}>
          <WorkOrdersHistory workOrders={workHistoryOrders} />
        </TabPanel>
      </Tabs>
    </Base>
  );
};
