import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { Typography, Grid, List, ListItem, Box } from '@mui/material';
import type { Asset } from 'types/asset.types';
import { InteractiveModal } from 'components/Modals/InteractiveModal/BaseModal';
import AssetCardItem from '../AssetCardItem';
import { Base } from 'components/Containers/BaseContainer';
import { AssetCardsDiv } from '../AssetCard.styled';
import FooterDisplay from './FooterDisplay';
import { ThemePalette } from 'mui.theme';
import ComparerModalFilter from './ModalSidebar';
import { sortCompareAssetsByChecked } from '../utils';

interface Props {
  title: string;
  open: boolean;
  handleAbort: () => void;
  enableDarkTheme: boolean;
  selectedAsset: string[];
  filteredAssets: Asset[];
}

export const CompareAssetModal = ({
  title,
  handleAbort,
  open,
  enableDarkTheme,
  selectedAsset,
  filteredAssets,
}: Props) => {
  const navigate = useNavigate();
  const [assetsDisplay, setAssetsDisplay] = useState<Asset[]>(filteredAssets);
  const [selectedAssetIds, setSelectedAssetIds] = useState<string[]>([
    ...selectedAsset,
  ]);

  const handleSubmit = () => {
    handleAbort();
    const selectedAssets = filteredAssets.filter((item) =>
      selectedAssetIds.includes(item.assetId)
    );
    navigate('/compare-assets', { state: { assets: selectedAssets } });
  };

  function handleCheckBoxSelected(arg: string) {
    let result: string[] = selectedAssetIds;

    const itemExists = selectedAssetIds.find((item) => item === arg);
    if (itemExists) {
      result = selectedAssetIds.filter((item) => item !== arg);
    } else {
      // Handle limit to 3
      if (selectedAssetIds.length > 2) {
        return;
      }
      result = [...selectedAssetIds, arg];
    }
    setSelectedAssetIds(result);
  }

  const defaultSelectedAssetType =
    filteredAssets.find((item) => item.assetId === selectedAsset[0])
      ?.assetType ?? '';

  return (
    <InteractiveModal
      initialState={open}
      title={title}
      handleAbort={handleAbort}
      SuccessButtonProps={{
        onClick: () => {
          handleSubmit();
        },
        disabled: selectedAssetIds.length < 2,
        label: 'Compare',
      }}
      ModalContentProps={{
        gridItemProps: {
          lg: 12,
        },
      }}
      confirmCancellation={selectedAssetIds.length > 1}
      enableDarkTheme={enableDarkTheme}
      footerMargin={'2px'}
    >
      <List
        style={{
          display: 'flex',
          flexDirection: 'row',
          padding: 0,
          minWidth: '800px',
        }}
      >
        <ListItem></ListItem>
        <ListItem></ListItem>
        <ListItem></ListItem>
      </List>
      <Grid container spacing={2}>
        <Grid item xs={3}>
          <Box
            sx={{
              paddingTop: '20px',
            }}
          >
            {/* Filter grid */}
            <Typography
              sx={{
                color: enableDarkTheme
                  ? ThemePalette.typography.white
                  : ThemePalette.typography.black,
                fontSize: '14px',
              }}
            >
              You can select up to 3 assets to compare
            </Typography>
            <Typography
              sx={{
                color: enableDarkTheme
                  ? ThemePalette.typography.white
                  : ThemePalette.typography.black,
                fontSize: '23px',
                paddingTop: '10px',
              }}
            >
              Filters
            </Typography>
            <ComparerModalFilter
              enableDarkTheme={enableDarkTheme}
              assets={filteredAssets}
              setAssetsDisplay={setAssetsDisplay}
              defaultAssetType={defaultSelectedAssetType}
              setSelectedAssetIds={setSelectedAssetIds}
            />
          </Box>
        </Grid>
        <Grid item xs={9}>
          <Base enableDarkTheme={enableDarkTheme}>
            <AssetCardsDiv>
              <Grid
                container
                spacing={2}
                id="assetCards"
                sx={{
                  paddingTop: '15px',
                  paddingRight: '15px',
                  paddingLeft: '15px',
                  paddingBottom: '15px',
                }}
              >
                {/* Main content grid */}
                {assetsDisplay.length > 0 ? (
                  sortCompareAssetsByChecked(
                    selectedAssetIds,
                    assetsDisplay
                  ).map((asset: Asset) => {
                    return (
                      <AssetCardItem
                        key={asset.assetId}
                        asset={asset}
                        enableDarkTheme={enableDarkTheme}
                        checkedList={selectedAssetIds}
                        showCheckedButton={true}
                        handleCheckBoxSelected={handleCheckBoxSelected}
                      />
                    );
                  })
                ) : (
                  <Typography
                    sx={{
                      color: enableDarkTheme
                        ? ThemePalette.typography.white
                        : ThemePalette.typography.black,
                    }}
                  >
                    No assets available.
                  </Typography>
                )}
              </Grid>
            </AssetCardsDiv>
          </Base>
        </Grid>
      </Grid>
      {selectedAssetIds.length > 0 ? (
        <FooterDisplay
          assets={filteredAssets}
          selectedAssetIds={selectedAssetIds}
          enableDarkTheme={enableDarkTheme}
          handleCheckBoxSelected={handleCheckBoxSelected}
        />
      ) : (
        <></>
      )}
      <></>
    </InteractiveModal>
  );
};
