import { SVG } from 'components/Asset/SVG';
import { theme } from 'mui.theme';
import { type CSSProperties } from 'react';
import { StyledIcon } from './CellRenderer.styled';

const { svgIcons } = theme.palette;

interface Props {
  data: Record<string, string>;
  field: string;
  iconName: string;
  /*
    Array of fields to check if they are present in data
    If any of the field is not present, render nothing
  */
  renderCondition?: string[];
  /*
    On click of Icon
  */
  onIconClick?: (param: any) => void;
  /*
    On click of display content 
  */
  onCellClick?: (param: any) => void;
  style?: CSSProperties;
}

export const IconRenderer = ({
  data,
  iconName,
  renderCondition = [],
  onIconClick,
  style,
}: Props) => {
  // if any of the renderCondition is not met, return null
  if (
    renderCondition.length &&
    renderCondition.some((condition) => !data[condition])
  )
    return null;

  return (
    <span style={style}>
      <StyledIcon
        onClick={() => {
          if (!onIconClick) return;

          onIconClick(data);
        }}
      >
        <SVG
          name={iconName}
          style={{
            ...svgIcons?.primary,
          }}
        />
      </StyledIcon>
    </span>
  );
};
