import { updateLoadingState } from 'store/app.slice';
import {
  type ICreateInternalUserPayload,
  type IUpdateInternalUserPayload,
} from 'types/payloads/user.payload.types';
import { BaseHandler } from './base.handler';
import { InternalUserAPI } from 'api/internal.user.api';
import { type InternalUser } from 'types/user.types';
import {
  appendInternalUserList,
  removeInternalUserFromList,
  setInternalUserList,
  updateInternalUserList,
} from 'store/internal.user.slice';
import { INTERNAL_USER_DOMAIN } from 'utils/enums';
import { SharedUserAPI } from 'api/shared.user.api';

export default class UserHandler extends BaseHandler {
  private readonly API: InternalUserAPI;
  private readonly sharedAPI: SharedUserAPI;

  constructor() {
    super();

    this.API = new InternalUserAPI();
    this.sharedAPI = new SharedUserAPI();
  }

  async getAll(): Promise<InternalUser[] | []> {
    this.dispatch(updateLoadingState(true));

    try {
      const userList = await this.API.getAll();

      // update state with user list
      this.dispatch(setInternalUserList(userList));
      this.dispatch(updateLoadingState(false));

      return userList;
    } catch (_) {
      // show snackbar error
      this.dispatch(updateLoadingState(false));
    }

    return [];
  }

  // create user using api
  async createInternalUser(payload: ICreateInternalUserPayload): Promise<void> {
    this.dispatch(updateLoadingState(true));

    try {
      // append symboticware domain
      payload.email = `${payload.email}${INTERNAL_USER_DOMAIN}`;

      const newUser = await this.API.create(payload);

      // update state with new user
      this.dispatch(appendInternalUserList(newUser));
      this.handleSuccess(
        'A new user has been created and a password creation email has been sent.'
      );
    } catch (_) {
      // show snackbar error
      this.handleError('An error occurred while creating the user.');
    }
  }

  async updateInternalUser(
    userId: string,
    payload: IUpdateInternalUserPayload
  ): Promise<void> {
    this.dispatch(updateLoadingState(true));

    try {
      payload.email = `${payload.email}${INTERNAL_USER_DOMAIN}`;

      const updateFields = {
        ...payload,
        userId,
      };
      const updatedUser = await this.API.update(updateFields);

      // update state with updated user
      this.dispatch(updateInternalUserList(updatedUser));
      this.handleSuccess('User has been updated successfully.');
    } catch (_) {
      // show snackbar error
      this.handleError('An error occurred while updating the user.');
    }
  }

  async deactivateInternalUser(
    userId: string,
    firstName: string,
    lastName: string
  ): Promise<void> {
    this.dispatch(updateLoadingState(true));

    try {
      const updatedUser = await this.API.deactivate(userId);

      this.dispatch(removeInternalUserFromList(updatedUser));
      this.handleSuccess(`${firstName} ${lastName} has been deactivated.`);
    } catch (_) {
      // show snackbar error
      this.handleError('An error occurred while deactivating the user.');
    }
  }

  /**
   * Requests a password reset for a user by id.
   * Invalidates a users Auth0 credentials and starts a workflow to reset the password.
   * This API is invokable by: director, internal-user, external-admin
   *
   * Restrictions:
   * - external-admin can not invalidate credentials of users outside of their company. The API will return 404
   * @param userId The internal_user_id (ID of the user from the database)
   */
  async resetPassword(userId: string): Promise<void> {
    try {
      this.dispatch(updateLoadingState(true));
      await this.sharedAPI.resetPassword(userId);
      this.dispatch(updateLoadingState(false));
      this.handleSuccess('User password reset request has been submitted');
    } catch (_) {
      this.handleError('An error occurred while triggering a password reset.');
    }
  }

  async userInfoSync() {
    try {
      await this.sharedAPI.syncUserInfo();
    } catch (error) {}
  }
}
