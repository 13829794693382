import { Box, type SxProps } from '@mui/material';

interface Props {
  sx?: SxProps;
  children?: JSX.Element | JSX.Element[];
  id?: string;
}

export const FlexBox = ({ sx, children, id }: Props) => {
  return (
    <Box
      sx={{
        display: 'flex',
        ...sx,
      }}
      id={id}
    >
      {children}
    </Box>
  );
};
