import { useEffect, useState } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { useAppDispatch, useAppSelector } from 'store/hook';
import { Base } from 'components/Containers/BaseContainer';
import { ToolbarContainer } from 'components/Containers/ToolbarContainer';
import { FlexBox } from 'components/Containers/FlexBox';
import { Selector } from 'components/Form/Selectors/Selector';
import { NotificationsList } from './components/List';
import { filterNotifications } from './utils';
import NotificationHandler from 'handlers/notification.handler';
import { ConfirmClearNotificationModal } from './components/Modal/ConfirmationModal';
import { isDarkTheme } from 'utils/theme';
import { ThemePalette } from 'mui.theme';
import LineChartComponent from 'components/Charts/LineChart';
import { type TagDetails, type TagAlertDetails } from 'types/tag.type';
import TagHandler from 'handlers/tag.handler';
import { formatChartData } from 'utils/helpers/chartData';
import { setIsLoading } from 'store/weather.slice';
import {
  type RuleCondition,
  type RuleNotifications,
} from 'types/notification.types';
import {
  clearSelectedNotifications,
  setSelectedNotifications,
} from 'store/ruleNotification.slice';
import NoChartData from 'views/FuelAnalytics/NoChart';
import { SingleFadeLoader } from 'components/SingleFadeLoader';
import dayjs from 'dayjs';

enum OpenModal {
  None,
  ConfirmClearNotification,
}

export const NotificationsView = (): JSX.Element => {
  const notificationHandler = new NotificationHandler();
  const tagHandler = new TagHandler();
  // Get the list of notifications from redux
  const { notifications, selectedNotification } = useAppSelector(
    (state) => state.ruleNotificationsReducer
  );
  const { rules } = useAppSelector((state) => state.ruleReducer);
  const dispatch = useAppDispatch();

  const theme = useAppSelector((state) => state.authReducer).customer.theme;
  const { assets } = useAppSelector((state) => state.assetReducer);
  const enableDarkTheme = isDarkTheme(theme);
  const [selectedViewOption, setSelectedViewOption] =
    useState<string>('active');
  const [selectedClearOption, setSelectedClearOption] = useState<string>('');
  const { isLoading } = useAppSelector((state) => state.appReducer);
  const [modalOpen, setModalOpen] = useState(OpenModal.None);
  const selectorOptions = [
    {
      id: 'all',
      display: 'All',
    },
    {
      id: 'active',
      display: 'Active',
    },
    {
      id: 'inactive',
      display: 'Inactive',
    },
  ];
  const clearSelectorOptions = [
    {
      id: 'today',
      display: `Clear Today's Notifications`,
    },
    {
      id: 'yesterday',
      display: `Clear Yesterday's Notifications`,
    },
    {
      id: 'thisWeek',
      display: `Clear This Week's Notifications`,
    },
    {
      id: 'lastWeek',
      display: `Clear Last Week's Notifications`,
    },
    {
      id: 'all',
      display: 'Clear All Notifications',
    },
  ];

  const handleClearNotifications = async () => {
    const notificationsToDelete = filterNotifications(
      selectedClearOption,
      notifications
    );
    const notificationIds = notificationsToDelete.map((item) => {
      return item.notificationId;
    });
    if (notificationIds.length > 0) {
      await notificationHandler.clearNotifications(notificationIds);
    }
    setSelectedClearOption('');
  };

  const [alertDetails, setAlertDetails] = useState<TagAlertDetails>({
    deviceId: '',
    notificationTime: '',
    tagDetails: [{ tagName: '', operator: '', value: 0 }],
  });

  const [chartData, setChartData] = useState<any>();
  const [formattedData, setFormattedData] = useState<any>();
  const [lineChartUnit, setLineChartUnit] = useState<string>('unit');
  const [selectedRow, setSelectedRow] = useState<string>('');
  const [selectedRowNotification, setSelectedRowNotification] =
    useState<RuleNotifications>();

  const [isReloaded, setIsReloaded] = useState(false);

  const handleAlertDetails = (data: TagAlertDetails) => {
    setAlertDetails(data);
  };

  const handleSelectedNotification = (notificationId: string) => {
    const notification = notifications.find(
      (item) => item.notificationId === notificationId
    );
    setSelectedRowNotification(notification);
    return notification;
  };

  useEffect(() => {
    const getAlertDataPoints = async () => {
      const data = await tagHandler.getNotificationTagDetails(alertDetails);
      setChartData(data);
      if (data && alertDetails.notificationId) {
        const selectedNotificationItem = handleSelectedNotification(
          alertDetails.notificationId
        );
        dispatch(setSelectedNotifications(selectedNotificationItem));
      } else {
        dispatch(clearSelectedNotifications());
      }
    };

    void getAlertDataPoints();
  }, [alertDetails]);

  useEffect(() => {
    const dataFetch = async () => {
      setIsLoading(true);
      if (chartData && Object.keys(chartData).length !== 0) {
        const dataObject = Object.entries(chartData).map(([key, value]) => ({
          [key]: value,
        }));

        const formatData = dataObject.map((data: any, index: number) => {
          const dataName = Object.keys(data)[0];
          const dataValues = data[Object.keys(data)[0]];

          if (dataValues.length > 0) {
            const isSymStart = dataValues.tagName === 'sym_start';
            const asset = assets.find((item) => {
              const dataDeviceId = dataValues[0].device_sensor.split('-')[0];
              return item.device.deviceId === dataDeviceId;
            });
            return {
              name: dataName,
              data: formatChartData(dataValues, isSymStart, dataName, asset),
              label: '',
            };
          } else {
            return {
              name: dataName,
              data: '',
              label: '',
            };
          }
        });
        setFormattedData(formatData);
      } else {
        setFormattedData(chartData);
      }
      setIsLoading(false);
    };
    void dataFetch();
  }, [chartData]);

  const activeNotifications = notifications.filter((item) => item.isActive);
  const inActiveNotifications = notifications.filter((item) => !item.isActive);

  useEffect(() => {
    const firstItemId =
      selectedViewOption === 'active'
        ? activeNotifications[0]?.notificationId
        : selectedViewOption === 'inactive'
        ? inActiveNotifications[0]?.notificationId
        : notifications[0]?.notificationId;
    setSelectedRow(firstItemId);

    const getAlertDataPoints = async () => {
      const notification = handleSelectedNotification(firstItemId);
      if (!notification) return null;

      if (
        notification &&
        selectedNotification?.notificationId.length < 1 &&
        isReloaded
      ) {
        const rule = rules.find((item) => item.ruleId === notification?.ruleId);
        if (!notification || !rule) return null;

        const tagDetails: TagDetails[] = [];
        rule.conditions.map((item) => {
          tagDetails.push({
            tagName: item.tagName,
            operator: item.relationalOperator,
            value: item.value,
          });
          return null;
        });
        handleNotificationConditions(rule.conditions as any);
        const data = await tagHandler.getNotificationTagDetails({
          deviceId: notification?.deviceId,
          notificationTime: notification?.alertStartTs,
          tagDetails,
        });
        setChartData(data);
        if (data) {
          const selectedNotificationItem = notifications.find(
            (item: RuleNotifications) =>
              item.notificationId === alertDetails.notificationId
          );
          dispatch(setSelectedNotifications(selectedNotificationItem));
        } else {
          dispatch(clearSelectedNotifications());
        }
      } else {
        dispatch(clearSelectedNotifications());
      }
    };
    void getAlertDataPoints();
  }, [notifications, isReloaded]);

  useEffect(() => {
    dispatch(clearSelectedNotifications());
    setIsReloaded(true);
  }, []);

  const [notificationConditions, setNotificationConditions] = useState<
    RuleCondition[]
  >([]);

  const handleNotificationConditions = (conditions: []) => {
    setNotificationConditions(conditions);
  };

  return (
    <>
      <Base enableDarkTheme={enableDarkTheme}>
        {
          // Unink device confirmation modal
          OpenModal.ConfirmClearNotification && (
            <ConfirmClearNotificationModal
              key={`${modalOpen.toString()}-clear-notification`}
              open={modalOpen === OpenModal.ConfirmClearNotification}
              handleAbort={() => {
                setModalOpen(OpenModal.None);
              }}
              handleSuccess={() => {
                void handleClearNotifications();
              }}
            />
          )
        }
        <ToolbarContainer enableDarkTheme={enableDarkTheme}>
          <FlexBox
            sx={{
              width: '100%',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <FlexBox>
              <Typography>Currently Viewing</Typography>
              <Selector
                value={selectedViewOption}
                onChange={(event) => {
                  const selectedValue = event.target.value as string;
                  setSelectedViewOption(selectedValue);
                }}
                selectorOptions={selectorOptions}
                selectSx={{
                  ml: '10px',
                  flex: 1,
                  fontSize: '14px',
                }}
                enableDarkTheme={enableDarkTheme}
              />
            </FlexBox>
            <FlexBox
              sx={{
                display: 'flex',
                alignItems: 'center',
                float: 'right',
                marginRight: '20px',
              }}
            >
              <Selector
                fallbackValue="Select Bulk Clear Action"
                value={selectedClearOption}
                onChange={(event) => {
                  const selectedValue = event.target.value as string;
                  setSelectedClearOption(selectedValue);
                }}
                selectorOptions={clearSelectorOptions}
                selectSx={{
                  ml: '10px',
                  flex: 1,
                  fontSize: '13px',
                }}
                enableDarkTheme={enableDarkTheme}
              />
              <Button
                variant="contained"
                disabled={!selectedClearOption}
                sx={{
                  marginLeft: '5px',
                  backgroundColor: 'button.primary',
                }}
                onClick={() => {
                  setModalOpen(OpenModal.ConfirmClearNotification);
                }}
              >
                GO
              </Button>
            </FlexBox>
          </FlexBox>
        </ToolbarContainer>
        {isLoading ? (
          <div style={{ marginLeft: '-200px' }}>
            {' '}
            <SingleFadeLoader />
          </div>
        ) : (
          <></>
        )}
        <NotificationsList
          filter={selectedViewOption}
          notifications={notifications}
          handleAlertDetails={handleAlertDetails}
          handleNotificationConditions={handleNotificationConditions}
          selectedRow={selectedRow}
          setSelectedRow={setSelectedRow}
          enableDarkTheme={enableDarkTheme}
        />
      </Base>
      <Box
        sx={{
          height: '800px',
          maxHeight: '800px',
          width: '100%',
          backgroundColor: `${
            enableDarkTheme
              ? ThemePalette.dark.boxBackground
              : ThemePalette.light.boxBackground
          }`,
        }}
      >
        <>
          {notifications.length < 1 ? (
            <></>
          ) : !isLoading && formattedData ? (
            <>
              <Typography
                sx={{
                  textAlign: 'center',
                  fontSize: '16px',
                  fontWeight: '600',
                  paddingTop: '20px',
                  color: `${
                    enableDarkTheme
                      ? ThemePalette.typography.wheat
                      : ThemePalette.typography.black
                  }`,
                }}
              >
                Time Series Chart
                <br />
                <span
                  style={{
                    color: `${
                      enableDarkTheme
                        ? ThemePalette.typography.wheat
                        : ThemePalette.typography.black
                    }`,
                    fontStyle: 'italic',
                    fontSize: '14px',
                  }}
                >
                  Notification :{' '}
                </span>
                <span
                  style={{
                    color: '#8884d8',
                    fontStyle: 'italic',
                  }}
                >
                  {selectedRowNotification?.rule?.ruleName}{' '}
                </span>
                <span
                  style={{
                    color: `${
                      enableDarkTheme
                        ? ThemePalette.typography.wheat
                        : ThemePalette.typography.black
                    }`,
                    fontStyle: 'italic',
                    fontSize: '14px',
                  }}
                >
                  on:{' '}
                </span>
                <span
                  style={{
                    color: '#8884d8',
                    fontStyle: 'italic',
                  }}
                >
                  {selectedRowNotification?.asset?.bumperNumber}{' '}
                </span>
                <span
                  style={{
                    color: `${
                      enableDarkTheme
                        ? ThemePalette.typography.wheat
                        : ThemePalette.typography.black
                    }`,
                    fontStyle: 'italic',
                    fontSize: '14px',
                  }}
                >
                  {' '}
                  {dayjs(selectedRowNotification?.alertStartTs).format(
                    'MM/DD/YYYY h:mma'
                  )}{' '}
                </span>
              </Typography>
              <LineChartComponent
                xAxis="ts"
                data={formattedData}
                exportTo={false}
                handleExport={() => {}}
                assetBumperNumber={''}
                selectedRange={''}
                device={''}
                avgVal={'value'}
                enableDarkTheme={enableDarkTheme}
                xAxisTimeFormat="HH:mm"
                domainValue={[true, 'value']}
                notificationConditions={notificationConditions}
              />
            </>
          ) : !isLoading && !formattedData ? (
            <NoChartData
              message="No chart data available for this notification"
              enableDarkTheme={enableDarkTheme}
            />
          ) : null}
        </>
      </Box>
    </>
  );
};
