import { ThemePalette } from 'mui.theme';

interface Props {
  node: any;
  value: string;
  data: any;
  handleClick: (device: string, bumperNumber: string) => void;
  assets: Array<Record<string, unknown>>;
  range: 'total' | 'week' | 'month';
  enableDarkTheme: boolean;
}

export const CellDisplay = ({
  value,
  data,
  handleClick,
  node,
  enableDarkTheme,
}: Props) => {
  return (
    <span
      style={{
        color: `${enableDarkTheme ? ThemePalette.typography.white : '#0047AB'}`,
        textDecoration: 'underline',
      }}
      onClick={() => {
        handleClick(data.device, data.bumperNumber);
      }}
    >
      {value}
    </span>
  );
};

export const EngineHoursOffset = ({ data, assets, range }: Props) => {
  const device: any = assets.find((item: any) => {
    return item.device.deviceId === data.device;
  });

  return +data.engine_hour_total + +device?.engineHoursOffset;
};
