import { useAppSelector } from 'store/hook';
import { truncateTo2dp } from 'utils/helpers/general';
import { isDarkTheme } from 'utils/theme';

const formatTooltip = (value: string | number, name: string) => {
  return typeof value === 'number' && name !== 'Trip num'
    ? truncateTo2dp(value)
    : value;
};

const ChartTooltip = ({ active, payload, fuelChart = false }: any) => {
  const unitSystem = useAppSelector((state) => state.authReducer).customer
    .unitSystem;
  const theme = useAppSelector((state) => state.authReducer).customer.theme;
  const enableDarkTheme = isDarkTheme(theme);
  if (active && payload && payload.length) {
    return (
      <div
        style={{
          backgroundColor: enableDarkTheme
            ? 'rgba(0,0,0,.8)'
            : 'rgba(225,225,225,.8)',
          padding: '5px 10px',
          borderRadius: '5px',
        }}
      >
        {payload.map((item: Record<string, any>) => (
          <p
            key={item.dataKey}
            style={{
              color: item.color,
            }}
          >
            {fuelChart
              ? `${item.payload.name} Fuel : ${formatTooltip(
                  item.value,
                  item.name
                )} ${unitSystem === 'metric' ? 'litres' : 'gals'} `
              : `${item.name} : ${formatTooltip(item.value, item.name)}`}
          </p>
        ))}
      </div>
    );
  } else return <></>;
};

export default ChartTooltip;
