import { type Auth0ClientOptions } from '@auth0/auth0-spa-js';
import { getEnvVariable } from 'utils/helpers/getEnvVariable';

interface IAuth0ClientOptions extends Auth0ClientOptions {
  domain: string;
  clientId: string;
  audience: string;
  redirectURI: string;
}

const authClientOptions: IAuth0ClientOptions = {
  domain: getEnvVariable('REACT_APP_AUTH0_DOMAIN'),
  clientId: getEnvVariable('REACT_APP_AUTH0_CLIENT_ID'),

  audience: getEnvVariable('REACT_APP_AUTH0_AUDIENCE'),
  redirectURI: getEnvVariable('REACT_APP_REDIRECT_URI'),
};

export default authClientOptions;
