import { Base } from 'components/Containers/BaseContainer';
import { ToolbarContainer } from 'components/Containers/ToolbarContainer';
import SWTab from 'components/Layout/Tabs/SWTab';
import { TabList, TabPanel, Tabs } from 'react-tabs';
import { AssetListView } from './AssetView/AssetView';
import { DeviceListView } from './DeviceView/DeviceView';
import { ExternalUserView } from './ExternalUserView/ExternalUserView';
import { OperatorListView } from './OperatorView/OperatorView';
import { useAppSelector } from 'store/hook';
import { isDarkTheme } from 'utils/theme';

/**
 * Renders a tabbed view for managing entities.
 *
 * Current tabs:
 * - Users
 * - Assets
 * - Operator
 */
export const EntityManagementView = () => {
  const theme = useAppSelector((state) => state.authReducer).customer.theme;
  const enableDarkTheme = isDarkTheme(theme);

  return (
    <Base enableDarkTheme={enableDarkTheme}>
      <Tabs>
        <TabList>
          <ToolbarContainer
            style={{
              paddingBottom: 0,
            }}
            enableDarkTheme={enableDarkTheme}
          >
            {/* BUG: The Permission tag causes MUI to detect the SWTab and create empty tab indexes */}
            <SWTab enableDarkTheme={enableDarkTheme}>Users </SWTab>
            <SWTab enableDarkTheme={enableDarkTheme}>Assets </SWTab>
            <SWTab enableDarkTheme={enableDarkTheme}>Operator </SWTab>
            <SWTab enableDarkTheme={enableDarkTheme}>Device </SWTab>
          </ToolbarContainer>
        </TabList>

        {/* USER LIST VIEW CONTENT */}
        {/* BUG: The Permission tag causes MUI to detect the SWTab and create empty tab indexes */}

        <TabPanel
          style={{
            padding: '11px 21px 21px',
          }}
        >
          <ExternalUserView />
        </TabPanel>

        {/* ASSET LIST VIEW CONTENT */}
        <TabPanel
          style={{
            padding: '11px 21px 21px',
          }}
        >
          <AssetListView />
        </TabPanel>

        {/* Operator LIST VIEW CONTENT */}
        <TabPanel
          style={{
            padding: '11px 21px 21px',
          }}
        >
          <OperatorListView />
        </TabPanel>

        {/* Device LIST VIEW CONTENT */}
        <TabPanel
          style={{
            padding: '11px 21px 21px',
          }}
        >
          <DeviceListView />
        </TabPanel>
      </Tabs>
    </Base>
  );
};
