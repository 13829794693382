import { type SyntheticEvent, useEffect, useState } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { useAppSelector } from 'store/hook';
import { isDarkTheme } from 'utils/theme';
import { ThemePalette } from 'mui.theme';
import {
  LinearProgress,
  Paper,
  Stack,
  Typography,
  Grid,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import { paperTabCardStyle, paperTabCardStyleDark, StyledSpan } from './styles';
import { type AssetTypeGeoTripData } from 'types/geo.types';
import { convertSeconds } from 'utils/helpers/dateTime';
import BorderLinearProgress from 'components/Progress/BorderLinearProgress';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

const formatCustomMessage = (key: string, analysis: Record<string, any>) => {
  switch (key) {
    case 'total_possible_time_savings':
      if (analysis.total_possible_time_savings > 0) {
        return `
        Average trip time was ${analysis.average_time_per_trip.toFixed(
          2
        )} seconds. 
        Total time taken for trips was  ${analysis.total_time_in_trips.toFixed(
          2
        )} seconds.
        Total possible time savings can be ${analysis.total_possible_time_savings.toFixed(
          2
        )} seconds.
        Trips that took more than average time: ${
          analysis.trips_more_than_average_time
        }
        `;
      } else {
        return ``;
      }

    case 'possible_increase_in_tonnage_per_trip':
      if (analysis.possible_increase_in_tonnage_per_trip > 0) {
        return `Average load per trip was ${analysis.average_ton_per_trip.toFixed(
          2
        )} tons. 
        Maximum load per trip was ${analysis.max_ton_per_trip.toFixed(2)} tons.
        It can be increased by ${analysis.possible_increase_in_tonnage_per_trip.toFixed(
          2
        )} tons.`;
      } else {
        return ``;
      }

    case 'total_possible_distance_savings':
      if (analysis.total_possible_distance_savings > 0) {
        return `Average distance per trip was ${analysis.average_distance_per_trip.toFixed(
          2
        )} Kms. 
        Total distance for trips was  ${analysis.total_distance.toFixed(2)} Kms.
        Total possible distance savings can be ${analysis.total_possible_distance_savings.toFixed(
          2
        )} Kms.
        Trips that traveled more than average distance: ${
          analysis.trips_more_than_average_distance
        }
        `;
      } else {
        return ``;
      }
  }
};

const renderAnalysisData = (analysis: Record<string, any>) => {
  const timeSavingsMessage = formatCustomMessage(
    'total_possible_time_savings',
    analysis
  );
  const tonnageIncreaseMessage = formatCustomMessage(
    'possible_increase_in_tonnage_per_trip',
    analysis
  );
  const distanceSavingsMessage = formatCustomMessage(
    'total_possible_distance_savings',
    analysis
  );

  return (
    <Grid container spacing={2}>
      {timeSavingsMessage && (
        <Grid item xs={12}>
          <Paper style={{ padding: '10px', margin: '10px' }}>
            <Typography variant="body1">{timeSavingsMessage}</Typography>
          </Paper>
        </Grid>
      )}
      {tonnageIncreaseMessage && (
        <Grid item xs={12}>
          <Paper style={{ padding: '10px', margin: '10px' }}>
            <Typography variant="body1">{tonnageIncreaseMessage}</Typography>
          </Paper>
        </Grid>
      )}
      {distanceSavingsMessage && (
        <Grid item xs={12}>
          <Paper style={{ padding: '10px', margin: '10px' }}>
            <Typography variant="body1">{distanceSavingsMessage}</Typography>
          </Paper>
        </Grid>
      )}
    </Grid>
  );
};
interface AllAssetsGeoDataTabsProps {
  type: string;
  date: string;
  geoHandler: any;
  startDate: Date;
  analysis: Record<string, unknown>;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const TRIPGOAL = 65;

export default function AllAssetsGeoDataTabs({
  type,
  date,
  geoHandler,
  startDate,
  analysis,
}: AllAssetsGeoDataTabsProps) {
  const [open, setOpen] = useState(false);

  const [value, setValue] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  // const [getAnalysis, setAnalysis] = useState(analysis);

  const [allAssets, setAllAssets] = useState<AssetTypeGeoTripData>();

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const assetTypeData = await geoHandler.getAssetTypeTrips(date);
      setAllAssets(assetTypeData);
      setIsLoading(false);
      localStorage.setItem(
        'tripsSummary',
        JSON.stringify({
          date: startDate,
          data: assetTypeData,
        })
      );
    };

    const savedData = localStorage.getItem('tripsSummary');
    if (savedData) {
      const { date, data } = JSON.parse(savedData);
      const d1 = new Date(date);
      const d2 = new Date(startDate);
      if (
        d1.getFullYear() !== d2.getFullYear() ||
        d1.getMonth() !== d2.getMonth() ||
        d1.getDate() !== d2.getDate()
      ) {
        void fetchData();
      } else {
        setAllAssets(data);
        setIsLoading(false);
      }
    } else {
      void fetchData();
    }
  }, [startDate]);

  const handleChange = (event: SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const { theme } = useAppSelector((state) => state.authReducer).customer;
  const enableDarkTheme = isDarkTheme(theme);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab
            label="Haulers"
            {...a11yProps(0)}
            style={{
              color: enableDarkTheme
                ? ThemePalette.typography.white
                : ThemePalette.typography.black,
              textTransform: 'capitalize',
              fontWeight: 'bold',
            }}
          />
          {/* <Tab
            label="Dozers"
            {...a11yProps(1)}
            style={{
              color: enableDarkTheme
                ? ThemePalette.typography.white
                : ThemePalette.typography.black,
              textTransform: 'capitalize',
              fontWeight: 'bold',
            }}
          /> */}
          {/* <Tab label="Item Three" {...a11yProps(2)} /> */}
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Paper
            elevation={7}
            sx={enableDarkTheme ? paperTabCardStyleDark : paperTabCardStyle}
          >
            {isLoading ? (
              <Stack sx={{ width: '100%', color: 'grey.200' }} spacing={2}>
                <LinearProgress />
                <LinearProgress />
                <LinearProgress />
              </Stack>
            ) : (
              <>
                <Typography
                  variant="body2"
                  style={{ fontWeight: 'bold', fontSize: '12px' }}
                >
                  Total Payload
                </Typography>
                <Typography
                  variant="h4"
                  style={{
                    fontWeight: 'bold',
                    color: '#497bfa',
                    padding: '5px 0',
                  }}
                >
                  {allAssets?.haul_truck.total_load}
                  <StyledSpan enableDarkTheme={enableDarkTheme}>
                    {allAssets?.haul_truck.load_unit}
                  </StyledSpan>
                </Typography>
                <Typography
                  variant="body2"
                  style={{ fontWeight: 'bold', fontSize: '12px' }}
                >
                  Average Payload : {allAssets?.haul_truck.avg_load_ton}
                  <StyledSpan enableDarkTheme={enableDarkTheme}>
                    {allAssets?.haul_truck.load_unit}
                  </StyledSpan>
                </Typography>
              </>
            )}
          </Paper>
          <Paper
            elevation={7}
            sx={enableDarkTheme ? paperTabCardStyleDark : paperTabCardStyle}
          >
            {isLoading ? (
              <Stack sx={{ width: '100%', color: 'grey.200' }} spacing={2}>
                <LinearProgress />
                <LinearProgress />
                <LinearProgress />
              </Stack>
            ) : (
              <>
                <Typography
                  variant="body2"
                  style={{ fontWeight: 'bold', fontSize: '12px' }}
                >
                  Total Trip Time
                </Typography>
                <Typography
                  variant="h4"
                  style={{
                    fontWeight: 'bold',
                    color: '#497bfa',
                    padding: '5px 0',
                  }}
                >
                  {allAssets
                    ? convertSeconds(allAssets.haul_truck.total_trip_time).hours
                    : '--'}
                  <StyledSpan enableDarkTheme={enableDarkTheme}>Hrs</StyledSpan>
                  {allAssets
                    ? convertSeconds(allAssets.haul_truck.total_trip_time)
                        .minutes
                    : '--'}
                  <StyledSpan enableDarkTheme={enableDarkTheme}>
                    Mins
                  </StyledSpan>
                  {allAssets
                    ? convertSeconds(allAssets.haul_truck.total_trip_time)
                        .seconds
                    : '--'}
                  <StyledSpan enableDarkTheme={enableDarkTheme}>Sec</StyledSpan>
                </Typography>
                <Typography
                  variant="body2"
                  style={{
                    fontWeight: 'bold',
                    fontSize: '12px',
                    display: 'flex',
                    justifyContent: 'space-between',
                  }}
                >
                  <div>
                    Max : <br />
                    {allAssets ? (
                      convertSeconds(allAssets.haul_truck.max_trip_time).hours >
                      0 ? (
                        <>
                          {
                            convertSeconds(allAssets?.haul_truck.max_trip_time)
                              .hours
                          }
                          <StyledSpan enableDarkTheme={enableDarkTheme}>
                            Hrs
                          </StyledSpan>
                        </>
                      ) : null
                    ) : (
                      '--'
                    )}
                    {allAssets ? (
                      convertSeconds(allAssets.haul_truck.max_trip_time)
                        .minutes > 0 ? (
                        <>
                          {
                            convertSeconds(allAssets?.haul_truck.max_trip_time)
                              .minutes
                          }
                          <StyledSpan enableDarkTheme={enableDarkTheme}>
                            Mins
                          </StyledSpan>
                        </>
                      ) : null
                    ) : (
                      '--'
                    )}
                    {allAssets
                      ? convertSeconds(allAssets.haul_truck.max_trip_time)
                          .seconds
                      : '--'}
                    <StyledSpan enableDarkTheme={enableDarkTheme}>
                      Sec
                    </StyledSpan>
                  </div>
                  <div>
                    Avg : <br />
                    {allAssets ? (
                      convertSeconds(allAssets.haul_truck.avg_trip_time).hours >
                      0 ? (
                        <>
                          {
                            convertSeconds(allAssets?.haul_truck.avg_trip_time)
                              .hours
                          }
                          <StyledSpan enableDarkTheme={enableDarkTheme}>
                            Hrs
                          </StyledSpan>
                        </>
                      ) : null
                    ) : (
                      '--'
                    )}
                    {allAssets ? (
                      convertSeconds(allAssets.haul_truck.avg_trip_time)
                        .minutes > 0 ? (
                        <>
                          {
                            convertSeconds(allAssets?.haul_truck.avg_trip_time)
                              .minutes
                          }
                          <StyledSpan enableDarkTheme={enableDarkTheme}>
                            Mins
                          </StyledSpan>
                        </>
                      ) : null
                    ) : (
                      '--'
                    )}
                    {allAssets
                      ? convertSeconds(allAssets.haul_truck.avg_trip_time)
                          .seconds
                      : '--'}
                    <StyledSpan enableDarkTheme={enableDarkTheme}>
                      Sec
                    </StyledSpan>
                  </div>
                  <div>
                    Min : <br />
                    {allAssets ? (
                      convertSeconds(allAssets.haul_truck.min_trip_time).hours >
                      0 ? (
                        <>
                          {allAssets
                            ? convertSeconds(allAssets.haul_truck.min_trip_time)
                                .hours
                            : '--'}
                          <StyledSpan enableDarkTheme={enableDarkTheme}>
                            Hrs
                          </StyledSpan>
                        </>
                      ) : null
                    ) : (
                      '--'
                    )}
                    {allAssets ? (
                      convertSeconds(allAssets.haul_truck.min_trip_time)
                        .minutes > 0 ? (
                        <>
                          {allAssets
                            ? convertSeconds(allAssets.haul_truck.min_trip_time)
                                .minutes
                            : '--'}
                          <StyledSpan enableDarkTheme={enableDarkTheme}>
                            Mins
                          </StyledSpan>
                        </>
                      ) : null
                    ) : (
                      '--'
                    )}
                    {allAssets
                      ? convertSeconds(allAssets.haul_truck.min_trip_time)
                          .seconds
                      : '--'}
                    <StyledSpan enableDarkTheme={enableDarkTheme}>
                      Sec
                    </StyledSpan>
                  </div>
                </Typography>
              </>
            )}
          </Paper>
          <Paper
            elevation={7}
            sx={enableDarkTheme ? paperTabCardStyleDark : paperTabCardStyle}
          >
            {isLoading ? (
              <Stack sx={{ width: '100%', color: 'grey.200' }} spacing={2}>
                <LinearProgress />
                <LinearProgress />
                <LinearProgress />
              </Stack>
            ) : (
              <>
                <Typography
                  variant="body2"
                  style={{ fontWeight: 'bold', fontSize: '12px' }}
                >
                  Total Trips
                </Typography>
                <Typography
                  variant="h4"
                  style={{
                    fontWeight: 'bold',
                    color: '#497bfa',
                    padding: '5px 0',
                  }}
                >
                  {allAssets?.haul_truck.total_trips}
                </Typography>

                <Typography
                  variant="small"
                  style={{
                    fontWeight: 'bold',
                    color: enableDarkTheme
                      ? ThemePalette.typography.white
                      : ThemePalette.typography.black,
                    padding: '2px 10px 3px',
                    display: 'flex',
                    justifyContent: 'flex-end',
                  }}
                >
                  Trip Goal: {TRIPGOAL}
                </Typography>
                <BorderLinearProgress
                  variant="determinate"
                  value={
                    allAssets?.haul_truck.total_trips
                      ? (allAssets.haul_truck.total_trips / TRIPGOAL) * 100
                      : 0
                  }
                />
              </>
            )}
          </Paper>
          {/* <Paper
            elevation={7}
            sx={enableDarkTheme ? paperTabCardStyleDark : paperTabCardStyle}
          >
            {isLoading ? (
              <Stack sx={{ width: '100%', color: 'grey.200' }} spacing={2}>
                <LinearProgress />
                <LinearProgress />
                <LinearProgress />
              </Stack>
            ) : (
              <>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleClickOpen}
                >
                  Show Analysis
                </Button>
                <Dialog
                  open={open}
                  onClose={handleClose}
                  maxWidth="md"
                  fullWidth
                >
                  <DialogTitle>Analysis Data</DialogTitle>
                  <DialogContent>
                    <Grid container spacing={2}>
                      {renderAnalysisData(analysis)}
                    </Grid>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleClose} color="primary">
                      Close
                    </Button>
                  </DialogActions>
                </Dialog>
              </>
            )}
          </Paper> */}
        </div>
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Paper
            elevation={7}
            sx={enableDarkTheme ? paperTabCardStyleDark : paperTabCardStyle}
          >
            {isLoading ? (
              <Stack sx={{ width: '100%', color: 'grey.200' }} spacing={2}>
                <LinearProgress />
                <LinearProgress />
                <LinearProgress />
              </Stack>
            ) : (
              <>
                <Typography
                  variant="body2"
                  style={{ fontWeight: 'bold', fontSize: '12px' }}
                >
                  Total Payload
                </Typography>
                <Typography
                  variant="h4"
                  style={{
                    fontWeight: 'bold',
                    color: '#497bfa',
                    padding: '5px 0',
                  }}
                >
                  {allAssets?.loader.total_load}
                  <StyledSpan enableDarkTheme={enableDarkTheme}>
                    {allAssets?.loader.load_unit}
                  </StyledSpan>
                </Typography>
                <Typography
                  variant="body2"
                  style={{ fontWeight: 'bold', fontSize: '12px' }}
                >
                  Average Payload : {allAssets?.loader.avg_load_ton}
                  <StyledSpan enableDarkTheme={enableDarkTheme}>
                    {allAssets?.haul_truck.load_unit}
                  </StyledSpan>
                </Typography>
              </>
            )}
          </Paper>
          <Paper
            elevation={7}
            sx={enableDarkTheme ? paperTabCardStyleDark : paperTabCardStyle}
          >
            {isLoading ? (
              <Stack sx={{ width: '100%', color: 'grey.200' }} spacing={2}>
                <LinearProgress />
                <LinearProgress />
                <LinearProgress />
              </Stack>
            ) : (
              <>
                <Typography
                  variant="body2"
                  style={{ fontWeight: 'bold', fontSize: '12px' }}
                >
                  Total Trip Time
                </Typography>
                <Typography
                  variant="h4"
                  style={{
                    fontWeight: 'bold',
                    color: '#497bfa',
                    padding: '5px 0',
                  }}
                >
                  {allAssets
                    ? convertSeconds(allAssets?.loader.total_trip_time).hours
                    : '--'}
                  <StyledSpan enableDarkTheme={enableDarkTheme}>
                    Hours
                  </StyledSpan>
                  {allAssets
                    ? convertSeconds(allAssets?.loader.total_trip_time).minutes
                    : '--'}
                  <StyledSpan enableDarkTheme={enableDarkTheme}>
                    Mins
                  </StyledSpan>
                  {allAssets
                    ? convertSeconds(allAssets?.loader.total_trip_time).seconds
                    : '--'}
                  <StyledSpan enableDarkTheme={enableDarkTheme}>
                    Seconds
                  </StyledSpan>
                </Typography>
                <Typography
                  variant="body2"
                  style={{ fontWeight: 'bold', fontSize: '12px' }}
                >
                  Max :{' '}
                  {allAssets ? (
                    convertSeconds(allAssets?.loader.max_trip_time).hours >
                    0 ? (
                      <>
                        {allAssets
                          ? convertSeconds(allAssets?.loader.max_trip_time)
                              .hours
                          : '--'}
                        <StyledSpan enableDarkTheme={enableDarkTheme}>
                          Hours
                        </StyledSpan>
                      </>
                    ) : null
                  ) : (
                    '--'
                  )}
                  {allAssets ? (
                    convertSeconds(allAssets?.loader.max_trip_time).minutes >
                    0 ? (
                      <>
                        {allAssets
                          ? convertSeconds(allAssets?.loader.max_trip_time)
                              .minutes
                          : '--'}
                        <StyledSpan enableDarkTheme={enableDarkTheme}>
                          Mins
                        </StyledSpan>
                      </>
                    ) : null
                  ) : (
                    '--'
                  )}
                  {allAssets
                    ? convertSeconds(allAssets?.loader.max_trip_time).seconds
                    : '--'}
                  <StyledSpan enableDarkTheme={enableDarkTheme}>
                    Seconds
                  </StyledSpan>
                  <br />
                  Avg :{' '}
                  {allAssets ? (
                    convertSeconds(allAssets?.loader.avg_trip_time).hours >
                    0 ? (
                      <>
                        {allAssets
                          ? convertSeconds(allAssets?.loader.avg_trip_time)
                              .hours
                          : '--'}
                        <StyledSpan enableDarkTheme={enableDarkTheme}>
                          Hours
                        </StyledSpan>
                      </>
                    ) : null
                  ) : (
                    '--'
                  )}
                  {allAssets ? (
                    convertSeconds(allAssets?.loader.avg_trip_time).minutes >
                    0 ? (
                      <>
                        {allAssets
                          ? convertSeconds(allAssets?.loader.avg_trip_time)
                              .minutes
                          : '--'}
                        <StyledSpan enableDarkTheme={enableDarkTheme}>
                          Mins
                        </StyledSpan>
                      </>
                    ) : null
                  ) : (
                    '--'
                  )}
                  {allAssets
                    ? convertSeconds(allAssets?.loader.avg_trip_time).seconds
                    : '--'}
                  <StyledSpan enableDarkTheme={enableDarkTheme}>
                    Seconds
                  </StyledSpan>
                  <br />
                  Min :{' '}
                  {allAssets ? (
                    convertSeconds(allAssets?.loader.min_trip_time).hours >
                    0 ? (
                      <>
                        {allAssets
                          ? convertSeconds(allAssets?.loader.min_trip_time)
                              .hours
                          : '--'}
                        <StyledSpan enableDarkTheme={enableDarkTheme}>
                          Hours
                        </StyledSpan>
                      </>
                    ) : null
                  ) : (
                    '--'
                  )}
                  {allAssets ? (
                    convertSeconds(allAssets?.loader.min_trip_time).minutes >
                    0 ? (
                      <>
                        {allAssets
                          ? convertSeconds(allAssets?.loader.min_trip_time)
                              .minutes
                          : '--'}
                        <StyledSpan enableDarkTheme={enableDarkTheme}>
                          Mins
                        </StyledSpan>
                      </>
                    ) : null
                  ) : (
                    '--'
                  )}
                  {allAssets
                    ? convertSeconds(allAssets?.loader.min_trip_time).seconds
                    : '--'}
                  <StyledSpan enableDarkTheme={enableDarkTheme}>
                    Seconds
                  </StyledSpan>
                </Typography>
              </>
            )}
          </Paper>
          <Paper
            elevation={7}
            sx={enableDarkTheme ? paperTabCardStyleDark : paperTabCardStyle}
          >
            {isLoading ? (
              <Stack sx={{ width: '100%', color: 'grey.200' }} spacing={2}>
                <LinearProgress />
                <LinearProgress />
                <LinearProgress />
              </Stack>
            ) : (
              <>
                <Typography
                  variant="body2"
                  style={{ fontWeight: 'bold', fontSize: '12px' }}
                >
                  Total Trips
                </Typography>
                <Typography
                  variant="h4"
                  style={{
                    fontWeight: 'bold',
                    color: '#497bfa',
                    padding: '5px 0',
                  }}
                >
                  {allAssets?.loader.total_trips}
                </Typography>
              </>
            )}
          </Paper>
        </div>
        ;
      </CustomTabPanel>
      {/* <CustomTabPanel value={value} index={2}>
        Item Three
      </CustomTabPanel> */}
    </Box>
  );
}
