import { Typography } from '@mui/material';
import { InteractiveModal } from 'components/Modals/InteractiveModal/BaseModal';
import AssetHandler from 'handlers/asset.handler';
import DeviceHandler from 'handlers/device.handler';

export {};

/**
 *
 * The delete modal is a modal that is used to delete an Asset.
 */

interface Props {
  open: boolean;
  assetId: string;
  assetVin: string;
  deviceId?: string;
  handleAbort: () => void;
}

export const AssetDeactivationModal = ({
  open,
  assetId,
  assetVin,
  deviceId,
  handleAbort,
}: Props) => {
  const assetHandler = new AssetHandler();
  const deviceHandler = new DeviceHandler();
  const title = `Confirm Asset Deletion`;
  return (
    <InteractiveModal
      initialState={open}
      title={title}
      handleAbort={handleAbort}
      SuccessButtonProps={{
        onClick: async () => {
          if (deviceId) {
            await deviceHandler.updateDevice(deviceId, {
              assetId: null,
            });
          }
          await assetHandler.deleteAsset(assetId, assetVin);
          handleAbort();
        },
        disabled: false,
        label: 'Delete Asset',
      }}
      ModalContentProps={{
        gridItemProps: {
          lg: 12,
        },
      }}
    >
      <Typography key="blurb-1" variant="medium">
        Are you sure you want to delete asset {assetVin}
      </Typography>
      <Typography key="blurb-2" variant="medium"></Typography>
    </InteractiveModal>
  );
};
