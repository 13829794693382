import ListItem, { type ListItemProps } from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import useTheme from '@mui/material/styles/useTheme';

import { useNavigate } from 'react-router-dom';

import { SVG } from 'components/Asset/SVG';
import { Typography } from '@mui/material';
import { useHover } from 'hooks/useHover';
import { type SWTypographyVariant } from 'types/theme.types';

interface Props extends ListItemProps {
  path: string;
  iconName?: string;
  listItemDisplay: string;
  ignoreIcon?: boolean;
  variant?: SWTypographyVariant;
  listItemStyle?: React.CSSProperties;
  listItemTextStyle?: React.CSSProperties;
  onClick?: () => void;
}

export const SWListItem = ({
  path,
  listItemDisplay,
  iconName,
  ignoreIcon = false,
  variant = 'large',
  listItemStyle = {},
  listItemTextStyle = {},
  onClick,
  ...rest
}: Props): JSX.Element => {
  const navigate = useNavigate();
  const [hoverRef, isHovering] = useHover();
  const {
    palette: { primary },
  } = useTheme();

  const isActiveTab = window.location.pathname === path;

  return (
    <ListItem
      ref={hoverRef}
      key={path}
      disablePadding
      sx={listItemStyle}
      {...rest}
    >
      <ListItemButton
        onClick={() => {
          // if onClick is passed in, call it before navigating
          // this is useful for things like closing a drawer
          // before navigating to a new page
          // or for things like logging out
          if (onClick) onClick();

          // Since the main purpose of this component is to navigate
          // navigation call is baked into button press
          navigate(path);
        }}
        sx={{
          '&:hover': {
            backgroundColor: 'white',
          },
        }}
      >
        {ignoreIcon ? (
          <div></div>
        ) : (
          <ListItemIcon>
            <SVG
              name={iconName}
              isHovering={isHovering}
              style={{
                fill: 'white',
                stroke: 'white',
                ...(isHovering && {
                  fill: primary.main,
                  stroke: primary.main,
                }),
              }}
            />
          </ListItemIcon>
        )}
        <ListItemText
          sx={{
            wordWrap: 'break-word',
            ...listItemTextStyle,
          }}
          primary={
            <Typography
              variant={variant}
              sx={{
                color: isActiveTab
                  ? '#F5C274'
                  : isHovering
                  ? 'primary.main'
                  : 'white',
              }}
            >
              {listItemDisplay}
            </Typography>
          }
        />
      </ListItemButton>
    </ListItem>
  );
};
