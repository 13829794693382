import { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import DatePicker from 'react-datepicker';
import { Box, type SelectChangeEvent, Typography } from '@mui/material';
import { Base } from 'components/Containers/BaseContainer';
import { ToolbarContainer } from 'components/Containers/ToolbarContainer';
import { FlexBox } from 'components/Containers/FlexBox';
import { EngineHoursList } from './Components/EngineHoursList';
import EngineHoursHandler from 'handlers/engine.hours.handler';
import { useAppSelector } from 'store/hook';
import { SingleFadeLoader } from 'components/SingleFadeLoader';
import {
  EngineHoursAreaChart,
  formatTimestamp,
} from './Components/EngineHoursAreaChart';
import { convertToTwoDecimalPlaces } from 'components/Charts/Tooltip';
import { isDarkTheme } from 'utils/theme';
import { ThemePalette } from 'mui.theme';
import ExportSelector from 'views/FleetOverview/components/FilterViewBar/ExportSelector';
import { useCustomDropdownEffect } from 'utils/exports/hooks';
import { exportToPdf } from 'utils/exports/pdf';
import { selectExportFormat } from 'store/exportData.slice';
import { formatDate } from 'views/Settings/ExternalUsers/Squares/Helpers/prepareFile';

dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);

export const EngineHoursView = (): JSX.Element => {
  const engineHoursHandler = new EngineHoursHandler();
  const { isLoading } = useAppSelector((state) => state.appReducer);
  const { data, engineHoursLoaded, deviceEngineHour } = useAppSelector(
    (state) => state.engineHoursReducer
  );

  const theme = useAppSelector((state) => state.authReducer).customer.theme;
  const enableDarkTheme = isDarkTheme(theme);
  const { assets } = useAppSelector((state) => state.assetReducer);

  const [selectedRow, setSelectedRow] = useState<string>('');
  const [selectedAsset, setSelectedAsset] = useState<string>('');
  const [selectedStartDate, setStartDate] = useState(
    new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000)
  );
  const [selectedEndDate, setEndDate] = useState(new Date());

  const [exportDropdownTo, setExportDropdownTo] = useState('');
  const [exportToLabel, setExportToLabel] = useState('Export To');
  const handleExportDropDownChange = (event: SelectChangeEvent) => {
    setExportDropdownTo(event.target.value);
  };
  const [exportTo, setExportTo] = useState(false);
  const handleExportClick = (val: boolean) => {
    setExportTo(val);
  };
  const exportFormat = useAppSelector(selectExportFormat);
  useCustomDropdownEffect(
    exportDropdownTo,
    handleExportClick,
    setExportToLabel,
    setExportDropdownTo,
    [exportDropdownTo],
    ['Engine Hours', 'Page1', 'Engine Hours - ']
  );

  const handleDateChange = (range: any) => {
    const [startDate, endDate] = range;
    setStartDate(startDate);
    setEndDate(endDate);
  };

  const getEngineHourForSelectedDevice = async (
    deviceId: string,
    startApiDate: string,
    endApiDate: string
  ) => {
    await engineHoursHandler.getDeviceEngineHour(
      deviceId,
      startApiDate,
      endApiDate
    );
  };

  useEffect(() => {
    if (selectedRow === '' && data.length > 0) {
      const firstItemId = data[0].device;
      if (firstItemId) {
        setSelectedRow(firstItemId);
        setSelectedAsset(data[0].bumperNumber);
      }
    }
  }, [data]);

  useEffect(() => {
    if (selectedRow !== '') {
      const startApiDate = dayjs().format('YYYY-MM-DD');
      const endApiDate = dayjs().subtract(7, 'day').format('YYYY-MM-DD');
      void getEngineHourForSelectedDevice(
        selectedRow,
        endApiDate,
        startApiDate
      );
    }
  }, [selectedRow]);

  useEffect(() => {
    const fetchData = async () => {
      if (exportTo && exportFormat === 'pdf') {
        await exportToPdf(
          'engine-hour-div',
          `Engine Hours for ${selectedAsset}- ${formatDate(new Date())}`,
          `Engine Hours for ${selectedAsset}`,
          enableDarkTheme,
          'landscape'
        );
        handleExportClick(false);
      }
    };

    void fetchData();
  }, [exportTo, handleExportClick, exportFormat]);

  return (
    <>
      {!engineHoursLoaded ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
          }}
        >
          <SingleFadeLoader />
        </div>
      ) : (
        <>
          <Base enableDarkTheme={enableDarkTheme}>
            <ToolbarContainer enableDarkTheme={enableDarkTheme}>
              <FlexBox
                sx={{
                  width: '100%',
                  alignItems: 'center',
                  justifyContent: 'end',
                }}
              >
                <ExportSelector
                  view={''}
                  exportToLabel={exportToLabel}
                  exportTo={exportDropdownTo}
                  onExportToChange={handleExportDropDownChange}
                  enableDarkTheme={enableDarkTheme}
                />
              </FlexBox>
            </ToolbarContainer>
            <div id="engine-hour-div">
              <EngineHoursList
                data={data}
                selectedRow={selectedRow}
                setSelectedRow={setSelectedRow}
                setSelectedAsset={setSelectedAsset}
                enableDarkTheme={enableDarkTheme}
                assets={assets}
                exportTo={exportTo}
                handleExport={handleExportClick}
              />
              {data.length > 0 ? (
                <>
                  <Box
                    sx={{
                      height: '600px',
                      maxHeight: '600px',
                      backgroundColor: `${
                        enableDarkTheme
                          ? ThemePalette.dark.boxBackground
                          : ThemePalette.light.boxBackground
                      }`,
                    }}
                  >
                    {selectedRow !== '' ? (
                      <>
                        <Typography
                          sx={{
                            textAlign: 'center',
                            fontSize: '20px',
                            fontWeight: '600',
                            paddingTop: '20px',
                            color: `${
                              enableDarkTheme
                                ? ThemePalette.typography.wheat
                                : ThemePalette.typography.black
                            }`,
                          }}
                        >
                          Engine Hours for asset
                          <span
                            style={{
                              color: '#8884d8',
                            }}
                          >{`  ${selectedAsset}`}</span>
                        </Typography>
                        <div
                          style={{
                            textAlign: 'center',
                            paddingTop: '10px',
                            paddingBottom: '10px',
                          }}
                        >
                          <DatePicker
                            showIcon
                            selected={selectedStartDate}
                            onChange={handleDateChange}
                            startDate={selectedStartDate}
                            endDate={selectedEndDate}
                            maxDate={new Date(new Date().getTime())}
                            minDate={
                              new Date(
                                new Date().getTime() - 7 * 24 * 60 * 60 * 1000
                              )
                            }
                            selectsRange
                          />
                        </div>
                        {engineHoursLoaded &&
                        !isLoading &&
                        formatChartData(
                          deviceEngineHour,
                          selectedStartDate,
                          selectedEndDate
                        ).length < 1 ? (
                          <Typography
                            sx={{
                              textAlign: 'center',
                              fontSize: '20px',
                              fontWeight: '600',
                              color: `${
                                enableDarkTheme
                                  ? ThemePalette.typography.wheat
                                  : ThemePalette.typography.black
                              }`,
                            }}
                          >
                            No Engine Hour data available for the selected time
                            period.
                          </Typography>
                        ) : (
                          <EngineHoursAreaChart
                            data={formatChartData(
                              deviceEngineHour,
                              selectedStartDate,
                              selectedEndDate
                            )}
                          />
                        )}
                      </>
                    ) : (
                      <>
                        {data.length > 0 ? (
                          <Typography
                            sx={{
                              textAlign: 'center',
                              fontSize: '20px',
                              fontWeight: '600',
                            }}
                          >
                            Please select an asset above to display data
                          </Typography>
                        ) : (
                          <></>
                        )}
                      </>
                    )}
                  </Box>
                </>
              ) : (
                <></>
              )}
            </div>
          </Base>
        </>
      )}
    </>
  );
};

function formatChartData(data: any[], startDate: any, endDate: any) {
  const formattedData = data.map((item: any) => {
    return {
      ...item,
      engine_hour_total: convertToTwoDecimalPlaces(item.engine_hour_total),
      hour: formatTimestamp(item.hour),
    };
  });

  if (startDate && endDate) {
    const filteredData = formattedData.filter((item: any) =>
      isDateBetween(item.hour, startDate, endDate)
    );
    return filteredData;
  }
  return formattedData;
}

function isDateBetween(dateToCheck: any, startDate: any, endDate: any) {
  // Parse the dates using Day.js
  const parsedDateToCheck = dayjs(dateToCheck);
  const parsedStartDate = dayjs(startDate);
  const parsedEndDate = dayjs(endDate);

  // Check if the date falls between the start and end dates, including midnight
  return (
    parsedDateToCheck.isSameOrAfter(parsedStartDate.startOf('day')) &&
    parsedDateToCheck.isSameOrBefore(parsedEndDate.endOf('day'))
  );
}
