import { createSlice } from '@reduxjs/toolkit';

export interface IdleAnalyticsData {
  make: string;
  model: string;
  device: string;
  dailyEngineHours: number;
  totalEngineHours: number;
  totalWorkingHours: number;
  customerDay: string;
  totalIdleFuelConsumption: number;
  dailyIdleTimeHours: number;
  sumIdleFuelConsumption: number;
  dailyFuelConsumption: number;
  sumFuelConsumption: number;
  sumIdleTimeHours: number;
  avgIdleFuelConsumption: number;
  avgIdleTimeHours: number;
  bumperNumber: string;
}
export interface IdleAnalyticsState {
  data: IdleAnalyticsData[];
}

const initialState: IdleAnalyticsState = {
  data: [],
};

export const idleAnalyticsSlice = createSlice({
  name: 'idleAnalytics',
  initialState,
  reducers: {
    setIdleAnalytics(state, { payload }) {
      return {
        ...state,
        ...payload,
      };
    },
    clearIdleAnalytics(state) {
      return initialState;
    },
  },
});

export const { setIdleAnalytics, clearIdleAnalytics } =
  idleAnalyticsSlice.actions;
export default idleAnalyticsSlice.reducer;
