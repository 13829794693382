import { Base } from 'components/Containers/BaseContainer';
import { FlexBox } from 'components/Containers/FlexBox';
import {
  Box,
  Divider,
  type SelectChangeEvent,
  Typography,
  Grid,
} from '@mui/material';
import React, { useState, useEffect, type FC, useCallback } from 'react';
import { type MaintenanceSquares } from 'types/squares.type';
import { MultiSelector } from 'components/Form/Selectors/MultiSelector';
import { type SelectOptions } from 'types/utils.types';
import {
  handleSelectAll,
  handleSelectSingle,
} from 'views/Settings/Rules/helpers/common';
import { Selector } from 'components/Form/Selectors/Selector';
import { type Assets } from '../modals/assets.modals';
import { TypographyWithIcon, AddToReports } from '../../Helpers/squareHelper';
import { SquaresComposedChartComponent } from '../../Helpers/Graphs/Squares.composedChart';
import Colors from '../../Helpers/Graphs/styles';
import CustomRangeDatePicker, {
  type SelectedDates,
} from '../../Helpers/customDatePicker';
import { ThemePalette } from 'mui.theme';

interface HighestTotalCostsProps {
  barKeys: Array<{ name: string; color: string; label: string }>;
  fetchSquaresData: (
    selectedStartDate: Date,
    selectedEndDate: Date,
    selectedAssetIds: string[],
    sort: string,
    category: string
  ) => Promise<MaintenanceSquares[]>;
  getAssets: Assets[];
  graphId: string;
  dataType: string;
  enableDarkTheme: boolean;
}

export const HighestTotalCosts: FC<HighestTotalCostsProps> = ({
  fetchSquaresData,
  barKeys,
  dataType,
  getAssets,
  graphId,
  enableDarkTheme,
}) => {
  const [chartData, setChartData] = useState<MaintenanceSquares[]>([]);
  const [assetOptions, setAssetOptions] = useState<SelectOptions[]>([]);
  const [selectedAssets, setSelectedAssets] = useState<{
    assets: SelectOptions[];
  }>({
    assets: [],
  });
  const [selectedAssetIds, setSelectedAssetIds] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(true); // Add isLoading state
  const [sort, setSort] = useState<string>('desc');
  const [isNoData, setIsNoData] = useState<boolean>(false);

  const formerSelectedDates: any = sessionStorage.getItem(
    'datePickerSelection'
  );

  let initialStartDate = new Date();
  let initialEndDate = new Date();

  if (formerSelectedDates) {
    const dateObjs = JSON.parse(formerSelectedDates);

    initialStartDate = new Date(dateObjs.startDate);
    initialEndDate = new Date(dateObjs.endDate);
  }

  const [selectedDates, setSelectedDates] = useState<SelectedDates>({
    startDate: (() => {
      const date = initialStartDate;
      if (!formerSelectedDates) {
        date.setMonth(date.getMonth() - 110);
      }
      return date;
    })(),
    endDate: initialEndDate,
  });

  const handleDateChange = ({
    startDate,
    endDate,
  }: {
    startDate: Date | null;
    endDate: Date | null;
  }) => {
    setSelectedDates({ startDate, endDate });

    sessionStorage.setItem(
      'datePickerSelection',
      JSON.stringify({
        startDate,
        endDate,
      })
    );
  };
  // Use useCallback to memoize the function so it doesn't get recreated on every render
  const fetchData = useCallback(async () => {
    setIsLoading(true);
    if (getAssets.toString() === null) {
      setIsNoData(true);
      setIsLoading(false);
    } else {
      setIsNoData(false);
      if (selectedDates.startDate === null || selectedDates.endDate === null) {
        return;
      }
      const data = await fetchSquaresData(
        selectedDates.startDate,
        selectedDates.endDate,
        selectedAssetIds,
        sort,
        dataType
      );

      if (data.length > 0) {
        setChartData(
          data ? data.filter((item) => item.category === dataType) : []
        );
        setIsLoading(false); // Set isLoading state to false
      } else {
        setIsLoading(false); // Set isLoading state to false
        setIsNoData(true);
      }
    }
  }, [
    getAssets,
    selectedDates,
    selectedAssetIds,
    sort,
    dataType,
    fetchSquaresData,
  ]);

  // Effect for fetching data
  useEffect(() => {
    if (selectedAssetIds.length > 0) {
      void fetchData();
    }
  }, [fetchData, selectedAssetIds]);

  useEffect(() => {
    if (!getAssets || getAssets.toString() === null) {
      setIsNoData(true);
      setIsLoading(false);
      return;
    }

    setIsNoData(false);

    // Deduplicate and transform getAssets
    const assetMap = new Map();
    getAssets.forEach((asset) => {
      if (asset?.id && !assetMap.has(asset.id)) {
        assetMap.set(asset.id, {
          id: asset.id,
          display: asset.display,
        });
      }
    });

    const uniqueAssets = Array.from(assetMap.values());

    setAssetOptions(uniqueAssets);
    if (uniqueAssets.length > 0) {
      setSelectedAssets({ assets: [uniqueAssets[0]] });
    }
  }, [getAssets]);

  const handleSortChange = useCallback(
    (event: SelectChangeEvent<number>) => {
      const newSort = event.target.value.toString();
      setSort(newSort);
    },
    [getAssets]
  );

  useEffect(() => {
    if (getAssets.toString() === null) {
      setIsNoData(true);
      setIsLoading(false);

      return;
    } else {
      setIsNoData(false);
    }
    const newSelectedAssetIds = selectedAssets.assets.map((asset) => asset.id);

    setSelectedAssetIds(newSelectedAssetIds);
  }, [selectedAssets]);

  return (
    <>
      <Box
        sx={{
          backgroundColor: enableDarkTheme
            ? ThemePalette.dark.pageBackground
            : ThemePalette.light.pageBackground,
          paddingTop: '10px',
          paddingLeft: '10px',
          paddingRight: '10px',
          minWidth: '0px',
        }}
      >
        <Grid
          container
          spacing={0}
          direction="row"
          justifyContent="space-between"
          sx={{
            backgroundColor: enableDarkTheme
              ? ThemePalette.dark.pageBackground
              : ThemePalette.light.pageBackground,
          }}
        >
          <Grid item sm={7} md={7} sx={{ display: 'flex' }}>
            <Typography
              sx={{
                color: enableDarkTheme
                  ? ThemePalette.typography.white
                  : ThemePalette.typography.black,
              }}
            >
              Total {dataType} Costs for
            </Typography>

            <MultiSelector
              isDisabled={sort !== 'manually'}
              value={selectedAssets.assets}
              renderValue={(selected) => {
                return `${selected?.length || 0} Assets(s) Selected`;
              }}
              handleSelectOne={(event) => {
                handleSelectSingle(
                  event,
                  selectedAssets,
                  setSelectedAssets,
                  'assets'
                );
              }}
              handleSelectAll={() => {
                handleSelectAll(
                  assetOptions,
                  selectedAssets,
                  setSelectedAssets,
                  'assets'
                );
              }}
              selectorOptions={assetOptions}
              enableDarkTheme={enableDarkTheme}
            />
            <Selector
              minWidth="auto"
              value={sort}
              onChange={handleSortChange}
              selectorOptions={[
                { display: 'Select Manually', id: 'manually' },
                { display: 'Most Expensive', id: 'desc' },
                { display: 'Least Expensive', id: 'asc' },
              ]}
              enableDarkTheme={enableDarkTheme}
            />

            <Typography
              sx={{
                color: enableDarkTheme
                  ? ThemePalette.typography.white
                  : ThemePalette.typography.black,
              }}
            >
              For Date Range:
            </Typography>

            <CustomRangeDatePicker
              selectedDates={selectedDates}
              handleDateChange={handleDateChange}
              enableDarkTheme={enableDarkTheme}
            />
          </Grid>
          <Grid
            item
            sm={4}
            md={4}
            sx={{ display: 'flex', justifyContent: 'start' }}
          >
            <TypographyWithIcon
              text="Total PM Cost"
              color={Colors.orange}
              enableDarkTheme={enableDarkTheme}
            />
            <TypographyWithIcon
              text="Total PM Labour Cost"
              color={Colors.blue}
              enableDarkTheme={enableDarkTheme}
            />
            <TypographyWithIcon
              text="Total PM Part Cost"
              color={Colors.purple}
              enableDarkTheme={enableDarkTheme}
            />
          </Grid>
          <Grid
            item
            sm={1}
            md={1}
            sx={{ display: 'flex', justifyContent: 'flex-end' }}
          >
            <AddToReports
              graphId={graphId}
              data={chartData}
              selectedAssets={selectedAssets}
              interval={selectedDates}
            />
          </Grid>
        </Grid>

        <Divider variant="middle" sx={{ margin: '3px' }} />

        <div style={{ width: '100%', height: '100%' }}>
          <div id={graphId}>
            <SquaresComposedChartComponent
              data={chartData}
              barKeys={barKeys}
              xAxisDataKey="asset_id"
              xAxisLabel="Assets"
              isLoading={isLoading}
              isNoData={isNoData}
              yAxisLabel="Cost ($)"
              sort={sort}
              enableDarkTheme={enableDarkTheme}
            />
          </div>
        </div>
      </Box>
    </>
  );
};
