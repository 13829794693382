import { Typography } from '@mui/material';
import { InteractiveModal } from 'components/Modals/InteractiveModal/BaseModal';
import UserHandler from 'handlers/internal.user.handler';

export {};

/**
 *
 * The deactivation modal is a modal that is used to deactivate a user.
 */

interface Props {
  open: boolean;
  userId: string;
  firstName: string;
  lastName: string;
  handleAbort: () => void;
}

export const UserDeactivationModal = ({
  open,
  userId,
  firstName,
  lastName,
  handleAbort,
}: Props) => {
  const userHandler = new UserHandler();
  const title = `Confirm Deletion of ${firstName} ${lastName}`;
  return (
    <InteractiveModal
      initialState={open}
      title={title}
      handleAbort={handleAbort}
      SuccessButtonProps={{
        onClick: async () => {
          await userHandler.deactivateInternalUser(userId, firstName, lastName);
          handleAbort();
        },
        disabled: false,
        label: 'Delete User',
      }}
      ModalContentProps={{
        gridItemProps: {
          lg: 12,
        },
      }}
    >
      <Typography key="blurb-1" variant="medium">
        Upon user deletion, this user will no longer have access to the software
      </Typography>
      <Typography key="blurb-2" variant="medium">
        To resurrect a user, create a ticket with the dev team.
      </Typography>
    </InteractiveModal>
  );
};
