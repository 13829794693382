// create a Permission component which renders children if user has the correct role

import { useAuth0 } from '@auth0/auth0-react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAppSelector } from 'store/hook';
import { type BaseProps } from 'types/props.base';
import { appConfiguration } from 'utils/config/app.config';
import { type PermissionConstraint, type Role } from './permission.types';

interface Props extends BaseProps {
  roles: Role[];
  type?: PermissionConstraint;
  fallbackComponent?: JSX.Element | null;
}

export const checkPermission = (
  roles: Role[],
  userRole: Role,
  type: PermissionConstraint
): boolean => {
  if (type === 'one-of') {
    return roles.includes(userRole);
  }

  return false;
};

/**
 * A component to render children conditioned on whether or not the user is authenticated.
 * 1. If the user is not authenicated then redirect them to "/"
 * 2. No role constraints: If the route has no role constraints then render the children
 * 3. Missing user role: If the users current role is unset (no role) then render the fallback component
 * 4. Role restrictions: If the users current role is not in the `roles` allow list then render the fallback component
 * 5. Render the children
 * @param props
 * @returns The child passed into the component or a fallback component
 */
const Permission = ({
  children,
  roles,
  type = 'one-of',
  fallbackComponent = null,
}: Props): JSX.Element | null => {
  // first check if user is authenticated
  const { isAuthenticated } = useAuth0();
  const { role } = useAppSelector((state) => state.authReducer);

  const location = useLocation();

  // if not authenticated, return early
  if (!isAuthenticated) {
    return <Navigate to="/" state={{ from: location }} />;
  }

  // if this is a route without
  // role constraints, return children
  if (!roles.length) {
    return children;
  }

  // there should always be a role, if there is no role, show fallback component
  if (!role) {
    return fallbackComponent;
  }

  /*
   * If the app is in development or staging mode, then
   * add the super-user role to the users roles
   *
   * This is to allow developers to test the app without
   * having to create a new user in Auth0
   */
  if (appConfiguration.isDevelopment || appConfiguration.isStaging) {
    roles.push('developer');
  }

  // check if the current users role allows them to access this route
  const hasPermission = checkPermission(roles, role, type);

  if (!hasPermission) {
    return fallbackComponent;
  }

  return children;
};

export default Permission;
