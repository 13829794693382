import SquaresWorkOrdersHandler from 'handlers/squaresHandlers/squaresWorOrders.handler';
import { type WorkOrdersSquareHistory } from 'types/squares.type';

// Custom hook
export const useFetchAllWorkHistoryOrders = () => {
  const squaresHandler = new SquaresWorkOrdersHandler();

  const fetchData = async (): Promise<WorkOrdersSquareHistory[]> => {
    try {
      const squaresData = await squaresHandler.getAllWorkOrdersHistory();
      return squaresData;
    } catch (error) {
      console.error(error);
      return []; // Return an empty array in case of an error
    }
  };

  return fetchData;
};
