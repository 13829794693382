import dayjs from 'dayjs';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';

dayjs.extend(quarterOfYear);

export function getQuarter(monthYear: string): string {
  const date = dayjs(monthYear, 'MMM YYYY');
  const startOfQuarter = date.startOf('quarter').format('MMM');
  const endOfQuarter = date.endOf('quarter').format('MMM');
  const year = date.format('YYYY');
  return `${startOfQuarter} - ${endOfQuarter} ${year}`;
}
