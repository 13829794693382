import DtcAPI from 'api/dtc.api';
import { updateLoadingState } from 'store/app.slice';
import { getAll } from 'store/dtc.slice';
import type { DTC } from 'types/dtc.types';

import { BaseHandler } from './base.handler';

export default class DtcHandler extends BaseHandler {
  private readonly api: DtcAPI;

  constructor() {
    super();

    this.api = new DtcAPI();
  }

  async getAll(): Promise<DTC[]> {
    this.dispatch(updateLoadingState(true));

    try {
      const dtcData = await this.api.getAll();

      this.dispatch(getAll(dtcData));
      this.dispatch(updateLoadingState(false));

      return dtcData;
    } catch (_) {
      this.handleError('An error occurred while fetching dtc data.');
    }

    return [];
  }
}
