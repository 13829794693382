import { Box, type SxProps } from '@mui/material';
import { ThemePalette } from 'mui.theme';

interface Props {
  children?: JSX.Element | JSX.Element[];
  style?: SxProps;
  enableDarkTheme?: boolean;
}

export const ToolbarContainer = ({
  children,
  style,
  enableDarkTheme = false,
}: Props) => {
  return (
    <Box
      sx={{
        padding: '10px 20px',
        boxShadow: '0px 0px 3px #00000029',
        backgroundColor: enableDarkTheme
          ? ThemePalette.dark.toolBarBackground
          : ThemePalette.light.toolBarBackground,
        borderRadius: '5px 5px 0px 0px',
        display: 'flex',
        alignItems: 'center',
        maxHeight: '44px',
        color: enableDarkTheme
          ? ThemePalette.typography.white
          : ThemePalette.typography.black,
        ...style,
      }}
    >
      {children}
    </Box>
  );
};
