import { SVG } from 'components/Asset/SVG';
import { theme } from 'mui.theme';
import { type ExternalUser } from 'types/user.types';
import { IdSpan } from 'components/AgGrid/CellRenderer/ListView.styled';
import { StatusWrapper } from 'components/AgGrid/CellRenderer/CellRenderer';
export { ResetPassword } from 'components/Buttons/ResetPassword';

const { svgIcons } = theme.palette;

interface Props {
  value: string;
  data: ExternalUser;
  handleDeleteAccount: (user_id: string) => void;
  handleNameClick: (user_id: string) => void;
  handleActivateAccount: (user_id: string) => void;
}

export const DeleteAccount = ({
  data: { isActive, userId },
  handleDeleteAccount,
}: Props) => {
  if (!isActive) return null;
  return (
    <span
      onClick={() => {
        handleDeleteAccount(userId);
      }}
    >
      <SVG
        name="deleteIcon"
        style={{
          ...svgIcons?.primary,
        }}
      />
    </span>
  );
};

export const UserName = ({
  handleNameClick,
  data: { firstName, lastName, userId },
}: Props) => {
  return (
    <IdSpan
      onClick={() => {
        handleNameClick(userId);
      }}
    >
      {firstName}, {lastName}
    </IdSpan>
  );
};

export const UserStatus = ({
  data: { isActive, userId },
  handleActivateAccount,
}: Props) => {
  return (
    <span
      onClick={() => {
        if (isActive) return;
        /**
         * TODO: Handle user reset password
         */
        handleActivateAccount(userId);
      }}
    >
      <StatusWrapper isActive={isActive} />
    </span>
  );
};

export const PlainSpan = () => {
  return <span></span>;
};
