export const delay = async (ms: number) =>
  await new Promise((resolve) => setTimeout(resolve, ms));

export const copyToClipboard = async (text: string): Promise<boolean> => {
  try {
    await navigator.clipboard.writeText(text);

    return true;
  } catch (error) {
    console.log('Unable to copy to clipboard: ', error);
  }

  return false;
};
export const lineChartDefaultColorMap: string[] = [
  '#131AA9',
  '#D8A909',
  '#09D851',
  '#A00611',
  '#8D09D8',
  '#873600',
  '#283747',
];

export const darkThemeLineChartDefaultColorMap: string[] = [
  '#6773E5',
  '#F5D257',
  '#09D851',
  '#E9604F',
  '#4C9CB9',
  '#EC8B33',
  '#406060',
];

export const pastelColorMap: string[] = [
  '#51829B',
  '#7469B6',
  '#FF69B4',
  '#9BB8CD',
  '#88AB8E',
  '#FF7F50',
  '#88AB8E',
  '#AC87C5',
  '#FF4500',
  '#FFD700',
  '#748E63',
  '#D37676',
  '#FFD966',
  '#82A0D8',
  '#EEE8AA',
  '#F7C8E0',
  '#BDB76B',
  '#DA70D6',
  '#BA55D3',
  '#9370DB',
  '#804674',
  '#7286D3',
  '#7F669D',
  '#829460',
  '#F675A8',
  '#554994',
];

export const generateRandomColor = () => {
  const letters = '0123456789ABCDEF';
  let color = '#';
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};
export const truncateTo2dp = (value: string | number): string => {
  const valueToFloat = typeof value === 'string' ? parseFloat(value) : value;
  return isNaN(valueToFloat) ? '--' : valueToFloat.toFixed(2);
};

export const formatTo2dp = (value: string | number): number => {
  const valueToFloat = typeof value === 'string' ? parseFloat(value) : value;
  return isNaN(valueToFloat) ? NaN : parseFloat(valueToFloat.toFixed(2));
};

export const getUnixMsBySelectedRange = (dateRange: number) => {
  const d = new Date();
  d.setDate(d.getDate() - dateRange);
  return new Date(d).getTime();
};

export const removeLastItemFromData = (data: any, range: string) => {
  const hourlyRange = ['1Y', 'ALL'];
  const clonedData = JSON.parse(JSON.stringify(data));
  if (hourlyRange.includes(range)) {
    clonedData[0].data.pop();
  }

  return clonedData;
};

export const moveKeyToBeginningInObject = (
  obj: Record<string, unknown>,
  keyToMove: string
) => {
  if (!(keyToMove in obj)) {
    return obj;
  }
  const newObj = { [keyToMove]: obj[keyToMove] };

  for (const key in obj) {
    if (key !== keyToMove) {
      newObj[key] = obj[key];
    }
  }

  return newObj;
};
