import { Base } from 'components/Containers/BaseContainer';
import {
  Box,
  Divider,
  type SelectChangeEvent,
  Typography,
  Grid,
} from '@mui/material';
import React, { useState, useEffect, type FC } from 'react';
import { type DashboardSquares } from 'types/squares.type';
import { SquaresComposedChartComponent } from '../../../Helpers/Graphs/Squares.composedChart';
import { Selector } from 'components/Form/Selectors/Selector';
import { AddToReports } from '../../../Helpers/squareHelper';
import Colors from '../../../Helpers/Graphs/styles';
import CustomRangeDatePicker, {
  type SelectedDates,
} from '../../../Helpers/customDatePicker';
import { ThemePalette } from 'mui.theme';

interface PmVsCmVsModificationProps {
  fetchSquaresData: (
    selectedStartDate: Date,
    selectedEndDate: Date
  ) => Promise<DashboardSquares[]>;
  dataKey: string;
  dataLabel: string;
  graphHeader: string;
  yAxisLabel: string;
  enableDarkTheme: boolean;
}

export const PmVsCmVsModification: FC<PmVsCmVsModificationProps> = ({
  fetchSquaresData,
  dataKey,
  dataLabel,
  graphHeader,
  yAxisLabel,
  enableDarkTheme,
}) => {
  const [chartData, setChartData] = useState<DashboardSquares[]>([]);

  const [barKeys, setBarKeys] = useState<
    Array<{ name: string; color: string; label: string }>
  >([]); // New state for bar keys
  const [isLoading, setIsLoading] = useState(true); // Add isLoading state
  const [isNoData, setisNoData] = useState(false); // Add isLoading state

  const formerSelectedDates: any = sessionStorage.getItem(
    'datePickerSelection'
  );

  let initialStartDate = new Date();
  let initialEndDate = new Date();

  if (formerSelectedDates) {
    const dateObjs = JSON.parse(formerSelectedDates);

    initialStartDate = new Date(dateObjs.startDate);
    initialEndDate = new Date(dateObjs.endDate);
  }

  const [selectedDates, setSelectedDates] = useState<SelectedDates>({
    startDate: (() => {
      const date = initialStartDate;
      if (!formerSelectedDates) {
        date.setMonth(date.getMonth() - 110);
      }
      return date;
    })(),
    endDate: initialEndDate,
  });

  const handleDateChange = ({
    startDate,
    endDate,
  }: {
    startDate: Date | null;
    endDate: Date | null;
  }) => {
    setSelectedDates({ startDate, endDate });
    sessionStorage.setItem(
      'datePickerSelection',
      JSON.stringify({
        startDate,
        endDate,
      })
    );
  };

  useEffect(() => {
    async function fetchData() {
      setIsLoading(true); // Set isLoading state to true
      setisNoData(false);
      if (selectedDates.startDate === null || selectedDates.endDate === null) {
        return;
      }
      const data = await fetchSquaresData(
        selectedDates.startDate,
        selectedDates.endDate
      );
      const bk = [
        {
          name: dataKey,
          color: Colors.blue,
          label: dataLabel,
        },
      ];

      setBarKeys(bk);
      if (data.length > 0) {
        setChartData(data);
        setIsLoading(false); // Set isLoading state to false
      } else {
        setIsLoading(false); // Set isLoading state to false
        setisNoData(true);
      }
    }

    void fetchData();
  }, [selectedDates, fetchSquaresData]);
  return (
    <>
      <Base>
        <Box
          sx={{
            backgroundColor: enableDarkTheme
              ? ThemePalette.dark.boxBackground
              : ThemePalette.light.boxBackground,
            paddingTop: '10px',
            paddingLeft: '10px',
            paddingRight: '10px',
            minWidth: '0px',
          }}
        >
          <Grid
            container
            spacing={0}
            direction="row"
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item sm={8} md={8}>
              <Typography
                sx={{
                  color: enableDarkTheme
                    ? ThemePalette.typography.white
                    : ThemePalette.typography.black,
                }}
              >
                PM/CM <b>Work Order {graphHeader} </b>For Date Range:(s)
              </Typography>
              <CustomRangeDatePicker
                selectedDates={selectedDates}
                handleDateChange={handleDateChange}
                enableDarkTheme={enableDarkTheme}
              />
            </Grid>
            <Grid item sm={4} md={2} justifyContent="flex-end">
              <AddToReports
                graphId={'PmVsCmVsModification'}
                data={chartData}
                interval={selectedDates}
              />{' '}
            </Grid>
          </Grid>

          <Divider variant="middle" sx={{ margin: '3px' }} />

          <div style={{ width: '100%', height: '100%' }}>
            <div id="PmVsCmVsModification">
              <SquaresComposedChartComponent
                data={chartData}
                barKeys={barKeys}
                xAxisDataKey="category"
                xAxisLabel="Maintenance"
                isBarColorsSolid={false}
                sort={''}
                isLoading={isLoading}
                isNoData={isNoData}
                yAxisLabel={yAxisLabel}
                enableDarkTheme={enableDarkTheme}
              />
            </div>
          </div>
        </Box>
      </Base>
    </>
  );
};
