import React, { useEffect } from 'react';
import DatePicker from 'react-datepicker';
import { getYear, getMonth } from 'date-fns';
import 'react-datepicker/dist/react-datepicker.css';
import { FormControl } from '@mui/material';

export interface SelectedDates {
  startDate: Date | null;
  endDate: Date | null;
}

interface CustomRangeDatePickerProps {
  selectedDates: {
    startDate: Date | null;
    endDate: Date | null;
  };
  handleDateChange: ({
    startDate,
    endDate,
  }: {
    startDate: Date | null;
    endDate: Date | null;
  }) => void;
  enableDarkTheme: boolean;
}

const CustomRangeDatePicker: React.FC<CustomRangeDatePickerProps> = ({
  selectedDates,
  handleDateChange,
  enableDarkTheme,
}) => {
  const years = Array.from(
    new Array(30),
    (val, index) => getYear(new Date()) - index
  );
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  // Update theme based on enableDarkTheme prop
  useEffect(() => {
    const root = document.documentElement;
    if (enableDarkTheme) {
      root.style.setProperty('--bg-color', '#2c3e50');
      root.style.setProperty('--text-color', '#ecf0f1');
      root.style.setProperty('--border-color', '#34495e');
      root.style.setProperty('--selected-day-bg', '#34495e');
    } else {
      root.style.setProperty('--bg-color', '#fff');
      root.style.setProperty('--text-color', '#495057');
      root.style.setProperty('--border-color', '#ced4da');
      root.style.setProperty('--selected-day-bg', '#021a39');
    }
  }, [enableDarkTheme]);

  // Handler for date range change
  const handleChange = (dates: [Date | null, Date | null]) => {
    const [startDate, endDate] = dates; // Destructure the start and end dates from the array
    handleDateChange({ startDate, endDate }); // Update the parent's state
  };

  return (
    <>
      <style>
        {`
          :root {
            --bg-color: #fff;
            --text-color: #495057;
            --border-color: #ced4da;
            --selected-day-bg: #021a39;
          }

          .react-datepicker__input-container input {
            border: 1px solid var(--border-color);
            border-radius: 4px;
            padding: 6px 8px;
            color: var(--text-color);
            background-color: var(--bg-color);
            width: 100%; /* Make input responsive */
            max-width: 300px; /* Max width for input */
            font-size: 1rem; /* Responsive font size */
          }

          .react-datepicker-ignore-onclickoutside {
            color: var(--text-color);
            border-radius: 4px;
            background-color: var(--bg-color);
          }

          .react-datepicker {
            font-size: 1rem; /* Responsive font size */
            border: 1px solid var(--border-color);
            border-radius: 4px;
            background-color: var(--bg-color);
            max-width: 100%; /* Responsive container width */
          }

          .react-datepicker__header {
            background-color: var(--bg-color);
            border-bottom: 1px solid var(--border-color);
          }

          .react-datepicker__day-name,
          .react-datepicker__day {
            width: 2rem;
            line-height: 2rem;
            color: var(--text-color);
          }

          .react-datepicker__current-month,
          .react-datepicker__navigation {
            color: var(--text-color);
          }

          .react-datepicker__day--selected,
          .react-datepicker__day--in-range,
          .react-datepicker__day--in-selecting-range {
            background-color: var(--selected-day-bg);
            color: white;
          }

          .react-datepicker__year-read-view--down-arrow,
          .react-datepicker__month-read-view--down-arrow,
          .react-datepicker__month-year-read-view--down-arrow {
            color: var(--text-color);
          }
        `}
      </style>
      <DatePicker
        selectsRange // Enable range selection
        startDate={selectedDates.startDate}
        endDate={selectedDates.endDate}
        maxDate={new Date()}
        dateFormat="dd/MM/yyyy"
        onChange={handleChange}
        renderCustomHeader={({
          date,
          changeYear,
          changeMonth,
          decreaseMonth,
          increaseMonth,
          prevMonthButtonDisabled,
          nextMonthButtonDisabled,
        }) => (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              color: 'var(--text-color)',
            }}
          >
            <button
              onClick={decreaseMonth}
              disabled={prevMonthButtonDisabled}
              style={{
                backgroundColor: 'var(--bg-color)',
                color: 'var(--text-color)',
                borderColor: 'var(--border-color)',
                padding: '0.5rem',
                marginRight: '0.5rem',
                borderRadius: '4px',
              }}
            >
              {'<'}
            </button>
            <select
              value={getYear(date)}
              onChange={({ target: { value } }) => {
                changeYear(parseInt(value));
              }}
              style={{
                backgroundColor: 'var(--bg-color)',
                color: 'var(--text-color)',
                borderColor: 'var(--border-color)',
                marginRight: '0.5rem',
              }}
            >
              {years.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
            <select
              value={months[getMonth(date)]}
              onChange={({ target: { value } }) => {
                changeMonth(months.indexOf(value));
              }}
              style={{
                backgroundColor: 'var(--bg-color)',
                color: 'var(--text-color)',
                borderColor: 'var(--border-color)',
                marginRight: '0.5rem',
              }}
            >
              {months.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
            <button
              onClick={increaseMonth}
              disabled={nextMonthButtonDisabled}
              style={{
                backgroundColor: 'var(--bg-color)',
                color: 'var(--text-color)',
                borderColor: 'var(--border-color)',
              }}
            >
              {'>'}
            </button>
          </div>
        )}
      />
    </>
  );
};

export default CustomRangeDatePicker;
