export const handleFocusRemoveSelectOutline = (e: any) => {
  e.target.style.outline = 'none';
};

export function darkenColor(color: string): string {
  let hex = color.replace('#', '');
  if (hex.length === 3) {
    hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
  }

  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);

  // Reduce each RGB component by 20% for darkening
  const darkerR = Math.max(0, r - Math.round(0.2 * 255));
  const darkerG = Math.max(0, g - Math.round(0.2 * 255));
  const darkerB = Math.max(0, b - Math.round(0.2 * 255));

  // Convert back to HEX
  return `#${darkerR.toString(16).padStart(2, '0')}${darkerG
    .toString(16)
    .padStart(2, '0')}${darkerB.toString(16).padStart(2, '0')}`;
}
