import { SVG } from 'components/Asset/SVG';
import { theme } from 'mui.theme';
import { StyledIcon } from './CellRenderer.styled';

const { svgIcons } = theme.palette;

export const StatusWrapper = ({ isActive }: { isActive: boolean }) => {
  return (
    <span>
      <StyledIcon>
        <SVG
          name={isActive ? 'activeIcon' : 'inActiveIcon'}
          style={{
            ...svgIcons?.primary,
          }}
        />
      </StyledIcon>
      {isActive ? 'Active' : 'Inactive'}
    </span>
  );
};
