import { Typography } from '@mui/material';
import { InteractiveModal } from 'components/Modals/InteractiveModal/BaseModal';

export {};
interface Props {
  open: boolean;
  handleAbort: () => void;
  handleSuccess: () => void;
}

export const ConfirmClearNotificationModal = ({
  open,
  handleAbort,
  handleSuccess,
}: Props) => {
  const title = 'Confirm Clear Notifications';

  return (
    <InteractiveModal
      initialState={open}
      title={title}
      handleAbort={handleAbort}
      SuccessButtonProps={{
        onClick: async () => {
          handleSuccess();
          handleAbort();
        },
        disabled: false,
        label: 'Confirm Clear Notification',
      }}
      ModalContentProps={{
        gridItemProps: {
          lg: 12,
        },
      }}
    >
      <Typography key="blurb-1" variant="medium">
        Are you sure you want to clear the notifications?
      </Typography>
      <Typography key="blurb-2" variant="medium"></Typography>
    </InteractiveModal>
  );
};
