import { store } from 'store';
import { type Device as DeviceType } from 'types/device.types';
import { isWithinLast24Hours } from 'utils/helpers/dateTime';

export const checkDeviceStatus = (device: DeviceType): boolean[] => {
  const state = store.getState();

  const { latestTagData } = state.deviceReducer;
  const deviceLatestTags = latestTagData[`${device?.deviceId}`];

  const isMissing = !device?.deviceId;
  const isActive = isWithinLast24Hours(+deviceLatestTags?.latest_ts);
  const isXYFound = !!(device?.xOrientation && device?.yOrientation);
  return [isMissing, isActive, isXYFound];
};
