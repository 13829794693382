import * as htmlToImage from 'html-to-image';
import {
  pdf,
  Document,
  Page,
  Image,
  StyleSheet,
  View,
  Text,
} from '@react-pdf/renderer';
import { type SelectOptions } from 'types/utils.types';
import { type DashboardSquares } from 'types/squares.type';

export const prepareCsvFile = (
  chartData: any[],
  fileName: string
): File | null => {
  try {
    if (!Array.isArray(chartData) || chartData.length === 0) {
      console.error('Invalid or empty chart data.');
      return null;
    }

    const currentDateTime = formatDate(new Date());
    const formattedFileName = `${fileName}-${currentDateTime}.csv`;

    const headers = Object.keys(chartData[0]).join(',');
    const rows = chartData
      .map((row) => {
        return Object.values(row)
          .map((field) => {
            if (field === null || field === undefined) {
              // Handle null or undefined
              return '""';
            } else if (typeof field === 'object' && field !== null) {
              // If it's an object (and not null), use JSON.stringify
              return `"${JSON.stringify(field).replace(/"/g, '""')}"`;
            } else {
              // For other types (number, string, boolean), convert to string and escape double quotes
              // Use String constructor to convert field to string to avoid no-base-to-string warning
              return `"${String(field).replace(/"/g, '""')}"`;
            }
          })
          .join(',');
      })
      .join('\n');

    const csvString = `${headers}\n${rows}`;

    // Create a Blob and a File from the CSV String
    const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });
    const file = new File([blob], formattedFileName);

    return file;
  } catch (error) {
    console.error('An error occurred while preparing the CSV file:', error);
    return null;
  }
};

export const preparePdfFile = async (
  elementById: string,
  fileName: string,
  title: string,
  filterInfo: string
): Promise<Blob | null> => {
  const node = document.getElementById(elementById);
  if (!node) {
    return null;
  }
  const currentDateTime = formatDate(new Date());

  const formattedFileName = `${fileName}-${currentDateTime}.pdf`;

  try {
    // Convert the element to an image (PNG)
    const dataUrl = await htmlToImage.toPng(node);

    // Create styles
    const styles = StyleSheet.create({
      page: {
        flexDirection: 'column',
        backgroundColor: '#FFFFFF', // White background
        alignItems: 'center',
        justifyContent: 'center',
      },
      header: {
        textAlign: 'center',
        fontSize: 16,
        marginBottom: 10,
      },
      filter: {
        textAlign: 'center',
        fontSize: 12,
        marginBottom: 10,
      },
    });

    // Create document element with the image, title, and filter info
    const MyDocument = () => (
      <Document>
        <Page size="A4" style={styles.page}>
          <View style={styles.header}>
            <Text>{title}</Text>
          </View>
          <View style={styles.filter}>
            <Text>{filterInfo}</Text>
          </View>
          <Image src={dataUrl} />
        </Page>
      </Document>
    );

    // Generate the PDF blob
    const blob = await pdf(<MyDocument />).toBlob();
    const file = new File([blob], formattedFileName);
    return file;
  } catch (error) {
    console.error('Error creating PDF file:', error);
    return null;
  }
};

export const formatDate = (date: Date) => {
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');
  const hours = date.getHours().toString().padStart(2, '0');
  const minutes = date.getMinutes().toString().padStart(2, '0');
  const seconds = date.getSeconds().toString().padStart(2, '0');

  return `${year}_${month}_${day}_${hours}${minutes}${seconds}`;
};

// Updated createFilterInfo function
export const createFilterInfo = (inputData: any[]) => {
  let assetNames = '';

  if (Array.isArray(inputData)) {
    // Extract asset_id from each object and join them with a comma
    assetNames = inputData.map((item) => item.asset_id).join(', ');
  }
  if (assetNames === ', ') {
    return '';
  }
  return assetNames;
};
