import FuelAnalyticsAPI from 'api/fuelAnalytics.api';
import { BaseHandler } from './base.handler';
import { setReport, setReportLoading } from 'store/exportData.slice';
import IdleAnalyticsAPI from 'api/idleAnalytics.api';
import { updateLoadingState } from 'store/app.slice';
import GeoAPI from 'api/geo.api';
import AssetAPI from 'api/asset.api';

export default class ReportsHandler extends BaseHandler {
  private readonly api: FuelAnalyticsAPI;
  private readonly idleApi: IdleAnalyticsAPI;
  private readonly geoApi: GeoAPI;
  private readonly assetApi: AssetAPI;

  constructor() {
    super();
    this.api = new FuelAnalyticsAPI();
    this.idleApi = new IdleAnalyticsAPI();
    this.geoApi = new GeoAPI();
    this.assetApi = new AssetAPI();
  }

  async getFuelUsageReport(startDate: string, endDate: string) {
    this.dispatch(updateLoadingState(true));
    this.dispatch(setReportLoading({ fuelUsageLoading: true }));
    try {
      const fuelUsageReport = await this.api.getFuelUsageReport(
        startDate,
        endDate
      );

      const response = fuelUsageReport;

      this.dispatch(setReport({ fuelUsage: response.Items }));
      this.dispatch(updateLoadingState(false));
      this.dispatch(setReportLoading({ fuelUsageLoading: false }));
      this.handleSuccess();
    } catch (error: any) {
      let msg = '';
      if (error.isAxiosError) {
        msg = error.response.data.message as string;
      }

      this.handleError(
        'An error occured while getting the fuel usage report data: ' + msg
      );
      this.dispatch(setReportLoading({ fuelUsageLoading: false }));
    }
  }

  async getUtilizationReport(startDate: string, endDate: string) {
    this.dispatch(updateLoadingState(true));
    this.dispatch(setReportLoading({ utilizationLoading: true }));
    try {
      const response = await this.idleApi.getIdleAnalytics(startDate, endDate);

      this.dispatch(setReport({ utilization: response.data }));
      this.dispatch(updateLoadingState(false));
      this.dispatch(setReportLoading({ utilizationLoading: false }));
      this.handleSuccess();
    } catch (error: any) {
      let msg = '';
      if (error.isAxiosError) {
        msg = error.response.data.message as string;
      }

      this.handleError(
        'An error occured while getting the utilization report data data: ' +
          msg
      );
    }
  }

  async getProductionReport(startDate: string, endDate: string) {
    this.dispatch(updateLoadingState(true));
    this.dispatch(setReportLoading({ productionLoading: true }));
    try {
      const response = await this.geoApi.getAssetTypeTripsDateRange(
        startDate,
        endDate
      );

      this.dispatch(setReport({ production: response }));
      this.dispatch(updateLoadingState(false));
      this.dispatch(setReportLoading({ productionLoading: false }));
      this.handleSuccess();
    } catch (error: any) {
      let msg = '';
      if (error.isAxiosError) {
        msg = error.response.data.message as string;
      }

      this.handleError(
        'An error occured while getting the production report data data: ' + msg
      );
    }
  }

  async getReportScores(startDate: string, endDate: string) {
    try {
      const response = await this.assetApi.getAssetsOverView(
        startDate,
        endDate
      );

      this.dispatch(setReport({ score: response }));
      this.handleSuccess();
    } catch (error: any) {
      let msg = '';
      if (error.isAxiosError) {
        msg = error.response.data.message as string;
      }

      this.handleError(
        'An error occured while getting the production report data data: ' + msg
      );
    }
  }
}
