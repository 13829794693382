import {
  Box,
  TextField,
  Typography,
  type TextFieldProps,
  type TypographyProps,
} from '@mui/material';
import { ThemePalette } from 'mui.theme';

interface Props {
  name: string;
  label?: string;
  placeholder?: string;
  TextFieldProps: TextFieldProps;
  LabelProps?: TypographyProps;
  children?: JSX.Element;
  enableDarkTheme?: boolean;
}
export const SWTextField = ({
  name,
  label,
  TextFieldProps,
  LabelProps,
  children,
  placeholder,
  enableDarkTheme = false,
}: Props) => {
  return (
    <Box key={name}>
      {label && (
        <Typography
          variant="bodyBold"
          display="block"
          mb="4px"
          {...LabelProps}
          sx={{
            color: enableDarkTheme
              ? ThemePalette.typography.white
              : ThemePalette.typography.black,
          }}
        >
          {label}
        </Typography>
      )}
      <TextField placeholder={placeholder} {...TextFieldProps} />
      {children}
    </Box>
  );
};
