import { Link as RouterLink } from 'react-router-dom';
import { Link, type TypographyProps } from '@mui/material';
import { type Variant } from '@mui/material/styles/createTypography';

interface Props {
  to: string;
  replace?: boolean;
  children: React.ReactNode;
  variant?: Variant;
  color?: TypographyProps['color'];
}

export const LinkNavigateButton = ({
  to,
  replace = false,
  children,
  variant = 'body2',
  color = 'button.primary',
}: Props) => {
  return (
    <Link
      component={RouterLink}
      replace={replace}
      to={to}
      variant={variant}
      color={color}
    >
      {children}
    </Link>
  );
};
