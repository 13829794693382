import { createSlice } from '@reduxjs/toolkit';
import { type RootState } from 'store';

export interface NotificationState {
  type: 'success' | 'error' | 'warning' | 'info';
  message: string;
  show: boolean;
}

const initialState: NotificationState = {
  show: false,
  message: '',
  type: 'success',
};

export const notificationSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    showNotification(state, { payload }) {
      return {
        ...state,
        ...payload,
        show: true,
      };
    },
    clearNotification(state) {
      return {
        ...state,
        show: false,
      };
    },
  },
});

export const { showNotification, clearNotification } =
  notificationSlice.actions;

export const selectNotificationsData = (state: RootState) =>
  state.ruleNotificationsReducer.notifications;

export default notificationSlice.reducer;
