import {
  Bar,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  ComposedChart,
} from 'recharts';
import CustomTooltip from './Tooltip';
import { ThemePalette } from 'mui.theme';

interface Props {
  data: any[];
  /** ChartLInes are the attributes in the data array to plat the graph for */
  barKeys: Array<{ name: string; color: string; label: string }>;
  lineKeys?: Array<{
    name: string;
    axisName: string;
    color: string;
    strokeWidth: number;
    strokeDashArray: string;
  }>;
  xAxis: string;
  showLegend: boolean;
  yAxisStyle?: { dx: number };
  showCustomToolTip?: boolean;
  enableDarkTheme: boolean;
  legendHeight?: number;
}

const ComposedChartComponent = ({
  data,
  barKeys,
  lineKeys,
  xAxis,
  showLegend,
  yAxisStyle = {
    dx: 20,
  },
  showCustomToolTip = false,
  enableDarkTheme = false,
  legendHeight = 36,
}: Props) => {
  return (
    <ResponsiveContainer width="100%" height="100%">
      <ComposedChart
        width={650}
        height={350}
        data={data}
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid
          stroke={
            enableDarkTheme
              ? ThemePalette.dark.cartesianGrid
              : ThemePalette.light.cartesianGrid
          }
        />
        <XAxis dataKey={xAxis} />
        {barKeys.map((bk) => {
          return (
            <YAxis
              key={bk.name}
              yAxisId={bk.name}
              stroke={bk.color}
              label={{
                value: `${bk.label}`,
                angle: -90,
                position: 'insideLeft',
                fontSize: 10,
                textAnchor: 'middle',
                dy: 150,
                ...yAxisStyle,
              }}
            />
          );
        })}
        <Tooltip content={<CustomTooltip />} />
        {showLegend ? (
          <Legend
            verticalAlign="top"
            height={legendHeight}
            wrapperStyle={{ fontSize: '18px' }}
            formatter={(value) => (
              <span
                style={{
                  color: enableDarkTheme
                    ? ThemePalette.typography.white
                    : ThemePalette.typography.black,
                  fontStyle: 'italic',
                }}
              >
                {value}
              </span>
            )}
          />
        ) : (
          <></>
        )}

        {barKeys.map((bk) => {
          if (bk.name === 'CO2') {
            return (
              <Bar
                key={bk.name}
                dataKey={bk.name}
                yAxisId={bk.name}
                barSize={25}
                fill={'#dfe9f0'}
              />
            );
          }
          return (
            <Bar
              key={bk.name}
              dataKey={bk.name}
              yAxisId={bk.name}
              barSize={25}
              fill={bk.color}
            />
          );
        })}
        {lineKeys && lineKeys.length > 0 ? (
          <>
            {lineKeys.map((key) => (
              <Line
                key={key.name}
                type="monotone"
                activeDot={{ r: 8 }}
                dataKey={key.name}
                yAxisId={key.axisName}
                stroke={key.color}
                strokeWidth={key.strokeWidth}
                strokeDasharray={key.strokeDashArray}
              />
            ))}
          </>
        ) : (
          <></>
        )}
      </ComposedChart>
    </ResponsiveContainer>
  );
};

export default ComposedChartComponent;
