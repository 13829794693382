import { type ObjectSchema } from 'yup';

// This is a middleware that will validate the
//  request body,query,params against a Yup schema
export const validateYupSchema = async (
  body: any,
  schema: ObjectSchema<any>
) => {
  try {
    // Validate the request body against the schema
    await schema.validate({
      body,
    });

    return true;
  } catch (err) {
    return false;
  }
};
