import { updateLoadingState } from 'store/app.slice';
import { BaseHandler } from './base.handler';
import { type ICreateRulePayload } from 'types/payloads/rule.payload.types';
import RuleAPI from 'api/rule.api';
import {
  appendRule,
  setRules,
  updateRuleList,
  updateRuleStatus,
  removeRule,
} from 'store/rule.slice';

export default class RuleHandler extends BaseHandler {
  private readonly api: RuleAPI;
  constructor() {
    super();

    this.api = new RuleAPI();
  }

  // create rule using api
  async createRule(payload: ICreateRulePayload): Promise<void> {
    try {
      const modifiedPayload = { ...payload };
      if (modifiedPayload.conditions && modifiedPayload.conditions.length > 0) {
        modifiedPayload.conditions = modifiedPayload.conditions.map((item) => {
          if (!item.id && item.conditionId) {
            return { ...item, id: item.conditionId };
          }
          return item;
        });
      }

      const createdRule = await this.api.create(modifiedPayload);

      this.dispatch(appendRule(createdRule));

      this.handleSuccess('A new rule has been added.');
    } catch (_) {
      // show snackbar error
      this.handleError('An error occurred while adding a rule.');
    }
  }

  /**
   * Gets all rules that the current user has access to
   */
  async getAll() {
    this.dispatch(updateLoadingState(true));
    try {
      const rules = await this.api.getAllRules();

      this.dispatch(setRules(rules));
      this.handleSuccess();
    } catch (error: any) {
      let msg: string = '';

      // Extract the error message from AxiosErrors
      // TODO: Look into other errors that can be thrown
      if (error.isAxiosError) {
        msg = error.response.data.message as string;
      }

      this.handleError(
        'An error occured while retrieving the rules list: ' + msg
      );
    }
  }

  /**
   * Updates rule that specified ruleId with the payload
   */
  async updateRule(
    ruleId: string,
    payload: { ruleName: string }
  ): Promise<void> {
    this.dispatch(updateLoadingState(true));

    try {
      const updatedRule = await this.api.update(ruleId, payload);
      this.dispatch(updateRuleList(updatedRule));
      this.handleSuccess('Rule has been updated.');
    } catch (_) {
      // show snackbar error
      this.handleError('An error occurred while updating the rule.');
    }
  }

  /**
   * Updates rule's status that specified ruleId with the payload
   */
  async updateRuleStatus(ruleId: string): Promise<void> {
    this.dispatch(updateLoadingState(true));

    try {
      const updatedRule = await this.api.updateStatus(ruleId);
      this.dispatch(updateRuleStatus(updatedRule));
      this.handleSuccess('Rule has been updated.');
    } catch (_) {
      // show snackbar error
      this.handleError('An error occurred while updating the rule status.');
    }
  }

  /**
   * Updates rule's status that specified ruleId with the payload
   */
  async softDeleteRule(ruleId: string): Promise<void> {
    this.dispatch(updateLoadingState(true));

    try {
      const updatedRule = await this.api.softDelete(ruleId);
      this.dispatch(removeRule(updatedRule));
      await this.getAll();
      this.handleSuccess('Rule has been deleted.');
    } catch (_) {
      // show snackbar error
      this.handleError('An error occurred while deleting rule.');
    }
  }
}
