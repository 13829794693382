import { Box, Stack, Typography } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';
import { SWTextField } from 'components/Form/Input/SWTextField';
import { InteractiveModal } from 'components/Modals/InteractiveModal/BaseModal';
import { type Role } from 'components/Permission/permission.types';
import ExternalUserHandler from 'handlers/external.user.handler';
import { useState } from 'react';
import { useAppSelector } from 'store/hook';
import { modalInputFieldStyle } from 'styles/global.css';
import { type IUpdateCustomerPayload } from 'types/payloads/customer.payload.types';
import { type BaseModalProps } from 'types/props.base';
import { updateCustomerRequestSchema } from 'utils/schemas/customer.schema';
import { useFormIsValid } from '../../useFormIsValidHook';
import SwitchToggle from 'components/Switch';
import { isDarkTheme } from 'utils/theme';
import { ThemePalette } from 'mui.theme';

export const EditCustomerContactModal = (props: BaseModalProps) => {
  const externalUserHandler = new ExternalUserHandler();

  const { customer, role } = useAppSelector((state: any) => state.authReducer);
  const theme = useAppSelector((state) => state.authReducer).customer.theme;
  const enableDarkTheme = isDarkTheme(theme);

  const [customerUnitSystem, setCustomerUnitSystem] = useState(
    customer.unitSystem
  );

  const [customerPayload, setCustomerPayload] =
    useState<IUpdateCustomerPayload>({
      contactFirstName: customer.contactFirstName,
      contactLastName: customer.contactLastName,
      customerName: customer.name,
      email: customer.email,
      phoneNumber: customer.phoneNumber,
      internalAccountingLink:
        customer.internalAccountingLink === null
          ? ''
          : customer.internalAccountingLink,
      unitSystem: customer.unitSystem,
    });

  // Validate form as user fills out fields
  const isValidForm = useFormIsValid(
    customerPayload,
    updateCustomerRequestSchema
  );

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setCustomerPayload({
      ...customerPayload,
      [name]: value,
    });
  };

  const handleSuccess = async () => {
    await externalUserHandler.updateCurrentCustomer({
      ...customerPayload,
      unitSystem: customerUnitSystem,
    });

    props.handleAbort();
  };

  return (
    <InteractiveModal
      initialState={props.open}
      SuccessButtonProps={{
        disabled: !isValidForm,
        onClick: handleSuccess,
        label: 'SAVE EDITS',
      }}
      handleAbort={props.handleAbort}
      title={props.title}
      confirmCancellation
      enableDarkTheme={enableDarkTheme}
    >
      <CustomerNameInput
        role={role}
        customerPayload={customerPayload}
        handleChange={handleChange}
        enableDarkTheme={enableDarkTheme}
      />

      <Grid2 xs={12} lg={12} padding={'15px'} key="text-block">
        <Box
          sx={{
            height: '35px',
            backgroundColor: '#7485A2',
            display: 'flex',
            alignItems: 'center',
            paddingLeft: '14px',
            paddingTop: '8px',
            paddingBottom: '8px',
          }}
        >
          <Typography color="white" variant="bodyBold">
            Company Contact Information
          </Typography>
        </Box>
      </Grid2>

      {/* First Name TextField */}
      <SWTextField
        key="firstName"
        name="firstName"
        label="First Name*"
        TextFieldProps={{
          name: 'contactFirstName',
          value: customerPayload.contactFirstName,
          style: { minWidth: '258px' },
          onChange: handleChange,
          InputProps: {
            sx: modalInputFieldStyle,
          },
        }}
        enableDarkTheme={enableDarkTheme}
      />

      {/* Last name input */}
      <SWTextField
        key="lastName"
        name="lastName"
        label="Last Name*"
        TextFieldProps={{
          name: 'contactLastName',
          value: customerPayload.contactLastName,
          style: { minWidth: '258px' },
          onChange: handleChange,
          InputProps: {
            sx: modalInputFieldStyle,
          },
        }}
        enableDarkTheme={enableDarkTheme}
      />

      {/* Email input */}
      <SWTextField
        key="email"
        name="email"
        label="Email*"
        TextFieldProps={{
          name: 'email',
          value: customerPayload.email,
          style: { minWidth: '258px' },
          onChange: handleChange,
          InputProps: {
            sx: modalInputFieldStyle,
          },
        }}
        enableDarkTheme={enableDarkTheme}
      />
      {/* Phone number input */}
      <SWTextField
        key="phoneNumber"
        name="phoneNumber"
        label="Phone Number*"
        TextFieldProps={{
          name: 'phoneNumber',
          value: customerPayload.phoneNumber,
          style: { minWidth: '258px' },
          onChange: handleChange,
          InputProps: {
            sx: modalInputFieldStyle,
          },
        }}
        enableDarkTheme={enableDarkTheme}
      />
      <SWTextField
        key="internalAccountingLink"
        name="internalAccountingLink"
        label="Internal Accounting Link"
        TextFieldProps={{
          name: 'internalAccountingLink',
          value: customerPayload.internalAccountingLink,
          style: { minWidth: '258px' },
          onChange: handleChange,
          InputProps: {
            sx: modalInputFieldStyle,
          },
        }}
        enableDarkTheme={enableDarkTheme}
      />
      <Box>
        <Typography
          variant="bodyBold"
          style={{ fontSize: '14px' }}
          sx={{
            color: enableDarkTheme
              ? ThemePalette.typography.wheat
              : ThemePalette.typography.black,
          }}
        >
          Unit System
        </Typography>
        <Stack
          direction="row"
          spacing={1}
          alignItems="center"
          key="unit-system"
          mt="8px"
        >
          <Typography
            variant="body2"
            style={{
              fontSize: '12px',
              opacity: customerUnitSystem === 'imperial' ? '1' : '.5',
              color:
                customerUnitSystem === 'imperial'
                  ? enableDarkTheme
                    ? ThemePalette.typography.buttonLink
                    : '#1061a8'
                  : enableDarkTheme
                  ? ThemePalette.typography.secondary
                  : ThemePalette.typography.black,
            }}
            key="imperial"
          >
            Imperial
          </Typography>
          <SwitchToggle
            checked={customerUnitSystem === 'metric'}
            onChange={(e) => {
              e.target.checked
                ? setCustomerUnitSystem('metric')
                : setCustomerUnitSystem('imperial');
            }}
            inputProps={{ 'aria-label': 'Unit System' }}
          />
          <Typography
            variant="body2"
            style={{
              fontSize: '12px',
              color:
                customerUnitSystem === 'metric'
                  ? enableDarkTheme
                    ? ThemePalette.typography.buttonLink
                    : '#1061a8'
                  : enableDarkTheme
                  ? ThemePalette.typography.secondary
                  : ThemePalette.typography.black,
              opacity: customerUnitSystem === 'metric' ? '1' : '.5',
            }}
            key="metric"
          >
            Metric
          </Typography>
        </Stack>
      </Box>
    </InteractiveModal>
  );
};

/**
 * Conditionally renders the Customer name component.
 * - Directors will see a text field
 * - Non-directors will see a constant text element
 */
function CustomerNameInput(props: {
  role: Role | null;
  customerPayload: IUpdateCustomerPayload;
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  enableDarkTheme: boolean;
}) {
  if (props.role === 'director') {
    return (
      <SWTextField
        key="customerName"
        name="customerName"
        label="Customer Name*"
        TextFieldProps={{
          name: 'customerName',
          value: props.customerPayload.customerName,
          style: { minWidth: '258px' },
          onChange: props.handleChange,
          InputProps: {
            sx: modalInputFieldStyle,
          },
        }}
        enableDarkTheme={props.enableDarkTheme}
      />
    );
  }

  return (
    <Box>
      <Typography variant="bodyBold">Company Name</Typography>
      <Typography>{props.customerPayload.customerName}</Typography>
    </Box>
  );
}
