import { createSlice } from '@reduxjs/toolkit';
import { type ExternalUser } from 'types/user.types';

export interface ExternalUserState {
  externalUserList: ExternalUser[];
  error: string | null;
}

const initialState: ExternalUserState = {
  externalUserList: [],
  error: null,
};

export const externalUserSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setExternalUserList(state, { payload }: { payload: ExternalUser[] }) {
      state.externalUserList = payload;
    },
    appendExternalUserList(state, { payload }: { payload: ExternalUser }) {
      state.externalUserList.push(payload);
    },
    updateExternalUserList(state, { payload }: { payload: ExternalUser }) {
      const index = state.externalUserList.findIndex(
        (user) => user.userId === payload.userId
      );

      if (index === -1) {
        return;
      }

      state.externalUserList[index] = payload;
    },
  },
});

export const {
  setExternalUserList,
  appendExternalUserList,
  updateExternalUserList,
} = externalUserSlice.actions;

export default externalUserSlice.reducer;
