import { type ITooltipParams } from 'ag-grid-community';
import './styles.css';

export interface CustomTooltipProps {
  bgColor?: string;
  color?: string;
  children: React.ReactNode;
}

export interface CombinedTooltipProps
  extends Partial<ITooltipParams>,
    CustomTooltipProps {}

const AgGridTooltip = ({ bgColor, color, children }: CombinedTooltipProps) => {
  return (
    <div
      className="custom-tooltip"
      style={{
        backgroundColor: bgColor ?? 'white',
        color: color ?? 'black',
      }}
    >
      {children}
    </div>
  );
};

export default AgGridTooltip;
