import { type Customer } from 'types/customer.types';
import {
  type ICreateCustomerPayload,
  type IUpdateCustomerPayload,
} from 'types/payloads/customer.payload.types';
import BaseAPI from './base.api';

export default class CustomerAPI extends BaseAPI {
  async createCustomer(payload: ICreateCustomerPayload) {
    const response = await this.API.post('customer', payload);

    return response.data;
  }

  async getAll() {
    const response = await this.API.get('customer');

    return response.data;
  }

  async updateCustomer(payload: IUpdateCustomerPayload): Promise<Customer> {
    const response = await this.API.put('customer', payload);
    return response.data;
  }

  async updateCustomerTheme(payload: { theme: string }) {
    const response = await this.API.put('customer/theme', payload);
    return response.data;
  }
}
