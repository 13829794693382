import { Typography } from '@mui/material';
import { ThemePalette } from 'mui.theme';

const NoChartData = ({
  message = 'No data available for the selected time range',
  enableDarkTheme = false,
}: {
  message?: string;
  enableDarkTheme: boolean;
}) => {
  return (
    <>
      <Typography
        sx={{
          textAlign: 'center',
          fontSize: '18px',
          color: enableDarkTheme
            ? ThemePalette.typography.white
            : ThemePalette.typography.black,
        }}
      >
        {message}
      </Typography>
    </>
  );
};

export default NoChartData;
