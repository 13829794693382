import { Button, type ButtonBaseProps, type SxProps } from '@mui/material';

interface Props {
  sx?: SxProps;
  children?: JSX.Element | JSX.Element[] | string;
  onClick?: ButtonBaseProps['onClick'];
}

export const TextButton = ({ sx, children, onClick }: Props) => (
  <Button
    variant="text"
    disableRipple
    sx={{
      padding: '0px',
      color: 'button.primary',
      backgroundColor: 'transparent',
      '&:hover': {
        backgroundColor: 'transparent',
        color: 'button.primary',
      },
      ...sx,
    }}
    onClick={onClick}
  >
    {children}
  </Button>
);
/**
 * minHeight: '19px',
        minWidth: '19px',
        
 */
