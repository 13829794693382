import { FormControlLabel } from '@mui/material';
import { type ColDef } from 'ag-grid-community';
import { BaseTableList } from 'components/AgGrid/AgGrid';
import { ListWrapper } from 'components/AgGrid/CellRenderer/ListView.styled';
import StyledSwitchButton from 'components/Buttons/SwitchButton';
import { FlexBox } from 'components/Containers/FlexBox';
import * as React from 'react';
import { selectAssociatedDevices } from 'store/asset.slice';
import { useAppDispatch, useAppSelector } from 'store/hook';
import { showNotification } from 'store/notification.slice';
import { type Device } from 'types/device.types';
import { copyToClipboard } from 'utils/helpers/general';
import { DeviceInfoModal } from 'views/Settings/ExternalUsers/DeviceView/components/modal/DeviceInfoModal';
import {
  DeviceName,
  daysBehind,
  ingestDate,
  xOrientation,
  yOrientation,
} from './DeviceListCellRenderer';
import { AssetDetailModal } from './modal/AssetDetailModal';
import { WithIconRenderer } from 'components/AgGrid/CellRenderer/WithIconRenderer';
import { IconRenderer } from 'components/AgGrid/CellRenderer/IconRenderer';
import { DeviceUnlinkModal } from './modal/DeviceUnlinkModal';
import { selectLatestTagData } from 'store/device.slice';

import { isDarkTheme } from 'utils/theme';
import { ThemePalette } from 'mui.theme';

enum OpenModal {
  None,
  EditAsset,
  ViewAsset,
  DeactivateAsset,
  DeviceInfo,
  DeviceUnlink,
}

/**
 * A component that displays a list of devices
 */
export const DeviceList = () => {
  const dispatch = useAppDispatch();
  const [selectedDeviceId, setSelectedDeviceId] = React.useState('');
  const [modalOpen, setModalOpen] = React.useState(OpenModal.None);
  const [showUnassociatedDevices, setShowUnassociatedDevices] =
    React.useState<boolean>(false);

  const { assets } = useAppSelector((state) => state.assetReducer);
  // 1. devices that are not associated with an asset
  const { unassociatedDevices } = useAppSelector(
    (state) => state.deviceReducer
  );
  // 2. devices that are associated with an asset
  const associatedDevices = useAppSelector(selectAssociatedDevices);
  const latestTagData = useAppSelector(selectLatestTagData);
  const theme = useAppSelector((state) => state.authReducer).customer.theme;
  const enableDarkTheme = isDarkTheme(theme);

  // 3. device list is composed of associated and unassociated devices

  const deviceList = React.useMemo(() => {
    associatedDevices.map((device) => {
      const deviceLatestTags = latestTagData[`${device.deviceId}`];

      return Object.assign(device, deviceLatestTags);
    });
    return [...associatedDevices, ...unassociatedDevices];
  }, [associatedDevices, unassociatedDevices, latestTagData]);

  // 4. Selected asset will open in the modal
  const selectedAsset = assets.find(
    (asset) => asset?.device?.deviceId === selectedDeviceId
  );
  // 5. Selected device will open in the modal
  const selectedDevice = deviceList.find(
    (device) => device.deviceId === selectedDeviceId
  );

  const [columnDefs] = React.useState<ColDef[]>([
    {
      field: 'deviceId',
      headerName: 'Device',
      minWidth: 160,
      cellRenderer: DeviceName,
      cellRendererParams: {
        handleIdClick: (deviceId: string) => {
          setSelectedDeviceId(deviceId);
          setModalOpen(OpenModal.DeviceInfo);
        },
      },
      filter: 'agTextColumnFilter',
      sortable: true,
    },
    {
      field: 'assetBumperNumber',
      headerName: 'Bumper ID',
      minWidth: 160,
      filter: 'agTextColumnFilter',
    },
    {
      field: 'assetId',
      headerName: 'Asset ID',
      cellRenderer: WithIconRenderer,
      cellRendererParams: {
        field: 'assetId',
        iconName: 'copyIcon',
        maxLength: 8,
        onIconClick: async (data: Device) => {
          const didCopy = await copyToClipboard(data.assetId);

          // Failed to copy to clipboard, return early
          if (!didCopy) return;

          // Show notification
          dispatch(
            showNotification({
              message: 'Copied to clipboard',
              type: 'success',
            })
          );
        },
        onCellClick: async (data: Device) => {
          setSelectedDeviceId(data.deviceId);
          setModalOpen(OpenModal.ViewAsset);
        },
      },
    },
    // {
    //   field: 'deviceStatus',
    //   cellRenderer: DeviceStatus,
    //   headerName: 'Device Status',
    //   minWidth: 150,
    // },
    {
      field: 'ingestDate',
      headerName: 'Most Recent Ingest Date',
      cellRenderer: ingestDate,
    },
    {
      field: 'ingestDate',
      headerName: 'Most Recent Record Date',
    },
    {
      field: 'daysBehind',
      headerName: 'Days Behind',
      cellStyle: { textAlign: 'center' },
      cellRenderer: daysBehind,
    },
    {
      field: 'xOrientation',
      headerName: 'xOrientation',
      cellRenderer: xOrientation,
    },
    {
      field: 'yOrientation',
      headerName: 'yOrientation',
      cellRenderer: yOrientation,
    },
    {
      field: 'deviceId',
      headerName: 'Unlink Device from Asset',
      cellRenderer: IconRenderer,
      cellRendererParams: {
        renderCondition: ['assetId'],
        iconName: 'unlinkIcon',
        style: {
          display: 'flex',
          justifyContent: 'center',
        },
        onIconClick: async (data: Device) => {
          setSelectedDeviceId(data.deviceId);
          setModalOpen(OpenModal.DeviceUnlink);
        },
      },
    },
  ]);

  return (
    <>
      {
        // Unink device confirmation modal
        OpenModal.DeviceUnlink && selectedDevice && (
          <DeviceUnlinkModal
            key={`${modalOpen.toString()}-unlink-device-modal`}
            deviceData={selectedDevice}
            open={modalOpen === OpenModal.DeviceUnlink}
            handleAbort={() => {
              setModalOpen(OpenModal.None);
            }}
          />
        )
      }
      {OpenModal.ViewAsset && selectedAsset && (
        <AssetDetailModal
          key={`${modalOpen.toString()}-edit-asset-modal`}
          open={modalOpen === OpenModal.ViewAsset}
          handleAbort={() => {
            setModalOpen(OpenModal.None);
          }}
          title="Asset Detail"
          assetData={selectedAsset}
        />
      )}

      {OpenModal.DeviceInfo && selectedDevice && (
        <DeviceInfoModal
          key={`${modalOpen.toString()}-device-info-modal}`}
          deviceData={selectedDevice}
          title="Edit Device"
          open={modalOpen === OpenModal.DeviceInfo}
          handleAbort={() => {
            setModalOpen(OpenModal.None);
          }}
        />
      )}
      <FlexBox
        sx={{
          display: 'flex',
          marginRight: '2rem',
          justifyContent: 'flex-end',
        }}
      >
        <FormControlLabel
          control={<StyledSwitchButton />}
          label="Show Unlinked Devices"
          labelPlacement="start"
          checked={showUnassociatedDevices}
          onChange={() => {
            // Flip the value
            setShowUnassociatedDevices((last) => !last);
          }}
          sx={{
            color: enableDarkTheme
              ? ThemePalette.typography.wheat
              : ThemePalette.typography.black,
          }}
        />
      </FlexBox>
      <ListWrapper>
        <BaseTableList
          rowData={deviceList}
          columnDefs={columnDefs}
          // filter is ON when NOT viewing unassociated devices
          // when it is ON, show unassociated devices
          isExternalFilterPresent={!showUnassociatedDevices}
          // filter on the assetId, if does not exist show unassociated devices
          filter={(node) => !!node.data?.assetId}
        />
      </ListWrapper>
    </>
  );
};
