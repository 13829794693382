import { Typography } from '@mui/material';
import { FlexBox } from 'components/Containers/FlexBox';
import React, { useState, useEffect } from 'react';
import { Cell, Pie, PieChart } from 'recharts';
import { ThemePalette } from 'mui.theme';

interface PieProps {
  enableDarkTheme: boolean;
}

export const NoDataPieChart: React.FC<PieProps> = ({
  enableDarkTheme = false,
}) => {
  const initialData = [
    { name: 'Grey Segment', value: 4 },
    { name: 'Dark Grey Segment', value: 1 },
  ];

  return (
    <div>
      <FlexBox
        sx={{
          flexDirection: 'column',
        }}
      >
        <div style={{ width: '100%', maxWidth: 300, height: 300 }}>
          <Typography
            sx={{
              color: enableDarkTheme
                ? ThemePalette.typography.white
                : ThemePalette.typography.black,
            }}
            width={195}
            align="center"
            fontSize={20}
          >
            No Data
          </Typography>

          <PieChart width={300} height={300}>
            <>
              <Pie
                data={initialData}
                cx="35%"
                cy="45%"
                outerRadius={100}
                innerRadius={65}
                dataKey="value"
                startAngle={90}
                endAngle={-270} // Full circle
              >
                {/* Mapping through data to set colors */}
                {initialData.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={index === 0 ? '#dcdcdc' : '#a9a9a9'}
                  />
                ))}
              </Pie>
              <foreignObject x="-45" y="85" width="100%" height="100%">
                <div
                  style={{
                    textAlign: 'center',
                    lineHeight: '100px',
                    fontSize: '14px', // You can adjust the font size as needed
                    fontWeight: 'bold', // You can adjust the font weight as needed
                    color: enableDarkTheme
                      ? ThemePalette.typography.white
                      : ThemePalette.typography.black, // You can adjust the text color as needed
                  }}
                >
                  No Data Found
                </div>
              </foreignObject>{' '}
            </>
          </PieChart>
        </div>
      </FlexBox>
    </div>
  );
};
