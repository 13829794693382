import React, { useState } from 'react';
import './ServiceTimeline.css';
import { Popover, Typography, Box, Divider } from '@mui/material';
import RemoveCircle from '@mui/icons-material/RemoveCircle';
import Cancel from '@mui/icons-material/Cancel';
import Error from '@mui/icons-material/Error';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import OpenInNew from '@mui/icons-material/OpenInNew';
import { ThemePalette } from 'mui.theme';
import { type AssetsOverview } from 'types/survivalAnalysis.types';

interface Service {
  date: Date;
  numberOfServices: number;
  services: AssetsOverview[];
}

interface TimelineItemProps {
  label: string;
  services: Service[];
  daysInMonth?: number;
  enableDarkTheme: boolean;
  onOpenInNewClick: (itemId: string) => void;
}

export const ServiceTimeline: React.FC<{
  services: Service[];
  enableDarkTheme: boolean;
  onOpenInNewClick: (itemId: string) => void;
}> = ({ services, enableDarkTheme, onOpenInNewClick }) => {
  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec',
  ];

  const today = new Date();
  const currentYear = today.getFullYear();
  const currentMonth = today.getMonth();

  const servicesByMonth: Service[][] = Array.from({ length: 12 }, () => []);
  services.forEach((service) => {
    const month = service.date.getMonth();
    servicesByMonth[month].push(service);
  });

  const orderedMonths = [
    ...months
      .slice(currentMonth)
      .map((month, index) => `${month} ${currentYear}`),
    ...months
      .slice(0, currentMonth)
      .map((month, index) => `${month} ${currentYear + 1}`),
  ];

  const orderedServicesByMonth = [
    ...servicesByMonth.slice(currentMonth),
    ...servicesByMonth.slice(0, currentMonth),
  ];

  return (
    <div
      className={
        enableDarkTheme ? 'timeline-container-dark' : 'timeline-container'
      }
    >
      {orderedMonths.map((month, index) => (
        <TimelineItem
          key={index}
          label={month}
          services={orderedServicesByMonth[index]}
          daysInMonth={new Date(
            currentYear,
            ((currentMonth + index) % 12) + 1,
            0
          ).getDate()}
          enableDarkTheme={enableDarkTheme}
          onOpenInNewClick={onOpenInNewClick}
        />
      ))}
    </div>
  );
};

const TimelineItem: React.FC<TimelineItemProps> = ({
  label,
  services,
  enableDarkTheme = false,
  onOpenInNewClick,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [popoverContent, setPopoverContent] = useState<any[]>([]);
  const [popoverDate, setPopoverDate] = useState<string>('');

  const handlePopoverOpen = (
    event: React.MouseEvent<HTMLElement>,
    services: AssetsOverview[],
    date: string
  ) => {
    setAnchorEl(event.currentTarget);
    setPopoverContent(services);
    setPopoverDate(date);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const eolCount = services
    .flatMap((service) => service.services)
    .filter((s) => s.eol).length;

  return (
    <div className="timeline-item">
      <div
        className={
          enableDarkTheme ? 'circle circle-dark' : 'circle circle-light'
        }
        onClick={(e) => {
          handlePopoverOpen(
            e,
            services.flatMap((service) => service.services),
            label
          );
        }}
      >
        {eolCount}
      </div>
      <div className="label">{label}</div>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        PaperProps={{
          style: {
            backgroundColor: enableDarkTheme
              ? ThemePalette.typography.black
              : ThemePalette.typography.white,
          },
        }}
      >
        <Box p={2}>
          <Typography
            style={{
              color: enableDarkTheme
                ? ThemePalette.typography.white
                : ThemePalette.typography.black,
            }}
            variant="body2"
          >
            Assets having service in {label}
          </Typography>
          <Divider
            sx={{
              marginY: 1,
              borderColor: enableDarkTheme
                ? ThemePalette.typography.white
                : ThemePalette.typography.black,
            }}
          />
          {popoverContent.map((service, index) => (
            <Box
              key={index}
              mb={2}
              sx={{
                marginTop: 2,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'left',
                justifyContent: 'left',
              }}
            >
              <Typography
                variant="body2"
                style={{
                  color: enableDarkTheme
                    ? ThemePalette.typography.white
                    : ThemePalette.typography.black,
                }}
              >
                {service.eolDate}
              </Typography>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'left',
                }}
              >
                <Typography
                  style={{
                    color: enableDarkTheme
                      ? ThemePalette.typography.white
                      : ThemePalette.typography.black,
                  }}
                  variant="body1"
                >
                  {service.item_id}
                </Typography>
                <OpenInNew
                  style={{ marginLeft: 5, color: 'grey', fontSize: '1rem' }}
                  onClick={() => {
                    onOpenInNewClick(service.item_id);
                  }}
                />
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'left',
                }}
              >
                <Cancel style={{ color: 'red', fontSize: '1rem' }} />
                <Typography
                  variant="body2"
                  fontWeight="bold"
                  sx={{ marginLeft: 0.5 }}
                  style={{
                    color: enableDarkTheme
                      ? ThemePalette.typography.white
                      : ThemePalette.typography.black,
                  }}
                >
                  {service.eol}
                </Typography>

                <Error sx={{ marginLeft: 1, color: 'red', fontSize: '1rem' }} />
                <Typography
                  variant="body2"
                  fontWeight="bold"
                  sx={{ marginLeft: 0.5 }}
                  style={{
                    color: enableDarkTheme
                      ? ThemePalette.typography.white
                      : ThemePalette.typography.black,
                  }}
                >
                  {service.high}
                </Typography>

                <RemoveCircle
                  sx={{ marginLeft: 1, color: 'orange', fontSize: '1rem' }}
                />
                <Typography
                  variant="body2"
                  fontWeight="bold"
                  sx={{ marginLeft: 0.5 }}
                  style={{
                    color: enableDarkTheme
                      ? ThemePalette.typography.white
                      : ThemePalette.typography.black,
                  }}
                >
                  {service.med}
                </Typography>

                <CheckCircleIcon
                  sx={{ marginLeft: 1, color: 'green', fontSize: '1rem' }}
                />
                <Typography
                  variant="body2"
                  fontWeight="bold"
                  sx={{ marginLeft: 0.5 }}
                  style={{
                    color: enableDarkTheme
                      ? ThemePalette.typography.white
                      : ThemePalette.typography.black,
                  }}
                >
                  {service.low}
                </Typography>
              </Box>
            </Box>
          ))}
        </Box>
      </Popover>
    </div>
  );
};
