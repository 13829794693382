import AssetAPI from 'api/asset.api';
import { updateLoadingState } from 'store/app.slice';
import { type ICreateAssetPayload } from 'types/payloads/asset.payload.types';
import { BaseHandler } from './base.handler';
import {
  appendAsset,
  updateAssetList,
  setAssets,
  setAssetsLoaded,
  removeAsset,
  setAssetsWithExcessiveIdling,
  setIsLoadingAllAssets,
  setAssetComparison,
  setIsLoadingAssetComparison,
  setLoadedAssetComparison,
  setIsLoadingAssetComparisonByTags,
  setAssetComparisonByTags,
} from 'store/asset.slice';
import {
  setAssetList,
  setFetchingAsset,
} from 'store/fuelAnalyticsAssetList.slice';
import HandlerUtils from './util/handlerUtils.handler';

export default class AssetHandler extends BaseHandler {
  private readonly api: AssetAPI;
  private readonly handlerUtils: HandlerUtils;

  constructor() {
    super();

    this.api = new AssetAPI();
    this.handlerUtils = new HandlerUtils();
  }

  // create asset using api
  async createAsset(payload: ICreateAssetPayload): Promise<void> {
    this.dispatch(updateLoadingState(true));

    try {
      const newAsset = await this.api.create(payload);
      // refetch all operators to get the updated device details
      if (newAsset.operators.length > 0) {
        await this.handlerUtils.updateOperators();
      }
      this.dispatch(appendAsset(newAsset));

      this.handleSuccess('A new asset has been created.');

      this.dispatch(updateLoadingState(false));
    } catch (_) {
      // show snackbar error
      this.handleError('An error occurred while creating the asset.');
    }
  }

  // update asset using api
  async updateAsset(
    assetId: string,
    payload: ICreateAssetPayload
  ): Promise<void> {
    this.dispatch(updateLoadingState(true));

    try {
      const updatedAsset = await this.api.update(assetId, payload);
      // refetch all operators to get the updated device details
      await this.handlerUtils.updateOperators();
      this.dispatch(updateAssetList(updatedAsset));
      this.handleSuccess('Asset has been updated.');
    } catch (_) {
      // show snackbar error
      this.handleError('An error occurred while updating the asset.');
    }
  }

  /**
   * Gets all assets that the current user has access to
   */
  async getAll() {
    this.dispatch(setAssetsLoaded(false));
    this.dispatch(setIsLoadingAllAssets(true));
    this.dispatch(updateLoadingState(true));
    this.dispatch(setAssets([]));
    try {
      const assets = await this.api.getAll();

      this.dispatch(setAssets(assets));
      this.dispatch(setAssetsLoaded(true));
      this.handleSuccess();

      const assetsWithExtraData = await this.api.getAllAssetsIntialData();

      this.dispatch(setAssets(assetsWithExtraData));
      this.dispatch(setIsLoadingAllAssets(false));

      this.dispatch(setFetchingAsset());
      const assetsWithData = await this.api.getAllWithData();
      this.dispatch(setAssetList(assetsWithData));
    } catch (error: any) {
      let msg: string = '';

      // Extract the error message from AxiosErrors
      // TODO: Look into other errors that can be thrown
      if (error.isAxiosError) {
        msg = error.response.data.message as string;
      }

      this.handleError(
        'An error occured while retrieving the asset list: ' + msg
      );
    }
  }

  /**
   * Deletes the specified asset
   */
  async deleteAsset(assetId: string, assetVin: string): Promise<void> {
    this.dispatch(updateLoadingState(true));

    try {
      await this.api.delete(assetId);

      this.dispatch(
        removeAsset({
          assetId,
        })
      );

      this.handleSuccess(`Asset ${assetVin} has been deleted.`);

      this.dispatch(updateLoadingState(false));
    } catch (e) {
      // show snackbar error
      console.log(e);
      this.handleError(`An error occurred while deleting asset ${assetVin}.`);
    }
  }

  /**
   * Gets all assets that the current user has access to
   */
  async getAssetsWithExcessiveIdling() {
    this.dispatch(updateLoadingState(true));
    try {
      const assets = await this.api.getAssetsWithExcessiveIdling();

      this.dispatch(setAssetsWithExcessiveIdling(assets));
      this.handleSuccess();
    } catch (error: any) {
      let msg: string = '';

      // Extract the error message from AxiosErrors
      // TODO: Look into other errors that can be thrown
      if (error.isAxiosError) {
        msg = error.response.data.message as string;
      }

      this.handleError(
        'An error occured while retrieving the asset list: ' + msg
      );
    }
  }

  /**
   * Gets Asset comparison data
   */
  async getAssetsComparisonData(
    startDate: string,
    endDate: string,
    deviceIds: string[],
    assetType: string
  ) {
    this.dispatch(setIsLoadingAssetComparison());
    try {
      const resp = await this.api.getAssetsComparison({
        startDate,
        endDate,
        deviceIds,
        assetType,
      });
      this.dispatch(setAssetComparison({ ...resp, loading: true }));
      this.handleSuccess();
      this.dispatch(setLoadedAssetComparison());
    } catch (error: any) {
      let msg: string = '';

      // Extract the error message from AxiosErrors
      // TODO: Look into other errors that can be thrown
      if (error.isAxiosError) {
        msg = error.response.data.message as string;
      }

      this.handleError(
        'An error occured while retrieving the asset Comparison data: ' + msg
      );
      this.dispatch(
        setAssetComparison({ score: {}, metrics: {}, loading: false })
      );
    }
  }

  /**
   * Gets Asset comparison data
   */
  async getAssetsComparisonByTagsData(
    startDate: string,
    endDate: string,
    deviceIds: string[],
    assetType: string,
    tagName: string
  ) {
    this.dispatch(setIsLoadingAssetComparisonByTags());
    try {
      const resp = await this.api.getAssetsComparisonByTags({
        startDate,
        endDate,
        deviceIds,
        assetType,
        tagName,
      });
      this.dispatch(setAssetComparisonByTags({ ...resp, loading: false }));
      this.handleSuccess();
    } catch (error: any) {
      let msg: string = '';
      if (error.isAxiosError) {
        msg = error.response.data.message as string;
      }

      this.handleError(
        'An error occured while retrieving the asset Comparison data: ' + msg
      );
    }
  }
}
