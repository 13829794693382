import { Box } from '@mui/material';
import { SVG } from 'components/Asset/SVG';
import { LinkButton } from 'components/Buttons/LinkButton';
import { Base } from 'components/Containers/BaseContainer';
import { FlexBox } from 'components/Containers/FlexBox';
import { ToolbarContainer } from 'components/Containers/ToolbarContainer';
import { SWToolTip } from 'components/SWToolTip';
import { useState } from 'react';
import { useAppSelector } from 'store/hook';
import { isInternalUser } from 'utils/validators/role.validation';
import { UsersList } from 'views/Settings/InternalUsers/components/InternalUsersList';
import { CreateInternalUser } from './components/modals/CreateInternalUserModal';
import { isDarkTheme } from 'utils/theme';
import { ThemePalette } from 'mui.theme';

export const InternalUsersView = (): JSX.Element => {
  const [isAddUserModalOpen, setIsAddUserModalOpen] = useState(false);
  const { role } = useAppSelector((state) => state.authReducer);
  const theme = useAppSelector((state) => state.authReducer).customer.theme;
  const enableDarkTheme = isDarkTheme(theme);

  // Certain client features will be disabled using this flag
  const isInternalUserRole = isInternalUser(role);
  const isInternalUserRoleTooltip = isInternalUserRole
    ? 'This is only available to Director level users'
    : '';

  return (
    <Base enableDarkTheme={enableDarkTheme}>
      <CreateInternalUser
        key={`${isAddUserModalOpen.toString()}-create-user-modal`}
        open={isAddUserModalOpen}
        handleAbort={() => {
          setIsAddUserModalOpen(false);
        }}
        title="Add a New Symboticware User"
        enableDarkTheme={enableDarkTheme}
      />

      <ToolbarContainer enableDarkTheme={enableDarkTheme}>
        <FlexBox
          sx={{
            width: '100%',
            justifyContent: 'space-between',
          }}
        >
          <div></div>
          <FlexBox
            sx={{
              display: 'flex',
            }}
          >
            <SWToolTip title={isInternalUserRoleTooltip}>
              <Box display="flex">
                <SVG
                  name={isInternalUserRole ? 'addDisabled' : 'add'}
                  style={{
                    marginRight: '10px',
                    fill: enableDarkTheme
                      ? ThemePalette.typography.wheat
                      : ThemePalette.typography.primary,
                  }}
                />
                <LinkButton
                  onClick={() => {
                    if (isInternalUserRole) return;

                    setIsAddUserModalOpen(true);
                  }}
                  isDisabled={isInternalUserRole}
                  color={
                    enableDarkTheme
                      ? ThemePalette.typography.wheat
                      : ThemePalette.typography.primary
                  }
                >
                  Add a New User
                </LinkButton>
              </Box>
            </SWToolTip>
          </FlexBox>
        </FlexBox>
      </ToolbarContainer>
      <UsersList />
    </Base>
  );
};
