import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';
import storageSession from 'redux-persist/lib/storage/session';
import { combineReducers, configureStore } from '@reduxjs/toolkit';
import authReducer from 'store/auth.slice';
import appReducer from 'store/app.slice';
import notificationReducer from 'store/notification.slice';
import customerReducer from './customer.slice';
import internalUserReducer from 'store/internal.user.slice';
import assetReducer from 'store/asset.slice';
import externalUserReducer from 'store/external.user.slice';
import persistedReducer from 'store/persisted.slice';
import operatorReducer from 'store/operator.slice';
import ruleReducer from 'store/rule.slice';
import hierarchyReducer from 'store/hierarchy.slice';
import deviceReducer from 'store/device.slice';
import tagReducer from 'store/tag.slice';
import fuelAnalyticsReducer from 'store/fuelAnalytics.slice';
import squaresMaintenanceReducer from 'store/SquaresStore/MaintenanceSquaresState.slice';
import squaresDashboardReducer from 'store/SquaresStore/DashboardSquaresState.slice';
import squaresWorkOrdersReducer from 'store/SquaresStore/WorkOrdersSquaresState.slice';
import fuelAnalyticsToolTipReducer from './fuelAnalyticsToolTip.slice';
import fuelAnalyticsAssetListReducer from './fuelAnalyticsAssetList.slice';
import nonHaulTruckfuelAnalyticsReducer from './nonHaulTrucks.slice';
import ruleNotificationsReducer from 'store/ruleNotification.slice';
import engineHoursReducer from './engine.hours.slice';
import exportDataReducer from './exportData.slice';
import dtcReducer from './dtc.slice';
import idleAnalyticsReducer from 'store/idleAnalytics.slice';
import weatherReducer from 'store/weather.slice';
import fuelPriceReducer from 'store/fuelPrice.slice';
import survivalAnaysisOverview from 'store/survivalAnaysisStore/overview.slice';

export const rootReducer = combineReducers({
  // add reducers
  authReducer,
  appReducer,
  notificationReducer,
  customerReducer,
  internalUserReducer,
  externalUserReducer,
  persistedReducer,
  assetReducer,
  operatorReducer,
  ruleReducer,
  hierarchyReducer,
  deviceReducer,
  tagReducer,
  fuelAnalyticsReducer,
  squaresMaintenanceReducer,
  squaresDashboardReducer,
  squaresWorkOrdersReducer,
  fuelAnalyticsToolTipReducer,
  fuelAnalyticsAssetListReducer,
  nonHaulTruckfuelAnalyticsReducer,
  ruleNotificationsReducer,
  engineHoursReducer,
  exportDataReducer,
  dtcReducer,
  idleAnalyticsReducer,
  weatherReducer,
  fuelPriceReducer,
  survivalAnaysisOverview,
});

const persistConfig = {
  key: 'root',
  version: 1,
  storage: storageSession,
  // only persisted reducer will be persisted
  whitelist: ['persistedReducer'],
};

const persistedReducers = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducers,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
