import { createSlice } from '@reduxjs/toolkit';
import type { Operator } from 'types/operator.types';

export interface OperatorState {
  operators: Operator[];
}

const initialState: OperatorState = {
  operators: [],
};

export const operatorSlice = createSlice({
  name: 'operator',
  initialState,
  reducers: {
    appendOperator(state, { payload }) {
      state.operators.push(payload);
    },

    updateOperatorList(state, { payload }: { payload: Operator }) {
      const index = state.operators.findIndex(
        (operator) => operator.operatorId === payload.operatorId
      );

      if (index === -1) {
        // do not update the state if the operator is not found
        return;
      }

      state.operators[index] = payload;
    },

    setOperators(state, { payload }) {
      state.operators = payload;
    },

    removeOperator(state, { payload }: { payload: { operatorId: string } }) {
      const filtedOperators = state.operators.filter(
        (operator) => operator.operatorId !== payload.operatorId
      );
      state.operators = filtedOperators;
    },
  },
});

export const {
  appendOperator,
  updateOperatorList,
  setOperators,
  removeOperator,
} = operatorSlice.actions;

export default operatorSlice.reducer;
