export const CustomizedContent = (props: {
  root: any;
  depth: number;
  x: number;
  y: number;
  width: number;
  height: number;
  index: number;
  payload: any;
  colors: string[];
  rank: number;
  name: string;
  onClick: any;
  selectedCell: string[];
  value: any;
  enableDarkTheme?: boolean;
}) => {
  const {
    depth,
    x,
    y,
    width,
    height,
    index,
    name,
    onClick,
    selectedCell,
    value,
    enableDarkTheme = false,
  } = props;
  const handleClick = () => {
    onClick(name);
  };
  const isSelected = selectedCell.includes(name);

  return (
    <g onClick={handleClick}>
      <rect
        x={x}
        y={y}
        width={width}
        height={height}
        style={{
          fill: isSelected ? '#C04D4D' : '#2B5A8A',
          stroke: enableDarkTheme ? '#0A192D' : '#ffffff',
          strokeWidth: 2 / (depth + 1e-10),
          strokeOpacity: 1 / (depth + 1e-10),
        }}
      />
      {depth === 1 ? (
        <text
          x={x + width / 2}
          y={y + height / 2 + 7}
          textAnchor="middle"
          fill="#fff"
          fontSize={14}
          style={{
            stroke: '#ffffff',
          }}
        >
          {name}
        </text>
      ) : null}
      {depth === 1 ? (
        <text
          x={x + 4}
          y={y + 18}
          fill="#fff"
          fontSize={16}
          fillOpacity={0.9}
          style={{
            stroke: '#ffffff',
          }}
        >
          {convertAmount(value)}
        </text>
      ) : null}
    </g>
  );
};

function convertAmount(amount: any) {
  if (amount >= 1000000) {
    return `${(amount / 1000000).toFixed(2)}m`;
  } else if (amount >= 1000) {
    return `${(amount / 1000).toFixed(2)}k`;
  } else {
    return amount.toString();
  }
}
