import { useState } from 'react';
import { SVG } from 'components/Asset/SVG';
import { LinkButton } from 'components/Buttons/LinkButton';
import { FlexBox } from 'components/Containers/FlexBox';
import { AssetList } from './components/AssetList';
import { CreateAsset } from './components/modals/createAssetModal';
import { useAppSelector } from 'store/hook';
import { SingleFadeLoader } from 'components/SingleFadeLoader';
import { isDarkTheme } from 'utils/theme';
import { ThemePalette } from 'mui.theme';

export const AssetListView = () => {
  const assetsLoaded = useAppSelector(
    (state) => state.assetReducer.assetsLoaded
  );

  const theme = useAppSelector((state) => state.authReducer).customer.theme;
  const enableDarkTheme = isDarkTheme(theme);

  const [isCreateAssetModalOpen, setIsCreateAssetModalOpen] = useState(false);
  return (
    <>
      {!assetsLoaded ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
          }}
        >
          <SingleFadeLoader />
        </div>
      ) : (
        <>
          <CreateAsset
            key={`${isCreateAssetModalOpen.toString()}-create-user-modal`}
            open={isCreateAssetModalOpen}
            handleAbort={() => {
              setIsCreateAssetModalOpen(false);
            }}
            title="Add a New Asset"
            enableDarkTheme={enableDarkTheme}
          />
          <FlexBox
            sx={{
              width: '100%',
              justifyContent: 'space-between',
            }}
          >
            <div></div>
            <FlexBox
              sx={{
                display: 'flex',
              }}
            >
              <SVG
                name="add"
                style={{ marginRight: '10px' }}
                onClick={() => {
                  setIsCreateAssetModalOpen(true);
                }}
              />

              <LinkButton
                onClick={() => {
                  setIsCreateAssetModalOpen(true);
                }}
                sx={{
                  color: enableDarkTheme
                    ? ThemePalette.typography.wheat
                    : ThemePalette.typography.black,
                }}
              >
                Add Asset(s)
              </LinkButton>
            </FlexBox>
          </FlexBox>
        </>
      )}

      <AssetList />
    </>
  );
};
