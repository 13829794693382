import * as React from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

import './styles.css';
import type { InternalUser, ExternalUser } from 'types/user.types';
import type { Customer } from 'types/customer.types';
import type { Asset } from 'types/asset.types';
import type { Notification, RuleNotifications } from 'types/notification.types';
import { type IRowNode, type ColDef } from 'ag-grid-community';
import type { Operator } from 'types/operator.types';
import { type WorkOrdersSquare } from 'types/squares.type';

import { type Device } from 'types/device.types';
import { useAppDispatch, useAppSelector } from 'store/hook';
import ToExcel from 'components/Exports/ToExcel';
import { extractTableData } from 'utils/exports/excel';
import { selectExportFormat } from 'store/exportData.slice';
import ToPdf from 'components/Exports/ToPdf';
import { isDarkTheme } from 'utils/theme';

type SupportedTypes =
  | InternalUser
  | Customer
  | ExternalUser
  | Asset
  | Operator
  | Notification
  | WorkOrdersSquare
  | Device
  | RuleNotifications;

interface Props<T extends SupportedTypes> {
  rowData: T[];
  columnDefs: ColDef[];
  /**
   * Enable or disable the table `filter`
   */
  isExternalFilterPresent?: boolean;
  /**
   * Filter for AgGrid.
   * Returns true to keep an item. Returns false to remove an item
   */
  filter?: (node: IRowNode<T>) => boolean;
  cacheQuickFilter?: boolean;
  searchValue?: string;
  rowSelection?: boolean;
  exportTo?: boolean;
  handleExport?: (val: boolean) => void;
  pagination?: boolean;
  paginationPageSize?: number;
  paginationAutoPageSize?: boolean;
  exportToExcel?: boolean;
  handleExportToExcel?: (val: boolean) => void;
  onGridReady?: (params: any) => void;
  tableStyles?: any;
  getRowStyle?: any;
  exportPdf?: boolean;
}

/**
 * This component acts as the base component for data list tables
 * Cell Renderer is the react component which is displayed in the cell which its assigned to
 */
export function BaseTableList<T extends SupportedTypes>({
  rowData,
  columnDefs,
  pagination = false, // Default value for pagination
  paginationPageSize = 10, // Default value for paginationPageSize
  paginationAutoPageSize = false, // Default value for paginationAutoPageSize
  exportPdf = true,
  ...props
}: Props<T>) {
  const defaultColDef: ColDef<T> = {
    flex: 1,
    resizable: true,
  };
  const gridRef = React.useRef<AgGridReact<T>>(null);
  const dispatch = useAppDispatch();

  const exportFormat = useAppSelector(selectExportFormat);
  const theme = useAppSelector((state) => state.authReducer).customer.theme;
  const enableDarkTheme = isDarkTheme(theme);

  const setQuickFilterCallback = React.useCallback(() => {
    if (!gridRef.current?.api) return;
    gridRef.current.api.setQuickFilter(props.searchValue ?? '');
  }, [props.searchValue]);

  // This is required to inform AgGrid that the rows need to be re-filtered
  // https://www.ag-grid.com/react-data-grid/filter-external/#doesexternalfilterpass
  React.useEffect(() => {
    if (!gridRef.current?.api) return;
    gridRef.current.api.onFilterChanged();

    // this enables a quick search
    setQuickFilterCallback();
  }, [gridRef.current, props.isExternalFilterPresent, props.searchValue]);

  const [toPrint, setToPrint] = React.useState(false);

  //  set toPrint back to false without page re-render
  const handleToPrint = (val: boolean) => {
    setToPrint(val);
    if (val) {
      setTimeout(() => {
        setToPrint(false);
      }, 4000);
    }
  };

  React.useEffect(() => {
    if (props.exportTo && props.handleExport) {
      extractTableData({ handleToPrint, gridRef, dispatch });
      props.handleExport(false);
    }
  }, [props.exportTo, props.handleExport]);

  return (
    <div
      className={enableDarkTheme ? 'ag-theme-alpine-dark' : 'ag-theme-alpine'}
      style={{
        width: '100%',
        height: '894px',
        overflow: 'auto',
        ...props.tableStyles,
      }}
    >
      <AgGridReact
        ref={gridRef}
        rowData={rowData}
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
        rowSelection={props.rowSelection ? 'multiple' : undefined}
        // isExternalFilterPresent is called exactly once every time the grid senses a filter change
        // e.g: api.onFilterChanged
        // https://www.ag-grid.com/react-data-grid/filter-external/
        isExternalFilterPresent={() => !!props.isExternalFilterPresent}
        // This runs when isExternalFilterPresent returns `true`.
        // The callback is called for every row of AgGrid (`rowData`)
        // If the filter returns true then the row is kept. Otherwise the row is removed
        doesExternalFilterPass={props.filter ?? (() => true)}
        cacheQuickFilter={props.cacheQuickFilter ?? false}
        tooltipShowDelay={0}
        tooltipHideDelay={2000}
        // Using the props with default values
        pagination={pagination}
        paginationPageSize={paginationPageSize}
        paginationAutoPageSize={paginationAutoPageSize}
        onGridReady={props.onGridReady}
        getRowStyle={props.getRowStyle}
        enableBrowserTooltips={true}
      ></AgGridReact>
      {toPrint && props.handleExport && exportFormat === 'excel' && <ToExcel />}
      {toPrint && props.handleExport && exportFormat === 'pdf' && exportPdf && (
        <ToPdf />
      )}
    </div>
  );
}
