import * as htmlToImage from 'html-to-image';
import {
  pdf,
  Document,
  Page,
  Image,
  StyleSheet,
  View,
  Text,
} from '@react-pdf/renderer';
import { saveAs } from 'file-saver';

export const exportToPdf = async (
  elementById: string,
  fileName: string,
  title: string,
  enableDarkTheme: boolean,
  orientation?: 'portrait' | 'landscape' | undefined
): Promise<any> => {
  const node = document.getElementById(elementById);
  if (!node) {
    return null;
  }

  try {
    const dataUrl = await htmlToImage.toPng(node);

    const styles = StyleSheet.create({
      page: {
        backgroundColor: enableDarkTheme ? '#0A192D' : '#FFFFFF',
        width: '100%',
        height: '100%',
        margin: '0 10px 0 10px',
      },
      header: {
        textAlign: 'center',
        fontSize: 16,
        marginBottom: 4,
      },
    });
    const pageOrientation = !orientation ? 'portrait' : orientation;

    const MyDocument = () => (
      <Document>
        <Page size="A4" style={styles.page} orientation={pageOrientation}>
          <View style={styles.header}>
            <Text style={{ color: enableDarkTheme ? '#ffffff' : '#000000' }}>
              {title}
            </Text>
          </View>
          <Image src={dataUrl} />
        </Page>
      </Document>
    );

    const pdfBlob = await pdf(<MyDocument />).toBlob();

    saveAs(pdfBlob, `${fileName}`);
  } catch (error) {
    console.error('Error creating PDF file:', error);
    return null;
  }
};
