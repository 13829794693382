import { Button, Divider } from '@mui/material';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import { LinkButton } from 'components/Buttons/LinkButton';
import { TextButton } from 'components/Buttons/TextButton';
import { FlexBox } from 'components/Containers/FlexBox';
import {
  modalBackdropStyle,
  modalContentContainerStyle,
} from 'components/Modals/common.modal.styles.css';
import * as React from 'react';
import { ModalContent } from '../components/ModalContent';
import { useState } from 'react';
import { theme, ThemePalette } from 'mui.theme';
const { bg } = theme.palette;

interface Props {
  children: JSX.Element[];
  title: string;
  initialState: boolean;
  handleAbort: () => void;
  SuccessButtonProps: {
    disabled: boolean;
    onClick: () => void;
    label: string;
  };
  /**
   * Another button that can be rendered in the footer of the modal (different from abort buttons).
   */
  SecondaryButtonProps?: {
    disabled: boolean;
    onClick: () => void;
    label: string;
  };
  ModalContentProps?: {
    gridItemProps?: {
      xs?: number;
      sm?: number;
      md?: number;
      lg?: number;
      xl?: number;
    };
  };
  cancelButtonText?: string;
  modalContentMaxWidth?: string;
  // if true, the cancel button will render a confirmation modal before calling handleAbort
  confirmCancellation?: boolean;
  enableDarkTheme?: boolean;
  footerMargin?: string;
}

/**
 *
 *
 * @returns JSX.Element
 * @param {Props} props
 *
 * This component is a wrapper for the Modal component from Material UI.
 * It is used to create a modal that has a title, a body, and a footer.
 * The body is passed in as a child component. The footer contains a cancel button
 * and a success button. The success button is passed in as a prop.
 * The cancel button is a link button that calls the handleAbort function passed in as a prop.
 */
export const BaseModal = ({
  initialState,
  handleAbort,
  children,
  title,
  SuccessButtonProps,
  SecondaryButtonProps,
  ModalContentProps,
  cancelButtonText = 'Cancel',
  modalContentMaxWidth,
  enableDarkTheme = false,
  footerMargin = '70px',
}: Props) => {
  const [open] = React.useState(initialState);

  const Title = () => {
    return (
      <Box justifyContent="space-between" display={'flex'}>
        <Typography
          alignSelf={'center'}
          variant={'h6'}
          sx={{
            color: enableDarkTheme
              ? ThemePalette.typography.wheat
              : ThemePalette.typography.primary,
          }}
        >
          {title}
        </Typography>
        <TextButton
          sx={{
            minHeight: '19px',
            minWidth: '19px',
          }}
          onClick={() => {
            handleAbort();
          }}
        >
          <Typography variant={'h6'}>X</Typography>
        </TextButton>
      </Box>
    );
  };

  return (
    <div>
      <Modal
        open={open}
        slotProps={{
          backdrop: {
            sx: {
              ...modalBackdropStyle,
              backgroundColor: enableDarkTheme
                ? ThemePalette.dark.pageBackground
                : bg.blue,
            },
          },
        }}
      >
        <Box
          sx={{
            ...modalContentContainerStyle,
            backgroundColor: enableDarkTheme
              ? ThemePalette.dark.modalBackground
              : bg.paper,
            maxWidth: modalContentMaxWidth,
          }}
        >
          <Title />
          <Divider
            variant="middle"
            sx={{
              borderBottomWidth: '1px',
              backgroundColor: enableDarkTheme
                ? ThemePalette.typography.secondary
                : ThemePalette.typography.black,
              marginTop: '7px',
              marginBottom: '14px',
              marginLeft: '0',
              marginRight: '0',
            }}
          />
          <ModalContent gridItemProps={ModalContentProps?.gridItemProps}>
            {children}
          </ModalContent>
          <FlexBox
            sx={{
              justifyContent: 'space-between',
              marginTop: footerMargin,
              alignItems: 'center',
            }}
          >
            <LinkButton
              onClick={() => {
                handleAbort();
              }}
              color={
                enableDarkTheme
                  ? ThemePalette.typography.wheat
                  : ThemePalette.typography.primary
              }
            >
              {cancelButtonText}
            </LinkButton>
            <Box display="flex" ml="auto">
              {SecondaryButtonProps && (
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: 'white',
                    color: 'button.primary',
                    '&:hover': {
                      backgroundColor: 'white',
                    },
                    marginRight: '10px',
                  }}
                  {...SecondaryButtonProps}
                >
                  {SecondaryButtonProps.label}
                </Button>
              )}
              <Button
                variant="contained"
                sx={{
                  backgroundColor: 'button.primary',
                  '&.Mui-disabled': {
                    backgroundColor: 'grey',
                    color: 'white',
                  },
                }}
                {...SuccessButtonProps}
              >
                {SuccessButtonProps.label}
              </Button>
            </Box>
          </FlexBox>
        </Box>
      </Modal>
    </div>
  );
};

export const InteractiveModal = ({
  initialState,
  handleAbort,
  children,
  title,
  SuccessButtonProps,
  SecondaryButtonProps,
  ModalContentProps,
  cancelButtonText = 'Cancel',
  modalContentMaxWidth,
  confirmCancellation,
  enableDarkTheme = false,
  footerMargin = '70px',
}: Props) => {
  // Handles the state of the cancel dialog which confirms user cancellation
  const [isCancelDialogOpen, setIsCancelDialogOpen] = useState(false);

  return (
    <>
      {confirmCancellation && (
        <BaseModal
          key={`cancel-dialog-${title}-${isCancelDialogOpen.toString()}`}
          title={'Confirm exit without saving'}
          initialState={isCancelDialogOpen}
          handleAbort={() => {
            setIsCancelDialogOpen(false);
          }}
          SuccessButtonProps={{
            disabled: false,
            onClick: () => {
              setIsCancelDialogOpen(false);
              handleAbort();
            },
            label: 'Exit without saving',
          }}
          ModalContentProps={{
            gridItemProps: {
              lg: 12,
            },
          }}
          modalContentMaxWidth={'25%'}
          enableDarkTheme={enableDarkTheme}
        >
          <Typography
            key={'blurb-1-cancel-confirmation'}
            sx={{
              color: enableDarkTheme
                ? ThemePalette.typography.white
                : ThemePalette.typography.black,
            }}
          >
            Any unsaved edits will be lost if you exit without saving.
          </Typography>
          <Typography
            key={'blurb-2-cancel-confirmation'}
            sx={{
              color: enableDarkTheme
                ? ThemePalette.typography.white
                : ThemePalette.typography.black,
            }}
          >
            Would you like to proceed without saving?
          </Typography>
        </BaseModal>
      )}
      <BaseModal
        title={title}
        initialState={initialState}
        handleAbort={() => {
          if (confirmCancellation) {
            setIsCancelDialogOpen(true);

            return;
          }

          handleAbort();
        }}
        SuccessButtonProps={SuccessButtonProps}
        SecondaryButtonProps={SecondaryButtonProps}
        ModalContentProps={ModalContentProps}
        cancelButtonText={cancelButtonText}
        modalContentMaxWidth={modalContentMaxWidth}
        enableDarkTheme={enableDarkTheme}
        footerMargin={footerMargin}
      >
        {children}
      </BaseModal>
    </>
  );
};
