import { useEffect } from 'react';

const useFullscreenChange = (viewerRef: React.RefObject<any | null>) => {
  useEffect(() => {
    const handleFullscreenChange = () => {
      if (viewerRef.current) {
        const viewer = viewerRef.current.cesiumElement;
        const isFullScreen = !!(
          document.fullscreenElement ??
          (document as any).webkitFullscreenElement ??
          (document as any).mozFullScreenElement ??
          (document as any).msFullscreenElement
        );

        if (isFullScreen) {
          viewer.container.style.position = 'absolute';
          viewer.container.style.top = '0';
          viewer.container.style.left = '0';
          viewer.container.style.width = '100%';
          viewer.container.style.height = '100%';
          viewer.container.style.zIndex = '100';
        } else {
          viewer.container.style.position = 'inherit';
          viewer.container.style.top = '';
          viewer.container.style.left = '';
          viewer.container.style.width = '';
          viewer.container.style.height = '100%';
          viewer.container.style.zIndex = '100';
        }
        viewer.resize();
      }
    };

    const exitFullscreen = () => {
      const fullscreenElement =
        document.fullscreenElement ??
        (document as any).webkitFullscreenElement ??
        (document as any).mozFullScreenElement ??
        (document as any).msFullscreenElement;

      if (fullscreenElement) {
        const exitFullscreenPromise =
          document.exitFullscreen?.() ??
          (document as any).webkitExitFullscreen?.() ??
          (document as any).mozCancelFullScreen?.() ??
          (document as any).msExitFullscreen?.();

        if (exitFullscreenPromise instanceof Promise) {
          exitFullscreenPromise.catch((error: any) => {
            console.error('Failed to exit fullscreen mode:', error);
          });
        }
      }
    };

    document.addEventListener('fullscreenchange', handleFullscreenChange);
    document.addEventListener('webkitfullscreenchange', handleFullscreenChange);
    document.addEventListener('mozfullscreenchange', handleFullscreenChange);
    document.addEventListener('MSFullscreenChange', handleFullscreenChange);

    window.addEventListener('beforeunload', exitFullscreen);
    window.addEventListener('pagehide', exitFullscreen);

    return () => {
      document.removeEventListener('fullscreenchange', handleFullscreenChange);
      document.removeEventListener(
        'webkitfullscreenchange',
        handleFullscreenChange
      );
      document.removeEventListener(
        'mozfullscreenchange',
        handleFullscreenChange
      );
      document.removeEventListener(
        'MSFullscreenChange',
        handleFullscreenChange
      );
      window.removeEventListener('beforeunload', exitFullscreen);
      window.removeEventListener('pagehide', exitFullscreen);

      // Ensure to exit fullscreen mode if the component is unmounted
      exitFullscreen();
    };
  }, [viewerRef]);
};

export default useFullscreenChange;
