import { SVG } from 'components/Asset/SVG';
import { LinkButton } from 'components/Buttons/LinkButton';
import { FlexBox } from 'components/Containers/FlexBox';
import { useState } from 'react';
import { CreateExternalUserModal } from './components/modals/CreateExternalUserModal';
import { ExternalUsersList } from './components/ExternalUserList';
import StyledSwitchButton from 'components/Buttons/SwitchButton';
import { FormControlLabel } from '@mui/material';
import { useAppSelector } from 'store/hook';
import { isExternalUser } from 'utils/validators/role.validation';
import { isDarkTheme } from 'utils/theme';
import { ThemePalette } from 'mui.theme';

export const ExternalUserView = () => {
  const [isAddUserModalOpen, setIsAddUserModalOpen] = useState(false);
  const [showInactiveUsers, setShowInactiveUsers] = useState(true);

  const { role } = useAppSelector((state) => state.authReducer);
  const theme = useAppSelector((state) => state.authReducer).customer.theme;
  const enableDarkTheme = isDarkTheme(theme);
  // if the user is an external user, disable certain fields
  const isDisabled = isExternalUser(role);

  return (
    <>
      <CreateExternalUserModal
        key={`${isAddUserModalOpen.toString()}-create-user-modal`}
        open={isAddUserModalOpen}
        handleAbort={() => {
          setIsAddUserModalOpen(false);
        }}
        title="Add a New User"
        enableDarkTheme={enableDarkTheme}
      />

      <FlexBox
        sx={{
          width: '100%',
          justifyContent: 'space-between',
        }}
      >
        <div></div>
        <FlexBox>
          <FlexBox
            sx={{
              display: 'flex',
              marginRight: '2rem',
            }}
          >
            <FormControlLabel
              control={<StyledSwitchButton />}
              label="Show Inactive Users"
              labelPlacement="start"
              checked={showInactiveUsers}
              onChange={() => {
                // Flip the value
                setShowInactiveUsers((last) => !last);
              }}
              sx={{
                color: enableDarkTheme
                  ? ThemePalette.typography.wheat
                  : ThemePalette.typography.black,
              }}
            />
          </FlexBox>
          <FlexBox
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <SVG
              name={isDisabled ? 'addDisabled' : 'add'}
              style={{ marginRight: '10px' }}
              onClick={() => {
                // open modal
                setIsAddUserModalOpen(true);
              }}
            />

            <LinkButton
              onClick={() => {
                // open modal
                setIsAddUserModalOpen(true);
              }}
              isDisabled={isDisabled}
              color={
                enableDarkTheme
                  ? ThemePalette.typography.wheat
                  : ThemePalette.typography.primary
              }
            >
              Add a New User
            </LinkButton>
          </FlexBox>
        </FlexBox>
      </FlexBox>
      <ExternalUsersList showInactiveUsers={showInactiveUsers} />
    </>
  );
};
