import { SVG } from 'components/Asset/SVG';
import { theme } from 'mui.theme';
import { IdSpan } from 'components/AgGrid/CellRenderer/ListView.styled';
import { StyledIcon } from 'components/AgGrid/CellRenderer/CellRenderer.styled';
import { type Operator } from 'types/operator.types';
export { ResetPassword } from 'components/Buttons/ResetPassword';

const { svgIcons } = theme.palette;

interface Props {
  value: string;
  data: Operator;
  handleDeleteAccount: (user_id: string) => void;
  handleNameClick: (user_id: string) => void;
  handleActivateAccount: (user_id: string) => void;
}

export const DeleteAccount = ({
  data: { isActive, operatorId },
  handleDeleteAccount,
}: Props) => {
  if (!isActive) return null;
  return (
    <span
      onClick={() => {
        handleDeleteAccount(operatorId);
      }}
    >
      <SVG
        name="deleteIcon"
        style={{
          ...svgIcons?.primary,
        }}
      />
    </span>
  );
};

export const OperatorName = ({
  handleNameClick,
  data: { operatorName, operatorId },
}: Props) => {
  return (
    <IdSpan
      onClick={() => {
        handleNameClick(operatorId);
      }}
    >
      {operatorName}
    </IdSpan>
  );
};

export const OperatorStatus = ({
  data: { isActive, operatorId },
  handleActivateAccount,
}: Props) => {
  return (
    <span
      onClick={() => {
        if (isActive) return;
        /**
         * TODO: Handle user reset password
         */
        handleActivateAccount(operatorId);
      }}
    >
      <StyledIcon>
        <SVG
          name={isActive ? 'activeIcon' : 'inActiveIcon'}
          style={{
            ...svgIcons?.primary,
          }}
        />
      </StyledIcon>
      {isActive ? 'Active' : 'Inactive'}
    </span>
  );
};

export const PlainSpan = () => {
  return <span></span>;
};
