import { updateLoadingState } from 'store/app.slice';
import { BaseHandler } from '../base.handler';
import SquaresAPI from 'api/Squares/squares.api';
import { useAppSelector } from 'store/hook';

export default class SquaresHandler extends BaseHandler {
  private readonly api: SquaresAPI;
  private readonly customer = useAppSelector((state) => state.persistedReducer)
    .customer.code;

  constructor() {
    super();
    this.api = new SquaresAPI();
  }

  async uploadFile(fileOrBlob: File | Blob): Promise<any> {
    try {
      this.dispatch(updateLoadingState(true));

      // If the input is a Blob, convert it to a File
      let fileToUpload = fileOrBlob;
      if (fileOrBlob instanceof Blob && !(fileOrBlob instanceof File)) {
        fileToUpload = new File([fileOrBlob], 'filename.pdf', {
          type: 'application/pdf',
        }); // Provide a default filename and type
      }

      const response = await this.api.uploadFile(fileToUpload as File);

      this.handleSuccess();
      return response; // You might want to return some data or status here
    } catch (error: any) {
      let msg: string = '';

      if (error.isAxiosError) {
        msg = error.response.data.message as string;
      }

      this.handleError('An error occurred while uploading the file: ' + msg);

      // Return an error response or throw an error depending on your error handling strategy
      return null;
    }
  }

  async uploadWorkOrderFile(
    fileOrBlob: File | Blob,
    filename: string
  ): Promise<any> {
    try {
      this.dispatch(updateLoadingState(true)); // Update loading state to true

      // Convert Blob to File if necessary
      let fileToUpload = fileOrBlob;
      if (fileOrBlob instanceof Blob && !(fileOrBlob instanceof File)) {
        const currentDateEpoch = Date.now();

        fileToUpload = new File(
          [fileOrBlob],
          `${filename}-${currentDateEpoch}.csv`,
          {
            // Default filename and type for work orders
            type: 'text/csv',
          }
        );
      }

      // Upload the file
      const response = await this.api.uploadWorkOrder(
        fileToUpload as File,
        this.customer
      );

      this.handleSuccess(); // Handle successful upload
      return response; // Return response data or status
    } catch (error: any) {
      let msg = '';

      // Extract error message from Axios error
      if (error.isAxiosError && error.response) {
        msg = error.response.data.message as string;
      } else {
        msg = error.message || 'Unknown error occurred';
      }

      this.handleError(
        'An error occurred while uploading the work order: ' + msg
      ); // Handle error scenario

      // Return null or throw an error depending on your error handling strategy
      return msg;
    } finally {
      this.dispatch(updateLoadingState(false)); // Update loading state to false
    }
  }
}
