import { type Operator } from 'types/operator.types';
import { type ICreateOperatorPayload } from 'types/payloads/operator.payload.types';

/**
 * @param event
 * @returns void
 *
 * This function is used to handle the change event
 * of the customer hierarchy.
 * It is for the hierarchy drop down,
 * when creating or editing.
 */
export const handleSelectOperatorHierarchyNode = (
  event: { target: { value: any } },
  operator: ICreateOperatorPayload,
  hierarchyNodeState: Array<Operator['nodeId']>
) => {
  const selectedValue = event.target.value;

  const nodeId = operator.nodeId;
  hierarchyNodeState.forEach(({ id, display }) => {
    if (id === selectedValue) {
      nodeId.id = id;
      nodeId.display = display;
    }
  });

  return nodeId;
};

/**
 * @param event
 * @returns void
 *
 * This function is used to handle the change event
 * of the customer assigned assits.
 * It is for the assigned assits drop down,
 * when creating or editing.
 */
export const handleSelectOperatorAssignedAssets = (
  event: { target: { value: any } },
  operator: ICreateOperatorPayload,
  assignedAssetsState: any[]
) => {
  const selectedValue = event.target.value;

  const asset = operator;
  assignedAssetsState.forEach(({ id, display }) => {
    if (id === selectedValue) {
      asset.assetId = id;
    }
  });
  return asset;
};
export const handleFormValidation = () => {};
