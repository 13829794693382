import { useEffect } from 'react';
import { type IdlingAssetData } from 'types/FleetOverview.types';
import { type Asset } from 'types/asset.types';

interface PieChart2 {
  pieChartValue: {
    clickState: boolean;
    val: string;
  };
  associatedFilteredAssets: Asset[];
  oldVal: string;
  excessiveIdlingAssetsData: IdlingAssetData[];
  setNewArr: React.Dispatch<React.SetStateAction<Asset[]>>;
  setOldVal: React.Dispatch<React.SetStateAction<string>>;
}

const useCustomEffect = ({
  pieChartValue,
  associatedFilteredAssets,
  oldVal,
  excessiveIdlingAssetsData,
  setNewArr,
  setOldVal,
}: PieChart2) => {
  useEffect(() => {
    if (pieChartValue.val === 'idling_assets' && oldVal !== 'idling_assets') {
      // return non_idling assets
      const filteredArr = associatedFilteredAssets.filter((asset) => {
        return !excessiveIdlingAssetsData.some(
          (idleAsset) => idleAsset.assetId === asset.assetId
        );
      });

      setNewArr(filteredArr);
      setOldVal(pieChartValue.val);
    } else if (
      pieChartValue.val === 'no_idling_assets' &&
      oldVal !== 'no_idling_assets'
    ) {
      // return idling assets
      const filteredArr = associatedFilteredAssets.filter((asset) => {
        return excessiveIdlingAssetsData.some(
          (idleAsset) => idleAsset.assetId === asset.assetId
        );
      });
      setNewArr(filteredArr);
      setOldVal(pieChartValue.val);
    } else if (
      (pieChartValue.val === 'idling_assets' && oldVal === 'idling_assets') ||
      (pieChartValue.val === 'no_idling_assets' &&
        oldVal === 'no_idling_assets')
    ) {
      setNewArr(associatedFilteredAssets);
      setOldVal('');
    } else {
      setNewArr(associatedFilteredAssets);
      setOldVal(pieChartValue.val);
    }
  }, [pieChartValue, associatedFilteredAssets]);
};

export default useCustomEffect;
