import * as React from 'react';
import { BaseTableList } from 'components/AgGrid/AgGrid';
import { PaddedListWrapper } from 'components/AgGrid/CellRenderer/ListView.styled';
import { Typography } from '@mui/material';
import { formatTo2dp } from 'utils/helpers/general';
import { ThemePalette } from 'mui.theme';
import { CellDisplay, EngineHoursOffset } from './EngineHoursCellRenderer';

interface Props {
  data: any;
  setSelectedRow: any;
  setSelectedAsset: any;
  selectedRow: string;
  enableDarkTheme: boolean;
  assets: Array<Record<string, unknown>>;
  exportTo: boolean;
  handleExport: (val: boolean) => void;
}

export const EngineHoursList = ({
  data,
  setSelectedRow,
  setSelectedAsset,
  selectedRow,
  enableDarkTheme,
  assets,
  exportTo,
  handleExport,
}: Props) => {
  const columnDefs = [
    {
      field: 'bumperNumber',
      headerName: 'Vehicle',
      filter: 'agTextColumnFilter',
      sortable: true,
      cellRenderer: CellDisplay,
      cellRendererParams: {
        handleClick: function (deviceId: any, bumperNumber: any) {
          setSelectedRow(deviceId);
          setSelectedAsset(bumperNumber);
        },
        enableDarkTheme,
      },
    },
    {
      field: 'device',
      headerName: 'Sym ID',
      filter: 'agTextColumnFilter',
      sortable: true,
    },
    {
      field: 'engine_hour_total',
      headerName: 'Engine Hour - Total',
      filter: 'agTextColumnFilter',
      cellRenderer: EngineHoursOffset,
      cellRendererParams: { assets, range: 'total' },
      sortable: true,
    },
    {
      field: 'engine_hour_last_week',
      headerName: 'Engine Hour - Last Week',
      filter: 'agTextColumnFilter',
      sortable: true,
    },
    {
      field: 'engine_hour_last_month',
      headerName: 'Engine Hour - Last Month',
      filter: 'agTextColumnFilter',
      sortable: true,
    },
  ];

  const getRowStyle = (params: any) => {
    if (params.data.device === selectedRow) {
      return { background: '#ABABE2' }; // Change background color to red for the highlighted row
    }
    return null;
  };

  return (
    <>
      <PaddedListWrapper>
        {data.length < 1 ? (
          <Typography
            sx={{
              color: enableDarkTheme
                ? ThemePalette.typography.white
                : ThemePalette.typography.black,
            }}
          >
            No Engine Hours Available
          </Typography>
        ) : (
          <BaseTableList
            rowData={data.map((item: any) => ({
              ...item,
              engine_hour_total: formatTo2dp(item.engine_hour_total.toString()),
              engine_hour_last_week: formatTo2dp(
                item.engine_hour_last_week.toString()
              ),
              engine_hour_last_month: formatTo2dp(
                item.engine_hour_last_month.toString()
              ),
            }))}
            columnDefs={columnDefs}
            cacheQuickFilter={true}
            tableStyles={{ height: '450px' }}
            getRowStyle={getRowStyle} // Apply row styles dynamically
            exportTo={exportTo}
            handleExport={handleExport}
            exportPdf={false}
          />
        )}
      </PaddedListWrapper>
    </>
  );
};
