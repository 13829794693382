import { Box, Typography } from '@mui/material';
import { SWTextField } from 'components/Form/Input/SWTextField';
import { Selector } from 'components/Form/Selectors/Selector';
import { InteractiveModal } from 'components/Modals/InteractiveModal/BaseModal';
import OperatorHandler from 'handlers/operator.handler';
import { useEffect, useState } from 'react';
import { modalInputFieldStyle } from 'styles/global.css';
import { validateYupSchema } from 'utils/validators/schema.validator';
import {
  handleSelectOperatorHierarchyNode,
  handleSelectOperatorAssignedAssets,
} from '../../helpers/common';
import { type ICreateOperatorPayload } from 'types/payloads/operator.payload.types';
import { type Operator } from 'types/operator.types';
import { createOrUpdateOperatorRequestBodySchema } from 'utils/schemas/operator.schema';
import { useAppSelector } from 'store/hook';
import { ThemePalette } from 'mui.theme';

interface Props {
  title: string;
  open: boolean;
  handleAbort: () => void;
  enableDarkTheme: boolean;
}

export const CreateOperatorModal = ({
  title,
  open,
  handleAbort,
  enableDarkTheme,
}: Props) => {
  const operatorHandler = new OperatorHandler();
  const { assets } = useAppSelector((state) => state.assetReducer);
  const transformedAssets: Array<{ id: string; display: string }> = assets.map(
    (asset) => {
      return {
        id: asset.assetId,
        display: asset.bumperNumber,
      };
    }
  );

  const [isValidForm, setFormValidity] = useState(false);
  const [operator, setOperator] = useState<ICreateOperatorPayload>({
    operatorName: '',
    customerId: '',
    nodeId: {
      id: '',
      display: '',
    },
    assetId: '',
    isActive: true,
  });

  /* THIS DATA IS STUBBED AND WILL BE REPLACED WITH REAL DATA */
  const nodeIdState: Array<Operator['nodeId']> = [];

  // Validate form as user fills out fields
  useEffect(() => {
    const validateForm = async () =>
      await validateYupSchema(
        operator,
        createOrUpdateOperatorRequestBodySchema
      );

    void validateForm().then((isValid) => {
      setFormValidity(isValid);
    });
  }, [operator]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setOperator({
      ...operator,
      [name]: value,
    });
  };

  const handleSuccess = async () => {
    await operatorHandler.createOperator(operator);

    handleAbort();
  };
  return (
    <InteractiveModal
      initialState={open}
      SuccessButtonProps={{
        disabled: !isValidForm,
        onClick: handleSuccess,
        label: 'ADD NEW USER',
      }}
      handleAbort={handleAbort}
      title={title}
      confirmCancellation
      enableDarkTheme={enableDarkTheme}
    >
      {/* First Name TextField */}
      <SWTextField
        key="operator-name"
        name="operatorName"
        label="Operator ID*"
        TextFieldProps={{
          name: 'operatorName',
          value: operator.operatorName,
          style: { minWidth: '258px' },
          onChange: handleChange,
          InputProps: {
            sx: modalInputFieldStyle,
          },
        }}
        enableDarkTheme={enableDarkTheme}
      />
      <br />
      {/* Permission selector drop down single */}
      <Box key="nodeId">
        <Typography
          variant="bodyBold"
          display="block"
          mb={'4px'}
          sx={{
            color: enableDarkTheme
              ? ThemePalette.typography.white
              : ThemePalette.typography.black,
          }}
        >
          Hierarchy Node
        </Typography>
        <Selector
          value={operator.nodeId.id}
          onChange={(event) => {
            setOperator({
              ...operator,
              nodeId: handleSelectOperatorHierarchyNode(
                event,
                operator,
                nodeIdState
              ),
            });
          }}
          selectorOptions={nodeIdState}
          inputProps={{ disabled: true }}
          enableDarkTheme={enableDarkTheme}
        />
      </Box>
      {/* Permission selector drop down single */}
      <Box key="assetId">
        <Typography
          variant="bodyBold"
          display="block"
          mb={'4px'}
          sx={{
            color: enableDarkTheme
              ? ThemePalette.typography.white
              : ThemePalette.typography.black,
          }}
        >
          Bumper ID
        </Typography>
        <Selector
          value={operator.assetId}
          onChange={(event) => {
            const updatedOperatorAssignedAssets =
              handleSelectOperatorAssignedAssets(
                event,
                operator,
                transformedAssets
              );
            setOperator({
              ...operator,
              assetId: updatedOperatorAssignedAssets.assetId,
            });
          }}
          selectorOptions={transformedAssets}
          enableDarkTheme={enableDarkTheme}
        />
      </Box>
    </InteractiveModal>
  );
};
