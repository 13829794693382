import { type Asset } from 'types/asset.types';
import { type ICreateAssetPayload } from 'types/payloads/asset.payload.types';
import BaseAPI from './base.api';
import { type IdlingAssetData } from 'types/FleetOverview.types';

export default class AssetAPI extends BaseAPI {
  create = async (payload: ICreateAssetPayload) => {
    const response = await this.API.post('asset', payload);
    return response.data;
  };

  update = async (assetId: string, payload: ICreateAssetPayload) => {
    const response = await this.API.patch(`asset/${assetId}`, payload);
    return response.data;
  };

  /**
   * Gets all assets the current user has access to
   * @returns A list of assets
   */
  getAll = async (): Promise<Asset[]> => {
    const response = await this.API.get('asset');
    return response.data;
  };

  /**
   * Deletes the specified asset
   * @returns A success message
   */
  delete = async (assetId: string): Promise<Asset[]> => {
    const response = await this.API.patch(`asset/${assetId}/delete`);
    return response.data;
  };

  /**
   * Fetches all assets with analytics data
   * @returns A success message
   */
  getAllWithData = async (): Promise<Asset[]> => {
    const response = await this.API.get(
      'analytics/fuel-analytics/get-assets-with-data'
    );
    return response.data;
  };

  /**
   * Gets all assets with excessive idling time that the current user has access to
   * @returns A list of assets
   */
  getAssetsWithExcessiveIdling = async (): Promise<IdlingAssetData[]> => {
    const response = await this.API.get(
      'analytics/fuel-analytics/excess-idling-assets'
    );
    return response.data;
  };

  /**
   * Gets assets performance for dashboard
   * @returns A list of assets
   */
  getAssetsOverView = async (
    startDate?: string,
    endDate?: string
  ): Promise<any> => {
    const response =
      startDate && endDate
        ? await this.API.get('asset/performance', {
            params: {
              startDate,
              endDate,
            },
          })
        : await this.API.get('asset/performance');
    return response.data;
  };

  /**
   * Gets all assets the current user has access to
   * @returns A list of assets
   */
  getAllAssetsIntialData = async (): Promise<Asset[]> => {
    const response = await this.API.get('asset/all');
    return response.data;
  };

  getAssetsComparison = async (payload: any) => {
    const response = await this.API.post('asset/compare', payload);
    return response.data;
  };

  getAssetsComparisonByTags = async (payload: any) => {
    const response = await this.API.post('asset/compare-by-tags', payload);
    return response.data;
  };
}
