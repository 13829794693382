import { Base } from 'components/Containers/BaseContainer';
import { ToolbarContainer } from 'components/Containers/ToolbarContainer';
import SWTab from 'components/Layout/Tabs/SWTab';
import { TabList, TabPanel, Tabs } from 'react-tabs';
import { TagAssetMakeModelView } from './TagManagementView/TagManagementView';
import { useAppSelector } from 'store/hook';
import { isDarkTheme } from 'utils/theme';

export const TagView = () => {
  const theme = useAppSelector((state) => state.authReducer).customer.theme;
  const enableDarkTheme = isDarkTheme(theme);
  return (
    <Base enableDarkTheme={enableDarkTheme}>
      <Tabs>
        <TabList>
          <ToolbarContainer
            style={{
              paddingBottom: 0,
            }}
            enableDarkTheme={enableDarkTheme}
          >
            {/* BUG: The Permission tag causes MUI to detect the SWTab and create empty tab indexes */}
            <SWTab enableDarkTheme={enableDarkTheme}>Asset Make/Model </SWTab>
          </ToolbarContainer>
        </TabList>

        <TabPanel
          style={{
            padding: '11px 21px 21px',
          }}
        >
          <TagAssetMakeModelView enableDarkTheme={enableDarkTheme} />
        </TabPanel>
      </Tabs>
    </Base>
  );
};
