import { updateLoadingState } from 'store/app.slice';
import { BaseHandler } from '../base.handler';
import SurvivalAnalysisOverviewApi from 'api/survivalAnalysis/survivalAnalysisOverview.api';
import {
  type SurvivalAnalysistRequestQueryCumulativeAssets,
  type ICreateSurvivalAnalysistPayload,
  type ICreateSurvivalAnalysistEolPayload,
} from 'types/payloads/survivalAnalysist.payload.types';
import {
  setAssets,
  setCumulativeAssets,
  setEol,
} from 'store/survivalAnaysisStore/overview.slice';
import { useAppSelector } from 'store/hook';
import { type EolDates } from 'types/survivalAnalysis.types';

export default class SurvivalAnalysisOverviewHandler extends BaseHandler {
  private readonly apiOverView: SurvivalAnalysisOverviewApi;
  private readonly customer = useAppSelector((state) => state.persistedReducer)
    .customer.code;

  constructor() {
    super();
    this.apiOverView = new SurvivalAnalysisOverviewApi();
  }

  async getAssetsRiskAnalysis() {
    this.dispatch(updateLoadingState(true));
    try {
      const payload: ICreateSurvivalAnalysistPayload = {
        customerName: this.customer,
      };
      const assetsRiskAnalysis = await this.apiOverView.getAssetsRiskAnalysis(
        payload
      );
      this.dispatch(setAssets(assetsRiskAnalysis.Items));
      this.handleSuccess();
      return assetsRiskAnalysis;
    } catch (error: any) {
      let msg = 'Unknown error occurred';

      if (error.isAxiosError) {
        msg = error.response?.data?.message || msg;
      }

      this.handleError(
        `An error occurred while retrieving the assets risk analysis: ${msg}`
      );
      return [];
    } finally {
      this.dispatch(updateLoadingState(false));
    }
  }

  async getCumulativeAssetsPerSubComponent() {
    this.dispatch(updateLoadingState(true));

    try {
      const payload: SurvivalAnalysistRequestQueryCumulativeAssets = {
        customerName: this.customer,
      };

      const cumulativeAssets =
        await this.apiOverView.getCumulativeAssetsPerSubComponent(payload);

      this.dispatch(setCumulativeAssets(cumulativeAssets.Items));
      this.handleSuccess();
      return cumulativeAssets;
    } catch (error: any) {
      let msg = 'Unknown error occurred';

      if (error.isAxiosError) {
        msg = error.response?.data?.message ?? msg;
      }

      this.handleError(
        `An error occurred while retrieving the cumulative assets: ${msg}`
      );
      return [];
    } finally {
      this.dispatch(updateLoadingState(false));
    }
  }

  async getEOLs() {
    this.dispatch(updateLoadingState(true));

    try {
      const payload: ICreateSurvivalAnalysistEolPayload = {
        customerName: this.customer,
      };

      const eol = await this.apiOverView.getEOLs(payload);

      this.dispatch(setEol(eol.Items));
      this.handleSuccess();
      return eol;
    } catch (error: any) {
      let msg = 'Unknown error occurred';

      if (error.isAxiosError) {
        msg = error.response?.data?.message ?? msg;
      }

      this.handleError(`An error occurred while retrieving the Eol(s): ${msg}`);
      return [];
    } finally {
      this.dispatch(updateLoadingState(false));
    }
  }

  async editEOL(payload: any): Promise<void> {
    this.dispatch(updateLoadingState(true));

    try {
      const date = new Date(payload.eol);
      const year = date.getUTCFullYear();
      const month = String(date.getUTCMonth() + 1).padStart(2, '0'); // Months are 0-based
      const day = String(date.getUTCDate()).padStart(2, '0');

      const formattedDate = `${year}-${month}-${day}`;
      const newPayload: ICreateSurvivalAnalysistEolPayload = {
        customerName: this.customer,
        assetId: payload.asset_id,
        component: payload.components_category,
        eol: formattedDate,
        subComponent: payload.sub_component,
      };

      const newEols = await this.apiOverView.updateEOL(newPayload);

      this.dispatch(setEol(newEols.Items));

      this.handleSuccess(`Successfully updated EOLs.`);
    } catch (_) {
      this.handleError('An error occurred while updating the EOLs.');
    } finally {
      this.dispatch(updateLoadingState(false));
    }
  }
}
