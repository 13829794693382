import type { RuleNotifications } from 'types/notification.types';

export function filterNotifications(
  selectedOption: string,
  notifications: RuleNotifications[]
) {
  const currentDate = new Date();

  switch (selectedOption) {
    case 'today': {
      return notifications.filter((notification) => {
        const notificationDate = new Date(notification.alertStartTs);
        return (
          notificationDate.getDate() === currentDate.getDate() &&
          notificationDate.getMonth() === currentDate.getMonth() &&
          notificationDate.getFullYear() === currentDate.getFullYear()
        );
      });
    }
    case 'yesterday': {
      const yesterday = new Date(currentDate);
      yesterday.setDate(currentDate.getDate() - 1);

      return notifications.filter((notification) => {
        const notificationDate = new Date(notification.alertStartTs);
        return (
          notificationDate.getDate() === yesterday.getDate() &&
          notificationDate.getMonth() === yesterday.getMonth() &&
          notificationDate.getFullYear() === yesterday.getFullYear()
        );
      });
    }

    case 'thisWeek': {
      const startOfWeek = new Date(currentDate);
      startOfWeek.setDate(currentDate.getDate() - currentDate.getDay());

      return notifications.filter((notification) => {
        const notificationDate = new Date(notification.alertStartTs);
        return notificationDate >= startOfWeek;
      });
    }

    case 'lastWeek': {
      const startOfLastWeek = new Date(currentDate);
      startOfLastWeek.setDate(currentDate.getDate() - currentDate.getDay() - 7);

      const endOfLastWeek = new Date(startOfLastWeek);
      endOfLastWeek.setDate(startOfLastWeek.getDate() + 6);

      return notifications.filter((notification) => {
        const notificationDate = new Date(notification.alertStartTs);
        return (
          notificationDate >= startOfLastWeek &&
          notificationDate <= endOfLastWeek
        );
      });
    }

    case 'all':
      return notifications;

    default:
      return notifications;
  }
}
