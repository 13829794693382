import { useEffect, useState } from 'react';
import { validateYupSchema } from 'utils/validators/schema.validator';
import { type ObjectSchema } from 'yup';

export function useFormIsValid(payload: any, schema: ObjectSchema<any>) {
  const [isValidForm, setFormValidity] = useState(false);
  useEffect(() => {
    const validateForm = async () => await validateYupSchema(payload, schema);

    void validateForm().then((isValid) => {
      setFormValidity(isValid);
    });
  }, [payload]);

  return isValidForm;
}
