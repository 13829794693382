/* Hash map helper functions */
export const isEmpty = (obj: Record<any, any>) => {
  return Object.keys(obj).length === 0;
};

/*
 * Get nested value in object by suppling a key
 * Example:
 * const obj = {
 *  a: {
 *   b: {
 *   c: 'hello'
 *  }
 * }
 *
 * getNestedValue(obj, 'a.b.c') // returns 'hello'
 *
 */
export const getNestedValue = (object: Record<string, any>, key: string) => {
  return key.split('.').reduce((nestedObject, nextKey) => {
    return nestedObject?.[nextKey];
  }, object);
};
