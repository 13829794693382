import FuelAnalyticsAPI from 'api/fuelAnalytics.api';
import { updateLoadingState } from 'store/app.slice';
import { BaseHandler } from './base.handler';
import { set, clear } from 'store/nonHaulTrucks.slice';

export default class NonHaulTruckHandler extends BaseHandler {
  private readonly api: FuelAnalyticsAPI;

  constructor() {
    super();

    this.api = new FuelAnalyticsAPI();
  }

  async getAnalytics(
    makeModels: Array<{
      make: string;
      model: string;
    }>,
    startDate: string,
    endDate: string
  ) {
    this.dispatch(updateLoadingState(true));
    try {
      const [fuelEfficiencyDataResult, fuelIdleEventsDataResult] =
        await Promise.allSettled([
          this.api.getNonHaulTrucksFuelEfficiency(
            makeModels,
            startDate,
            endDate
          ),
          this.api.getNonHaulTrucksFuelIdleEvents(
            makeModels,
            startDate,
            endDate
          ),
        ]);

      const fuelEfficiencyData =
        fuelEfficiencyDataResult.status === 'fulfilled'
          ? fuelEfficiencyDataResult.value
          : null;
      const fuelEventsData =
        fuelIdleEventsDataResult.status === 'fulfilled'
          ? fuelIdleEventsDataResult.value
          : null;

      const response = {
        fuelEfficiency: {
          data: fuelEfficiencyData.data,
          sortedBottom5:
            fuelEfficiencyData.sortedTop5getNonHaulTruckFuelEfficiency,
          sortedTop5:
            fuelEfficiencyData.sortedBottom5getNonHaulTruckFuelEfficiency,
          fuelConsumptionAverage: fuelEfficiencyData.fuelConsumptionAverage,
          engineHoursAverage: fuelEfficiencyData.engineHoursAverage,
        },
        fuelNoneIdleEvents: {
          data: fuelEventsData.data,
          sortedBottom5:
            fuelEventsData.sortedTop5NonHaulTruckFuelNonEventHandlingTime,
          sortedTop5:
            fuelEventsData.sortedBottom5NonHaulTruckFuelNonEventHandlingTime,
          fuelConsumptionAverage: fuelEventsData.fuelConsumptionAverage,
        },
      };

      this.dispatch(set(response));
      this.handleSuccess();
    } catch (error: any) {
      let msg: string = '';
      if (error.isAxiosError) {
        msg = error.response.data.message as string;
      }

      this.handleError(
        'An error occured while getting the fuel analytics data: ' + msg
      );
    }
  }

  clearAnalytics() {
    this.dispatch(clear());
  }
}
