import { type ColDef } from 'ag-grid-community';
import { BaseTableList } from 'components/AgGrid/AgGrid';
import { PaddedListWrapper } from 'components/AgGrid/CellRenderer/ListView.styled';
import { WithIconRenderer } from 'components/AgGrid/CellRenderer/WithIconRenderer';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { type RootState } from 'store';
import { useAppDispatch, useAppSelector } from 'store/hook';
import { showNotification } from 'store/notification.slice';
import { type InternalUser } from 'types/user.types';
import { copyToClipboard } from 'utils/helpers/general';
import { UpdateInternalUserModal } from 'views/Settings/InternalUsers/components/modals/UpdateInternalUserModal';
import { UserDeactivationModal } from 'views/Settings/InternalUsers/components/modals/UserDeactivationModal';
import { calculateCustomerPermissionLevel } from '../helpers/conditional.functions';
import {
  Customers,
  DeleteAccount,
  ResetPassword,
  UserDepartment,
  UserName,
} from './InternalUsersListCellRenderer';
import { isInternalUser } from 'utils/validators/role.validation';

export const UsersList = () => {
  const dispatch = useAppDispatch();
  const { role } = useAppSelector((state) => state.authReducer);

  // Certain client features will be disabled using this flag
  const isInternalUserRole = isInternalUser(role);

  const [isDeactivateUserModalOpen, setIsDeactivateUserModalOpen] =
    React.useState(false);
  const [isEditUserModalOpen, setIsEditUserModalOpen] = React.useState(false);
  const [selectedUserId, setSelectedUserId] = React.useState('');

  const { internalUserList: internalUserListState } = useSelector(
    (state: RootState) => state.internalUserReducer
  );
  const { customers } = useAppSelector((state) => state.customerReducer);
  const totalCustomerCount = customers.length;

  const internalUserList = internalUserListState.map((user) => {
    const customerAccessLevel = calculateCustomerPermissionLevel(
      user.userCustomerAssociations?.length,
      totalCustomerCount
    );

    return {
      ...user,
      customerAccessLevel,
    };
  });

  const [columnDefs, setColumnDefs] = React.useState<ColDef[]>([
    {
      colId: 'firstName',
      field: 'firstName',
      headerName: 'User Name',
      width: 50,
      maxWidth: 250,
      cellRenderer: UserName,
      cellRendererParams: {
        handleEditAccount: (userId: string) => {
          if (isInternalUserRole) return;

          setSelectedUserId(userId);
          setIsEditUserModalOpen(true);
        },
      },
    },
    {
      colId: 'userId',
      field: 'userId',
      headerName: 'User ID#',
      width: 50,
      maxWidth: 150,
      cellRenderer: WithIconRenderer,
      cellRendererParams: {
        field: 'userId',
        iconName: 'copyIcon',
        maxLength: 8,
        onIconClick: async (data: InternalUser) => {
          const didCopy = await copyToClipboard(data.userId);

          // Failed to copy to clipboard, return early
          if (!didCopy) return;

          // Show notification
          dispatch(
            showNotification({
              message: 'Copied to clipboard',
              type: 'success',
            })
          );
        },
      },
    },
    {
      colId: 'customerAccessLevel',
      field: 'customerAccessLevel',
      headerName: 'Permission Level',
      suppressSizeToFit: true,
      width: 50,
      maxWidth: 200,
    },
    {
      colId: 'department',
      field: 'department',
      headerName: 'Department',
      width: 50,
      maxWidth: 200,
      cellRenderer: UserDepartment,
    },
    {
      colId: 'email',
      field: 'email',
      headerName: 'Email Address',
    },
    {
      colId: 'userCustomerAssociations',
      field: 'userCustomerAssociations',
      headerName: 'Customer Assignment(s)',
      cellRenderer: Customers,
      width: 50,
      maxWidth: 350,
    },
    {
      colId: 'resetPassword',
      field: 'userId',
      headerName: 'Reset Password',
      cellRenderer: ResetPassword,
      width: 50,
      maxWidth: 180,
      cellStyle: { textAlign: 'center' },
    },
    {
      colId: 'deleteUser',
      field: 'userId',
      headerName: 'Delete User',
      cellRenderer: DeleteAccount,
      cellRendererParams: {
        handleDeleteAccount: (userId: string) => {
          setSelectedUserId(userId);
          setIsDeactivateUserModalOpen(true);
        },
      },
      width: 50,
      maxWidth: 180,
      cellStyle: { textAlign: 'center' },
    },
  ]);

  React.useEffect(() => {
    if (!isInternalUserRole) return;

    setColumnDefs((prevColumnDefs) => {
      const filteredColDef = prevColumnDefs.filter((columnDef) => {
        if (!columnDef.colId) return true;

        const colFilter = ['resetPassword', 'deleteUser'];

        return !colFilter.includes(columnDef.colId);
      });

      return filteredColDef;
    });
  }, [isInternalUserRole]);

  const selectedUser = internalUserList.find(
    (user) => user.userId === selectedUserId
  ) ?? {
    userId: '',
    firstName: '',
    lastName: '',
    email: '',
    isActive: true,
    department: '',
    userCustomerAssociations: [],
  };

  return (
    <>
      <UserDeactivationModal
        key={`${isDeactivateUserModalOpen.toString()}-deactivate-user-modal}`}
        firstName={selectedUser?.firstName ?? ''}
        lastName={selectedUser?.lastName ?? ''}
        userId={selectedUser?.userId ?? ''}
        open={isDeactivateUserModalOpen}
        handleAbort={() => {
          setIsDeactivateUserModalOpen(false);
        }}
      />

      <UpdateInternalUserModal
        key={`${isEditUserModalOpen.toString()}-update-user-modal`}
        open={isEditUserModalOpen}
        handleAbort={() => {
          setIsEditUserModalOpen(false);
        }}
        title="Edit Symboticware User"
        userData={selectedUser}
      />

      <PaddedListWrapper>
        <BaseTableList rowData={internalUserList} columnDefs={columnDefs} />
      </PaddedListWrapper>
    </>
  );
};
