import {
  Checkbox,
  FormControl,
  ListItemText,
  MenuItem,
  Select,
  type SelectChangeEvent,
  type SxProps,
  InputLabel,
  OutlinedInput,
} from '@mui/material';
import { type ReactNode } from 'react';
import { SWTextField } from '../Input/SWTextField';
import { modalInputFieldStyle } from 'styles/global.css';
import { type TMultiSelectOptions } from 'types/utils.types';
import { ThemePalette } from 'mui.theme';

interface Props {
  selectorOptions: TMultiSelectOptions;
  handleSelectOne: (event: SelectChangeEvent<any>) => void;
  handleSelectAll: () => void;
  value: TMultiSelectOptions;
  renderValue: (arg0: TMultiSelectOptions) => ReactNode;
  minWidth?: string;
  disableSelectAll?: boolean;
  labelText?: string;
  isSelectionEmpty?: boolean;
  messageIfSelectionEmpty?: string;
  formControlSx?: SxProps;
  isDisabled?: boolean;
  height?: string;
  dropdownVerticalPosition?: 'top' | 'bottom' | 'center';
  dropdownHorizontalPosition?: 'left' | 'right' | 'center';
  enableDarkTheme?: boolean;
  enabledList?: TMultiSelectOptions;
}

/**
 *
 * @param {Props} props
 * @returns JSX.Element
 *
 * This component is a wrapper for the Select component from Material UI.
 * It is used to create a multi selector drop down.
 * The selectorOptions prop is an array of objects that contain an id and a display value.
 * The handleSelectOne prop is a function that is called when a single item is selected.
 * The handleSelectAll prop is a function that is called when the select all option is selected.
 * The value prop is an array of objects that contain an id and a display value.
 * The renderValue prop is a function that is called to render the value of the selector.
 *
 */

export const MultiSelector = ({
  selectorOptions,
  handleSelectOne,
  handleSelectAll,
  value,
  renderValue,
  minWidth = '200px',
  disableSelectAll = false,
  labelText,
  isSelectionEmpty = false,
  messageIfSelectionEmpty = 'Empty',
  formControlSx,
  isDisabled = false,
  height = '12px',
  dropdownVerticalPosition = 'bottom',
  dropdownHorizontalPosition = 'center',
  enableDarkTheme = false,
  enabledList = [],
}: Props) => {
  const selectAllIdentifier = 'reservedVal:selectAll';
  return (
    <FormControl
      sx={{ minWidth, height, ...formControlSx }}
      size="small"
      disabled={isDisabled}
    >
      {labelText && (
        <InputLabel
          id="multiple-checkbox-label"
          sx={{
            paddingBottom: '5px',
            display: 'flex',
            // height,
            minHeight: '12px',
            alignItems: 'center',
            color: enableDarkTheme
              ? ThemePalette.typography.lightGrey
              : ThemePalette.typography.black,
            '&.Mui-focused': {
              color: enableDarkTheme
                ? ThemePalette.typography.cornflowerBlue
                : ThemePalette.typography.primary,
            },
          }}
        >
          {labelText}
        </InputLabel>
      )}
      {isSelectionEmpty && selectorOptions.length < 1 ? (
        // If isSelectionEmpty  is true and there is no items on the list, show an input field with a message to indicate there is no items
        <SWTextField
          name="emptyMessage"
          TextFieldProps={{
            placeholder: messageIfSelectionEmpty,
            name: 'emptyMessage',
            InputProps: {
              disabled: true,
              sx: modalInputFieldStyle,
            },
          }}
          enableDarkTheme={enableDarkTheme}
        />
      ) : (
        <Select
          labelId="multiple-checkbox-label"
          multiple
          value={value}
          input={labelText ? <OutlinedInput label={labelText} /> : undefined}
          onChange={(event) => {
            const eventTargetValue = event.target.value;

            const didSelectAll =
              eventTargetValue[eventTargetValue.length - 1] ===
              selectAllIdentifier;

            // handle select all
            if (didSelectAll) {
              handleSelectAll();

              return;
            }
            handleSelectOne(event);
          }}
          SelectDisplayProps={{
            style: {
              display: 'flex',
              height: '12px',
              minHeight: '12px',
              alignItems: 'center',
            },
          }}
          sx={{
            color: enableDarkTheme
              ? ThemePalette.typography.white
              : ThemePalette.typography.black,
            ...(enableDarkTheme && {
              '& .MuiSvgIcon-root': {
                color: 'purple', // <------------------ arrow-svg-color
              },
              '&.MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: ThemePalette.border, // <------------------ outline-color by default
                },
                '&:hover fieldset': {
                  borderColor: ThemePalette.typography.cornflowerBlue, // <------------------ outline-color on hover
                },
                '&.Mui-focused fieldset': {
                  borderColor: ThemePalette.typography.cornflowerBlue, // <------------------ outline-color on focus
                },
              },
            }),
          }}
          MenuProps={{
            PaperProps: {
              style: {
                minHeight: 0,
                backgroundColor: enableDarkTheme
                  ? ThemePalette.dark.menuOptions
                  : ThemePalette.light.menuOptions, // Change background color here
              },
            },
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'right',
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'right',
            },
          }}
          renderValue={renderValue}
        >
          {!disableSelectAll && (
            <MenuItem
              value={selectAllIdentifier}
              sx={{
                color: enableDarkTheme
                  ? ThemePalette.typography.lightGrey
                  : ThemePalette.typography.black,
              }}
            >
              <Checkbox
                checked={value.length >= selectorOptions.length}
                sx={{
                  color: enableDarkTheme
                    ? ThemePalette.typography.cornflowerBlue
                    : ThemePalette.typography.primary,
                  '&.Mui-checked': {
                    color: enableDarkTheme
                      ? ThemePalette.typography.cornflowerBlue
                      : ThemePalette.typography.primary,
                  },
                  '&.Mui-disabled': {
                    color: ThemePalette.typography.secondary,
                  },
                }}
              />
              <ListItemText primary={'Select All'} />
            </MenuItem>
          )}
          {selectorOptions.map((option) => (
            <MenuItem
              key={option.id}
              value={JSON.stringify(option)}
              sx={{
                color: enableDarkTheme
                  ? ThemePalette.typography.lightGrey
                  : ThemePalette.typography.black,
              }}
              disabled={
                enabledList.length > 0
                  ? enabledList.filter((item) => item.id === option.id).length <
                    1
                  : false
              }
            >
              <Checkbox
                checked={value.find(({ id }) => id === option.id) !== undefined}
                sx={{
                  color: enableDarkTheme
                    ? ThemePalette.typography.cornflowerBlue
                    : ThemePalette.typography.primary,
                  '&.Mui-checked': {
                    color: enableDarkTheme
                      ? ThemePalette.typography.cornflowerBlue
                      : ThemePalette.typography.primary,
                  },
                  '&.Mui-disabled': {
                    color: ThemePalette.typography.secondary,
                  },
                }}
              />
              <ListItemText primary={option.display} />
            </MenuItem>
          ))}

          {/* to supress invalid out of range value errors when using objects as values */}
          <MenuItem
            hidden
            value={value.toString()}
            sx={{
              display: 'none',
              color: enableDarkTheme
                ? ThemePalette.typography.lightGrey
                : ThemePalette.typography.black,
            }}
          ></MenuItem>
        </Select>
      )}
    </FormControl>
  );
};
