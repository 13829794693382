import { type IUpdateDevicePayload } from 'types/payloads/device.payload.types';
import BaseAPI from './base.api';

export default class DeviceAPI extends BaseAPI {
  update = async (deviceId: string, payload: IUpdateDevicePayload) => {
    const response = await this.API.patch(`device/${deviceId}`, payload);
    return response.data;
  };

  unassociatedDeviceGetAll = async () => {
    const response = await this.API.get('device/unassociated');
    return response.data;
  };

  deviceLatestSensorDataAll = async () => {
    const response = await this.API.get('device/latest-sensor-data');
    return response.data;
  };
}
