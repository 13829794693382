import { Grid } from '@mui/material';
import { useAppSelector } from 'store/hook';
import { selectAssetByMakeModel } from 'store/asset.slice';
import FuelEfficiency from './components/FuelEfficiency';
import FuelNoneIdleConsumption from '../components/FuelConsumption/FuelNoneIdleConsumption';
import FuelConsumptionRange from '../components/FuelConsumptionRange/FuelConsumptionRange';
import type { NonHaulTruckFuelEventItem } from 'types/fuelAnalytics';
import { useEffect } from 'react';
import { selectExportFormat } from 'store/exportData.slice';
import { chartToExcelForNonHaulTruckUsageDashboard } from 'utils/exports/excel';
import { exportToPdf } from 'utils/exports/pdf';
import { formatDate } from 'views/Settings/ExternalUsers/Squares/Helpers/prepareFile';
import { formatDateToString } from 'utils/helpers/dateTime';
import type { ResponsePrice } from 'types/fuelPrice.types';

interface Props {
  makeModel: string[];
  showConsumptionRange?: boolean;
  exportTo?: boolean;
  handleExport: (val: boolean) => void;
  selectedEndDate?: Date;
  selectedStartDate?: Date;
  selectedDuration?: string;
  selectedConsumptionRange: string;
  setSelectedConsumptionRange: any;
  fuelPriceData: ResponsePrice;
  enableDarkTheme: boolean;
  startDateFilter: Date;
  endDateFilter: Date;
  selectedDevice: string | undefined;
}

export const OtherAssetsView = ({
  makeModel,
  showConsumptionRange = false,
  exportTo,
  handleExport,
  selectedStartDate,
  selectedEndDate,
  selectedDuration,
  selectedConsumptionRange,
  setSelectedConsumptionRange,
  fuelPriceData,
  enableDarkTheme = false,
  startDateFilter,
  endDateFilter,
  selectedDevice,
}: Props) => {
  const unitSystem = useAppSelector((state) => state.persistedReducer).customer
    .unitSystem;
  const assetMakeModelList = useAppSelector(selectAssetByMakeModel);
  const { isLoading } = useAppSelector((state) => state.appReducer);
  const { fuelEfficiency, fuelNoneIdleEvents } = useAppSelector(
    (state) => state.nonHaulTruckfuelAnalyticsReducer
  );
  const fuelAnalytics = useAppSelector((state) => state.fuelAnalyticsReducer);

  const makeModelsString = makeModel[0];
  let assetsList: any[] = [];

  makeModel.map((makeModel) => {
    assetsList = [...assetsList, ...assetMakeModelList[makeModel]];
    return makeModel;
  });

  const exportFormat = useAppSelector(selectExportFormat);

  const fileNameWithDateTime = selectedDuration
    ? `historical-usage-dashboard-${makeModel}-${formatDate(new Date())}`
    : `usage-dashboard-${makeModel}-${formatDate(new Date())}`;

  const title =
    selectedStartDate && selectedEndDate
      ? `Usage Dashboard for ${makeModelsString
          .split('!')
          .join(' ')
          .toUpperCase()} - (${formatDateToString(
          selectedStartDate
        )} - ${formatDateToString(selectedEndDate)})`
      : selectedDuration
      ? `Historical Usage Dashboard for ${makeModelsString
          .split('!')
          .join(' ')
          .toUpperCase()} - for the past ${
          selectedDuration.charAt(0).toUpperCase() + selectedDuration.slice(1)
        }`
      : `Dashboard for ${makeModelsString.split('!').join(' ').toUpperCase()}`;

  useEffect(() => {
    const fetchData = async () => {
      if (exportTo && exportFormat === 'pdf') {
        await exportToPdf(
          'usage-dashboard-non-haul-truck',
          `${fileNameWithDateTime}`,
          title,
          enableDarkTheme
        );
        handleExport(false);
      }
      if (exportTo && exportFormat === 'excel') {
        chartToExcelForNonHaulTruckUsageDashboard(
          [
            fuelEfficiency,
            formattter(fuelNoneIdleEvents),
            fuelAnalytics.fuelConsumptionRangeAnalytics,
          ],
          fileNameWithDateTime,
          `${title}`,
          !!selectedDuration,
          unitSystem
        );
        handleExport(false);
      }
    };

    void fetchData();
  }, [exportTo, handleExport, exportFormat]);

  return (
    <Grid id="usage-dashboard-non-haul-truck" container>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <FuelEfficiency
          assets={assetsList}
          fuelEfficiency={fuelEfficiency}
          isLoading={isLoading}
          enableDarkTheme={enableDarkTheme}
          selectedDevice={selectedDevice}
        />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <FuelNoneIdleConsumption
          assets={assetsList}
          fuelNoneIdleEvents={formattter(fuelNoneIdleEvents)}
          isLoading={isLoading}
          enableDarkTheme={enableDarkTheme}
          selectedDevice={selectedDevice}
        />
      </Grid>
      {showConsumptionRange ? (
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <FuelConsumptionRange
            data={fuelAnalytics.fuelConsumptionRangeAnalytics}
            isLoading={isLoading}
            assetMakeModel={makeModelsString}
            selectedConsumptionRange={selectedConsumptionRange}
            setSelectedConsumptionRange={setSelectedConsumptionRange}
            fuelPriceData={fuelPriceData}
            enableDarkTheme={enableDarkTheme}
          />
        </Grid>
      ) : (
        <></>
      )}
    </Grid>
  );
};

function formattter(arg: {
  data: NonHaulTruckFuelEventItem[];
  sortedBottom5: NonHaulTruckFuelEventItem[];
  sortedTop5: NonHaulTruckFuelEventItem[];
  fuelConsumptionAverage: number;
}) {
  const initialData = {
    data: arg.data.map((item) => {
      return getFormatted(item);
    }),
    sortedTop5NonEventIdlingData: arg.sortedTop5.map((item) => {
      return getFormatted(item);
    }),
    sortedBottom5NonEventIdlingData: arg.sortedBottom5.map((item) => {
      return getFormatted(item);
    }),
    nonIdlingAverage: arg.fuelConsumptionAverage,
  };
  return initialData;
}

function getFormatted(item: any) {
  return {
    device: item.device,
    customer_day: new Date(),
    total_idle_fuel_consumption: item.total_idle_fuel_consumption,
    total_idle_time_minutes: item.total_idle_time,
    avg_idle_fuel_consumption: item.avg_fuel_litre_consumption,
    avg_idle_time_minutes: item.avg_idle_time,
    bumperNumber: item.bumperNumber,
    'Fuel Source': item.fuelSource,
    CO2: item.CO2,
  };
}
