import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import { SVG } from 'components/Asset/SVG';
import { LinkNavigateButton } from 'components/Buttons/LinkNavigateButton';
import {
  modalBackdropStyle,
  modalContentContainerStyle,
} from 'components/Modals/common.modal.styles.css';

export const NoAccess = () => {
  const paragraphs: Record<string, string> = {
    one: `We apologize, but it seems that the page you are trying to visit is not accessible given your current role.`,
    two: `Our system is designed to ensure that users only have access to the areas of the site that are relevant to
     their needs and responsibilities. Unfortunately, it appears that the route you have attempted to access is not
     available for your specific role. hesitate to contact us.`,
    three: `If you believe that you should have access to this page, please contact the site administrator or your supervisor
     to discuss your access privileges. Alternatively, please navigate to another area of the site that is accessible to your role.`,
    four: `We appreciate your understanding and apologize for any inconvenience this may have caused. If you have any further
     questions or concerns, please do not hesitate to contact us.`,
  };

  const paragraphItems = Object.keys(paragraphs);

  return (
    <Modal
      open={true}
      slotProps={{
        backdrop: {
          sx: modalBackdropStyle,
        },
      }}
    >
      <Box
        sx={{
          ...modalContentContainerStyle,
          minWidth: '400px',
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <SVG name="symboticwareLogoFull" style={{ paddingBottom: '26px' }} />
        {paragraphItems.map((key) => (
          <Typography
            id={`para-${key}`}
            key={`para-key-${key}`}
            variant="body2"
            component="h2"
            display="block"
            paddingBottom="14px"
          >
            {paragraphs[key]}
          </Typography>
        ))}

        <LinkNavigateButton
          replace={true}
          to="/fleet-summary"
          variant="body2"
          color={'button.primary'}
        >
          Back to Fleet Overview
        </LinkNavigateButton>
      </Box>
    </Modal>
  );
};
