import React from 'react';
import dayjs from 'dayjs';
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Legend,
} from 'recharts';
import { pastelColorMap, generateRandomColor } from 'utils/helpers/general';

const CustomTooltip: React.FC<{
  active?: boolean;
  payload?: any;
  label?: string;
}> = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    const tooltipContent = (
      <>
        <p></p>
        {payload.map((item: any, index: number) => (
          <React.Fragment key={item.payload.asset_id}>
            <p
              style={{
                color: pastelColorMap[index] || generateRandomColor(),
              }}
            >
              {item.payload.asset_id} - Total Cost Asset:{' '}
              {item.payload.total_cost_asset}
            </p>
            <p
              style={{
                color: pastelColorMap[index] || generateRandomColor(),
              }}
            >
              {item.payload.asset_id} - Work Order Number:{' '}
              {item.payload.work_order_number}
            </p>
          </React.Fragment>
        ))}
      </>
    );
    return <div className="custom-tooltip">{tooltipContent}</div>;
  }

  return null;
};

export const ComponentsLineChart = ({ data }: { data: [] }) => {
  const formatTimestamp = (timestamp: number): string => {
    const date = dayjs.unix(timestamp);
    const formattedDate = date.format('YYYY MMM DD');
    return formattedDate;
  };

  return (
    <ResponsiveContainer width="100%" height="100%">
      <AreaChart
        width={400}
        height={300}
        margin={{
          top: 5,
          right: 60,
          left: 40,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          dataKey="timestamp"
          domain={['auto', 'auto']} // Set x-axis domain automatically
          scale="time" // Set x-axis scale to 'time'
          type="number" // Set x-axis type to 'number'
          allowDuplicatedCategory={false}
          tickFormatter={formatTimestamp}
          tick={{ fontSize: 14, fontWeight: 'normal' }}
        />
        <YAxis
          label={{
            value: 'Cost',
            angle: -90,
            position: 'outside',
            fill: 'black',
            dy: 10,
            dx: -42,
          }}
        />
        <Tooltip content={<CustomTooltip />} />
        <Legend />
        {data.map((dt: any, index: number) => {
          return (
            <Area
              name={dt.name}
              key={index}
              type="monotone"
              dataKey="total_cost_asset"
              data={dt.data}
              stroke={pastelColorMap[index] || generateRandomColor()}
              fill={pastelColorMap[index] || generateRandomColor()}
              fillOpacity={0.3}
              strokeOpacity={1}
              activeDot={{ r: 8 }}
            />
          );
        })}
      </AreaChart>
    </ResponsiveContainer>
  );
};
