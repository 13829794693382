import { SVG } from 'components/Asset/SVG';
import { FlexBox } from 'components/Containers/FlexBox';
import { MediumText } from 'components/Typography/MediumText';

interface Props {
  headerName: string;
  iconName: string;
}

/*
    Note, this only renders the tooltip icon, not the tooltip itself.
    To render tooltip in AG grid headers, use the headerTooltip: 'Tooltip Text'
    property in columnDefs
*/
export const WithIconHeader = ({ headerName, iconName }: Props) => {
  return (
    <FlexBox>
      <MediumText text={headerName} />
      <SVG
        name={iconName}
        style={{ paddingLeft: '5px', alignSelf: 'center' }}
      />
    </FlexBox>
  );
};
