import { FlexBox } from 'components/Containers/FlexBox';
import {
  ComposedChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  ReferenceLine,
  Line,
} from 'recharts';
import { Box, Typography } from '@mui/material';
import type { Asset } from 'types/asset.types';
import type { DataEntry, Data, HistogramData } from 'types/FleetOverview.types';
import { ThemePalette } from 'mui.theme';
import { useState, useEffect } from 'react';
import { Selector } from 'components/Form/Selectors/Selector';
import { findHighestAndValuesForKey } from '../FleetOverview/helpers/asset.helpers';
import { findHighestAndLowestCumulativeTotal } from './helper';

export const ChartView = ({
  enableDarkTheme,
  assets,
  metrics,
}: {
  enableDarkTheme: boolean;
  assets: Asset[];
  metrics: Data;
}) => {
  const [assetBumperIds, setAssetBumperIds] = useState<string[]>([]);
  const [data, setData] = useState<any[]>([]);
  const [selectedAsset, setSelectedAsset] = useState<string>('');

  const colorIndex = ['#F08733', '#58BF6A', '#497BFA'];

  useEffect(() => {
    const formattedMetrics: any[] = [];
    const newAssetBumperIdsSet: Set<string> = new Set<string>();

    for (const key in metrics) {
      let obj = {
        name: key.replace(/\b\w/g, (char) => char.toUpperCase()),
      };
      const value = (metrics as any)[key] as {
        data: DataEntry[];
        histogram: HistogramData[];
      };
      const benchmarkValue =
        value.data.find((item) => item.device === 'benchmark')?.value ?? 0;

      value.data.forEach((item) => {
        const currentAsset = assets.find(
          (asset) => asset.device.deviceId === item.device
        );
        if (currentAsset) {
          newAssetBumperIdsSet.add(currentAsset.bumperNumber);
          obj = {
            ...obj,
            [currentAsset.bumperNumber]: percentageDifference(
              benchmarkValue,
              item.value
            ),
          };
        }
      });

      formattedMetrics.push(obj);
    }

    const newAssetBumperIds = Array.from(newAssetBumperIdsSet);

    const mergedArr = findHighestAndValuesForKey(
      formattedMetrics,
      newAssetBumperIds[0]
    );

    setData(mergedArr);
    setAssetBumperIds(newAssetBumperIds);
    setSelectedAsset(newAssetBumperIds[0]);
  }, [metrics, assets]);

  useEffect(() => {
    if (selectedAsset !== '') {
      const mergedArr = findHighestAndValuesForKey(data, selectedAsset);

      setData(mergedArr);
    }
  }, [selectedAsset]);

  const selectorOptions = assetBumperIds.map((item) => {
    return {
      id: item,
      display: item,
    };
  });

  const { highest, lowest } = findHighestAndLowestCumulativeTotal(data);

  return (
    <Box>
      <Box
        sx={{
          paddingBottom: '20px',
        }}
      >
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          sx={{
            paddingRight: '5px',
          }}
        >
          <Typography
            sx={{
              color: enableDarkTheme
                ? ThemePalette.typography.white
                : ThemePalette.typography.black,
              paddingTop: '10px',
              paddingBottom: '10px',
            }}
          >
            Pareto Chart
          </Typography>

          <Box display="flex" alignItems="center">
            {assetBumperIds.map((item, index) => {
              return (
                <>
                  <Box width={10} height={10} bgcolor={colorIndex[index]} />
                  <Typography
                    sx={{
                      fontSize: '11px',
                      paddingLeft: '5px',
                      paddingRight: '10px',
                      color: enableDarkTheme
                        ? ThemePalette.typography.white
                        : ThemePalette.typography.black,
                    }}
                    key={index}
                  >
                    {item}
                  </Typography>
                </>
              );
            })}
            <Typography
              sx={{
                paddingLeft: '20px',
                paddingRight: '20px',
                color: enableDarkTheme
                  ? ThemePalette.typography.white
                  : ThemePalette.typography.black,
                fontSize: '12px',
              }}
            >
              Pareto Primary
            </Typography>

            <Selector
              minWidth="1px"
              value={selectedAsset}
              onChange={(event) => {
                setSelectedAsset(String(event.target.value));
              }}
              selectorOptions={selectorOptions}
              enableDarkTheme={enableDarkTheme}
            />
          </Box>
        </Box>
      </Box>
      <FlexBox
        sx={{
          justifyContent: 'center',
          alignItems: 'center',
          maxWidth: 'inherit',
          height: '500px',
          flexDirection: 'column',
          paddingTop: '20px',
          marginRight: '10px',
          paddingBottom: '10px',
        }}
        id="vehicle-status-page"
      >
        <ResponsiveContainer width="100%" height="100%">
          <ComposedChart
            width={700}
            height={300}
            data={data}
            margin={{
              top: 20,
              right: 30,
              left: 20,
              bottom: 30,
            }}
          >
            <CartesianGrid
              strokeDasharray="3 3"
              stroke={
                enableDarkTheme
                  ? ThemePalette.dark.cartesianGrid
                  : ThemePalette.light.cartesianGrid
              }
              vertical={false}
            />
            <XAxis
              dataKey="name"
              label={{
                value: 'Metric',
                fill: enableDarkTheme
                  ? ThemePalette.typography.wheat
                  : ThemePalette.typography.black,
                dy: 30,
                dx: 0,
              }}
            />
            <YAxis
              orientation="left"
              yAxisId="left"
              label={{
                value: 'Change from benchmark (%)',
                angle: -90,
                position: 'inside',
                fill: enableDarkTheme
                  ? ThemePalette.typography.wheat
                  : ThemePalette.typography.black,
                dy: 0,
                dx: -15,
              }}
            />
            <YAxis
              orientation="right"
              yAxisId="right"
              label={{
                value: 'Cumulative Total (%) (Pareto Primary)',
                angle: -90,
                position: 'inside',
                fill: enableDarkTheme
                  ? ThemePalette.typography.wheat
                  : ThemePalette.typography.black,
                dy: 0,
                dx: 15,
              }}
              domain={[lowest ?? 0, highest ?? 100]}
            />
            <Tooltip />
            <ReferenceLine
              y={0}
              stroke="#26364C"
              strokeWidth={2}
              yAxisId="left"
            />
            {assetBumperIds.map((item, index) => {
              return (
                <Bar
                  dataKey={item}
                  fill={colorIndex[index]}
                  key={index}
                  yAxisId="left"
                />
              );
            })}
            <Line
              type="monotone"
              activeDot={{ r: 8 }}
              dataKey="Cumulative Total"
              yAxisId="right"
              stroke={
                colorIndex[
                  selectorOptions.findIndex((item) => item.id === selectedAsset)
                ]
              }
            />
          </ComposedChart>
        </ResponsiveContainer>
      </FlexBox>
    </Box>
  );
};

function percentageDifference(
  firstNumber: number,
  secondNumber: number
): number {
  let percentageChange;

  if (firstNumber === 0) {
    if (secondNumber === 0) {
      percentageChange = 0; // If both numbers are zero, the percentage change is zero.
    } else {
      percentageChange = 100; // If the first number is zero and the second number is not, treat it as a 100% increase.
    }
    return parseFloat(percentageChange.toFixed(2));
  }

  percentageChange = ((secondNumber - firstNumber) / firstNumber) * 100;
  return parseFloat(percentageChange.toFixed(2));
}
