import { Typography } from '@mui/material';
import { InteractiveModal } from 'components/Modals/InteractiveModal/BaseModal';
import OperatorHandler from 'handlers/operator.handler';

export {};

/**
 *
 * The activation modal is a modal that is used to activate an External operator.
 */

interface Props {
  open: boolean;
  operatorId: string;
  isActive: boolean;
  handleAbort: () => void;
}

export const OperatorActivationModal = ({
  open,
  operatorId,
  isActive,
  handleAbort,
}: Props) => {
  const operatorHandler = new OperatorHandler();
  const title = 'Confirm Operator Reactivation';
  return (
    <InteractiveModal
      initialState={open}
      title={title}
      handleAbort={handleAbort}
      SuccessButtonProps={{
        onClick: async () => {
          await operatorHandler.activationOperator(operatorId, isActive);
          handleAbort();
        },
        disabled: false,
        label: 'Reactivate Operator',
      }}
      ModalContentProps={{
        gridItemProps: {
          lg: 12,
        },
      }}
    >
      <Typography key="blurb-1" variant="medium">
        Are you sure you want to reactivate {operatorId}?
      </Typography>
      <Typography key="blurb-2" variant="medium"></Typography>
    </InteractiveModal>
  );
};
