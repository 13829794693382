import { Typography } from '@mui/material';
import { FlexBox } from 'components/Containers/FlexBox';
import { useState, useEffect } from 'react';
import { Cell, Pie, PieChart } from 'recharts';

const LoadingPieChart = () => {
  const initialData = [
    { name: 'Grey Segment', value: 4 },
    { name: 'Dark Grey Segment', value: 1 },
  ];

  const [placeholderData, setPlaceholderData] = useState(initialData);

  useEffect(() => {
    const interval = setInterval(() => {
      setPlaceholderData(
        placeholderData.map((item) => ({
          ...item,
          value: Math.random() * 100, // Random value between 0 and 100
        }))
      );
    }, 700); // Update every 500 milliseconds

    return () => {
      clearInterval(interval);
    }; // Clean up the interval on component unmount
  }, [placeholderData]);

  return (
    <div>
      <FlexBox
        sx={{
          flexDirection: 'column',
        }}
      >
        <div style={{ width: '100%', maxWidth: 300, height: 300 }}>
          <PieChart width={300} height={300}>
            <Pie
              data={placeholderData}
              cx="35%"
              cy="45%"
              outerRadius={100}
              innerRadius={65}
              dataKey="value"
              startAngle={90}
              endAngle={-270} // Full circle
            >
              {/* Mapping through data to set colors */}
              {placeholderData.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={index === 0 ? '#dcdcdc' : '#a9a9a9'}
                />
              ))}
            </Pie>
            <foreignObject x="-45" y="85" width="100%" height="100%">
              <div
                style={{
                  textAlign: 'center',
                  lineHeight: '100px',
                  fontSize: '14px', // You can adjust the font size as needed
                  fontWeight: 'bold', // You can adjust the font weight as needed
                  color: '#333', // You can adjust the text color as needed
                }}
              >
                Loading
              </div>
            </foreignObject>
          </PieChart>
        </div>
      </FlexBox>
    </div>
  );
};

export default LoadingPieChart;
