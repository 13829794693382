import { createSlice } from '@reduxjs/toolkit';
import type { Notification } from 'types/notification.types';
import type { ICreateRulePayload } from 'types/payloads/rule.payload.types';

export interface RuleState {
  rules: Notification[];
  newRule: ICreateRulePayload;
}

const initialState: RuleState = {
  rules: [],
  newRule: {
    ruleName: '',
    assets: [],
    conditions: [],
    alertByEmail: false,
    alertByUI: false,
    alertByNotificationCenter: false,
    distributionUsers: [],
    notificationLevel: '1',
  },
};

export const ruleSlice = createSlice({
  name: 'rules',
  initialState,
  reducers: {
    updateNewRule(
      state,
      { payload }: { payload: typeof initialState.newRule }
    ) {
      state.newRule = payload;
    },
    resetCreateRule(state) {
      state.newRule = initialState.newRule;
    },
    appendRuleCondition(state, { payload }) {
      /*
       * This action adds a conditon to the newRule attribute if it does not exist
       */

      // If conditions array exists in state
      if (state.newRule.conditions) {
        const conditionsExists = state.newRule.conditions.find(
          (c) => c.id === payload.id
        );
        // Check If payload exists in conditions array using the id
        if (conditionsExists) {
          // payload exists in condition array so we replace existing with payload.
          const updatedConditions = state.newRule.conditions.map((c) => {
            if (c.id === payload.id) {
              return payload;
            }
            return c;
          });
          state.newRule.conditions = updatedConditions;
        }
        // Payload does not exist in condition array so we push to the end of condition array
        else {
          state.newRule.conditions.push(payload);
        }
      }
      // If condition array does not exists in state, we initialize a new array
      else {
        state.newRule.conditions = [payload];
      }
    },
    /*
     * This action removes an item from the condition array
     */
    removeRuleCondition(state, { payload }) {
      if (state.newRule.conditions) {
        const updatedConditions = state.newRule.conditions.filter(
          (c) => c.id !== payload
        );
        state.newRule.conditions = updatedConditions;
      }
      return state;
    },
    /*
     * This action updates the notification attributes in the newRule Attribute
     */
    addRuleNotification(state, { payload }) {
      const {
        alertByEmail,
        alertByUI,
        alertByNotificationCenter,
        distributionUsers,
        notificationLevel,
      } = payload;
      const updatedRule = {
        ...state,
        newRule: {
          ...state.newRule,
          alertByEmail,
          alertByUI,
          alertByNotificationCenter,
          distributionUsers,
          notificationLevel,
        },
      };
      return updatedRule;
    },
    appendRule(state, { payload }) {
      state.rules.push(payload);
      state.newRule = initialState.newRule;
    },
    setRules(state, { payload }) {
      state.rules = payload;
    },
    updateRuleList(state, { payload }: { payload: Notification }) {
      const index = state.rules.findIndex(
        (rule) => rule.ruleId === payload.ruleId
      );

      if (index === -1) {
        // do not update the state if the operator is not found
        return;
      }
      state.rules[index] = payload;
    },
    updateRuleStatus(state, { payload }) {
      const updatedRules = state.rules.map((rule) => {
        if (rule.ruleId === payload.ruleId) {
          return {
            ...rule,
            ...payload,
          };
        }
        return rule;
      });
      state.rules = updatedRules;
    },
    removeRule(state, { payload }) {
      const updatedRules = state.rules.filter(
        (rule) => rule.ruleId !== payload.ruleId
      );
      state.rules = updatedRules;
    },
  },
});

export const {
  updateNewRule,
  resetCreateRule,
  appendRuleCondition,
  removeRuleCondition,
  addRuleNotification,
  appendRule,
  setRules,
  updateRuleList,
  updateRuleStatus,
  removeRule,
} = ruleSlice.actions;

export default ruleSlice.reducer;
