import { Checkbox } from '@mui/material';
import { ThemePalette } from 'mui.theme';

interface Props {
  node: any;
  data: Record<string, any>;
  field: string;
  onCheckboxChange: (param: any, nodeId?: string) => void;
  enableDarkTheme: boolean;
}
/**
 * Checkbox Cell Renderer
 * @param Props
 * @returns {JSX.Element}
 */
export const CheckboxRenderer = ({
  node,
  data,
  field,
  onCheckboxChange,
  enableDarkTheme,
}: Props) => {
  let isDisabled = !data.isActive;
  if (field === 'isActive') {
    isDisabled = false;
  }
  return (
    <span>
      <Checkbox
        disabled={isDisabled}
        sx={{
          alignSelf: 'center',
          color: enableDarkTheme
            ? ThemePalette.typography.cornflowerBlue
            : ThemePalette.typography.primary,
          '&.Mui-checked': {
            color: enableDarkTheme
              ? ThemePalette.typography.cornflowerBlue
              : ThemePalette.typography.primary,
          },
          '&.Mui-disabled': {
            color: ThemePalette.typography.secondary,
          },
        }}
        checked={data[field]}
        onChange={(e) => {
          if (!e.target.value) return;
          if (!onCheckboxChange) return;

          onCheckboxChange(data, node.id);
        }}
      />
    </span>
  );
};
