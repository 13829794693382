import { type ICreateInternalUserPayload } from 'types/payloads/user.payload.types';
import { type InternalUser } from 'types/user.types';

/**
 * @param event
 * @returns void
 *
 * This function is used to handle the change event
 * of the customer account selector.
 * It will add or remove the selected customer account
 * from the internal user's customer accounts array.
 */
export const handleSelectCustomerSingle = (
  event: { target: { value: any } },
  internalUser: ICreateInternalUserPayload,
  setInternalUser: React.Dispatch<
    React.SetStateAction<ICreateInternalUserPayload>
  >
) => {
  const selectedValue = event.target.value;

  if (!selectedValue?.length) {
    setInternalUser({
      ...internalUser,
      userCustomerAssociations: [],
    });

    return;
  }

  const selectedCustomer = JSON.parse(selectedValue[selectedValue.length - 1]);

  const userCustomerAssociations = [...internalUser.userCustomerAssociations];
  const idx = userCustomerAssociations.findIndex(({ id }, idx) => {
    return id === selectedCustomer.id;
  });

  // found the index of the customer account, remove
  if (idx > -1) {
    userCustomerAssociations.splice(idx, 1);
  } else {
    userCustomerAssociations.push(selectedCustomer);
  }

  setInternalUser({
    ...internalUser,
    userCustomerAssociations,
  });
};

/**
 *
 * @param userCustomerAssociationsAll
 * @param internalUser
 * @param setInternalUser
 * @returns void
 *
 * This function is used to handle the change event
 * of the customer account selector.
 *
 * It will add or remove all customer accounts
 * from the internal user's customer accounts array.
 *
 */
export const handleSelectCustomerAll = (
  userCustomerAssociationsAll: InternalUser['userCustomerAssociations'], // todo: type this
  internalUser: ICreateInternalUserPayload,
  setInternalUser: React.Dispatch<
    React.SetStateAction<ICreateInternalUserPayload>
  >
) => {
  const currCustomers = internalUser.userCustomerAssociations;

  // if all customer accounts are selected, remove all
  if (userCustomerAssociationsAll.length === currCustomers.length) {
    setInternalUser({
      ...internalUser,
      userCustomerAssociations: [],
    });

    return;
  }

  // if not all customer accounts are selected, add all
  setInternalUser({
    ...internalUser,
    userCustomerAssociations: userCustomerAssociationsAll,
  });
};

export const handleFormValidation = () => {};
