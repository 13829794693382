import { Typography } from '@mui/material';
import { ThemePalette } from 'mui.theme';
import type { TabProps } from 'react-tabs';
import { Tab } from 'react-tabs';
import './style.css';

interface Props extends TabProps {
  children: string;
  enableDarkTheme?: boolean;
}
// All custom elements should pass through other props
const SWTab = ({ children, enableDarkTheme = false, ...rest }: Props) => {
  const { focus }: any = rest;
  return (
    <Tab {...rest}>
      <Typography
        variant="medium"
        sx={{
          color: enableDarkTheme
            ? ThemePalette.typography.white
            : focus
            ? ThemePalette.typography.white
            : ThemePalette.typography.primary,
        }}
      >
        {children}
      </Typography>
    </Tab>
  );
};

SWTab.tabsRole = 'Tab'; // Required field to use custom Tab

export default SWTab;
