import type { GroupedHistogramDataItem } from 'types/FleetOverview.types';

interface TransformedDataPoint {
  name: string;
  [key: string]: number | string;
}

type DateMap = Record<string, TransformedDataPoint>;

export function findHighestAndLowestCumulativeTotal(arr: any[]) {
  if (arr.length === 0) {
    return { highest: null, lowest: null };
  }

  const cumulativeTotals = arr.map((item) => item['Cumulative Total']);

  const highest = Math.max(...cumulativeTotals);
  const lowest = Math.min(...cumulativeTotals);

  return { highest, lowest };
}

export function transformData(
  data: GroupedHistogramDataItem[]
): TransformedDataPoint[] {
  const dateMap: DateMap = {};

  data.forEach((series) => {
    series.data.forEach((point) => {
      if (!dateMap[point.date]) {
        dateMap[point.date] = { name: point.date };
      }
      dateMap[point.date][series.name] = point.value;
    });
  });

  return Object.values(dateMap);
}

export function calculateAverage(arr: any) {
  if (arr.length === 0) return [];

  const result = [arr[0]];

  for (let i = 1; i < arr.length; i++) {
    const prevItem = result[i - 1];
    const newItem: any = { name: arr[i].name };

    for (const key in arr[i]) {
      if (key !== 'name') {
        // eslint-disable-next-line
        newItem[key] = Number(((prevItem[key] + arr[i][key]) / 2).toFixed(2));
      }
    }

    result.push(newItem);
  }

  return result;
}
