/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable @typescript-eslint/await-thenable */
import { useEffect, useState } from 'react';
import { usePDF } from 'react-to-pdf';
import { selectExcelExportData } from 'store/exportData.slice';
import { useAppSelector } from 'store/hook';
import { processArrays } from 'utils/exports/excel';
import { isDarkTheme } from 'utils/theme';
import { formatDate } from 'views/Settings/ExternalUsers/Squares/Helpers/prepareFile';

const ToPdf = () => {
  const theme = useAppSelector((state) => state.authReducer).customer.theme;
  const enableDarkTheme = isDarkTheme(theme);
  const [isTableLoaded, setIsTableLoaded] = useState(false);
  const {
    data,
    columnHeaders,
    fileDetails: { filename, title, subTitle },
  } = useAppSelector(selectExcelExportData);

  const { toPDF, targetRef } = usePDF({
    filename: `${filename}-${formatDate(new Date())}`,
    page: { orientation: 'landscape', margin: 4 },
  });

  const rowData = [...data];
  let rowHeaders: any = [...columnHeaders];
  rowHeaders = rowHeaders.filter((item: any) => item.field !== 'ruleId');
  const newData = processArrays(rowData, rowHeaders);

  useEffect(() => {
    const setupTable = async () => {
      // Simulate an asynchronous operation (e.g., fetching data) before setting the table
      await new Promise((resolve) => setTimeout(resolve, 1000));

      // Set the table loaded state when the table is fully set up
      setIsTableLoaded(true);
    };

    setupTable();
  }, []);

  useEffect(() => {
    const downloadPDF = async () => {
      // Wait for the table to be loaded before triggering the download
      if (targetRef.current && newData.length > 0 && isTableLoaded) {
        toPDF();
      }
    };

    downloadPDF();
  }, [targetRef, newData, isTableLoaded]);

  return (
    <div>
      <table
        style={{
          width: '100%',
          borderCollapse: 'collapse',
          fontFamily: "'Montserrat', 'Open Sans'",
          backgroundColor: enableDarkTheme ? '#0A192D' : '#FFFFFF',
        }}
        ref={targetRef}
      >
        <caption>
          <h1>
            {title}
            <span style={{ fontSize: '15px' }}>{subTitle}</span>
          </h1>
        </caption>
        <thead>
          <tr
            style={{
              backgroundColor: '#023059',
              color: '#fff',
            }}
          >
            {rowHeaders?.map((header: any, index: number) => (
              <th
                key={index}
                style={{
                  border: '.5px solid rgba(255,255, 255, 0.1)',
                  padding: '2px 4px',
                }}
              >
                {header.headerName}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {newData.map((item: any, index: number) => (
            <tr key={index}>
              {Object.values(item).map((value: any, colIndex: number) => (
                <td
                  key={colIndex}
                  style={{
                    border: '.5px solid rgba(0,0, 0, 0.1)',
                    // padding: '8px',
                    textAlign: 'left', // adjust text alignment as needed
                  }}
                >
                  {value}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ToPdf;
