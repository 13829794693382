import { type FC } from 'react';
interface StageProgressBarRendererProps {
  value: string;
}

export const StageProgressBarRenderer: FC<StageProgressBarRendererProps> = ({
  value,
}) => {
  // Extract the numeric part of the stage value to determine progress
  const stageNumber = parseInt(value.split('_')[0], 10);
  const totalStages = 8;
  const progressPercentage = Math.round((stageNumber / totalStages) * 100);
  const isCompleted = value.includes('_Complete');
  // Check if the value contains "99_" indicating an error
  const isError = value.includes('99_');

  const tooltipText = value.substring(2).replace('_', ' ').toUpperCase();

  return (
    <div
      style={{ width: '100%', display: 'flex', alignItems: 'center' }}
      title={tooltipText} // Adding tooltip
    >
      {isCompleted ? (
        // Display "Completed" in green if the stage contains "_Complete"
        <div style={{ color: 'green', fontWeight: 'bold' }}>COMPLETED</div>
      ) : isError ? (
        // Display "ERROR" in red if the stage contains "99_"
        <div style={{ color: 'red', fontWeight: 'bold' }}>ERROR</div>
      ) : (
        // Otherwise, display the progress bar
        <>
          <div
            style={{
              width: `${progressPercentage}%`,
              backgroundColor: 'lightblue',
              height: '20px',
            }}
          ></div>
          <div style={{ marginLeft: '10px' }}>{`${progressPercentage}%`}</div>
        </>
      )}
    </div>
  );
};

interface formatDateProps {
  value: string | null | undefined;
}

export const formatDate: FC<formatDateProps> = ({ value }) => {
  if (!value) return <></>;
  const date = new Date(value);
  return <span>{date.toLocaleString()}</span>;
};
