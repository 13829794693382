import BaseAPI from './base.api';

export class SharedUserAPI extends BaseAPI {
  /**
   * Requests a password reset for a user by id.
   * Invalidates a users Auth0 credentials and starts a workflow to reset the password.
   * This API is invokable by: director, internal-user, external-admin
   *
   * Restrictions:
   * - external-admin can not invalidate credentials of users outside of their company. The API will return 404
   * @param userId The internal_user_id (ID of the user from the database)
   */
  resetPassword = async (userId: string) => {
    const response = await this.API.post('user/reset-password', { userId });
    return response.data;
  };

  syncUserInfo = async () => {
    const response = await this.API.get('user/sync');
    return response.data;
  };
}
