import * as React from 'react';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { useAppDispatch, useAppSelector } from 'store/hook';
import { clearNotification } from 'store/notification.slice';

export const SnackBar = () => {
  const notification = useAppSelector((state) => state.notificationReducer);
  const dispatch = useAppDispatch();
  const handleClose = () => {
    dispatch(clearNotification());
  };
  const vertical = 'bottom';
  const horizontal = 'right';

  /*
    We want to Close the snackbar after 3 secs 
    when the notification is not an error type 
  */
  React.useEffect(() => {
    if (['error', 'longSuccess'].includes(notification.type)) return;
    const timerId = setTimeout(() => {
      handleClose();
    }, 3000);
    // Cleaning up timeout here to prevent memory leak
    return () => {
      clearTimeout(timerId);
    };
  }, [notification]);

  return (
    <Snackbar
      open={notification.show}
      anchorOrigin={{ vertical, horizontal }}
      key={vertical + horizontal}
    >
      <Alert
        onClose={handleClose}
        icon={false}
        severity={
          ['success', 'longSuccess'].includes(notification.type)
            ? 'success'
            : notification.type === 'error'
            ? 'error'
            : notification.type === 'info'
            ? 'info'
            : 'warning'
        }
        sx={{
          width: '100%',
          color: '#FFFFFF',
          letterSpacing: '0px',
          textAlign: 'left',
        }}
      >
        {notification.message}
      </Alert>
    </Snackbar>
  );
};
