import { useState } from 'react';
import { useAppSelector, useAppDispatch } from 'store/hook';
import { Base } from 'components/Containers/BaseContainer';
import { SVG } from 'components/Asset/SVG';
import { LinkButton } from 'components/Buttons/LinkButton';
import { ToolbarContainer } from 'components/Containers/ToolbarContainer';
import { FlexBox } from 'components/Containers/FlexBox';
import { resetCreateRule } from 'store/rule.slice';
import { NotificationList } from './components/NotificationList';
import { CreateRuleGeneralInfoModal } from './components/modals/CreateRuleGeneralInfo';
import { CreateRuleConditionsModal } from './components/modals/CreateRuleConditions';
import { CreateRuleConfirmationModal } from './components/modals/RuleConfirmation';
import { selectAssetsWithTags } from 'store/asset.slice';
import { type SelectChangeEvent } from '@mui/material';
import ExportSelector from 'views/FleetOverview/components/FilterViewBar/ExportSelector';
import { useCustomDropdownEffect } from 'utils/exports/hooks';
import { isDarkTheme } from 'utils/theme';
import { ThemePalette } from 'mui.theme';

enum OpenModal {
  None,
  RuleGeneralInfo,
  RuleConditions,
  RuleConfirmation,
}

export const NotificationView = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const { newRule } = useAppSelector((state) => state.ruleReducer);
  const theme = useAppSelector((state) => state.authReducer).customer.theme;
  const enableDarkTheme = isDarkTheme(theme);
  const [modalOpen, setModalOpen] = useState(OpenModal.None);

  const [exportDropdownTo, setExportDropdownTo] = useState('');
  const [exportToLabel, setExportToLabel] = useState('Export To');

  const handleExportDropDownChange = (event: SelectChangeEvent) => {
    setExportDropdownTo(event.target.value);
  };
  const [exportTo, setExportTo] = useState(false);
  const handleExportClick = (val: boolean) => {
    setExportTo(val);
  };

  useCustomDropdownEffect(
    exportDropdownTo,
    handleExportClick,
    setExportToLabel,
    setExportDropdownTo,
    [exportDropdownTo],
    ['Fleet Settings Rules', 'Page1', 'Rules - ']
  );

  const assetsWithTags = useAppSelector(selectAssetsWithTags);

  return (
    <Base enableDarkTheme={enableDarkTheme}>
      <CreateRuleGeneralInfoModal
        key={`${modalOpen.toString()}-create-rule-general-info`}
        open={modalOpen === OpenModal.RuleGeneralInfo}
        handleAbort={() => {
          setModalOpen(OpenModal.None);
          dispatch(resetCreateRule());
        }}
        handleConfirm={() => {
          setModalOpen(OpenModal.RuleConditions);
        }}
        title="Add a New Rule"
        assets={assetsWithTags}
        newRule={newRule}
        confirmCancellation={true}
        enableDarkTheme={enableDarkTheme}
      />

      <CreateRuleConditionsModal
        key={`${modalOpen.toString()}-create-rule-conditions`}
        open={modalOpen === OpenModal.RuleConditions}
        handleAbort={() => {
          setModalOpen(OpenModal.RuleGeneralInfo);
        }}
        handleConfirm={() => {
          setModalOpen(OpenModal.RuleConfirmation);
        }}
        title="Add a New Rule"
        newRule={newRule}
        assets={assetsWithTags}
        enableDarkTheme={enableDarkTheme}
      />

      <CreateRuleConfirmationModal
        key={`${modalOpen.toString()}-create-rule-confirmation`}
        open={modalOpen === OpenModal.RuleConfirmation}
        handleCancel={() => {
          setModalOpen(OpenModal.RuleConditions);
        }}
        handleAbort={() => {
          setModalOpen(OpenModal.None);
          dispatch(resetCreateRule());
        }}
        title="Add a New Rule"
        newRule={newRule}
        enableDarkTheme={enableDarkTheme}
      />

      <ToolbarContainer enableDarkTheme={enableDarkTheme}>
        <FlexBox
          sx={{
            width: '100%',
            justifyContent: 'flex-end',
          }}
        >
          <div style={{ marginRight: '10%' }}>
            <ExportSelector
              view={''}
              exportToLabel={exportToLabel}
              exportTo={exportDropdownTo}
              onExportToChange={handleExportDropDownChange}
              enableDarkTheme={enableDarkTheme}
            />
          </div>
          <FlexBox
            sx={{
              display: 'flex',
            }}
          >
            <SVG
              name="add"
              style={{ marginRight: '10px', paddingTop: '10px' }}
            />

            <LinkButton
              onClick={() => {
                setModalOpen(OpenModal.RuleGeneralInfo);
              }}
              color={
                enableDarkTheme
                  ? ThemePalette.typography.wheat
                  : ThemePalette.typography.primary
              }
            >
              Add a New Rule
            </LinkButton>
          </FlexBox>
        </FlexBox>
      </ToolbarContainer>
      <NotificationList
        exportTo={exportTo}
        handleExport={handleExportClick}
        enableDarkTheme={enableDarkTheme}
      />
    </Base>
  );
};
