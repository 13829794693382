import { createSlice } from '@reduxjs/toolkit';
import { type DashboardSquares } from 'types/squares.type';

export interface DashboardSquaresState {
  squares: DashboardSquares[];
  squareAssets: DashboardSquares[];
  dashboard: {
    assets: any;
    components?: any;
    timeseries?: any;
  };
  assetDashboard: {
    assets: any;
    components?: any;
    timeseries?: any;
  };
}

const initialState: DashboardSquaresState = {
  squares: [],
  squareAssets: [],
  dashboard: {
    assets: {},
    components: {},
    timeseries: {},
  },
  assetDashboard: {
    assets: {},
    components: {},
    timeseries: {},
  },
};

export const squaresDashboardSlice = createSlice({
  name: 'squares',
  initialState,
  reducers: {
    appendSquares(state, { payload }) {
      state.squares.push(payload);
    },

    updateSquaresList(state, { payload }: { payload: any }) {
      const index = state.squares.findIndex(
        (squares) => squares.category === payload.squaresId
      );

      if (index === -1) {
        // do not update the state if the squares is not found
        return;
      }

      state.squares[index] = payload;
    },

    setSquares(state, { payload }) {
      state.squares = payload;
    },

    setSquaresAssets(state, { payload }) {
      state.squareAssets = payload;
    },
    setSquaresDashboard(state, { payload }) {
      state.dashboard = payload;
    },
    setSquaresAssetsDashboard(state, { payload }) {
      state.assetDashboard = payload;
    },
  },
});

export const {
  appendSquares,
  updateSquaresList,
  setSquares,
  setSquaresAssets,
  setSquaresDashboard,
  setSquaresAssetsDashboard,
} = squaresDashboardSlice.actions;

export default squaresDashboardSlice.reducer;
