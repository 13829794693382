export interface CategoryData {
  name: string;
  totalCost: number;
  totalWorkOrders: string;
  totalHours: string;
}

export interface Asset {
  asset: string;
  total_asset_cost: number;
}

export function extractWorkOrderData(
  data: Record<string, any>
): CategoryData[] {
  const result: CategoryData[] = [];
  for (const key in data) {
    if (Object.hasOwnProperty.call(data, key)) {
      const {
        total_cost: totalCost,
        total_work_orders: totalWorkOrders,
        total_hours: totalHours,
      } = data[key];
      const newObj = {
        totalCost,
        totalWorkOrders,
        totalHours,
        name: key,
        size: 1,
      };
      result.push(newObj);
    }
  }
  result.sort((a, b) => {
    return b.totalCost - a.totalCost;
  });
  return result;
}

export function getAssetsByKey(
  data: Record<string, any>,
  key: string
): Asset[] | [] {
  const category = data?.[key];
  if (category?.assets) {
    const assetsWithNames = category.assets.map((asset: Asset) => ({
      ...asset,
      name: asset.asset, // Set 'name' to the value of 'asset'
    }));
    return assetsWithNames;
  }
  return [];
}

export function extractSelectedAssets(
  dataset: any,
  selectedLines: string[]
): any {
  const extractedData: any = {};
  selectedLines.forEach((lineKey: any) => {
    if (dataset[lineKey]) {
      extractedData[lineKey] = dataset[lineKey];
    }
  });
  const output: any[] = [];

  for (const key in extractedData) {
    if (Object.prototype.hasOwnProperty.call(extractedData, key)) {
      output.push({
        name: key,
        data: extractedData[key],
      });
    }
  }

  return output;
}

export function getFirstAttribute(obj: any): string {
  const arr = [];
  let response = '';
  // Check if the object is empty
  if (obj === undefined || Object.keys(obj).length === 0) {
    response = '';
  }

  for (const key in obj) {
    arr.push({ [key]: obj[key].total_cost || 0 });
  }

  if (arr.length > 0) {
    const sortedArr = arr.sort((a, b) => {
      const costA = Object.values(a)[0];
      const costB = Object.values(b)[0];
      return costB - costA;
    });

    const highestKey = Object.keys(sortedArr[0])[0];
    response = highestKey;
  }

  return response;
}
