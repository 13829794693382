import { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import dayjs from 'dayjs';
import { Base } from 'components/Containers/BaseContainer';
import { FlexBox } from 'components/Containers/FlexBox';
import { Box, type SelectChangeEvent, Typography } from '@mui/material';
import { Selector } from 'components/Form/Selectors/Selector';
import { useAppSelector } from 'store/hook';
import { selectAssetByMakeModel } from 'store/asset.slice';
import type { SelectOptions } from 'types/utils.types';
import FuelAnalyticsHandler from 'handlers/fuelAnalytics.handler';
import NonHaulTruckHandler from 'handlers/nonHaulTruck.handler';
import { ToolbarContainer } from 'components/Containers/ToolbarContainer';
import {
  capitalizeFirstLetter,
  safeReplace,
} from 'utils/helpers/string.manipulation';
import { HaulTruckView } from './HaulTrucks/HaulTrucks';
import { OtherAssetsView } from './OtherAssets/OtherAsets';
import ExportSelector from 'views/FleetOverview/components/FilterViewBar/ExportSelector';
import { useCustomDropdownEffect } from 'utils/exports/hooks';

import { ThemePalette } from 'mui.theme';
export const notAvailableText =
  'Vehicle types being monitored for this customer do not support Fuel Analytics currently';
export const klgMakeModelsToFilter = [
  'cat d11t',
  'cat d10t',
  'cat 395',
  'cat 795f',
  'cat 24m',
  'all assets',
];
export const gbsMakeModelsToFilter = [
  'sandvik lh-410',
  'sandvik lh-307',
  'all assets',
];

export const UsageDashboard = ({
  enableDarkTheme,
}: {
  enableDarkTheme: boolean;
}) => {
  const customerCode = useAppSelector((state) => state.persistedReducer)
    .customer.code;
  const { data: fuelPriceData } = useAppSelector(
    (state) => state.fuelPriceReducer
  );
  const fuelAnalyticsHandler = new FuelAnalyticsHandler();
  const nonHaulTruckHandler = new NonHaulTruckHandler();
  const assetMakeModelList = useAppSelector(selectAssetByMakeModel);
  const [assetOptions, setAssetOptions] = useState<SelectOptions[]>([]);
  const [selectedAsset, setSelectedAsset] = useState<string>('');
  const [showCharts, setShowCharts] = useState<boolean>(false);
  const [selectedStartDate, setStartDate] = useState(
    new Date(new Date().getTime() - 24 * 60 * 60 * 1000)
  );
  const [selectedEndDate, setEndDate] = useState(new Date());

  const handleDateChange = (range: any) => {
    const [startDate, endDate] = range;
    setStartDate(startDate);
    setEndDate(endDate);
  };

  useEffect(() => {
    if (
      Object.keys(assetMakeModelList).length &&
      customerCode !== 'mlc' &&
      customerCode !== 'mlp' &&
      customerCode !== 'cma'
    ) {
      const selectorOptions = Object.keys(assetMakeModelList).map((key) => {
        return {
          display: capitalizeFirstLetter(safeReplace(key, '!', ' ')),
          id: key,
        };
      });
      selectorOptions.sort((a, b) => {
        const displayA = a.display.toUpperCase();
        const displayB = b.display.toUpperCase();
        if (displayA < displayB) {
          return -1;
        }
        if (displayA > displayB) {
          return 1;
        }
        return 0;
      });

      const selectOptions =
        customerCode === 'klg'
          ? selectorOptions.filter((option) =>
              klgMakeModelsToFilter.includes(option.display.toLowerCase())
            )
          : customerCode === 'gbs'
          ? selectorOptions.filter((option) =>
              gbsMakeModelsToFilter.includes(option.display.toLowerCase())
            )
          : selectorOptions;

      setSelectedAsset(selectOptions[0].id);
      setAssetOptions(selectOptions);
      setShowCharts(true);
    }
  }, [assetMakeModelList, customerCode]);

  const getAssetAvgFuelConsumption = async (
    makeModel: string,
    isHaulTruck: boolean
  ) => {
    const { make = '', model = '' } = assetMakeModelList[makeModel]?.[0] ?? {};
    const startDate = dayjs(selectedStartDate).format('YYYY-MM-DD');
    const endDate = dayjs(selectedEndDate).format('YYYY-MM-DD');
    if (!isHaulTruck) {
      const sDate = dayjs(selectedStartDate);
      const eDate = dayjs(selectedEndDate);

      let numberOfDays = eDate.diff(sDate, 'day');
      if (numberOfDays === 0) {
        numberOfDays = 1;
      }
      await nonHaulTruckHandler.getAnalytics([], startDate, endDate);
    }
    if (make !== '' && model !== '') {
      await fuelAnalyticsHandler.getFuelUsageAnalytics(
        [],
        isHaulTruck,
        startDate,
        endDate,
        'month',
        ''
      );
    }
  };

  useEffect(() => {
    if (
      selectedAsset !== '' &&
      selectedStartDate &&
      selectedEndDate &&
      customerCode !== 'mlc' &&
      customerCode !== 'mlp' &&
      customerCode !== 'cma'
    ) {
      const isHaulTruck =
        assetMakeModelList[selectedAsset][0]?.assetType === 'haul_truck';
      void getAssetAvgFuelConsumption(selectedAsset, isHaulTruck);
      // If is not haultruck, call new handler
    }
  }, [selectedAsset, selectedEndDate, customerCode]);

  const [exportDropdownTo, setExportDropdownTo] = useState('');
  const [exportToLabel, setExportToLabel] = useState('Export To');

  const handleExportDropDownChange = (event: SelectChangeEvent) => {
    setExportDropdownTo(event.target.value);
  };
  const [exportTo, setExportTo] = useState(false);
  const handleExportClick = (val: boolean) => {
    setExportTo(val);
  };

  useCustomDropdownEffect(
    exportDropdownTo,
    handleExportClick,
    setExportToLabel,
    setExportDropdownTo,
    [exportDropdownTo]
  );
  return customerCode === 'mlc' ||
    customerCode === 'mlp' ||
    customerCode === 'cma' ? (
    <Base enableDarkTheme={enableDarkTheme}>
      <Typography
        sx={{
          color: enableDarkTheme
            ? ThemePalette.typography.white
            : ThemePalette.typography.black,
        }}
      >
        {notAvailableText}
      </Typography>
    </Base>
  ) : (
    <Base enableDarkTheme={enableDarkTheme}>
      <FlexBox
        sx={{
          width: '100%',
          justifyContent: 'space-between',
        }}
      />

      <ToolbarContainer enableDarkTheme={enableDarkTheme}>
        <Typography
          sx={{
            paddingRight: '5px',
            color: enableDarkTheme
              ? ThemePalette.typography.white
              : ThemePalette.typography.black,
          }}
        >
          Displaying Data for
        </Typography>

        <Selector
          value={selectedAsset}
          onChange={(event) => {
            const selectedValue = event.target.value as string;
            fuelAnalyticsHandler.clearFuelUsageAnalytics();
            setSelectedAsset(selectedValue);
          }}
          selectorOptions={
            customerCode === 'klg'
              ? assetOptions.filter((option) =>
                  klgMakeModelsToFilter.includes(option.display.toLowerCase())
                )
              : customerCode === 'gbs'
              ? assetOptions.filter((option) =>
                  gbsMakeModelsToFilter.includes(option.display.toLowerCase())
                )
              : assetOptions
          }
          selectSx={{
            ml: '10px',
            flex: 1,
            fontFamily: 'open sans',
            fontSize: '14px',
          }}
          enableDarkTheme={enableDarkTheme}
        />
        <Typography
          sx={{
            marginLeft: '10px',
            marginRight: '10px',
            color: enableDarkTheme
              ? ThemePalette.typography.white
              : ThemePalette.typography.black,
          }}
        >
          For Date Range:
        </Typography>
        <DatePicker
          showIcon
          selected={selectedStartDate}
          onChange={handleDateChange}
          startDate={selectedStartDate}
          endDate={selectedEndDate}
          maxDate={new Date(new Date().getTime())}
          selectsRange
        />

        <ExportSelector
          view={''}
          exportToLabel={exportToLabel}
          exportTo={exportDropdownTo}
          onExportToChange={handleExportDropDownChange}
          itemArr={['excel', 'pdf']}
          enableDarkTheme={enableDarkTheme}
        />
      </ToolbarContainer>
      <Box
        sx={{
          backgroundColor: enableDarkTheme
            ? ThemePalette.dark.boxBackground
            : ThemePalette.light.boxBackground,
          minHeight: '700px',
          paddingLeft: '10px',
          paddingRight: '10px',
        }}
      >
        {showCharts ? (
          assetMakeModelList[selectedAsset][0]?.assetType === 'haul_truck' ? (
            <HaulTruckView
              makeModel={[selectedAsset]}
              exportTo={exportTo}
              handleExport={handleExportClick}
              selectedStartDate={selectedStartDate}
              selectedEndDate={selectedEndDate}
              selectedConsumptionRange={''}
              setSelectedConsumptionRange={() => {}}
              fuelPriceData={fuelPriceData}
              enableDarkTheme={enableDarkTheme}
              startDateFilter={selectedStartDate}
              endDateFilter={selectedEndDate}
              selectedDevice={undefined}
            />
          ) : (
            <OtherAssetsView
              makeModel={[selectedAsset]}
              exportTo={exportTo}
              handleExport={handleExportClick}
              selectedStartDate={selectedStartDate}
              selectedEndDate={selectedEndDate}
              selectedConsumptionRange={''}
              setSelectedConsumptionRange={() => {}}
              fuelPriceData={fuelPriceData}
              enableDarkTheme={enableDarkTheme}
              startDateFilter={selectedStartDate}
              endDateFilter={selectedEndDate}
              selectedDevice={undefined}
            />
          )
        ) : (
          <></>
        )}
      </Box>
    </Base>
  );
};
