import { useLocation } from 'react-router-dom';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { AssetDetailBar } from 'components/AssetDetailBar';
import { Base } from 'components/Containers/BaseContainer';
import { useEffect, useMemo, useState } from 'react';
import { useAppSelector, useAppDispatch } from 'store/hook';
import { showNotification } from 'store/notification.slice';
import { type Asset } from 'types/asset.types';
import {
  useAssetFromPath,
  useNavigateOnAssetChange,
} from 'hooks/navigationPath';
import { type Device } from 'types/device.types';
import { getClosestChartOption } from './utils';
import { isDarkTheme } from 'utils/theme';
import { ThemePalette } from 'mui.theme';
import { selectAssetByMakeModel } from 'store/asset.slice';
/**
 * A page that displays asset-specific data and trends
 */
export const VehicleStatusView = () => {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const [selectedAsset, setSelectedAsset] = useState<Asset>({
    assetId: '',
    assetVin: '',
    assetType: 'haul_truck',
    make: '',
    bumperNumber: '',
    engineHoursOffset: -1,
    device: {} as const as Device,
    isActive: false,
    model: '',
    odometerOffset: -1,
    fuelSource: '',
    operators: [],
    externalId: '',
    assetNotes: [],
  });
  const makeModelReceived = location.state?.makemodel;

  const { assets } = useAppSelector((state) => state.assetReducer);
  const assetMakeModelList = Object.keys(
    useAppSelector(selectAssetByMakeModel)
  );
  const theme = useAppSelector((state) => state.authReducer).customer.theme;
  const enableDarkTheme = isDarkTheme(theme);
  const uniquePairs = new Set();

  assetMakeModelList.forEach((pair) => {
    const [make, model] = pair.split('!');
    uniquePairs.add(`${make}-${model}`);
  });

  const uniquePairsArray = Array.from(uniquePairs).sort((a, b) => {
    const [makeA, modelA] = (a as string).split('-');
    const [makeB, modelB] = (b as string).split('-');
    if (makeA !== makeB) {
      return makeA.localeCompare(makeB);
    }
    return modelA.localeCompare(modelB);
  });

  const [selectedMakeModel, setSelectedMakeModel] = useState(() => {
    if (
      makeModelReceived?.make &&
      makeModelReceived?.model &&
      makeModelReceived?.bumperNumber
    ) {
      return `${makeModelReceived.make.toLowerCase()}-${makeModelReceived.model.toLowerCase()}`;
    } else if (uniquePairsArray.length > 0) {
      return uniquePairsArray[0] as string;
    } else {
      return '';
    }
  });
  // Filter assets based on selected make and model
  const filteredAssets = useMemo(() => {
    const sortedAssets = [...assets];
    sortedAssets.sort((a, b) => a.bumperNumber.localeCompare(b.bumperNumber));
    if (!selectedMakeModel)
      return sortedAssets.filter((asset) => asset.device.deviceId);

    const [selectedMake, selectedModel] = selectedMakeModel.split('-');
    return sortedAssets.filter(
      (asset) =>
        asset.make.toLowerCase() === selectedMake.toLowerCase() &&
        asset.model.toLowerCase() === selectedModel.toLowerCase() &&
        asset.device.deviceId
    );
  }, [selectedMakeModel, assets]);

  // Map the list of filteredAssets to a hashmap
  const assetMap = useMemo(() => {
    const map: Record<Asset['assetVin'], Asset> = {};
    filteredAssets.forEach((asset) => (map[asset.assetVin] = asset));
    return map;
  }, [filteredAssets]);

  useEffect(() => {
    const activeAssets = filteredAssets.find((asset) => asset.isActive);
    if (activeAssets) {
      setSelectedAsset(activeAssets);
    } else if (filteredAssets.length > 0) {
      setSelectedAsset(filteredAssets[0]);
    }
  }, [filteredAssets]);

  useEffect(() => {
    const activeAssets = filteredAssets.find((asset) => asset.isActive);
    if (activeAssets) {
      setSelectedAsset(activeAssets);
    } else if (filteredAssets.length > 0) {
      // If no active asset found, select the first asset
      setSelectedAsset(filteredAssets[0]);
    }
  }, [filteredAssets]);

  useAssetFromPath(assetMap, (asset) => {
    setSelectedAsset(asset);
  });
  useNavigateOnAssetChange(selectedAsset, 'vehicle-status');
  // Get the encoded data from the query parameter
  const encodedData: any = new URLSearchParams(location.search).get('data');

  // Decode the data and parse it into an object
  const data = JSON.parse(decodeURIComponent(encodedData));
  let defaultTimeRange;

  if (data && selectedAsset) {
    if (data.ruleSummary) {
      dispatch(
        showNotification({
          type: 'longSuccess',
          message: data.ruleSummary,
        })
      );
    }
    defaultTimeRange = getClosestChartOption(data.alertStartTs);
  }

  return (
    <>
      <Base
        enableDarkTheme={enableDarkTheme}
        sx={{
          padding: '40px',
          display: 'flex',
          gap: '10px',
        }}
      >
        <FormControl fullWidth style={{ width: '20%' }}>
          <InputLabel
            id="asset-select-label"
            sx={{
              color: enableDarkTheme
                ? ThemePalette.typography.white
                : ThemePalette.typography.black,
              '&.Mui-focused': {
                color: enableDarkTheme
                  ? ThemePalette.typography.cornflowerBlue
                  : ThemePalette.typography.primary,
              },
            }}
          >
            Make-Model
          </InputLabel>
          <Select
            labelId="make-model-select-label"
            id="make-model-select"
            value={selectedMakeModel}
            label="Make Model"
            onChange={(event) => {
              const selectedValue = event.target.value;
              setSelectedMakeModel(selectedValue);
            }}
            style={{ width: '80%' }}
            sx={{
              width: '25%',
              color: enableDarkTheme
                ? ThemePalette.typography.white
                : ThemePalette.typography.black,
              ...(enableDarkTheme && {
                '& .MuiSvgIcon-root': {
                  color: 'purple', // <------------------ arrow-svg-color
                },
                '&.MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: ThemePalette.border, // <------------------ outline-color by default
                  },
                  '&:hover fieldset': {
                    borderColor: ThemePalette.typography.cornflowerBlue, // <------------------ outline-color on hover
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: ThemePalette.typography.cornflowerBlue, // <------------------ outline-color on focus
                  },
                },
              }),
            }}
            MenuProps={{
              PaperProps: {
                style: {
                  backgroundColor: enableDarkTheme
                    ? ThemePalette.dark.menuOptions
                    : ThemePalette.light.menuOptions, // Change background color here
                },
              },
            }}
          >
            {uniquePairsArray.map((pair: any) => {
              return (
                <MenuItem
                  key={pair}
                  value={pair}
                  sx={{
                    color: enableDarkTheme
                      ? ThemePalette.typography.lightGrey
                      : ThemePalette.typography.black,
                  }}
                >
                  {pair}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>

        <FormControl fullWidth style={{ width: '20%' }}>
          <InputLabel
            id="asset-select-label"
            sx={{
              color: enableDarkTheme
                ? ThemePalette.typography.white
                : ThemePalette.typography.black,
            }}
          >
            Bumper ID
          </InputLabel>
          <Select
            labelId="asset-select-label"
            id="asset-select"
            value={selectedAsset.assetVin}
            label="Bumper ID"
            onChange={(event) => {
              setSelectedAsset(assetMap[event.target.value]);
            }}
            style={{ width: '80%' }}
            sx={{
              width: '25%',
              color: enableDarkTheme
                ? ThemePalette.typography.white
                : ThemePalette.typography.black,
              ...(enableDarkTheme && {
                '& .MuiSvgIcon-root': {
                  color: 'purple', // <------------------ arrow-svg-color
                },
                '&.MuiOutlinedInput-root': {
                  '& fieldset': {
                    borderColor: ThemePalette.border, // <------------------ outline-color by default
                  },
                  '&:hover fieldset': {
                    borderColor: ThemePalette.typography.cornflowerBlue, // <------------------ outline-color on hover
                  },
                  '&.Mui-focused fieldset': {
                    borderColor: ThemePalette.typography.cornflowerBlue, // <------------------ outline-color on focus
                  },
                },
              }),
            }}
            MenuProps={{
              PaperProps: {
                style: {
                  backgroundColor: enableDarkTheme
                    ? ThemePalette.dark.menuOptions
                    : ThemePalette.light.menuOptions, // Change background color here
                },
              },
            }}
          >
            {filteredAssets
              .filter((asset) => asset?.device?.deviceId)
              .map((asset) => {
                return (
                  <MenuItem
                    key={asset.assetVin}
                    value={asset.assetVin}
                    sx={{
                      color: enableDarkTheme
                        ? ThemePalette.typography.lightGrey
                        : ThemePalette.typography.black,
                    }}
                  >
                    {asset.bumperNumber}
                  </MenuItem>
                );
              })}
          </Select>
        </FormControl>
      </Base>

      {/* Asset detail bar */}
      {selectedAsset.assetId && (
        <AssetDetailBar
          asset={selectedAsset}
          defaultTags={data?.tagNames}
          defaultTimeRange={defaultTimeRange}
          enableDarkTheme={enableDarkTheme}
        />
      )}
    </>
  );
};
