import { Grid, Typography } from '@mui/material';
import { treeMapContainerStyle } from 'views/FleetOverview/components/styles';
import { Treemap, Tooltip } from 'recharts';
import { Base } from 'components/Containers/BaseContainer';
import { ThemePalette } from 'mui.theme';

interface TreeNode {
  name: string;
  children?: TreeNode[]; // Children can be an array of TreeNode
  size?: number;
}

export const TreemapSection = ({
  dataTreeMap,
  enableDarkTheme,
  handleFilterUpdate,
  isProductivity,
}: {
  dataTreeMap: TreeNode[];
  enableDarkTheme: boolean;
  handleFilterUpdate: any;
  isProductivity: boolean;
}) => {
  return (
    <Grid item lg={10} md={12} sm={12} xs={12}>
      <Base
        sx={{
          ...treeMapContainerStyle,
          width: window.innerWidth * 0.65,
          overflow: 'hidden',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: enableDarkTheme
            ? ThemePalette.dark.modalBackground
            : '#FFFFFF',
        }}
      >
        <Typography
          sx={{
            paddingTop: '10px',
            paddingBottom: '10px',
            fontSize: '19px',
            fontWeight: '600',
            color: enableDarkTheme
              ? ThemePalette.typography.white
              : ThemePalette.typography.black,
          }}
        >
          {isProductivity ? 'Production' : 'Idling'}
        </Typography>
        <Treemap
          width={window.innerWidth * 0.65}
          fill="#4A7BFA"
          stroke="white"
          aspectRatio={2 / 1}
          height={(window.outerHeight * 29) / 100}
          data={dataTreeMap}
          dataKey="size"
          onClick={(e) => {
            handleFilterUpdate(e);
          }}
        >
          <Tooltip
            content={({ payload }) => {
              if (payload && payload.length > 0) {
                const { name, value } = payload[0].payload;
                return (
                  <div
                    style={{
                      backgroundColor: '#fee',
                      padding: '6px',
                      border: '1px solid #ccc',
                    }}
                  >
                    <p style={{ fontSize: 'smaller' }}>
                      Asset name: <b>{name}</b>
                    </p>
                    <p style={{ fontSize: 'smaller' }}>
                      {isProductivity ? 'Production Time' : 'Idling time'}:{' '}
                      <b>{value}</b> hrs
                    </p>
                  </div>
                );
              }
              return null;
            }}
          />
        </Treemap>
      </Base>
      {isProductivity ? (
        <></>
      ) : (
        <span
          style={{
            paddingRight: '10px',
            position: 'relative',
            fontSize: 'smaller',
            color: enableDarkTheme
              ? ThemePalette.typography.white
              : ThemePalette.typography.black,
          }}
        >
          ** Idle time starts at 6 consecutive minutes of idle behavior - once
          the 6 minute threshold is reached, all minutes are counted
        </span>
      )}
    </Grid>
  );
};
