import React, { useEffect, useState } from 'react';
import {
  Grid,
  Card,
  CardContent,
  Box,
  Typography,
  Divider,
  IconButton,
  Paper,
  CardActions,
  Tooltip,
} from '@mui/material';
import { SurvivalAnalysisDetailsChart } from 'views/SurvivalAnalysis/Helper/Modal/SurvivalAnalysis.details.chart';
import { ThemePalette } from 'mui.theme';
import {
  type CumulativeOverview,
  type AssetsOverview,
  type EolDates,
} from 'types/survivalAnalysis.types';
import {
  addDaysToDate,
  formatAgeInDays,
  mergeEolDatesIntoComponents,
  riskDays,
} from '../Function/helper.function';
import SurvivalAnalysisEditModal from './SurvivalAnalysis.edit.modal';
import { Edit } from '@mui/icons-material';
import { GetIcons } from '../Icons/GetIcons.function';
import componentsImage from '../Icons/componenent.png';
import assetImage from '../Icons/vehicle.png';
interface SurvivalAnalysisDetailsCardProps {
  components: any[];
  assetsPerSubComponent: CumulativeOverview[];
  enableDarkTheme: boolean;
  displayMode: string;
  detailType: string;
  eolDates?: EolDates[];
  handleModalSuccess: () => void;
}

export const SurvivalAnalysisDetailsCard: React.FC<
  SurvivalAnalysisDetailsCardProps
> = ({
  components,
  assetsPerSubComponent,
  enableDarkTheme,
  displayMode,
  detailType,
  eolDates,
  handleModalSuccess,
}) => {
  const backgroundColor = enableDarkTheme
    ? ThemePalette.dark.modalBackground
    : ThemePalette.light.menuOptions;
  const textColor = enableDarkTheme
    ? ThemePalette.typography.white
    : ThemePalette.typography.black;

  const [isSurvivalAnalysisEditModal, setIsSurvivalAnalysisEditModal] =
    useState(false);
  const [selectedComponent, setSelectedComponent] = useState('');

  const [componentItems, setComponentItems] = useState<any[]>([]);

  useEffect(() => {
    if (eolDates) {
      const updatedComponents = mergeEolDatesIntoComponents(
        components,
        eolDates
      );
      setComponentItems(updatedComponents);
    }
  }, [components, eolDates]);

  return (
    <>
      <SurvivalAnalysisEditModal
        key={`${isSurvivalAnalysisEditModal.toString()}-edit-date`}
        open={isSurvivalAnalysisEditModal}
        handleAbort={() => {
          setIsSurvivalAnalysisEditModal(false);
        }}
        title="Edit High Risk Date"
        enableDarkTheme={enableDarkTheme}
        component={selectedComponent}
        handleModalSuccess={handleModalSuccess}
      />

      <Grid container spacing={1}>
        {componentItems.map((component, index) => (
          <Grid
            item
            xs={6}
            sm={6}
            md={displayMode === 'grid' ? 4 : 12}
            key={index}
          >
            <Card
              className="card"
              sx={{
                display: 'flex',
                flexDirection: 'column',

                backgroundColor: backgroundColor,
                color: textColor,
                padding: 0,
                gap: 0,
              }}
            >
              <CardContent
                sx={{
                  padding: 0,
                  gap: 0,
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'flex-start',
                    justifyContent: 'flex-start',
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      padding: 1,
                      minWidth: '150px',
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-start',
                        alignItems: 'center',
                        gap: 1,
                      }}
                    >
                      <Box>
                        <GetIcons
                          icon={
                            detailType === 'Assets'
                              ? componentsImage
                              : assetImage
                          }
                          enableDarkTheme={enableDarkTheme}
                        />
                      </Box>
                      <Box>
                        <Tooltip
                          title={`${component.asset_id} ${component.components_category}`}
                        >
                          <Box
                            sx={{
                              display: 'flex',
                              flexDirection: 'column',
                              paddingLeft: 1,
                            }}
                          >
                            <Typography variant="small">
                              {component.asset_id}
                            </Typography>
                            <Typography
                              variant="small"
                              sx={{
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                                maxWidth: '100px',
                              }}
                            >
                              {component.components_category}
                            </Typography>
                          </Box>
                        </Tooltip>
                      </Box>
                    </Box>

                    <Typography variant="small">
                      {component.sub_component}
                    </Typography>
                    <Typography variant="small" color="grey">
                      Installation Date:
                    </Typography>
                    <Typography variant="small">
                      {component.date_of_orders}
                    </Typography>
                    <Typography variant="small" color="grey">
                      Age:
                    </Typography>
                    <Typography variant="small">
                      {formatAgeInDays(component.work_order_age_in_days)}
                    </Typography>
                    <Typography variant="small">
                      <var>
                        <span style={{ color: 'grey' }}>Low Risk: </span>
                      </var>
                      {addDaysToDate(
                        component.date_of_orders,
                        riskDays.low.days
                      )}
                    </Typography>
                    <Typography variant="small">
                      <var>
                        <span style={{ color: 'grey' }}>Med Risk: </span>
                      </var>
                      {addDaysToDate(
                        component.date_of_orders,
                        riskDays.med.days
                      )}
                    </Typography>
                    <Typography variant="small">
                      <var>
                        <span style={{ color: 'grey' }}>High Risk: </span>
                      </var>
                      {component.eolDate}
                    </Typography>
                  </Box>
                  <Paper
                    sx={{
                      display: 'flex',
                      backgroundColor: enableDarkTheme
                        ? 'rgba(255, 255, 255, 0.1)'
                        : 'rgba(0, 0, 0, 0.05)',
                      paddingLeft: 2,
                      paddingRight: 0,
                      width: '100%',
                      // minWidth: '150px',
                    }}
                  >
                    <SurvivalAnalysisDetailsChart
                      enableDarkTheme={enableDarkTheme}
                      workOrderAgeCategory={component.work_order_age_category}
                      component={component}
                      assetsPerSubComponent={assetsPerSubComponent}
                    />
                    {detailType === 'Assets' &&
                      component.work_order_age_category === 'eol' && (
                        <IconButton
                          sx={{
                            color: enableDarkTheme
                              ? ThemePalette.typography.white
                              : ThemePalette.typography.black,
                            '&:hover': {
                              backgroundColor: 'transparent', // Remove hover background color
                            },
                            alignItems: 'flex-start',
                          }}
                          onClick={() => {
                            setIsSurvivalAnalysisEditModal(true);
                            setSelectedComponent(component);
                          }}
                        >
                          <Edit />
                        </IconButton>
                      )}
                  </Paper>
                </Box>
              </CardContent>
              <CardActions
                sx={{
                  padding: 0,
                  gap: 0,
                }}
              ></CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>
    </>
  );
};
