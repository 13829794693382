import GeoAPI from 'api/geo.api';
import { updateLoadingState } from 'store/app.slice';
import {
  type AssetTypeGeoTripData,
  type GeoTripAnalysis,
} from 'types/geo.types';

import { BaseHandler } from './base.handler';

export default class GeoHandler extends BaseHandler {
  private readonly api: GeoAPI;

  constructor() {
    super();

    this.api = new GeoAPI();
  }

  async getTrips(
    deviceId: string,
    tripStartTime: string
  ): Promise<GeoTripAnalysis> {
    try {
      const geoData = await this.api.getTrips(deviceId, tripStartTime);

      return geoData;
    } catch (_) {
      this.handleError('An error occurred while fetching geo trips data.');
    }

    return {
      data: [],
      analysis: {},
    };
  }

  async getAssetTypeTrips(
    tripStartTime: string
  ): Promise<AssetTypeGeoTripData | undefined> {
    try {
      const geoData = await this.api.getAssetTypeTrips(tripStartTime);

      return geoData.data;
    } catch (_) {
      this.handleError(
        'An error occurred while fetching geo trips data by asset type.'
      );
      this.dispatch(updateLoadingState(false));
      return undefined;
    }
  }
}
