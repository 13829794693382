import { useAuth0 } from '@auth0/auth0-react';
import { Button, Typography } from '@mui/material';
import LoginBg from 'assets/bg/login_bg.svg';
import { SVG } from 'components/Asset/SVG';
import { FlexBox } from 'components/Containers/FlexBox';
import CustomerHandler from 'handlers/customer.handler';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from 'store/hook';
import { CustomerSelector } from './components/CustomerSelector';

/**
 *
 * @returns  JSX.Element
 *
 * This component is used to display the customer selection page.
 * It is only visible to users with the director or internal-user role.
 * It is the first page that is displayed after login if no customer is selected
 * It is also displayed when the user clicks on the change link in the top bar
 */
export const CustomerSelectionView = (): JSX.Element => {
  const { isAuthenticated } = useAuth0();
  const { accessToken } = useAppSelector((state) => state.authReducer);
  const { customer } = useAppSelector((state) => state.persistedReducer);
  const navigate = useNavigate();

  const customerHandler = new CustomerHandler();

  useEffect(() => {
    if (!isAuthenticated || !accessToken) return;

    // in case of app refresh, fetch customer data
    void customerHandler.getAll();
  }, [isAuthenticated, accessToken]);

  let disableButton = false;

  if (!customer.id) {
    disableButton = true;
  }

  return (
    <div
      style={{
        height: '100vh',
        width: '100vw',
        backgroundImage: `url(${LoginBg})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
      }}
    >
      <FlexBox
        sx={{
          width: '480px',
          height: '325px',
          backgroundColor: 'white',
          alignItems: 'center',
          justifyContent: 'center',
          display: 'flex',
          flexDirection: 'column',
          padding: '35px',
          borderRadius: '5px',
        }}
      >
        <SVG name="symboticwareLogoFullDirectionColumn"></SVG>
        <Typography
          variant="h6" // font size 20px
          sx={{
            mb: '5px',
            mt: '20px',
          }}
        >
          Select Customer
        </Typography>
        <CustomerSelector />
        <Button
          variant="contained"
          disabled={disableButton}
          sx={{
            backgroundColor: 'button.primary',
            mt: '50px',
          }}
          onClick={() => {
            navigate('/fleet-summary');
          }}
        >
          GO TO 4-SIGHT.AI
        </Button>
      </FlexBox>
    </div>
  );
};
