import { type ICreateExternalUserPayload } from 'types/payloads/user.payload.types';
import BaseAPI from './base.api';
import { type ExternalUser } from 'types/user.types';
import { type Customer } from 'types/customer.types';

export class ExternalUserAPI extends BaseAPI {
  create = async (payload: ICreateExternalUserPayload) => {
    const response = await this.API.post('external-user', payload);
    return response.data;
  };

  async getAll() {
    const response = await this.API.get('external-user');

    return response.data;
  }

  async update(payload: Omit<ExternalUser, 'isActive'>) {
    const response = await this.API.patch(`external-user/${payload.userId}`, {
      ...payload,
      role: payload.customerAccessLevel,
    });

    return response.data;
  }

  async currentCustomer(): Promise<Customer> {
    const response = await this.API.get('external-user/currentCustomer');
    return response.data;
  }

  async toggleActiveExternalUser(userId: string, isActive: boolean) {
    const response = await this.API.patch('external-user/toggle-active', {
      userId,
      isActive,
    });

    return response.data;
  }
}
