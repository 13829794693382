import {
  Checkbox,
  FormControl,
  ListItemText,
  MenuItem,
  Select,
  type SxProps,
  InputLabel,
  OutlinedInput,
  Typography,
  TextField,
} from '@mui/material';
import { useState, type ReactNode, useEffect } from 'react';
import { SWTextField } from '../Input/SWTextField';
import { modalInputFieldStyle } from 'styles/global.css';
import {
  type TGroupedMultiSelectOptionsData,
  type TGroupedMultiSelectOptions,
  type TMultiSelectOptions,
} from 'types/utils.types';
import { groupedData } from 'utils/helpers/tags';
import { capitalizeFirstWord } from 'utils/helpers/string.manipulation';
import { ThemePalette } from 'mui.theme';

interface Props {
  selectorOptions: TGroupedMultiSelectOptions;
  handleSelectOne: (option: TGroupedMultiSelectOptions) => void;
  handleSelectAll: (
    option: TGroupedMultiSelectOptions,
    allSelected: boolean
  ) => void;
  value: TGroupedMultiSelectOptions;
  renderValue: (arg0: TMultiSelectOptions) => ReactNode;
  minWidth?: string;
  disableSelectAll?: boolean;
  labelText?: string;
  isSelectionEmpty?: boolean;
  messageIfSelectionEmpty?: string;
  formControlSx?: SxProps;
  isDisabled?: boolean;
  height?: string;
  dropdownVerticalPosition?: 'top' | 'bottom' | 'center';
  dropdownHorizontalPosition?: 'left' | 'right' | 'center';
  enableDarkTheme?: boolean;
}

/**
 *
 * @param {Props} props
 * @returns JSX.Element
 *
 * This component is a wrapper for the Select component from Material UI.
 * It is used to create a multi selector drop down.
 * The selectorOptions prop is an array of objects that contain an id and a display value.
 * The handleSelectOne prop is a function that is called when a single item is selected.
 * The handleSelectAll prop is a function that is called when the select all option is selected.
 * The value prop is an array of objects that contain an id and a display value.
 * The renderValue prop is a function that is called to render the value of the selector.
 *
 */

export const GroupedTagMultiSelector = ({
  selectorOptions,
  handleSelectOne,
  handleSelectAll,
  value,
  renderValue,
  minWidth = '200px',
  disableSelectAll = false,
  labelText,
  isSelectionEmpty = false,
  messageIfSelectionEmpty = 'Empty',
  formControlSx,
  isDisabled = false,
  height = '12px',
  dropdownVerticalPosition = 'bottom',
  dropdownHorizontalPosition = 'center',
  enableDarkTheme = false,
}: Props) => {
  const groupData = groupedData({ selectorOptions });

  const [selectedOptions, setSelectedOptions] =
    useState<TGroupedMultiSelectOptions>(value);
  const selectAllIdentifier = 'reservedVal:selectAll';

  const [searchTerm, setSearchTerm] = useState('');

  const handleSearchChange = (event: any) => {
    setSearchTerm(event.target.value);
  };

  const handleGroupSelectAll = (groupOptions: any[]) => {
    const allSelected = groupOptions.every((tag) => {
      return value.some((val) => val.id === tag.id);
    });
    if (allSelected) {
      handleSelectAll([...groupOptions], true);
    } else {
      handleSelectAll([...value, ...groupOptions], false);
    }
  };

  useEffect(() => {
    setSelectedOptions(value);
  }, [value]);

  const handleOptionSelect = (option: any) => {
    setSelectedOptions((prevSelected: any) => {
      handleSelectOne([...prevSelected, option]);
      return [...prevSelected, option];
    });
  };

  const optionsSearch = (data: TGroupedMultiSelectOptionsData) => {
    function isGroupedOptions(
      data: TGroupedMultiSelectOptionsData
    ): data is Array<{
      groupName: string;
      options: TGroupedMultiSelectOptions;
    }> {
      return (
        Array.isArray(data) &&
        data.length > 0 &&
        'groupName' in data[0] &&
        'options' in data[0]
      );
    }
    if (isGroupedOptions(data)) {
      return data.filter((option) =>
        option.options.find((item) =>
          item.display.toLowerCase().includes(searchTerm.toLowerCase())
        )
      );
    } else {
      return data.filter((option) =>
        option.display.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }
  };

  return (
    <FormControl
      sx={{
        minWidth,
        height,
        ...formControlSx,
      }}
      size="small"
      disabled={isDisabled}
    >
      {labelText && (
        <InputLabel
          id="multiple-checkbox-label"
          sx={{
            paddingBottom: '5px',
            display: 'flex',
            // height,
            minHeight: '12px',
            alignItems: 'center',
            '&.Mui-focused': {
              color: enableDarkTheme
                ? ThemePalette.typography.cornflowerBlue
                : ThemePalette.typography.primary,
            },
          }}
        >
          {labelText}
        </InputLabel>
      )}
      {isSelectionEmpty && selectorOptions.length < 1 ? (
        <SWTextField
          name="emptyMessage"
          TextFieldProps={{
            placeholder: messageIfSelectionEmpty,
            name: 'emptyMessage',
            InputProps: {
              disabled: true,
              sx: modalInputFieldStyle,
            },
          }}
        />
      ) : (
        <Select
          labelId="multiple-checkbox-label"
          multiple
          value={value}
          input={labelText ? <OutlinedInput label={labelText} /> : undefined}
          onFocus={() => {
            setSearchTerm('');
          }}
          SelectDisplayProps={{
            style: {
              display: 'flex',
              height: '12px',
              minHeight: '12px',
              alignItems: 'center',
            },
          }}
          MenuProps={{
            PaperProps: {
              style: {
                minHeight: 0,
                backgroundColor: enableDarkTheme
                  ? ThemePalette.dark.menuOptions
                  : ThemePalette.light.menuOptions, // Change background color here
              },
            },
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'right',
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'right',
            },
          }}
          renderValue={renderValue}
          sx={{
            color: enableDarkTheme
              ? ThemePalette.typography.lightGrey
              : ThemePalette.typography.black,
            ...(enableDarkTheme && {
              '& .MuiSvgIcon-root': {
                color: 'purple', // <------------------ arrow-svg-color
              },
              '&.MuiOutlinedInput-root': {
                '& fieldset': {
                  borderColor: ThemePalette.border, // <------------------ outline-color by default
                },
                '&:hover fieldset': {
                  borderColor: ThemePalette.typography.cornflowerBlue, // <------------------ outline-color on hover
                },
                '&.Mui-focused fieldset': {
                  borderColor: ThemePalette.typography.cornflowerBlue, // <------------------ outline-color on focus
                },
              },
            }),
          }}
        >
          <TextField
            label="Search Tags"
            value={searchTerm}
            onChange={handleSearchChange}
            variant="outlined"
            margin="dense"
            style={{ marginLeft: '10px', width: '80%' }}
            onKeyDown={(e: React.KeyboardEvent<HTMLDivElement>) => {
              e.stopPropagation();
            }}
            sx={{
              color: enableDarkTheme
                ? ThemePalette.typography.white
                : ThemePalette.typography.black,
              width: '120%',
              fontSize: '18px',
              '& .MuiInputBase-root': {
                height: '40px',
              },
              input: {
                color: enableDarkTheme
                  ? ThemePalette.typography.white
                  : ThemePalette.typography.black,
              },
              ...(enableDarkTheme && {
                border: `1px solid ${String(ThemePalette.border)}`,
              }),
            }}
            InputLabelProps={{
              style: { color: ThemePalette.typography.wheat }, // Change placeholder color here
            }}
            InputProps={{
              style: { color: ThemePalette.typography.wheat }, // Change input text color here
            }}
          />
          {!disableSelectAll && (
            <MenuItem value={selectAllIdentifier}>
              <Checkbox
                checked={value.length >= selectorOptions.length}
                sx={{
                  color: enableDarkTheme
                    ? ThemePalette.typography.cornflowerBlue
                    : ThemePalette.typography.primary,
                  '&.Mui-checked': {
                    color: enableDarkTheme
                      ? ThemePalette.typography.cornflowerBlue
                      : ThemePalette.typography.primary,
                  },
                  '&.Mui-disabled': {
                    color: ThemePalette.typography.secondary,
                  },
                }}
              />
              <ListItemText primary={'Select All'} />
            </MenuItem>
          )}
          {optionsSearch(groupData).map((group: any) => (
            <div key={group.groupName}>
              <MenuItem
                sx={{
                  color: enableDarkTheme
                    ? ThemePalette.typography.lightGrey
                    : ThemePalette.typography.black,
                }}
              >
                <Checkbox
                  onClick={() => {
                    handleGroupSelectAll(group.options);
                  }}
                  checked={group.options.every(
                    (option: any) =>
                      value.find(({ id }) => id === option.id) !== undefined
                  )}
                  sx={{
                    color: enableDarkTheme
                      ? ThemePalette.typography.cornflowerBlue
                      : ThemePalette.typography.primary,
                    '&.Mui-checked': {
                      color: enableDarkTheme
                        ? ThemePalette.typography.cornflowerBlue
                        : ThemePalette.typography.primary,
                    },
                    '&.Mui-disabled': {
                      color: ThemePalette.typography.secondary,
                    },
                  }}
                />
                <ListItemText
                  primary={
                    <Typography variant="h6" style={{ fontWeight: 'bold' }}>
                      {capitalizeFirstWord(group.groupName)} Tags
                    </Typography>
                  }
                />
              </MenuItem>
              {optionsSearch(group.options).map((option: any) => (
                <MenuItem
                  key={option.id}
                  value={JSON.stringify(option)}
                  style={{ paddingLeft: '30px' }}
                  sx={{
                    color: enableDarkTheme
                      ? ThemePalette.typography.lightGrey
                      : ThemePalette.typography.black,
                  }}
                >
                  <Checkbox
                    onClick={() => {
                      handleOptionSelect(option);
                    }}
                    checked={
                      value.find(({ id }) => id === option.id) !== undefined
                    }
                    sx={{
                      color: enableDarkTheme
                        ? ThemePalette.typography.cornflowerBlue
                        : ThemePalette.typography.primary,
                      '&.Mui-checked': {
                        color: enableDarkTheme
                          ? ThemePalette.typography.cornflowerBlue
                          : ThemePalette.typography.primary,
                      },
                      '&.Mui-disabled': {
                        color: ThemePalette.typography.secondary,
                      },
                    }}
                  />
                  <ListItemText primary={option.display} />
                </MenuItem>
              ))}
            </div>
          ))}
          <MenuItem
            hidden
            value={value.toString()}
            sx={{
              display: 'none',
              color: enableDarkTheme
                ? ThemePalette.typography.lightGrey
                : ThemePalette.typography.black,
            }}
          ></MenuItem>
        </Select>
      )}
    </FormControl>
  );
};
