import { Box, Typography, Checkbox } from '@mui/material';
import { SVG } from 'components/Asset/SVG';
import { type Asset } from 'types/asset.types';
import { mapVehiclesAssets } from 'utils/helpers/mapAssetImage';
import { resolveAssetTypeDisplay } from 'utils/helpers/assets';
import { timeAgo } from '../utils';

import { ThemePalette } from 'mui.theme';

interface Props {
  asset: Asset;
  enableDarkTheme: boolean;
  showCheckedButton: boolean;
  handleCheckBoxSelected?: any;
  checkedList: string[];
}

const AssetCardDetails = ({
  asset,
  enableDarkTheme = false,
  checkedList,
  showCheckedButton,
  handleCheckBoxSelected,
}: Props) => {
  const { assetId, assetType, bumperNumber, make, model, device } = asset;
  const assetTypeResolved = resolveAssetTypeDisplay(assetType);
  const isChecked =
    checkedList.filter((item: string) => item === assetId)?.length > 0;
  return (
    <Box
      sx={{
        width: '40%',
        backgroundColor: enableDarkTheme
          ? ThemePalette.dark.modalBackground
          : ThemePalette.light.pageBackground,
        borderRight: '1px solid #26364C',
        borderTopLeftRadius: '5px',
        borderBottomLeftRadius: '5px',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column',
          position: 'relative',
        }}
      >
        {showCheckedButton ? (
          <Checkbox
            sx={{
              color: enableDarkTheme
                ? ThemePalette.typography.cornflowerBlue
                : ThemePalette.typography.primary,
              '&.Mui-checked': {
                color: enableDarkTheme
                  ? ThemePalette.typography.cornflowerBlue
                  : ThemePalette.typography.primary,
              },
              '&.Mui-disabled': {
                color: ThemePalette.typography.secondary,
              },
              position: 'absolute',

              top: '5px',
              left: '0px',
              transform: 'scale(0.7)',
            }}
            checked={isChecked}
            onChange={(e) => {
              if (!e.target.value) return;
              handleCheckBoxSelected(assetId);
            }}
          />
        ) : (
          <></>
        )}
        <SVG
          name={mapVehiclesAssets(assetType)}
          style={{
            fill: '#E2C171',
            height: '30px',
            width: showCheckedButton ? '40px' : '60px',
            paddingLeft: '10px',
            paddingTop: '10px',
          }}
        />
        <Typography
          sx={{
            paddingTop: '5px',
            paddingBottom: '10px',
            fontSize: '13px',
            textAlign: 'center',
            color: enableDarkTheme
              ? ThemePalette.typography.white
              : ThemePalette.typography.black,
          }}
        >
          {bumperNumber}
        </Typography>
      </Box>
      <Box
        sx={{
          color: enableDarkTheme
            ? ThemePalette.typography.white
            : ThemePalette.typography.black,
          paddingLeft: '3px',
        }}
      >
        <Typography
          sx={{
            fontSize: '11px',
            paddingBottom: '3px',
          }}
        >
          Type:{' '}
          <span
            style={{
              fontSize: '10px',
            }}
          >
            {assetTypeResolved}
          </span>
        </Typography>
        <Typography
          sx={{
            fontSize: '11px',
            paddingBottom: '3px',
          }}
        >
          OEM:{' '}
          <span
            style={{
              fontSize: '10px',
            }}
          >
            {make}
          </span>
        </Typography>
        <Typography
          sx={{
            fontSize: '11px',
            paddingBottom: '3px',
          }}
        >
          Model:{' '}
          <span
            style={{
              fontSize: '10px',
            }}
          >
            {model}
          </span>
        </Typography>
        <Typography
          sx={{
            fontSize: '11px',
            paddingBottom: '3px',
          }}
        >
          Last Connected:{' '}
          <span
            style={{
              fontSize: '10px',
            }}
          >
            {timeAgo(device.ingestDate)}
          </span>
        </Typography>
      </Box>
    </Box>
  );
};

export default AssetCardDetails;
