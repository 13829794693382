import { Link } from '@mui/material';
import { SVG } from 'components/Asset/SVG';
import { theme } from 'mui.theme';
import { type CSSProperties } from 'react';
import { safeTrim } from 'utils/helpers/string.manipulation';
import { StyledIcon } from './CellRenderer.styled';

const { svgIcons } = theme.palette;

interface Props {
  data: Record<string, string>;
  field: string;
  iconName: string;
  maxLength?: number;
  /*
    On click of Icon
  */
  onIconClick?: (param: any) => void;
  /*
    On click of display content 
  */
  onCellClick?: (param: any) => void;
  style?: CSSProperties;
}

export const WithIconRenderer = ({
  data,
  field,
  iconName,
  maxLength,
  onIconClick,
  onCellClick,
  style,
}: Props) => {
  const displayContent = safeTrim(data[field], maxLength);

  if (!displayContent) return null;

  return (
    <span style={style}>
      <StyledIcon
        onClick={() => {
          if (!onIconClick) return;

          onIconClick(data);
        }}
      >
        <SVG
          name={iconName}
          style={{
            ...svgIcons?.primary,
          }}
        />
      </StyledIcon>

      <Link
        sx={{
          textDecoration: 'none',
        }}
        color={'button.primary'}
        onClick={() => {
          if (!onCellClick) return;

          onCellClick(data);
        }}
      >
        {displayContent}
      </Link>
    </span>
  );
};
