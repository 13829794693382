import {
  disableThenButton,
  isOperatorActive,
} from 'views/Settings/Rules/helpers/common';
import { SWButton } from 'components/Buttons/SWButton';

interface Props {
  isValidForm: boolean;
  rows: string[];
  addRow: (index: number, operator: string) => void;
  keyId?: number;
}

export const RuleConditionButtonGroup = ({
  isValidForm,
  rows,
  addRow,
  keyId,
}: Props): JSX.Element => {
  const conditionButtons = ['AND', 'OR', 'NOT', 'THEN'];
  let ListComponent: React.ReactElement[] = [];

  const shouldDisableThenButton = disableThenButton(rows, keyId);

  ListComponent = conditionButtons.map((button: string, index: number) => {
    let isDisabled = !isValidForm;
    if (button === 'THEN' && !isDisabled) {
      isDisabled = shouldDisableThenButton;
    } else {
      isDisabled = !isValidForm || (rows.length > 0 && keyId === 1);
    }

    const isButtonActive = isOperatorActive(button, rows, keyId ?? 0);

    return (
      <SWButton
        key={index.toString()}
        disabled={isDisabled}
        sx={{
          marginRight: '20px',
          border: !isDisabled ? '1px solid #023059' : '',
          color: `${
            isButtonActive ? '#FFFFFF' : isDisabled ? '#A8A9A9' : '#023059'
          } !important`,
          backgroundColor: `${
            isButtonActive ? '#023059' : isDisabled ? '#E3E4E6' : '#FFFFFF'
          } !important`,
        }}
        onClick={() => {
          let indexValue = 0;
          if (keyId) {
            indexValue = keyId;
          }
          addRow(indexValue, button);
        }}
      >
        {button}
      </SWButton>
    );
  });

  return <>{ListComponent}</>;
};
