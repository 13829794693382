import AgGridTooltip, {
  type CombinedTooltipProps,
} from 'components/AgGrid/Tooltip';
import { checkDeviceStatus } from 'store/utils';

const AssetTooltip = (props: CombinedTooltipProps) => {
  const { data, bgColor, color } = props;
  const [isMissing, isActive, isXYFound] = checkDeviceStatus(data?.device);

  return (
    <>
      <AgGridTooltip bgColor={bgColor} color={color}>
        {isMissing ? (
          <p>No Device Detected </p>
        ) : !isXYFound ? (
          <>
            <p>
              Device Orientation must be specified for activation.
              <br />
              Click the link to update
            </p>
          </>
        ) : !isActive ? (
          <p>Device not Active</p>
        ) : null}
      </AgGridTooltip>
    </>
  );
};

export default AssetTooltip;
