import { type SxProps } from '@mui/material';
import { theme } from 'mui.theme';

const { bg } = theme.palette;

export const modalContentContainerStyle: SxProps = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxWidth: '650px',
  backgroundColor: bg.paper,
  border: '0px transparent',
  boxShadow: '0px 3px 6px #00000029',
  p: '40px',
  '&:focus': {
    outline: 'none',
  },
  overflowY: 'scroll',
  maxHeight: '100vh',
};

export const modalBackdropStyle: SxProps = {
  backgroundColor: bg.blue,
};
