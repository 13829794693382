export const getEnvVariable = (variable: string): string => {
  const value = process.env[variable];

  // Check against undefined because an empty string is a valid value
  // Check against undefined because a 0/false is a valid value
  if (value === undefined) {
    throw new Error(`Environment variable ${variable} is not defined`);
  }

  return value;
};
