/* eslint-disable no-prototype-builtins */
/* eslint-disable @typescript-eslint/no-floating-promises */
/* eslint-disable @typescript-eslint/await-thenable */
import React, { useEffect, useRef, useState } from 'react';
import { utils, writeFile } from 'xlsx';
import { selectExcelExportData } from 'store/exportData.slice';
import { useAppSelector } from 'store/hook';
import { processArrays, trimSheetName } from 'utils/exports/excel';
import { formatDate } from 'views/Settings/ExternalUsers/Squares/Helpers/prepareFile';

const ToExcel = () => {
  const [isTableLoaded, setIsTableLoaded] = useState(false);
  const tableRef = useRef<HTMLTableElement>(null);
  const {
    data,
    columnHeaders,
    fileDetails: { filename, sheet, title, subTitle },
  } = useAppSelector(selectExcelExportData);

  const fileNameWithDateTime = `${filename}-${formatDate(new Date())}`;

  const rowData: any = [...data];
  let rowHeaders: any = [...columnHeaders];

  rowHeaders = rowHeaders.filter((item: any) => item.field !== 'ruleId');

  const newData = processArrays(rowData, rowHeaders);
  useEffect(() => {
    const setupTable = async () => {
      // Simulate an asynchronous operation (e.g., fetching data) before setting the table
      await new Promise((resolve) => setTimeout(resolve, 1000));

      // Set the table loaded state when the table is fully set up
      setIsTableLoaded(true);
    };

    setupTable();
  }, []);

  useEffect(() => {
    const downloadExcel = async () => {
      // Wait for the table to be loaded before triggering the download
      if (tableRef.current && newData.length > 1 && isTableLoaded) {
        const ws = utils.table_to_sheet(document.getElementById('exportTable'));

        // Style the header row to make it bold and set the height
        ws['!rows'] = [
          {
            hidden: false,
            hpt: 30,
            hpx: 30,
          },
        ];

        const wb = utils.book_new();
        utils.book_append_sheet(wb, ws, trimSheetName(sheet));

        // Save the workbook as a file
        writeFile(wb, `${fileNameWithDateTime}.xlsx`, { cellStyles: true });
      }
    };

    downloadExcel();
  }, [tableRef, newData, isTableLoaded, rowHeaders]);

  return (
    <div id="exportTable">
      <table style={{ display: 'none' }} ref={tableRef}>
        <thead>
          <tr>
            <th colSpan={rowHeaders.length} style={{ fontWeight: 'bold' }}>
              <h1>
                {title}
                <span style={{ fontSize: '15px' }}>{subTitle}</span>
              </h1>
            </th>
          </tr>
          <tr>
            {rowHeaders?.map((header: any, index: any) => {
              return <th key={index}>{header.headerName}</th>;
            })}
          </tr>
        </thead>
        <tbody>
          {newData.map((item: any, index: any) => {
            return (
              <tr key={index}>
                {Object.values(item).map((value: any, index: any) => {
                  return <td key={index}>{value}</td>;
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default ToExcel;
