class A {
  async getAll(): Promise<any> {}
}

class B {
  async get(): Promise<any> {}
}

interface IHandlerMap {
  handlers: Partial<{
    internalUserHandler: A;
    customerHandler: A;
    externalUserHandler: A & {
      currentCustomer: () => Promise<any>;
    };
    assetHandler: A & {
      getAssetsWithExcessiveIdling: () => Promise<any>;
    };
    operatorHandler: A;
    hierarchyHandler: B;
    ruleHandler: A;
    notificationHandler: A & {
      getAllLatest: () => Promise<any>;
    };
    deviceHandler: A & {
      getDeviceLatestSensorDataAll: () => Promise<any>;
    };
    tagHandler: A;
    engineHoursHandler: A;
    dtcHandler: A;
    weatherHandler: B;
    fuelPriceHandler: B;
    geoHandler: B;
    fleetOverviewHandler: A;
  }>;
}

// execute a fetch for the getAll method of each handler
export async function internalFetchAll({ handlers }: IHandlerMap) {
  const Promises1 = [
    handlers.weatherHandler?.get(),
    handlers.internalUserHandler?.getAll(),
    handlers.customerHandler?.getAll(),
    handlers.externalUserHandler?.getAll(),
    handlers.assetHandler?.getAll(),
    handlers.assetHandler?.getAssetsWithExcessiveIdling(),
    handlers.operatorHandler?.getAll(),
    handlers.ruleHandler?.getAll(),
  ];
  const Promises2 = [
    handlers.deviceHandler?.getAll(),
    handlers.notificationHandler?.getAllLatest(),
    handlers.deviceHandler?.getDeviceLatestSensorDataAll(),
    handlers.tagHandler?.getAll(),

    handlers.hierarchyHandler?.get(),

    handlers.externalUserHandler?.currentCustomer(),
    handlers.engineHoursHandler?.getAll(),
    handlers.dtcHandler?.getAll(),
    handlers.fuelPriceHandler?.get(),
    handlers.fleetOverviewHandler?.getAll(),
  ];

  try {
    await Promise.allSettled(Promises1);
    await Promise.allSettled(Promises2);
  } catch (error) {
    // do something with error
    console.log(error);
  }
}

export async function externalFetchAll({ handlers }: IHandlerMap) {
  const Promises1 = [
    handlers.weatherHandler?.get(),
    handlers.externalUserHandler?.getAll(),
    handlers.assetHandler?.getAll(),
    handlers.assetHandler?.getAssetsWithExcessiveIdling(),
    handlers.operatorHandler?.getAll(),
    handlers.hierarchyHandler?.get(),
    handlers.ruleHandler?.getAll(),
  ];
  const Promises2 = [
    handlers.deviceHandler?.getAll(),
    handlers.notificationHandler?.getAllLatest(),
    handlers.deviceHandler?.getDeviceLatestSensorDataAll(),
    handlers.tagHandler?.getAll(),

    handlers.externalUserHandler?.currentCustomer(),
    handlers.engineHoursHandler?.getAll(),
    handlers.dtcHandler?.getAll(),
    handlers.fuelPriceHandler?.get(),
    handlers.fleetOverviewHandler?.getAll(),
  ];

  try {
    await Promise.allSettled(Promises1);
    await Promise.allSettled(Promises2);
  } catch (error) {
    // do something with error
    console.log(error);
  }
}
