import { type ICreateInternalUserPayload } from 'types/payloads/user.payload.types';
import BaseAPI from './base.api';
import { type InternalUser } from 'types/user.types';

export class InternalUserAPI extends BaseAPI {
  create = async (payload: ICreateInternalUserPayload) => {
    const response = await this.API.post('internal-user', payload);

    return response.data;
  };

  update = async (payload: Omit<InternalUser, 'isActive'>) => {
    const response = await this.API.patch('internal-user', payload);

    return response.data;
  };

  getAll = async () => {
    const response = await this.API.get('internal-user');

    return response.data;
  };

  deactivate = async (userId: string): Promise<InternalUser> => {
    const response = await this.API.patch('internal-user/deactivate', {
      userId,
    });

    return response.data;
  };
}
