import { IdSpan } from 'components/AgGrid/CellRenderer/ListView.styled';
import {
  AssetId,
  type AssetListRendererProp,
  type AlertListRendererProp,
  DeviceStatus,
  AssetType,
  OperationalStatus,
  AlertStatus,
  DeviceIngestDate,
} from 'views/Settings/ExternalUsers/AssetView/components/AssetListCellRenderer';
import { truncateTo2dp } from 'utils/helpers/general';

export const AssetIdForTable = ({
  handleIdClick,
  data,
}: AssetListRendererProp) => {
  return AssetId({
    handleIdClick,
    data,
  });
};

export const DeviceStatusForTable = ({
  data,
  handleDeviceClick,
}: AssetListRendererProp) => {
  return DeviceStatus({
    data,
    handleDeviceClick,
    // do nothing
    handleNoDeviceClick: () => {},
  });
};

export const DeviceIngestDateForTable = ({ data }: AssetListRendererProp) => {
  return DeviceIngestDate({
    data,
  });
};

export const AlertStatusForTable = ({
  data,
  handleAlertClick,
}: AlertListRendererProp) => {
  return (
    <IdSpan
      onClick={() => {
        handleAlertClick(data.notifications, data.bumperNumber);
      }}
    >
      {AlertStatus({ data })}
    </IdSpan>
  );
};

export const AssetTypeForTable = ({
  data,
  handleIdClick,
}: AssetListRendererProp) => {
  return (
    <IdSpan
      onClick={() => {
        handleIdClick(
          data.assetId,
          data.assetVin,
          data.make,
          data.model,
          data.bumperNumber
        );
      }}
    >
      {AssetType({ data })}
    </IdSpan>
  );
};

export const BumperNumberForTable = ({
  data,
  handleIdClick,
}: AssetListRendererProp) => {
  return (
    <IdSpan
      onClick={() => {
        handleIdClick(
          data.assetId,
          data.assetVin,
          data.make,
          data.model,
          data.bumperNumber
        );
      }}
    >
      {data?.bumperNumber}
    </IdSpan>
  );
};

export const OperationalStatusForTable = ({ data }: AssetListRendererProp) => {
  return OperationalStatus({ data });
};

export const AssetLiveTagDataColumnRenderer = ({
  data,
  colDef,
}: AssetListRendererProp) => {
  const tagName = colDef.field;
  const tagValue = data.liveTagData?.[tagName];
  const currentOdometerOffset = data.odometerOffset;

  let response: number | string = '--';

  if (tagValue) {
    if (tagName?.includes('gps')) {
      response = tagValue;
    } else if (tagName?.includes('edge_odometer')) {
      response = (tagValue as number) / 1000 + +currentOdometerOffset;
    } else {
      response = formatNumberOrString(tagValue) ?? '--';
    }
  }
  return response;
};

function formatNumberOrString(
  input: string | number | undefined
): string | number | undefined {
  if (input === undefined) {
    return undefined;
  }

  let parsedValue: number;

  if (typeof input === 'number') {
    // If the input is already a number, return it
    return input;
  } else if (typeof input === 'string') {
    parsedValue = parseFloat(input);

    if (isNaN(parsedValue)) {
      // If parsing as a number fails, return the original input
      return input;
    }
  } else {
    // If the input is neither a string nor a number, return the original input
    return input;
  }

  // Check if the parsedValue has more than 2 decimal places
  const decimalPlaces = (parsedValue.toString().split('.')[1] || []).length;

  // If the number has more than 2 decimal places, truncate to 2 decimal places
  const formattedValue =
    decimalPlaces > 2 ? truncateTo2dp(parsedValue) : parsedValue;

  return formattedValue;
}

export function formatDataToSort(data: any, tagValue: any, tagName: string) {
  const currentOdometerOffset: number = data.odometerOffset;

  let response: number | string = '--';

  if (tagValue) {
    if (tagName?.includes('gps')) {
      response = tagValue;
    } else if (tagName?.includes('edge_odometer')) {
      response = (tagValue as number) / 1000 + currentOdometerOffset;
    } else {
      response = formatNumberOrString(tagValue) ?? '--';
    }
  }
  return response;
}
