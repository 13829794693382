import { Base } from 'components/Containers/BaseContainer';
import { ToolbarContainer } from 'components/Containers/ToolbarContainer';
import SWTab from 'components/Layout/Tabs/SWTab';
import { TabList, TabPanel, Tabs } from 'react-tabs';
import { PmView } from './pmOverview/PmView';
import { CmView } from './CmOverview/CmView';
import { SVG } from 'components/Asset/SVG';
import { theme } from 'mui.theme';
import { useAppSelector } from 'store/hook';
import { isDarkTheme } from 'utils/theme';

const { svgIcons } = theme.palette;

/**
 * Renders a tabbed view for managing entities.
 *
 * Current tabs:
 * - Users
 * - Assets
 * - Operator
 */
export const MaintenanceEntityManagementView = () => {
  const themeState = useAppSelector((state) => state.authReducer).customer
    .theme;
  const enableDarkTheme = isDarkTheme(themeState);
  return (
    <Base enableDarkTheme={enableDarkTheme}>
      <Tabs>
        <TabList>
          <ToolbarContainer
            style={{
              paddingBottom: 0,
            }}
            enableDarkTheme={enableDarkTheme}
          >
            {/* BUG: The Permission tag causes MUI to detect the SWTab and create empty tab indexes */}
            <SWTab enableDarkTheme={enableDarkTheme}>PM Overview </SWTab>
            <SWTab enableDarkTheme={enableDarkTheme}>CM Overview </SWTab>

            {/* Email Icon */}
            <a href="mailto:sample@test.com" style={{ marginLeft: 'auto' }}>
              <SVG
                name="envelopeIcon"
                style={{
                  ...svgIcons?.primary,
                }}
              />
            </a>
          </ToolbarContainer>
        </TabList>

        {/* USER LIST VIEW CONTENT */}
        {/* BUG: The Permission tag causes MUI to detect the SWTab and create empty tab indexes */}

        <TabPanel
          style={{
            padding: '11px 21px 21px',
          }}
        >
          <PmView enableDarkTheme={enableDarkTheme} />
        </TabPanel>

        {/* ASSET LIST VIEW CONTENT */}
        <TabPanel
          style={{
            padding: '11px 21px 21px',
          }}
        >
          <CmView enableDarkTheme={enableDarkTheme} />
        </TabPanel>
      </Tabs>
    </Base>
  );
};
