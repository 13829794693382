import HierarchyAPI from 'api/hierarchy.api';
import { updateLoadingState } from 'store/app.slice';
import { BaseHandler } from './base.handler';
import { setAssignedHierarchyNode } from 'store/hierarchy.slice';

export default class HierarchyHandler extends BaseHandler {
  private readonly api: HierarchyAPI;
  constructor() {
    super();

    this.api = new HierarchyAPI();
  }

  async get(): Promise<void> {
    this.dispatch(updateLoadingState(true));

    try {
      const hierarchyNode = await this.api.get();

      this.dispatch(setAssignedHierarchyNode(hierarchyNode));
      this.dispatch(updateLoadingState(false));
    } catch (_) {
      // show snackbar error
      this.handleError(
        'An error occurred while retrieving hierarchy information.'
      );
    }
  }
}
