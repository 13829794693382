import SquaresWorkOrdersHandler from 'handlers/squaresHandlers/squaresWorOrders.handler';
import { useDispatch } from 'react-redux';
import { updateLoadingState } from 'store/app.slice';
import { type WorkOrdersSquare } from 'types/squares.type';

// Custom hook
export const useFetchAllWorkOrders = () => {
  const dispatch = useDispatch();
  const squaresHandler = new SquaresWorkOrdersHandler();

  const fetchData = async (): Promise<WorkOrdersSquare[]> => {
    dispatch(updateLoadingState(true));
    try {
      const squaresData = await squaresHandler.getAllWorkOrders();
      return squaresData;
    } catch (error) {
      console.error(error);
      return []; // Return an empty array in case of an error
    } finally {
      dispatch(updateLoadingState(false));
    }
  };

  return fetchData;
};
