import React, { useState, useEffect } from 'react';
import {
  Bar,
  ComposedChart,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
} from 'recharts';

const NoDataGraph = () => {
  // Initial static placeholder data
  const initialData = [
    { name: '', value: 30 },
    { name: '', value: 60 },
    { name: '', value: 45 },
    { name: '', value: 80 },
  ];

  return (
    <ResponsiveContainer height={600}>
      <ComposedChart
        data={initialData}
        margin={{ top: 5, right: 30, left: 20, bottom: 4 }}
      >
        <CartesianGrid stroke="#f5f5f5" />
        <XAxis
          dataKey="name"
          height={170}
          label={{ fontSize: 25, value: 'No Data was found', dy: 10 }}
        />
        <YAxis
          label={{
            value: 'No Data was found',
            angle: -90,
            position: 'insideLeft',
            fontSize: 25,
            textAnchor: 'middle',
            dy: 170,
          }}
        />
        <Bar dataKey="value" fill="#cccccc" barSize={20} />
      </ComposedChart>
    </ResponsiveContainer>
  );
};

export default NoDataGraph;
