import { Typography, Box, Divider } from '@mui/material';
import useTheme from '@mui/material/styles/useTheme';
import RuleHandler from 'handlers/rule.handler';
import { InteractiveModal } from 'components/Modals/InteractiveModal/BaseModal';
import type { ICreateRulePayload } from 'types/payloads/rule.payload.types';
import { formatConditions } from '../../helpers/common';
import { NOTIFICATION_LEVEL } from 'utils/enums';
import { RuleModalHeader } from './RuleModalHeader';
import { ThemePalette } from 'mui.theme';
import { useAppSelector } from 'store/hook';

interface Props {
  open: boolean;
  handleAbort: () => void;
  title: string;
  newRule: ICreateRulePayload;
  handleCancel: () => void;
  enableDarkTheme: boolean;
}

/**
 *
 * The createRuleGeneralInfo modal is a modal
 * that is used to confirm rule creation
 */
export const CreateRuleConfirmationModal = ({
  open,
  handleAbort,
  handleCancel,
  title,
  newRule,
  enableDarkTheme,
}: Props) => {
  const unitSystem = useAppSelector((state) => state.persistedReducer).customer
    .unitSystem;
  const ruleHandler = new RuleHandler();

  const {
    typography,
    palette: { bg },
  } = useTheme();

  let formattedConditions;
  let notificationLevelString;

  if (newRule.conditions) {
    formattedConditions = formatConditions(newRule.conditions, unitSystem);
    notificationLevelString = NOTIFICATION_LEVEL.find(
      (item) => item.id === newRule.notificationLevel
    )?.display;
  }

  return (
    <InteractiveModal
      initialState={open}
      title={title}
      handleAbort={handleCancel}
      SuccessButtonProps={{
        onClick: async () => {
          await ruleHandler.createRule(newRule);
          handleAbort();
        },
        disabled: false,
        label: 'Create Rule',
      }}
      ModalContentProps={{
        gridItemProps: {
          lg: 12,
        },
      }}
      cancelButtonText={'Back'}
      enableDarkTheme={enableDarkTheme}
    >
      <Typography
        key="general-info-text"
        variant="medium"
        sx={{
          fontFamily: typography.fontFamily,
        }}
      >
        <RuleModalHeader disabledPages={[]} enableDarkTheme={enableDarkTheme} />
      </Typography>

      <Box
        sx={{
          backgroundColor: enableDarkTheme
            ? ThemePalette.dark.boxBackground
            : bg.grey,
        }}
      >
        <Typography
          variant="bodyBold"
          key="rule-name-text"
          sx={{
            color: enableDarkTheme
              ? ThemePalette.typography.white
              : ThemePalette.typography.black,
          }}
        >
          {newRule.ruleName}
        </Typography>
        <Typography
          key="rule-applies-to-text"
          variant="medium"
          sx={{
            color: enableDarkTheme
              ? ThemePalette.typography.white
              : ThemePalette.typography.black,
          }}
        >
          , applies to&nbsp;
        </Typography>
        <Typography
          variant="bodyBold"
          key="rule-applies-to-variable"
          sx={{
            color: enableDarkTheme
              ? ThemePalette.typography.white
              : ThemePalette.typography.black,
          }}
        >
          {newRule.assets.length} Assets
        </Typography>
        <Divider
          sx={{
            marginTop: '10px',
            marginBottom: '10px',
            color: '#77859F',
            border: '1px solid #77859F',
          }}
        />
        <Typography
          key="rule-if-text"
          variant="medium"
          sx={{
            color: enableDarkTheme
              ? ThemePalette.typography.white
              : ThemePalette.typography.black,
          }}
        >
          {formattedConditions}, {notificationLevelString}
        </Typography>
      </Box>
    </InteractiveModal>
  );
};
