import { type SxProps } from '@mui/material';

export const chartContainerStyle: SxProps = {
  width: '547px',
  height: '289px',
  padding: '10px 15px',
};

export const treeMapContainerStyle: SxProps = {
  width: '95%',
  height: '340px',
  padding: '10px 15px',
};

export const paperContainerStyle: SxProps = {
  width: '90%',
  height: '300px',
  padding: '10px 10px',
};
export const pieContainerStyle: SxProps = {
  width: '300px',
  height: '350px',
  padding: '10px 15px',
};

export const areaContainerStyle: SxProps = {
  width: '95%',
  height: '310px',
  padding: '10px 15px',
};
