import { SVG } from 'components/Asset/SVG';
import UserHandler from 'handlers/internal.user.handler';
import { theme } from 'mui.theme';
import { type User } from 'types/user.types';

interface Props {
  data: User;
  isDisabled: boolean;
}

const { svgIcons } = theme.palette;

/**
 * A reset password button. This is used when an admin wants to reset the
 * password of a user.
 *
 * The API called by the button handles authorization and will throw an
 * error if the invoking user has insufficient privileges.
 */
export const ResetPassword = ({
  data: { isActive, userId },
  isDisabled,
}: Props) => {
  const userHandler = new UserHandler();

  if (!isActive) return null;
  return (
    <span
      onClick={async () => {
        if (isDisabled) return;

        await userHandler.resetPassword(userId);
      }}
    >
      <SVG
        name="resetIcon"
        style={{
          ...svgIcons?.primary,
        }}
      />
    </span>
  );
};
