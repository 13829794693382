import { useAuth0 } from '@auth0/auth0-react';
import { useDispatch } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { updateLoadingState } from 'store/app.slice';

/**
 *
 * @returns  JSX.Element
 *
 * This component is used to redirect the user to the fleet overview page
 * if they are authenticated. Otherwise, it renders an empty div as the user
 * is redirected to the Auth0 login page.
 */
function LoginView(): JSX.Element {
  const { isAuthenticated } = useAuth0();
  const dispatch = useDispatch();

  if (isAuthenticated) {
    return <Navigate to={'/fleet-summary'} />;
  }

  return <div className="App"></div>;
}

export default LoginView;
