import {
  Box,
  Typography,
  FormControlLabel,
  Checkbox,
  Button,
} from '@mui/material';
import CreateIcon from '@mui/icons-material/Create';
import ChromeReaderModeIcon from '@mui/icons-material/ChromeReaderMode';
import { InteractiveModal } from 'components/Modals/InteractiveModal/BaseModal';
import { Selector } from 'components/Form/Selectors/Selector';
import { MultiSelector } from 'components/Form/Selectors/MultiSelector';
import AssetHandler from 'handlers/asset.handler';
import { useEffect, useState } from 'react';
import { modalInputFieldStyle } from 'styles/global.css';
import { createOrUpdateAssetRequestBodySchema } from 'utils/schemas/asset.schema';
import { validateYupSchema } from 'utils/validators/schema.validator';
import { SWTextField } from 'components/Form/Input/SWTextField';
import type { ICreateAssetPayload } from 'types/payloads/asset.payload.types';
import { ASSET_TYPES } from 'utils/enums';
import type {
  ASSET_TYPES_ENUM,
  Asset,
  assetNotesInterface,
} from 'types/asset.types';
import {
  handleSelectOperatorSingle,
  handleSelectOperatorAll,
  handleOperationalStatus,
} from 'views/Settings/ExternalUsers/AssetView/helpers/common';
import { useAppSelector } from 'store/hook';
import { formatDateToString } from 'utils/helpers/dateTime';

interface Props {
  /**
   * Title of the Modal
   */
  title: string;
  /**
   * Boolean Value to determine if modal is displpayed or not
   */
  open: boolean;
  /**
   * Asset Data modal is to render
   */
  assetData: Asset;
  /**
   * Function to call to close modal
   */
  handleAbort: () => void;
}

export const UpdateAssetModal = ({
  title,
  handleAbort,
  open,
  assetData,
}: Props) => {
  const assetHandler = new AssetHandler();
  const [isValidForm, setFormValidity] = useState(false);
  const [asset, setAsset] = useState<ICreateAssetPayload>({
    assetVin: assetData.assetVin,
    assetType: assetData.assetType,
    make: assetData.make,
    model: assetData.model,
    bumperNumber: assetData.bumperNumber,
    engineHoursOffset: assetData.engineHoursOffset,
    odometerOffset: assetData.odometerOffset,
    fuelSource: assetData.fuelSource,
    operators: assetData.operators || [],
    isActive: assetData.isActive,
    externalId: assetData.externalId,
    assetNotes: [],
  });

  const [showNotes, setShowNotes] = useState(true);

  const { operators } = useAppSelector((state) => state.operatorReducer);
  const transformedOperators: Array<{ id: string; display: string }> = operators
    .filter((operator) => operator.isActive) // Filter only active operators
    .map((operator) => {
      return {
        id: operator.operatorId,
        display: operator.operatorName,
      };
    });

  // Validate form as user fills out fields
  useEffect(() => {
    const validateForm = async () =>
      await validateYupSchema(asset, createOrUpdateAssetRequestBodySchema);

    void validateForm().then((isValid) => {
      setFormValidity(isValid);
    });
  }, [asset]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    setAsset({
      ...asset,
      [name]: value,
    });
  };

  const handleSubmit = async () => {
    await assetHandler.updateAsset(assetData.assetId, asset);

    handleAbort();
  };
  /**
   * This is the form that will be rendered in the modal
   *
   * Asset VIN/ID
   */
  return (
    <InteractiveModal
      initialState={open}
      SuccessButtonProps={{
        disabled: !isValidForm,
        onClick: handleSubmit,
        label: 'SAVE EDITS',
      }}
      handleAbort={handleAbort}
      title={title}
      confirmCancellation
    >
      {/* Asset VIN/ID TextField */}
      <SWTextField
        key="assetVin"
        name="assetVin"
        label="Asset VIN/ID*"
        placeholder="Enter VIN/ID Number"
        TextFieldProps={{
          name: 'assetVin',
          value: asset.assetVin,
          onChange: handleChange,
          InputProps: {
            sx: modalInputFieldStyle,
          },
        }}
      />

      {/* Asset Type selector drop down single */}
      <Box key="assetType">
        <Typography variant="bodyBold" display="block" mb={'4px'}>
          Asset Type*
        </Typography>
        <Selector
          value={asset.assetType}
          onChange={(event) => {
            const selectedValue = event.target.value as ASSET_TYPES_ENUM;
            setAsset({
              ...asset,
              assetType: selectedValue,
            });
          }}
          selectorOptions={ASSET_TYPES}
        />
      </Box>

      <SWTextField
        key="make"
        name="make"
        label="Make*"
        placeholder="Enter Asset Make"
        TextFieldProps={{
          name: 'make',
          value: asset.make,
          onChange: handleChange,
          InputProps: {
            sx: modalInputFieldStyle,
          },
          /*
           * We're disabling make/model updates b/c
           * they've now become key categories for Asset
           * grouping in tags and tag onboarding
           *
           * TODO, we might look into squashing these fields into one
           * to make development easier
           */
          disabled: true,
        }}
      />

      <SWTextField
        key="model"
        name="model"
        label="Model*"
        placeholder="Enter Asset Model"
        TextFieldProps={{
          name: 'model',
          value: asset.model,
          onChange: handleChange,
          InputProps: {
            sx: modalInputFieldStyle,
          },
          /*
           * We're disabling make/model updates b/c
           * they've now become key categories for Asset
           * grouping in tags and tag onboarding
           *
           * TODO, we might look into squashing these fields into one
           * to make development easier
           */
          disabled: true,
        }}
      />

      <SWTextField
        key="bumperNumber"
        name="bumperNumber"
        label="Bumper ID"
        placeholder="Enter Bumper ID"
        TextFieldProps={{
          name: 'bumperNumber',
          value: asset.bumperNumber,
          onChange: handleChange,
          InputProps: {
            sx: modalInputFieldStyle,
          },
        }}
      />

      <SWTextField
        key="odometerOffset"
        name="odometerOffset"
        label="Odometer Reading Offset (km)"
        placeholder="Enter Odometer Reading Offset"
        TextFieldProps={{
          name: 'odometerOffset',
          value: asset.odometerOffset,
          onChange: handleChange,
          InputProps: {
            sx: modalInputFieldStyle,
          },
        }}
      />

      <SWTextField
        key="engineHoursOffset"
        name="engineHoursOffset"
        label="Engine Hours Offset"
        placeholder="Enter Engine Hours Offset"
        TextFieldProps={{
          name: 'engineHoursOffset',
          value: asset.engineHoursOffset,
          onChange: handleChange,
          InputProps: {
            sx: modalInputFieldStyle,
          },
        }}
      />

      {/* External ID TextField */}
      <SWTextField
        key="externalId"
        name="externalId"
        label="External ID"
        placeholder="Enter Customer Identified ID"
        TextFieldProps={{
          name: 'externalId',
          value: asset.externalId,
          onChange: handleChange,
          InputProps: {
            sx: modalInputFieldStyle,
          },
        }}
      />

      {/* Fuel Source TextField */}
      <SWTextField
        key="fuelSource"
        name="fuelSource"
        label="Fuel Source"
        TextFieldProps={{
          name: 'fuelSource',
          value: asset.fuelSource,
          onChange: handleChange,
          InputProps: {
            sx: modalInputFieldStyle,
          },
          disabled: true,
        }}
      />

      {/* Operators multi selector */}
      <Box key="operators">
        <Typography variant="bodyBold" display="block" mb={'4px'}>
          Operator(s)
        </Typography>
        <MultiSelector
          isSelectionEmpty={true}
          messageIfSelectionEmpty="No operators available"
          value={asset.operators}
          renderValue={(selected) => {
            return `${selected?.length || 0} Operators(s) Selected`;
          }}
          handleSelectOne={(event) => {
            handleSelectOperatorSingle(event, asset, setAsset);
          }}
          handleSelectAll={() => {
            handleSelectOperatorAll(transformedOperators, asset, setAsset);
          }}
          /*
        This stubbed response be replaced with real customer accounts
        */
          selectorOptions={transformedOperators}
        />
      </Box>

      {/* Operational Status Check Box */}
      <Box key="operationalStatus">
        <FormControlLabel
          control={<Checkbox />}
          checked={!asset.isActive}
          label="Asset is currently out of service"
          onChange={() => {
            handleOperationalStatus(asset, setAsset);
          }}
          sx={{ pt: 2 }}
        />
      </Box>

      {/* Fuel Source TextField */}
      <div style={{ width: '100%', marginTop: '-10px' }}>
        <div>
          {assetData.assetNotes?.length > 0 ? (
            <Button
              variant="outlined"
              startIcon={showNotes ? <CreateIcon /> : <ChromeReaderModeIcon />}
              aria-label="addNotes"
              onClick={() => {
                setShowNotes(!showNotes);
              }}
            >
              <span style={{ fontSize: '12px' }}>
                {showNotes ? 'Add Notes' : 'Show Notes'}
              </span>
            </Button>
          ) : (
            <Button
              variant="outlined"
              startIcon={showNotes ? <CreateIcon /> : <ChromeReaderModeIcon />}
              aria-label="addNotes"
              onClick={() => {
                setShowNotes(!showNotes);
              }}
            >
              <span style={{ fontSize: '12px' }}>
                {showNotes ? 'Add Notes' : 'Show Notes'}
              </span>
            </Button>
          )}
          <span
            style={{
              paddingLeft: '20px',
              fontWeight: 'bold',
              fontSize: '14px',
            }}
          >
            Historical data for Asset
          </span>
        </div>
        <div
          style={{
            width: '100%',
            marginTop: '10px',
            maxHeight: '200px',
            overflow: 'scroll',
          }}
          key={'assetNotes'}
        >
          {showNotes && assetData.assetNotes?.length > 0 ? (
            <div>
              {assetData.assetNotes?.map((item: assetNotesInterface) => {
                return (
                  <>
                    <div
                      key={assetData?.assetNotes?.indexOf(item)}
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        color: '#2a2a2a',
                        fontSize: '14px',
                        padding: '2px 0 5px 0',
                      }}
                    >
                      <div
                        style={{
                          width: '70%',
                        }}
                      >
                        {item.values}
                      </div>
                      <div
                        style={{
                          marginTop: '10px',
                          fontStyle: 'italic',
                        }}
                      >
                        --{' '}
                        {formatDateToString(
                          item.date_added,
                          'HH:mm:ss, MMM DD, YYYY'
                        )}
                      </div>
                    </div>
                    <div style={{ border: 'dotted 0.5px #e0d9d9' }} />
                  </>
                );
              })}
            </div>
          ) : showNotes ? null : (
            <SWTextField
              key="assetNotes"
              name="assetNotes"
              placeholder="Add new note here"
              TextFieldProps={{
                name: 'assetNotes',
                value: asset.assetNotes,
                onChange: handleChange,
                multiline: true,
                minRows: 2,
                fullWidth: true,
              }}
            />
          )}
        </div>
      </div>
    </InteractiveModal>
  );
};
