import dayjs from 'dayjs';
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';

interface Props {
  data: any[];
}
export const formatTimestamp = (timestamp: number): string => {
  const date = dayjs(timestamp * 1000);
  const formattedDate = date.format('YYYY-MMM-DD HH:mm');
  return formattedDate;
};

export const EngineHoursAreaChart = ({ data }: Props) => {
  return (
    <>
      {data.length > 0 ? (
        <>
          <ResponsiveContainer width="100%" height="60%">
            <AreaChart
              width={500}
              height={600}
              data={data}
              margin={{
                top: 10,
                right: 30,
                left: 0,
                bottom: 140,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                dataKey="hour"
                // tickFormatter={formatTimestamp}
                angle={-65}
                textAnchor="end"
                tick={{ fontSize: 12 }}
                allowDuplicatedCategory={false}
              />
              <YAxis
                label={{
                  value: 'Hours',
                  angle: -90,
                  position: 'inside',
                  fill: 'grey',
                  dy: 10,
                  dx: -10,
                }}
              />
              <Tooltip />
              <Area
                type="monotone"
                dataKey="engine_hour_total"
                stackId="1"
                stroke="#8884d8"
                fill="#8884d8"
              />
            </AreaChart>
          </ResponsiveContainer>
        </>
      ) : (
        <></>
      )}
    </>
  );
};
