import React, { useEffect, useState, useRef } from 'react';
import {
  Box,
  Typography,
  IconButton,
  Tooltip as MuiTooltip,
} from '@mui/material';
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  Tooltip as RechartsTooltip,
  ResponsiveContainer,
} from 'recharts';
import CancelIcon from '@mui/icons-material/Cancel';
import { ThemePalette } from 'mui.theme';
import {
  calculateMonthDifference,
  categoryMapping,
  filterByCategoryBySubCategory,
  generateGraphData,
} from '../Function/helper.function';
import {
  type AssetsOverview,
  type CumulativeOverview,
} from 'types/survivalAnalysis.types';

// Define the CustomTooltip component with appropriate type annotations
const CustomTooltip = ({
  active,
  payload,
  label,
  enableDarkTheme,
}: {
  active?: boolean;
  payload?: any;
  label?: string;
  enableDarkTheme: boolean;
}) => {
  if (active && payload && payload.length) {
    return (
      <Box
        sx={{
          backgroundColor: enableDarkTheme
            ? ThemePalette.dark.pageBackground
            : ThemePalette.light.pageBackground,
          padding: '10px',
        }}
      >
        <Typography
          sx={{
            color: enableDarkTheme
              ? ThemePalette.typography.grey
              : ThemePalette.typography.black,
          }}
          variant="body2"
        >
          {payload[0].value} Assets to check
        </Typography>
        <Typography
          sx={{
            color: enableDarkTheme
              ? ThemePalette.typography.grey
              : ThemePalette.typography.black,
          }}
          variant="body2"
        >
          in {label} Month(s)
        </Typography>
      </Box>
    );
  }

  return null;
};

export const SurvivalAnalysisDetailsChart = ({
  enableDarkTheme = false,
  workOrderAgeCategory = 'low',
  assetsPerSubComponent,
  component,
}: {
  enableDarkTheme: boolean;
  workOrderAgeCategory?: 'high' | 'med' | 'low' | 'eol';
  assetsPerSubComponent: CumulativeOverview[];
  component: AssetsOverview;
}) => {
  const [generateGraphDataItems, setGenerateGraphDataItems] = useState<any[]>(
    []
  );
  const [filterAssets, setFilterAssets] = useState<any[]>([]);
  const containerRef = useRef<HTMLDivElement>(null);
  const [arrowPosition, setArrowPosition] = useState<number | null>(null);

  useEffect(() => {
    const filteredAssets = filterByCategoryBySubCategory(
      assetsPerSubComponent,
      component.components_category,
      component.sub_component
    );
    setFilterAssets(filteredAssets);
    setGenerateGraphDataItems(generateGraphData(filteredAssets));
  }, [
    assetsPerSubComponent,
    component.components_category,
    component.sub_component,
  ]);

  useEffect(() => {
    if (containerRef.current && generateGraphDataItems.length > 0) {
      const chartWidth = containerRef.current.clientWidth || 0;
      const barWidth = chartWidth / generateGraphDataItems.length;
      const installationDate = component.date_of_orders;
      const currentDate = new Date();
      const installationMonth = calculateMonthDifference(
        installationDate,
        currentDate
      );
      const effectiveMonth = Math.min(
        installationMonth,
        generateGraphDataItems.length
      );
      setArrowPosition(effectiveMonth * barWidth - barWidth / 2);
    }
  }, [generateGraphDataItems, component.date_of_orders]);

  const installationDate = component.date_of_orders;
  const installationMonth = calculateMonthDifference(
    installationDate,
    new Date()
  );

  const closestMonthData = generateGraphDataItems.find(
    (item) => item.month === installationMonth
  );

  const getBarColor = (month: number) => {
    if (month <= 1.5) {
      return categoryMapping.low.color;
    } else if (month >= 2 && month <= 3) {
      return categoryMapping.med.color;
    } else {
      return categoryMapping.high.color;
    }
  };

  const arrowColor = getBarColor(installationMonth);

  return (
    <Box
      sx={{
        padding: 1,
        width: '100%',
        maxWidth: 1200, // Adjust as needed
        height: 220, // Adjust as needed
        position: 'relative',
      }}
    >
      <Box>
        <Typography
          variant="body2"
          sx={{
            ml: 1,
            color: enableDarkTheme
              ? ThemePalette.typography.white
              : ThemePalette.typography.black,
          }}
        >
          Current Status
        </Typography>
      </Box>

      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <IconButton
          sx={{ color: categoryMapping[workOrderAgeCategory].color ?? 'black' }}
        >
          <CancelIcon />
        </IconButton>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Typography
            variant="h6"
            sx={{ color: categoryMapping[workOrderAgeCategory].color }}
          >
            {categoryMapping[workOrderAgeCategory].text}
          </Typography>
        </Box>
      </Box>
      <Box ref={containerRef} sx={{ width: '100%', height: 200 }}>
        <ResponsiveContainer width="100%" height="75%">
          <BarChart data={generateGraphDataItems}>
            <XAxis
              dataKey="month"
              tickLine={false}
              label={{
                value: 'Months', // Text of the label
                position: 'bottom', // Position of the label
                offset: -3.3,
                fill: enableDarkTheme
                  ? ThemePalette.typography.white
                  : ThemePalette.typography.black,
                fontSize: 12, // Smaller font size
              }}
            />
            <YAxis hide />
            <RechartsTooltip
              content={<CustomTooltip enableDarkTheme={enableDarkTheme} />}
            />
            <Bar
              dataKey="cumulative_assets"
              fill="#ff7300"
              barSize={10}
              isAnimationActive={false}
            >
              {generateGraphDataItems.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={getBarColor(entry.month)} />
              ))}
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      </Box>
      {arrowPosition !== null && (
        <MuiTooltip
          title={
            <div>
              <Typography>Date: {new Date().toLocaleDateString()}</Typography>
              <Typography>Month: {installationMonth}</Typography>
              <Typography>
                Cumulative Assets:{' '}
                {closestMonthData ? closestMonthData.cumulative_assets : 0}
              </Typography>
            </div>
          }
          placement="top"
        >
          <Box
            sx={{
              position: 'absolute',
              top: `70px`, // Adjust the top value as needed
              left: `${arrowPosition}px`, // Ensure the arrow stays within the chart
              transform: 'translateX(-50%)',
              zIndex: 99,
            }}
          >
            <Box
              sx={{
                width: 0,
                height: 0,
                borderLeft: '10px solid transparent',
                borderRight: '10px solid transparent',
                borderTop: `20px solid ${arrowColor}`, // Arrow color based on month
                position: 'absolute',
              }}
            />
          </Box>
        </MuiTooltip>
      )}
    </Box>
  );
};
