// Persisted slice to be configured with redux-persist
// State saved in this slice is persisted in local storage

import { createSlice } from '@reduxjs/toolkit';

export interface PersistedState {
  customer: {
    id: string;
    name: string;
    code: string;
    unitSystem: string;
  };
}

const initialState: PersistedState = {
  customer: {
    id: '',
    name: '',
    code: '',
    unitSystem: 'metric',
  },
};

export const persistedSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    updateCustomer(
      state,
      { payload }: { payload: PersistedState['customer'] }
    ) {
      return {
        ...state,
        customer: payload,
      };
    },
    resetPersistedState() {
      return initialState;
    },
  },
});

export const { updateCustomer, resetPersistedState } = persistedSlice.actions;

export default persistedSlice.reducer;
