import { Box, Grid } from '@mui/material';
import {
  useFetchDataPmExpenditures,
  useFetchDataAssetsComponentPmCost,
  useFetchDataPmVsCmCost,
  useFetchComponentsCategory,
  useFetchAssets,
} from '../Helper/fetchData';
import { PmVsCm } from './modals/PmVsCm';
import React from 'react';
import { ComponentCost } from '../graphs/ComponentCost';
import { type Assets } from '../modals/assets.modals';
import { HighestTotalCosts } from '../graphs/HighestTotalCosts';
import Colors from '../../Helpers/Graphs/styles';
import { ThemePalette } from 'mui.theme';
import { Base } from 'components/Containers/BaseContainer';

export const PmView = ({ enableDarkTheme }: { enableDarkTheme: boolean }) => {
  const fetchDataPmExpenditures = useFetchDataPmExpenditures();
  const fetchDataAssetsComponentPmCost = useFetchDataAssetsComponentPmCost();
  const fetchDataPmVsCmCost = useFetchDataPmVsCmCost();
  const fetchComponentsCategory = useFetchComponentsCategory();
  const fetchAssets = useFetchAssets();

  const [assets, setAssets] = React.useState<Assets[]>([]);

  React.useEffect(() => {
    const getAssets = async () => {
      const assetsData = await fetchAssets();
      setAssets(assetsData);
    };

    void getAssets();
  }, []);

  return (
    <>
      <Base>
        <Box>
          <Grid
            container
            spacing={0}
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            sx={{
              backgroundColor: enableDarkTheme
                ? ThemePalette.dark.boxBackground
                : ThemePalette.light.boxBackground,
            }}
          >
            <Grid item xs={12}>
              <HighestTotalCosts
                dataType="PM"
                fetchSquaresData={fetchDataPmExpenditures}
                barKeys={[
                  {
                    name: 'grand_total_cost',
                    color: Colors.orange,
                    label: 'Grand total cost',
                  },
                  {
                    name: 'total_labour_cost',
                    color: Colors.blue,
                    label: 'Total labour cost',
                  },
                  {
                    name: 'total_part_cost',
                    color: Colors.purple,
                    label: 'Total part cost',
                  },
                ]}
                getAssets={assets}
                graphId="HighestTotalPmCosts"
                enableDarkTheme={enableDarkTheme}
              ></HighestTotalCosts>
            </Grid>
            <Grid item xs={6}>
              <ComponentCost
                fetchSquaresData={fetchDataAssetsComponentPmCost}
                getAssets={assets}
                graphId="ComponentCostPM"
                barKeys={[
                  {
                    name: 'total_preventative_cost',
                    color: Colors.blue,
                    label: 'Total Preventative Cost',
                  },
                ]}
                enableDarkTheme={enableDarkTheme}
              />
            </Grid>
            <Grid item xs={6}>
              <PmVsCm
                fetchSquaresData={fetchDataPmVsCmCost}
                getAssets={assets}
                graphId="PmVsCm"
                enableDarkTheme={enableDarkTheme}
              />
            </Grid>
          </Grid>
        </Box>
      </Base>
    </>
  );
};
