import styled from 'styled-components';
import { statusEnum } from './utils';

export const WrapperDiv = styled.div<{
  backgroundcolor?: any;
}>`
  margin: 0px;
  background: ${(props) => props.backgroundcolor};
  padding: 10px;
  font-family: 'Montserrat';
`;

export const ContainerDiv = styled.div`
  margin: 40px auto;
  width: 100%;
  background-color: #fff;
  padding: 20px;
  min-height: 100vh;
`;

export const ExportDiv = styled.div`
  position: fixed;
  bottom: 10px;
  left: 50%;
  width: 100%;
  z-index: 100;
`;

export const HeaderDiv = styled.header`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 10px;
`;

export const TwoColumnDiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;

export const LightText = styled.p`
  color: rgba(0, 0, 0, 0.5);
  font-family: 'Metrophobic', sans-serif;
  margin-top: -5px;
  margin-right: 20px;
`;

export const PerfomanceDescription = styled.p<{
  status: 'success' | 'warning' | 'error';
}>`
  font-size: 12px;
  font-family: 'Inter Variable', sans-serif;
  font-weight: bold;
  margin-top: -10px;
  color: ${(props) => statusEnum[props.status]};
`;
