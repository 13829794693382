import { type Operator } from 'types/operator.types';
import { type ICreateOperatorPayload } from 'types/payloads/operator.payload.types';
import BaseAPI from './base.api';

export default class OperatorAPI extends BaseAPI {
  create = async (payload: ICreateOperatorPayload) => {
    const response = await this.API.post('operator', payload);
    return response.data;
  };

  update = async (operatorId: string, payload: ICreateOperatorPayload) => {
    const response = await this.API.patch(`operator/${operatorId}`, payload);
    return response.data;
  };

  /**
   * Gets all operators the current user has access to
   * @returns A list of operators
   */
  getAll = async (): Promise<Operator[]> => {
    const response = await this.API.get('operator');
    return response.data;
  };

  /**
   * Activate Operator the specified operator
   * @returns A success message
   */
  activationOperator = async (operatorId: string, isActive: boolean) => {
    const response = await this.API.patch(
      `operator/${operatorId}/activationOperator`,
      { isActive }
    );
    return response.data;
  };
}
