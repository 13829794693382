import {
  useAuth0,
  type Auth0ContextInterface,
  type IdToken,
} from '@auth0/auth0-react';
import { updateAuthState } from 'store/auth.slice';
import authClientOptions from 'utils/config/auth0.config';
import { BaseHandler } from './base.handler';

interface TClaim extends IdToken {
  role: string;
}

export default class AuthHandler extends BaseHandler {
  auth0: Auth0ContextInterface;
  authClientOptions: typeof authClientOptions;

  constructor() {
    super();
    this.auth0 = useAuth0();
    this.authClientOptions = authClientOptions;
  }

  // login with redirect
  async login(): Promise<void> {
    try {
      // after logging, redirect user to the fleet-overview page
      // TODO: use SITE_MAP to redirect to the correct page
      await this.auth0.loginWithRedirect({
        appState: {
          returnTo: '/fleet-summary', // TODO, we may want to redirect to the last page the user was at
        },
      });
    } catch (err) {
      console.log(err);
    }
  }

  // get access token silently async
  async hydrateToken(): Promise<string | undefined> {
    try {
      const token = await this.auth0.getAccessTokenSilently();

      this.dispatch(
        updateAuthState({
          accessToken: token ?? '',
          isAuthenticated: this.auth0.isAuthenticated,
          email: this.auth0.user?.email ?? '',
          name: this.auth0.user?.name ?? '',
        })
      );

      return token;
    } catch (e) {}
  }

  async hydrateClaims(): Promise<TClaim | undefined> {
    try {
      const claims = (await this.auth0.getIdTokenClaims()) as TClaim;

      this.dispatch(
        updateAuthState({
          role: claims?.role,
        })
      );

      return claims;
    } catch (e) {
      return undefined;
    }
  }
}
