import { createSelector, createSlice } from '@reduxjs/toolkit';
import { type RootState } from 'store';
import { type ITag } from 'types/tag.type';

export interface ITagState {
  /*
    All tags belonging to the customer for this context
  */
  tags: Record<
    string,
    {
      tags: ITag[];
    }
  >;
  /*
    All tags that are isActive. 
    Dependent on the this.tags property.

    List is distinct by tag name
  */
  distinctActiveTags: Record<string, unknown>;
  tagChartData: any;
  tagPayload: Array<Record<string, unknown>>;
}

const initialState: ITagState = {
  tags: {},
  distinctActiveTags: {},
  tagChartData: [],
  tagPayload: [],
};

export const tagSlice = createSlice({
  name: 'tags',
  initialState,
  reducers: {
    getAll(state) {
      return {
        ...state,
      };
    },
    setTagList(state, { payload }: { payload: ITagState['tags'] }) {
      return {
        ...state,
        tags: payload,
      };
    },
    setTagChartData(state, { payload }) {
      return {
        ...state,
        tagChartData: payload,
      };
    },
    setTagPayload(state, { payload }) {
      return {
        ...state,
        tagPayload: payload,
      };
    },
  },
});

export const { getAll, setTagList, setTagChartData, setTagPayload } =
  tagSlice.actions;

export const selectTags = (state: RootState) => state.tagReducer.tags;

/*
  Select all tags that are active.
  No duplicates.
*/
export const selectUniqByActiveShowOnTableTags = createSelector(
  [selectTags],
  (tags): ITag[] => {
    const activeTags: Record<string, ITag> = {};

    Object.keys(tags).forEach((key) => {
      const tagList = tags[key].tags;

      tagList.forEach((tag) => {
        if (
          activeTags[tag.tagName] &&
          !activeTags[tag.tagName].showOnFleetOverviewTable &&
          tag.showOnFleetOverviewTable
        ) {
          activeTags[tag.tagName] = {
            ...tag,
          };
        } else if (!activeTags[tag.tagName] && tag.isActive) {
          activeTags[tag.tagName] = {
            ...tag,
          };
        }
      });
    });

    return Object.values(activeTags);
  }
);

export default tagSlice.reducer;
