import { Grid } from '@mui/material';
import type { HistoricalAnalysisCard } from 'types/fuelAnalytics';
import AnalysisItem from './item';

const AnalysisWrapper = ({
  enableDarkTheme,
  data,
  value = 0,
  isLoading,
  assetOptions,
  unitSystem,
}: {
  enableDarkTheme: boolean;
  data: HistoricalAnalysisCard;
  value: number;
  isLoading: boolean;
  assetOptions: any[];
  unitSystem: string;
}) => {
  return (
    <Grid container>
      {[
        {
          title: 'Total Fuel Consumed',
          value: assetOptions.length === 0 ? 0 : data.totalFuelConsumed,
          unit: unitSystem === 'metric' ? ' Litres' : ' Gallons',
        },
        {
          title: 'Fuel Consumed(idling)',
          value: assetOptions.length === 0 ? 0 : data.fuelConsumedIdling,
          unit: unitSystem === 'metric' ? ' Litres' : ' Gallons',
        },
        {
          title: 'Average CO2 Emissions',
          value: assetOptions.length === 0 ? 0 : data.co2Emissions,
          unit: unitSystem === 'metric' ? ' kg/hr' : 'lb/hr',
        },
        {
          title: 'Average Consumption Index',
          value: assetOptions.length === 0 ? 0 : data.consumptioinIdex,
          unit: unitSystem === 'metric' ? ' Litres/hr' : ' Gallons/hr',
        },
      ].map((item, index) => {
        return (
          <Grid item lg={3} sm={6} xs={12} key={index}>
            <AnalysisItem
              headerText={item.title}
              value={item.value}
              unit={item.unit}
              errorCount={2}
              warningCount={2}
              successCount={2}
              enableDarkTheme={enableDarkTheme}
              progressBarValue={value}
              isLoading={isLoading}
            />
          </Grid>
        );
      })}
    </Grid>
  );
};

export default AnalysisWrapper;
