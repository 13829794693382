import { createSlice } from '@reduxjs/toolkit';
import { type RootState } from 'store';
import { type Device } from 'types/device.types';
import { calculateTimeStampFromCurrentTime } from 'utils/helpers/dateTime';

export interface IDeviceState {
  unassociatedDevices: Device[];
  /**
   * Latest tag data for all associated devices
   * Fetched from dynamoDB, refreshes every 5 seconds
   */
  latestTagData: Record<string, Record<string, string | number>>;
}

const initialState: IDeviceState = {
  unassociatedDevices: [],
  latestTagData: {},
};

export const getDaysBehind = (data: any): number => {
  if (!data.latest_ts) return -1;

  const daysInMs = calculateTimeStampFromCurrentTime(data.latest_ts);
  const days = daysInMs / (24 * 60 * 60 * 1000);

  // Return the number of days as a floating-point number
  return parseFloat(days.toFixed(2));
};

const ingestDateFormat = (data: any): string => {
  if (!data.latest_ts) return 'NA';
  const getDate = new Date(Number(data.latest_ts));
  const year = getDate.getFullYear();
  const month = String(getDate.getMonth() + 1).padStart(2, '0');
  const day = String(getDate.getDate()).padStart(2, '0');
  const hour = String(getDate.getHours()).padStart(2, '0');
  const minute = String(getDate.getMinutes()).padStart(2, '0');
  return `${month}/${day}/${year}, ${hour}:${minute}`;
};

export const deviceSlice = createSlice({
  name: 'devices',
  initialState,
  reducers: {
    getAll(state) {
      return {
        ...state,
      };
    },
    setUnassociatedDeviceList(state, { payload }) {
      return {
        ...state,
        unassociatedDevices: payload,
      };
    },
    updateUnassociatedDeviceList(state, { payload }) {
      let isDeviceInList = false;
      // either remove or add the device to the list
      let updatedUnassociatedDevices = state.unassociatedDevices.map(
        (device) => {
          if (device.deviceId === payload.deviceId) {
            isDeviceInList = true;

            return payload;
          }
          return device;
        }
      );

      // device is unassociated but not found, it should be in the list
      if (!isDeviceInList && !payload.assetId) {
        updatedUnassociatedDevices.push(payload);
      }

      // device is associated and should be removed from the list
      if (isDeviceInList && payload.assetId) {
        updatedUnassociatedDevices = updatedUnassociatedDevices.filter(
          (device) => device.deviceId !== payload.deviceId
        );
      }

      return {
        ...state,
        unassociatedDevices: updatedUnassociatedDevices,
      };
    },
    setDevicesWithTagList(state, { payload }) {
      // Check if payload is valid
      if (payload) {
        // Loop through each device in the payload
        for (const deviceId in payload) {
          // eslint-disable-next-line no-prototype-builtins
          if (payload.hasOwnProperty(deviceId)) {
            payload[deviceId].daysBehind = getDaysBehind(payload[deviceId]);
            payload[deviceId].ingestDate = ingestDateFormat(payload[deviceId]);
          }
        }
      }
      return {
        ...state,
        latestTagData: payload,
      };
    },
    resetDevices(state) {
      state = initialState;
    },
  },
});

export const {
  getAll,
  setUnassociatedDeviceList,
  updateUnassociatedDeviceList,
  setDevicesWithTagList,
  resetDevices,
} = deviceSlice.actions;

export const selectLatestTagData = (state: RootState) =>
  state.deviceReducer.latestTagData;

export default deviceSlice.reducer;
