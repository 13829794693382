import { styled } from '@mui/material/styles';
import Switch from '@mui/material/Switch';

const StyledSwitchButton = styled(Switch)(({ theme }) => ({
  padding: 8,
  '& .MuiSwitch-track': {
    borderRadius: 22 / 2,
  },
  '& .MuiSwitch-thumb': {
    boxShadow: 'none',
    width: 16,
    height: 16,
    margin: 2,
    background: 'white',
  },
  '& .MuiSwitch-switchBase': {
    '&.Mui-checked': {
      '& + .MuiSwitch-track': {
        backgroundColor: '#023059',
        opacity: 1,
      },
    },
  },
}));

export default StyledSwitchButton;
