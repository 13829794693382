const Colors = {
  blue: '#63CAD8',
  green: '#b8d8be',
  yellow: '#F2D496',
  orange: '#F6AC69',
  red: '#d45355',
  purple: '#513B63',
  grey: '#989a9c',
  lightBlue: '#ADD8E6',
  dimGray: '#696969',
};

export default Colors;
