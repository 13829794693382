import BaseAPI from 'api/base.api';
import {
  type ICreateSurvivalAnalysistPayload,
  type ICreateSurvivalAnalysistCumulativeAssetsPayload,
  type ICreateSurvivalAnalysistEolPayload,
} from 'types/payloads/survivalAnalysist.payload.types';

export default class SurvivalAnalysisOverviewApi extends BaseAPI {
  /**
   * Gets all operators the current user has access to
   * @returns A list of operators
   */

  getAssetsRiskAnalysis = async (payload: ICreateSurvivalAnalysistPayload) => {
    const response = await this.API.post(
      'squares/getAssetsRiskAnalysis',
      payload
    );
    return response.data;
  };

  getCumulativeAssetsPerSubComponent = async (
    payload: ICreateSurvivalAnalysistCumulativeAssetsPayload
  ) => {
    const response = await this.API.post(
      'squares/getCommulativeAssetsPerSubComponent',
      payload
    );
    return response.data;
  };

  getEOLs = async (payload: ICreateSurvivalAnalysistEolPayload) => {
    const response = await this.API.post('squares/getEOLs', payload);
    return response.data;
  };

  updateEOL = async (payload: ICreateSurvivalAnalysistEolPayload) => {
    const response = await this.API.post('squares/updateEol', payload);
    return response.data;
  };
}
