import { createSlice } from '@reduxjs/toolkit';
import {
  type WorkOrdersSquareHistory,
  type WorkOrdersSquare,
} from 'types/squares.type';

export interface WorkOrdersSquaresState {
  squaresWorkOrders: WorkOrdersSquare[];
  squaresWorkOrdersHistory: WorkOrdersSquareHistory[];
}

const initialState: WorkOrdersSquaresState = {
  squaresWorkOrders: [],
  squaresWorkOrdersHistory: [],
};

export const squaresWorkOrdersSlice = createSlice({
  name: 'squaresWorkOrders',
  initialState,
  reducers: {
    appendSquaresWorkOrder(state, { payload }) {
      state.squaresWorkOrders.push(payload);
    },

    updateSquaresListWorkOrder(state, { payload }: { payload: any }) {
      const index = state.squaresWorkOrders.findIndex(
        (squaresWorkOrder) => squaresWorkOrder.asset_id === payload.squaresId
      );

      if (index === -1) {
        // do not update the state if the squares is not found
        return;
      }

      state.squaresWorkOrders[index] = payload;
    },

    setSquaresWorkOrder(state, { payload }) {
      state.squaresWorkOrders = payload;
    },

    removeSquaresWorkOrder(
      state,
      { payload }: { payload: { squaresId: string } }
    ) {
      const filtedSquares = state.squaresWorkOrders.filter(
        (squaresWorkOrder) => squaresWorkOrder.asset_id !== payload.squaresId
      );
      state.squaresWorkOrders = filtedSquares;
    },
    setSquaresWorkOrdersHistory(state, { payload }) {
      state.squaresWorkOrdersHistory = payload;
    },
  },
});

export const {
  appendSquaresWorkOrder,
  updateSquaresListWorkOrder,
  setSquaresWorkOrder,
  removeSquaresWorkOrder,
  setSquaresWorkOrdersHistory,
} = squaresWorkOrdersSlice.actions;

export default squaresWorkOrdersSlice.reducer;
