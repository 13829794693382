import { Button, type ButtonBaseProps, type SxProps } from '@mui/material';

interface Props {
  sx?: SxProps;
  children?: JSX.Element | JSX.Element[] | string;
  onClick?: ButtonBaseProps['onClick'];
  disabled?: boolean;
}

export const SWButton = ({
  sx,
  children,
  onClick,
  disabled = false,
  ...rest
}: Props) => (
  <Button
    variant="contained"
    disableRipple
    disabled={disabled}
    sx={{
      backgroundColor: 'button.primary',
      '&:hover': {
        backgroundColor: '#023059',
      },
      ...sx,
    }}
    onClick={onClick}
    {...rest}
  >
    {children}
  </Button>
);
