import { type ITag } from 'types/tag.type';

/**
 *
 * @param prev previous state
 * @param data ag grid data param
 * @param field string
 * @param value string value to set for the field
 * @returns
 */
export const setOrToggleField = (
  prev: Record<string, any>,
  data: Record<string, any>,
  field: string,
  value?: string
) => {
  // create deep copy of tag list
  const newTagList = JSON.parse(JSON.stringify(prev));
  // find tag in list, reminder, tag list is unique by tag name
  const index = newTagList.findIndex(
    (tag: ITag) => tag.tagName === data.tagName
  );

  // Set value if provided
  if (value !== undefined) {
    newTagList[index][field] = value || '';

    return newTagList;
  }

  // toggle boolean fields
  newTagList[index][field] = !newTagList[index][field];

  // if tag is deactivated, remove other fields
  if (field === 'isActive' && !newTagList[index][field]) {
    newTagList[index].unit = '';
    newTagList[index].showOnFleetOverviewCard = false;
    newTagList[index].showOnFleetOverviewMap = false;
    newTagList[index].showOnFleetOverviewTable = false;
  }

  return newTagList;
};

export const disableSaveButton = (tagsToUpdate: string[], tagList: ITag[]) => {
  // filter tag list to only include tags that are in the tagsToUpdate list
  const filteredTagList = tagList.filter((tag) =>
    tagsToUpdate.includes(tag.tagName)
  );

  // UOM + enabled must both be true to allow saving
  const invalidTag = filteredTagList.find((tag) => {
    // if tag is activated, it must have a UOM
    if (tag.isActive && !tag.unit) return true;
    if (tag.unit && !tag.isActive) return true;
    if (tag.showOnFleetOverviewCard && !tag.isActive) return true;
    if (tag.showOnFleetOverviewMap && !tag.isActive) return true;

    return false;
  });

  if (invalidTag) return true;

  return false;
};
