import { Box, Grid } from '@mui/material';
import {
  useFetchDataPmExpenditures,
  useFetchDataAssetsComponentPmCost,
  useFetchAssets,
} from '../Helper/fetchData';
import { ComponentCost } from '../graphs/ComponentCost';
import React from 'react';
import { HighestTotalCosts } from '../graphs/HighestTotalCosts';
import { type Assets } from '../modals/assets.modals';
import Colors from '../../Helpers/Graphs/styles';

export const CmView = ({ enableDarkTheme }: { enableDarkTheme: boolean }) => {
  const fetchDataPmExpenditures = useFetchDataPmExpenditures();
  const fetchDataAssetsComponentPmCost = useFetchDataAssetsComponentPmCost();
  const fetchAssets = useFetchAssets();

  const [assets, setAssets] = React.useState<Assets[]>([]);

  React.useEffect(() => {
    const getAssets = async () => {
      const assets = fetchAssets();
      const assetsData = await fetchAssets();
      setAssets(assetsData);
    };

    void getAssets();
  }, []);
  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <HighestTotalCosts
            dataType="CM"
            fetchSquaresData={fetchDataPmExpenditures}
            barKeys={[
              {
                name: 'grand_total_cost',
                color: Colors.orange,
                label: 'Grand total cost',
              },
              {
                name: 'total_labour_cost',
                color: Colors.blue,
                label: 'Total labour cost',
              },
              {
                name: 'total_part_cost',
                color: Colors.purple,
                label: 'Total part cost',
              },
            ]}
            graphId="HighestTotalCostsCM"
            getAssets={assets}
            enableDarkTheme={enableDarkTheme}
          ></HighestTotalCosts>
        </Grid>
        <Grid item xs={12}>
          <ComponentCost
            fetchSquaresData={fetchDataAssetsComponentPmCost}
            barKeys={[
              {
                name: 'total_preventative_cost',
                color: Colors.blue,
                label: 'Total Corrective Cost',
              },
            ]}
            getAssets={assets}
            graphId="ComponentCostCM"
            enableDarkTheme={enableDarkTheme}
          />
        </Grid>
      </Grid>
    </Box>
  );
};
