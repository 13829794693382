import { SWButton } from 'components/Buttons/SWButton';
import { FlexBox } from 'components/Containers/FlexBox';
import { Selector } from 'components/Form/Selectors/Selector';
import { MediumText } from 'components/Typography/MediumText';
import { ThemePalette } from 'mui.theme';

interface Props {
  onChange: (arg0: any) => void;
  selectorOptions: Array<{ id: string; display: string }>;
  value?: string;
  handleSaveUpdates: () => Promise<void>;
  disableSaveEditButton: boolean;
  enableDarkTheme?: boolean;
}

export const TabHeader = ({
  onChange,
  selectorOptions,
  value,
  handleSaveUpdates,
  disableSaveEditButton,
  enableDarkTheme = false,
}: Props): JSX.Element => {
  return (
    <FlexBox
      sx={{
        width: '100%',
        justifyContent: 'start',
        alignItems: 'center',
      }}
    >
      <MediumText
        text="Displaying Tags for"
        sx={{
          color: enableDarkTheme
            ? ThemePalette.typography.wheat
            : ThemePalette.typography.black,
        }}
      />
      <Selector
        formControlSx={{
          padding: '0px 5px',
        }}
        onChange={(event: any) => {
          const selectedValue = event.target.value?.toString();

          onChange(selectedValue);
        }}
        value={value ?? ''}
        selectorOptions={selectorOptions}
        enableDarkTheme={enableDarkTheme}
      />

      <SWButton
        disabled={disableSaveEditButton}
        onClick={async () => {
          void handleSaveUpdates();
        }}
        sx={{
          ml: 'auto',
        }}
      >
        Save edits
      </SWButton>
    </FlexBox>
  );
};
