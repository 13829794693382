export function getAssetsMakeModel(carArray: string[]) {
  return carArray.map((car) => {
    const [make, model] = car.split('!');
    return { make, model };
  });
}

export function getTripTime(
  unloadedTripStart: string,
  loadedTripStart: string,
  unloadedTripEnd: string,
  loadedTripEnd: string
) {
  let startTime, endTime;
  const unloadedTripStartDate = new Date(unloadedTripStart); // convert Dtae string to Date object for comparison
  const loadedTripStartDate = new Date(loadedTripStart);
  if (unloadedTripStartDate < loadedTripStartDate) {
    startTime = unloadedTripStart;
    endTime = loadedTripEnd;
  } else {
    startTime = loadedTripStart;
    endTime = unloadedTripEnd;
  }
  return { startTime, endTime };
}
