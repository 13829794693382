import { ContainerDiv, TwoColumnDiv, WrapperDiv } from './Productivity.styled';
import { useEffect, useRef, useState } from 'react';
import { formatTooltipValue } from './utils';
import { useAppSelector } from 'store/hook';
import { useLocation } from 'react-router-dom';
import { formatDateToString } from 'utils/helpers/dateTime';
import ReportsHandler from 'handlers/reports.handlers';
import { getGridDataPoints } from 'views/FleetOverview/components/AssetCardsV2/utils';
import {
  CartesianGrid,
  Cell,
  Label,
  Legend,
  Line,
  LineChart,
  Pie,
  PieChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { truncateTo2dp } from 'utils/helpers/general';
import { FadeLoader } from 'react-spinners';
import PageLengends from './Components/PageLegend';
import ReportTable from './Components/ReportTable';
import Header from './Components/Header';

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  index,
}: {
  cx: number;
  cy: number;
  midAngle: number;
  innerRadius: number;
  outerRadius: number;
  percent: number;
  index: number;
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5 - 12;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      x={x}
      y={y}
      fill="white"
      textAnchor={x > cx ? 'start' : 'end'}
      dominantBaseline="central"
    >
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

interface PropTypes {
  unitSystem: string;
}

const Production = ({ unitSystem }: PropTypes) => {
  const reportsHandler = new ReportsHandler();
  const gridRef = useRef<any>(null);
  const { isLoading } = useAppSelector((state) => state.appReducer);

  const location = useLocation();
  const { startDate, endDate, pageType } = location.state || {};
  const {
    production,
    loading: { productionLoading },
    score,
  } = useAppSelector((state) => state.exportDataReducer.reports);

  const [status, setStatus] = useState<
    | { success: 'Ideal Performance' }
    | { warning: 'Caution Advised' }
    | { error: 'Attention Required' }
  >({ error: 'Attention Required' });
  const [fleet, setFleet] = useState<Record<string, any>>({});

  useEffect(() => {
    if (score.fuelScore) {
      const val = getGridDataPoints(score);
      setFleet(getGridDataPoints(score));
      if (val.fleet.production <= 33.3) {
        setStatus({ error: 'Attention Required' });
      } else if (val.fleet.production > 33.3 && val.fleet.production <= 66.6) {
        setStatus({ warning: 'Caution Advised' });
      } else if (val.fleet.production > 66.6 && val.fleet.production <= 100) {
        setStatus({ success: 'Ideal Performance' });
      } else {
        setStatus({ error: 'Attention Required' });
      }
    }
  }, [score]);

  const getReports = async (startDate: Date, endDate: Date) => {
    const date1 = formatDateToString(startDate, 'YYYY-MM-DD');
    const date2 = formatDateToString(endDate, 'YYYY-MM-DD');
    await reportsHandler.getProductionReport(date1, date2);
  };

  const [productionData, setProductionData] = useState<
    Array<{
      name: string;
      value: number;
      color: string;
    }>
  >([]);

  const [tableChartData, setTableChartData] = useState([]);
  const unit = unitSystem === 'metric' ? ' Tons' : ' Lbs';

  useEffect(() => {
    const colors: any = {
      haul_trucks: '#EB5757',
      loaders: '#497BFA',
      others: '#EC4899',
    };
    if ((production as any).metric) {
      const result = Object.keys((production as any).metric)
        .filter((key) => key !== 'all')
        .map((key) => {
          const category = (production as any)?.metric[key as any];
          const totalTonsProduced = Object.values(category).reduce(
            (sum: number, item: any) => sum + +item.tonsProduced,
            0
          );

          return {
            name: key.slice(0, -1),
            value: totalTonsProduced,
            color: colors[key] || '#000000',
            percentage:
              totalTonsProduced / +(production as any).metric.all.tonsProduced,
          };
        });

      const chartResult: any = [];

      chartResult.push({
        type: 'All',
        model: '',
        tonsProduced: (production as any)?.metric?.all?.tonsProduced,
        avgTonsPerDay: (production as any)?.metric?.all?.avgTonsPerDay,
        avgTonsPerHr: (production as any)?.metric?.all?.avgTonsPerHr,
        noOfTrips: (production as any)?.metric?.all?.noOfTrips,
      });

      for (const [key, value] of Object.entries((production as any).metric)) {
        if (key === 'all') continue;

        for (const [model, details] of Object.entries(value as any)) {
          const entry: any = {
            type: key
              .replace('_', ' ')
              .replace(/(?:^|\s)\S/g, (a) => a.toUpperCase()),
            model,
          };

          for (const [detailKey, detailValue] of Object.entries(
            details as any
          )) {
            entry[detailKey] = detailValue;
          }

          chartResult.push(entry);
        }
      }

      setProductionData(result);
      setTableChartData(chartResult);
    }
  }, [production]);

  useEffect(() => {
    void getReports(startDate, endDate);
  }, [pageType, startDate, endDate]);

  const columnDefs = [
    {
      headerName: 'Vehicle Type',
      field: 'type',
      rowGroup: true,
      hide: true,
      headerClass: 'custom-header',
    },
    { headerName: 'Model', field: 'model', headerClass: 'custom-header' },
    {
      headerName: 'Tons Produced',
      field: 'tonsProduced',
      headerClass: 'custom-header',
    },
    {
      headerName: `Avg ${unit}/Day (*Days here depict the days when vehicle was active)`,
      field: 'avgTonsPerDay',
      headerClass: 'custom-header',
      autoHeaderHeight: true,
    },
    // {
    //   headerName: 'Avg Tons/Hr',
    //   field: '',
    //   headerClass: 'custom-header',
    // },
    {
      headerName: '# of Trips',
      field: 'noOfTrips',
      headerClass: 'custom-header',
    },
  ];

  const defaultColDef = {
    sortable: false,
    filter: false,
    resizable: true,
  };

  const autoGroupColumnDef = {
    headerName: 'Vehicle Type',
    field: 'type',
    cellRendererParams: {
      suppressCount: true,
    },
    headerClass: 'custom-header',
  };

  const rowData = tableChartData;

  useEffect(() => {
    if (gridRef.current ?? gridRef.current?.api) {
      const allColumnIds: any = [];
      gridRef.current.columnApi?.getAllColumns().forEach((column: any) => {
        allColumnIds.push(column.getId());
      });
      gridRef.current.columnApi?.autoSizeColumns(allColumnIds);
    }
  }, [gridRef.current]);

  return (
    <WrapperDiv>
      <ContainerDiv id="exportId">
        {isLoading || productionLoading ? (
          <FadeLoader
            style={{
              height: '100vh',
              backgroundColor: 'transparent',
              position: 'absolute',
              width: '100%',
              zIndex: 9999,
              top: '50%',
              left: '50%',
            }}
            color="black"
            loading={isLoading}
          />
        ) : (
          <>
            <Header
              startDate={startDate}
              endDate={endDate}
              fleet={fleet}
              status={status}
              title="Production"
            />

            <TwoColumnDiv>
              <div style={{ width: '30%', margin: '0 0 0 0' }}>
                <p style={{ fontStyle: 'italic', color: '#333333' }}>
                  Productive by asset type
                </p>
                {productionData.length > 0 && (
                  <PieChart width={800} height={400}>
                    <Pie
                      data={productionData}
                      cx={120}
                      cy={100}
                      innerRadius={60}
                      outerRadius={90}
                      fill="#8884d8"
                      dataKey="value"
                      labelLine={false}
                      label={renderCustomizedLabel}
                    >
                      <Label
                        value="Total"
                        position="center"
                        fontSize={'16px'}
                        fill="rgba(0,0,0, .5)"
                        style={{
                          transform: 'translateY(-25px)',
                        }}
                      />
                      <Label
                        value={truncateTo2dp(
                          (production as any).metric.all.tonsProduced
                        )}
                        position="centerBottom"
                        fontSize={'25px'}
                        fontWeight="bold"
                        fill="#0063BE"
                        style={{
                          transform: 'translateY(15px)',
                        }}
                      />
                      <Label
                        value={`${unit}`}
                        position="center"
                        fontSize={'16px'}
                        fill="rgba(0,0,0, .5)"
                        style={{
                          transform: 'translateY(35px)',
                        }}
                      />
                      {productionData.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={entry.color} />
                      ))}
                    </Pie>
                    <Tooltip
                      formatter={(value) => formatTooltipValue(+value)}
                    />
                  </PieChart>
                )}
              </div>
              <div style={{ width: '60%' }}>
                <p style={{ fontStyle: 'italic', color: '#333333' }}>
                  Daily Production
                </p>
                <ResponsiveContainer width="95%" height={300}>
                  <LineChart
                    data={(production as any).chartData}
                    margin={{
                      top: 5,
                      right: 20,
                      left: 10,
                      bottom: 5,
                    }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="date" />
                    <YAxis
                      label={{
                        value: 'Daily Production',
                        angle: -90,
                        position: 'insideLeft',
                        dy: 80,
                        dx: 0,
                      }}
                    />
                    <Tooltip
                      formatter={(value) => formatTooltipValue(+value)}
                    />
                    <Legend />

                    <Line
                      type="monotone"
                      dataKey="haul_trucks"
                      stroke="#EB5757"
                      activeDot={{ r: 8 }}
                    />
                    <Line type="monotone" dataKey="loader" stroke="#497BFA" />
                    <Line type="monotone" dataKey="others" stroke="#EC4899" />
                  </LineChart>
                </ResponsiveContainer>
              </div>
            </TwoColumnDiv>
            <PageLengends />
            <ReportTable
              gridRef={gridRef}
              columnDefs={columnDefs}
              defaultColDef={defaultColDef}
              rowData={rowData}
              autoGroupColumnDef={autoGroupColumnDef}
            />
          </>
        )}
      </ContainerDiv>
    </WrapperDiv>
  );
};

export default Production;
