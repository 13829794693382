import { Box, Grid } from '@mui/material';
import { PmVsCmVsModification } from './Modals/PmVsCmVsModification';
import {
  useFetchPmVsCmVsModification,
  useFetchPmVsCmExpenditures,
} from './Helper/fetchData';
import { PmVsCmExpenditure } from './Modals/PmVsCmExpenditure';
import React from 'react';
import Colors from '../../Helpers/Graphs/styles';

export const MaintenanceComparisonView = ({
  enableDarkTheme,
}: {
  enableDarkTheme: boolean;
}) => {
  const fetchPmVsCmVsModification = useFetchPmVsCmVsModification();
  const fetchPmVsCmExpenditures = useFetchPmVsCmExpenditures();

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <PmVsCmVsModification
            dataKey="total_cost"
            dataLabel="Total Cost"
            fetchSquaresData={fetchPmVsCmVsModification}
            graphHeader="Cost Totals"
            yAxisLabel="Cost ($)"
            enableDarkTheme={enableDarkTheme}
          />
        </Grid>
        <Grid item xs={6}>
          <PmVsCmVsModification
            dataKey="total_quantity"
            dataLabel="Total Quantity"
            fetchSquaresData={fetchPmVsCmVsModification}
            graphHeader="by Count"
            yAxisLabel="Count"
            enableDarkTheme={enableDarkTheme}
          />
        </Grid>
        <Grid item xs={6}>
          <PmVsCmVsModification
            dataKey="total_hours"
            dataLabel="Total Hours"
            fetchSquaresData={fetchPmVsCmVsModification}
            graphHeader="Hours Totals"
            yAxisLabel="Hours"
            enableDarkTheme={enableDarkTheme}
          />
        </Grid>
        <Grid item xs={6}>
          <PmVsCmExpenditure
            fetchSquaresData={fetchPmVsCmExpenditures}
            graphHeader="Expenditures"
            barKeys={[
              {
                name: 'grand_total_cost',
                color: Colors.orange,
                label: 'Grand total cost',
              },
              {
                name: 'total_labour_cost',
                color: Colors.blue,
                label: 'Total labour cost',
              },
              {
                name: 'total_part_cost',
                color: Colors.purple,
                label: 'Total part cost',
              },
            ]}
            enableDarkTheme={enableDarkTheme}
          ></PmVsCmExpenditure>
        </Grid>
      </Grid>
    </Box>
  );
};
