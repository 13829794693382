import { type HierarchyNode } from 'types/hierarchy.type';
import BaseAPI from './base.api';

export default class HierarchyAPI extends BaseAPI {
  /**
   * Gets all assets the current user has access to
   * @returns A list of assets
   */
  get = async (): Promise<HierarchyNode> => {
    // TODO - change this to the correct return type

    const response = await this.API.get('hierarchy/assigned-hierarchy-node');
    return response.data;
  };
}
