import { createSlice } from '@reduxjs/toolkit';

export interface weatherState {
  data: {
    city: string;
    result: any[];
    state: string;
  };
  isLoading: boolean;
}

const initialState: weatherState = {
  data: {
    city: '',
    result: [],
    state: '',
  },
  isLoading: false,
};

export const weatherSlice = createSlice({
  name: 'weather',
  initialState,
  reducers: {
    getAll(state, { payload }) {
      return {
        data: payload,
        isLoading: false,
      };
    },
    setIsLoading(state, { payload }) {
      state.isLoading = payload;
    },
  },
});

export const { getAll, setIsLoading } = weatherSlice.actions;
export default weatherSlice.reducer;
