import { useEffect, useState } from 'react';
import { Box } from '@mui/material';
import type { Asset } from 'types/asset.types';
import { getAssetMakeModel } from '../utils';
import FilterItem from './FilterItem';
import { assetTypeMappings } from 'views/FleetOverview/helpers/asset.helpers';

interface Props {
  enableDarkTheme: boolean;
  assets: Asset[];
  setAssetsDisplay: any;
  defaultAssetType: string;
  setSelectedAssetIds: any;
}

const ComparerModalFilter = ({
  enableDarkTheme,
  assets,
  setAssetsDisplay,
  defaultAssetType,
  setSelectedAssetIds,
}: Props) => {
  const defaultAssetTypeValue = assetTypeMappings[defaultAssetType] || 'Others';
  const [selectedAssetType, setSelectedAssetType] = useState<string>(
    defaultAssetTypeValue
  );
  const [assetMakeModelList, setAssetMakeModelList] = useState<string[]>(
    getAssetMakeModel(assets)
  );
  const [selectedMakeModel, setSelectedMakeModel] = useState<string[]>([
    ...assetMakeModelList,
  ]);

  function handleAssetTypeSelection(type: string) {
    setSelectedAssetType(type);
    // Clearing list of items since type has changed
    setSelectedAssetIds([]);
  }

  const filterList = [
    {
      title: 'Asset Type',
      listItems: ['Haul Truck', 'Loader', 'Others'],
      checkedList: [selectedAssetType],
      action: handleAssetTypeSelection,
    },
    {
      title: 'Make Model',
      listItems: assetMakeModelList,
      checkedList: selectedMakeModel,
      action: setSelectedMakeModel,
    },
  ];

  useEffect(() => {
    // When type changes get matching make models
    const assetTypes = Object.keys(assetTypeMappings).filter(
      (key) => assetTypeMappings[key] === selectedAssetType
    );
    const assetsFiltered = assets.filter((asset: Asset) => {
      return assetTypes.includes(asset.assetType);
    });
    const assetMakeModelList = getAssetMakeModel(assetsFiltered);
    setAssetMakeModelList(assetMakeModelList);
  }, [selectedAssetType]);

  useEffect(() => {
    const filteredAssets = filterAssets(
      selectedAssetType,
      selectedMakeModel,
      assets
    );
    setAssetsDisplay(filteredAssets);
  }, [selectedMakeModel, selectedAssetType]);

  return (
    <Box
      sx={{
        paddingTop: '20px',
      }}
    >
      {filterList.map((item: any, index) => {
        return (
          <FilterItem
            key={index}
            enableDarkTheme={enableDarkTheme}
            filterList={item.listItems}
            title={item.title}
            checkedList={item.checkedList}
            action={item.action}
          />
        );
      })}
    </Box>
  );
};

export default ComparerModalFilter;

function filterAssets(
  assetType: string,
  makeModels: string[],
  assets: Asset[]
) {
  const assetTypes = Object.keys(assetTypeMappings).filter(
    (key) => assetTypeMappings[key] === assetType
  );
  let assetsToDisplay = assets.filter((asset: Asset) => {
    return assetTypes.includes(asset.assetType);
  });
  assetsToDisplay = assetsToDisplay.filter((asset: Asset) => {
    const assetMakeModel = `${asset.make.toLowerCase()}-${asset.model.toLowerCase()}`;
    return makeModels.includes(assetMakeModel);
  });

  return assetsToDisplay;
}
