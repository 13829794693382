import React, { useState, useEffect } from 'react';
import {
  Bar,
  ComposedChart,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
} from 'recharts';

const LoadingGraph = () => {
  // Initial static placeholder data
  const initialData = [
    { name: '', value: 30 },
    { name: '', value: 60 },
    { name: '', value: 45 },
    { name: '', value: 80 },
  ];

  const [placeholderData, setPlaceholderData] = useState(initialData);

  useEffect(() => {
    const interval = setInterval(() => {
      setPlaceholderData(
        placeholderData.map((item) => ({
          ...item,
          value: Math.random() * 100, // Random value between 0 and 100
        }))
      );
    }, 700); // Update every 500 milliseconds

    return () => {
      clearInterval(interval);
    }; // Clean up the interval on component unmount
  }, [placeholderData]);

  return (
    <ResponsiveContainer height={600}>
      <ComposedChart
        data={placeholderData}
        margin={{ top: 5, right: 30, left: 20, bottom: 4 }}
      >
        <CartesianGrid stroke="#f5f5f5" />
        <XAxis
          dataKey="name"
          height={170}
          label={{ fontSize: 25, value: 'Loading', dy: 10 }}
        />
        <YAxis
          label={{
            value: 'Loading',
            angle: -90,
            position: 'insideLeft',
            fontSize: 25,
            textAnchor: 'middle',
            dy: 170,
          }}
        />
        <Bar dataKey="value" fill="#cccccc" barSize={20} />
      </ComposedChart>
    </ResponsiveContainer>
  );
};

export default LoadingGraph;
