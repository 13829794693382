import { createSlice } from '@reduxjs/toolkit';

export interface AppState {
  isLoading: boolean;
  latestSensorPollingId?: NodeJS.Timeout | null;
  notificationPollingId?: NodeJS.Timeout | null;
}

const initialState: AppState = {
  isLoading: false,
  latestSensorPollingId: null,
  notificationPollingId: null,
};

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    updateLoadingState(state, { payload }) {
      return {
        ...state,
        isLoading: payload,
      };
    },
    updateLatestSensorPollingState(state, { payload }) {
      return { ...state, latestSensorPollingId: payload };
    },
    updateNotificationPollingState(state, { payload }) {
      return { ...state, notificationPollingId: payload };
    },
  },
});

export const {
  updateLoadingState,
  updateNotificationPollingState,
  updateLatestSensorPollingState,
} = appSlice.actions;
export default appSlice.reducer;
