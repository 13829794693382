import { Box, Typography } from '@mui/material';
import { InteractiveModal } from 'components/Modals/InteractiveModal/BaseModal';
import { useState } from 'react';
import type { ICreateAssetPayload } from 'types/payloads/asset.payload.types';
import type { Asset, assetNotesInterface } from 'types/asset.types';
import { resolveAssetTypeDisplay } from 'utils/helpers/assets';
import { formatDateToString } from 'utils/helpers/dateTime';

interface Props {
  /**
   * Title of the Modal
   */
  title: string;
  /**
   * Boolean Value to determine if modal is displpayed or not
   */
  open: boolean;
  /**
   * Asset Data modal is to render
   */
  assetData: Asset;
  /**
   * Function to call to close modal
   */
  handleAbort: () => void;
}

export const AssetDetailModal = ({
  title,
  handleAbort,
  open,
  assetData,
}: Props) => {
  const [asset] = useState<ICreateAssetPayload>({
    assetVin: assetData.assetVin,
    assetType: assetData.assetType,
    make: assetData.make,
    model: assetData.model,
    bumperNumber: assetData.bumperNumber,
    engineHoursOffset: assetData.engineHoursOffset,
    odometerOffset: assetData.odometerOffset,
    fuelSource: assetData.fuelSource,
    operators: assetData.operators || [],
    isActive: assetData.isActive,
    externalId: assetData?.externalId,
    assetNotes: assetData.assetNotes,
  });

  /**
   * This is the form that will be rendered in the modal
   *
   * Asset VIN/ID
   * Asset Type
   * Asset Make
   * Asset Model
   * Bumper Number
   * Odometer Reading Offset
   * Engine Hours Offset
   */
  return (
    <InteractiveModal
      initialState={open}
      SuccessButtonProps={{
        disabled: true,
        onClick: handleAbort,
        label: 'OK',
      }}
      handleAbort={handleAbort}
      title={title}
    >
      <Box key="assetVin">
        <Typography variant="bodyBold" display="block" mb={'4px'}>
          Asset VIN/ID
        </Typography>
        <Typography variant="bodyBold" mb={'4px'}>
          {asset.assetVin}
        </Typography>
      </Box>

      <Box key="assetType">
        <Typography variant="bodyBold" display="block" mb={'4px'}>
          Asset Type
        </Typography>
        <Typography variant="bodyBold" mb={'4px'}>
          {resolveAssetTypeDisplay(asset.assetType)}
        </Typography>
      </Box>

      <Box key="make">
        <Typography variant="bodyBold" display="block" mb={'4px'}>
          Asset Make
        </Typography>
        <Typography variant="bodyBold" mb={'4px'}>
          {asset.make}
        </Typography>
      </Box>

      <Box key="assetModel">
        <Typography variant="bodyBold" display="block" mb={'4px'}>
          Asset Model
        </Typography>
        <Typography variant="bodyBold" mb={'4px'}>
          {asset.model}
        </Typography>
      </Box>

      <Box key="bumperNumber">
        <Typography variant="bodyBold" display="block" mb={'4px'}>
          Bumper ID
        </Typography>
        <Typography variant="bodyBold" mb={'4px'}>
          {asset.bumperNumber}
        </Typography>
      </Box>

      <Box key="fuelSource">
        <Typography variant="bodyBold" display="block" mb={'4px'}>
          Fuel Source
        </Typography>
        <Typography variant="bodyBold" mb={'4px'}>
          {asset.fuelSource}
        </Typography>
      </Box>

      <Box key="odometerOffset">
        <Typography variant="bodyBold" display="block" mb={'4px'}>
          Odometer Reading Offset (km)
        </Typography>
        <Typography variant="bodyBold" mb={'4px'}>
          {asset.odometerOffset}
        </Typography>
      </Box>

      <Box key="engineHoursOffset">
        <Typography variant="bodyBold" display="block" mb={'4px'}>
          Engine Hours Offset
        </Typography>
        <Typography variant="bodyBold" mb={'4px'}>
          {asset.engineHoursOffset}
        </Typography>
      </Box>

      <div
        style={{
          width: '100%',
          marginTop: '10px',
          maxHeight: '200px',
          overflow: 'scroll',
        }}
      >
        {asset?.assetNotes?.map((item: assetNotesInterface) => {
          return (
            <>
              <div
                key={asset?.assetNotes?.indexOf(item)}
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  color: '#2a2a2a',
                  fontSize: '14px',
                  padding: '2px 0 5px 0',
                }}
              >
                <div
                  style={{
                    width: '70%',
                  }}
                >
                  {item.values}
                </div>
                <div
                  style={{
                    marginTop: '10px',
                    fontStyle: 'italic',
                  }}
                >
                  --{' '}
                  {formatDateToString(
                    item.date_added,
                    'HH:mm:ss, MMM DD, YYYY'
                  )}
                </div>
              </div>
              <div style={{ border: 'dotted 0.5px #e0d9d9' }} />
            </>
          );
        })}
      </div>
    </InteractiveModal>
  );
};
