import { useState, useEffect } from 'react';
import { type Asset } from 'types/asset.types';
import FuelConsumption from './FuelConsumption';
import type { FuelIdleEvents, FuelIdleEventItem } from 'types/fuelAnalytics';
import Colors from 'views/Settings/ExternalUsers/Squares/Helpers/Graphs/styles';
import {
  fuelIdleConsumptionLabels,
  getFuelLabelWrtUnitSystem,
} from 'utils/helpers/labels';
import { useAppSelector } from 'store/hook';

interface Props {
  assets: Asset[];
  fuelIdleEvents: FuelIdleEvents;
  isLoading: boolean;
  enableDarkTheme: boolean;
  selectedDevice: string | undefined;
}

const FuelIdleConsumption = ({
  assets,
  fuelIdleEvents,
  isLoading,
  enableDarkTheme = false,
  selectedDevice,
}: Props) => {
  const unitSystem = useAppSelector((state) => state.persistedReducer).customer
    .unitSystem;
  const [chartData, setChartData] = useState<FuelIdleEventItem[]>([]);

  useEffect(() => {
    setChartData(
      formatData(
        fuelIdleEvents.sortedTop5IdlingEventData,
        fuelIdleEvents.idlingEventAverage
      )
    );
  }, [fuelIdleEvents]);

  const getChartData = async (idling: string, assets: string[]) => {
    let data = chartData;

    if (idling === 'mostIdling') {
      data = fuelIdleEvents.sortedTop5IdlingEventData;
    } else if (assets.length) {
      const filteredData = fuelIdleEvents.data.reduce(
        (accumulator: any, dataEntry: any) => {
          if (assets.includes(dataEntry.device)) {
            accumulator.push(dataEntry);
          }
          return accumulator;
        },
        []
      );

      data = filteredData;
    }

    setChartData(formatData(data, fuelIdleEvents.idlingEventAverage));
  };

  return (
    <FuelConsumption
      title="Total Fuel Consumption Index In Idle Time Events"
      assets={assets}
      chartData={chartData}
      barKeys={[
        {
          name: 'Total Fuel Consumed',
          color: Colors.green,
          label: `Total Fuel Consumption Index During Idle Events (${getFuelLabelWrtUnitSystem(
            unitSystem,
            fuelIdleConsumptionLabels.total_fuel_consumed
          )})`,
        },
        {
          name: 'Total Idle Time Events',
          color: Colors.lightBlue,
          label: `${getFuelLabelWrtUnitSystem(
            unitSystem,
            fuelIdleConsumptionLabels.total_idle_time_events
          )}  of Idle Time Events`,
        },
        {
          name: 'CO2',
          color: Colors.dimGray,
          label: `CO2 Emission ${getFuelLabelWrtUnitSystem(
            unitSystem,
            fuelIdleConsumptionLabels.CO2
          )}`,
        },
      ]}
      lineKeys={[
        {
          name: 'Fleet Avg Idle Time Events',
          axisName: 'Total Idle Time Events',
          color: '#C77838',
          strokeWidth: 2,
          strokeDashArray: '3 3',
        },
      ]}
      xAxis="bumperNumber"
      getChartData={getChartData}
      isLoading={isLoading}
      enableDarkTheme={enableDarkTheme}
      data={fuelIdleEvents}
      selectedDevice={selectedDevice}
    />
  );
};

export default FuelIdleConsumption;

function formatData(data: FuelIdleEventItem[], idlingEventAverage: number) {
  return data.map((item: FuelIdleEventItem) => {
    const updatedItem = {
      ...item,
      'Total Fuel Consumed': item.total_fuel_consumption,
      'Total Idle Time Events': item.excess_idle,
      'Fleet Avg Idle Time Events': idlingEventAverage,
      // 'CO2 Produced': item.CO2,
    };
    return updatedItem;
  });
}
