import { Box, Chip, Grid } from '@mui/material';
import LaunchIcon from '@mui/icons-material/Launch';
import { type Asset } from 'types/asset.types';
import { getNotificationIndex } from '../utils';
import AssetCardFuelUsage from './Cards/AssetCardFuelUsage';
import AssetCardUtilization from './Cards/AssetCardUtilization';
import AssetCardProductivity from './Cards/AssetCardProductivity';
import AssetCardMaintenance from './Cards/AssetCardMaintenance';

import { ThemePalette } from 'mui.theme';

interface Props {
  asset: Asset;
  enableDarkTheme: boolean;
  showCheckedButton: boolean;
  handleAssetSelected?: any;
  isLoadingAllAssets: boolean;
  dateComparer: string;
  assetNotifications: any;
  handleAlertClick: any;
}

const AssetCardInsights = ({
  asset,
  enableDarkTheme = false,
  showCheckedButton,
  handleAssetSelected,
  isLoadingAllAssets,
  dateComparer,
  assetNotifications,
  handleAlertClick,
}: Props) => {
  const { assetId } = asset;

  const notificationIndex = getNotificationIndex(assetNotifications);

  return (
    <Box
      sx={{
        width: '60%',
        backgroundColor: enableDarkTheme
          ? ThemePalette.dark.toolBarBackground
          : ThemePalette.light.toolBarBackground,
        borderRadius: '5px',
      }}
    >
      {/** Box for Caution chip and modal button */}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          padding: '10px',
        }}
      >
        <Box
          sx={{
            width: '90%',
          }}
        >
          <Chip
            label={notificationIndex.message}
            variant="outlined"
            sx={{
              color: '#ffffff',
              backgroundColor: notificationIndex.color,
              borderRadius: '8px',
              border: 'none',
              fontSize: '0.7rem',
            }}
          />
        </Box>
        <Box
          sx={{
            width: '10%',
          }}
        >
          {showCheckedButton ? (
            <></>
          ) : (
            <>
              {isLoadingAllAssets ? (
                <></>
              ) : (
                <LaunchIcon
                  onClick={() => {
                    handleAssetSelected(assetId);
                  }}
                  sx={{
                    paddingTop: '8px',
                    fontSize: '17px',
                    color: enableDarkTheme
                      ? ThemePalette.typography.white
                      : ThemePalette.typography.black,
                  }}
                />
              )}
            </>
          )}
        </Box>
      </Box>
      {/** Box for content */}
      <Grid container spacing={2}>
        <Grid item md={6} lg={6}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              textAlign: 'center',
            }}
          >
            <Box
              sx={{
                textAlign: 'center',
                color: enableDarkTheme
                  ? ThemePalette.typography.white
                  : ThemePalette.typography.black,
              }}
            >
              <AssetCardFuelUsage
                asset={asset}
                enableDarkTheme={enableDarkTheme}
                isLoadingAllAssets={isLoadingAllAssets}
                dateComparer={dateComparer}
              />
              <AssetCardProductivity
                asset={asset}
                enableDarkTheme={enableDarkTheme}
                isLoadingAllAssets={isLoadingAllAssets}
                dateComparer={dateComparer}
              />
            </Box>
          </Box>
        </Grid>
        <Grid item md={6} lg={6}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Box
              sx={{
                textAlign: 'center',
                color: enableDarkTheme
                  ? ThemePalette.typography.white
                  : ThemePalette.typography.black,
              }}
            >
              <AssetCardUtilization
                asset={asset}
                enableDarkTheme={enableDarkTheme}
                isLoadingAllAssets={isLoadingAllAssets}
                dateComparer={dateComparer}
              />
              <AssetCardMaintenance
                asset={asset}
                enableDarkTheme={enableDarkTheme}
                isLoadingAllAssets={isLoadingAllAssets}
                dateComparer={dateComparer}
                assetNotifications={assetNotifications}
                handleAlertClick={handleAlertClick}
              />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default AssetCardInsights;
