import DeviceAPI from 'api/device.api';
import {
  updateLoadingState,
  updateLatestSensorPollingState,
} from 'store/app.slice';
import {
  setDevicesWithTagList,
  setUnassociatedDeviceList,
  updateUnassociatedDeviceList,
} from 'store/device.slice';
import { type Device } from 'types/device.types';
import { type IUpdateDevicePayload } from 'types/payloads/device.payload.types';
import AssetHandler from './asset.handler';
import { BaseHandler } from './base.handler';

export default class DeviceHandler extends BaseHandler {
  private readonly api: DeviceAPI;
  private readonly assetHandler: AssetHandler;

  constructor() {
    super();

    this.api = new DeviceAPI();
    this.assetHandler = new AssetHandler();
  }

  /**
   * Gets all unassociated devices from the backend for this particular customer
   * The devices are then stored in the redux store. Associated devices are retrieved with
   * in the asset handler using joins. This is to avoid programatically joining the devices
   * to the assets.
   *
   * @returns Promise<Device[]>
   *
   */
  async getAll(): Promise<Device[]> {
    this.dispatch(updateLoadingState(true));

    try {
      const devices = await this.api.unassociatedDeviceGetAll();

      this.dispatch(setUnassociatedDeviceList(devices));
      this.dispatch(updateLoadingState(false));

      return devices;
    } catch (_) {
      this.handleError('An error occurred while fetching devices.');
    }

    return [];
  }

  // update device info using api
  async updateDevice(
    deviceId: string,
    payload: IUpdateDevicePayload
  ): Promise<void> {
    this.dispatch(updateLoadingState(true));

    try {
      const updatedDevice = await this.api.update(deviceId, payload);

      // refetch all assets to get the updated device details
      void this.assetHandler.getAll();

      // update the unassociated device list
      this.dispatch(updateUnassociatedDeviceList(updatedDevice));

      this.handleSuccess('Device has been updated.');
      this.dispatch(updateLoadingState(false));
    } catch (_) {
      // show snackbar error
      this.handleError('An error occurred while updating the device.');
    }
  }

  /**
   * Gets all tags for associated devices from the dynamodb device_status for this particular customer
   * The data are then stored in the redux store.
   * @returns Promise<Device[]>
   *
   */
  async getDeviceLatestSensorDataAll(): Promise<Device[]> {
    let id: NodeJS.Timeout | null | undefined = null;
    this.dispatch(updateLoadingState(true));

    try {
      const fetchLatestSensorDataAsync = async () => {
        let devices = await this.api.deviceLatestSensorDataAll();
        // Start short polling with a delay
        const pollData = async () => {
          devices = await this.api.deviceLatestSensorDataAll();

          this.dispatch(setDevicesWithTagList(devices));
          // Fetch data again after 5 minutes
          id = setTimeout(pollData, 300000);
          this.dispatch(updateLatestSensorPollingState(id));
        };

        // Initial delay before first data fetch
        id = setTimeout(pollData, 300000);
        this.dispatch(updateLatestSensorPollingState(id));

        return devices;
      };

      const devices = await fetchLatestSensorDataAsync();

      this.dispatch(setDevicesWithTagList(devices));
      this.dispatch(updateLoadingState(false));
    } catch (e: unknown) {
      if (typeof e === 'object' && e !== null) {
        const response = (e as Record<string, any>).response;
        if (response.data.message === 'no devices found') {
          this.handleError('No device has been associated with an asset');
        } else {
          this.handleError('An error occurred while fetching devices.');
        }
      }
    }

    return [];
  }
}
