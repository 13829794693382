import SquaresDashboardHandler from 'handlers/squaresHandlers/squaresDashboard.handler';
import { useDispatch } from 'react-redux';
import { updateLoadingState } from 'store/app.slice';
import { type DashboardSquares } from 'types/squares.type';

export const useFetchDashboardAssetsData = () => {
  const squaresHandler = new SquaresDashboardHandler();

  const fetchData = async (
    selectedStartDate: Date,
    selectedEndDate: Date
  ): Promise<DashboardSquares[]> => {
    try {
      const squaresData = await squaresHandler.getDashboardAssetsData(
        selectedStartDate,
        selectedEndDate
      );
      return squaresData.assets;
    } catch (error) {
      console.error(error);
      return []; // Return an empty array in case of an error
    }
  };

  return fetchData;
};

export const useFetchComponentData = () => {
  const dispatch = useDispatch();
  const squaresHandler = new SquaresDashboardHandler();

  const fetchData = async (
    selectedStartDate: Date,
    selectedEndDate: Date
  ): Promise<DashboardSquares[]> => {
    dispatch(updateLoadingState(true));
    try {
      const squaresData = await squaresHandler.getPartsComponentsData(
        selectedStartDate,
        selectedEndDate
      );
      return squaresData.components;
    } catch (error) {
      console.error(error);
      return []; // Return an empty array in case of an error
    } finally {
      dispatch(updateLoadingState(false));
    }
  };

  return fetchData;
};
