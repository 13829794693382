import { COLORS } from 'utils/enums';
export const ASSET_TYPE_COLORS = Object.freeze({
  haul_truck: '#6E89D0',
  light_truck: '#5BEEEE',
  heavy_truck: '#4444D1',
  dozer: '#3285A6',
  loader: '#F5C274',
  excavator: '#515252',
  drill: '#D37423',
  bolter: '#A8A9A9',
  miscellaneous: '#A8A9A9',
});

/*
  Asset make model colors are in array format
  because we cannot deduce what the make/model is
*/
export const ASSET_MAKE_MODEL_COLORS = Object.freeze([
  '#CE9A5E',
  '#4444D1',
  '#D37423',
  '#023059',
  '#6888D6',
  '#A8A9A9 ',
]);

export const ASSET_DEVICE_STATUS_COLORS = Object.freeze({
  online: '#6888D6',
  offline: '#D37423',
  unknown: '#A8A9A9',
});

export const ASSET_ALERT_STATUS_COLORS = Object.freeze({
  '3': COLORS.red,
  '2': COLORS.yellow,
  '1': COLORS.grey,
  '0': COLORS.green,
});
