const PageLengends = () => (
  <div style={{ margin: '-60px 30% 40px', display: 'flex' }}>
    <div
      style={{
        margin: '0px auto',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
      }}
    >
      <div
        style={{
          width: '10px',
          height: '10px',
          borderRadius: '50%',
          backgroundColor: '#EB5757',
          margin: '0px 10px',
        }}
      />

      <p style={{ marginTop: '-5px' }}> Haul Trucks</p>
    </div>
    <div
      style={{
        margin: '0px auto',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
      }}
    >
      <div
        style={{
          width: '10px',
          height: '10px',
          borderRadius: '50%',
          backgroundColor: '#497BFA',
          margin: '0px 10px',
        }}
      />

      <p style={{ marginTop: '-5px' }}> Loaders</p>
    </div>
    <div
      style={{
        margin: '0px auto',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
      }}
    >
      <div
        style={{
          width: '10px',
          height: '10px',
          borderRadius: '50%',
          backgroundColor: '#009161',
          margin: '0px 10px',
        }}
      />

      <p style={{ marginTop: '-5px' }}> Others</p>
    </div>
  </div>
);

export default PageLengends;
