export const statusEnum = {
  success: '#009161',
  warning: '#FFA403',
  error: '#EB5757',
};

interface DeviceData {
  device: string;
  dailyEngineHours: number;
  totalEngineHours: number;
  totalWorkingHours: number;
  customerDay: string;
  totalIdleFuelConsumption: number;
  dailyIdleTimeHours: number;
  sumIdleFuelConsumption: number;
  sumIdleTimeHours: number;
  avgIdleFuelConsumption: number;
  avgIdleTimeHours: number;
  dailyFuelConsumption: number;
  sumFuelConsumption: number;
  bumperNumber: string;
  make: string;
  model: string;
  assetType: string;
  makeModel: string;
}

interface FormattedData {
  type: string;
  model: string;
  dailyEngineHours: number;
  totalEngineHours: number;
  averageFuelPerDay: number;
}

function roundUp(value: number, decimals: number): number {
  return Math.ceil(value * Math.pow(10, decimals)) / Math.pow(10, decimals);
}

/**
 * Formats the given array of DeviceData into an array of FormattedData by aggregating data based on asset type and make model.
 *
 * @param data Array of DeviceData to be formatted
 * @returns FormattedData array with aggregated data for each asset type and make model, including an 'All' category
 */
export function formatData(data: DeviceData[]): FormattedData[] {
  const result: FormattedData[] = [];

  const groupedData: Record<
    string,
    {
      dailyEngineHours: number;
      totalEngineHours: number;
      sumIdleFuelConsumption: number;
      dailyFuelConsumption: number;
    }
  > = {};

  data?.forEach((item) => {
    const assetKey = `${item.assetType}|${item.makeModel}`;

    if (!groupedData[assetKey]) {
      groupedData[assetKey] = {
        dailyEngineHours: 0,
        totalEngineHours: 0,
        sumIdleFuelConsumption: 0,
        dailyFuelConsumption: 0,
      };
    }

    groupedData[assetKey].dailyEngineHours += item.dailyEngineHours;
    groupedData[assetKey].totalEngineHours += item.totalEngineHours;
    groupedData[assetKey].sumIdleFuelConsumption += item.sumIdleFuelConsumption;
    groupedData[assetKey].dailyFuelConsumption += item.dailyFuelConsumption;
  });

  // Aggregate the 'All' category
  let totalDailyEngineHours = 0;
  let totalEngineHours = 0;
  let totalSumIdleFuelConsumption = 0;
  let totalDailyFuelConsumption = 0;

  for (const key in groupedData) {
    const [type, model] = key.split('|');
    const {
      dailyEngineHours,
      totalEngineHours: totalHours,
      sumIdleFuelConsumption,
      dailyFuelConsumption,
    } = groupedData[key];
    const averageFuelPerDay = roundUp(
      sumIdleFuelConsumption / dailyFuelConsumption,
      2
    );

    result.push({
      type: type.charAt(0).toUpperCase() + type.slice(1),
      model,
      dailyEngineHours: roundUp(dailyEngineHours, 2),
      totalEngineHours: roundUp(totalHours, 2),
      averageFuelPerDay,
    });

    totalDailyEngineHours += dailyEngineHours;
    totalEngineHours += totalHours;
    totalSumIdleFuelConsumption += sumIdleFuelConsumption;
    totalDailyFuelConsumption += dailyFuelConsumption;
  }

  // Add the 'All' type
  result.unshift({
    type: 'All',
    model: '',
    dailyEngineHours: roundUp(totalDailyEngineHours, 2),
    totalEngineHours: roundUp(totalEngineHours, 2),
    averageFuelPerDay: roundUp(
      totalSumIdleFuelConsumption / totalDailyFuelConsumption,
      2
    ),
  });

  return result;
}

/**
 * Formats the given array of DeviceData into an array of FormattedData by aggregating data based on asset type and make model.
 *
 * @param data Array of DeviceData to be formatted
 * @returns FormattedData array with aggregated data for each asset type and make model, including an 'All' category
 */
export function formatFuelUsageData(data: DeviceData[]): any {
  const uniqueDays = [...new Set(data.map((item: any) => item.customer_day))]
    .length;

  // Aggregate all data
  const allFuelConsumed = data.reduce(
    (acc, item: any) => acc + (item.sum_fuel_consumption_device as number),
    0
  );

  const aggregatedData = [
    {
      type: 'All',
      model: '',
      totalFuelConsumed: allFuelConsumed.toFixed(2),
      avgFuelUsage: (allFuelConsumed / uniqueDays).toFixed(2),
      averageFuelPerHour: null,
    },
    ...Object.values(
      data.reduce((acc: any, item: any) => {
        const key = `${item.asset_type}-${item.makeModel}`;
        if (!acc[key]) {
          acc[key] = {
            type:
              (item.asset_type.charAt(0).toUpperCase() as string) +
              (item.asset_type.slice(1) as string),
            model: item.makeModel,
            totalFuelConsumed: 0,
            avgFuelUsage: 0,
            averageFuelPerHour: null,
          };
        }
        (acc[key].totalFuelConsumed as number) +=
          item.sum_fuel_consumption_device as number;
        return acc;
      }, {})
    ).map((entry: any) => ({
      ...entry,
      totalFuelConsumed: entry.totalFuelConsumed.toFixed(2),
      avgFuelUsage: (entry.totalFuelConsumed / uniqueDays).toFixed(2),
    })),
  ];
  return aggregatedData;
}

export const formatTooltipValue = (value: number) => {
  return value.toFixed(2);
};
