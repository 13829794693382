import { useAuth0 } from '@auth0/auth0-react';
import { useDispatch } from 'react-redux';
import { resetUserState } from 'store/auth.slice';
import { resetPersistedState } from 'store/persisted.slice';
import { resetCustomerState } from 'store/customer.slice';
import authClientOptions from 'utils/config/auth0.config';

// log user out and reset user state
export const useRemoveAuth = (): {
  execRemoveAuth: () => void;
  isAuthenticated: boolean;
} => {
  const { logout, isAuthenticated } = useAuth0();
  const dispatch = useDispatch();

  const execRemoveAuth = () => {
    dispatch(resetUserState());
    dispatch(resetPersistedState());
    dispatch(resetCustomerState());

    logout({ logoutParams: { returnTo: authClientOptions.redirectURI } });
  };

  return {
    execRemoveAuth,
    isAuthenticated,
  };
};
