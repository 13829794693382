import { Box, TextField, Typography } from '@mui/material';
import { FlexBox } from 'components/Containers/FlexBox';
import { SWTextField } from 'components/Form/Input/SWTextField';
import { Selector } from 'components/Form/Selectors/Selector';
import { InteractiveModal } from 'components/Modals/InteractiveModal/BaseModal';
import ExternalUserHandler from 'handlers/external.user.handler';
import { useEffect, useState } from 'react';
import { modalInputFieldStyle } from 'styles/global.css';
import { type ICreateExternalUserPayload } from 'types/payloads/user.payload.types';
import { USER_PERMISSION_LEVEL_OPTIONS } from 'utils/enums';
import { createOrUpdateExternalUserRequestBodySchema } from 'utils/schemas/user.schema';
import { validateYupSchema } from 'utils/validators/schema.validator';
import { type RoleType } from 'types/user.types';
import { formatDate } from 'utils/helpers/dateTime';

interface Props {
  /**
   * Title of the Modal
   */
  title: string;
  /**
   * Boolean Value to determine if modal is displpayed or not
   */
  open: boolean;
  /**
   * User Data modal is to render
   */
  userData: RoleType;
  /**
   * Function to call to close modal
   */
  handleAbort: () => void;
}

export const UpdateExternalUserModal = ({
  title,
  open,
  handleAbort,
  userData,
}: Props) => {
  const userHandler = new ExternalUserHandler();

  const [isValidForm, setFormValidity] = useState(false);
  const [externalUser, setExternalUser] = useState<ICreateExternalUserPayload>({
    firstName: userData.firstName,
    lastName: userData.lastName,
    email: userData.email,
    customerAccessLevel:
      userData.role !== undefined ? userData.role : 'external-user',
    hierarchyNode: userData.hierarchyNode,
  });

  // Validate form as user fills out fields
  useEffect(() => {
    const validateForm = async () =>
      await validateYupSchema(
        externalUser,
        createOrUpdateExternalUserRequestBodySchema
      );

    void validateForm().then((isValid) => {
      setFormValidity(isValid);
    });
  }, [externalUser]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    setExternalUser({
      ...externalUser,
      [name]: value,
    });
  };

  const handleSuccess = async () => {
    await userHandler.updateExternalUser(userData.userId, externalUser);

    handleAbort();
  };

  return (
    <InteractiveModal
      initialState={open}
      SuccessButtonProps={{
        disabled: !isValidForm,
        onClick: handleSuccess,
        label: 'EDIT USER',
      }}
      handleAbort={handleAbort}
      title={title}
      confirmCancellation
    >
      {/* First Name TextField */}
      <SWTextField
        key="first-name"
        name="firstName"
        label="First Name*"
        TextFieldProps={{
          name: 'firstName',
          value: externalUser.firstName,
          onChange: handleChange,
          InputProps: {
            sx: modalInputFieldStyle,
          },
        }}
      />

      {/* Last Name TextField */}
      <SWTextField
        key="last-name"
        name="lastName"
        label="Last Name*"
        TextFieldProps={{
          name: 'lastName',
          value: externalUser.lastName,
          onChange: handleChange,
          InputProps: {
            sx: modalInputFieldStyle,
          },
        }}
      />

      {/* Email  TextField */}
      <Box key="email">
        <Typography variant="bodyBold" display="block" mb={'4px'}>
          Email Address*
        </Typography>
        <FlexBox>
          <TextField
            name="email"
            onChange={handleChange}
            value={externalUser.email}
            InputProps={{
              sx: modalInputFieldStyle,
            }}
          />
        </FlexBox>
      </Box>

      {/* Hierarchy Node selector drop down single */}
      <Box key="hierarchyNode">
        <Typography variant="bodyBold" display="block" mb={'4px'}>
          Hierarchy Node
        </Typography>
        <Selector
          value={externalUser.hierarchyNode}
          onChange={(event) => {
            setExternalUser({
              ...externalUser,
              hierarchyNode:
                event.target.value.toString() as ICreateExternalUserPayload['hierarchyNode'],
            });
          }}
          selectorOptions={[
            {
              id: userData.hierarchyNode ?? '',
              display: userData.hierarchyNode ?? '',
            },
          ]}
        />
      </Box>

      {/* Permission selector drop down single */}
      <Box key="customerAccessLevel">
        <Typography variant="bodyBold" display="block" mb={'4px'}>
          Permission Level
        </Typography>
        <Selector
          value={externalUser.customerAccessLevel}
          onChange={(event) => {
            setExternalUser({
              ...externalUser,
              customerAccessLevel:
                event.target.value.toString() as ICreateExternalUserPayload['customerAccessLevel'],
            });
          }}
          selectorOptions={USER_PERMISSION_LEVEL_OPTIONS}
        />
      </Box>
      <FlexBox></FlexBox>

      {/* Non updateable customer data starts here */}
      <Box key="user-id" mt="40px">
        <Typography variant="bodyBold" display="block" mb="4px">
          User ID #
        </Typography>
        <Typography variant="small" display="block">
          {userData.userId}
        </Typography>
      </Box>
      <Box key="login-count" mt="40px">
        <Typography variant="bodyBold" display="block" mb="4px">
          Number of Logins
        </Typography>
        <Typography variant="small" display="block">
          {userData.loginCount}
        </Typography>
      </Box>
      <Box key="last-login" mt="40px">
        <Typography variant="bodyBold" display="block" mb="4px">
          Last Login
        </Typography>
        <Typography variant="small" display="block">
          {userData.lastLogin ? formatDate(userData.lastLogin) : null}
        </Typography>
      </Box>
    </InteractiveModal>
  );
};
