import { Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { useAppSelector } from 'store/hook';
import { selectAssetByMakeModel } from 'store/asset.slice';
import { type SelectOptions } from 'types/utils.types';
import {
  capitalizeFirstLetter,
  safeReplace,
} from 'utils/helpers/string.manipulation';
import { gbsMakeModelsToFilter, klgMakeModelsToFilter } from './UsageDash';
import dayjs from 'dayjs';
import { ThemePalette } from 'mui.theme';
import { FilterHeader } from './IdleDashboard/FilterHeader';
import { TreemapSection } from './IdleDashboard/TreemapSection';
import { CardSection } from './IdleDashboard/CardSection';
import { PiechartSection } from './IdleDashboard/PiechartSection';
import { AreachartSection } from './IdleDashboard/AreaChartSection';
import { assetTypeMappings } from 'views/FleetOverview/helpers/asset.helpers';
import { getAssetMakeModel } from 'views/FleetOverview/components/AssetCardsV2/utils';
import type { Asset } from 'types/asset.types';
import {
  getSelectedDeviceMakeModel,
  getTreeMapData,
  getCardData,
  getAreaChartData,
  getPieChartData,
} from './IdleDashboard/utils';

export const IdleDashboard = ({
  enableDarkTheme,
  selectedEndDate,
  selectedStartDate,
  customerCode,
  selectedData,
  setSelectedData,
  handleDateChange,
  isProductivity,
  type,
  selectedDevice,
  setSelecedDevice,
  selectedMakeModel,
  setSelectedMakeModel,
  selectedAssetType,
  setSelectedAssetType,
}: {
  enableDarkTheme: boolean;
  selectedEndDate: Date;
  selectedStartDate: Date;
  customerCode: string;
  selectedData: string | null;
  setSelectedData: any;
  handleDateChange: any;
  isProductivity: boolean;
  type: string | undefined;
  selectedDevice: string | undefined;
  setSelecedDevice: any;
  selectedMakeModel: string;
  setSelectedMakeModel: any;
  selectedAssetType: string;
  setSelectedAssetType: any;
}): JSX.Element => {
  /**
   * New implementation to support utilization click from fleetoverview page
   * Hacky work. tech debt
   */

  const { isLoading } = useAppSelector((state) => state.appReducer);
  const assets = useAppSelector((state) => state.assetReducer.assets);
  const [treeMapEmpty, setTreeMapEmpty] = useState<boolean>(false);
  const idleAnalytics = useAppSelector((state) => state.idleAnalyticsReducer);

  const assetMakeModelList = useAppSelector(selectAssetByMakeModel);
  const [assetOptions, setAssetOptions] = useState<SelectOptions[]>([]);

  const calculateDaysDifference = () => {
    const startDate = dayjs(selectedStartDate);
    const endDate = dayjs(selectedEndDate);

    return endDate.diff(startDate, 'day') + 1;
  };

  useEffect(() => {
    if (
      Object.keys(assetMakeModelList).length &&
      customerCode !== 'mlc' &&
      customerCode !== 'mlp' &&
      customerCode !== 'cma'
    ) {
      const selectorOptions = Object.keys(assetMakeModelList).map((key) => {
        return {
          display: capitalizeFirstLetter(safeReplace(key, '!', ' ')),
          id: key,
        };
      });

      selectorOptions.sort((a, b) => {
        const displayA = a.display.toUpperCase();
        const displayB = b.display.toUpperCase();
        if (displayA < displayB) {
          return -1;
        }
        if (displayA > displayB) {
          return 1;
        }
        return 0;
      });
      let selectOptions: Array<{ display: string; id: string }> = [];

      selectOptions =
        customerCode === 'klg'
          ? selectorOptions.filter((option) =>
              klgMakeModelsToFilter.includes(option.display.toLowerCase())
            )
          : customerCode === 'gbs'
          ? selectorOptions.filter((option) =>
              gbsMakeModelsToFilter.includes(option.display.toLowerCase())
            )
          : selectorOptions;

      if (selectOptions.length > 1) {
        if (selectedAssetType !== 'total') {
          const assetTypes = Object.keys(assetTypeMappings).filter(
            (key) => assetTypeMappings[key] === selectedAssetType
          );
          const assetsFiltered = assets.filter((asset: Asset) => {
            return assetTypes.includes(asset.assetType);
          });
          const assetTypeMakeModelList = getAssetMakeModel(assetsFiltered).map(
            (item) => item.replace('-', '!')
          );

          selectOptions = selectOptions.filter((item) =>
            assetTypeMakeModelList.includes(item.id)
          );
        }
      }
      selectOptions.push({ id: 'all', display: 'All Assets' });

      let defaultMakeModel = 'all';
      if (selectedDevice) {
        defaultMakeModel =
          getSelectedDeviceMakeModel(assets, selectedDevice) ?? 'all';
      }
      if (selectedMakeModel) {
        defaultMakeModel = selectedMakeModel;
      }

      setSelectedMakeModel(defaultMakeModel);
      setSelectedData(null);
      setAssetOptions(selectOptions);
    }
  }, [assetMakeModelList, customerCode, selectedAssetType]);

  const handleAssetTypeChange = (event: any) => {
    setSelectedAssetType(event.target.value);
    setSelectedMakeModel('all');
    setSelecedDevice(undefined);
  };

  useEffect(() => {
    const isValidData = getTreeMapData(
      assets,
      idleAnalytics,
      selectedAssetType,
      selectedMakeModel,
      isProductivity
    );
    if (isValidData.length === 0) {
      setTreeMapEmpty(true);
    } else {
      setTreeMapEmpty(false);
    }
  }, [assets, idleAnalytics, selectedAssetType, selectedMakeModel]);

  return (
    <>
      <FilterHeader
        selectedMakeModel={selectedMakeModel}
        setSelectedMakeModel={setSelectedMakeModel}
        setSelectedData={setSelectedData}
        customerCode={customerCode}
        assetOptions={assetOptions}
        klgMakeModelsToFilter={klgMakeModelsToFilter}
        gbsMakeModelsToFilter={gbsMakeModelsToFilter}
        selectedEndDate={selectedEndDate}
        selectedStartDate={selectedStartDate}
        handleDateChange={handleDateChange}
        selectedAssetType={selectedAssetType}
        handleAssetTypeChange={handleAssetTypeChange}
      />

      {isLoading ? (
        <></>
      ) : treeMapEmpty ? (
        <span
          style={{
            color: enableDarkTheme
              ? ThemePalette.typography.white
              : ThemePalette.typography.black,
          }}
        >
          Data not present
        </span>
      ) : (
        <Grid
          style={{
            backgroundColor: enableDarkTheme
              ? ThemePalette.dark.boxBackground
              : ThemePalette.light.toolBarBackground,
            paddingLeft: '10px',
            display: 'flex',
            marginLeft: '0px',
            maxWidth: '100%',
          }}
          id="treemap"
          container
          spacing={2}
        >
          <TreemapSection
            dataTreeMap={getTreeMapData(
              assets,
              idleAnalytics,
              selectedAssetType,
              selectedMakeModel,
              isProductivity
            )}
            enableDarkTheme={enableDarkTheme}
            handleFilterUpdate={(e: any) => {
              if (e.name) {
                setSelectedData(e.name);
              }
            }}
            isProductivity={isProductivity}
          />

          <CardSection
            selectedData={selectedData}
            selectedMakeModel={selectedMakeModel}
            selectedDataMetrics={getCardData(
              assets,
              idleAnalytics,
              selectedAssetType,
              selectedMakeModel,
              selectedData,
              isProductivity
            )}
            calculateDaysDifference={calculateDaysDifference}
            enableDarkTheme={enableDarkTheme}
            isProductivity={isProductivity}
          />

          <PiechartSection
            selectedEndDate={selectedEndDate}
            thChartData={
              getPieChartData(
                assets,
                idleAnalytics,
                selectedAssetType,
                selectedMakeModel,
                selectedData,
                selectedStartDate,
                selectedEndDate,
                isProductivity
              ).thChartData
            }
            ehChartData={
              getPieChartData(
                assets,
                idleAnalytics,
                selectedAssetType,
                selectedMakeModel,
                selectedData,
                selectedStartDate,
                selectedEndDate,
                isProductivity
              ).ehChartData
            }
            enableDarkTheme={enableDarkTheme}
            isProductivity={isProductivity}
          />

          <AreachartSection
            selectedEndDate={selectedEndDate}
            filteredAreaChartData={getAreaChartData(
              assets,
              idleAnalytics,
              selectedAssetType,
              selectedMakeModel,
              selectedData,
              isProductivity
            )}
            enableDarkTheme={enableDarkTheme}
            isProductivity={isProductivity}
          />
        </Grid>
      )}
    </>
  );
};
