import { Cancel, RemoveCircle, Error } from '@mui/icons-material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
  Typography,
} from '@mui/material';

import { ThemePalette } from 'mui.theme';

import {
  categoryMapping,
  formatToDollars,
  getMedianWorkOrderAge,
} from '../Function/helper.function';
import { timeAgo } from 'views/FleetOverview/components/AssetCardsV2/utils';
import componentsImage from '../Icons/componenent.png';
import assetImage from '../Icons/vehicle.png';
import { GetIcons } from '../Icons/GetIcons.function';
import LoadingDots from 'views/FleetOverview/components/AssetCardsV2/LoadingDots';

interface Props {
  filteredAssets: any;
  enableDarkTheme: boolean;
  onShowDetails: any;
  detailType: string;
}

export const SurvivalAnalysisCard = ({
  filteredAssets,
  enableDarkTheme,
  onShowDetails,
  detailType,
}: Props) => {
  return (
    <>
      <Grid container spacing={2} sx={{ padding: 2 }}>
        {filteredAssets.length > 0 ? (
          filteredAssets.map((asset: any) => (
            <Grid item xs={12} sm={6} md={4} key={asset.item_id}>
              <Card
                key={asset.item_id}
                className="card"
                sx={{
                  height: '100%',
                  backgroundColor: enableDarkTheme
                    ? ThemePalette.dark.modalBackground
                    : ThemePalette.light.menuOptions,
                  color: enableDarkTheme
                    ? ThemePalette.typography.white
                    : ThemePalette.typography.black,
                  position: 'relative', // Ensure the card is a positioned element
                  padding: 0,
                  gap: 0,
                }}
              >
                <CardContent
                  sx={{
                    padding: 0,
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      gap: 0,
                    }}
                  >
                    {/* firstBox */}
                    <Box sx={{ padding: 2 }}>
                      <GetIcons
                        icon={
                          detailType === 'Assets' ? assetImage : componentsImage
                        }
                        enableDarkTheme={enableDarkTheme}
                      />
                      {detailType === 'Assets' ? (
                        <>
                          <Box>
                            <Typography variant="small">
                              {asset.item_id}
                            </Typography>
                          </Box>
                          <Box display="flex" flexDirection="column" gap={0.5}>
                            <Typography variant="small">
                              Type: {asset.type}
                            </Typography>
                            <Typography variant="small">
                              OEM: {asset.oem}
                            </Typography>
                            <Typography variant="small">
                              Model: {asset.model}
                            </Typography>
                            <Typography variant="small">
                              Last Connected: {timeAgo(asset.lastConnected)}
                            </Typography>
                          </Box>
                        </>
                      ) : (
                        <>
                          <Box>
                            <Typography variant="medium">
                              {asset.item_id}
                            </Typography>
                          </Box>
                          <Box display="flex" flexDirection="column" gap={0.5}>
                            <Typography variant="small">
                              Installed On:{' '}
                              {asset.componentsCategoryAssets.size}
                            </Typography>
                            <Typography variant="small">
                              Median Age:{' '}
                              {getMedianWorkOrderAge(
                                asset.componentsCategoryAssets
                              )}{' '}
                              Days
                            </Typography>
                            <Typography variant="small">
                              System: {asset.components_category}
                            </Typography>
                            <Typography variant="small">
                              Cost: {formatToDollars(asset.cost)}
                            </Typography>
                          </Box>
                        </>
                      )}
                    </Box>

                    {/* secondBox */}
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        minWidth: 250,

                        backgroundColor: enableDarkTheme
                          ? 'rgba(255, 255, 255, 0.1)'
                          : 'rgba(0, 0, 0, 0.05)',
                      }}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'space-evenly',
                          padding: 2,
                          gap: 0,
                        }}
                      >
                        <Box
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'left',
                              justifyContent: 'left',
                            }}
                          >
                            <Cancel
                              style={{ color: categoryMapping.eol.color }}
                            />
                            <Typography fontWeight="bold">EOL</Typography>
                          </Box>
                          <Typography variant="h4" fontWeight="bold">
                            {asset.eol}
                          </Typography>

                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'left',
                              justifyContent: 'left',
                              marginTop: 1,
                            }}
                          >
                            <RemoveCircle
                              style={{ color: categoryMapping.med.color }}
                            />
                            <Typography fontWeight="bold">Med Risk</Typography>
                          </Box>
                          <Typography variant="h4" fontWeight="bold">
                            {asset.med}
                          </Typography>
                        </Box>
                        <Box
                          sx={{
                            textAlign: 'center',
                            marginLeft: 2,
                          }}
                        >
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'left',
                              justifyContent: 'left',
                            }}
                          >
                            <Error
                              style={{ color: categoryMapping.high.color }}
                            />
                            <Typography fontWeight="bold">High Risk</Typography>
                          </Box>
                          <Typography variant="h4" fontWeight="bold">
                            {asset.high}
                          </Typography>

                          <Box
                            sx={{
                              display: 'flex',
                              marginTop: 1,
                            }}
                          >
                            <CheckCircleIcon
                              style={{ color: categoryMapping.low.color }}
                            />
                            <Typography fontWeight="bold">Low Risk</Typography>
                          </Box>
                          <Typography variant="h4" fontWeight="bold">
                            {asset.low}
                          </Typography>
                        </Box>
                      </Box>
                      <Button
                        variant="contained"
                        sx={{
                          marginTop: 2,
                          width: '100%',
                          height: '100%',
                          borderTopLeftRadius: '16px',
                          borderTopRightRadius: '16px',
                          borderBottomLeftRadius: '0px',
                          borderBottomRightRadius: '0px',
                        }}
                        onClick={() => onShowDetails(asset)}
                      >
                        View Details
                      </Button>
                    </Box>
                  </Box>
                </CardContent>
                <CardActions
                  sx={{
                    padding: 0,
                    gap: 0,
                  }}
                ></CardActions>
              </Card>
            </Grid>
          ))
        ) : (
          <Grid item xs={12}>
            <Card
              className="card"
              sx={{
                height: '100%',
                backgroundColor: enableDarkTheme
                  ? ThemePalette.dark.modalBackground
                  : ThemePalette.light.menuOptions,
                color: enableDarkTheme
                  ? ThemePalette.typography.white
                  : ThemePalette.typography.black,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <CardContent>
                <Typography variant="h6">
                  Waiting for Assets{' '}
                  <LoadingDots enableDarkTheme={enableDarkTheme} />
                </Typography>{' '}
              </CardContent>
            </Card>
          </Grid>
        )}
      </Grid>
    </>
  );
};
export default SurvivalAnalysisCard;
