import { SVG } from 'components/Asset/SVG';
import { theme } from 'mui.theme';
import { NameSpan } from './CustomerListCellRenderer.styled';
import { EmailSpan } from 'components/AgGrid/CellRenderer/CellRenderer.styled';
import { type Customer } from 'types/customer.types';
import { CUSTOMER_TYPE_OPTIONS } from 'utils/enums';

const { svgIcons } = theme.palette;

interface Props {
  value: string;
  data: Customer;
}

export const DeleteAccount = () => {
  return (
    <span>
      <SVG
        name="deleteIcon"
        style={{
          ...svgIcons.primary,
        }}
      />
    </span>
  );
};

export const CustomerName = ({ data: { customerName, customerId } }: Props) => {
  return (
    <div>
      <NameSpan>{customerName}</NameSpan>
      <span>
        <SVG
          name="tableView"
          style={{
            ...svgIcons.primary,
            paddingRight: '10px',
          }}
        />
      </span>
      <span>
        <SVG
          name="hierachyView"
          style={{
            ...svgIcons.primary,
          }}
        />
      </span>
    </div>
  );
};

export const PrimaryContact = ({
  data: { contactFirstName, contactLastName, customerId },
}: Props) => {
  return (
    <>
      <EmailSpan>
        <SVG
          name="envelopeIcon"
          style={{
            ...svgIcons.primary,
          }}
        />
      </EmailSpan>
      <span>
        {contactFirstName}, {contactLastName}
      </span>
    </>
  );
};

export const internalAccountingLink = ({
  data: { internalAccountingLink },
}: Props) => {
  return <span>{internalAccountingLink}</span>;
};

export const InternalUsers = ({ data: { internalUsers } }: Props) => {
  if (!internalUsers?.length) return null;

  return (
    <span>
      {internalUsers.map((element, index) => {
        let assignedUsers = element.display;
        const key = element.id;
        // Append comma after each account display, until last item
        if (index + 1 !== internalUsers.length) {
          assignedUsers = `${assignedUsers}, `;
        }
        return <span key={key}>{assignedUsers}</span>;
      })}
    </span>
  );
};

export const CustomerType = ({ data: { customerType } }: Props) => {
  const customerTypeOption = CUSTOMER_TYPE_OPTIONS.find(
    (item) => item.id === customerType
  );
  return <>{customerTypeOption?.display}</>;
};
