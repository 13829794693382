import { createSlice } from '@reduxjs/toolkit';
import type { RuleNotifications } from 'types/notification.types';

export interface NotificationState {
  notifications: RuleNotifications[];
  selectedNotification: RuleNotifications;
}

const initialState: NotificationState = {
  notifications: [],
  selectedNotification: {
    notificationId: '',
    alertId: '',
    customerId: '',
    assetId: '',
    deviceId: '',
    ruleId: '',
    alertCount: 0,
    isEmailSent: false,
    isRead: false,
    isActive: false,
    isDeleted: false,
    alertStartTs: '',
    alertEndTs: null,
    createdAt: '',
    updatedAt: '',
    conditions: [],
    asset: {
      make: '',
      model: '',
      bumperNumber: '',
      assetVin: '',
    },
    rule: {
      ruleName: '',
      notificationLevel: '',
    },
  },
};

export const notificationsSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    setNotifications(state, { payload }) {
      state.notifications = payload;
    },
    removeNotification(state, { payload }) {
      const updatedNotifications = state.notifications.filter((item) => {
        return item.notificationId !== payload.notificationId;
      });
      state.notifications = updatedNotifications;
    },
    removeNotifications(state, { payload }) {
      const updatedNotifications = state.notifications.filter((item) => {
        return !payload.notificationIds.includes(item.notificationId);
      });
      state.notifications = updatedNotifications;
    },
    readNotification(state, { payload }) {
      const updatedNotifications = state.notifications.map((notification) => {
        if (notification.notificationId === payload.notificationId) {
          return {
            ...notification,
            isRead: true,
          };
        }
        return notification;
      });
      state.notifications = updatedNotifications;
    },
    updateNotification(state, { payload }) {
      const updatedNotifications = state.notifications.map((notification) => {
        if (notification.notificationId === payload.notificationId) {
          return {
            ...notification,
            isActive: payload.isActive,
          };
        }
        return notification;
      });
      state.notifications = updatedNotifications;
    },
    setSelectedNotifications(state, { payload }) {
      state.selectedNotification = payload;
    },
    clearSelectedNotifications(state) {
      state.selectedNotification = initialState.selectedNotification;
    },
  },
});

export const {
  setNotifications,
  removeNotification,
  removeNotifications,
  readNotification,
  updateNotification,
  setSelectedNotifications,
  clearSelectedNotifications,
} = notificationsSlice.actions;
export default notificationsSlice.reducer;
