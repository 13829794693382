import { SVG } from 'components/Asset/SVG';
import { theme } from 'mui.theme';
import { type InternalUser } from 'types/user.types';
import { USER_DEPARTMENT_MAP } from 'utils/enums';
import { IdSpan } from 'components/AgGrid/CellRenderer/ListView.styled';
import { StyledIcon } from 'components/AgGrid/CellRenderer/CellRenderer.styled';
export { ResetPassword } from 'components/Buttons/ResetPassword';

const { svgIcons } = theme.palette;

interface Props {
  value: string;
  data: InternalUser & {
    primary_contact: string;
  };
  handleDeleteAccount: (user_id: string) => void;
  handleEditAccount: (user_id: string) => void;
}

export const DeleteAccount = ({
  data: { isActive, userId },
  handleDeleteAccount,
}: Props) => {
  if (!isActive) return null;
  return (
    <span
      onClick={() => {
        handleDeleteAccount(userId);
      }}
    >
      <SVG
        name="deleteIcon"
        style={{
          ...svgIcons?.primary,
        }}
      />
    </span>
  );
};

export const UserName = ({
  handleEditAccount,
  data: { firstName, lastName, userId },
}: Props) => {
  return (
    <IdSpan
      onClick={() => {
        handleEditAccount(userId);
      }}
    >
      {firstName}, {lastName}
    </IdSpan>
  );
};

export const UserStatus = ({ data: { isActive } }: Props) => {
  return (
    <>
      <StyledIcon>
        <SVG
          name={isActive ? 'activeIcon' : 'inActiveIcon'}
          style={{
            ...svgIcons?.primary,
          }}
        />
      </StyledIcon>
      {isActive ? 'Active' : 'Inactive'}
    </>
  );
};

export const Customers = ({ data: { userCustomerAssociations } }: Props) => {
  if (!userCustomerAssociations?.length) return null;
  return (
    <span>
      {userCustomerAssociations.map((element, index) => {
        let accountDisplay = element.display;
        const key = element.id;
        // Append comma after each account display, until last item
        if (index + 1 !== userCustomerAssociations.length) {
          accountDisplay = `${accountDisplay}, `;
        }
        return <span key={key}>{accountDisplay}</span>;
      })}
    </span>
  );
};

export const UserDepartment = ({ data: { department } }: Props) => {
  const userDept = (USER_DEPARTMENT_MAP as any)[department] ?? '';
  return <>{userDept}</>;
};
