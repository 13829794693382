import { Box, Typography } from '@mui/material';
import { ThemePalette } from 'mui.theme';
import { type ChartData } from '../types/chart.type';

interface Props {
  entry: {
    display: string;
    value: string;
    color: string;
    id: string;
  };
  fallback?: JSX.Element;
  filteredChartData: ChartData;
  onClick?: (e: any) => void;
  enableDarkTheme: boolean;
}

export const WithCountLegendFormatter = ({
  entry,
  filteredChartData,
  onClick = () => {},
  enableDarkTheme,
}: Props): JSX.Element => {
  const { display, value, id } = entry;

  /*
   If the entry is not in the filteredChartData, then it is not selected
   and should be faded out. The legend displays this field with opacity 0.5
  */
  const isFiltered = filteredChartData.find((item) => item.id === id);

  return (
    <Box
      onClick={() => {
        onClick({
          payload: {
            id,
          },
        });
      }}
      display="inline-flex"
      justifyContent={'space-between'}
      width="150px"
      sx={{
        textDecoration: isFiltered ? 'none' : 'line-through',
        opacity: isFiltered ? 1 : 0.5,
      }}
    >
      <Typography
        variant="small"
        fontStyle={{
          color: enableDarkTheme
            ? ThemePalette.typography.cyan
            : ThemePalette.typography.black,
        }}
      >
        {display}
      </Typography>
      <Typography
        ml="15px"
        variant="small"
        fontStyle={{
          color: enableDarkTheme
            ? ThemePalette.typography.cyan
            : ThemePalette.typography.black,
        }}
        sx={{
          justifyContent: 'right',
        }}
      >
        {value}
      </Typography>
    </Box>
  );
};
