import { useCallback, useRef, useState } from 'react';

/*
 * useHover hook
 * @returns [ref, isHovering]
 * @example
 * const [ref, isHovering] = useHover();
 * return <div ref={ref}>{isHovering ? '👋' : '👋🏻'}</div>;
 */
export const useHover = () => {
  const [isHovering, setHovered] = useState(false);

  const addHover = useCallback(() => {
    setHovered(true);
  }, []);
  const removeHover = useCallback(() => {
    setHovered(false);
  }, []);

  const componentRef = useRef<HTMLElement>();

  const callbackRef = useCallback(
    (node: any) => {
      if (!componentRef?.current) {
        componentRef.current = node;
      }

      if (!componentRef?.current) return;

      componentRef.current.addEventListener('mouseenter', addHover);
      componentRef.current.addEventListener('mouseleave', removeHover);
    },
    [addHover, removeHover]
  ) as any;

  return [callbackRef, isHovering];
};
