interface Props {
  value: string;
  data: any;
  handleVehicleClick: (
    bumper_id: string,
    start_ts: string,
    dtc_type_2: string
  ) => void;
  handleDTCCodeClick: (dtcCode: string) => void;
}

export const VehicleDisplay = ({
  data: { asset, bumper_id: bumperId, start_ts: startTs, dtc_type_2: dtcType },
  handleVehicleClick,
}: Props) => {
  return <span>{asset}</span>;
};

export const DTCCodeDisplay = ({ data: { dtc_code: dtcCode } }: Props) => {
  return <span>{dtcCode}</span>;
};
