import { createSlice } from '@reduxjs/toolkit';

export interface fuelAnalyticsToolTipState {
  data: any[];
  loadedAverage: number;
  unLoadedAverage: number;
  engineAverages: any[];
}

const initialState: fuelAnalyticsToolTipState = {
  data: [],
  loadedAverage: 0,
  unLoadedAverage: 0,
  engineAverages: [],
};

export const fuelAnalyticsToolTipSlice = createSlice({
  name: 'fuelAnalyticsToolTip',
  initialState,
  reducers: {
    setToolTip(state, { payload }) {
      return {
        ...payload,
      };
    },
  },
});

export const { setToolTip } = fuelAnalyticsToolTipSlice.actions;
export default fuelAnalyticsToolTipSlice.reducer;
