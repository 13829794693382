import { stringContains } from './string.manipulation';

export const mapVehiclesAssets = (vehicleType: string): string => {
  if (
    stringContains('truck', vehicleType) &&
    stringContains('light', vehicleType)
  ) {
    return 'lightTruckImage';
  }

  if (stringContains('loader', vehicleType)) {
    return 'loaderImage';
  }

  if (stringContains('pump', vehicleType)) {
    return 'pumpImage';
  }

  if (
    stringContains('drill', vehicleType) &&
    stringContains('vertical', vehicleType)
  ) {
    return 'verticalDrillImage';
  }

  if (
    stringContains('drill', vehicleType) &&
    stringContains('jumbo', vehicleType)
  ) {
    return 'jumboDrillImage';
  }

  if (stringContains('bolt', vehicleType)) {
    return 'bolterImage';
  }

  if (stringContains('dozer', vehicleType)) {
    return 'dozerImage';
  }
  if (stringContains('grader', vehicleType)) {
    return 'dozerImage';
  }

  if (stringContains('excavator', vehicleType)) {
    return 'excavatorImage';
  }

  if (
    stringContains('truck', vehicleType) &&
    stringContains('haul', vehicleType)
  ) {
    return 'haulTruckImage';
  }

  if (
    stringContains('truck', vehicleType) &&
    stringContains('heavy', vehicleType)
  ) {
    return 'heavyTruckImage';
  }

  if (stringContains('drill', vehicleType)) {
    return 'verticalDrillImage';
  }

  if (stringContains('truck', vehicleType)) {
    return 'lightTruckImage';
  }

  return 'haulTruckImage';
};
