import React, { useRef, useState, type ChangeEvent } from 'react';
import Papa from 'papaparse';
import { Box, Button, Typography } from '@mui/material';
import { Base } from 'components/Containers/BaseContainer';
import SquaresHandler from 'handlers/squaresHandlers/squares.handler';
import {
  FileRequirements,
  SuccessComponent,
  downloadTemplate,
  requiredHeaders,
  ErrorComponent,
} from './FileRequirements';
import HeaderMapping from './HeaderMapping';
import { useNotificationHandler } from '../../Helpers/squareHelper';
import * as XLSX from 'xlsx';
import { ThemePalette } from 'mui.theme';

type DynamicCsvRow = Record<string, string | undefined>;
type DuplicateMappings = Record<string, boolean>;

interface Props {
  switchToHistoryTab: any;
  workOrders: any[];
  enableDarkTheme: boolean;
}

export const UploadWorkOrdersViewModal: React.FC<Props> = ({
  switchToHistoryTab,
  workOrders,
  enableDarkTheme = false,
}) => {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [file, setFile] = useState<File | null>(null);
  type MappedHeaders = Record<string, string>;
  const [csvHeaders, setCsvHeaders] = useState<string[]>([]);
  const [mappedHeaders, setMappedHeaders] = useState<MappedHeaders>({});
  const [duplicateMappings, setDuplicateMappings] = useState<DuplicateMappings>(
    {}
  );
  const [isUploaded, setIsUploaded] = useState(false); // New state to track upload status
  const { handleError } = useNotificationHandler(); // Use the notification handler hook
  const [uploadError, setUploadError] = useState(false);

  type MappedHeadersItems = Record<string, string>;

  const autoMapHeaders = (headers: string[]) => {
    const initialMapping: MappedHeadersItems = {};
    requiredHeaders.forEach((rh) => {
      const matchedHeader = headers.find(
        (h: string) => h.toLowerCase() === rh.name.toLowerCase()
      );
      initialMapping[rh.name] = matchedHeader ?? '';
    });
    setMappedHeaders(initialMapping);
  };

  const handleUploadAndMap = async () => {
    if (!file) {
      handleError('No file selected'); // Use handleError for displaying error messages
      return;
    }
    const fileNamePrefix = file.name.split('.')[0];

    // Ensure all required headers are mapped
    const allHeadersMapped = requiredHeaders.every(
      (rh) =>
        !rh.required ||
        (mappedHeaders[rh.name] && mappedHeaders[rh.name].trim() !== '')
    );

    // Find duplicate mappings
    const headerValues = Object.values(mappedHeaders).filter(
      (value) => value.trim() !== ''
    );
    const hasDuplicates = new Set(headerValues).size !== headerValues.length;

    if (!allHeadersMapped) {
      handleError('Please map all required headers before uploading.');
      return;
    }

    if (hasDuplicates) {
      // Find and mark the duplicates
      const duplicates: DuplicateMappings = headerValues.reduce(
        (acc: DuplicateMappings, header, _, array) => {
          if (array.indexOf(header) !== array.lastIndexOf(header)) {
            acc[header] = true; // Mark this header as a duplicate
          }
          return acc;
        },
        {}
      );

      setDuplicateMappings(duplicates);
      handleError(
        'Duplicate mappings found. Please ensure each header is uniquely mapped.'
      );
      return;
    } else {
      setDuplicateMappings({}); // Clear duplicates if none found
    }
    // Read and parse the file
    Papa.parse(file, {
      header: true,
      complete: async (results) => {
        // Assert that each row is of type DynamicCsvRow
        const oldData: DynamicCsvRow[] = results.data as DynamicCsvRow[];
        const newData = oldData.map((row) => {
          const newRow: DynamicCsvRow = {}; // Declare newRow as DynamicCsvRow
          Object.entries(mappedHeaders).forEach(([newHeader, oldHeader]) => {
            newRow[newHeader] = row[oldHeader] ?? ''; // Map old headers to new headers
          });
          return newRow;
        });

        // Convert the new data back to a CSV string
        const newCsvString = Papa.unparse(newData);

        // Create a Blob from the new CSV string
        const blob = new Blob([newCsvString], {
          type: 'text/csv;charset=utf-8;',
        });

        try {
          const handleUpload = await squaresHandler.uploadWorkOrderFile(
            blob,
            fileNamePrefix
          );
          setIsUploaded(true); // Set the isUploaded state to true on successful upload
          setFile(null); // Clear the selected file after successful upload
          // Handle successful upload (e.g., update state, show notification)

          if (handleUpload) {
            setFile(null); // Clear the selected file after successful upload
          } else {
            throw new Error(handleUpload);
          }
          switchToHistoryTab(); // Switch to the WorkOrdersHistory tab
        } catch (error) {
          const errorMessage =
            error instanceof Error ? error.message : 'Unknown error occurred';
          handleError(errorMessage); // Use handleError for displaying error messages
        }
      },
    });
  };

  const selectFile = (event: ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files ? event.target.files[0] : null;
    if (selectedFile) {
      // Extract the prefix from the selected file name
      const fileNamePrefix = selectedFile.name.split('.')[0];
      const isValid = /^[a-zA-Z0-9_\- ]+$/.test(fileNamePrefix);

      // If the inputString contains special characters or dashes, return false immediately
      if (!isValid) {
        handleError(
          'The file name contains invalid characters. Only alphanumeric characters, dashes and underscore are allowed.'
        );

        return false;
      }
      const fileType = selectedFile.name.split('.').pop()?.toLowerCase();

      // Check if the selected file type is supported
      if (fileType === 'csv' || fileType === 'xlsx' || fileType === 'xls') {
        // Check if the prefix matches any workorder_name prefix in the workOrders
        const isMatchingWorkOrder = checkWorkOrderNameMatch(fileNamePrefix);

        if (!isMatchingWorkOrder) {
          if (fileType === 'csv') {
            handleFileRead(event); // Directly handle CSV files
            setFile(selectedFile);
          } else if (fileType === 'xlsx' || fileType === 'xls') {
            convertXlsxToCsv(selectedFile, (csvFile) => {
              // Simulate a file input event for the CSV file
              const simulatedEvent = {
                target: { files: [csvFile] },
              } as unknown as ChangeEvent<HTMLInputElement>;
              handleFileRead(simulatedEvent);
            });
            setFile(selectedFile);
          }
        } else {
          handleError('Please avoid uploading files with duplicate names.');
        }
      } else {
        handleError('Unsupported file type.');
      }
    }
  };

  function checkWorkOrderNameMatch(inputString: string): boolean {
    // Replace spaces in inputString with +
    const modifiedInputString = inputString.replace(/\s/g, '+');

    for (let i = 0; i < workOrders.length; i++) {
      // Remove the last 18 characters from workorder_name
      const workOrderPrefix = workOrders[i].workorder_name.slice(0, -18);
      if (modifiedInputString === workOrderPrefix) {
        return true; // Match found, return true immediately
      }
    }
    return false; // No match found after checking all workOrders
  }

  const squaresHandler = new SquaresHandler();

  const handleHeaderMappingChange = (
    requiredHeader: string,
    selectedHeader: string
  ) => {
    setMappedHeaders({ ...mappedHeaders, [requiredHeader]: selectedHeader });
  };

  const handleFileRead = (event: ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files ? event.target.files[0] : null;
    setFile(selectedFile);
    if (selectedFile) {
      Papa.parse(selectedFile, {
        complete: (results) => {
          // Assuming results.data is an array of objects, where the keys of the first object are your headers
          const headers = results.data[0] ? Object.keys(results.data[0]) : [];
          // Now 'headers' should be an array of strings (the keys of the first object)
          if (headers.length > 0) {
            setCsvHeaders(headers); // Set the CSV headers
            autoMapHeaders(headers); // Map the headers
          } else {
            // Handle the case where no headers are found or the structure is unexpected
            console.error(
              'No headers found or unexpected data structure:',
              results
            );
          }
        },
        header: true,
      });
    }
  };

  // Function to handle the conversion
  const convertXlsxToCsv = (
    file: File,
    onCsvReady: (csvFile: File) => void
  ) => {
    const reader = new FileReader();
    reader.onload = (e: any) => {
      const data = e.target.result;
      const workbook = XLSX.read(data, { type: 'binary' });
      const firstSheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[firstSheetName];
      const csvOutput = XLSX.utils.sheet_to_csv(worksheet);

      // Create a Blob from the CSV and then a File for Papa.parse
      const csvBlob = new Blob([csvOutput], { type: 'text/csv' });
      const csvFile = new File([csvBlob], file.name.replace(/\..+$/, '.csv'), {
        type: 'text/csv',
      });
      onCsvReady(csvFile);
    };
    reader.readAsBinaryString(file);
  };

  return (
    <Base enableDarkTheme={enableDarkTheme}>
      <Box
        sx={{
          backgroundColor: enableDarkTheme
            ? ThemePalette.dark.pageBackground
            : ThemePalette.light.pageBackground,
          height: '44px',
          opacity: 1,
          paddingLeft: '10px',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Typography
          sx={{
            paddingRight: '5px',
            color: enableDarkTheme
              ? ThemePalette.typography.white
              : ThemePalette.typography.black,
          }}
        >
          Upload Work Order CSV from Local Machine
        </Typography>

        <Button variant="contained" component="label">
          Select File
          <input
            type="file"
            accept=".csv, .xls, .xlsx"
            ref={fileInputRef}
            onChange={selectFile}
            hidden
          />
        </Button>

        {file && (
          <>
            <Typography
              sx={{
                paddingRight: '5px',
                paddingLeft: '5px',
                color: enableDarkTheme
                  ? ThemePalette.typography.white
                  : ThemePalette.typography.black,
              }}
            >
              {file.name}
            </Typography>
          </>
        )}
      </Box>
      <Base enableDarkTheme={enableDarkTheme}>
        {isUploaded && !uploadError ? (
          <SuccessComponent />
        ) : uploadError ? (
          <ErrorComponent />
        ) : (
          <>
            <FileRequirements enableDarkTheme={enableDarkTheme} />{' '}
            <Box
              sx={{
                backgroundColor: enableDarkTheme
                  ? ThemePalette.dark.pageBackground
                  : ThemePalette.light.pageBackground,
                padding: '10px',
                marginTop: '10px',
                display: 'flex',
                justifyContent: 'flex-end',
              }}
            >
              <Button
                variant="contained"
                onClick={downloadTemplate}
                sx={{ backgroundColor: 'button.primary' }}
              >
                Download CSV Template
              </Button>
            </Box>
          </>
        )}

        <>
          {file && (
            <>
              <HeaderMapping
                requiredHeaders={requiredHeaders}
                csvHeaders={csvHeaders}
                mappedHeaders={mappedHeaders}
                duplicateMappings={duplicateMappings}
                handleHeaderMappingChange={handleHeaderMappingChange}
                enableDarkTheme={enableDarkTheme}
              />
              <Box
                sx={{
                  backgroundColor: enableDarkTheme
                    ? ThemePalette.dark.pageBackground
                    : ThemePalette.light.pageBackground,
                  padding: '10px',
                  marginTop: '10px',
                  display: 'flex',
                  justifyContent: 'flex-end',
                }}
              >
                <Button
                  variant="contained"
                  onClick={handleUploadAndMap}
                  sx={{ backgroundColor: 'button.primary' }}
                >
                  Upload File
                </Button>
              </Box>
            </>
          )}
        </>
      </Base>
    </Base>
  );
};
