// create a user slice in src/store/user.slice.ts

import { createSlice } from '@reduxjs/toolkit';
import { type Role } from 'components/Permission/permission.types';

export interface UserState {
  role: null | Role;
  email: string;
  name: string;
  userId: string;
  isAuthenticated: boolean;
  accessToken: string;
  customer: {
    id: string;
    name: string;
    code: string;
    email: string;
    contactFirstName: string;
    contactLastName: string;
    phoneNumber: string;
    internalAccountingLink: string;
    unitSystem: string;
    theme: string;
  };
}

const initialState: UserState = {
  role: null,
  email: '',
  name: '',
  userId: '',
  isAuthenticated: false,
  accessToken: '',
  customer: {
    id: '',
    name: '',
    code: '',
    email: '',
    phoneNumber: '',
    contactFirstName: '',
    contactLastName: '',
    internalAccountingLink: '',
    unitSystem: '',
    theme: 'light',
  },
};

// creaet a user slice
export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    // add reducers here
    updateAuthState(state, { payload } /* : { payload: UserState } */) {
      return {
        ...state,
        ...payload,
      };
    },
    updateCustomer(state, { payload }: { payload: UserState['customer'] }) {
      return {
        ...state,
        customer: payload,
      };
    },
    resetUserState() {
      return initialState;
    },
    //   extraReducers(builder) {},
    updateCustomerTheme(state, { payload }) {
      state.customer.theme = payload.theme;
      return state;
    },
  },
});

export const {
  updateAuthState,
  updateCustomer,
  resetUserState,
  updateCustomerTheme,
} = userSlice.actions;

export default userSlice.reducer;
