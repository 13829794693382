import BaseAPI from './base.api';
import { type RuleNotifications } from 'types/notification.types';

export default class NotificationAPI extends BaseAPI {
  getAllNotifications = async (): Promise<RuleNotifications[]> => {
    const response = await this.API.get('notification');
    return response.data;
  };

  clearNotifications = async (payload: {
    notificationIds: string[];
  }): Promise<RuleNotifications[]> => {
    const response = await this.API.patch('notification/bulk/delete', payload);
    return response.data;
  };

  clearNotification = async (
    notificationId: string
  ): Promise<RuleNotifications[]> => {
    const response = await this.API.patch(
      `notification/${notificationId}/delete`
    );
    return response.data;
  };

  readNotification = async (
    notificationId: string,
    isRead: boolean
  ): Promise<RuleNotifications[]> => {
    const response = await this.API.patch(`notification/${notificationId}`, {
      isRead,
    });
    return response.data;
  };

  updateNotificationStatus = async (
    notificationId: string,
    isActive: boolean
  ): Promise<RuleNotifications[]> => {
    const response = await this.API.patch(`notification/${notificationId}`, {
      isActive,
    });
    return response.data;
  };
}
