import dayjs from 'dayjs';
import { type Asset } from 'types/asset.types';
import { Base } from 'components/Containers/BaseContainer';
import Grid2 from '@mui/material/Unstable_Grid2';
import DatePicker from 'react-datepicker';
import {
  Box,
  Typography,
  Link,
  Tooltip,
  type SelectChangeEvent,
} from '@mui/material';
import { ASSET_TYPES, AVG_VAL, UNITS } from 'utils/enums';
import { useMemo, Fragment, useState, useEffect } from 'react';
import Divider from '@mui/material/Divider';
import { SVG } from 'components/Asset/SVG';
import { Selector } from 'components/Form/Selectors/Selector';
import { useAppSelector } from 'store/hook';
import LineChartComponent from './Charts/LineChart';
import type {
  SelectOptionsWithTagName,
  TGroupedMultiSelectOptions,
} from 'types/utils.types';
import type { IGetSingleDetailTagPayload } from 'types/payloads/tag.payload.types';
import TagHandler from 'handlers/tag.handler';
import { chartSelectorMap, getChartData } from 'utils/helpers/chartData';
import {
  handleSelectAllForGroupedSelector,
  handleSelectSingleForGroupedSelector,
} from 'views/Settings/Rules/helpers/common';
import NoChartData from 'views/FuelAnalytics/NoChart';
import ExportSelector from 'views/FleetOverview/components/FilterViewBar/ExportSelector';
import { useCustomDropdownEffect } from 'utils/exports/hooks';
import {
  chartRange,
  getDateRange,
  getMaxDate,
  hourlyTags,
} from 'utils/charts/tags';
import { GroupedTagMultiSelector } from './Form/Selectors/GroupedTagMultiSelector';
import { ThemePalette } from 'mui.theme';
import { getLabelWrtUnitSystem } from 'utils/helpers/unitSystem';

interface Props {
  asset: Asset;
  defaultTags?: string[];
  defaultTimeRange?: string;
  enableDarkTheme: boolean;
}

/**
 * A bar to show general details about an asset. Used on the Vehicle Status page
 */
export const AssetDetailBar = ({
  asset,
  defaultTags,
  defaultTimeRange = '1D',
  enableDarkTheme,
}: Props) => {
  const unitSystem = useAppSelector((state) => state.persistedReducer).customer
    .unitSystem;
  const tagHandler = new TagHandler();

  const hierarchyInfo = useAppSelector((state) => state.hierarchyReducer);
  const { tagChartData, tagPayload } = useAppSelector(
    (state) => state.tagReducer
  );
  const { isLoading } = useAppSelector((state) => state.appReducer);
  const { latestTagData } = useAppSelector((state) => state.deviceReducer);
  // Memoize the asset type
  // Find the correct name in the ASSET_TYPES enum
  const assetType = useMemo(
    () => ASSET_TYPES.find((e) => e.id === asset.assetType)?.display ?? '',
    [asset]
  );

  const getInitialTags = () => {
    let previouslySelectedTags = [];
    const storageData: any = sessionStorage.getItem(
      'selectedVehicleStatusTags'
    );
    if (storageData) {
      previouslySelectedTags = JSON.parse(storageData);
    }

    if (previouslySelectedTags) {
      const { tags } = previouslySelectedTags;
      const filteredTags = tags?.filter((item: any) => item !== null);
      if (filteredTags?.length > 0) {
        return {
          tags: filteredTags,
        };
      }
    }
    return { tags: [] };
  };

  const [selectedChartSelector, setSelectedChartSelector] =
    useState<string>(defaultTimeRange);
  const [selectedOperation, setSelectedOperation] = useState<string>(
    AVG_VAL[0].id
  );
  const [tagOptions, setTagOptions] = useState<SelectOptionsWithTagName[]>([]);
  const [selectedTags, setSelectedTags] = useState<{
    tags: SelectOptionsWithTagName[];
  }>({
    tags: [],
  });
  const sortedTagOptions = tagOptions
    .slice()
    .sort((a, b) => a.display.localeCompare(b.display));
  const [lineChartUnit, setLineChartUnit] = useState<string>('unit');
  const [chartData, setChartData] = useState([]);
  const [chartDataToDisplay, setChartDisplayToDisplay] = useState<any[]>([]);

  const assetDetails = [
    {
      label: 'Type:',
      value: assetType,
    },
    {
      label: 'Hierarchy Node:',
      /*
        NOTE: This only shows the highest hierarchy node. In the future
        We will have to show children hierarchy nodes by mapping the asset nodeID
        to the hierarchy nodeid/display map (the hierarchy DAG)
      */
      value: hierarchyInfo.nodeDisplay,
    },
    {
      label: 'OEM:',
      value: asset.make,
    },
    {
      label: 'Model:',
      value: asset.model,
    },
    {
      label: 'Status:',
      value: asset.isActive ? 'Operational' : 'Out Of Service',
    },
    {
      label: 'Last Ingested time:',
      value: Array.isArray(latestTagData)
        ? undefined
        : latestTagData[asset.device?.deviceId]?.ingestDate,
    },
  ];

  const { validRange: chartSelectors, allRanges } = chartRange(
    tagChartData,
    selectedChartSelector
  );

  const getTagDetails = async (
    deviceId: string,
    payload: IGetSingleDetailTagPayload,
    tagPayload: Record<string, any>
  ) => {
    await tagHandler.getTagDetail(deviceId, payload, tagPayload, tagChartData);
  };

  useEffect(() => {
    if (asset.device?.tags) {
      const newTagOptions: SelectOptionsWithTagName[] = asset.device.tags.map(
        (tag) => {
          return {
            id: tag.tagName,
            display: tag.tagAlias || tag.tagName,
            tagName: tag.tagName,
          };
        }
      );
      const indexOfObject = newTagOptions.findIndex(
        (obj) => obj.tagName === 'sb_ignition_status'
      );
      const defaultTag =
        indexOfObject !== -1 ? newTagOptions[indexOfObject] : newTagOptions[0];
      setTagOptions(newTagOptions);
      let tagsToDisplay: SelectOptionsWithTagName[] = [defaultTag];
      if (defaultTags) {
        const gdgd = newTagOptions.filter((item: SelectOptionsWithTagName) =>
          defaultTags.includes(item.tagName)
        );
        if (gdgd.length > 0) {
          tagsToDisplay = gdgd;
        }
      }
      const initialTags = getInitialTags();

      if (initialTags?.tags.length > 0) {
        // Confirm tags exist for this current device
        const commonItems = initialTags.tags.filter((item1: any) => {
          return newTagOptions.some((item2) => item2.display === item1.display);
        });
        if (commonItems.length > 0) {
          setSelectedTags({ tags: commonItems });
        } else {
          setSelectedTags({ tags: tagsToDisplay });
        }
      } else {
        setSelectedTags({ tags: tagsToDisplay });
      }
    } else {
      setChartData([]);
      setTagOptions([]);
    }
  }, [asset.device]);
  useEffect(() => {
    if (tagChartData.length) {
      const data = tagChartData
        .map((data: any, index: number) => {
          const isSymStart = data.tagName === 'sym_start';
          return {
            name: data.tagName,
            data: getChartData(
              hourlyTags.includes(selectedChartSelector)
                ? data.tagDetails[0].hourly
                : data.tagDetails[0].daily,
              selectedChartSelector,
              isSymStart,
              data.tagName,
              asset
            ),
            label: getLabelWrtUnitSystem(
              lineChartUnit.split(', ')[index],
              unitSystem
            ),
          };
        })
        .filter((d: { data: string | any[] }) => d.data.length);

      // Use the sort method to order the list based on the oldest object
      // in the inner array length of the 'data' array

      const dataList = data.sort((a: any, b: any) => {
        const timestampA =
          a.length > 0 && a[0].createdAt ? new Date(a[0].createdAt) : Infinity;
        const timestampB =
          b.length > 0 && b[0].createdAt ? new Date(b[0].createdAt) : Infinity;

        // Compare timestamps of the first elements
        if (timestampA < timestampB) {
          return -1; // If timestampA is older, it should come before timestampB
        }
        if (timestampA > timestampB) {
          return 1; // If timestampA is newer, it should come after timestampB
        }

        // If timestamps are equal, compare based on array length
        return b.length - a.length; // Compare array lengths in descending order
      });

      setChartData(dataList);
    }
  }, [tagChartData]);

  useEffect(() => {
    if (
      chartSelectors.length >= 1 &&
      !chartSelectors.some(
        (item) =>
          JSON.stringify(item.display) === JSON.stringify(selectedChartSelector)
      )
    ) {
      setSelectedChartSelector(chartSelectors[0].display);
    }
  }, [selectedChartSelector, chartSelectors]);

  const [exportDropdownTo, setExportDropdownTo] = useState('');
  const [exportToLabel, setExportToLabel] = useState('Export To');

  const handleExportDropDownChange = (event: SelectChangeEvent) => {
    setExportDropdownTo(event.target.value);
  };
  const [exportTo, setExportTo] = useState(false);
  const handleExportClick = (val: boolean) => {
    setExportTo(val);
  };

  const [selectedStartDate, setStartDate] = useState(
    new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000)
  );
  const [selectedEndDate, setEndDate] = useState(new Date());

  const [useDateFilter, setUseDateFilter] = useState(false);

  const handleDateChange = (range: any) => {
    const [startDate, endDate] = range;
    setStartDate(startDate);
    setEndDate(endDate);
    setUseDateFilter(true);
  };

  useCustomDropdownEffect(
    exportDropdownTo,
    handleExportClick,
    setExportToLabel,
    setExportDropdownTo,
    [exportDropdownTo]
  );

  useEffect(() => {
    if (selectedTags.tags.length) {
      const combinedUnits: string[] = [];
      const tagNames: string[] = [];
      selectedTags.tags.map((st) => {
        const selectedTag = asset.device.tags.find(
          (tg) => tg.tagName === st.tagName
        );
        if (selectedTag) {
          combinedUnits.push(selectedTag.unit);
          tagNames.push(selectedTag.tagName);
        }
        return st;
      });
      setLineChartUnit(combinedUnits.join(', '));
      setChartData([]);

      void getTagDetails(
        asset.device.deviceId,
        {
          timeFrame: selectedChartSelector,
          tagNames,
        },
        tagPayload
      );
    }
  }, [selectedTags.tags, selectedChartSelector, selectedOperation]);

  useEffect(() => {
    const { startDate, endDate } = getDateRange(selectedChartSelector);

    setStartDate(startDate);
    setEndDate(endDate);
    setUseDateFilter(false);
  }, [selectedChartSelector]);

  useEffect(() => {
    const initialState = [...chartData];
    if (selectedStartDate && selectedEndDate) {
      const unixStartTime = dayjs(
        new Date(selectedStartDate).setHours(0, 0, 0, 0)
      ).valueOf();
      const unixEndTime = dayjs(
        new Date(selectedEndDate).setHours(23, 59, 59, 999)
      ).valueOf();

      const datePickerFilteredData = initialState.map((cd: any) => {
        const updatedData = cd.data.filter(
          (item: any) => item.ts >= unixStartTime && item.ts <= unixEndTime
        );
        return { ...cd, data: updatedData };
      });

      setChartDisplayToDisplay(datePickerFilteredData);
    }
  }, [selectedStartDate, selectedEndDate, chartData]);

  return (
    <>
      <Base
        enableDarkTheme={enableDarkTheme}
        sx={{
          padding: '40px',
          display: 'flex',
        }}
      >
        <Grid2
          container
          spacing={4}
          sx={{
            width: '100%',
            justifyContent: 'space-between',
            height: '100%',
          }}
        >
          {/* Render the asset identifier */}
          <Grid2
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            {/* This will have the image of the asset */}
            <SVG name="envelopeIcon" />

            <Typography
              sx={{
                marginLeft: '1rem',
                color: enableDarkTheme
                  ? ThemePalette.typography.white
                  : ThemePalette.typography.black,
              }}
            >
              <b>Bumper ID:</b> {asset.bumperNumber}
            </Typography>
          </Grid2>

          <Grid2 padding={0}>
            <Divider
              orientation="vertical"
              sx={{
                padding: 0,
                background: ThemePalette.border,
              }}
            />
          </Grid2>
          {/* Render asset details */}
          {assetDetails.map((data, index, arr) => (
            <Fragment key={`${data.label}-group`}>
              <Grid2 key={`${data.label}-1`}>
                <Typography
                  sx={{
                    color: enableDarkTheme
                      ? ThemePalette.typography.white
                      : ThemePalette.typography.black,
                  }}
                >
                  <b>{data.label}</b> {data.value}
                </Typography>
              </Grid2>
              <Grid2 padding={0}>
                {index < arr.length - 1 && (
                  <Divider
                    key={`${data.label}-divider`}
                    orientation="vertical"
                    sx={{
                      background: ThemePalette.border,
                    }}
                  />
                )}
              </Grid2>
            </Fragment>
          ))}
        </Grid2>
      </Base>
      <Box
        sx={{
          paddingTop: '10px',
        }}
      >
        <Box
          sx={{
            backgroundColor: enableDarkTheme
              ? ThemePalette.dark.toolBarBackground
              : ThemePalette.light.toolBarBackground,
            height: '44px',
            opacity: 1,
            paddingLeft: '10px',
            display: 'flex',
            alignItems: 'center',
            color: enableDarkTheme
              ? ThemePalette.typography.white
              : ThemePalette.typography.black,
          }}
        >
          Raw Sensor
          <Box
            sx={{
              paddingLeft: '10px',
              paddingRight: '10px',
            }}
          >
            {/* <Typography variant="bodyBold" display="block" mb={'4px'}>
              Avg/Max/Min
            </Typography> */}
            <Selector
              minWidth="1px"
              value={selectedOperation}
              onChange={(event) => {
                setSelectedOperation(String(event.target.value));
              }}
              selectorOptions={AVG_VAL}
              enableDarkTheme={enableDarkTheme}
            />
          </Box>
          Readings for
          <Box
            sx={{
              marginTop: '-10px',
              paddingLeft: '10px',
            }}
          >
            <GroupedTagMultiSelector
              disableSelectAll={true}
              value={selectedTags.tags}
              renderValue={(selected) => {
                return `${selected?.length || 0} Tag(s) Selected`;
              }}
              handleSelectOne={(option: TGroupedMultiSelectOptions) => {
                handleSelectSingleForGroupedSelector(
                  option,
                  selectedTags,
                  setSelectedTags,
                  'tags',
                  Infinity,
                  true, // handles cases where we want at least one selected
                  true // handles cases where we want to save this in cache
                );
              }}
              handleSelectAll={(
                options: TGroupedMultiSelectOptions,
                allSelected: boolean
              ) => {
                handleSelectAllForGroupedSelector(
                  options,
                  selectedTags,
                  setSelectedTags,
                  'tags',
                  allSelected,
                  false,
                  true // handles cases where we want to save this in cache
                );
              }}
              selectorOptions={sortedTagOptions}
              enableDarkTheme={enableDarkTheme}
            />
          </Box>
          <Typography
            sx={{
              marginLeft: '20px',
            }}
          >
            Quick Range
          </Typography>
          <Tooltip
            title={
              <>
                <div>
                  Data points are plotted hourly for 1D, 7D and 14D time
                  periods.
                </div>
                <br />
                <div>
                  Data points are plotted daily for 1Y and ALL time periods.
                </div>
              </>
            }
            placement="top"
          >
            <div>
              {' '}
              {/* Wrapping div for Tooltip to work correctly with SVG */}
              <SVG
                name={'infoTooltipIcon'}
                style={{
                  paddingLeft: '5px',
                  alignSelf: 'center',
                  paddingRight: '5px',
                }}
              />
            </div>
          </Tooltip>
          :
          {allRanges.map((range) => {
            return (
              <Typography
                key={range.display}
                sx={{
                  marginLeft: '30px',
                }}
              >
                <Link
                  color={
                    selectedChartSelector === range.display &&
                    chartSelectors.some(
                      (item) => JSON.stringify(item) === JSON.stringify(range)
                    )
                      ? enableDarkTheme
                        ? ThemePalette.typography.cornflowerBlue
                        : '#000000'
                      : enableDarkTheme
                      ? ThemePalette.typography.white
                      : 'button.primary'
                  }
                  sx={
                    !chartSelectors.some(
                      (item) => JSON.stringify(item) === JSON.stringify(range)
                    )
                      ? {
                          pointerEvents: 'none',
                          opacity: '0.5',
                          cursor: 'not-allowed',
                        }
                      : {}
                  }
                  onClick={() => {
                    setSelectedChartSelector(range.display);
                  }}
                >
                  {range.display}
                </Link>
              </Typography>
            );
          })}
          <span
            style={{
              paddingLeft: '30px',
            }}
          >
            <span
              style={{
                paddingRight: '10px',
              }}
            >
              {' '}
              Select Date Range
            </span>
            <DatePicker
              showIcon
              selected={selectedStartDate}
              onChange={handleDateChange}
              startDate={selectedStartDate}
              endDate={selectedEndDate}
              maxDate={new Date(new Date().getTime())}
              minDate={
                new Date(
                  new Date().getTime() -
                    getMaxDate(selectedChartSelector) * 24 * 60 * 60 * 1000
                )
              }
              selectsRange
            />
          </span>
          {isLoading ||
          (chartDataToDisplay && chartDataToDisplay.length < 1) ? null : (
            <ExportSelector
              view={''}
              exportToLabel={exportToLabel}
              exportTo={exportDropdownTo}
              onExportToChange={handleExportDropDownChange}
              enableDarkTheme={enableDarkTheme}
            />
          )}
        </Box>

        <Box
          sx={{
            backgroundColor: enableDarkTheme
              ? ThemePalette.dark.boxBackground
              : ThemePalette.light.boxBackground,
            height: '700px',
            paddingLeft: '10px',
            paddingTop: '40px',
          }}
        >
          {isLoading ? (
            <></>
          ) : chartDataToDisplay && chartDataToDisplay.length < 1 ? (
            <NoChartData
              message={`No data available for ${
                chartSelectorMap[selectedChartSelector] || 'ALL'
              } day${
                chartSelectorMap[selectedChartSelector] === 1 ? '' : 's'
              } time range`}
              enableDarkTheme={enableDarkTheme}
            />
          ) : (
            <LineChartComponent
              xAxis="ts"
              data={useDateFilter ? chartDataToDisplay : chartData}
              exportTo={exportTo}
              handleExport={handleExportClick}
              assetBumperNumber={asset.bumperNumber}
              selectedRange={selectedChartSelector}
              device={asset.device.deviceId}
              avgVal={selectedOperation}
              enableDarkTheme={enableDarkTheme}
            />
          )}
        </Box>
      </Box>
    </>
  );
};
