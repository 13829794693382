import { Box, Typography } from '@mui/material';
import { Selector } from 'components/Form/Selectors/Selector';
import { InteractiveModal } from 'components/Modals/InteractiveModal/BaseModal';
import DeviceHandler from 'handlers/device.handler';
import { useState } from 'react';
import { useAppSelector } from 'store/hook';
import { DEVICE_ORIENTATION } from 'utils/enums';

interface Props {
  /**
   * Title of the Modal
   */
  title: string;
  /**
   * Boolean Value to determine if modal is displpayed or not
   */
  open: boolean;
  /**
   * Asset Data modal is to render
   */
  assetId: string;
  /**
   * Function to call to close modal
   */
  handleAbort: () => void;
}

export const DeviceLinkModal = ({
  title,
  handleAbort,
  open,
  assetId,
}: Props) => {
  const deviceHandler = new DeviceHandler();

  const unassociatedDevices = useAppSelector(
    (state) => state.deviceReducer.unassociatedDevices
  ).map((device) => ({
    id: device.deviceId,
    display: device.deviceId,
  }));

  const [device, setDevice] = useState<{
    yOrientation: string;
    xOrientation: string;
    deviceId: string | undefined;
  }>({
    yOrientation: DEVICE_ORIENTATION[0].id,
    xOrientation: DEVICE_ORIENTATION[0].id,
    deviceId: undefined,
  });

  const handleSubmit = async () => {
    if (!device.deviceId) return;

    await deviceHandler.updateDevice(device.deviceId, {
      assetId,
      ...device,
    });

    handleAbort();
  };

  /**
   * This is the form that will be rendered in the modal
   */
  return (
    <InteractiveModal
      initialState={open}
      SuccessButtonProps={{
        disabled: !device.deviceId,
        onClick: handleSubmit,
        label: 'LINK DEVICE',
      }}
      handleAbort={handleAbort}
      title={title}
      confirmCancellation
    >
      {/* Asset ID */}
      <Box key="assetId">
        <Typography variant="bodyBold" display="block" mb={'4px'}>
          Asset ID
        </Typography>
        <Typography key="blurb-1" variant="medium">
          {assetId}
        </Typography>
      </Box>
      <Box key="deviceId">
        <Typography variant="bodyBold" display="block" mb={'4px'}>
          Device ID
        </Typography>
        <Selector
          selectSx={{
            width: '270px',
          }}
          value={device.deviceId ?? ''}
          onChange={(event) => {
            const selectedValue = event.target.value as string;
            setDevice({
              ...device,
              deviceId: selectedValue,
            });
          }}
          selectorOptions={unassociatedDevices}
        />
      </Box>

      {/* Device X-Orientation* */}
      <Box key="xOrientation">
        <Typography variant="bodyBold" display="block" mb={'4px'}>
          Device X-Orientation*
        </Typography>
        <Selector
          value={device.xOrientation}
          onChange={(event) => {
            const selectedValue = event.target.value as string;
            setDevice({
              ...device,
              xOrientation: selectedValue,
            });
          }}
          selectorOptions={DEVICE_ORIENTATION}
        />
      </Box>

      {/* Device Y-Orientation* */}
      <Box key="yOrientation">
        <Typography variant="bodyBold" display="block" mb={'4px'}>
          Device Y-Orientation*
        </Typography>
        <Selector
          value={device.yOrientation}
          onChange={(event) => {
            const selectedValue = event.target.value as string;
            setDevice({
              ...device,
              yOrientation: selectedValue,
            });
          }}
          selectorOptions={DEVICE_ORIENTATION}
        />
      </Box>
    </InteractiveModal>
  );
};
