import { updateLoadingState } from 'store/app.slice';
import { useAppDispatch } from 'store/hook';
import { showNotification } from 'store/notification.slice';

export abstract class BaseHandler {
  protected readonly dispatch: any;

  constructor() {
    this.dispatch = useAppDispatch();
  }

  /*
    turn off loading state and show success notification
  */
  protected handleSuccess = (message?: string): void => {
    this.dispatch(updateLoadingState(false));

    // handle success silently if no message is provided
    if (!message) return;

    this.dispatch(
      showNotification({
        message,
        type: 'success',
      })
    );
  };

  /*
     turn off loading state and show error notification
   */
  protected handleError = (message: string): void => {
    this.dispatch(updateLoadingState(false));
    this.dispatch(
      showNotification({
        message,
        type: 'error',
      })
    );
  };
}
