import { createSlice } from '@reduxjs/toolkit';
import type { ResponsePrice } from 'types/fuelPrice.types';

export interface fuelPriceState {
  data: ResponsePrice;
  isLoading: boolean;
}

const initialState: fuelPriceState = {
  data: {},
  isLoading: false,
};

export const fuelPriceSlice = createSlice({
  name: 'fuelPrice',
  initialState,
  reducers: {
    getAll(state, { payload }) {
      return {
        data: payload,
        isLoading: false,
      };
    },
    setIsLoading(state, { payload }) {
      state.isLoading = payload;
    },
  },
});

export const { getAll, setIsLoading } = fuelPriceSlice.actions;
export default fuelPriceSlice.reducer;
