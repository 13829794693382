import React from 'react';
import { Box, Typography, Select, MenuItem, Tooltip } from '@mui/material';
import { SVG } from 'components/Asset/SVG';
import { ThemePalette, theme } from 'mui.theme';
import { type RequiredHeader } from './FileRequirements';

const { svgIcons } = theme.palette;

interface HeaderMappingProps {
  requiredHeaders: RequiredHeader[];
  csvHeaders: string[];
  mappedHeaders: Record<string, string>;
  duplicateMappings: Record<string, boolean>;
  enableDarkTheme: boolean;
  handleHeaderMappingChange: (
    requiredHeader: string,
    selectedHeader: string
  ) => void;
}

const HeaderMapping: React.FC<HeaderMappingProps> = ({
  requiredHeaders,
  csvHeaders,
  mappedHeaders,
  duplicateMappings,
  enableDarkTheme,
  handleHeaderMappingChange,
}) => {
  return (
    <Box
      sx={{
        backgroundColor: enableDarkTheme
          ? ThemePalette.dark.pageBackground
          : ThemePalette.light.pageBackground,
        padding: '10px',
        marginTop: '10px',
        marginBottom: '30px',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Typography
        variant="h6"
        sx={{
          marginBottom: '10px',
          display: 'block',
          color: enableDarkTheme
            ? ThemePalette.typography.white
            : ThemePalette.typography.black,
        }}
      >
        Map CSV Headers
      </Typography>
      {requiredHeaders.map((header, index) => (
        <Box
          key={header.name}
          sx={{
            display: 'flex',
            alignItems: 'center',
            marginBottom: '10px',
          }}
        >
          <Typography
            sx={{
              minWidth: 250,
              marginRight: '30px',
              color: enableDarkTheme
                ? ThemePalette.typography.white
                : ThemePalette.typography.black,
            }}
          >
            {header.name}
          </Typography>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Select
              labelId={`label-${index}`}
              id={`select-${index}`}
              style={{ marginRight: '5px' }}
              value={mappedHeaders[header.name] || ''}
              onChange={(e) => {
                handleHeaderMappingChange(header.name, e.target.value);
              }}
              displayEmpty
              sx={{
                color: enableDarkTheme
                  ? ThemePalette.typography.lightGrey
                  : ThemePalette.typography.black,
                background: enableDarkTheme
                  ? ThemePalette.dark.menuOptions
                  : ThemePalette.light.menuOptions,
              }}
            >
              <MenuItem
                value=""
                sx={{
                  color: enableDarkTheme
                    ? ThemePalette.typography.lightGrey
                    : ThemePalette.typography.black,
                  background: enableDarkTheme
                    ? ThemePalette.dark.menuOptions
                    : ThemePalette.light.menuOptions,
                }}
              >
                <em>Select a Header</em>
              </MenuItem>
              {csvHeaders.map((csvHeader) => (
                <MenuItem
                  key={csvHeader}
                  value={csvHeader}
                  sx={{
                    color: enableDarkTheme
                      ? ThemePalette.typography.lightGrey
                      : ThemePalette.typography.black,
                    background: enableDarkTheme
                      ? ThemePalette.dark.menuOptions
                      : ThemePalette.light.menuOptions,
                  }}
                >
                  {csvHeader}
                </MenuItem>
              ))}
            </Select>
            {header.required && (
              <Tooltip title="Required" placement="top" arrow>
                <div>
                  {' '}
                  {/* Wrapping div for Tooltip to work correctly with SVG */}
                  <SVG
                    name="redIcon"
                    style={{
                      ...svgIcons?.primary,
                      display:
                        mappedHeaders[header.name] === '' ||
                        duplicateMappings[mappedHeaders[header.name]]
                          ? 'inline'
                          : 'none',
                    }}
                  />
                </div>
              </Tooltip>
            )}
          </Box>
        </Box>
      ))}
    </Box>
  );
};

export default HeaderMapping;
