import { Typography } from '@mui/material';
import { InteractiveModal } from 'components/Modals/InteractiveModal/BaseModal';
import DeviceHandler from 'handlers/device.handler';
import { type Device } from 'types/device.types';

export {};

/**
 *
 * The delete modal is a modal that is used to delete an Asset.
 */

interface Props {
  open: boolean;
  deviceData: Device;
  handleAbort: () => void;
}

export const DeviceUnlinkModal = ({ open, deviceData, handleAbort }: Props) => {
  const deviceHandler = new DeviceHandler();
  const title = 'Confirm Device Unlinking';
  return (
    <InteractiveModal
      initialState={open}
      title={title}
      handleAbort={handleAbort}
      SuccessButtonProps={{
        onClick: async () => {
          await deviceHandler.updateDevice(deviceData.deviceId, {
            assetId: null,
          });
          handleAbort();
        },
        disabled: false,
        label: 'Confirm Unlinking',
      }}
      ModalContentProps={{
        gridItemProps: {
          lg: 12,
        },
      }}
    >
      <Typography key="blurb-1" variant="medium">
        Are you sure you want to unlink {deviceData.deviceId} from asset #
        {deviceData.assetId}
      </Typography>
      <Typography key="blurb-2" variant="medium"></Typography>
    </InteractiveModal>
  );
};
