import { type Role } from 'components/Permission/permission.types';
import { appConfiguration } from 'utils/config/app.config';

// returns true if role is internal
export const isInternalUserOrDirector = (role: Role | null) => {
  if (!role) return false;

  const internalUserRoles = ['director', 'internal-user'];

  if (appConfiguration.isDevelopment || appConfiguration.isStaging) {
    internalUserRoles.push('developer');
  }

  return internalUserRoles.includes(role);
};

export const isInternalUser = (role: Role | null) => {
  if (!role) return false;

  return role === 'internal-user';
};

// returns true if role is external
export const isExternalUserOrAdmin = (role: Role) => {
  const externalUserRoles = ['external-admin', 'external-user'];

  return externalUserRoles.includes(role);
};

export const isExternalUser = (role: Role | null) => {
  return (role && role === 'external-user') ?? true;
};
