import { ServiceTimeline } from 'views/SurvivalAnalysis/Helper/Modal/ServiceTimeline';
import { ThemePalette } from 'mui.theme';
import { Typography } from '@mui/material';
import {
  type EolDates,
  type AssetsOverview,
} from 'types/survivalAnalysis.types';
import { transformWorkOrdersToTimeLine } from 'views/SurvivalAnalysis/Helper/Function/helper.function';
import { useAppSelector } from 'store/hook';

export const OverviewModalSelector = ({
  enableDarkTheme,
  allAssets,
  onOpenInNewClick,
  eolDates,
}: {
  enableDarkTheme: boolean;
  allAssets: AssetsOverview[];
  eolDates: EolDates[];

  onOpenInNewClick: (itemId: string) => void;
}) => {
  const { assets } = useAppSelector((state) => state.assetReducer);

  const services = transformWorkOrdersToTimeLine(allAssets, assets, eolDates);
  return (
    <>
      <div className="overview-container">
        <Typography
          variant="h5"
          fontStyle={{
            color: enableDarkTheme
              ? ThemePalette.typography.white
              : ThemePalette.typography.black,
          }}
        >
          Service Timeline
        </Typography>
      </div>
      <ServiceTimeline
        services={services}
        enableDarkTheme={enableDarkTheme}
        onOpenInNewClick={onOpenInNewClick}
      />
    </>
  );
};
