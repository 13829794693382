import * as yup from 'yup';

/**
 * 
[A-Za-z0-9._%+-]+ - Must be one or more alphanumeric characters, dots, underscores, percent signs, plus signs, or hyphens, which form the local part of the email address.
@ - Matches the at symbol.
[A-Za-z0-9.-]+ - the domain name part of the email address. Must be one or more alphanumeric characters, dots, or hyphens, 
\.[A-Za-z]{2,} - the top-level domain (TLD) part of the email has to be preceeded by a dot and must two or more alphabetic characters
$ - End of the string.
 *
 * @returns 
 */
export const emailValidation = () =>
  yup
    .string()
    .email()
    .matches(
      /^[A-Za-z0-9._-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/,
      'Invalid email address.'
    )
    .required();

/**
 *
 * @returns phone number with acceptable formats, a minimum of 6 numbers.
 * Accepts international code and number formats
 */

export const phoneNumberValidation = () =>
  yup
    .string()
    .matches(
      /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/,
      'Invalid Phone Number'
    )
    .required();

/**
 * only accepts alphabets that are more than 1 in length, with no digits or special chrecters
 * @returns
 */
export const nameValidation = () =>
  yup
    .string()
    .matches(/^[a-zA-Z ]*$/, 'Must be alphabets only')
    .min(1)
    .required();

/**
 * Only accepts small case alphabets with length=3, with no digits or special characters.
 * @returns {yup.StringSchema<string>}
 */
export const codeValidation = () =>
  yup
    .string()
    .matches(/^[a-z]*$/i, 'Must be alphabets only')
    .min(3, 'Must be exactly 3 characters')
    .required('Required field');
