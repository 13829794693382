import AssetAPI from 'api/asset.api';
import OperatorAPI from 'api/operator.api';
import { BaseHandler } from '../base.handler';
import { setOperators } from 'store/operator.slice';
import { setAssets } from 'store/asset.slice';

export default class HandlerUtils extends BaseHandler {
  private readonly operatorApi: OperatorAPI;
  private readonly assetApi: AssetAPI;

  constructor() {
    super();
    this.operatorApi = new OperatorAPI();
    this.assetApi = new AssetAPI();
  }

  async updateAssets() {
    const assets = await this.assetApi.getAll();
    this.dispatch(setAssets(assets));
  }

  async updateOperators() {
    const operators = await this.operatorApi.getAll();
    this.dispatch(setOperators(operators));
  }
}
