import { createSlice } from '@reduxjs/toolkit';

export interface HierarchyState {
  nodeId: string;
  nodeDisplay: string;
}

const initialState: HierarchyState = {
  nodeId: '',
  nodeDisplay: '',
};

export const hierarchySlice = createSlice({
  name: 'hierarchy',
  initialState,
  reducers: {
    setAssignedHierarchyNode(state, { payload }: { payload: HierarchyState }) {
      return {
        ...payload,
      };
    },
  },
});

export const { setAssignedHierarchyNode } = hierarchySlice.actions;
export default hierarchySlice.reducer;
