import { Grid } from '@mui/material';
import { useAppSelector } from 'store/hook';
import { selectAssetByMakeModel } from 'store/asset.slice';
import LoadedFuelEfficiency from '../components/FuelEfficiency/LoadedFuelEfficiency';
import UnLoadedFuelEfficiency from '../components/FuelEfficiency/UnLoadedFuelEfficiency';
import FuelIdleConsumption from '../components/FuelConsumption/FuelIdleConsumption';
import FuelNoneIdleConsumption from '../components/FuelConsumption/FuelNoneIdleConsumption';
import FuelConsumptionRange from '../components/FuelConsumptionRange/FuelConsumptionRange';
import { selectExportFormat } from 'store/exportData.slice';
import { formatDate } from 'views/Settings/ExternalUsers/Squares/Helpers/prepareFile';
import { formatDateToString } from 'utils/helpers/dateTime';
import { useEffect } from 'react';
import { exportToPdf } from 'utils/exports/pdf';
import { chartToExcelForUsageDashboard } from 'utils/exports/excel';
import type { ResponsePrice } from 'types/fuelPrice.types';

interface Props {
  makeModel: string[];
  showConsumptionRange?: boolean;
  exportTo?: boolean;
  handleExport: (val: boolean) => void;
  selectedEndDate?: Date;
  selectedStartDate?: Date;
  selectedDuration?: string;
  selectedConsumptionRange: string;
  setSelectedConsumptionRange: any;
  fuelPriceData: ResponsePrice;
  enableDarkTheme: boolean;
  startDateFilter: Date;
  endDateFilter: Date;
  selectedDevice: string | undefined;
}

export const HaulTruckView = ({
  makeModel,
  showConsumptionRange = false,
  exportTo,
  handleExport,
  selectedEndDate,
  selectedStartDate,
  selectedDuration,
  selectedConsumptionRange,
  setSelectedConsumptionRange,
  fuelPriceData,
  enableDarkTheme = false,
  startDateFilter,
  endDateFilter,
  selectedDevice,
}: Props) => {
  const unitSystem = useAppSelector((state) => state.persistedReducer).customer
    .unitSystem;
  const assetMakeModelList = useAppSelector(selectAssetByMakeModel);
  const fuelAnalytics = useAppSelector((state) => state.fuelAnalyticsReducer);
  const { isLoading } = useAppSelector((state) => state.appReducer);
  const fuelAnalyticsToolTip = useAppSelector(
    (state) => state.fuelAnalyticsToolTipReducer
  );

  const makeModelsString = makeModel[0];
  let assetsList: any[] = [];

  makeModel.map((makeModel) => {
    assetsList = [...assetsList, ...assetMakeModelList[makeModel]];
    return makeModel;
  });

  const exportFormat = useAppSelector(selectExportFormat);

  const fileNameWithDateTime = selectedDuration
    ? // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      `historical-usage-dashboard-${makeModelsString}-${formatDate(new Date())}`
    : // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
      `usage-dashboard-${makeModelsString}-${formatDate(new Date())}`;

  const title =
    selectedStartDate && selectedEndDate
      ? `Usage Dashboard for ${makeModelsString
          .split('!')
          .join(' ')
          .toUpperCase()} - (${formatDateToString(
          selectedStartDate
        )} - ${formatDateToString(selectedEndDate)})`
      : selectedDuration
      ? `Historical Usage Dashboard for ${makeModelsString
          .split('!')
          .join(' ')
          .toUpperCase()} - for the past ${
          selectedDuration.charAt(0).toUpperCase() + selectedDuration.slice(1)
        }`
      : `Dashboard for ${makeModelsString.split('!').join(' ').toUpperCase()}`;

  useEffect(() => {
    const fetchData = async () => {
      if (exportTo && exportFormat === 'pdf') {
        await exportToPdf(
          'usage-dashboard-haul-truck',
          `${fileNameWithDateTime}`,
          title,
          enableDarkTheme
        );
        handleExport(false);
      }
      if (exportTo && exportFormat === 'excel') {
        chartToExcelForUsageDashboard(
          fuelAnalytics,
          fileNameWithDateTime,
          `${title}`,
          !!selectedDuration,
          unitSystem
        );
        handleExport(false);
      }
    };

    void fetchData();
  }, [exportTo, handleExport, exportFormat]);

  return (
    <Grid id="usage-dashboard-haul-truck" container>
      <Grid item lg={6} md={12} sm={12} xs={12}>
        <LoadedFuelEfficiency
          assets={assetsList}
          fuelEfficiency={fuelAnalytics.fuelLoadedUnloadedAnalytics}
          isLoading={isLoading}
          toolTipData={fuelAnalyticsToolTip}
          enableDarkTheme={enableDarkTheme}
          selectedDevice={selectedDevice}
        />
      </Grid>
      <Grid item lg={6} md={12} sm={12} xs={12}>
        <UnLoadedFuelEfficiency
          assets={assetsList}
          fuelEfficiency={fuelAnalytics.fuelLoadedUnloadedAnalytics}
          isLoading={isLoading}
          toolTipData={fuelAnalyticsToolTip}
          enableDarkTheme={enableDarkTheme}
          selectedDevice={selectedDevice}
        />
      </Grid>
      <Grid item lg={6} md={12} sm={12} xs={12}>
        <FuelIdleConsumption
          assets={assetsList}
          fuelIdleEvents={fuelAnalytics.fuelIdleEvents}
          isLoading={isLoading}
          enableDarkTheme={enableDarkTheme}
          selectedDevice={selectedDevice}
        />
      </Grid>
      <Grid item lg={6} md={12} sm={12} xs={12}>
        <FuelNoneIdleConsumption
          assets={assetsList}
          fuelNoneIdleEvents={fuelAnalytics.fuelNoneIdleEvents}
          isLoading={isLoading}
          enableDarkTheme={enableDarkTheme}
          selectedDevice={selectedDevice}
        />
      </Grid>
      {showConsumptionRange ? (
        <Grid item lg={12} md={12} sm={12} xs={12}>
          <FuelConsumptionRange
            data={fuelAnalytics.fuelConsumptionRangeAnalytics}
            isLoading={isLoading}
            assetMakeModel={makeModelsString}
            selectedConsumptionRange={selectedConsumptionRange}
            setSelectedConsumptionRange={setSelectedConsumptionRange}
            fuelPriceData={fuelPriceData}
            enableDarkTheme={enableDarkTheme}
          />
        </Grid>
      ) : null}
    </Grid>
  );
};
