import { type TagAlertDetails, type ITag } from 'types/tag.type';
import { type IGetSingleDetailTagPayload } from 'types/payloads/tag.payload.types';
import BaseAPI from './base.api';

export default class TagAPI extends BaseAPI {
  get = async () => {
    const response = await this.API.get(`tag`);
    return response.data;
  };

  update = async ({ updatedTags }: { updatedTags: ITag[] }) => {
    const response = await this.API.patch('tag', {
      updatedTags,
    });
    return response.data;
  };

  getSingleTagDetails = async (
    tagId: string, // this is actually deviceId
    payload: IGetSingleDetailTagPayload
  ) => {
    const response = await this.API.post(`tag/${tagId}`, payload);
    return response.data;
  };

  getNotificationTagDetails = async ({
    deviceId,
    notificationTime,
    tagDetails,
  }: TagAlertDetails) => {
    const response = await this.API.post(`tag/notification-tags/${deviceId}`, {
      dateTime: notificationTime,
      tagDetails,
    });
    return response.data;
  };
}
