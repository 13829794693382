import { Typography, RadioGroup, Radio, FormControlLabel } from '@mui/material';
import { useAppSelector } from 'store/hook';
import { Selector } from 'components/Form/Selectors/Selector';
import { ToolbarContainer } from 'components/Containers/ToolbarContainer';
import { isDarkTheme } from 'utils/theme';
import { ThemePalette } from 'mui.theme';
import DatePicker from 'react-datepicker';
import { type SelectOptions } from 'types/utils.types';

export const FilterHeader = ({
  selectedMakeModel,
  setSelectedMakeModel,
  setSelectedData,
  customerCode,
  assetOptions,
  klgMakeModelsToFilter,
  gbsMakeModelsToFilter,
  selectedEndDate,
  selectedStartDate,
  handleDateChange,
  handleAssetTypeChange,
  selectedAssetType,
}: {
  selectedMakeModel: string;
  setSelectedMakeModel: any;
  setSelectedData: any;
  customerCode: string;
  assetOptions: SelectOptions[];
  klgMakeModelsToFilter: string[];
  gbsMakeModelsToFilter: string[];
  selectedEndDate: Date;
  selectedStartDate: Date;
  handleDateChange: any;
  handleAssetTypeChange: any;
  selectedAssetType: string;
}) => {
  const theme = useAppSelector((state) => state.authReducer).customer.theme;
  const enableDarkTheme = isDarkTheme(theme);

  return (
    <ToolbarContainer enableDarkTheme={enableDarkTheme}>
      <RadioGroup
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group"
        value={selectedAssetType}
        onChange={handleAssetTypeChange}
      >
        <FormControlLabel
          value="total"
          control={
            <Radio
              sx={{
                color: enableDarkTheme
                  ? ThemePalette.typography.cornflowerBlue
                  : ThemePalette.typography.primary,
                '&.Mui-checked': {
                  color: enableDarkTheme
                    ? ThemePalette.typography.cornflowerBlue
                    : ThemePalette.typography.primary,
                },
              }}
            />
          }
          label="Total Fleet"
          sx={{
            color: enableDarkTheme
              ? ThemePalette.typography.white
              : ThemePalette.typography.black,
          }}
        />
        <FormControlLabel
          value="Haul Truck"
          control={
            <Radio
              sx={{
                color: enableDarkTheme
                  ? ThemePalette.typography.cornflowerBlue
                  : ThemePalette.typography.primary,
                '&.Mui-checked': {
                  color: enableDarkTheme
                    ? ThemePalette.typography.cornflowerBlue
                    : ThemePalette.typography.primary,
                },
              }}
            />
          }
          label="Haulers"
          sx={{
            color: enableDarkTheme
              ? ThemePalette.typography.white
              : ThemePalette.typography.black,
          }}
        />
        <FormControlLabel
          value="Loader"
          control={
            <Radio
              sx={{
                color: enableDarkTheme
                  ? ThemePalette.typography.cornflowerBlue
                  : ThemePalette.typography.primary,
                '&.Mui-checked': {
                  color: enableDarkTheme
                    ? ThemePalette.typography.cornflowerBlue
                    : ThemePalette.typography.primary,
                },
              }}
            />
          }
          label="Loaders"
          sx={{
            color: enableDarkTheme
              ? ThemePalette.typography.white
              : ThemePalette.typography.black,
          }}
        />
        <FormControlLabel
          value="Others"
          control={
            <Radio
              sx={{
                color: enableDarkTheme
                  ? ThemePalette.typography.cornflowerBlue
                  : ThemePalette.typography.primary,
                '&.Mui-checked': {
                  color: enableDarkTheme
                    ? ThemePalette.typography.cornflowerBlue
                    : ThemePalette.typography.primary,
                },
              }}
            />
          }
          label="Dozers"
          sx={{
            color: enableDarkTheme
              ? ThemePalette.typography.white
              : ThemePalette.typography.black,
          }}
        />
      </RadioGroup>
      <Typography
        sx={{
          paddingRight: '5px',
          color: enableDarkTheme
            ? ThemePalette.typography.white
            : ThemePalette.typography.black,
        }}
      >
        Displaying Data for
      </Typography>

      <Selector
        value={selectedMakeModel}
        onChange={(event) => {
          const selectedValue = event.target.value as string;
          // fuelAnalyticsHandler.clearFuelUsageAnalytics();
          setSelectedMakeModel(selectedValue);
          setSelectedData(null);
        }}
        selectorOptions={
          customerCode === 'klg'
            ? assetOptions.filter((option) =>
                klgMakeModelsToFilter.includes(option.display.toLowerCase())
              )
            : customerCode === 'gbs'
            ? assetOptions.filter((option) =>
                gbsMakeModelsToFilter.includes(option.display.toLowerCase())
              )
            : assetOptions
        }
        selectSx={{
          ml: '10px',
          flex: 1,
          fontFamily: 'open sans',
          fontSize: '14px',
        }}
        enableDarkTheme={enableDarkTheme}
      />
      <Typography
        sx={{
          marginLeft: '10px',
          marginRight: '10px',
          color: enableDarkTheme
            ? ThemePalette.typography.white
            : ThemePalette.typography.black,
        }}
      >
        For Date Range:
      </Typography>
      <DatePicker
        showIcon
        selected={selectedStartDate}
        onChange={handleDateChange}
        startDate={selectedStartDate}
        endDate={selectedEndDate}
        maxDate={new Date(new Date().getTime())}
        selectsRange
      />
    </ToolbarContainer>
  );
};
