import { Grid } from '@mui/material';
import { ThemePalette } from 'mui.theme';
import { Base } from 'components/Containers/BaseContainer';
import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  AreaChart,
  Area,
  Legend,
} from 'recharts';
import { areaContainerStyle } from 'views/FleetOverview/components/styles';

type AreaChartData = Array<{
  name: string;
  idlingHours: number;
  engineHours: number;
  totalFuelConsumed?: number;
  fuelWasted?: number;
}>;

export const AreachartSection = ({
  selectedEndDate,
  filteredAreaChartData,
  enableDarkTheme = false,
  isProductivity,
}: {
  selectedEndDate: Date;
  filteredAreaChartData: AreaChartData;
  enableDarkTheme: boolean;
  isProductivity: boolean;
}) => {
  return (
    <Grid item lg={6} md={12} sm={12} xs={12}>
      {selectedEndDate !== null && filteredAreaChartData?.length > 0 ? (
        <>
          <Base
            sx={{
              ...areaContainerStyle,
              backgroundColor: enableDarkTheme
                ? ThemePalette.dark.modalBackground
                : '#FFFFFF',
            }}
          >
            <span
              style={{
                padding: '16px',
                marginBottom: '16px',
                color: enableDarkTheme ? ThemePalette.typography.white : 'grey',
                alignContent: 'right',
              }}
            >
              Engine Hours vs {isProductivity ? 'Productive' : 'Idle'} Hours
            </span>

            <AreaChart
              width={window.innerWidth * 0.4}
              height={150}
              data={filteredAreaChartData}
              margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
            >
              <defs>
                <linearGradient id="colorRed" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor="#EB5757" stopOpacity={0.8} />
                  <stop offset="95%" stopColor="#EB5757" stopOpacity={0} />
                </linearGradient>
                <linearGradient id="colorblue" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor="#229161" stopOpacity={0.8} />
                  <stop offset="95%" stopColor="#229161" stopOpacity={0} />
                </linearGradient>
                <linearGradient id="colorPurple" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor="#229161" stopOpacity={0.8} />
                  <stop offset="95%" stopColor="#229161" stopOpacity={0} />
                </linearGradient>
              </defs>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                dataKey="name"
                stroke={
                  enableDarkTheme
                    ? ThemePalette.typography.white
                    : ThemePalette.typography.black
                }
              />
              <YAxis
                type="number"
                stroke={
                  enableDarkTheme
                    ? ThemePalette.typography.white
                    : ThemePalette.typography.black
                }
              />
              <Tooltip />
              {/* Area Stack Chart */}
              <Area
                type="monotone"
                dataKey="engineHours"
                stackId="1"
                fill="#229161"
                stopColor="#229161"
                name="Engine Hours"
              />
              <Area
                type="monotone"
                dataKey="idlingHours"
                stackId="1"
                fill="url(#colorRed)"
                stroke="#EB5757"
                name={isProductivity ? 'Productive Hours' : 'Idling Hours'}
              />
              <Legend
                verticalAlign="top"
                iconType="line"
                style={{
                  padding: '16px',
                }}
              />
            </AreaChart>
            <span
              style={{
                padding: '16px',
                marginTop: '16px',
                marginBottom: '16px',
                color: enableDarkTheme ? ThemePalette.typography.white : 'grey',
                alignContent: 'right',
              }}
            >
              Fuel Consumption
            </span>
            <AreaChart
              width={window.innerWidth * 0.4}
              height={150}
              data={filteredAreaChartData}
              margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
            >
              <defs>
                <linearGradient id="colorRed" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor="#EB5757" stopOpacity={0.8} />
                  <stop offset="95%" stopColor="#EB5757" stopOpacity={0} />
                </linearGradient>
                <linearGradient id="colorblue" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor="#229161" stopOpacity={0.8} />
                  <stop offset="95%" stopColor="#229161" stopOpacity={0} />
                </linearGradient>
                <linearGradient id="colorPurple" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor="#229161" stopOpacity={0.8} />
                  <stop offset="95%" stopColor="#229161" stopOpacity={0} />
                </linearGradient>
              </defs>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                dataKey="name"
                stroke={
                  enableDarkTheme
                    ? ThemePalette.typography.white
                    : ThemePalette.typography.black
                }
              />
              <YAxis
                type="number"
                stroke={
                  enableDarkTheme
                    ? ThemePalette.typography.white
                    : ThemePalette.typography.black
                }
              />
              <Tooltip />

              {/* Area Stack Chart */}
              <Area
                type="monotone"
                dataKey="totalFuelConsumed"
                stackId="1"
                fill="#229161"
                stopColor="#229161"
                name="Total Fuel Consumed"
              />
              <Area
                type="monotone"
                dataKey="fuelWasted"
                stackId="1"
                fill="#EB5757"
                stroke="#EB5757"
                name={`Fuel Consumed during ${
                  isProductivity ? 'Production' : 'Idling'
                }`}
              />

              <Legend
                verticalAlign="bottom"
                iconType="line"
                style={{
                  padding: '16px',
                }}
              />
            </AreaChart>
          </Base>
        </>
      ) : null}
    </Grid>
  );
};
