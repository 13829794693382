import dayjs from 'dayjs';
import { assetTypeMappings } from 'views/FleetOverview/helpers/asset.helpers';
import type { Asset } from 'types/asset.types';
import { composeUniqueKey } from 'utils/helpers/string.manipulation';

export function getAssetBumperIdsForType(
  selectedAssetType: string,
  assets: Asset[]
) {
  const assetTypes = Object.keys(assetTypeMappings).filter(
    (key) => assetTypeMappings[key] === selectedAssetType
  );
  const assetsFiltered = assets.filter((asset: Asset) => {
    return assetTypes.includes(asset.assetType);
  });

  const assetBumperIds = assetsFiltered.map((item) => item.bumperNumber);
  return assetBumperIds;
}

export function filterObjectByKeys(keysArray: string[], dataObject: any) {
  const filteredObject: any = {};

  keysArray.forEach((key) => {
    if (Object.prototype.hasOwnProperty.call(dataObject, key)) {
      filteredObject[key] = dataObject[key];
    }
  });

  return filteredObject;
}

export function getSelectedDeviceMakeModel(
  assets: Asset[],
  selectedDevice: string | undefined
) {
  let resp;

  if (selectedDevice) {
    const makeModelAsset = assets.find(
      (item) => item.device.deviceId === selectedDevice
    );

    if (makeModelAsset) {
      const make = makeModelAsset.make;
      const model = makeModelAsset.model;

      const concactedId = composeUniqueKey([make, model]);
      resp = concactedId;
    }
  }
  return resp;
}

function getUniqueObjects(arr: any[]) {
  const uniqueBumperNumbers = new Set();
  const uniqueObjects: any[] = [];

  arr.forEach((obj) => {
    if (!uniqueBumperNumbers.has(obj.bumperNumber)) {
      uniqueBumperNumbers.add(obj.bumperNumber);
      uniqueObjects.push(obj);
    }
  });

  return uniqueObjects;
}

function filterAllPossibleAssets(
  assets: Asset[],
  data: any,
  selectedAssetType: string,
  selectedMakeModel: string,
  selectedData: string | null
) {
  let possibleObjects = data.data;
  let allowedBumperIds: string[] = [];

  if (selectedAssetType !== 'total') {
    allowedBumperIds = getAssetBumperIdsForType(selectedAssetType, assets);
    if (allowedBumperIds.length === 0) {
      return [];
    }
  }

  if (allowedBumperIds.length > 0) {
    possibleObjects = possibleObjects.filter((item: any) =>
      allowedBumperIds.includes(item.bumperNumber)
    );
  }

  if (selectedMakeModel !== 'all') {
    const makeModelAssetBumperIds = assets
      .filter(
        (item) =>
          selectedMakeModel === composeUniqueKey([item.make, item.model])
      )
      .map((item) => item.bumperNumber);
    possibleObjects = possibleObjects.filter((item: any) =>
      makeModelAssetBumperIds.includes(item.bumperNumber)
    );
  }

  if (selectedData) {
    possibleObjects = data.data.filter(
      (item: any) => item.bumperNumber === selectedData
    );
  }
  return possibleObjects;
}

export function getTreeMapData(
  assets: Asset[],
  data: any,
  selectedAssetType: string,
  selectedMakeModel: string,
  isProductivity: boolean
) {
  const filteredAssets = filterAllPossibleAssets(
    assets,
    data,
    selectedAssetType,
    selectedMakeModel,
    null
  );
  const uniqueObjects = getUniqueObjects(filteredAssets);

  const resp = uniqueObjects.map((item: any) => {
    return {
      name: item.bumperNumber,
      size: isProductivity ? item.totalWorkingHours : item.sumIdleTimeHours,
    };
  });

  return resp;
}

export function getCardData(
  assets: Asset[],
  data: any,
  selectedAssetType: string,
  selectedMakeModel: string,
  selectedData: string | null,
  isProductivity: boolean
) {
  const filteredAssets = filterAllPossibleAssets(
    assets,
    data,
    selectedAssetType,
    selectedMakeModel,
    selectedData
  );
  const uniqueObjects = getUniqueObjects(filteredAssets);
  let allowedBumperIds: string[] = [];

  if (selectedAssetType !== 'total') {
    allowedBumperIds = getAssetBumperIdsForType(selectedAssetType, assets);
    if (allowedBumperIds.length === 0) {
      return {
        sumIdleFuelConsumption: 0,
        sumIdleTimeHours: 0,
        assetCount: 0,
      };
    }
  }

  const metrics = uniqueObjects.reduce(
    (acc: any, item: any) => {
      return {
        totalIdleTime:
          // eslint-disable-next-line
          acc.totalIdleTime +
          (isProductivity ? item.totalWorkingHours : item.sumIdleTimeHours),

        totalIdleFuel:
          // eslint-disable-next-line
          acc.totalIdleFuel +
          (isProductivity
            ? item.sumFuelConsumption - item.sumIdleFuelConsumption
            : item.sumIdleFuelConsumption),
      };
    },
    { totalIdleTime: 0, totalIdleFuel: 0 }
  );

  return {
    sumIdleTimeHours: metrics.totalIdleTime,
    sumIdleFuelConsumption: metrics.totalIdleFuel,
    assetCount: uniqueObjects.length,
  };
}

function processAndAggregateData(data: any, isProductivity: boolean) {
  const dataAreaChart: any = {};
  const aggregatedData: any = {};

  data.forEach((item: any) => {
    const device = item.bumperNumber;

    if (device) {
      if (!dataAreaChart[device]) {
        dataAreaChart[device] = [];
      }

      const dailyEntry = {
        name: item.customerDay,
        idlingHours: Number(
          isProductivity
            ? (item.dailyEngineHours - item.dailyIdleTimeHours).toFixed(2)
            : item.dailyIdleTimeHours.toFixed(2)
        ),
        engineHours: Number(item.dailyEngineHours.toFixed(2)),
        totalFuelConsumed: Number(item.dailyFuelConsumption.toFixed(2)),
        fuelWasted: Number(
          isProductivity
            ? (
                item.dailyFuelConsumption - item.totalIdleFuelConsumption
              ).toFixed(2)
            : item.totalIdleFuelConsumption.toFixed(2)
        ),
      };

      dataAreaChart[device].push(dailyEntry);

      if (!aggregatedData[item.customerDay]) {
        aggregatedData[item.customerDay] = {
          name: item.customerDay,
          idlingHours: 0,
          engineHours: 0,
          fuelWasted: 0,
          totalFuelConsumed: 0,
        };
      }
      // eslint-disable-next-line
      aggregatedData[item.customerDay].idlingHours += dailyEntry.idlingHours;
      // eslint-disable-next-line
      aggregatedData[item.customerDay].engineHours += dailyEntry.engineHours;
      // eslint-disable-next-line
      aggregatedData[item.customerDay].fuelWasted += dailyEntry.fuelWasted;
      // eslint-disable-next-line
      aggregatedData[item.customerDay].totalFuelConsumed +=
        dailyEntry.totalFuelConsumed;
    }
  });

  const aggregatedDataArray = Object.values(aggregatedData).map(
    (entry: any) => ({
      name: entry.name,
      idlingHours: Number(entry.idlingHours.toFixed(2)),
      engineHours: Number(entry.engineHours.toFixed(2)),
      fuelWasted: Number(entry.fuelWasted.toFixed(2)),
      totalFuelConsumed: Number(entry.totalFuelConsumed.toFixed(2)),
    })
  );

  return aggregatedDataArray;
}

export function getAreaChartData(
  assets: Asset[],
  data: any,
  selectedAssetType: string,
  selectedMakeModel: string,
  selectedData: string | null,
  isProductivity: boolean
) {
  const filteredAssets = filterAllPossibleAssets(
    assets,
    data,
    selectedAssetType,
    selectedMakeModel,
    selectedData
  );

  const resp = processAndAggregateData(filteredAssets, isProductivity);
  return resp;
}

export const calculateDaysDifference = (
  selectedStartDate: Date,
  selectedEndDate: Date
) => {
  const startDate = dayjs(selectedStartDate);
  const endDate = dayjs(selectedEndDate);

  return endDate.diff(startDate, 'day') + 1;
};

export function getPieChartData(
  assets: Asset[],
  data: { data: any[] },
  selectedAssetType: string,
  selectedMakeModel: string,
  selectedData: string | null = null,
  selectedStartDate: Date,
  selectedEndDate: Date,
  isProductivity: boolean
) {
  const filteredAssets = filterAllPossibleAssets(
    assets,
    data,
    selectedAssetType,
    selectedMakeModel,
    selectedData
  );
  const totalHoursPerDay = 24;

  const th: any = {};
  const eh: any = {};

  filteredAssets.forEach((entry: any) => {
    const {
      bumperNumber,
      totalWorkingHours,
      totalEngineHours,
      sumIdleTimeHours,
    } = entry;
    const deviceTotalHours =
      totalHoursPerDay *
      calculateDaysDifference(selectedStartDate, selectedEndDate);

    if (!th[bumperNumber]) {
      th[bumperNumber] = [
        {
          name: `Productive Hours`,
          value: isProductivity ? totalWorkingHours : sumIdleTimeHours,
        },
        { name: 'Total Hours', value: deviceTotalHours },
        {
          name: 'Diff',
          value:
            deviceTotalHours -
            (isProductivity ? totalWorkingHours : sumIdleTimeHours),
        },
        {
          name: 'Percent',
          value: (
            ((isProductivity ? totalWorkingHours : sumIdleTimeHours) /
              deviceTotalHours) *
            100
          ).toFixed(2),
        },
      ];
    }

    if (!eh[bumperNumber]) {
      eh[bumperNumber] = [
        {
          name: 'Productive Hours',
          value: isProductivity ? totalWorkingHours : sumIdleTimeHours,
        },
        { name: 'Engine Hours', value: totalEngineHours },
        {
          name: 'Diff',
          value:
            totalEngineHours -
            (isProductivity ? totalWorkingHours : sumIdleTimeHours),
        },
        {
          name: 'Percent',
          value: (
            ((isProductivity ? totalWorkingHours : sumIdleTimeHours) /
              totalEngineHours) *
            100
          ).toFixed(2),
        },
      ];
    }
  });

  const aggregateChartData = (chartData: any, key: any) => {
    let allProductiveHours = 0;
    let allTotalHours = 0;

    Object.values(chartData).forEach((dataArray: any) => {
      dataArray.forEach((entry: any) => {
        if (entry.name === 'Productive Hours') {
          allProductiveHours += parseFloat(entry.value);
        }
        if (entry.name === key) {
          allTotalHours += parseFloat(entry.value);
        }
      });
    });

    return [
      { name: 'Productive Hours', value: allProductiveHours },
      { name: key, value: allTotalHours },
      { name: 'Diff', value: allTotalHours - allProductiveHours },
      {
        name: 'Percent',
        value: ((allProductiveHours / allTotalHours) * 100).toFixed(2),
      },
    ];
  };

  const allThChartData = aggregateChartData(th, 'Total Hours');
  const allEhChartData = aggregateChartData(eh, 'Engine Hours');

  const thChartData = selectedData
    ? th[selectedData].filter(
        (entry: any) =>
          entry.name === 'Productive Hours' ||
          entry.name === 'Diff' ||
          entry.name === 'Percent'
      )
    : allThChartData.filter(
        (entry) =>
          entry.name === 'Productive Hours' ||
          entry.name === 'Diff' ||
          entry.name === 'Percent'
      );

  const ehChartData = selectedData
    ? eh[selectedData].filter(
        (entry: any) =>
          entry.name === 'Productive Hours' ||
          entry.name === 'Diff' ||
          entry.name === 'Percent'
      )
    : allEhChartData.filter(
        (entry) =>
          entry.name === 'Productive Hours' ||
          entry.name === 'Diff' ||
          entry.name === 'Percent'
      );

  return { thChartData, ehChartData };
}
