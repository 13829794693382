import { useLocation, useNavigate } from 'react-router-dom';
import Utilization from './Utilization';
import FuelUsage from './FuelUsage';
import Production from './Production';
import { exportToPdf } from 'utils/exports/pdf-reports';
import { ExportDiv } from './Productivity.styled';
import { Button } from '@mui/material';
import './styles.css';
import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hook';
import { clearReport } from 'store/exportData.slice';
import { formatDateToString } from 'utils/helpers/dateTime';
import ReportsHandler from 'handlers/reports.handlers';

const Productivity = () => {
  const reportsHandler = new ReportsHandler();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { startDate, endDate, pageType } = location.state || {};

  const handleExport = async () => {
    await exportToPdf(pageType, 'productivity-report', '', false, 'portrait');
    navigate('/fleet-summary');
  };
  const unitSystem = useAppSelector((state) => state.persistedReducer).customer
    .unitSystem;

  const getScores = async (startDate: Date, endDate: Date) => {
    const date1 = formatDateToString(startDate, 'YYYY-MM-DD');
    const date2 = formatDateToString(endDate, 'YYYY-MM-DD');
    await reportsHandler.getReportScores(date1, date2);
  };

  useEffect(() => {
    void getScores(startDate, endDate);
  }, []);

  useEffect(() => {
    return () => {
      dispatch(clearReport());
    };
  }, [navigate, dispatch]);

  return (
    <>
      <div
        style={{
          width: '80%',
          height: 'auto',
          padding: '0px',
        }}
      >
        {pageType.includes('Fuel Usage') && (
          <div id="Fuel Usage">
            <FuelUsage unitSystem={unitSystem} />
          </div>
        )}
        {pageType.includes('Utilization') && (
          <div id="Utilization">
            <Utilization />
          </div>
        )}
        {pageType.includes('Production') && (
          <div id="Production">
            <Production unitSystem={unitSystem} />
          </div>
        )}
      </div>

      <ExportDiv>
        <Button onClick={handleExport} variant="contained">
          Save Report
        </Button>
      </ExportDiv>
    </>
  );
};

export default Productivity;
