import { type ICreateAssetPayload } from 'types/payloads/asset.payload.types';

/**
 * @param event
 * @returns void
 *
 * This function is used to handle the change event
 * of the asset operator selector.
 * It will add or remove the selected asset operator
 * from the asset's operator array.
 */
export const handleSelectOperatorSingle = (
  event: { target: { value: any } },
  asset: ICreateAssetPayload,
  setAsset: React.Dispatch<React.SetStateAction<ICreateAssetPayload>>
) => {
  const selectedValue = event.target.value;
  if (!selectedValue?.length) {
    setAsset({
      ...asset,
      operators: [],
    });

    return;
  }

  const transformedOperators: Array<{ id: string; display: string }> =
    asset.operators.map((operator) => {
      return {
        id: operator.id,
        display: operator.display,
      };
    });
  const selectedOperator = JSON.parse(selectedValue[selectedValue.length - 1]);
  const assetOperators = transformedOperators;
  const idx = assetOperators.findIndex(({ id }, idx) => {
    return id === selectedOperator.id;
  });

  // found the index of the customer account, remove
  if (idx > -1) {
    assetOperators.splice(idx, 1);
  } else {
    assetOperators.push(selectedOperator);
  }

  setAsset({
    ...asset,
    operators: assetOperators,
  });
};

/**
 *
 * @param operators
 * @param asset
 * @param setAsset
 * @returns void
 *
 * This function is used to handle the change event
 * of the asset's operator selector.
 *
 * It will add or remove all asset operators
 * from the asset's operators array.
 *
 */
export const handleSelectOperatorAll = (
  operatorsAll: Array<{
    id: string;
    display: string;
  }>,
  asset: ICreateAssetPayload,
  setAsset: React.Dispatch<React.SetStateAction<ICreateAssetPayload>>
) => {
  const currAssetOperators = asset.operators;

  // if all customer accounts are selected, remove all
  if (operatorsAll.length === currAssetOperators.length) {
    setAsset({
      ...asset,
      operators: [],
    });

    return;
  }

  // if not all customer accounts are selected, add all
  setAsset({
    ...asset,
    operators: operatorsAll,
  });
};

export const handleOperationalStatus = (
  asset: ICreateAssetPayload,
  setAsset: React.Dispatch<React.SetStateAction<ICreateAssetPayload>>
) => {
  setAsset({
    ...asset,
    isActive: !asset.isActive,
  });
};

export const handleAssetStatus = (isActive: boolean): string => {
  let operationalStatus = 'operational';
  if (!isActive) {
    operationalStatus = 'out-of-service';
  }
  return operationalStatus;
};
