import { type ICreateSquaresPayload } from 'types/payloads/squares.payload.types';
import BaseAPI from '../../base.api';

export default class SquaresAPI extends BaseAPI {
  /**
   * Gets all operators the current user has access to
   * @returns A list of operators
   */

  getPmVsCmVsModification = async (payload: ICreateSquaresPayload) => {
    const response = await this.API.post(
      'squares/getPmVsCmVsModification',
      payload
    );
    return response.data;
  };

  getPmVsCmExpenditures = async (payload: ICreateSquaresPayload) => {
    const response = await this.API.post(
      'squares/getPmVsCmExpenditures',
      payload
    );
    return response.data;
  };

  getPartsTotalCost = async (payload: ICreateSquaresPayload) => {
    const response = await this.API.post('squares/getPartsTotalCost', payload);
    return response.data;
  };

  getPartsTotalQuantity = async (payload: ICreateSquaresPayload) => {
    const response = await this.API.post(
      'squares/getPartsTotalQuantity',
      payload
    );
    return response.data;
  };

  getPartsTotalOrderHours = async (payload: ICreateSquaresPayload) => {
    const response = await this.API.post(
      'squares/getPartsTotalOrderHours',
      payload
    );
    return response.data;
  };

  getDashboardAssetsAscDesc = async (payload: ICreateSquaresPayload) => {
    const response = await this.API.post(
      'squares/getDashboardAssetsAscDesc',
      payload
    );
    return response.data;
  };

  getDashboardComponentswData = async (payload: ICreateSquaresPayload) => {
    const response = await this.API.post('squares/getComponentsData', payload);
    return response.data;
  };

  getDashboardAssetsData = async (payload: ICreateSquaresPayload) => {
    const response = await this.API.post('squares/getAssetsData', payload);
    return response.data;
  };
}
