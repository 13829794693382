import React, { useState, useEffect, type FC } from 'react';
import { Base } from 'components/Containers/BaseContainer';
import {
  Box,
  Divider,
  Typography,
  Grid,
  type SelectChangeEvent,
} from '@mui/material';
import { type MaintenanceSquares } from 'types/squares.type';
import { type SelectOptions } from 'types/utils.types';

import {
  handleSelectAll,
  handleSelectSingle,
} from 'views/Settings/Rules/helpers/common';
import { MultiSelector } from 'components/Form/Selectors/MultiSelector';
import { Selector } from 'components/Form/Selectors/Selector';
import { SquaresComposedChartComponent } from '../../Helpers/Graphs/Squares.composedChart';
import { AddToReports } from '../../Helpers/squareHelper';
import {
  filterAssetsByComponent,
  getUniqueComponents,
} from '../Helper/helperFunction';
import { type Assets } from '../modals/assets.modals';
import CustomRangeDatePicker, {
  type SelectedDates,
} from '../../Helpers/customDatePicker';
import { ThemePalette } from 'mui.theme';

interface ComponentCostProps {
  barKeys: Array<{ name: string; color: string; label: string }>;
  fetchSquaresData: (
    selectedStartDate: Date,
    selectedEndDate: Date,
    selectedAssetIds: string[],
    category: string
  ) => Promise<MaintenanceSquares[]>;
  getAssets: Assets[];
  graphId: string;
  enableDarkTheme: boolean;
}

export const ComponentCost: FC<ComponentCostProps> = ({
  fetchSquaresData,
  barKeys,
  getAssets,
  graphId,
  enableDarkTheme,
}) => {
  const [chartData, setChartData] = useState<MaintenanceSquares[]>([]);
  const [assetOptions, setAssetOptions] = useState<SelectOptions[]>([]);
  const [selectedAssets, setSelectedAssets] = useState<{
    assets: SelectOptions[];
  }>({ assets: [] });
  const [selectedAssetIds, setSelectedAssetIds] = useState<string[]>([]);
  const [selectedComponentCategory, setSelectedComponentCategory] =
    useState<string>('');
  const [componentOptions, setComponentOptions] = useState<Assets[]>([]);
  const [isLoading, setIsLoading] = useState(true); // Add isLoading state
  const [isNoData, setisNoData] = useState(false); // Add isLoading state

  const formerSelectedDates: any = sessionStorage.getItem(
    'datePickerSelection'
  );

  let initialStartDate = new Date();
  let initialEndDate = new Date();

  if (formerSelectedDates) {
    const dateObjs = JSON.parse(formerSelectedDates);

    initialStartDate = new Date(dateObjs.startDate);
    initialEndDate = new Date(dateObjs.endDate);
  }

  const [selectedDates, setSelectedDates] = useState<SelectedDates>({
    startDate: (() => {
      const date = initialStartDate;
      if (!formerSelectedDates) {
        date.setMonth(date.getMonth() - 110);
      }
      return date;
    })(),
    endDate: initialEndDate,
  });

  const handleDateChange = ({
    startDate,
    endDate,
  }: {
    startDate: Date | null;
    endDate: Date | null;
  }) => {
    setSelectedDates({ startDate, endDate });
    sessionStorage.setItem(
      'datePickerSelection',
      JSON.stringify({
        startDate,
        endDate,
      })
    );
  };
  useEffect(() => {
    // Combine asset and component options updates
    if (getAssets == null) {
      setisNoData(true);
      setIsLoading(false);

      return;
    } else {
      setisNoData(false);
    }
    setAssetOptions(getAssets);

    const updatedComponentOptions = getUniqueComponents(getAssets).map(
      (component) => ({
        id: component,
        display: component,
        components: component,
      })
    );
    setComponentOptions(updatedComponentOptions);

    if (updatedComponentOptions.length > 0) {
      setSelectedComponentCategory(updatedComponentOptions[0].id);
    }

    // Set default assets
    if (getAssets.length > 0) {
      setSelectedAssets({ assets: [getAssets[0]] });
    }
  }, []);

  useEffect(() => {
    if (getAssets.toString() === null) {
      setisNoData(true);
      setIsLoading(false);

      return;
    } else {
      setisNoData(false);
    }
    // Only fetch data when necessary
    async function fetchData() {
      setIsLoading(true); // Set isLoading state to true
      setisNoData(false);
      if (selectedDates.startDate === null || selectedDates.endDate === null) {
        return;
      }
      const data = await fetchSquaresData(
        selectedDates.startDate,
        selectedDates.endDate,
        selectedAssets.assets.map((asset) => asset.id),
        selectedComponentCategory
      );

      if (data.length > 0) {
        setChartData(data || []);

        setIsLoading(false); // Set isLoading state to false
      } else {
        setIsLoading(false); // Set isLoading state to false
        setisNoData(true);
      }
    }

    if (selectedAssets.assets.length > 0) {
      void fetchData();
    } else {
      setisNoData(true); // Set isNoData true if there are no selected assets
    }
  }, [
    selectedDates,
    selectedAssets.assets,
    selectedComponentCategory,
    fetchSquaresData,
  ]);

  useEffect(() => {
    const newSelectedAssetIds = selectedAssets.assets.map((asset) => asset.id);
    setSelectedAssetIds(newSelectedAssetIds);
  }, [selectedAssets]);

  useEffect(() => {
    if (getAssets.toString() === null) {
      setisNoData(true);
      setIsLoading(false);

      return;
    } else {
      setisNoData(false);
    }

    const updatedAssetOptions = getAssets;
    setAssetOptions(updatedAssetOptions);

    const updatedComponentOptions = getUniqueComponents(
      updatedAssetOptions
    ).map((component) => ({
      id: component,
      display: component,
      components: component,
    }));
    setComponentOptions(updatedComponentOptions);

    // Set default values
    if (updatedComponentOptions.length > 0) {
      const defaultComponent = updatedComponentOptions[0].id;
      setSelectedComponentCategory(defaultComponent);
    }

    if (updatedAssetOptions.length > 0) {
      const defaultAssets = updatedAssetOptions.slice(0, 1).map((asset) => ({
        id: asset.id,
        display: asset.display,
      }));
      setSelectedAssets({ assets: defaultAssets });
    }
  }, [getAssets]);

  const handleComponentCategoryChange = (event: SelectChangeEvent<any>) => {
    const newComponentCategory = event.target.value;
    setSelectedComponentCategory(newComponentCategory);

    const updatedAssetOptions = filterAssetsByComponent(
      newComponentCategory,
      getAssets
    );

    const validSelectedAssets = selectedAssets.assets.filter((asset) =>
      updatedAssetOptions.some((option) => option.id === asset.id)
    );

    setSelectedAssets({ assets: validSelectedAssets });

    // Update isNoData based on the updated assets selection
    setisNoData(validSelectedAssets.length === 0);
  };

  return (
    <>
      <Base>
        <Box
          sx={{
            backgroundColor: enableDarkTheme
              ? ThemePalette.dark.boxBackground
              : ThemePalette.light.boxBackground,
            paddingTop: '10px',
            paddingLeft: '10px',
            paddingRight: '10px',
            fontSize: '10px',
          }}
        >
          <Grid
            container
            spacing={2}
            direction={{ xs: 'column', sm: 'column', md: 'row', lg: 'row' }} // Responsive direction
            justifyContent="start"
          >
            {/* Component Category Selector */}
            <Grid item xs={12} sm={12} md={3} lg={3}>
              <Typography
                sx={{
                  color: enableDarkTheme
                    ? ThemePalette.typography.white
                    : ThemePalette.typography.black,
                }}
              >
                Total
              </Typography>
              <Selector
                minWidth="100%"
                value={selectedComponentCategory}
                onChange={handleComponentCategoryChange}
                selectorOptions={componentOptions}
                enableDarkTheme={enableDarkTheme}
              />
            </Grid>

            {/* CM Costs for */}
            <Grid item xs={12} sm={12} md={3} lg={3}>
              <Typography
                sx={{
                  color: enableDarkTheme
                    ? ThemePalette.typography.white
                    : ThemePalette.typography.black,
                }}
              >
                {graphId === 'ComponentCostCM'
                  ? 'CM Costs for'
                  : 'PM Costs for'}
              </Typography>

              <MultiSelector
                dropdownHorizontalPosition="right"
                minWidth="100%"
                value={selectedAssets.assets}
                renderValue={(selected) =>
                  `${selected?.length || 0} Asset(s) Selected`
                }
                handleSelectOne={(event) => {
                  handleSelectSingle(
                    event,
                    selectedAssets,
                    setSelectedAssets,
                    'assets'
                  );
                }}
                handleSelectAll={() => {
                  handleSelectAll(
                    assetOptions,
                    selectedAssets,
                    setSelectedAssets,
                    'assets'
                  );
                }}
                selectorOptions={filterAssetsByComponent(
                  selectedComponentCategory,
                  getAssets
                )}
                enableDarkTheme={enableDarkTheme}
              />
            </Grid>

            {/* For Date Range */}
            <Grid item xs={12} sm={12} md={3} lg={3}>
              <Typography
                sx={{
                  color: enableDarkTheme
                    ? ThemePalette.typography.white
                    : ThemePalette.typography.black,
                }}
              >
                For Date Range:
              </Typography>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'stretch', // Ensures full width
                  width: '100%', // Full width within the grid
                }}
              >
                <CustomRangeDatePicker
                  selectedDates={selectedDates}
                  handleDateChange={handleDateChange}
                  enableDarkTheme={enableDarkTheme}
                />
              </Box>
            </Grid>

            {/* Add to Reports */}
            <Grid
              item
              xs={12} // Full width on xs screens
              sm={12} // Full width on sm screens
              md={2} // Adjust width on md and larger
              lg={2} // Adjust width on lg screens
              sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                alignItems: 'center',
              }}
            >
              <AddToReports
                graphId={graphId}
                data={chartData}
                interval={selectedDates}
                orientation="vertical"
              />
            </Grid>
          </Grid>

          <Divider variant="middle" sx={{ margin: '3px' }} />

          <div style={{ width: '100%', height: '100%' }}>
            <div id={graphId} style={{ width: '100%', height: '100%' }}>
              <SquaresComposedChartComponent
                data={chartData}
                barKeys={barKeys}
                xAxisDataKey="asset_id"
                xAxisLabel="Assets"
                isLoading={isLoading}
                isNoData={isNoData}
                yAxisLabel="Cost ($)"
                enableDarkTheme={enableDarkTheme}
              />
            </div>
          </div>
        </Box>
      </Base>
    </>
  );
};
