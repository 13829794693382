import * as React from 'react';
import {
  Box,
  Divider,
  RadioGroup,
  FormControlLabel,
  Radio,
  Typography,
} from '@mui/material';
import { MultiSelector } from 'components/Form/Selectors/MultiSelector';
import { type Asset } from 'types/asset.types';
import type { SelectOptions } from 'types/utils.types';
import {
  handleSelectAll,
  handleSelectSingle,
} from 'views/Settings/Rules/helpers/common';
import { ThemePalette } from 'mui.theme';

interface Props {
  title: string;
  assets: Asset[];
  getAssetData: (arg0: string, arg1: string[]) => void;
  enableDarkTheme: boolean;
  data: any;
  selectedDevice: string | undefined;
}

const FuelEfficiencySelectorHeader = ({
  title,
  assets,
  getAssetData,
  enableDarkTheme = false,
  data,
  selectedDevice,
}: Props) => {
  const [efficiency, setEfficiency] = React.useState<string>('worstAssets');
  const [selectedAssets, setSelectedAssets] = React.useState<{
    assets: SelectOptions[];
  }>({
    assets: [],
  });
  const [assetSelectOptions, setAssetSelectOptions] = React.useState<
    SelectOptions[]
  >([]);

  React.useEffect(() => {
    const assetIds = selectedAssets.assets.map((item: SelectOptions) => {
      return item.id;
    });
    getAssetData(efficiency, assetIds);
  }, [selectedAssets, efficiency]);

  React.useEffect(() => {
    if (assets?.length > 0) {
      const optionsToSelect = assets?.map((item: Asset) => {
        return {
          id: item.device.deviceId,
          display: item.bumperNumber,
        };
      });
      setAssetSelectOptions(optionsToSelect);
    }
  }, [assets]);

  React.useEffect(() => {
    return () => {
      setEfficiency('worstAssets');
    };
  }, []);

  React.useEffect(() => {
    if (selectedDevice) {
      setEfficiency('chosenAssets');

      const defaultItem = assetSelectOptions.filter(
        (item) => item.id === selectedDevice
      );
      setSelectedAssets({
        assets: [...defaultItem],
      });
    } else {
      setEfficiency('worstAssets');
    }
  }, [data]);

  return (
    <>
      <Box
        sx={{
          display: 'flex',
        }}
      >
        <Typography
          style={{
            paddingTop: '8px',
            paddingLeft: '20px',
            fontSize: '13px',
            color: enableDarkTheme
              ? ThemePalette.typography.white
              : ThemePalette.typography.black,
          }}
        >
          {title}
        </Typography>
        <span
          style={{
            paddingLeft: '5px',
          }}
        >
          <RadioGroup
            row
            aria-labelledby="row-radio-buttons-group-label"
            name="assets-radio-buttons-group"
            defaultValue="worstAssets"
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setEfficiency(event.target.value.toString());
            }}
          >
            <FormControlLabel
              value="worstAssets"
              control={
                <Radio
                  size="small"
                  sx={{
                    color: enableDarkTheme
                      ? ThemePalette.typography.cornflowerBlue
                      : ThemePalette.typography.primary,
                    '&.Mui-checked': {
                      color: enableDarkTheme
                        ? ThemePalette.typography.cornflowerBlue
                        : ThemePalette.typography.primary,
                    },
                  }}
                />
              }
              label="Worst 5 assets"
              checked={efficiency === 'worstAssets'}
              sx={{
                color: enableDarkTheme
                  ? ThemePalette.typography.white
                  : ThemePalette.typography.black,
              }}
            />
            <FormControlLabel
              value="bestAssets"
              control={
                <Radio
                  size="small"
                  sx={{
                    color: enableDarkTheme
                      ? ThemePalette.typography.cornflowerBlue
                      : ThemePalette.typography.primary,
                    '&.Mui-checked': {
                      color: enableDarkTheme
                        ? ThemePalette.typography.cornflowerBlue
                        : ThemePalette.typography.primary,
                    },
                  }}
                />
              }
              label="Best 5 assets"
              checked={efficiency === 'bestAssets'}
              sx={{
                color: enableDarkTheme
                  ? ThemePalette.typography.white
                  : ThemePalette.typography.black,
              }}
            />
            <FormControlLabel
              value="chosenAssets"
              control={
                <Radio
                  size="small"
                  sx={{
                    color: enableDarkTheme
                      ? ThemePalette.typography.cornflowerBlue
                      : ThemePalette.typography.primary,
                    '&.Mui-checked': {
                      color: enableDarkTheme
                        ? ThemePalette.typography.cornflowerBlue
                        : ThemePalette.typography.primary,
                    },
                  }}
                />
              }
              label=""
              checked={efficiency === 'chosenAssets'}
              sx={{
                color: enableDarkTheme
                  ? ThemePalette.typography.white
                  : ThemePalette.typography.black,
              }}
            />
          </RadioGroup>
        </span>
        <span>
          <MultiSelector
            value={selectedAssets.assets}
            renderValue={(selected) => {
              return `${selected?.length || 5} Asset(s) Selected`;
            }}
            handleSelectOne={(event) => {
              handleSelectSingle(
                event,
                selectedAssets,
                setSelectedAssets,
                'assets',
                5
              );
            }}
            handleSelectAll={() => {
              handleSelectAll(
                assetSelectOptions,
                selectedAssets,
                setSelectedAssets,
                'assets'
              );
            }}
            selectorOptions={assetSelectOptions}
            formControlSx={{
              paddingTop: '5px',
            }}
            minWidth="200px"
            disableSelectAll={true}
            isDisabled={efficiency !== 'chosenAssets'}
            isSelectionEmpty={!selectedAssets.assets.length}
            messageIfSelectionEmpty="Select Up to 5 Assets"
            enableDarkTheme={enableDarkTheme}
          />
        </span>
      </Box>

      <Divider
        sx={{
          paddingTop: '5px',
          marginBottom: '10px',
        }}
      />
    </>
  );
};

export default FuelEfficiencySelectorHeader;
