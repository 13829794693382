import FuelPriceAPI from 'api/fuelPrice.api';
import { getAll } from 'store/fuelPrice.slice';

import { BaseHandler } from './base.handler';

export default class FuelPriceHandler extends BaseHandler {
  private readonly api: FuelPriceAPI;

  constructor() {
    super();

    this.api = new FuelPriceAPI();
  }

  async get(): Promise<any> {
    try {
      const fuelPrice = await this.api.get();

      this.dispatch(getAll(fuelPrice));
      return fuelPrice;
    } catch (_) {
      this.handleError('An error occurred while fetching fuel price.');
    }

    return {};
  }
}
