import useTheme from '@mui/material/styles/useTheme';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import {
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Collapse,
  List,
} from '@mui/material';
import { SVG } from 'components/Asset/SVG';
import { useHover } from 'hooks/useHover';
import { useState } from 'react';
import { type BaseProps } from 'types/props.base';

interface Props extends BaseProps {
  iconName?: string;
  displayName: string;
}
export const CollapsableListItem = ({
  children,
  displayName,
  iconName = '',
}: Props) => {
  const [isExpanded, setExpansion] = useState(false);
  const [hoverRef, isHovering] = useHover();
  const {
    palette: { primary },
  } = useTheme();

  const handleClick = () => {
    setExpansion(!isExpanded);
  };

  return (
    <>
      <ListItemButton
        onClick={handleClick}
        ref={hoverRef}
        sx={{
          '&:hover': {
            backgroundColor: 'white',
            color: 'primary.main',
          },
        }}
      >
        <ListItemIcon>
          <SVG
            name={iconName}
            isHovering={isHovering}
            style={{
              fill: 'white',
              stroke: 'white',
              ...(isHovering && {
                // since SVG isn't an MUI component
                // we have to import theme via useTheme and apply it manually
                fill: primary.main,
                stroke: primary.main,
              }),
            }}
          />
        </ListItemIcon>
        <ListItemText primary={displayName} color={'red'} />
        {isExpanded ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={isExpanded} timeout="auto" unmountOnExit>
        <List>{children}</List>
      </Collapse>
    </>
  );
};
