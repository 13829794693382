import { Outlet } from 'react-router-dom';

import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Grid2 from '@mui/material/Unstable_Grid2';
import { CollapsableListItem, SWListItem } from 'components/Layout/ListItem';

import SideBarBG from 'assets/bg/sidebar-bg.svg';
import { SVG } from 'components/Asset/SVG';
import Permission from 'components/Permission/Permission';
import { useRemoveAuth } from 'hooks/useRemoveAuth';
import type { SidebarListItemOptions } from './types';
import { SnackBar } from 'components/SnackBar/SnackBar';
import { TopBar } from './TopBar/TopBar';
import { useAppSelector } from 'store/hook';
import { isDarkTheme } from 'utils/theme';
import { ThemePalette } from 'mui.theme';

interface Props {
  children?: JSX.Element | null;
  routes: SidebarListItemOptions;
  settingRoutes: SidebarListItemOptions;
  squaresRoutes: SidebarListItemOptions;
  survivalAnalysis: SidebarListItemOptions;
  fleetOverViewRoutes: SidebarListItemOptions;
}

export const SideBar = ({
  routes,
  settingRoutes,
  squaresRoutes,
  survivalAnalysis,
  fleetOverViewRoutes,
}: Props): JSX.Element => {
  const drawerWidth = '180px';
  const { execRemoveAuth } = useRemoveAuth();
  const userEmail = useAppSelector((state) => state.authReducer).email;
  const theme = useAppSelector((state) => state.authReducer).customer.theme;
  const enableDarkTheme = isDarkTheme(theme);
  const shouldShowSquares = userEmail === 'squares-dev@symboticware.com';

  return (
    <>
      <Grid2
        container
        sx={{
          backgroundColor: enableDarkTheme
            ? ThemePalette.dark.pageBackground
            : ThemePalette.light.pageBackground,
        }}
      >
        <Grid2 width={drawerWidth}>
          <Drawer
            sx={{
              width: drawerWidth,
              flexShrink: 0,
              '& .MuiDrawer-paper': {
                width: drawerWidth,
                boxSizing: 'border-box',
                backgroundColor: enableDarkTheme
                  ? ThemePalette.dark.pageBackground
                  : ThemePalette.light.pageBackground,
              },
            }}
            variant="permanent"
            anchor="left"
          >
            <List
              sx={{
                display: 'flex',
                flexDirection: 'column',
                width: drawerWidth,
                height: '100%',
                color: 'white',
                backgroundColor: 'primary.main',
                backgroundImage: `url(${SideBarBG})`,
                backgroundSize: 'cover',
                paddingTop: 0,
              }}
            >
              <div style={{ flex: 1, backgroundColor: '#032859' }}>
                <SVG
                  name="sidebarLogoMain"
                  style={{
                    width: drawerWidth,
                  }}
                />
                {/* FleetOverview category */}
                <CollapsableListItem
                  displayName="Fleet Overview"
                  iconName="sidebarFleetOverview"
                >
                  <>
                    {fleetOverViewRoutes.map(
                      ({ path, displayName, iconName, roles }) => (
                        <Permission
                          key={path}
                          roles={roles}
                          fallbackComponent={null}
                        >
                          <SWListItem
                            key={path}
                            path={path}
                            listItemDisplay={displayName}
                            iconName={iconName}
                            ignoreIcon
                            variant="medium"
                            listItemStyle={{
                              backgroundColor: 'primary.dark',
                            }}
                          />
                        </Permission>
                      )
                    )}
                  </>
                </CollapsableListItem>

                {routes.map(({ path, displayName, iconName, roles }) => (
                  <Permission key={path} roles={roles} fallbackComponent={null}>
                    <SWListItem
                      path={path}
                      listItemDisplay={displayName}
                      iconName={iconName}
                    />
                  </Permission>
                ))}

                {/* Settings category */}
                <CollapsableListItem
                  displayName="Settings"
                  iconName="sidebarSettings"
                >
                  <>
                    {settingRoutes.map(
                      ({ path, displayName, iconName, roles }) => (
                        <Permission
                          key={path}
                          roles={roles}
                          fallbackComponent={null}
                        >
                          <SWListItem
                            key={path}
                            path={path}
                            listItemDisplay={displayName}
                            iconName={iconName}
                            ignoreIcon
                            variant="medium"
                            listItemStyle={{
                              backgroundColor: 'primary.dark',
                            }}
                          />
                        </Permission>
                      )
                    )}
                  </>
                </CollapsableListItem>
                {/* Fuel Analytics category */}
                {shouldShowSquares && (
                  <CollapsableListItem
                    displayName="Squares"
                    iconName="sidebarSquares"
                  >
                    <>
                      {squaresRoutes.map(
                        ({ path, displayName, iconName, roles }) => (
                          <Permission
                            key={path}
                            roles={roles}
                            fallbackComponent={null}
                          >
                            <SWListItem
                              key={path}
                              path={path}
                              listItemDisplay={displayName}
                              iconName={iconName}
                              ignoreIcon
                              variant="medium"
                              listItemStyle={{
                                backgroundColor: 'primary.dark',
                              }}
                            />
                          </Permission>
                        )
                      )}
                    </>
                  </CollapsableListItem>
                )}
                {shouldShowSquares && (
                  <CollapsableListItem
                    displayName="Survival Analysis"
                    iconName="sidebarAnalytics"
                  >
                    <>
                      {survivalAnalysis.map(
                        ({ path, displayName, iconName, roles }) => (
                          <Permission
                            key={path}
                            roles={roles}
                            fallbackComponent={null}
                          >
                            <SWListItem
                              key={path}
                              path={path}
                              listItemDisplay={displayName}
                              iconName={iconName}
                              ignoreIcon
                              variant="medium"
                              listItemStyle={{
                                backgroundColor: 'primary.dark',
                              }}
                            />
                          </Permission>
                        )
                      )}
                    </>
                  </CollapsableListItem>
                )}
              </div>

              {/* Log Out button */}
              <SWListItem
                onClick={() => {
                  sessionStorage.removeItem('assetSelectedTags');
                  execRemoveAuth();
                }}
                path="/login"
                listItemDisplay="Log Out"
                ignoreIcon
                listItemStyle={{
                  backgroundColor: 'inherit',
                }}
                listItemTextStyle={{
                  textAlign: 'center',
                }}
              />
            </List>
          </Drawer>
        </Grid2>
        <Grid2 xs={true} paddingRight="16px" paddingLeft="16px">
          <TopBar />
          <Outlet />
        </Grid2>
      </Grid2>
      <SnackBar />
    </>
  );
};
