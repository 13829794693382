import { EditCustomerContactModal } from './components/modals/EditCustomerContactModal';
import { useState } from 'react';
import { navigationMap } from 'components/Route/navigationMap';
import { useNavigate } from 'react-router-dom';

export const EditCustomerContactView = () => {
  const [isOperatorModalOpen, setIsOperatorModalOpen] = useState(true);
  const navigateTo = useNavigate();

  return (
    <>
      <EditCustomerContactModal
        key={`${isOperatorModalOpen.toString()}-create-operator`}
        open={isOperatorModalOpen}
        handleAbort={() => {
          setIsOperatorModalOpen(false);
          navigateTo(navigationMap.settings.externalUsers.path);
        }}
        title="Edit Contact Information"
      />
    </>
  );
};
