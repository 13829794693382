import * as htmlToImage from 'html-to-image';
import {
  pdf,
  Document,
  Page,
  Image,
  StyleSheet,
  View,
} from '@react-pdf/renderer';
import { saveAs } from 'file-saver';

export const exportToPdf = async (
  elementIds: string[],
  fileName: string,
  title: string,
  enableDarkTheme: boolean,
  orientation?: 'portrait' | 'landscape' | undefined
): Promise<any> => {
  try {
    const scale = 2; // Scale factor for higher resolution
    const imagesDataUrls = await Promise.all(
      elementIds.map(async (elementId) => {
        const node = document.getElementById(elementId);
        if (!node) {
          throw new Error(`Element with ID ${elementId} not found`);
        }
        // Increase the scale for higher resolution
        return await htmlToImage.toPng(node, {
          quality: 1,
          pixelRatio: scale, // Higher DPI for sharper images
        });
      })
    );

    const pageOrientation = orientation ?? 'portrait';
    const pageWidth = pageOrientation === 'portrait' ? 595.28 : 841.89;
    const pageHeight = pageOrientation === 'portrait' ? 841.89 : 595.28;

    const styles = StyleSheet.create({
      page: {
        backgroundColor: enableDarkTheme ? '#0A192D' : '#FFFFFF',
        padding: 0,
      },
      imageContainer: {
        flexDirection: 'column',
        alignItems: 'flex-start', // Align to the top left
        justifyContent: 'flex-start', // Align to the top
      },
      image: {
        objectFit: 'contain',
        width: pageWidth,
        height: 'auto', // Maintain aspect ratio
        maxHeight: pageHeight,
      },
    });

    const MyDocument = () => (
      <Document>
        {imagesDataUrls.map((dataUrl, i) => (
          <Page
            key={i}
            size="A4"
            orientation={pageOrientation}
            style={styles.page}
          >
            <View style={styles.imageContainer}>
              <Image
                src={dataUrl}
                style={{
                  width: pageWidth,
                  height: 'auto',
                  maxHeight: pageHeight,
                }}
              />
            </View>
          </Page>
        ))}
      </Document>
    );

    const pdfBlob = await pdf(<MyDocument />).toBlob();
    saveAs(pdfBlob, `${fileName}`);
  } catch (error) {
    console.error('Error creating PDF file:', error);
    return null;
  }
};
