import { useState, useEffect } from 'react';
import { Box } from '@mui/material';
import ComposedChartComponent from 'components/Charts/ComposedChart';
import { type Asset } from 'types/asset.types';
import FuelEfficiencySelectorHeader from 'views/FuelAnalytics/components/FuelEfficiency/FuelEfficiencySelectorHeader';
import type { NonHaulTruckFuelEfficiencyItem } from 'types/fuelAnalytics';
import NoChartData from 'views/FuelAnalytics/NoChart';
import Colors from 'views/Settings/ExternalUsers/Squares/Helpers/Graphs/styles';
import { useAppSelector } from 'store/hook';
interface Props {
  assets: Asset[];
  fuelEfficiency: {
    data: NonHaulTruckFuelEfficiencyItem[];
    sortedBottom5: NonHaulTruckFuelEfficiencyItem[];
    sortedTop5: NonHaulTruckFuelEfficiencyItem[];
    fuelConsumptionAverage: number;
    engineHoursAverage: number;
  };
  isLoading: boolean;
  enableDarkTheme: boolean;
  selectedDevice: string | undefined;
}

const FuelEfficiency = ({
  assets,
  fuelEfficiency,
  isLoading,
  enableDarkTheme,
  selectedDevice,
}: Props) => {
  const unitSystem = useAppSelector((state) => state.persistedReducer).customer
    .unitSystem;

  const [chartData, setChartData] = useState<any>([]);

  useEffect(() => {
    const data = formatEfficiencyData(
      fuelEfficiency.sortedBottom5,
      fuelEfficiency.fuelConsumptionAverage,
      fuelEfficiency.engineHoursAverage,
      unitSystem
    );
    setChartData(data);
  }, [fuelEfficiency]);

  const getChartData = async (efficiency: string, assets: string[]) => {
    let data = chartData;

    if (efficiency === 'worstAssets') {
      data = fuelEfficiency.sortedBottom5;
    } else if (efficiency === 'bestAssets') {
      data = fuelEfficiency.sortedTop5;
    } else if (assets.length) {
      const filteredData = fuelEfficiency.data.reduce(
        (accumulator: any, dataEntry: any) => {
          if (assets.includes(dataEntry.device)) {
            accumulator.push(dataEntry);
          }
          return accumulator;
        },
        []
      );

      data = filteredData;
    }
    data = formatEfficiencyData(
      data,
      fuelEfficiency.fuelConsumptionAverage,
      fuelEfficiency.engineHoursAverage,
      unitSystem
    );
    setChartData(data);
  };

  return (
    <Box
      sx={{
        marginRight: '4px',
        marginBottom: '10px',
        paddingTop: '20px',
      }}
    >
      <FuelEfficiencySelectorHeader
        title="Total fuel consumed(Idling and non-idling):"
        assets={assets}
        getAssetData={getChartData}
        enableDarkTheme={enableDarkTheme}
        data={fuelEfficiency}
        selectedDevice={selectedDevice}
      />

      <Box
        sx={{
          height: '400px',
        }}
      >
        {isLoading ? (
          <></>
        ) : chartData?.length ? (
          <ComposedChartComponent
            data={chartData}
            barKeys={[
              {
                name: 'Fuel Use Rate',
                color: Colors.blue,
                label: `Fuel Consumption Index (${
                  unitSystem === 'metric' ? 'L/h' : 'Gal/m'
                }) - Lower Is Better`,
              },
              {
                name: 'Engine Hours',
                color: Colors.orange,
                label: 'Engine Hours (h)',
              },

              {
                name: 'CO2',
                color: Colors.dimGray,
                label: `CO2 Emission ${
                  unitSystem === 'metric' ? 'Kg/h' : 'Lb/h'
                }`,
              },
            ]}
            lineKeys={[
              {
                name: 'Fleet Avg Fuel Use Rate',
                axisName: 'Fuel Use Rate',
                color: '#79859D',
                strokeWidth: 2,
                strokeDashArray: '3 3',
              },
              {
                name: 'Fleet Avg Engine Hours',
                axisName: 'Engine Hours',
                color: '#C77838',
                strokeWidth: 2,
                strokeDashArray: '3 3',
              },
            ]}
            xAxis="bumperNumber"
            showLegend={true}
            enableDarkTheme={enableDarkTheme}
          />
        ) : (
          <NoChartData enableDarkTheme={enableDarkTheme} />
        )}
      </Box>
    </Box>
  );
};

export default FuelEfficiency;

function formatEfficiencyData(
  data: NonHaulTruckFuelEfficiencyItem[],
  fuelConsumptionAverage: number,
  engineHoursAverage: number,
  unitSystem: string
) {
  return data.map((item: NonHaulTruckFuelEfficiencyItem) => {
    const updatedItem = {
      ...item,
      'Engine Hours': item?.engine_hour,
      'Fuel Use Rate': item.avg_fuel_rate,
      'Fleet Avg Fuel Use Rate': fuelConsumptionAverage,
      'Fleet Avg Engine Hours': engineHoursAverage,
    };
    return updatedItem;
  });
}
