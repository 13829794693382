import { Link, type SxProps, type TypographyProps } from '@mui/material';
import { type Variant } from '@mui/material/styles/createTypography';
import { forwardRef, type ForwardedRef } from 'react';

interface Props {
  children: React.ReactNode;
  variant?: Variant;
  color?: TypographyProps['color'];
  onClick: () => void;
  sx?: SxProps;
  isDisabled?: boolean;
}

export const LinkButton = forwardRef(
  (
    {
      children,
      variant = 'body2',
      color = 'button.primary',
      sx,
      onClick,
      isDisabled = false,
      ...props
    }: // ref,
    Props,
    forwardedRef: ForwardedRef<any>
  ) => {
    return (
      <Link
        ref={forwardedRef}
        component={'button'}
        style={{ cursor: 'pointer' }}
        variant={variant}
        color={isDisabled ? 'text.disabled' : color}
        onClick={onClick}
        sx={{
          textDecoration: 'none',

          ...sx,
        }}
        display={'flex'}
        alignItems={'center'}
        disabled={isDisabled}
        {...props}
      >
        {children}
      </Link>
    );
  }
);

LinkButton.displayName = 'LinkButton';
