/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { Typography } from '@mui/material';
import { FlexBox } from 'components/Containers/FlexBox';
import React, { useState } from 'react';
import { PieChart, Pie, Cell } from 'recharts';
import Colors from '../styles';
import { ThemePalette } from 'mui.theme';

const pieColors = {
  preventative: Colors.yellow,
  corrective: Colors.purple,
};

interface PieProps {
  dataPreventative: number;
  dataCorrective: number;
  header: string;
  enableDarkTheme: boolean;
}

export const PieComponent: React.FC<PieProps> = ({
  dataPreventative,
  dataCorrective,
  header,
  enableDarkTheme = false,
}) => {
  const [activeIndex, setActiveIndex] = useState<number | null>(0);

  const pieData = [
    {
      name: 'Value',
      value: !dataPreventative || dataPreventative < 0 ? 0 : dataPreventative,
      label: 'Preventive',
    },
    {
      name: 'Remaining',
      value: !dataCorrective || dataCorrective < 0 ? 0 : dataCorrective,
      label: 'Corrective',
    },
  ];

  const onPieClick = (
    data: any,
    index: number,
    e: React.MouseEvent<Element, MouseEvent>
  ) => {
    setActiveIndex(index);
  };

  const renderActiveShape = ({ cx, cy, payload }: any) => {
    return (
      <g>
        <text
          x={cx}
          y={cy}
          dy={-10}
          fontSize="14"
          textAnchor="middle"
          fill={
            enableDarkTheme
              ? ThemePalette.typography.white
              : ThemePalette.typography.black
          }
        >
          {payload.label}
        </text>
        <text
          x={cx}
          y={cy}
          dy={10}
          fontSize="16"
          textAnchor="middle"
          fill={
            enableDarkTheme
              ? ThemePalette.typography.white
              : ThemePalette.typography.black
          }
        >
          {`${payload?.value}%`}
        </text>
      </g>
    );
  };
  const handleFocus = (e: any) => {
    e.target.style.outline = 'none';
  };
  return (
    <div onFocus={handleFocus}>
      {' '}
      {/* Add style here if you want to apply it to the wrapper div */}
      <FlexBox
        sx={{
          flexDirection: 'column',
        }}
      >
        <div style={{ width: '100%', maxWidth: 300, minHeight: 600 }}>
          <Typography
            width={195}
            align="center"
            fontSize={20}
            sx={{
              color: enableDarkTheme
                ? ThemePalette.typography.white
                : ThemePalette.typography.black,
            }}
          >
            {header}
          </Typography>
          <div onFocus={handleFocus}>
            {' '}
            {/* Or apply the style here if you want it closer to the chart */}
            <PieChart width={300} height={300}>
              <>
                <Pie
                  data={pieData}
                  cx="35%"
                  cy="45%"
                  outerRadius={100}
                  innerRadius={65}
                  dataKey="value"
                  onClick={onPieClick}
                >
                  {pieData.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`}
                      fill={
                        pieColors[
                          entry.name === 'Value' ? 'preventative' : 'corrective'
                        ]
                      }
                      stroke="none"
                    />
                  ))}
                </Pie>
                {activeIndex !== null ? (
                  <Pie
                    data={
                      pieData[activeIndex].value === 0
                        ? [pieData[1]]
                        : [pieData[activeIndex]]
                    }
                    cx="35%"
                    cy="45%"
                    outerRadius={100}
                    innerRadius={65}
                    fill={Colors.purple}
                    dataKey="value"
                    activeIndex={0}
                    activeShape={renderActiveShape}
                  />
                ) : null}
              </>
            </PieChart>
          </div>
        </div>
      </FlexBox>
    </div>
  );
};
