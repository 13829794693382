import { type SxProps, Typography } from '@mui/material';

interface Props {
  text: string;
  fontFamily?: string;
  sx?: SxProps;
}

export const HeavyBodyText = ({ text, fontFamily, sx }: Props) => {
  return (
    <Typography
      sx={{
        display: 'flex',
        alignSelf: 'center',
        fontWeight: 'bold',
        ...sx,
      }}
      gutterBottom
      variant="body2"
      fontFamily={fontFamily ?? ''}
    >
      {text}
    </Typography>
  );
};
