import { Box, type SxProps } from '@mui/material';
import { ThemePalette } from 'mui.theme';

interface Props {
  children?: JSX.Element | JSX.Element[];
  sx?: SxProps;
  enableDarkTheme?: boolean;
}

export const Base = ({ children, sx, enableDarkTheme = false }: Props) => {
  return (
    <Box
      sx={{
        boxShadow: '0px 0px 3px #00000029',
        height: 'auto',
        borderRadius: '5px',
        marginTop: '17px',
        backgroundColor: enableDarkTheme
          ? ThemePalette.dark.boxBackground
          : ThemePalette.light.boxBackground,
        ...sx,
      }}
    >
      {children}
    </Box>
  );
};
