import { SVG } from 'components/Asset/SVG';
import {
  HeaderDiv,
  LightText,
  PerfomanceDescription,
  TwoColumnDiv,
} from '../Productivity.styled';
import { IconButton, LinearProgress } from '@mui/material';
import ErrorIcon from '@mui/icons-material/Error';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DoDisturbOnIcon from '@mui/icons-material/DoDisturbOn';
import { formatDateToString } from 'utils/helpers/dateTime';
import { ProgressBarWithArrow } from 'views/FleetOverview/FleetOverviewV2/ProgressBarWithArrow';
import { statusEnum } from '../utils';

interface PropTypes {
  startDate: Date;
  endDate: Date;
  fleet: Record<string, any>;
  status:
    | { success: 'Ideal Performance' }
    | { warning: 'Caution Advised' }
    | { error: 'Attention Required' };
  title: string;
}
const Header = ({ startDate, endDate, fleet, status, title }: PropTypes) => {
  return (
    <>
      <HeaderDiv>
        <div>
          <SVG name="buzziReportLogo" />
        </div>
        <div>
          <LightText>Productivity Report for period from: </LightText>
          <p
            style={{
              fontWeight: 'bold',
              color: '#0063BE',
            }}
          >
            {formatDateToString(startDate, 'DD MMM, YYYY')} -{' '}
            {formatDateToString(endDate, 'DD MMM, YYYY')}
          </p>
        </div>
      </HeaderDiv>
      <TwoColumnDiv>
        {fleet?.fleet?.fuel ? (
          <>
            <IconButton
              edge="end"
              sx={{
                color: '#FFFFFF',
                backgroundColor:
                  statusEnum[Object.keys(status)[0] as keyof typeof statusEnum],
                width: '50px',
                maxHeigth: '15px',
                borderRadius: '5px',
                height: '50px',
              }}
            >
              {Object.keys(status)[0] === 'success' ? (
                <CheckCircleIcon />
              ) : Object.keys(status)[0] === 'warning' ? (
                <DoDisturbOnIcon />
              ) : (
                <ErrorIcon />
              )}
            </IconButton>
            <div style={{ width: '30%', margin: '-10px 0 0 20px' }}>
              <p
                style={{
                  fontSize: '14px',
                  fontWeight: 'bold',
                }}
              >
                {title}
              </p>
              <PerfomanceDescription
                status={Object.keys(status)[0] as keyof typeof statusEnum}
              >
                {Object.values(status)[0]}
              </PerfomanceDescription>
              <ProgressBarWithArrow
                value={fleet?.fleet?.production}
                enableDarkTheme={false}
              />
            </div>
          </>
        ) : (
          <>
            <LinearProgress
              sx={{ color: 'black', width: '40%', height: '20px' }}
            />
          </>
        )}
        <div style={{ width: '60%', marginLeft: '10%' }}>
          <p
            style={{
              color: 'rgba(0, 0, 0, 0.5)',
              fontFamily: 'Montserrat',
            }}
          ></p>
        </div>
      </TwoColumnDiv>
    </>
  );
};

export default Header;
