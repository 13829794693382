import {
  FormControl,
  type InputProps,
  MenuItem,
  Select,
  type SelectChangeEvent,
  type SxProps,
} from '@mui/material';
import { ThemePalette } from 'mui.theme';
import { type ReactNode } from 'react';

interface Props {
  selectorOptions: Array<{ id: string; display: string }>;
  onChange: (event: SelectChangeEvent<number>, child: ReactNode) => void;
  value: any;
  selectSx?: SxProps;
  formControlSx?: SxProps;
  inputProps?: InputProps['inputProps'];
  disabled?: boolean;
  fallbackValue?: string;
  minWidth?: string;
  enableDarkTheme?: boolean;
}

export const Selector = ({
  selectorOptions,
  onChange,
  value,
  selectSx,
  formControlSx,
  inputProps,
  disabled = false,
  fallbackValue = '',
  minWidth = '270px',
  enableDarkTheme = false,
}: Props) => {
  return (
    <FormControl sx={{ minWidth, ...formControlSx }} size="small">
      <Select
        value={value ?? ''}
        onChange={onChange}
        SelectDisplayProps={{
          style: {
            display: 'flex',
            height: '12px',
            minHeight: '12px',
            alignItems: 'center',
          },
        }}
        inputProps={inputProps}
        sx={{
          ...selectSx,
          color: enableDarkTheme
            ? ThemePalette.typography.lightGrey
            : ThemePalette.typography.black,
          ...(enableDarkTheme && {
            '& .MuiSvgIcon-root': {
              color: 'purple', // <------------------ arrow-svg-color
            },
            '&.MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: '#2e3b4d', // <------------------ outline-color by default
              },
              '&:hover fieldset': {
                borderColor: ThemePalette.typography.cornflowerBlue, // <------------------ outline-color on hover
              },
              '&.Mui-focused fieldset': {
                borderColor: ThemePalette.typography.cornflowerBlue, // <------------------ outline-color on focus
              },
            },
          }),
        }}
        disabled={disabled}
        displayEmpty={!!fallbackValue}
        MenuProps={{
          PaperProps: {
            style: {
              backgroundColor: enableDarkTheme
                ? ThemePalette.dark.menuOptions
                : ThemePalette.light.menuOptions, // Change background color here
            },
          },
        }}
      >
        <MenuItem
          value=""
          disabled
          sx={{
            color: enableDarkTheme
              ? ThemePalette.typography.lightGrey
              : ThemePalette.typography.black,
          }}
        >
          {fallbackValue}
        </MenuItem>
        {selectorOptions.map(({ id, display }) => (
          <MenuItem
            key={id}
            value={id}
            sx={{
              color: enableDarkTheme
                ? ThemePalette.typography.lightGrey
                : ThemePalette.typography.black,
            }}
          >
            {display}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
