export const chartRange = (
  tagChartData: [{ tagName: string; tagDetails: [] }],
  selectedChartSelector: string
): Record<string, any[]> => {
  let validRange: Array<{ display: string }> = [];
  const allRanges = [
    {
      display: '1D',
    },
    {
      display: '7D',
    },
    {
      display: '14D',
    },
    {
      display: '1Y',
    },
    {
      display: 'ALL',
    },
  ];

  if (
    tagChartData.every((item) => {
      const tagDetails = selectHourlyOrDailyObject(
        item.tagDetails,
        selectedChartSelector
      );
      return tagDetails?.length < 1;
    })
  ) {
    return { validRange, allRanges };
  } else {
    // return all the ts values from the array of tags
    const tsValues = tagChartData.flatMap((item: any) => {
      return hourlyTags.includes(selectedChartSelector)
        ? item.tagDetails[0].hourly?.map((detail: any) => detail.ts)
        : item.tagDetails[0].daily?.map((detail: any) => detail.ts);
    });

    // get the ts with the highest value
    const latestTs = Math.max(...tsValues);

    validRange = validRangeByTS(latestTs);
  }

  return { validRange, allRanges };
};

const validRangeByTS = (latestTs: number) => {
  let validRange: Array<{ display: string }> = [];
  const rangeUnixMs = (day: number) => Date.now() - day * 24 * 60 * 60 * 1000;
  if (latestTs >= rangeUnixMs(1)) {
    validRange = [
      {
        display: '1D',
      },
      {
        display: '7D',
      },
      {
        display: '14D',
      },
      {
        display: '1Y',
      },
      {
        display: 'ALL',
      },
    ];
  }
  if (latestTs >= rangeUnixMs(7) && latestTs < rangeUnixMs(1)) {
    validRange = [
      {
        display: '7D',
      },
      {
        display: '14D',
      },
      {
        display: '1Y',
      },
      {
        display: 'ALL',
      },
    ];
  }
  if (latestTs >= rangeUnixMs(14) && latestTs < rangeUnixMs(7)) {
    validRange = [
      {
        display: '14D',
      },
      {
        display: '1Y',
      },
      {
        display: 'ALL',
      },
    ];
  }
  if (latestTs < rangeUnixMs(14) && latestTs > rangeUnixMs(365)) {
    validRange = [
      {
        display: '1Y',
      },
      {
        display: 'ALL',
      },
    ];
  }
  if (latestTs < rangeUnixMs(365)) {
    validRange = [
      {
        display: 'ALL',
      },
    ];
  }
  return validRange;
};

export const cloneData = (data: any) => {
  return JSON.parse(JSON.stringify(data));
};

export const setIsValidRangeForTags = (data: any, payload: any) => {
  return data.map((tag: any, index: number) => {
    tag.isValidRange = true;
    const tagDetails = selectHourlyOrDailyObject(
      tag.tagDetails,
      payload.timeFrame
    );
    const tagLatestTs =
      tagDetails.length >= 1 ? tagDetails[tagDetails.length - 1].ts : null;

    const validRange = validRangeByTS(tagLatestTs as number);

    if (!validRange.some((range) => range.display === payload.timeFrame)) {
      tag.isValidRange = false;
    }
    return null;
  });
};

export function getDateRange(arg: string) {
  const currentDate = new Date();
  const startDate = new Date(currentDate);
  let endDate;

  switch (arg) {
    case '1D':
      startDate.setHours(0, 0, 0, 0); // Set to midnight
      endDate = new Date(startDate);
      endDate.setDate(endDate.getDate() + 1); // Add 1 day
      break;
    case '7D':
      startDate.setDate(startDate.getDate() - 7); // Subtract 7 days
      endDate = new Date(currentDate);
      break;
    case '14D':
      startDate.setDate(startDate.getDate() - 14); // Subtract 14 days
      endDate = new Date(currentDate);
      break;
    default:
      // Default to 3 months if argument is unrecognized
      startDate.setMonth(currentDate.getMonth() - 3); // Subtract 3 months
      endDate = new Date(currentDate);
  }

  startDate.setHours(0, 0, 0, 0);

  return { startDate, endDate };
}

export function getMaxDate(arg: string) {
  let maxDate = 91; // days

  switch (arg) {
    case '1D':
      maxDate = 1;
      break;
    case '7D':
      maxDate = 7;
      break;
    case '14D':
      maxDate = 14;
      break;
    default:
      maxDate = 91;
  }

  return maxDate;
}
export const hourlyTags = ['1D', '7D', '14D'];
export const dailyTags = ['1Y', 'ALL'];

export function selectHourlyOrDailyObject(
  tagDetails: Record<string, any>,
  selectedChartSelector: string
): Array<Record<string, unknown>> {
  return hourlyTags.includes(selectedChartSelector)
    ? tagDetails[0].hourly
    : tagDetails[0].daily;
}
