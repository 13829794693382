import { Typography, Box } from '@mui/material';
import { ThemePalette } from 'mui.theme';
// import CancelIcon from '@mui/icons-material/Cancel';
// import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
// import CheckCircleIcon from '@mui/icons-material/CheckCircle';
// import { ProgressBarWithArrow } from 'views/FleetOverview/FleetOverviewV2/ProgressBarWithArrow';
import { truncateTo2dp } from 'utils/helpers/general';
import LoadingDots from 'views/FleetOverview/components/AssetCardsV2/LoadingDots';

const AnalysisItem = ({
  headerText,
  value,
  unit,
  errorCount,
  warningCount,
  successCount,
  enableDarkTheme,
  progressBarValue,
  isLoading,
}: {
  headerText: string;
  enableDarkTheme: boolean;
  value: number;
  unit: string;
  errorCount: number;
  warningCount: number;
  successCount: number;
  progressBarValue: number;
  isLoading: boolean;
}) => {
  return (
    <Box
      sx={{
        backgroundColor: enableDarkTheme
          ? ThemePalette.dark.modalBackground
          : ThemePalette.light.pageBackground,
        borderRadius: '5px',
        margin: '10px',
        minHeight: '132px',
        padding: '5px',
      }}
    >
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        sx={{
          paddingRight: '5px',
        }}
      >
        <Typography
          sx={{
            fontSize: '13px',
            color: enableDarkTheme
              ? ThemePalette.typography.white
              : ThemePalette.typography.black,
          }}
        >
          {headerText}
        </Typography>{' '}
        <Typography>
          <Box display="flex" alignItems="center">
            {/**
            *  <Box
              display="flex"
              alignItems="center"
              sx={{
                paddingRight: '5px',
              }}
            >
              <CancelIcon sx={{ fontSize: '16px', color: '#eb5757' }} />
              <Typography
                variant="body1"
                sx={{
                  marginLeft: '2px',
                  color: enableDarkTheme
                    ? ThemePalette.typography.white
                    : ThemePalette.typography.black,
                }}
              >
                {errorCount}
              </Typography>
            </Box>
            <Box
              display="flex"
              alignItems="center"
              sx={{ marginLeft: '5px', paddingRight: '5px' }}
            >
              <RemoveCircleIcon sx={{ fontSize: '16px', color: '#fd7f34' }} />
              <Typography
                variant="body1"
                sx={{
                  marginLeft: '2px',
                  color: enableDarkTheme
                    ? ThemePalette.typography.white
                    : ThemePalette.typography.black,
                }}
              >
                {warningCount}
              </Typography>
            </Box>
            <Box display="flex" alignItems="center" sx={{ marginLeft: '5px' }}>
              <CheckCircleIcon sx={{ fontSize: '16px', color: '#228b22' }} />
              <Typography
                variant="body1"
                sx={{
                  marginLeft: '2px',
                  color: enableDarkTheme
                    ? ThemePalette.typography.white
                    : ThemePalette.typography.black,
                }}
              >
                {successCount}
              </Typography>
            </Box>
            */}
          </Box>
        </Typography>
      </Box>

      <Box>
        {isLoading ? (
          <LoadingDots fontSize={30} enableDarkTheme={enableDarkTheme} />
        ) : (
          <Typography
            sx={{
              fontSize: '32px',
              color: '#4A7BFA',
              fontWeight: '700',
              lineHeight: '48px',
            }}
          >
            {truncateTo2dp(value)}
            <span
              style={{
                color: enableDarkTheme
                  ? ThemePalette.typography.white
                  : ThemePalette.typography.black,
                fontSize: '14px',
                paddingLeft: '5px',
              }}
            >
              {unit}
            </span>
          </Typography>
        )}
      </Box>
      <Box
        sx={{
          paddingTop: '30px',
        }}
      >
        {/* <Typography
          component="div"
          sx={{
            fontSize: '12px',
            textAlign: 'center',
            color: enableDarkTheme
              ? ThemePalette.typography.white
              : ThemePalette.typography.black,
          }}
        >
          <ProgressBarWithArrow
            value={progressBarValue}
            enableDarkTheme={enableDarkTheme}
          />
        </Typography> */}
      </Box>
    </Box>
  );
};

export default AnalysisItem;
