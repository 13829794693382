import * as yup from 'yup';
import {
  nameValidation,
  emailValidation,
  phoneNumberValidation,
  codeValidation,
} from './reusable-fields';

export const createOrUpdateCustomerUserRequestBodySchema = yup
  .object({
    body: yup.object({
      customerName: nameValidation(),
      customerCode: codeValidation(),
      contactFirstName: nameValidation(),
      contactLastName: nameValidation(),
      email: emailValidation(),
      phoneNumber: phoneNumberValidation(),
      customerType: yup
        .string()
        .oneOf([
          'internal-customer',
          'external-customer',
          'third-party-customer',
        ])
        .required('Invalid customer type.'),
    }),
    unitSystem: yup.string().oneOf(['metric', 'imperial']),
  })
  .required();

// Synced to backend schema - 2023-05-12
export const updateCustomerRequestSchema = yup.object({
  body: yup.object({
    customerName: nameValidation(),
    contactFirstName: nameValidation(),
    contactLastName: nameValidation(),
    email: emailValidation(),
    phoneNumber: phoneNumberValidation(),
    internalAccountingLink: yup.string(),
    unitSystem: yup.string().oneOf(['metric', 'imperial']),
  }),
});
