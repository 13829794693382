import { Grid } from '@mui/material';
import { ThemePalette } from 'mui.theme';
import { Base } from 'components/Containers/BaseContainer';
import { pieContainerStyle } from 'views/FleetOverview/components/styles';
import { Tooltip, PieChart, Pie, Label, Sector } from 'recharts';

type THType = Array<{
  name: string;
  value?: number | string;
}>;

export const PiechartSection = ({
  selectedEndDate,
  thChartData,
  ehChartData,
  enableDarkTheme = false,
  isProductivity,
}: {
  selectedEndDate: Date;
  thChartData: THType;
  ehChartData: THType;
  enableDarkTheme: boolean;
  isProductivity: boolean;
}) => {
  return (
    <>
      <Grid item lg={3} md={12} sm={12} xs={12}>
        {selectedEndDate !== null && thChartData?.length > 0 ? (
          <Base
            sx={{
              ...pieContainerStyle,
              backgroundColor: enableDarkTheme
                ? ThemePalette.dark.modalBackground
                : '#FFFFFF',
            }}
          >
            <span
              style={{
                display: 'block',
                textAlign: 'center',
                color: enableDarkTheme
                  ? ThemePalette.typography.white
                  : ThemePalette.typography.black,
              }}
            >
              {isProductivity ? 'Productive ' : 'Idling '}Hours / Total Hours
              (%)
            </span>
            <PieChart width={300} height={340}>
              <Pie
                activeIndex={0}
                activeShape={renderActiveShape}
                data={thChartData}
                dataKey="value"
                nameKey="name"
                cx="50%"
                cy="50%"
                innerRadius={80}
                outerRadius={120}
                fill="#229161"
              >
                {/* Central Label */}
                <Label
                  value={`${Number(
                    thChartData.find((entry) => entry.name === 'Percent')?.value
                  ).toFixed(2)}%`}
                  position="center"
                  fontSize={20}
                  fontWeight={'bold'}
                  fill={
                    enableDarkTheme
                      ? ThemePalette.typography.white
                      : ThemePalette.typography.black
                  }
                />
              </Pie>
              <Tooltip
                content={({ payload }) => {
                  if (payload && payload.length > 0) {
                    const { name, value } = payload[0].payload;
                    let n = '';
                    if (name === 'Diff') {
                      n = `Total Hours - ${
                        isProductivity ? 'Productive' : 'Idling'
                      } Hours`;
                    } else if (name === 'Productive Hours') {
                      n = `${isProductivity ? 'Productive' : 'Idling'} Hours`;
                    } else {
                      n = name;
                    }
                    return (
                      <div
                        style={{
                          backgroundColor: '#fee',
                          padding: '6px',
                          border: '1px solid #ccc',
                        }}
                      >
                        <p style={{ fontSize: 'smaller' }}>{n}</p>
                        <p style={{ fontSize: 'smaller' }}>
                          {Number(value).toFixed(2)}
                        </p>
                      </div>
                    );
                  }
                  return null;
                }}
              />

              {/* <Legend verticalAlign="bottom" height={36} /> */}
            </PieChart>
          </Base>
        ) : null}
      </Grid>
      <Grid item lg={3} md={12} sm={12} xs={12}>
        {selectedEndDate !== null &&
        ehChartData?.length > 0 &&
        !Number.isNaN(
          ehChartData.find((entry) => entry.name === 'Percent')?.value
        ) ? (
          <Base
            sx={{
              ...pieContainerStyle,
              backgroundColor: enableDarkTheme
                ? ThemePalette.dark.modalBackground
                : '#FFFFFF',
            }}
          >
            <span
              style={{
                display: 'block',
                textAlign: 'center',
                color: enableDarkTheme
                  ? ThemePalette.typography.white
                  : ThemePalette.typography.black,
              }}
            >
              {isProductivity ? 'Productive ' : 'Idling '} Hours / Engine Hours
              (%)
            </span>
            <PieChart width={300} height={360}>
              <Pie
                activeIndex={0}
                activeShape={renderActiveShape}
                data={ehChartData}
                dataKey="value"
                nameKey="name"
                cx="50%"
                cy="50%"
                innerRadius={80}
                outerRadius={120}
                fill="#229161"
              >
                <Label
                  value={`${Number(
                    ehChartData.find((entry) => entry.name === 'Percent')?.value
                  ).toFixed(2)}%`}
                  position="center"
                  fontSize={20}
                  fontWeight={'bold'}
                  fill={
                    enableDarkTheme
                      ? ThemePalette.typography.white
                      : ThemePalette.typography.black
                  }
                />
              </Pie>
              <Tooltip
                content={({ payload }) => {
                  if (payload && payload.length > 0) {
                    const { name, value } = payload[0].payload;
                    let n = '';
                    if (name === 'Diff') {
                      n = `Engine Hours - ${
                        isProductivity ? 'Productive ' : 'Idling '
                      } Hours`;
                    } else if (name === 'Productive Hours') {
                      n = `${isProductivity ? 'Productive' : 'Idling'} Hours`;
                    } else {
                      n = name;
                    }
                    return (
                      <div
                        style={{
                          backgroundColor: '#fee',
                          padding: '6px',
                          border: '1px solid #ccc',
                        }}
                      >
                        <p style={{ fontSize: 'smaller' }}>{n}</p>
                        <p style={{ fontSize: 'smaller' }}>
                          {Number(value).toFixed(2)}
                        </p>
                      </div>
                    );
                  }
                  return null;
                }}
              />
            </PieChart>
          </Base>
        ) : (
          <p>Select End Date to see this graph</p>
        )}
      </Grid>
    </>
  );
};

const renderActiveShape = (props: any) => {
  const { cx, cy, startAngle, endAngle, innerRadius, outerRadius } = props;

  return (
    <g>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
        outerRadius={outerRadius + 0}
        startAngle={startAngle}
        endAngle={endAngle}
        fill="#F9A405"
      />
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius - 18}
        outerRadius={innerRadius - 10}
        startAngle={0}
        endAngle={endAngle}
        fill="#4A7BFA"
      />

      {/* <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill="none" />
            <circle cx={ex} cy={ey} r={2} fill={fill} stroke="none" /> */}
      {/* <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill="#333">{`${value}%`}</text> */}
    </g>
  );
};
