import AssetAPI from 'api/asset.api';
import { updateLoadingState } from 'store/app.slice';
import { BaseHandler } from './base.handler';
import { setAssetFleetOverview } from 'store/asset.slice';

export default class FleetOverviewHandler extends BaseHandler {
  private readonly api: AssetAPI;

  constructor() {
    super();

    this.api = new AssetAPI();
  }

  /**
   * Gets asset Overview Data Display
   */
  async getAll() {
    this.dispatch(updateLoadingState(true));
    try {
      const response = await this.api.getAssetsOverView();

      this.dispatch(setAssetFleetOverview(response));

      this.handleSuccess();
    } catch (error: any) {
      let msg = '';

      // Extract the error message from AxiosErrors
      // TODO: Look into other errors that can be thrown
      if (error.isAxiosError) {
        msg = error.response.data.message as string;
      }

      this.handleError(
        'An error occured while retrieving the assets overview display: ' + msg
      );
    }
  }
}
