import { Typography } from '@mui/material';
import { InteractiveModal } from 'components/Modals/InteractiveModal/BaseModal';
import OperatorHandler from 'handlers/operator.handler';

export {};

/**
 *
 * The Deactivation modal is a modal that is used to deactivate an External user.
 */

interface Props {
  open: boolean;
  operatorId: string;
  isActive: boolean;
  handleAbort: () => void;
}

export const OperatorDeactivationModal = ({
  open,
  operatorId,
  isActive,
  handleAbort,
}: Props) => {
  const userHandler = new OperatorHandler();
  const title = `Confirm Deactivation of ${operatorId}`;
  return (
    <InteractiveModal
      initialState={open}
      title={title}
      handleAbort={handleAbort}
      SuccessButtonProps={{
        onClick: async () => {
          await userHandler.activationOperator(operatorId, isActive);
          handleAbort();
        },
        disabled: false,
        label: 'Deactivate Operator',
      }}
      ModalContentProps={{
        gridItemProps: {
          lg: 12,
        },
      }}
    >
      <Typography key="blurb-1" variant="medium">
        Upon Operator deactivation, this Operator will no longer have access to
        the software
      </Typography>
      <Typography key="blurb-2" variant="medium">
        {"To reactivate a Operator, click the 'Inactive' Link under 'Status'"}
      </Typography>
    </InteractiveModal>
  );
};
