import BaseAPI from './base.api';

export default class IdleAnalyticsAPI extends BaseAPI {
  getIdleAnalytics = async (startDate: string, endDate: string) => {
    const response = await this.API.get(
      `analytics/fuel-analytics/idle-analytics`,
      {
        params: {
          startDate,
          endDate,
        },
      }
    );
    return response.data;
  };
}
