import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import { useAppSelector } from 'store/hook';
import { BaseTableList } from 'components/AgGrid/AgGrid';
import { PaddedListWrapper } from 'components/AgGrid/CellRenderer/ListView.styled';
import { Typography } from '@mui/material';
import { VehicleDisplay, DTCCodeDisplay } from './DTCCellrenderer';
import { SingleFadeLoader } from 'components/SingleFadeLoader';
import { ThemePalette } from 'mui.theme';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';

export const DTCList = ({ enableDarkTheme }: { enableDarkTheme: boolean }) => {
  const navigate = useNavigate();
  const hourStringToMinutesMap: Record<string, number> = {};
  const { data, isLoading } = useAppSelector((state) => state.dtcReducer);

  const { assets } = useAppSelector((state) => state.assetReducer);

  // Extend dayjs with the necessary plugins
  dayjs.extend(utc);
  dayjs.extend(timezone);

  function convertToUserTimeZone(gmtDate: any) {
    // Assume the input date is in GMT
    const dateInGMT = dayjs.utc(gmtDate);

    // Convert to the user's local time zone
    const localTime = dateInGMT.local();

    return localTime.format('MM/DD/YYYY h:mma'); // You can customize the format if needed
  }

  // Custom value getter for Start column
  function startValueGetter(params: any) {
    return params.data.start_ts
      ? convertToUserTimeZone(params.data.start_ts)
      : null;
  }

  // Custom value getter for End column
  function endValueGetter(params: any) {
    return params.data.end_ts
      ? convertToUserTimeZone(params.data.end_ts)
      : null;
  }

  // Custom value getter for Age column using duration_minutes
  function ageValueGetter(params: any) {
    if (
      params.data.duration_minutes === null ||
      params.data.duration_minutes === undefined ||
      isNaN(params.data.duration_minutes)
    ) {
      return '0 hrs 0 mins';
    }
    const hours = Math.floor(params.data.duration_minutes / 60);
    const minutes = params.data.duration_minutes % 60;
    const hourString = `${hours} hrs ${minutes} mins`;
    hourStringToMinutesMap[hourString] = params.data.duration_minutes; // Add to hash map
    return hourString;
  }

  function hourStringComparator(valueA: any, valueB: any) {
    const durationA = hourStringToMinutesMap[valueA];
    const durationB = hourStringToMinutesMap[valueB];
    return durationA - durationB; // Compare durations
  }

  const [columnDefs] = React.useState([
    {
      field: 'start_ts',
      headerName: 'DTC Start Time',
      sortable: true,
      valueGetter: startValueGetter,
    },
    {
      field: 'end_ts',
      headerName: 'DTC End Time',
      sortable: true,
      valueGetter: endValueGetter,
    },
    {
      field: 'is_active',
      headerName: 'Status',
      sortable: true,
      valueFormatter: (params: any) => (params.value ? 'Active' : 'Inactive'),
    },
    {
      field: 'asset',
      headerName: 'Vehicle',
      filter: 'agTextColumnFilter',
      sortable: true,
      cellRenderer: VehicleDisplay,
      cellRendererParams: {
        field: 'asset',
        handleVehicleClick: async (
          asset: string,
          startTs: string,
          dtcType: string
        ) => {
          const selectedAsset = assets.find(
            (item) => item.bumperNumber === asset
          );
          if (selectedAsset) {
            const dataToPass = {
              assetVin: selectedAsset.assetVin,
              alertStartTs: startTs,
              tagNames: [dtcType],
            };
            let encodedData = encodeURIComponent(JSON.stringify(dataToPass));
            encodedData = encodedData.replace(/%/g, '%25');
            navigate(
              `/vehicle-status/${selectedAsset.assetVin}?data=${encodedData}`
            );
          }
        },
      },
    },
    {
      field: 'dtc_type',
      headerName: 'DTC Type',
      filter: 'agTextColumnFilter',
      sortable: true,
    },
    {
      field: 'dtc_code',
      headerName: 'DTC Code',
      filter: 'agTextColumnFilter',
      sortable: true,
      cellRenderer: DTCCodeDisplay,
      tooltipValueGetter: (params: any) => {
        if (params.data.failure_reason) {
          const formattedString = `
DTC Type Info: ${params.data.dtc_type_info}
DTC Code Info: ${params.data.dtc_code_info}
Failure Reason: ${params.data.failure_reason}
Recommendations:
- ${params.data.recommendations.slice(1, -1).split(', ').join('\n- ')}
`;
          return `${formattedString}`;
        }
      },
    },
    {
      field: 'device',
      headerName: 'Sym ID',
      filter: 'agTextColumnFilter',
      sortable: true,
    },
    {
      field: 'age',
      headerName: 'DTC Age',
      sortable: true,
      valueFormatter: (params: any) => params.value.replace(/\.\d{3}/, ''),
    },
  ]);

  const sortedData = [...data].sort((a, b) => {
    return dayjs(b.start_ts).isAfter(dayjs(a.start_ts)) ? 1 : -1;
  });

  return (
    <>
      {isLoading ? (
        <div
          style={{
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <SingleFadeLoader />
        </div>
      ) : (
        <PaddedListWrapper>
          {sortedData.length < 1 ? (
            <Typography
              sx={{
                color: enableDarkTheme
                  ? ThemePalette.typography.white
                  : ThemePalette.typography.black,
              }}
            >
              DTC Data not available
            </Typography>
          ) : (
            <BaseTableList
              rowData={sortedData}
              columnDefs={columnDefs}
              cacheQuickFilter={true}
            />
          )}
        </PaddedListWrapper>
      )}
    </>
  );
};
