import { createSlice, createSelector } from '@reduxjs/toolkit';
import type { RootState } from 'store';
import type { Asset } from 'types/asset.types';
import { composeUniqueKey } from 'utils/helpers/string.manipulation';

export interface fuelAnalyticsAssetListState {
  isLoading: boolean;
  assets: any[];
}

const initialState: fuelAnalyticsAssetListState = {
  isLoading: false,
  assets: [],
};

export const fuelAnalyticsAssetListSlice = createSlice({
  name: 'fuelAnalyticsAssetList',
  initialState,
  reducers: {
    setFetchingAsset(state) {
      return {
        ...state,
        isLoading: true,
      };
    },
    setAssetList(state, { payload }) {
      return {
        isLoading: false,
        assets: payload,
      };
    },
  },
});

export const { setAssetList, setFetchingAsset } =
  fuelAnalyticsAssetListSlice.actions;

/* Define input selectors */
export const selectAssets = (state: RootState) =>
  state.fuelAnalyticsAssetListReducer.assets;

/**
 * Selector for assets by make and model
 * @param {Asset[]} assets
 *
 * @description
 * 1. Filter assets with devices
 * 2. Create a hashmap of all valid make and models
 * 3. Add assets to hashmap with devices and tags
 * 4. Return hashmap
 */
export const selectFuelAnalyticsAssetByMakeModel = createSelector(
  [selectAssets],
  (assets) => {
    const map: Record<string, Asset[]> = {};
    const assetMakeModelMap = assets
      .filter((asset) => !asset.isDeleted)
      .reduce((acc, asset) => {
        /*
        find make and model
        we join make and model using `!` as a delimiter
        b/c we can guarantee it will not be included
        in an actual make or model
       */
        const makeModel = composeUniqueKey([asset.make, asset.model]);

        if (!acc[makeModel]) acc[makeModel] = [];

        acc[makeModel].push(asset);

        return acc;
      }, map);

    return assetMakeModelMap;
  }
);
export default fuelAnalyticsAssetListSlice.reducer;
