import { ContainerDiv, TwoColumnDiv, WrapperDiv } from './Productivity.styled';
import { useEffect, useRef, useState } from 'react';
import { formatFuelUsageData, formatTooltipValue } from './utils';
import { useAppSelector } from 'store/hook';
import { useLocation } from 'react-router-dom';
import { formatDateToString } from 'utils/helpers/dateTime';
import ReportsHandler from 'handlers/reports.handlers';
import { getGridDataPoints } from 'views/FleetOverview/components/AssetCardsV2/utils';
import {
  CartesianGrid,
  Cell,
  Label,
  Legend,
  Line,
  LineChart,
  Pie,
  PieChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { truncateTo2dp } from 'utils/helpers/general';
import { FadeLoader } from 'react-spinners';
import PageLengends from './Components/PageLegend';
import ReportTable from './Components/ReportTable';
import Header from './Components/Header';

const RADIAN = Math.PI / 180;
const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  index,
}: {
  cx: number;
  cy: number;
  midAngle: number;
  innerRadius: number;
  outerRadius: number;
  percent: number;
  index: number;
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5 - 12;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text
      x={x}
      y={y}
      fill="white"
      textAnchor={x > cx ? 'start' : 'end'}
      dominantBaseline="central"
    >
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

interface OutputData {
  date: string;
  haul_truck?: number;
  loader?: number;
}
interface PropTypes {
  unitSystem: string;
}
const FuelUsage = ({ unitSystem }: PropTypes) => {
  const reportsHandler = new ReportsHandler();

  const gridRef = useRef<any>(null);
  const { isLoading } = useAppSelector((state) => state.appReducer);

  const location = useLocation();
  const { startDate, endDate, pageType } = location.state || {};
  const {
    fuelUsage,
    loading: { fuelUsageLoading },
    score,
  } = useAppSelector((state) => state.exportDataReducer.reports);
  const [status, setStatus] = useState<
    | { success: 'Ideal Performance' }
    | { warning: 'Caution Advised' }
    | { error: 'Attention Required' }
  >({ success: 'Ideal Performance' });
  const [fleet, setFleet] = useState<Record<string, any>>({});

  useEffect(() => {
    if (score.fuelScore) {
      const val = getGridDataPoints(score);
      setFleet(getGridDataPoints(score));
      if (val.fleet.fuel <= 33.3) {
        setStatus({ error: 'Attention Required' });
      } else if (val.fleet.fuel > 33.3 && val.fleet.fuel <= 66.6) {
        setStatus({ warning: 'Caution Advised' });
      } else {
        setStatus({ success: 'Ideal Performance' });
      }
    }
  }, [score]);

  const getReports = async (startDate: Date, endDate: Date) => {
    const date1 = formatDateToString(startDate, 'YYYY-MM-DD');
    const date2 = formatDateToString(endDate, 'YYYY-MM-DD');
    await reportsHandler.getFuelUsageReport(date1, date2);
  };

  const [fuelUsageWithAsset, setFuelUsageWithAsset] = useState<any>([]);
  const [fuelUsageData, setFuelUsageData] = useState<
    Array<{
      name: string;
      value: number;
      color: string;
    }>
  >([]);

  const [lineChartData, setLineChartData] = useState<
    Array<{
      name: string;
      value: number;
      date: string;
    }>
  >([]);

  const totalFuel = 0;
  const unit = unitSystem === 'metric' ? ' Ltrs' : ' gal';

  useEffect(() => {
    const fuelUsageDt: Array<{
      name: string;
      value: number;
      color: string;
    }> = [];

    const lineChartDt: any = [];
    const fuelUsageScoreDt: number[] = [];
    const fuelUsageAsset = fuelUsage.map((item: any) => {
      let assetType: any;
      if (
        ['light_truck', 'heavy_truck', 'haul_truck'].includes(item.asset_type)
      ) {
        assetType = 'haul_truck';
      } else if (
        ['grader', 'excavator', 'drill', 'bolter', 'miscellaneous'].includes(
          item.asset_type
        )
      ) {
        assetType = 'others';
      } else {
        assetType = item.asset_type;
      }

      const newItem = {
        ...item,
        assetType,
        makeModel: `${item.make} ${item.model}`,
      };

      // if (asset) {
      const itemIndex = fuelUsageDt.findIndex((ut) => ut.name === assetType);
      if (itemIndex >= 0) {
        fuelUsageDt[itemIndex].value +=
          item.sum_fuel_consumption_device as number;
      } else {
        fuelUsageDt.push({
          name: assetType,
          value: item.sum_fuel_consumption_device,
          color:
            assetType === 'haul_truck'
              ? '#EB5757'
              : assetType === 'loader'
              ? '#497BFA'
              : assetType === 'others'
              ? '#009161'
              : '#000000',
        });
      }
      lineChartDt.push({
        name: assetType,
        date: item.customer_day,
        value: item.sum_fuel_consumption_device,
      });
      // }
      fuelUsageScoreDt.push(item.sum_fuel_consumption_device);
      return newItem;
    });
    setFuelUsageData(fuelUsageDt);
    setLineChartData(lineChartDt);
    setFuelUsageWithAsset(fuelUsageAsset);
  }, [fuelUsage]);

  useEffect(() => {
    void getReports(startDate, endDate);
  }, [pageType, startDate, endDate]);

  const sumFuel = fuelUsage.reduce(
    (accumulator: number, currentValue: Record<string, unknown>) =>
      accumulator + (currentValue.sum_fuel_consumption_device as number),
    totalFuel
  );

  const dataWithPercentages = fuelUsageData.map((item: any) => ({
    ...item,
    percentage: item.value / sumFuel,
  }));

  // // Group data by date and name
  const groupedData = lineChartData.reduce(
    (acc: any, { name, date, value }) => {
      if (!acc[date]) {
        acc[date] = { date };
      }
      if (!acc[date][name]) {
        acc[date][name] = 0;
      }
      (acc[date][name] as number) += value;
      return acc;
    },
    {}
  );

  const finalData: OutputData[] = Object.values(groupedData);

  lineChartData.sort(
    (a: any, b: any) => (new Date(a.date) as any) - (new Date(b.date) as any)
  );

  const columnDefs = [
    {
      headerName: 'Vehicle Type',
      field: 'type',
      rowGroup: true,
      hide: true,
      headerClass: 'custom-header',
    },
    { headerName: 'Model', field: 'model', headerClass: 'custom-header' },
    {
      headerName: `Total Usage (${unit})`,
      field: 'totalFuelConsumed',
      headerClass: 'custom-header',
    },
    {
      headerName: `Avg Usage (${unit})`,
      field: 'avgFuelUsage',
      headerClass: 'custom-header',
    },
    {
      headerName: ' ',
      field: 'averageFuelPerHour',
      headerClass: 'custom-header',
    },
  ];

  const defaultColDef = {
    sortable: false,
    filter: false,
    resizable: true,
  };

  const autoGroupColumnDef = {
    headerName: 'Vehicle Type',
    field: 'type',
    cellRendererParams: {
      suppressCount: true,
    },
    headerClass: 'custom-header',
  };

  const rowData =
    fuelUsageWithAsset.length > 0
      ? formatFuelUsageData(fuelUsageWithAsset)
      : [];

  useEffect(() => {
    if (gridRef.current ?? gridRef.current?.api) {
      const allColumnIds: any = [];
      gridRef.current.columnApi?.getAllColumns().forEach((column: any) => {
        allColumnIds.push(column.getId());
      });
      gridRef.current.columnApi?.autoSizeColumns(allColumnIds);
    }
  }, [gridRef.current]);

  return (
    <WrapperDiv>
      <ContainerDiv>
        {isLoading || fuelUsageLoading ? (
          <FadeLoader
            style={{
              height: '100vh',
              backgroundColor: 'transparent',
              position: 'absolute',
              width: '100%',
              zIndex: 9999,
              top: '50%',
              left: '50%',
            }}
            color="black"
            loading={isLoading}
          />
        ) : (
          <>
            <Header
              startDate={startDate}
              endDate={endDate}
              fleet={fleet}
              status={status}
              title="Fuel Usage"
            />

            <TwoColumnDiv>
              <div style={{ width: '30%', margin: '0 0 0 0' }}>
                <p style={{ fontStyle: 'italic', color: '#333333' }}>
                  Fuel Usage by asset type
                </p>
                {fuelUsageData.length > 0 && (
                  <PieChart width={800} height={400}>
                    <Pie
                      data={dataWithPercentages}
                      cx={120}
                      cy={100}
                      innerRadius={60}
                      outerRadius={90}
                      fill="#8884d8"
                      dataKey="value"
                      labelLine={false}
                      label={renderCustomizedLabel}
                    >
                      <Label
                        value="Total"
                        position="center"
                        fontSize={'16px'}
                        fill="rgba(0,0,0, .5)"
                        style={{
                          transform: 'translateY(-25px)',
                        }}
                      />
                      <Label
                        value={truncateTo2dp(sumFuel)}
                        position="centerBottom"
                        fontSize={'25px'}
                        fontWeight="bold"
                        fill="#0063BE"
                        style={{
                          transform: 'translateY(15px)',
                        }}
                      />
                      <Label
                        value={`${unit}`}
                        position="center"
                        fontSize={'16px'}
                        fill="rgba(0,0,0, .5)"
                        style={{
                          transform: 'translateY(35px)',
                        }}
                      />
                      {fuelUsageData.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={entry.color} />
                      ))}
                    </Pie>
                    <Tooltip
                      formatter={(value) => formatTooltipValue(+value)}
                    />
                  </PieChart>
                )}
              </div>
              <div style={{ width: '60%' }}>
                <p style={{ fontStyle: 'italic', color: '#333333' }}>
                  Usage by day
                </p>
                <ResponsiveContainer width="95%" height={300}>
                  <LineChart
                    data={finalData}
                    margin={{
                      top: 5,
                      right: 20,
                      left: 10,
                      bottom: 5,
                    }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="date" />
                    <YAxis
                      label={{
                        value: 'Daily Fuel Consumption',
                        angle: -90,
                        position: 'insideLeft',
                        dy: 80,
                        dx: 0,
                      }}
                    />
                    <Tooltip
                      formatter={(value) => formatTooltipValue(+value)}
                    />
                    <Legend />

                    <Line
                      type="monotone"
                      dataKey="haul_truck"
                      stroke="#EB5757"
                      activeDot={{ r: 8 }}
                    />
                    <Line type="monotone" dataKey="loader" stroke="#497BFA" />
                    <Line type="monotone" dataKey="others" stroke="#009161" />
                  </LineChart>
                </ResponsiveContainer>
              </div>
            </TwoColumnDiv>

            <PageLengends />
            <ReportTable
              gridRef={gridRef}
              columnDefs={columnDefs}
              defaultColDef={defaultColDef}
              rowData={rowData}
              autoGroupColumnDef={autoGroupColumnDef}
            />
          </>
        )}
      </ContainerDiv>
    </WrapperDiv>
  );
};

export default FuelUsage;
