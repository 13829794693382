import { type ICreateSquaresPayload } from 'types/payloads/squares.payload.types';
import BaseAPI from '../../base.api';

export default class SquaresAPI extends BaseAPI {
  /**
   * Gets all operators the current user has access to
   * @returns A list of operators
   */

  getSelectedAssetsPmExpenditures = async (payload: ICreateSquaresPayload) => {
    const response = await this.API.post('squares/getPmExpenditures', payload);
    return response.data;
  };

  getSelectedAssetsComponentPmCost = async (payload: ICreateSquaresPayload) => {
    const response = await this.API.post('squares/getComponentPmCost', payload);
    return response.data;
  };

  getSelectedAssetsPmVsCmCost = async (payload: ICreateSquaresPayload) => {
    const response = await this.API.post('squares/getPmVsCmCost', payload);
    return response.data;
  };

  getAllAssets = async (payload: ICreateSquaresPayload) => {
    const response = await this.API.post('squares/getAllAssets', payload);
    return response.data;
  };

  getAllComponentsCategory = async () => {
    const response = await this.API.post('squares/getAllComponentsCategory');
    return response.data;
  };

  uploadFile = async (file: File) => {
    const formData = new FormData();
    formData.append('file', file);

    const response = await this.API.post('squares/upload', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    return response.data;
  };
}
