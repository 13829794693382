import { ThemePalette } from 'mui.theme';
import React, { useEffect, useState } from 'react';

const LoadingDots = ({
  fontSize = 13,
  enableDarkTheme,
}: {
  fontSize?: number;
  enableDarkTheme: boolean;
}) => {
  const [dots, setDots] = useState('.');

  useEffect(() => {
    const interval = setInterval(() => {
      setDots((prev) => (prev.length < 3 ? prev + '.' : '.'));
    }, 500);
    return () => {
      clearInterval(interval);
    };
  }, []);

  const loadingDotsStyle: React.CSSProperties = {
    display: 'inline-block',
    fontSize: `${fontSize}px`,
    lineHeight: '1em',
    color: enableDarkTheme
      ? ThemePalette.typography.wheat
      : ThemePalette.typography.black,
  };

  return <div style={loadingDotsStyle}>{dots}</div>;
};

export default LoadingDots;
