import * as React from 'react';
import { BaseTableList } from 'components/AgGrid/AgGrid';
import {
  DeleteAccount,
  CustomerName,
  PrimaryContact,
  InternalUsers,
  CustomerType,
  internalAccountingLink,
} from './CustomerListCellRenderer';
import { PaddedListWrapper } from 'components/AgGrid/CellRenderer/ListView.styled';
import { useAppSelector } from 'store/hook';
import { type RootState } from 'store';

export const CustomersList = () => {
  const { customers } = useAppSelector(
    (state: RootState) => state.customerReducer
  );

  const [columnDefs] = React.useState([
    {
      field: 'customerName',
      headerName: 'Customer Name / Views',
      minWidth: 340,
      cellRenderer: CustomerName,
    },
    {
      field: 'accountNumber',
      headerName: 'Account #',
      cellRenderer: internalAccountingLink,
    },
    {
      field: 'customerType',
      headerName: 'Type',
      suppressSizeToFit: true,
      cellRenderer: CustomerType,
    },
    {
      field: 'primaryContact',
      headerName: 'Primary Customer Contact',
      cellRenderer: PrimaryContact,
    },
    {
      field: 'phoneNumber',
      headerName: 'Customer Phone #',
    },
    {
      field: 'internalUsers',
      headerName: 'Assigned Symboticware User(s)',
      cellRenderer: InternalUsers,
    },
    {
      field: 'unitSystem',
      headerName: 'Unit System',
    },
    {
      field: 'id',
      headerName: 'Delete Account',
      cellRenderer: DeleteAccount,
      cellStyle: { textAlign: 'center' },
    },
  ]);

  return (
    <PaddedListWrapper>
      <BaseTableList rowData={customers} columnDefs={columnDefs} />
    </PaddedListWrapper>
  );
};
