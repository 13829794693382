import { SWTextField } from 'components/Form/Input/SWTextField';
import { Selector } from 'components/Form/Selectors/Selector';
import { InteractiveModal } from 'components/Modals/InteractiveModal/BaseModal';
import { useEffect, useState } from 'react';
import { modalInputFieldStyle } from 'styles/global.css';
import { validateYupSchema } from 'utils/validators/schema.validator';
import { type Operator } from 'types/operator.types';
import { type ICreateOperatorPayload } from 'types/payloads/operator.payload.types';
import { createOrUpdateOperatorRequestBodySchema } from 'utils/schemas/operator.schema';
import OperatorHandler from 'handlers/operator.handler';
import {
  handleSelectOperatorAssignedAssets,
  handleSelectOperatorHierarchyNode,
} from '../../helpers/common';
import { Box, Typography } from '@mui/material';
import { useAppSelector } from 'store/hook';

interface Props {
  /**
   * Title of the Modal
   */
  title: string;
  /**
   * Boolean Value to determine if modal is displpayed or not
   */
  open: boolean;
  /**
   * User Data modal is to render
   */
  operatorData: Operator;
  /**
   * Function to call to close modal
   */
  handleAbort: () => void;
}

export const UpdateOperatorModal = ({
  title,
  open,
  handleAbort,
  operatorData,
}: Props) => {
  const operatorHandler = new OperatorHandler();

  const [isValidForm, setFormValidity] = useState(false);
  const [operator, setOperator] = useState<ICreateOperatorPayload>({
    operatorName: operatorData.operatorName,
    customerId: operatorData.customerId,
    nodeId: {
      id: '',
      display: '',
    },
    isActive: operatorData.isActive,
    assetId: operatorData.assetId || '',
  });

  /* THIS DATA IS STUBBED AND WILL BE REPLACED WITH REAL DATA */
  const nodeIdState: Array<Operator['nodeId']> = [];

  const { assets } = useAppSelector((state) => state.assetReducer);
  const transformedAssets: Array<{ id: string; display: string }> = assets.map(
    (asset) => {
      return {
        id: asset.assetId,
        display: asset.bumperNumber,
      };
    }
  );

  // Validate form as user fills out fields
  useEffect(() => {
    const validateForm = async () =>
      await validateYupSchema(
        operator,
        createOrUpdateOperatorRequestBodySchema
      );

    void validateForm().then((isValid) => {
      setFormValidity(isValid);
    });
  }, [operator]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    setOperator({
      ...operator,
      [name]: value,
    });
  };

  const handleSuccess = async () => {
    await operatorHandler.updateOperator(operatorData.operatorId, operator);

    handleAbort();
  };

  return (
    <InteractiveModal
      initialState={open}
      SuccessButtonProps={{
        disabled: !isValidForm,
        onClick: handleSuccess,
        label: 'EDIT USER',
      }}
      handleAbort={handleAbort}
      title={title}
      confirmCancellation
    >
      {/* First Name TextField */}
      <SWTextField
        key="operator-name"
        name="operatorName"
        label="Operator ID*"
        TextFieldProps={{
          name: 'operatorName',
          value: operator.operatorName,
          style: { minWidth: '258px' },
          onChange: handleChange,
          InputProps: {
            sx: modalInputFieldStyle,
          },
        }}
      />
      <br />

      {/* Permission selector drop down single */}
      <Box key="nodeId">
        <Typography variant="bodyBold" display="block" mb={'4px'}>
          Hierarchy Node
        </Typography>
        <Selector
          value={operator.nodeId.id}
          onChange={(event) => {
            setOperator({
              ...operator,
              nodeId: handleSelectOperatorHierarchyNode(
                event,
                operator,
                nodeIdState
              ),
            });
          }}
          selectorOptions={nodeIdState}
        />
      </Box>
      {/* Permission selector drop down single */}
      <Box key="assetId">
        <Typography variant="bodyBold" display="block" mb={'4px'}>
          Assigned Asset
        </Typography>
        <Selector
          value={operator.assetId}
          onChange={(event) => {
            const updatedOperatorAssignedAssets =
              handleSelectOperatorAssignedAssets(
                event,
                operator,
                transformedAssets
              );
            setOperator({
              ...operator,
              assetId: updatedOperatorAssignedAssets.assetId,
            });
          }}
          selectorOptions={transformedAssets}
          disabled={!operator.isActive}
        />
      </Box>
    </InteractiveModal>
  );
};
