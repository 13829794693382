import { Base } from 'components/Containers/BaseContainer';
import { ToolbarContainer } from 'components/Containers/ToolbarContainer';
import { useAppSelector } from 'store/hook';
import { FlexBox } from 'components/Containers/FlexBox';
import { DTCList } from './Components/DTCList';
import { isDarkTheme } from 'utils/theme';

export const DTCView = (): JSX.Element => {
  const theme = useAppSelector((state) => state.authReducer).customer.theme;
  const enableDarkTheme = isDarkTheme(theme);
  return (
    <Base enableDarkTheme={enableDarkTheme}>
      <ToolbarContainer enableDarkTheme={enableDarkTheme}>
        <FlexBox
          sx={{
            width: '100%',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        ></FlexBox>
      </ToolbarContainer>
      <DTCList enableDarkTheme={enableDarkTheme} />
    </Base>
  );
};
