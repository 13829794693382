import {
  createFilterInfo,
  prepareCsvFile,
  preparePdfFile,
} from '../../Helpers/prepareFile';
import { type Assets } from '../modals/assets.modals';
import SquaresHandler from 'handlers/squaresHandlers/squares.handler';
import { useDispatch } from 'react-redux';

export const getUniqueComponents = (assets: Assets[]) => {
  if (assets && Array.isArray(assets)) {
    const uniqueComponents = new Set<string>();
    assets.forEach((asset) => uniqueComponents.add(asset.components));
    return Array.from(uniqueComponents);
  }
  return [];
};

export function filterAssetsByComponent(
  selectedComponent: string,
  allAssets: Assets[]
) {
  if (allAssets && Array.isArray(allAssets)) {
    return allAssets.filter((asset) => asset.components === selectedComponent);
  }
  return [];
}

export function getCategoryValue(
  data: any[],
  category: string,
  costType: string
) {
  if (!Array.isArray(data)) {
    console.error('Expected an array from the database, received:', data);
    return null; // or return a default value
  }
  const categoryData = data.find((item) => item.category === category);
  return categoryData ? categoryData[costType] : null;
}
