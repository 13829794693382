import * as React from 'react';
import { BaseTableList } from 'components/AgGrid/AgGrid';
import {
  DeleteAccount,
  UserName,
  ResetPassword,
  UserStatus,
  PlainSpan,
} from './ExternalUserListCellRenderer';
import { PaddedListWrapper } from 'components/AgGrid/CellRenderer/ListView.styled';
import { UpdateExternalUserModal } from './modals/UpdateExternalUserModal';
import { ExternalUserActivationModal } from './modals/ActivateExternalUserModal';
import { ExternalUserDeactivationModal } from './modals/DeactiveExternalUserModal';
import { type RootState } from 'store';
import { WithIconRenderer } from 'components/AgGrid/CellRenderer/WithIconRenderer';
import { type ExternalUser } from 'types/user.types';
import { copyToClipboard } from 'utils/helpers/general';
import { useAppDispatch, useAppSelector } from 'store/hook';
import { isExternalUser } from 'utils/validators/role.validation';
import { showNotification } from 'store/notification.slice';

enum OpenModal {
  None,
  EditUser,
  ActivateUser,
  DeactivateUser,
}

interface Props {
  showInactiveUsers: boolean;
}

/**
 * A table component that renders a list of external users.
 */
export const ExternalUsersList = (props: Props) => {
  const dispatch = useAppDispatch();
  const [selectedUserId, setSelectedUserId] = React.useState('');
  const [modalOpen, setModalOpen] = React.useState(OpenModal.None);

  // Get a list of external users from Redux
  const { externalUserList: externalUserListState } = useAppSelector(
    (state: RootState) => state.externalUserReducer
  );
  const { role } = useAppSelector((state) => state.authReducer);
  // if the user is an external user, disable certain fields
  const isDisabled = isExternalUser(role);

  // Store the external user list in react state
  const rowData = React.useMemo(
    () => externalUserListState,
    [externalUserListState, props.showInactiveUsers]
  );

  // TODO: Refactor this into a separate file
  // Column definitions for the table
  const [columnDefs] = React.useState([
    {
      field: 'firstName',
      headerName: 'User Name',
      maxWidth: 350,
      cellRenderer: UserName,
      cellRendererParams: {
        handleNameClick: (userId: string) => {
          if (isDisabled) return;

          setSelectedUserId(userId);
          setModalOpen(OpenModal.EditUser);
        },
      },
      sortable: true,
    },
    {
      field: 'userId',
      headerName: 'User Id#',
      width: 50,
      maxWidth: 150,
      cellRenderer: WithIconRenderer,
      cellRendererParams: {
        field: 'userId',
        iconName: 'copyIcon',
        maxLength: 8,
        onIconClick: async (data: ExternalUser) => {
          const didCopy = await copyToClipboard(data.userId);

          // Failed to copy to clipboard, return early
          if (!didCopy) return;

          // Show notification
          dispatch(
            showNotification({
              message: 'Copied to clipboard',
              type: 'success',
            })
          );
        },
      },
    },
    {
      field: 'role',
      headerName: 'Permission Level',
      suppressSizeToFit: true,
      maxWidth: 300,
    },
    {
      field: 'customer.customerName',
      headerName: 'Company',
      suppressSizeToFit: true,
      maxWidth: 300,
    },
    {
      field: 'isActive',
      headerName: 'Status',
      maxWidth: 150,
      cellRenderer: UserStatus,
      cellRendererParams: {
        handleActivateAccount: (userId: string) => {
          if (isDisabled) return;

          setSelectedUserId(userId);
          setModalOpen(OpenModal.ActivateUser);
        },
      },
    },
    {
      field: 'email',
      headerName: 'Email Address',
    },
    {
      field: 'userId',
      headerName: 'Reset Password',
      cellRenderer: ResetPassword,
      width: 50,
      maxWidth: 180,
      cellStyle: { textAlign: 'center' },
      cellRendererParams: {
        isDisabled,
      },
    },
    {
      field: 'userId',
      headerName: 'Deactivate User',
      cellRenderer: DeleteAccount,
      cellRendererParams: {
        handleDeleteAccount: (userId: string) => {
          if (isDisabled) return;

          setSelectedUserId(userId);
          setModalOpen(OpenModal.DeactivateUser);
        },
      },
      maxWidth: 180,
      cellStyle: { textAlign: 'center' },
    },
    {
      field: 'userId',
      headerName: '',
      maxWidth: 90,
      cellRenderer: PlainSpan,
    },
  ]);

  const selectedUser = rowData.find(
    (user) => user.userId === selectedUserId
  ) ?? {
    userId: '',
    firstName: '',
    lastName: '',
    email: '',
    isActive: true,
    customerAccessLevel: 'external-user',
  };

  return (
    <>
      {/*
       * START Modals
       * Modals are the popup dialogs that users see
       */}
      <UpdateExternalUserModal
        key={`${modalOpen.toString()}-create-user-modal`}
        open={modalOpen === OpenModal.EditUser}
        handleAbort={() => {
          setModalOpen(OpenModal.None);
        }}
        title="Edit User"
        userData={selectedUser}
      />
      <ExternalUserActivationModal
        key={`${modalOpen.toString()}-activate-user-modal}`}
        firstName={selectedUser?.firstName}
        lastName={selectedUser?.lastName}
        userId={selectedUser?.userId}
        open={modalOpen === OpenModal.ActivateUser}
        handleAbort={() => {
          setModalOpen(OpenModal.None);
        }}
      />

      <ExternalUserDeactivationModal
        key={`${modalOpen.toString()}-deactivate-user-modal}`}
        firstName={selectedUser?.firstName}
        lastName={selectedUser?.lastName}
        userId={selectedUser?.userId}
        open={modalOpen === OpenModal.DeactivateUser}
        handleAbort={() => {
          setModalOpen(OpenModal.None);
        }}
      />
      {/* END Modals */}
      <PaddedListWrapper>
        <BaseTableList
          rowData={rowData}
          columnDefs={columnDefs}
          // The filter is not present when showing all users
          // Filter enabled -> Active users
          // Filter disabled -> Active + Inactive users
          isExternalFilterPresent={!props.showInactiveUsers}
          filter={(node: any) => {
            // Always return true if showInactiveUsers is true
            // Otherwise filter the list to users that are active
            return !!node.data?.isActive;
          }}
        />
      </PaddedListWrapper>
    </>
  );
};
