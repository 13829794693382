export interface IAppConfiguration {
  isDevelopment: boolean;
  isProduction: boolean;
  isStaging: boolean;
}

export const appConfiguration: IAppConfiguration = {
  isDevelopment: process.env.REACT_APP_ENV === 'development',
  isProduction: process.env.REACT_APP_ENV === 'production',
  isStaging: process.env.REACT_APP_ENV === 'staging',
};

if (process.env.REACT_APP_ENV === undefined) {
  throw new Error('REACT_APP_ENV variable is missing');
}
