/* eslint-disable @typescript-eslint/promise-function-async */
import {
  type AxiosInstance,
  type AxiosRequestConfig,
  type AxiosError,
  type AxiosPromise,
} from 'axios';

const withLogger = <T>(promise: AxiosPromise<T>) =>
  promise.catch((error: AxiosError) => {
    /*
      always log errors if not in dev env
    */
    if (process.env.NODE_ENV !== 'development') throw error;

    if (error.response !== undefined || error.response !== null) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      console.log(error.response?.data);
      console.log(error);
    } else if (error.request !== undefined || error.request !== null) {
      // The request was made but no response was received
      console.log(error.request);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.log('Error', error.message);
    }

    console.log(error.config);

    throw error;
  });

export const api = (axios: AxiosInstance) => {
  return {
    get: <T>(url: string, config: AxiosRequestConfig = {}) =>
      withLogger<T>(axios.get<T>(url, config)),
    post: <T>(url: string, body: unknown, config: AxiosRequestConfig = {}) =>
      withLogger<T>(axios.post<T>(url, body, config)),
    patch: <T>(url: string, body: unknown, config: AxiosRequestConfig = {}) =>
      withLogger<T>(axios.patch<T>(url, body, config)),
    put: <T>(url: string, body: unknown, config: AxiosRequestConfig = {}) =>
      withLogger<T>(axios.put<T>(url, body, config)),
  };
};
