import * as yup from 'yup';
import { emailValidation, nameValidation } from './reusable-fields';

export const createOrUpdateInternalUserRequestBodySchema = yup
  .object({
    body: yup.object({
      firstName: nameValidation(),
      lastName: nameValidation(),
      email: yup
        .string()
        .matches(/^[A-Za-z0-9._-]+$/, 'Invalid email address.')
        .required(),
      department: yup
        .string()
        .oneOf(['customer_service', 'sales', 'product', 'other'])
        .required('Invalid department.'),
      userCustomerAssociations: yup.array().of(
        yup.object({
          id: yup.string().required(),
          display: yup.string().required(),
        })
      ),
    }),
  })
  .required();

export const createOrUpdateExternalUserRequestBodySchema = yup
  .object({
    body: yup.object({
      firstName: nameValidation(),
      lastName: nameValidation(),
      email: emailValidation(),
      customerAccessLevel: yup
        .string()
        .oneOf(['external-user', 'external-admin'])
        .required(),
    }),
  })
  .required();
