import BaseAPI from 'api/base.api';

export default class SquaresAPI extends BaseAPI {
  uploadFile = async (file: File) => {
    const formData = new FormData();
    formData.append('file', file);

    const response = await this.API.post('squares/upload', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    return response.data;
  };

  uploadWorkOrder = async (file: File, customer: string) => {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('customerName', customer); // Include customerName in the payload

    const response = await this.API.post('squares/uploadWorkOrder', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    return response.data;
  };
}
