import { SVG } from 'components/Asset/SVG';
import { useAppSelector } from 'store/hook';
import { LinkButton } from 'components/Buttons/LinkButton';
import { FlexBox } from 'components/Containers/FlexBox';
import { CreateOperatorModal } from './components/modals/CreateOperatorModal';
import { useState } from 'react';
import { OperatorsList } from './components/OperatorList';
import { FormControlLabel } from '@mui/material';
import StyledSwitchButton from 'components/Buttons/SwitchButton';
import { isDarkTheme } from 'utils/theme';
import { ThemePalette } from 'mui.theme';

/**
 * The Operator view is a tab under "Users & Assets" section,
 * it will allow external users to view/add/edit a new/existing operator
 */
export const OperatorListView = () => {
  const [isOperatorModalOpen, setIsOperatorModalOpen] = useState(false);
  const [showInactiveOperators, setShowInactiveOperators] = useState(true);
  const theme = useAppSelector((state) => state.authReducer).customer.theme;
  const enableDarkTheme = isDarkTheme(theme);

  return (
    <>
      <CreateOperatorModal
        key={`${isOperatorModalOpen.toString()}-create-operator`}
        open={isOperatorModalOpen}
        handleAbort={() => {
          setIsOperatorModalOpen(false);
        }}
        title="Add a New Operator"
        enableDarkTheme={enableDarkTheme}
      />

      <FlexBox
        sx={{
          width: '100%',
          justifyContent: 'space-between',
        }}
      >
        <div></div>
        <FlexBox>
          <FlexBox
            sx={{
              display: 'flex',
              marginRight: '2rem',
            }}
          >
            <FormControlLabel
              control={<StyledSwitchButton />}
              label="Show Inactive Operators"
              labelPlacement="start"
              checked={showInactiveOperators}
              onChange={() => {
                // Flip the value
                setShowInactiveOperators((last) => !last);
              }}
              sx={{
                color: enableDarkTheme
                  ? ThemePalette.typography.wheat
                  : ThemePalette.typography.black,
              }}
            />
          </FlexBox>
          <FlexBox
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <SVG
              name="add"
              style={{ marginRight: '10px' }}
              onClick={() => {
                // open modal
                setIsOperatorModalOpen(true);
              }}
            />

            <LinkButton
              onClick={() => {
                // open modal
                setIsOperatorModalOpen(true);
              }}
              sx={{
                color: enableDarkTheme
                  ? ThemePalette.typography.wheat
                  : ThemePalette.typography.black,
              }}
            >
              Add a New Operator
            </LinkButton>
          </FlexBox>
        </FlexBox>
      </FlexBox>
      <OperatorsList showInactiveOperators={showInactiveOperators} />
    </>
  );
};
