import { ReactComponent as SidebarAnalytics } from 'assets/icons/sidebar-analytics.svg';
import { ReactComponent as SidebarFleetOverview } from 'assets/icons/sidebar-fleet-overview.svg';
import { ReactComponent as SidebarUtilization } from 'assets/icons/side-bar-utilization.svg';
import { ReactComponent as SidebarFuelUsage2 } from 'assets/icons/side-bar-fuel-usage.svg';
import { ReactComponent as SidebarProduction } from 'assets/icons/side-bar-production.svg';
import { ReactComponent as SidebarNotificationCenter } from 'assets/icons/side-bar-notification-center.svg';
import { ReactComponent as SidebarVehicleStatus } from 'assets/icons/side-bar-vehicle-status.svg';
import { ReactComponent as SidebarFleetOverview2 } from 'assets/icons/side-bar-fleet-overview.svg';
import { ReactComponent as SidebarFuelUsage } from 'assets/icons/Icon_nav_fuel_usage.svg';
import { ReactComponent as SidebarSettings } from 'assets/icons/sidebar-settings.svg';
import { ReactComponent as SidebarSquares } from 'assets/icons/sidebar-squares.svg';
import { ReactComponent as SidebarSWLogo } from 'assets/icons/sidebar-sw-logo.svg';
import { ReactComponent as DeleteIcon } from 'assets/icons/delete.svg';
import { ReactComponent as TableView } from 'assets/icons/icon-table.svg';
import { ReactComponent as HierachyView } from 'assets/icons/icon-hierarchy.svg';
import { ReactComponent as EnvelopeIcon } from 'assets/icons/email-icon.svg';
import { ReactComponent as ResetIcon } from 'assets/icons/reset-icon.svg';
import { ReactComponent as ActiveIcon } from 'assets/icons/active-icon.svg';
import { ReactComponent as InActiveIcon } from 'assets/icons/icon-remove.svg';
import { ReactComponent as OfflineIcon } from 'assets/icons/icon-offline.svg';
import { ReactComponent as GreenIcon } from 'assets/icons/green-icon.svg';
import { ReactComponent as RedIcon } from 'assets/icons/red-icon.svg';
import { ReactComponent as AddIcon } from 'assets/icons/add.svg';
import { ReactComponent as ViewGridIcon } from 'assets/icons/icon_views_grid_view.svg';
import { ReactComponent as ViewTableIcon } from 'assets/icons/icon_views_table_view.svg';
import { ReactComponent as ViewMapIcon } from 'assets/icons/icon_views_map_view.svg';
import { ReactComponent as CopyIcon } from 'assets/icons/icon-copy.svg';
import { ReactComponent as AddDisabledIcon } from 'assets/icons/add-disabled.svg';
import { ReactComponent as UnlinkIcon } from 'assets/icons/icon-unlink.svg';
import { ReactComponent as UnlinkedDeviceStatusIcon } from 'assets/icons/icon-device-status-unlinked.svg';
import { ReactComponent as InfoTooltipIcon } from 'assets/icons/icon-info-tooltip.svg';
import { ReactComponent as NotificationIcon } from 'assets/icons/icon_interface_notification.svg';
import { ReactComponent as CancelIcon } from 'assets/icons/x-thin.svg';

// logos
import { ReactComponent as SidebarLogoMain } from 'assets/logos/sidebar-logo-main.svg';
import { ReactComponent as SymboticwareLogoFull } from 'assets/logos/sw-logo-full.svg';
import { ReactComponent as SymboticwareLogoFullDirectionColumn } from 'assets/logos/full-logo-direction-column.svg';
import { ReactComponent as BuzziReportLogo } from 'assets/logos/buzzi.svg';

// bckgrounds
import { ReactComponent as BackgroundImage } from 'assets/bg/sidebar-bg.svg';

// vehicles
import { ReactComponent as BolterImage } from 'assets/vehicles/icon_assets_bolter.svg';
import { ReactComponent as DozerImage } from 'assets/vehicles/icon_assets_dozer.svg';
import { ReactComponent as ExcavatorImage } from 'assets/vehicles/icon_assets_excavator.svg';
import { ReactComponent as HaulTruckImage } from 'assets/vehicles/icon_assets_haul_truck.svg';
import { ReactComponent as HeavyTruckImage } from 'assets/vehicles/icon_assets_heavy_truck.svg';
import { ReactComponent as JumboDrillImage } from 'assets/vehicles/icon_assets_jumbo_drill.svg';
import { ReactComponent as LightTruckImage } from 'assets/vehicles/icon_assets_light_truck.svg';
import { ReactComponent as LoaderImage } from 'assets/vehicles/icon_assets_loader.svg';
import { ReactComponent as PumpImage } from 'assets/vehicles/icon_assets_pump.svg';
import { ReactComponent as VerticalDrillImage } from 'assets/vehicles/icon_assets_vert_drill.svg';

import {
  type CSSProperties,
  type FunctionComponent,
  type SVGProps,
} from 'react';

const icons: Record<
  string,
  FunctionComponent<SVGProps<SVGSVGElement> & { title?: string }>
> = {
  sidebarAnalytics: SidebarAnalytics,
  sidebarFleetOverview: SidebarFleetOverview,
  sidebarSquares: SidebarSquares,
  sidebarSymboticwareLogo: SidebarSWLogo,
  sidebarSettings: SidebarSettings,
  deleteIcon: DeleteIcon,
  tableView: TableView,
  hierachyView: HierachyView,
  envelopeIcon: EnvelopeIcon,
  resetIcon: ResetIcon,
  activeIcon: ActiveIcon,
  greenIcon: GreenIcon,
  redIcon: RedIcon,
  inActiveIcon: InActiveIcon,
  offlineIcon: OfflineIcon,
  viewGridIcon: ViewGridIcon,
  viewTableIcon: ViewTableIcon,
  viewMapIcon: ViewMapIcon,
  copyIcon: CopyIcon,
  unlinkIcon: UnlinkIcon,
  unlinkedDeviceStatusIcon: UnlinkedDeviceStatusIcon,
  infoTooltipIcon: InfoTooltipIcon,
  sidebarFuelUsage: SidebarFuelUsage,
  notificationIcon: NotificationIcon,
  sidebarUtilization: SidebarUtilization,
  sidebarFuelUsage2: SidebarFuelUsage2,
  sidebarProduction: SidebarProduction,
  sidebarNotificationCenter: SidebarNotificationCenter,
  sidebarVehicleStatus: SidebarVehicleStatus,
  sidebarFleetOverview2: SidebarFleetOverview2,

  // icons
  add: AddIcon,
  addDisabled: AddDisabledIcon,
  cancelIcon: CancelIcon,

  // logos
  sidebarLogoMain: SidebarLogoMain,
  symboticwareLogoFull: SymboticwareLogoFull,
  symboticwareLogoFullDirectionColumn: SymboticwareLogoFullDirectionColumn,
  buzziReportLogo: BuzziReportLogo,

  // backgrounds
  bg: BackgroundImage,

  // vehicles
  bolterImage: BolterImage,
  dozerImage: DozerImage,
  graderImage: DozerImage,
  excavatorImage: ExcavatorImage,
  haulTruckImage: HaulTruckImage,
  heavyTruckImage: HeavyTruckImage,
  jumboDrillImage: JumboDrillImage,
  lightTruckImage: LightTruckImage,
  loaderImage: LoaderImage,
  pumpImage: PumpImage,
  verticalDrillImage: VerticalDrillImage,
};

interface Props extends SVGProps<SVGSVGElement> {
  name?: string;
  isHovering?: boolean;
  style?: CSSProperties;
}

export const SVG = ({
  name = '', // TODO change this to generic
  isHovering = false,
  style,
  ...rest
}: Props): JSX.Element | null => {
  const Icon = icons[name];

  if (!Icon) return null;

  return <Icon {...rest} style={style} />;
};
