import * as React from 'react';
import { BaseTableList } from 'components/AgGrid/AgGrid';
import { UpdateOperatorModal } from './modals/UpdateOperatorModal';
import { OperatorActivationModal } from './modals/ActivateOperatorModal';
import { OperatorDeactivationModal } from './modals/DeactiveOperatorModal';
import {
  DeleteAccount,
  OperatorName,
  OperatorStatus,
  PlainSpan,
} from './modals/OperatorListCellRenderer';
import { ListWrapper } from 'components/AgGrid/CellRenderer/ListView.styled';
import { useAppSelector } from 'store/hook';
import { WithIconRenderer } from 'components/AgGrid/CellRenderer/WithIconRenderer';
enum OpenModal {
  None,
  EditOperator,
  ActivateOperator,
  DeactivateOperator,
}
interface Props {
  showInactiveOperators: boolean;
}
export const OperatorsList = (props: Props) => {
  const [selectedOperatorId, setSelectedOperatorId] = React.useState('');
  const [modalOpen, setModalOpen] = React.useState(OpenModal.None);

  const { operators } = useAppSelector((state) => state.operatorReducer);

  // Store the operator list in react state
  const rowData = React.useMemo(
    () => operators,
    [operators, props.showInactiveOperators]
  );

  const [columnDefs] = React.useState([
    {
      field: 'operator',
      headerName: 'Operator',
      maxWidth: 300,
      cellRenderer: OperatorName,
      cellRendererParams: {
        handleNameClick: (operatorId: string) => {
          setSelectedOperatorId(operatorId);
          setModalOpen(OpenModal.EditOperator);
        },
      },
    },

    {
      field: 'assetBumperNumber',
      headerName: 'Bumper ID',
      minWidth: 160,
    },
    {
      field: 'assetId',
      headerName: 'Asset ID',
      minWidth: 170,
      cellRenderer: WithIconRenderer,
      cellRendererParams: {
        field: 'assetId',
        iconName: 'copyIcon',
        maxLength: 8,
      },
    },
    {
      field: 'isActive',
      headerName: 'Status',
      maxWidth: 150,
      cellRenderer: OperatorStatus,
      cellRendererParams: {
        handleActivateAccount: (operatorId: string) => {
          setSelectedOperatorId(operatorId);
          setModalOpen(OpenModal.ActivateOperator);
        },
      },
    },
    {
      field: 'operatorId',
      headerName: 'Deactivate Operator',
      cellRenderer: DeleteAccount,
      cellRendererParams: {
        handleDeleteAccount: (operatorId: string) => {
          setSelectedOperatorId(operatorId);
          setModalOpen(OpenModal.DeactivateOperator);
        },
      },
      width: 50,
      maxWidth: 250,
      cellStyle: { textAlign: 'center' },
    },
    {
      field: 'operatorId',
      headerName: '',
      maxWidth: 60,
      cellRenderer: PlainSpan,
    },
  ]);

  const selectedOperator = operators.find(
    (operator) => operator.operatorId === selectedOperatorId
  );

  return (
    <>
      {OpenModal.EditOperator && selectedOperator && (
        <UpdateOperatorModal
          key={`${modalOpen.toString()}-create-user-modal`}
          open={modalOpen === OpenModal.EditOperator}
          handleAbort={() => {
            setModalOpen(OpenModal.None);
          }}
          title="Edit Operator"
          operatorData={selectedOperator}
        />
      )}
      {OpenModal.ActivateOperator && selectedOperator && (
        <OperatorActivationModal
          key={`${modalOpen.toString()}-activate-user-modal}`}
          operatorId={selectedOperator?.operatorId}
          isActive={selectedOperator?.isActive}
          open={modalOpen === OpenModal.ActivateOperator}
          handleAbort={() => {
            setModalOpen(OpenModal.None);
          }}
        />
      )}

      {OpenModal.DeactivateOperator && selectedOperator && (
        <OperatorDeactivationModal
          key={`${modalOpen.toString()}-deactivate-user-modal}`}
          operatorId={selectedOperator?.operatorId}
          isActive={selectedOperator?.isActive}
          open={modalOpen === OpenModal.DeactivateOperator}
          handleAbort={() => {
            setModalOpen(OpenModal.None);
          }}
        />
      )}
      <ListWrapper>
        <BaseTableList
          rowData={rowData}
          columnDefs={columnDefs}
          // Filter enabled -> Active operators
          // Filter disabled -> Active + Inactive operators
          isExternalFilterPresent={!props.showInactiveOperators}
          filter={(node: any) => {
            // Always return true if showInactiveOperators is true
            // Otherwise filter the list to operators that are active
            return !!node.data?.isActive;
          }}
        />
      </ListWrapper>
    </>
  );
};
