import styled, { css } from 'styled-components';
import { type SxProps } from '@mui/material';

interface NotificationProps {
  isRead: boolean;
}

export const Notification = styled.span<NotificationProps>`
  color: #1049e5;
  text-decoration: underline;

  ${({ isRead }) =>
    !isRead &&
    css`
      font-weight: 700;
    `}
`;

export const ListItemText = styled.span`
  color: #000000;
  padding: 0px 0px 0px 10px;
`;

export const defaultTabStyle: SxProps = {
  padding: '0px',
  color: '#FFFFFF',
  backgroundColor: '#023059',
  minHeight: '30px',
  minWidth: '30px',
  '&:hover': {
    backgroundColor: '#023059',
  },
};

export const disabledTabStyle: SxProps = {
  padding: '0px',
  color: '#7485A2',
  border: '1px solid #77859F',
  borderRadius: '5px',
  backgroundColor: 'transparent',
  minHeight: '30px',
  minWidth: '30px',
};
