import { BaseTableList } from 'components/AgGrid/AgGrid';
import { ListWrapper } from 'components/AgGrid/CellRenderer/ListView.styled';
import { WithIconRenderer } from 'components/AgGrid/CellRenderer/WithIconRenderer';
import * as React from 'react';
import { useAppDispatch, useAppSelector } from 'store/hook';
import {
  selectNotificationsData,
  showNotification,
} from 'store/notification.slice';
import { type Asset } from 'types/asset.types';
import { copyToClipboard } from 'utils/helpers/general';
import AssetTooltip from 'views/FleetOverview/components/AssetTooltip';
import { DeviceInfoModal } from 'views/Settings/ExternalUsers/DeviceView/components/modal/DeviceInfoModal';
import {
  AssetType,
  DeleteAsset,
  Device,
  DeviceStatus,
  OperationalStatus,
  Operators,
  Tags,
  FuelSource,
} from './AssetListCellRenderer';
import { AssetDeactivationModal } from './modals/deleteAssetModal';
import { UpdateAssetModal } from './modals/updateAssetModal';
import { DeviceLinkModal } from './modals/DeviceLinkModal';
import { addLiveDataToAssets } from 'views/FleetOverview/helpers/asset.helpers';
import { selectLatestTagData } from 'store/device.slice';

enum OpenModal {
  None,
  EditAsset,
  DeactivateAsset,
  DeviceInfo,
  DeviceLink,
}

/**
 * A component that displays a list of assets
 */
export const AssetList = () => {
  const dispatch = useAppDispatch();
  const [selectedAssetId, setSelectedAssetId] = React.useState('');
  const [modalOpen, setModalOpen] = React.useState(OpenModal.None);

  // Get the list of assets from redux
  const { assets } = useAppSelector((state) => state.assetReducer);
  const latestTagData = useAppSelector(selectLatestTagData);
  const notificationsData = useAppSelector(selectNotificationsData);

  const assetsWithLiveData = addLiveDataToAssets(
    assets,
    latestTagData,
    notificationsData
  );

  const selectedAsset = assetsWithLiveData.find(
    (asset) => asset.assetId === selectedAssetId
  );

  const [columnDefs] = React.useState([
    {
      field: 'assetId',
      headerName: 'Asset VIN/ID',
      minWidth: 170,
      cellRenderer: WithIconRenderer,
      cellRendererParams: {
        field: 'assetId',
        iconName: 'copyIcon',
        maxLength: 8,
        onIconClick: async (data: Asset) => {
          const didCopy = await copyToClipboard(data.assetId);

          // Failed to copy to clipboard, return early
          if (!didCopy) return;

          // Show notification
          dispatch(
            showNotification({
              message: 'Copied to clipboard',
              type: 'success',
            })
          );
        },
        onCellClick: async (data: Asset) => {
          setSelectedAssetId(data.assetId);
          setModalOpen(OpenModal.EditAsset);
        },
      },
    },
    {
      field: 'bumperNumber',
      headerName: 'Bumper ID',
      minWidth: 160,
      filter: 'agTextColumnFilter',
      sortable: true,
    },
    {
      field: 'assetType',
      headerName: 'Asset Type',
      maxWidth: 180,
      cellRenderer: AssetType,
      sortable: true,
    },
    {
      field: 'operationalStatus',
      headerName: 'Operational Status',
      maxWidth: 280,
      cellRenderer: OperationalStatus,
    },
    {
      field: 'make',
      headerName: 'Make',
      maxWidth: 250,
      sortable: true,
    },
    {
      field: 'model',
      headerName: 'Model',
      maxWidth: 250,
      sortable: true,
    },
    {
      field: 'engineHoursOffset',
      headerName: 'Engine Hours Offset',
      maxWidth: 230,
      cellStyle: { textAlign: 'right' },
    },
    {
      field: 'odometerOffset',
      headerName: 'Odom Reading Offset (km)',
      cellStyle: { textAlign: 'right' },
    },
    {
      field: 'fuelSource',
      headerName: 'Fuel Source',
      cellStyle: { textAlign: 'right' },
      sortable: true,
      cellRenderer: FuelSource,
    },
    {
      field: 'device.deviceId',
      headerName: 'Device',
      maxWidth: 250,
      cellRenderer: Device,
      cellRendererParams: {
        handleDeviceClick: (assetId: string) => {
          setSelectedAssetId(assetId);
          setModalOpen(OpenModal.DeviceInfo);
        },
        handleIdClick: (assetId: string) => {
          setSelectedAssetId(assetId);
          setModalOpen(OpenModal.EditAsset);
        },
      },
      sortable: true,
      filter: 'agTextColumnFilter',
    },
    {
      /*
      TODO/FIXME:
      This field is not in the API response nor in the asset data currently retrieved by the api
      This value will be queried from tables in the data warehouse and added to the asset data
      When fetching assets from the API.

      This information will be retrieved if a device is associated to an asset.

      isActive is the status of the asset and not the status of the device.
      */
      field: 'device.deviceStatus',
      headerName: 'Device Status',
      maxWidth: 190,
      sortable: true,
      cellRenderer: DeviceStatus,
      cellRendererParams: {
        // handles device click
        handleDeviceClick: (assetId: string) => {
          setSelectedAssetId(assetId);
          setModalOpen(OpenModal.DeviceInfo);
        },
        // handles device click if no device is associated to the asset
        // opens the device link modal
        handleNoDeviceClick: (assetId: string) => {
          setSelectedAssetId(assetId);
          setModalOpen(OpenModal.DeviceLink);
        },
      },
      tooltipComponent: AssetTooltip,
      tooltipField: 'device.deviceStatus',
      tooltipComponentParams: {
        bgColor: '#023059',
        color: '#fff',
        data: selectedAsset,
      },
    },
    {
      field: 'assetId',
      headerName: 'Tags',
      maxWidth: 140,
      cellRenderer: Tags,
      cellRendererParams: {
        handleTagClick: (assetId: string) => {
          console.log(assetId);
        },
      },
    },
    {
      field: 'assetId',
      headerName: 'Operator(s)',
      maxWidth: 150,
      cellRenderer: Operators,
      cellRendererParams: {
        handleOperatorClick: (assetId: string) => {
          console.log(assetId);
        },
      },
    },
    {
      field: 'assetId',
      headerName: 'Delete Asset',
      maxWidth: 160,
      cellRenderer: DeleteAsset,
      cellRendererParams: {
        handleDeleteAsset: (assetId: string) => {
          setSelectedAssetId(assetId);
          setModalOpen(OpenModal.DeactivateAsset);
        },
      },
      cellStyle: { textAlign: 'center' },
    },
  ]);

  return (
    <>
      {selectedAsset && (
        <>
          <UpdateAssetModal
            key={`${modalOpen.toString()}-edit-asset-modal`}
            open={modalOpen === OpenModal.EditAsset}
            handleAbort={() => {
              setModalOpen(OpenModal.None);
            }}
            title="Edit Asset"
            assetData={selectedAsset}
          />

          <AssetDeactivationModal
            key={`${modalOpen.toString()}-deactivate-asset-modal}`}
            assetId={selectedAsset.assetId}
            assetVin={selectedAsset.assetVin}
            deviceId={selectedAsset?.device?.deviceId}
            open={modalOpen === OpenModal.DeactivateAsset}
            handleAbort={() => {
              setModalOpen(OpenModal.None);
            }}
          />
          {OpenModal.DeviceInfo && selectedAsset.device && (
            <DeviceInfoModal
              key={`${modalOpen.toString()}-device-info-modal}`}
              deviceData={selectedAsset.device}
              title="Edit Device"
              open={modalOpen === OpenModal.DeviceInfo}
              handleAbort={() => {
                setModalOpen(OpenModal.None);
              }}
            />
          )}
          {OpenModal.DeviceLink && selectedAsset.assetId && (
            <DeviceLinkModal
              key={`${modalOpen.toString()}-device-link-modal}`}
              title="Link Device"
              assetId={selectedAsset.assetId}
              open={modalOpen === OpenModal.DeviceLink}
              handleAbort={() => {
                setModalOpen(OpenModal.None);
              }}
            />
          )}
        </>
      )}
      <ListWrapper>
        <BaseTableList rowData={assetsWithLiveData} columnDefs={columnDefs} />
      </ListWrapper>
    </>
  );
};
