import { createSlice } from '@reduxjs/toolkit';
import {
  type AssetsOverview,
  type CumulativeOverview,
} from 'types/survivalAnalysis.types';

export interface OverviewState {
  assets: AssetsOverview[];
  cumulative: CumulativeOverview[];
  eol: any[];
}

const initialState: OverviewState = {
  assets: [],
  cumulative: [],
  eol: [],
};

export const assetsOverviewSlice = createSlice({
  name: 'survivalAnalysisAssetsOverviewSlice',
  initialState,
  reducers: {
    appendSurvivalAnalysis(state, { payload }) {
      state.cumulative.push(payload);
      state.assets.push(payload);
    },

    updateAssetsOverviewList(state, { payload }: { payload: any }) {
      const index = state.assets.findIndex(
        (assets) => assets.asset_id === payload.assetId
      );

      if (index === -1) {
        // do not update the state if the squares is not found
        return;
      }

      state.assets[index] = payload;
    },

    setAssets(state, { payload }) {
      state.assets = payload;
    },

    removeAssets(state, { payload }: { payload: { squaresId: string } }) {
      const filteredAssets = state.assets.filter(
        (assets) => assets.asset_id !== payload.squaresId
      );
      state.assets = filteredAssets;
    },

    appendSurvivalAnalysisCumulativeAssets(state, { payload }) {
      state.cumulative.push(payload);
    },

    setCumulativeAssets(state, { payload }) {
      state.cumulative = payload;
    },

    appendEol(state, { payload }) {
      state.eol.push(payload);
    },

    setEol(state, { payload }) {
      state.eol = payload;
    },
  },
});

export const {
  appendSurvivalAnalysis,
  updateAssetsOverviewList,
  setAssets,
  removeAssets,
  appendSurvivalAnalysisCumulativeAssets,
  setCumulativeAssets,
  appendEol,
  setEol,
} = assetsOverviewSlice.actions;

export const CumulativeAssetsSlice = createSlice({
  name: 'survivalAnalysisCumulativeAssetsSlice',
  initialState,
  reducers: {},
});

export default assetsOverviewSlice.reducer;
