import * as React from 'react';
import {
  Box,
  Divider,
  Typography,
  FormControlLabel,
  Button,
} from '@mui/material';
import { Selector } from 'components/Form/Selectors/Selector';
import { SWTextField } from 'components/Form/Input/SWTextField';
import StyledSwitchButton from 'components/Buttons/SwitchButton';
import {
  capitalizeFirstLetter,
  safeReplace,
} from 'utils/helpers/string.manipulation';
import { ThemePalette } from 'mui.theme';
import {
  fuelConsumptionLabels,
  getFuelLabelWrtUnitSystem,
} from 'utils/helpers/labels';
import { useAppSelector } from 'store/hook';

interface Props {
  assetMakeModel: string;
  setExtraBarKeys: any;
  selectedConsumptionRange: string;
  setSelectedConsumptionRange: any;
  unit: boolean;
  currentPrice: { dieselPrice: number; gasPrice: number };
  setUnit: any;
  setCurrentPrice: any;
  enableDarkTheme: boolean;
}

interface Data {
  co2Consumption: {
    name: string;
    color: string;
    label: string;
  };
  dollarsSpentOnFuel: {
    name: string;
    color: string;
    label: string;
  };
}

const FuelConsumptionRangeHeader = ({
  assetMakeModel,
  setExtraBarKeys,
  selectedConsumptionRange,
  setSelectedConsumptionRange,
  unit,
  currentPrice,
  setUnit,
  setCurrentPrice,
  enableDarkTheme,
}: Props) => {
  const unitSystem = useAppSelector((state) => state.persistedReducer).customer
    .unitSystem;
  const barKeysPair: Data = {
    co2Consumption: {
      name: `CO2 consumption in ${getFuelLabelWrtUnitSystem(
        unitSystem,
        fuelConsumptionLabels.CO2
      )}`,
      color: '#818589',
      label: `CO2 consumption in ${getFuelLabelWrtUnitSystem(
        unitSystem,
        fuelConsumptionLabels.CO2
      )}`,
    },
    dollarsSpentOnFuel: {
      name: 'Dollars spent on fuel',
      color: '#8884d8',
      label: 'Dollars spent on fuel',
    },
  };

  const getBarKey = (selectedValue: string, unit: boolean) => {
    const selectedValueData = barKeysPair[selectedValue as keyof Data];
    if (selectedValueData.name.includes('Dollar')) {
      selectedValueData.label = `Dollars spent on fuel (${
        unit ? 'CAD' : 'USD'
      })`;
    }
    return selectedValueData;
  };
  const [barKey, setBarKey] = React.useState<string>('co2Consumption');
  const [userInputPrice, setUserInputPrice] = React.useState<{
    dieselPrice: number;
    gasPrice: number;
  }>({ dieselPrice: 0, gasPrice: 0 });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    let defaultValue = 0;
    if (parseFloat(value)) {
      defaultValue = parseFloat(value);
    }
    // enforce value as number
    setUserInputPrice({
      ...currentPrice,
      [name]: defaultValue,
    });
  };

  return (
    <>
      <Box
        sx={{
          display: 'flex',
        }}
      >
        <Typography
          style={{
            paddingTop: '8px',
            paddingLeft: '20px',
            fontSize: '13px',
            color: enableDarkTheme
              ? ThemePalette.typography.white
              : ThemePalette.typography.black,
          }}
        >
          {`Total Fuel Consumption + Avg Fuel Consumption Per Asset (${capitalizeFirstLetter(
            safeReplace(assetMakeModel, '!', ' ')
          )})`}
        </Typography>
        <span
          style={{
            paddingLeft: '5px',
          }}
        >
          <Selector
            value={selectedConsumptionRange}
            onChange={(event) => {
              const selectedValue = event.target.value as string;
              setSelectedConsumptionRange(selectedValue);
              window.scrollTo({
                top: 0,
                behavior: 'smooth', // Smooth scrolling animation
              });
            }}
            selectorOptions={[
              {
                id: 'monthOverMonth',
                display: 'Month over Month',
              },
              {
                id: 'quarterOverQuarter',
                display: 'Quarter over Quarter',
              },
            ]}
            selectSx={{
              mt: '3px',
              ml: '10px',
              flex: 1,
              fontFamily: 'open sans',
              fontSize: '14px',
            }}
            enableDarkTheme={enableDarkTheme}
          />
        </span>

        <span
          style={{
            paddingLeft: '5px',
          }}
        >
          <Selector
            value={barKey}
            onChange={(event) => {
              const selectedValue = event.target.value as string;
              setBarKey(selectedValue);
              const selectedValueData = getBarKey(selectedValue, unit);
              setExtraBarKeys([selectedValueData]);
            }}
            selectorOptions={[
              {
                id: 'co2Consumption',
                display: `CO2 consumption in ${getFuelLabelWrtUnitSystem(
                  unitSystem,
                  fuelConsumptionLabels.CO2
                )}`,
              },
              {
                id: 'dollarsSpentOnFuel',
                display: ' Dollars spent on fuel',
              },
            ]}
            selectSx={{
              mt: '3px',
              ml: '10px',
              flex: 1,
              fontFamily: 'open sans',
              fontSize: '14px',
            }}
            enableDarkTheme={enableDarkTheme}
          />
        </span>
        {barKey.includes('dollar') ? (
          <>
            <FormControlLabel
              control={<StyledSwitchButton />}
              label=""
              labelPlacement="start"
              checked={unit}
              onChange={() => {
                // Flip the value
                setUnit((unit: boolean) => !unit);
                const selectedValueData = getBarKey(barKey, !unit);
                setExtraBarKeys([selectedValueData]);
              }}
            />
            <span
              style={{
                paddingLeft: '15px',
                paddingTop: '8px',
                color: enableDarkTheme
                  ? ThemePalette.typography.white
                  : ThemePalette.typography.black,
              }}
            >
              {unit ? 'CAD($)' : 'USD($)'}
            </span>
            <span
              style={{
                paddingLeft: '15px',
                paddingTop: '8px',
                color: enableDarkTheme
                  ? ThemePalette.typography.white
                  : ThemePalette.typography.black,
              }}
            >
              Gasoline
            </span>
            {/* gasolinePrice TextField */}
            <SWTextField
              key="gasPrice"
              name="gasPrice"
              placeholder="Gas price"
              TextFieldProps={{
                name: 'gasPrice',
                value: userInputPrice.gasPrice,
                onChange: handleChange,
                InputProps: {
                  sx: {
                    marginLeft: '10px',
                    height: '27px',
                    maxWidth: '100px',
                    WebkitBoxShadow: '0 0 0 1000px white inset',
                    marginTop: '4px',
                  },
                },
              }}
              enableDarkTheme={enableDarkTheme}
            />
            <span
              style={{
                paddingLeft: '15px',
                paddingTop: '8px',
                color: enableDarkTheme
                  ? ThemePalette.typography.white
                  : ThemePalette.typography.black,
              }}
            >
              Diesel
            </span>
            {/* dieselPrice TextField */}
            <SWTextField
              key="dieselPrice"
              name="dieselPrice"
              placeholder="Diesel price"
              TextFieldProps={{
                name: 'dieselPrice',
                value: userInputPrice.dieselPrice,
                onChange: handleChange,
                InputProps: {
                  sx: {
                    marginLeft: '10px',
                    height: '27px',
                    maxWidth: '100px',
                    WebkitBoxShadow: '0 0 0 1000px white inset',
                    marginTop: '4px',
                  },
                },
              }}
              enableDarkTheme={enableDarkTheme}
            />
            <Button
              variant="contained"
              sx={{
                marginLeft: '20px',
                height: '35px',
                backgroundColor: 'button.primary',
              }}
              onClick={() => {
                setCurrentPrice(userInputPrice);
              }}
            >
              GO
            </Button>
          </>
        ) : (
          <></>
        )}
      </Box>
      <Divider
        sx={{
          paddingTop: '5px',
          marginBottom: '10px',
        }}
      />
    </>
  );
};

export default FuelConsumptionRangeHeader;
