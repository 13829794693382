import React from 'react';
import dayjs from 'dayjs';
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Legend,
} from 'recharts';

import { pastelColorMap, generateRandomColor } from 'utils/helpers/general';
import { ThemePalette } from 'mui.theme';

// Define interfaces for type safety
interface TimeSeriesEntry {
  timestamp: string; // Assuming timestamp is a string that can be parsed by dayjs
  total_cost_asset: number;
  work_order_number: string;
  asset_id: string;
}

interface LineChartData {
  name: string;
  data: TimeSeriesEntry[];
}

const CustomTooltip: React.FC<{
  active?: boolean;
  payload?: any[];
  label?: string;
}> = ({ active, payload }) => {
  if (active && payload && payload.length) {
    return (
      <div className="custom-tooltip">
        {payload.map((item, index) => (
          <React.Fragment key={index}>
            <p
              style={{ color: pastelColorMap[index] || generateRandomColor() }}
            >
              {item.name}: {item.value}
            </p>
          </React.Fragment>
        ))}
      </div>
    );
  }
  return null;
};

export const ComponentsLineChart = ({
  data,
  selectedAssetsCostType,
  enableDarkTheme = false,
}: {
  data: LineChartData[];
  selectedAssetsCostType: string[];
  enableDarkTheme: boolean;
}) => {
  const formatTimestamp = (timestamp: number): string => {
    const date = dayjs.unix(timestamp);
    const formattedDate = date.format('YYYY MMM DD');
    return formattedDate;
  };

  return (
    <ResponsiveContainer width="100%" height="100%">
      <AreaChart
        margin={{
          top: 5,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <defs>
          <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8} />
            <stop offset="95%" stopColor="#8884d8" stopOpacity={0} />
          </linearGradient>
          <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8} />
            <stop offset="95%" stopColor="#82ca9d" stopOpacity={0} />
          </linearGradient>
        </defs>
        <XAxis
          dataKey="timestamp"
          domain={['auto', 'auto']} // Set x-axis domain automatically
          scale="time" // Set x-axis scale to 'time'
          type="number" // Set x-axis type to 'number'
          allowDuplicatedCategory={false}
          tickFormatter={formatTimestamp}
          tick={{ fontSize: 14, fontWeight: 'normal' }}
        />
        <YAxis
          label={{
            value: 'Cost',
            angle: -90,
            position: 'insideLeft',
            fill: enableDarkTheme
              ? ThemePalette.typography.white
              : ThemePalette.typography.black,
            dy: 10,
            dx: -10,
          }}
        />
        <Tooltip content={<CustomTooltip />} />
        <Legend />

        {data.map((dt, assetIndex) => {
          return selectedAssetsCostType.map((costType, index) => (
            <Area
              key={`line-${assetIndex}-${index}`}
              name={`${dt.name} - ${costType}`}
              type="monotone"
              dataKey={costType}
              data={dt.data}
              fill={
                pastelColorMap[
                  assetIndex * selectedAssetsCostType.length + index
                ] || generateRandomColor()
              }
              fillOpacity={0.3}
              strokeOpacity={1}
              stroke={
                pastelColorMap[
                  assetIndex * selectedAssetsCostType.length + index
                ] || generateRandomColor()
              }
              activeDot={{ r: 8 }}
            />
          ));
        })}
      </AreaChart>
    </ResponsiveContainer>
  );
};
