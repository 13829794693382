import React from 'react';
import type { TooltipProps } from 'recharts';

type CustomTooltipProps = TooltipProps<any, any>;

const CustomTooltip: React.FC<CustomTooltipProps> = ({
  active,
  payload,
  label,
}: CustomTooltipProps) => {
  if (active && payload && payload.length > 0) {
    return (
      <div
        style={{
          backgroundColor: '#FFFFFF',
          border: '2px solid #919190',
          paddingLeft: '10px',
          paddingRight: '10px',
          margin: '10px',
        }}
      >
        <p>{label}</p>
        {Object.entries(payload[0].payload)
          .filter(
            ([key, value]) =>
              !key.includes('_') &&
              key !== 'bumperNumber' &&
              key !== 'customerDay' &&
              key !== 'customer_day'
          )
          .map(([key, value]) => (
            <p key={key}>{`${key}: ${convertToTwoDecimalPlaces(
              value as string
            )}`}</p>
          ))}
      </div>
    );
  }
  return null;
};

export default CustomTooltip;

export function convertToTwoDecimalPlaces(input: string): string {
  const numericValue: number = parseFloat(input);

  if (!isNaN(numericValue) && !Number.isInteger(numericValue)) {
    // If input is a valid number, convert to 2 decimal places
    const roundedValue: string = numericValue.toFixed(2).toString();
    return Number(roundedValue) !== Number(0)
      ? roundedValue
      : numericValue.toFixed(5).toString();
  } else {
    // If input is not a valid number, return original string
    return input;
  }
}
