import { type ICreateSquaresPayload } from 'types/payloads/squares.payload.types';
import BaseAPI from '../../base.api';

export default class SquaresAPI extends BaseAPI {
  /**
   * Gets all operators the current user has access to
   * @returns A list of operators
   */

  getAllWorkOrders = async (payload: ICreateSquaresPayload) => {
    const response = await this.API.post('squares/getAllWorkOrders', payload);
    return response.data;
  };

  getAllWorkOrdersHistory = async (payload: ICreateSquaresPayload) => {
    const response = await this.API.post(
      'squares/getAllWorkOrdersHistory',
      payload
    );
    return response.data;
  };
}
