import { updateLoadingState } from 'store/app.slice';
import { BaseHandler } from '../base.handler';
import WorkOrdersSquaresAPI from 'api/Squares/WorkOrders/squares.api';
import {
  setSquaresWorkOrder,
  setSquaresWorkOrdersHistory,
} from 'store/SquaresStore/WorkOrdersSquaresState.slice';
import {
  type WorkOrdersSquareHistory,
  type WorkOrdersSquare,
} from 'types/squares.type';
import { useAppSelector } from 'store/hook';
import { type ICreateSquaresPayload } from 'types/payloads/squares.payload.types';

export default class SquaresMaintenanceHandler extends BaseHandler {
  private readonly apiWorkOrders: WorkOrdersSquaresAPI;
  private readonly customer = useAppSelector((state) => state.persistedReducer)
    .customer.code;

  constructor() {
    super();
    this.apiWorkOrders = new WorkOrdersSquaresAPI();
  }

  async getAllWorkOrders(): Promise<WorkOrdersSquare[]> {
    try {
      this.dispatch(updateLoadingState(true));
      const payload: ICreateSquaresPayload = {
        customerName: this.customer,
      };

      const response = await this.apiWorkOrders.getAllWorkOrders(payload);
      const squares = response.Items;

      this.dispatch(setSquaresWorkOrder(squares));
      this.handleSuccess();

      return squares;
    } catch (error: any) {
      let msg: string = '';

      if (error.isAxiosError) {
        msg = error.response.data.message as string;
      }

      this.handleError(
        'An error occured while retrieving the squares : ' + msg
      );
      return [];
    }
  }

  async getAllWorkOrdersHistory() {
    let id: NodeJS.Timeout | null | undefined = null;
    // Function to fetch work orders and check completion status
    const fetchWorkOrders = async () => {
      const payload: ICreateSquaresPayload = {
        customerName: this.customer,
      };

      const squares = await this.apiWorkOrders.getAllWorkOrdersHistory(payload);
      this.dispatch(setSquaresWorkOrdersHistory(squares));

      // Check if all items are at stage "6_Complete" or if the array is empty
      const allCompletedOrEmpty =
        squares.length === 0 ||
        squares.every(
          (square: { stage: string }) => !/[1-7]/.test(square.stage)
        );

      if (!allCompletedOrEmpty) {
        console.log(
          'Not all work orders are complete. Retrying in 30 seconds...'
        );
        // Schedule the next check in 30 seconds if not all are completed
        id = setTimeout(fetchWorkOrders, 30000); //  30 seconds
      } else {
        console.log('All work orders are complete.');
        // Clear timeout if it exists
        if (id) clearTimeout(id);
      }

      return squares;
    };

    try {
      const notifications = await fetchWorkOrders();
      this.handleSuccess();
      return notifications;
    } catch (error: any) {
      let msg: string = '';

      if (error.isAxiosError) {
        msg = error.response.data.message as string;
      }

      this.handleError(
        'An error occurred while retrieving the work orders: ' + msg
      );
      // Ensure that the polling is stopped in case of an error
      if (id) clearTimeout(id);
      throw error;
    }
  }
}
