import { useEffect } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { type Asset } from 'types/asset.types';

/**
 * Automatically update the pathname when the asset model changes
 * @param asset The asset to track
 */
export function useNavigateOnAssetChange(asset: Asset, path: string) {
  const navigateTo = useNavigate();

  useEffect(() => {
    // Do not modify the location if no assets are selected
    if (asset.assetId === '') return;

    // Update the URL to the current asset identifier
    navigateTo(`/${path}/${asset.assetVin}`);
  }, [asset]);
}

/**
 * Automatically try to resolve an asset when the pathname changes
 * @param assetMap A hashmap of assets
 * @param setSelectedAsset A callback to update an asset
 */
export function useAssetFromPath(
  assetMap: Record<Asset['assetVin'], Asset>,
  setSelectedAsset: (asset: Asset) => void
) {
  const location = useLocation();
  const { assetVin } = useParams();

  useEffect(() => {
    // Do not process if the asset vin is empty
    if (!assetVin) return;

    // Try setting the page information from the assetVin
    if (assetMap[assetVin] === undefined) return;

    setSelectedAsset(assetMap[assetVin]);
  }, [location.pathname, assetMap, assetVin]);
}
