import { Box, Typography } from '@mui/material';
import { Selector } from 'components/Form/Selectors/Selector';
import { InteractiveModal } from 'components/Modals/InteractiveModal/BaseModal';
import DeviceHandler from 'handlers/device.handler';
import { useEffect, useState } from 'react';
import { selectAssets, selectUnassociatedAssets } from 'store/asset.slice';
import { useAppSelector } from 'store/hook';
import type { Device } from 'types/device.types';
import { DEVICE_ORIENTATION } from 'utils/enums';
import { updateDeviceRequestBodySchema } from 'utils/schemas/device.schema';
import { validateYupSchema } from 'utils/validators/schema.validator';
import { safeTrim } from 'utils/helpers/string.manipulation';
import { SWTextField } from 'components/Form/Input/SWTextField';
import { modalInputFieldStyle } from 'styles/global.css';

interface Props {
  /**
   * Title of the Modal
   */
  title: string;
  /**
   * Boolean Value to determine if modal is displpayed or not
   */
  open: boolean;
  /**
   * Asset Data modal is to render
   */
  deviceData: Device;
  /**
   * Function to call to close modal
   */
  handleAbort: () => void;
}
// if no value is present in the textfield, this will be displayed
const TEXTFIELD_FALLBACK_VAL = 'Unknown';

export const DeviceInfoModal = ({
  title,
  handleAbort,
  open,
  deviceData,
}: Props) => {
  const deviceHandler = new DeviceHandler();
  const unassociatedAssetList = useAppSelector(selectUnassociatedAssets)?.map(
    (asset) => ({
      display: asset.bumperNumber,
      id: asset.assetId,
    })
  );

  // push the current associated asset into the list
  // so that it can be selected or visible in the ui
  if (deviceData.assetId) {
    unassociatedAssetList.push({
      display: deviceData.assetBumperNumber,
      id: deviceData.assetId,
    });
  }

  const [device, setDevice] = useState<{
    yOrientation: string;
    xOrientation: string;
    assetId: string;
    modelNumber: string;
    hardwareConfigurationCode: string;
  }>({
    yOrientation: deviceData.yOrientation,
    xOrientation: deviceData.xOrientation,
    assetId: deviceData.assetId,
    modelNumber: deviceData.modelNumber ?? '',
    hardwareConfigurationCode: deviceData.hardwareConfigurationCode ?? '',
  });
  const [isValidForm, setFormValidity] = useState(false);

  const handleSubmit = async () => {
    await deviceHandler.updateDevice(deviceData.deviceId, device);
    handleAbort();
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    setDevice({
      ...device,
      [name]: value,
    });
  };

  // Validate form as user fills out fields
  useEffect(() => {
    const validateForm = async () =>
      await validateYupSchema(
        {
          ...device,
        },
        updateDeviceRequestBodySchema
      );

    void validateForm().then((isValid) => {
      setFormValidity(isValid);
    });
  }, [device]);

  /**
   * This is the form that will be rendered in the modal
   */
  return (
    <InteractiveModal
      initialState={open}
      SuccessButtonProps={{
        disabled: !isValidForm,
        onClick: handleSubmit,
        label: 'SAVE EDITS',
      }}
      handleAbort={handleAbort}
      title={title}
      confirmCancellation
    >
      {/* Device ID */}
      <Box key="deviceId">
        <Typography variant="bodyBold" display="block" mb={'4px'}>
          Device ID
        </Typography>
        <Typography key="blurb-1" variant="medium">
          {deviceData.deviceId}
        </Typography>
      </Box>
      <Box key="assetId">
        <Typography variant="bodyBold" display="block" mb={'4px'}>
          Asset ID
        </Typography>
        <Selector
          selectSx={{
            width: '270px',
          }}
          value={device.assetId ?? ''}
          onChange={(event) => {
            const selectedValue = event.target.value as string;
            setDevice({
              ...device,
              assetId: selectedValue,
            });
          }}
          selectorOptions={unassociatedAssetList}
        />
      </Box>
      {/* First Message Sent */}
      <Box key="firstEventTimestamp">
        <Typography variant="bodyBold" display="block" mb={'4px'}>
          First Message Sent
        </Typography>
        <Typography key="blurb-1" variant="medium">
          {deviceData.firstEventTimestamp?.toString() || TEXTFIELD_FALLBACK_VAL}
        </Typography>
      </Box>

      {/* IMEI Number */}
      <Box key="imeiNumber">
        <Typography variant="bodyBold" display="block" mb={'4px'}>
          IMEI Number
        </Typography>
        <Typography key="blurb-1" variant="medium">
          {deviceData.imei || TEXTFIELD_FALLBACK_VAL}
        </Typography>
      </Box>

      {/* MAC address */}
      <Box key="ethernetMac">
        <Typography variant="bodyBold" display="block" mb={'4px'}>
          MAC address
        </Typography>
        <Typography key="blurb-1" variant="medium">
          {deviceData.ethernetMac || TEXTFIELD_FALLBACK_VAL}
        </Typography>
      </Box>

      {/* MAC address */}
      <Box key="wifiMac">
        <Typography variant="bodyBold" display="block" mb={'4px'}>
          Wifi Mac Address
        </Typography>
        <Typography key="blurb-1" variant="medium">
          {deviceData.wifiMac || TEXTFIELD_FALLBACK_VAL}
        </Typography>
      </Box>

      {/* Swarm Device Number */}
      <Box key="swarmModemIdentifier">
        <Typography variant="bodyBold" display="block" mb={'4px'}>
          Swarm Device Number
        </Typography>
        <Typography key="blurb-1" variant="medium">
          {deviceData.swarmModemIdentifier || TEXTFIELD_FALLBACK_VAL}
        </Typography>
      </Box>

      {/* SIM Number */}
      <Box key="simNumber">
        <Typography variant="bodyBold" display="block" mb={'4px'}>
          SIM Number
        </Typography>
        <Typography key="blurb-1" variant="medium">
          {deviceData.simNumber || TEXTFIELD_FALLBACK_VAL}
        </Typography>
      </Box>

      {/* Firmware Version */}

      <Box key="firmwareVersion">
        <Typography variant="bodyBold" display="block" mb={'4px'}>
          Firmware Version
        </Typography>
        <Typography key="blurb-1" variant="medium">
          {deviceData.firmwareVersion || TEXTFIELD_FALLBACK_VAL}
        </Typography>
      </Box>
      <div key="spacer" />
      {/* Model Number* */}
      <Box key="modelNumber">
        <SWTextField
          key="modelNumber"
          name="modelNumber"
          label="Model Number*"
          TextFieldProps={{
            name: 'modelNumber',
            value: device.modelNumber,
            onChange: handleChange,
            InputProps: {
              sx: {
                ...modalInputFieldStyle,
                minWidth: '297px',
              },
            },
          }}
        />
      </Box>

      {/* hardwareConfigurationCode* */}
      <Box key="hardwareConfigurationCode">
        <SWTextField
          key="hardwareConfigurationCode"
          name="hardwareConfigurationCode"
          label="Hardware Configuration Code*"
          TextFieldProps={{
            name: 'hardwareConfigurationCode',
            value: device.hardwareConfigurationCode,
            onChange: handleChange,
            InputProps: {
              sx: {
                ...modalInputFieldStyle,
                minWidth: '297px',
              },
            },
          }}
        />
      </Box>

      {/* Device X-Orientation* */}
      <Box key="xOrientation">
        <Typography variant="bodyBold" display="block" mb={'4px'}>
          Device X-Orientation*
        </Typography>
        <Selector
          value={device.xOrientation}
          onChange={(event) => {
            const selectedValue = event.target.value as string;
            setDevice({
              ...device,
              xOrientation: selectedValue,
            });
          }}
          selectorOptions={DEVICE_ORIENTATION}
        />
      </Box>

      {/* Device Y-Orientation* */}
      <Box key="yOrientation">
        <Typography variant="bodyBold" display="block" mb={'4px'}>
          Device Y-Orientation*
        </Typography>
        <Selector
          value={device.yOrientation}
          onChange={(event) => {
            const selectedValue = event.target.value as string;
            setDevice({
              ...device,
              yOrientation: selectedValue,
            });
          }}
          selectorOptions={DEVICE_ORIENTATION}
        />
      </Box>
    </InteractiveModal>
  );
};
