import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import dayjs from 'dayjs';
import DatePicker from 'react-datepicker';
import { Base } from 'components/Containers/BaseContainer';
import { FlexBox } from 'components/Containers/FlexBox';
import type { Asset } from 'types/asset.types';
import {
  Box,
  type SelectChangeEvent,
  Typography,
  RadioGroup,
  Radio,
  FormControlLabel,
} from '@mui/material';
import { MultiSelector } from 'components/Form/Selectors/MultiSelector';
import { useAppSelector } from 'store/hook';
import { selectAssetByMakeModel } from 'store/asset.slice';
import type { SelectOptions } from 'types/utils.types';
import FuelAnalyticsHandler from 'handlers/fuelAnalytics.handler';
import NonHaulTruckHandler from 'handlers/nonHaulTruck.handler';
import {
  capitalizeFirstLetter,
  safeReplace,
} from 'utils/helpers/string.manipulation';
import { HaulTruckView } from './HaulTrucks/HaulTrucks';
import { OtherAssetsView } from './OtherAssets/OtherAsets';
import ExportSelector from 'views/FleetOverview/components/FilterViewBar/ExportSelector';
import { useCustomDropdownEffect } from 'utils/exports/hooks';
import {
  gbsMakeModelsToFilter,
  klgMakeModelsToFilter,
  notAvailableText,
} from './UsageDash';
import { isDarkTheme } from 'utils/theme';
import { ThemePalette } from 'mui.theme';
import { getAssetsMakeModel } from './utils';
import { assetTypeMappings } from 'views/FleetOverview/helpers/asset.helpers';
import { getAssetMakeModel } from 'views/FleetOverview/components/AssetCardsV2/utils';
import AnalysisWrapper from './HistoricalAnalysis/wrapper';

type DurationKey = 'week' | 'month' | 'year';

export const HistoricalAnalysis = () => {
  /**
   * New implementation to support utilization click from fleetoverview page
   * Hacky work. tech debt
   */
  const location = useLocation();
  const { type, value, device } = location.state || {};
  const [selectedAssetType, setSelectedAssetType] = useState(
    type ?? 'Haul Truck'
  );
  const [selectedDevice, setSelecedDevice] = useState<string | undefined>(
    device ?? undefined
  );
  const customerCode = useAppSelector((state) => state.persistedReducer)
    .customer.code;
  const { data: fuelPriceData } = useAppSelector(
    (state) => state.fuelPriceReducer
  );
  const assets = useAppSelector((state) => state.assetReducer.assets);
  const theme = useAppSelector((state) => state.authReducer).customer.theme;
  const enableDarkTheme = isDarkTheme(theme);
  const fuelAnalyticsHandler = new FuelAnalyticsHandler();
  const nonHaulTruckHandler = new NonHaulTruckHandler();
  const assetMakeModelList = useAppSelector(selectAssetByMakeModel);
  const fuelAnalytics = useAppSelector((state) => state.fuelAnalyticsReducer);
  const unitSystem = useAppSelector((state) => state.persistedReducer).customer
    .unitSystem;
  const [selectedDuration] = useState<DurationKey>('week');
  const [selectedStartDate, setStartDate] = useState(
    new Date(new Date().getTime() - 7 * 24 * 60 * 60 * 1000)
  );
  const [selectedEndDate, setEndDate] = useState(new Date());
  const [assetOptions, setAssetOptions] = useState<SelectOptions[]>([]);
  const [selectedAsset, setSelectedAsset] = useState<string[]>([]);
  const [showCharts, setShowCharts] = useState<boolean>(false);
  const [showHaulTrucks, setShowHaulTrucks] = useState<boolean>(false);
  const [possibleAssetOptions, setPossibleAssetOptions] = useState<
    SelectOptions[]
  >([]);
  const [selectedConsumptionRange, setSelectedConsumptionRange] =
    useState<string>('monthOverMonth');

  const [exportDropdownTo, setExportDropdownTo] = useState('');
  const [exportToLabel, setExportToLabel] = useState('Export To');

  const handleExportDropDownChange = (event: SelectChangeEvent) => {
    setExportDropdownTo(event.target.value);
  };
  const [exportTo, setExportTo] = useState(false);
  const handleExportClick = (val: boolean) => {
    setExportTo(val);
  };

  const handleDateChange = (range: any) => {
    const [startDate, endDate] = range;
    setStartDate(startDate);
    setEndDate(endDate);
    resetSelectedDevice();
  };

  const handleAssetTypeChange = (event: any) => {
    setSelectedAssetType(event.target.value);
    resetSelectedDevice();
  };

  useEffect(() => {
    if (Object.keys(assetMakeModelList).length && customerCode !== 'cma') {
      const selectorOptions = Object.keys(assetMakeModelList).map((key) => {
        return {
          display: capitalizeFirstLetter(safeReplace(key, '!', ' ')),
          id: key,
        };
      });
      selectorOptions.sort((a, b) => {
        const displayA = a.display.toUpperCase();
        const displayB = b.display.toUpperCase();
        if (displayA < displayB) {
          return -1;
        }
        if (displayA > displayB) {
          return 1;
        }
        return 0;
      });
      let selectOptions =
        customerCode === 'klg'
          ? selectorOptions.filter((option) =>
              klgMakeModelsToFilter.includes(option.display.toLowerCase())
            )
          : customerCode === 'gbs'
          ? selectorOptions.filter((option) =>
              gbsMakeModelsToFilter.includes(option.display.toLowerCase())
            )
          : selectorOptions;

      if (selectOptions.length > 1) {
        if (selectedAssetType !== 'total') {
          const assetTypes = Object.keys(assetTypeMappings).filter(
            (key) => assetTypeMappings[key] === selectedAssetType
          );
          const assetsFiltered = assets.filter((asset: Asset) => {
            return assetTypes.includes(asset.assetType);
          });
          const assetTypeMakeModelList = getAssetMakeModel(assetsFiltered).map(
            (item) => item.replace('-', '!')
          );

          selectOptions = selectOptions.filter((item) =>
            assetTypeMakeModelList.includes(item.id)
          );
        }

        if (selectOptions.length > 0) {
          if (selectedAssetType !== 'total') {
            const ids = selectOptions.map((item: any) => {
              return item.id;
            });
            setSelectedAsset([...ids]);
          } else {
            setSelectedAsset([selectOptions[0].id]);
          }
          setAssetOptions(selectOptions);
          setShowCharts(true);
        } else {
          setSelectedAsset([]);
          setAssetOptions([]);
          setShowCharts(false);
        }
      }
    }
  }, [assetMakeModelList, customerCode, selectedAssetType]);

  const getAssetAvgFuelConsumption = async (
    makeModels: Array<{
      make: string;
      model: string;
    }>,
    initialStartDate: Date,
    initialEndDate: Date,
    isHaulTruck: boolean,
    selectedConsumptionRange: string,
    selectedAssetType: string
  ) => {
    const startDate = dayjs(initialStartDate).format('YYYY-MM-DD');
    const endDate = dayjs(initialEndDate).format('YYYY-MM-DD');
    let rangeToReturn = 'month';
    if (selectedConsumptionRange.includes('quarter')) {
      rangeToReturn = 'quarter';
    }
    await fuelAnalyticsHandler.getFuelUsageAnalytics(
      makeModels,
      isHaulTruck,
      startDate,
      endDate,
      rangeToReturn,
      selectedAssetType
    );
    if (!isHaulTruck) {
      await nonHaulTruckHandler.getAnalytics(makeModels, startDate, endDate);
    }
  };

  const resetSelectedDevice = () => {
    setSelecedDevice(undefined);
  };

  useEffect(() => {
    if (
      selectedAsset.length > 0 &&
      customerCode !== 'cma' &&
      selectedStartDate &&
      selectedEndDate
    ) {
      const isHaulTruck =
        assetTypeMappings[
          assetMakeModelList[selectedAsset[0]][0]?.assetType
        ] === 'Haul Truck';

      const makeModels = getAssetsMakeModel(selectedAsset);
      void getAssetAvgFuelConsumption(
        makeModels,
        selectedStartDate,
        selectedEndDate,
        isHaulTruck,
        selectedConsumptionRange,
        selectedAssetType
      );
    }
  }, [
    selectedAsset,
    selectedStartDate,
    selectedEndDate,
    customerCode,
    selectedConsumptionRange,
  ]);

  useEffect(() => {
    if (selectedAssetType === 'total') {
      if (selectedAsset.length > 0) {
        const currentSelection = assetMakeModelList[selectedAsset[0]];
        const currentSelectionType =
          assetTypeMappings[currentSelection[0].assetType];

        let filteredKeys;
        if (currentSelectionType === 'Haul Truck') {
          filteredKeys = Object.keys(assetMakeModelList).filter((key) =>
            assetMakeModelList[key].some(
              (item) => assetTypeMappings[item.assetType] === 'Haul Truck'
            )
          );
          setShowHaulTrucks(true);
        } else {
          filteredKeys = Object.keys(assetMakeModelList).filter((key) =>
            assetMakeModelList[key].every(
              (item) => assetTypeMappings[item.assetType] !== 'Haul Truck'
            )
          );
          setShowHaulTrucks(false);
        }
        const selectorOptions = filteredKeys.map((key) => {
          return {
            display: capitalizeFirstLetter(safeReplace(key, '!', ' ')),
            id: key,
          };
        });
        selectorOptions.sort((a, b) => {
          const displayA = a.display.toUpperCase();
          const displayB = b.display.toUpperCase();
          if (displayA < displayB) {
            return -1;
          }
          if (displayA > displayB) {
            return 1;
          }
          return 0;
        });
        setPossibleAssetOptions(selectorOptions);
      } else {
        setPossibleAssetOptions(assetOptions);
      }
    } else {
      setPossibleAssetOptions(assetOptions);
    }
  }, [selectedAsset, assetMakeModelList, selectedAssetType]);

  useCustomDropdownEffect(
    exportDropdownTo,
    handleExportClick,
    setExportToLabel,
    setExportDropdownTo,
    [exportDropdownTo]
  );

  return customerCode === 'cma' ? (
    <Base enableDarkTheme={enableDarkTheme}>
      <Typography
        sx={{
          color: enableDarkTheme
            ? ThemePalette.typography.white
            : ThemePalette.typography.black,
        }}
      >
        {notAvailableText}
      </Typography>
    </Base>
  ) : (
    <Base enableDarkTheme={enableDarkTheme}>
      <FlexBox
        sx={{
          width: '100%',
          justifyContent: 'space-between',
        }}
      />
      <Box
        sx={{
          backgroundColor: enableDarkTheme
            ? ThemePalette.dark.toolBarBackground
            : ThemePalette.light.toolBarBackground,
          height: '44px',
          opacity: 1,
          paddingLeft: '10px',
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <RadioGroup
          row
          aria-labelledby="demo-row-radio-buttons-group-label"
          name="row-radio-buttons-group"
          value={selectedAssetType}
          onChange={handleAssetTypeChange}
        >
          <FormControlLabel
            value="total"
            control={
              <Radio
                sx={{
                  color: enableDarkTheme
                    ? ThemePalette.typography.cornflowerBlue
                    : ThemePalette.typography.primary,
                  '&.Mui-checked': {
                    color: enableDarkTheme
                      ? ThemePalette.typography.cornflowerBlue
                      : ThemePalette.typography.primary,
                  },
                }}
              />
            }
            label="Total Fleet"
            sx={{
              color: enableDarkTheme
                ? ThemePalette.typography.white
                : ThemePalette.typography.black,
            }}
          />
          <FormControlLabel
            value="Haul Truck"
            control={
              <Radio
                sx={{
                  color: enableDarkTheme
                    ? ThemePalette.typography.cornflowerBlue
                    : ThemePalette.typography.primary,
                  '&.Mui-checked': {
                    color: enableDarkTheme
                      ? ThemePalette.typography.cornflowerBlue
                      : ThemePalette.typography.primary,
                  },
                }}
              />
            }
            label="Haulers"
            sx={{
              color: enableDarkTheme
                ? ThemePalette.typography.white
                : ThemePalette.typography.black,
            }}
          />
          <FormControlLabel
            value="Loader"
            control={
              <Radio
                sx={{
                  color: enableDarkTheme
                    ? ThemePalette.typography.cornflowerBlue
                    : ThemePalette.typography.primary,
                  '&.Mui-checked': {
                    color: enableDarkTheme
                      ? ThemePalette.typography.cornflowerBlue
                      : ThemePalette.typography.primary,
                  },
                }}
              />
            }
            label="Loaders"
            sx={{
              color: enableDarkTheme
                ? ThemePalette.typography.white
                : ThemePalette.typography.black,
            }}
          />
          <FormControlLabel
            value="Others"
            control={
              <Radio
                sx={{
                  color: enableDarkTheme
                    ? ThemePalette.typography.cornflowerBlue
                    : ThemePalette.typography.primary,
                  '&.Mui-checked': {
                    color: enableDarkTheme
                      ? ThemePalette.typography.cornflowerBlue
                      : ThemePalette.typography.primary,
                  },
                }}
              />
            }
            label="Dozers"
            sx={{
              color: enableDarkTheme
                ? ThemePalette.typography.white
                : ThemePalette.typography.black,
            }}
          />
        </RadioGroup>
        <Typography
          sx={{
            paddingRight: '5px',
            paddingLeft: '20px',
            color: enableDarkTheme
              ? ThemePalette.typography.white
              : ThemePalette.typography.black,
          }}
        >
          Make Model
        </Typography>
        <MultiSelector
          value={selectedAsset.map((item) => {
            return { id: item, display: item };
          })}
          renderValue={(selected) => {
            return `${selected?.length || 5} Selected`;
          }}
          handleSelectOne={(event) => {
            const selectedValue = event.target.value;
            const selectedItem = JSON.parse(
              selectedValue[selectedValue.length - 1]
            );
            const existsAlready = selectedAsset.find(
              (item) => item === selectedItem.id
            );
            if (existsAlready) {
              // Remove from list
              const newList = selectedAsset.filter(
                (item) => item !== selectedItem.id
              );
              if (newList.length === 0) {
                // disable chart until theres a selection
                setShowCharts(false);
              }
              setSelectedAsset(newList);
            } else {
              // add to list
              if (!showCharts) {
                setShowCharts(true);
              }
              setSelectedAsset([...selectedAsset, selectedItem.id]);
            }
            resetSelectedDevice();
          }}
          handleSelectAll={() => {}}
          selectorOptions={
            customerCode === 'klg'
              ? assetOptions.filter((option) =>
                  klgMakeModelsToFilter.includes(option.display.toLowerCase())
                )
              : customerCode === 'gbs'
              ? assetOptions.filter((option) =>
                  gbsMakeModelsToFilter.includes(option.display.toLowerCase())
                )
              : assetOptions
          }
          formControlSx={{
            fontFamily: 'open sans',
            fontSize: '14px',
            paddingBottom: '17px',
          }}
          minWidth="200px"
          disableSelectAll={true}
          messageIfSelectionEmpty="Select Make Model"
          enableDarkTheme={enableDarkTheme}
          enabledList={possibleAssetOptions}
        />

        <Typography
          sx={{
            marginLeft: '10px',
            marginRight: '10px',
            color: enableDarkTheme
              ? ThemePalette.typography.white
              : ThemePalette.typography.black,
          }}
        >
          For Date Range:
        </Typography>

        <DatePicker
          showIcon
          selected={selectedStartDate}
          onChange={handleDateChange}
          startDate={selectedStartDate}
          endDate={selectedEndDate}
          maxDate={new Date(new Date().getTime())}
          minDate={new Date(new Date().getTime() - 365 * 24 * 60 * 60 * 1000)}
          selectsRange
        />

        <Typography
          sx={{
            marginLeft: '10px',
            color: enableDarkTheme
              ? ThemePalette.typography.white
              : ThemePalette.typography.black,
          }}
        >
          ({dayjs(selectedStartDate).format('MMM DD, YYYY')}-
          {dayjs(selectedEndDate).format('MMM DD, YYYY')})
        </Typography>

        <ExportSelector
          view={''}
          exportToLabel={exportToLabel}
          exportTo={exportDropdownTo}
          onExportToChange={handleExportDropDownChange}
          itemArr={['excel', 'pdf']}
          enableDarkTheme={enableDarkTheme}
        />
      </Box>

      <Box
        sx={{
          paddingTop: '30px',
          paddingBottom: '30px',
        }}
      >
        <AnalysisWrapper
          enableDarkTheme={enableDarkTheme}
          data={fuelAnalytics.historicalAnalysisCard}
          value={value}
          isLoading={fuelAnalytics.isLoading}
          assetOptions={assetOptions}
          unitSystem={unitSystem}
        />
      </Box>
      <Box
        sx={{
          backgroundColor: enableDarkTheme
            ? ThemePalette.dark.boxBackground
            : ThemePalette.light.boxBackground,
          minHeight: '700px',
          paddingLeft: '10px',
          paddingRight: '10px',
        }}
      >
        {showCharts ? (
          selectedAssetType === 'Haul Truck' || showHaulTrucks ? (
            <HaulTruckView
              makeModel={selectedAsset}
              showConsumptionRange={true}
              exportTo={exportTo}
              handleExport={handleExportClick}
              selectedDuration={selectedDuration}
              selectedConsumptionRange={selectedConsumptionRange}
              setSelectedConsumptionRange={setSelectedConsumptionRange}
              fuelPriceData={fuelPriceData}
              enableDarkTheme={enableDarkTheme}
              startDateFilter={selectedStartDate}
              endDateFilter={selectedEndDate}
              selectedDevice={selectedDevice}
            />
          ) : (
            <OtherAssetsView
              makeModel={selectedAsset}
              showConsumptionRange={true}
              exportTo={exportTo}
              handleExport={handleExportClick}
              selectedDuration={selectedDuration}
              selectedConsumptionRange={selectedConsumptionRange}
              setSelectedConsumptionRange={setSelectedConsumptionRange}
              fuelPriceData={fuelPriceData}
              enableDarkTheme={enableDarkTheme}
              startDateFilter={selectedStartDate}
              endDateFilter={selectedEndDate}
              selectedDevice={selectedDevice}
            />
          )
        ) : (
          <></>
        )}
      </Box>
    </Base>
  );
};
