import {
  Box,
  Typography,
  Divider,
  RadioGroup,
  FormControlLabel,
  Radio,
  Stack,
} from '@mui/material';
import { useState, useEffect } from 'react';
import ComposedChartComponent from 'components/Charts/ComposedChart';
import { SVG } from 'components/Asset/SVG';
import { MultiSelector } from 'components/Form/Selectors/MultiSelector';
import {
  handleSelectAll,
  handleSelectSingle,
} from 'views/Settings/Rules/helpers/common';
import type { SelectOptions } from 'types/utils.types';
import { type Asset } from 'types/asset.types';
import NoChartData from 'views/FuelAnalytics/NoChart';
import { ThemePalette } from 'mui.theme';

interface Props {
  assets: Asset[];
  chartData: Array<{ device: string }>;
  barKeys: Array<{ name: string; color: string; label: string }>;
  lineKeys: Array<{
    name: string;
    axisName: string;
    color: string;
    strokeWidth: number;
    strokeDashArray: string;
  }>;
  xAxis: string;
  title: string;
  getChartData: (arg0: string, arg1: string[]) => void;
  isLoading: boolean;
  enableDarkTheme: boolean;
  data: any;
  selectedDevice: string | undefined;
}

const FuelConsumption = ({
  assets,
  chartData,
  barKeys,
  lineKeys,
  xAxis,
  title,
  getChartData,
  isLoading,
  enableDarkTheme = false,
  data,
  selectedDevice,
}: Props) => {
  const [idling, setIdling] = useState<string>('mostIdling');
  const [selectedAssets, setSelectedAssets] = useState<{
    assets: SelectOptions[];
  }>({
    assets: [],
  });
  const [assetSelectOptions, setAssetSelectOptions] = useState<SelectOptions[]>(
    []
  );

  useEffect(() => {
    const assetIds = selectedAssets.assets.map((item: SelectOptions) => {
      return item.id;
    });
    getChartData(idling, assetIds);
  }, [selectedAssets, idling]);

  useEffect(() => {
    if (assets?.length > 0) {
      const optionsToSelect = assets?.map((item: Asset) => {
        return {
          id: item.device.deviceId,
          display: item.bumperNumber,
        };
      });
      setAssetSelectOptions(optionsToSelect);
    }
  }, [assets]);

  useEffect(() => {
    if (selectedDevice) {
      setIdling('chosenIdling');
      const defaultItem = assetSelectOptions.filter(
        (item) => item.id === selectedDevice
      );
      setSelectedAssets({
        assets: [...defaultItem],
      });
    } else {
      setIdling('mostIdling');
    }
  }, [data]);

  return (
    <Box
      sx={{
        marginRight: '4px',
        marginBottom: '10px',
        paddingTop: '20px',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          width: '100%',
        }}
      >
        <Stack direction="row" flexWrap="wrap">
          <Typography
            style={{
              paddingTop: '8px',
              paddingLeft: '20px',
              fontSize: '13px',
              color: enableDarkTheme
                ? ThemePalette.typography.white
                : ThemePalette.typography.black,
            }}
          >
            {title}
          </Typography>
          <span
            style={{
              paddingLeft: '10px',
            }}
          ></span>
          <span
            style={{
              paddingTop: '7px',
              paddingLeft: '5px',
            }}
          >
            <SVG name="infoTooltipIcon" />
          </span>
          <span
            style={{
              paddingTop: '8px',
              paddingLeft: '7px',
              color: enableDarkTheme
                ? ThemePalette.typography.white
                : ThemePalette.typography.black,
            }}
          >
            :
          </span>
          <span
            style={{
              paddingLeft: '5px',
            }}
          >
            <RadioGroup
              row
              aria-labelledby="idling-row-radio-buttons-group-label"
              name="idling-radio-buttons-group"
              defaultValue="mostIdling"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setIdling(event.target.value.toString());
              }}
            >
              <FormControlLabel
                value="mostIdling"
                control={
                  <Radio
                    size="small"
                    sx={{
                      color: enableDarkTheme
                        ? ThemePalette.typography.cornflowerBlue
                        : ThemePalette.typography.primary,
                      '&.Mui-checked': {
                        color: enableDarkTheme
                          ? ThemePalette.typography.cornflowerBlue
                          : ThemePalette.typography.primary,
                      },
                    }}
                  />
                }
                label="5 w/ Most Idling Time"
                checked={idling === 'mostIdling'}
                sx={{
                  color: enableDarkTheme
                    ? ThemePalette.typography.white
                    : ThemePalette.typography.black,
                }}
              />
              <FormControlLabel
                value="chosenIdling"
                control={
                  <Radio
                    size="small"
                    sx={{
                      color: enableDarkTheme
                        ? ThemePalette.typography.cornflowerBlue
                        : ThemePalette.typography.primary,
                      '&.Mui-checked': {
                        color: enableDarkTheme
                          ? ThemePalette.typography.cornflowerBlue
                          : ThemePalette.typography.primary,
                      },
                    }}
                  />
                }
                label=""
                checked={idling === 'chosenIdling'}
                sx={{
                  color: enableDarkTheme
                    ? ThemePalette.typography.white
                    : ThemePalette.typography.black,
                }}
              />
            </RadioGroup>
          </span>
          <span>
            <MultiSelector
              value={selectedAssets.assets}
              renderValue={(selected) => {
                return `${selected?.length || 5} Asset(s) Selected`;
              }}
              handleSelectOne={(event) => {
                handleSelectSingle(
                  event,
                  selectedAssets,
                  setSelectedAssets,
                  'assets',
                  5
                );
              }}
              handleSelectAll={() => {
                handleSelectAll(
                  assetSelectOptions,
                  selectedAssets,
                  setSelectedAssets,
                  'assets'
                );
              }}
              selectorOptions={assetSelectOptions}
              formControlSx={{
                paddingTop: '5px',
              }}
              minWidth="200px"
              disableSelectAll={true}
              isDisabled={idling !== 'chosenIdling' ?? true}
              isSelectionEmpty={!selectedAssets.assets.length}
              messageIfSelectionEmpty="Select Up to 5 Assets"
              enableDarkTheme={enableDarkTheme}
            />
          </span>
        </Stack>
      </Box>
      <Divider
        sx={{
          paddingTop: '5px',
          marginBottom: '10px',
        }}
      />
      <Box
        sx={{
          height: '400px',
        }}
      >
        {isLoading ? (
          <></>
        ) : chartData?.length ? (
          <ComposedChartComponent
            data={chartData}
            barKeys={barKeys}
            lineKeys={lineKeys}
            xAxis={xAxis}
            showLegend={true}
            enableDarkTheme={enableDarkTheme}
          />
        ) : (
          <NoChartData enableDarkTheme={enableDarkTheme} />
        )}
      </Box>
    </Box>
  );
};

export default FuelConsumption;
