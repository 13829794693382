import { Typography } from '@mui/material';
import { InteractiveModal } from 'components/Modals/InteractiveModal/BaseModal';
import ExternalUserHandler from 'handlers/external.user.handler';

export {};

/**
 *
 * The Deactivation modal is a modal that is used to deactivate an External user.
 */

interface Props {
  open: boolean;
  userId: string;
  firstName: string;
  lastName: string;
  handleAbort: () => void;
}

export const ExternalUserDeactivationModal = ({
  open,
  userId,
  firstName,
  lastName,
  handleAbort,
}: Props) => {
  const userHandler = new ExternalUserHandler();
  const title = `Confirm Deactivation of ${firstName} ${lastName}`;
  const active = false;
  return (
    <InteractiveModal
      initialState={open}
      title={title}
      handleAbort={handleAbort}
      SuccessButtonProps={{
        onClick: async () => {
          await userHandler.deactivateExternalUser(userId, firstName, lastName);
          handleAbort();
        },
        disabled: false,
        label: 'Deactivate User',
      }}
      ModalContentProps={{
        gridItemProps: {
          lg: 12,
        },
      }}
    >
      <Typography key="blurb-1" variant="medium">
        Upon user deactivation, this user will no longer have access to the
        software
      </Typography>
      <Typography key="blurb-2" variant="medium">
        {"To reactivate a user, click the 'Inactive' Link under 'Status'"}
      </Typography>
    </InteractiveModal>
  );
};
