import SquaresMaintenanceHandler from 'handlers/squaresHandlers/squaresMaintenance.handler';
import { useDispatch } from 'react-redux';
import { updateLoadingState } from 'store/app.slice';
import { type MaintenanceSquares } from 'types/squares.type';

// Custom hook
export const useFetchDataPmExpenditures = () => {
  const dispatch = useDispatch();
  const squaresHandler = new SquaresMaintenanceHandler();

  const fetchData = async (
    selectedStartDate: Date,
    selectedEndDate: Date,
    selectedAssetIds: string[],
    sort: string,
    category: string
  ): Promise<MaintenanceSquares[]> => {
    dispatch(updateLoadingState(true));
    try {
      const squaresData = await squaresHandler.getSelectedAssetsPmExpenditures(
        selectedStartDate,
        selectedEndDate,
        selectedAssetIds,
        sort,
        category
      );

      return squaresData.Items;
    } catch (error) {
      console.error(error);
      return []; // Return an empty array in case of an error
    } finally {
      dispatch(updateLoadingState(false));
    }
  };

  return fetchData;
};
export const useFetchDataAssetsComponentPmCost = () => {
  const dispatch = useDispatch();
  const squaresHandler = new SquaresMaintenanceHandler();

  const fetchData = async (
    selectedStartDate: Date,
    selectedEndDate: Date,
    selectedAssetIds: string[],
    category: string
  ): Promise<MaintenanceSquares[]> => {
    dispatch(updateLoadingState(true));
    try {
      const squaresData = await squaresHandler.getSelectedAssetsComponentPmCost(
        selectedStartDate,
        selectedEndDate,
        selectedAssetIds,
        category
      );
      return squaresData.Items;
    } catch (error) {
      console.error(error);
      return []; // Return an empty array in case of an error
    } finally {
      dispatch(updateLoadingState(false));
    }
  };

  return fetchData;
};
export const useFetchDataPmVsCmCost = () => {
  const dispatch = useDispatch();
  const squaresHandler = new SquaresMaintenanceHandler();

  const fetchData = async (
    selectedStartDate: Date,
    selectedEndDate: Date,
    selectedAssetIds: string[],
    category: string
  ): Promise<any[]> => {
    dispatch(updateLoadingState(true));
    try {
      const squaresData = await squaresHandler.getSelectedAssetsPmVsCmCost(
        selectedStartDate,
        selectedEndDate,
        selectedAssetIds,
        category
      );
      return squaresData.Items;
    } catch (error) {
      console.error(error);
      return []; // Return an empty array in case of an error
    } finally {
      dispatch(updateLoadingState(false));
    }
  };

  return fetchData;
};

interface ComponentCategory {
  components_category: string;
}

export const useFetchComponentsCategory = () => {
  const dispatch = useDispatch();
  const squaresHandler = new SquaresMaintenanceHandler();

  const fetchComponentsCategory = async (): Promise<
    Array<{ id: string; display: string }>
  > => {
    dispatch(updateLoadingState(true));
    try {
      const categories = await squaresHandler.getAllComponentsCategory();

      // Explicitly type categories as ComponentCategory[]
      if (categories && Array.isArray(categories)) {
        const formattedCategories = (categories as ComponentCategory[]).map(
          (category, index) => ({
            id: index.toString(),
            display: category.components_category,
          })
        );
        return formattedCategories;
      }
      return [];
    } catch (error) {
      console.error('Error fetching component categories:', error);
      return [];
    } finally {
      // ... other code
    }
  };

  return fetchComponentsCategory;
};

interface Asset {
  asset_id: string;
  components_category: string;
}

// Custom hook for fetching assets
export const useFetchAssets = () => {
  const dispatch = useDispatch();
  const squaresHandler = new SquaresMaintenanceHandler();

  const fetchAssets = async (): Promise<
    Array<{ id: string; display: string; components: string }>
  > => {
    dispatch(updateLoadingState(true));
    try {
      const assets = await squaresHandler.getAllAssets('1000');
      if (assets && Array.isArray(assets)) {
        const formattedAssets = (assets as Asset[]).map((asset) => ({
          id: asset.asset_id, // Access the asset_id property of the asset object
          display: asset.asset_id,
          components: asset.components_category,
        }));

        return formattedAssets;
      }
      return assets;
    } catch (error) {
      console.error(error);
      return [];
    } finally {
      dispatch(updateLoadingState(false));
    }
  };

  return fetchAssets;
};
