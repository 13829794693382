import React from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  LabelList,
  ResponsiveContainer,
} from 'recharts';
import { format, addDays, addYears } from 'date-fns';
import {
  type AssetsOverview,
  type CumulativeOverview,
} from 'types/survivalAnalysis.types';
import { categoryMapping } from '../Function/helper.function';
import {
  CustomXAxisTick,
  CustomYAxisTick,
  renderCustomLabel,
  processData,
} from '../Function/detail.listView.helper.function';
import { Box, Typography } from '@mui/material';
import { ThemePalette } from 'mui.theme';
import { Cancel, RemoveCircle, Error } from '@mui/icons-material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const startingDate = new Date();
const endDate = addYears(startingDate, 1);

interface SurvivalAnalysisDetailsListViewProps {
  components: AssetsOverview[];
  assetsPerSubComponent: CumulativeOverview[];
  enableDarkTheme: boolean;
  displayMode: string;
}

const CustomTooltip = ({
  active,
  payload,
  label,
  enableDarkTheme,
}: {
  active?: boolean;
  payload?: any[];
  label?: any;
  enableDarkTheme?: boolean;
}) => {
  // Define messages for each risk level
  const riskMessages: Record<string, string> = {
    eol: 'End of Life warning!',
    high: 'High risk level. Proceed with caution.',
    med: 'Moderate risk. Stay alert.',
    low: 'Low risk. Situation under control.',
  };

  // Define icons for each risk level
  const riskIcons: Record<string, React.ElementType> = {
    eol: Cancel,
    high: Error,
    med: RemoveCircle,
    low: CheckCircleIcon,
  };

  // Check if the tooltip should be displayed
  if (active && payload && payload.length) {
    return (
      <Box
        sx={{
          backgroundColor: enableDarkTheme
            ? ThemePalette.dark.pageBackground
            : ThemePalette.light.pageBackground,
          padding: '10px',
        }}
      >
        {payload.map((entry, index) => {
          // Check if entry.value is not 0
          if (entry.value !== 0) {
            // Get the icon component based on entry.name
            const IconComponent = riskIcons[entry.name];

            return (
              <Box
                key={`listViewItem-${index}`}
                sx={{
                  display: 'flex',
                  alignItems: 'left',
                  justifyContent: 'left',
                  marginTop: 1,
                }}
              >
                {/* Render the icon */}
                {IconComponent && (
                  <IconComponent
                    style={{
                      color: categoryMapping[entry.name]?.color || 'gray',
                      marginRight: '8px',
                    }}
                  />
                )}
                <Typography
                  sx={{
                    color: enableDarkTheme
                      ? ThemePalette.typography.grey
                      : ThemePalette.typography.black,
                  }}
                  variant="body2"
                >
                  {entry.name === 'eol'
                    ? `${entry.name} Risk: ${
                        riskMessages[entry.name] || 'Unknown risk level.'
                      }`
                    : `${entry.name} Risk: ${entry.value} Days left - ${
                        riskMessages[entry.name] || 'Unknown risk level.'
                      }`}
                </Typography>
              </Box>
            );
          }
          return null;
        })}
      </Box>
    );
  }

  return null;
};

const SurvivalAnalysisDetailsListView: React.FC<
  SurvivalAnalysisDetailsListViewProps
> = ({ components, assetsPerSubComponent, enableDarkTheme, displayMode }) => {
  const data = processData(components, startingDate, endDate);

  const ticks = Array.from({ length: 366 }, (_, i) => i);
  const formattedTicks = ticks.map((tick) =>
    format(addDays(startingDate, tick), 'MMM dd')
  );

  const backgroundWidth = 200;
  const backgroundHeight = 50;

  return (
    <div style={{ width: '100%', height: 'auto' }}>
      <ResponsiveContainer width="100%" height={data.length * 80}>
        <BarChart
          width={1200}
          height={data.length * 80}
          data={data}
          layout="vertical"
          margin={{ top: 5, right: 30, left: 100, bottom: 5 }}
        >
          <XAxis
            type="number"
            dataKey="date"
            ticks={ticks}
            tickFormatter={(tick) =>
              format(addDays(startingDate, tick), 'MMM dd')
            }
            interval={30}
            orientation="top"
            tick={(props) => (
              <CustomXAxisTick
                {...props}
                backgroundWidth={backgroundWidth}
                backgroundHeight={backgroundHeight}
                formattedTicks={formattedTicks}
              />
            )}
          />
          <YAxis
            type="category"
            dataKey="name"
            label={{
              color: enableDarkTheme
                ? ThemePalette.typography.white
                : ThemePalette.typography.black,
            }}
            tick={(props) => (
              <CustomYAxisTick
                {...props}
                backgroundWidth={backgroundWidth}
                backgroundHeight={backgroundHeight}
                enableDarkTheme={enableDarkTheme}
              />
            )}
          />
          <Tooltip
            content={<CustomTooltip enableDarkTheme={enableDarkTheme} />}
          />
          <Bar
            dataKey="low"
            stackId="a"
            fill={categoryMapping.low.color}
            isAnimationActive={false}
          >
            <LabelList
              dataKey="low"
              content={(props) =>
                renderCustomLabel({
                  ...props,
                  customKey: 'Low',
                })
              }
            />
          </Bar>
          <Bar
            dataKey="med"
            stackId="a"
            fill={categoryMapping.med.color}
            isAnimationActive={false}
          >
            <LabelList
              dataKey="med"
              content={(props) =>
                renderCustomLabel({
                  ...props,
                  customKey: 'Med',
                })
              }
            />
          </Bar>
          <Bar
            dataKey="high"
            stackId="a"
            fill={categoryMapping.high.color}
            isAnimationActive={false}
          >
            <LabelList
              dataKey="high"
              content={(props) =>
                renderCustomLabel({
                  ...props,
                  customKey: 'High',
                })
              }
            />
          </Bar>
          <Bar
            dataKey="eol"
            stackId="a"
            fill={categoryMapping.eol.color}
            isAnimationActive={false}
          >
            <LabelList
              dataKey="eol"
              content={(props) =>
                renderCustomLabel({
                  ...props,
                  customKey: 'EOL',
                })
              }
            />
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default SurvivalAnalysisDetailsListView;
