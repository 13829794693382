import { SVG } from 'components/Asset/SVG';
import { LinkButton } from 'components/Buttons/LinkButton';
import { Base } from 'components/Containers/BaseContainer';
import { FlexBox } from 'components/Containers/FlexBox';
import { ToolbarContainer } from 'components/Containers/ToolbarContainer';
import { useState } from 'react';
import { CustomersList } from './components/CustomerList';
import { CreateCustomerModal } from './components/modals/CreateCustomerModal';
import { useAppSelector } from 'store/hook';
import { isDarkTheme } from 'utils/theme';
import { ThemePalette } from 'mui.theme';

export const CustomersView = (): JSX.Element => {
  const [isCreateCustomerModalOpen, setIsCreateCustomerModalOpen] =
    useState(false);

  const theme = useAppSelector((state) => state.authReducer).customer.theme;
  const enableDarkTheme = isDarkTheme(theme);

  return (
    <Base enableDarkTheme={enableDarkTheme}>
      <CreateCustomerModal
        key={`${isCreateCustomerModalOpen.toString()}-create-customer-modal`}
        open={isCreateCustomerModalOpen}
        handleAbort={() => {
          setIsCreateCustomerModalOpen(false);
        }}
        title="Create a New Customer Account"
        enableDarkTheme={enableDarkTheme}
      />
      <ToolbarContainer enableDarkTheme={enableDarkTheme}>
        <FlexBox
          sx={{
            width: '100%',
            justifyContent: 'space-between',
          }}
        >
          <div></div>
          <FlexBox
            sx={{
              display: 'flex',
            }}
          >
            <SVG name="add" style={{ marginRight: '10px' }} />

            <LinkButton
              onClick={() => {
                setIsCreateCustomerModalOpen(true);
              }}
              color={
                enableDarkTheme
                  ? ThemePalette.typography.wheat
                  : ThemePalette.typography.primary
              }
            >
              Add a New Customer Account
            </LinkButton>
          </FlexBox>
        </FlexBox>
      </ToolbarContainer>
      <CustomersList />
    </Base>
  );
};
