import { createSlice } from '@reduxjs/toolkit';
import { type MaintenanceSquares } from 'types/squares.type';

export interface MaintenanceSquaresState {
  squares: MaintenanceSquares[];
  squareAssets: MaintenanceSquares[];
}

const initialState: MaintenanceSquaresState = {
  squares: [],
  squareAssets: [],
};

export const squaresMaintenanceSlice = createSlice({
  name: 'squares',
  initialState,
  reducers: {
    appendSquares(state, { payload }) {
      state.squares.push(payload);
    },

    updateSquaresList(state, { payload }: { payload: any }) {
      const index = state.squares.findIndex(
        (squares) => squares.asset_id === payload.squaresId
      );

      if (index === -1) {
        // do not update the state if the squares is not found
        return;
      }

      state.squares[index] = payload;
    },

    setSquares(state, { payload }) {
      state.squares = payload;
    },

    setSquaresAssets(state, { payload }) {
      state.squareAssets = payload;
    },

    removeSquares(state, { payload }: { payload: { squaresId: string } }) {
      const filtedSquares = state.squares.filter(
        (squares) => squares.asset_id !== payload.squaresId
      );
      state.squares = filtedSquares;
    },
  },
});

export const {
  appendSquares,
  updateSquaresList,
  setSquares,
  removeSquares,
  setSquaresAssets,
} = squaresMaintenanceSlice.actions;

export default squaresMaintenanceSlice.reducer;
