import React from 'react';
import LoadingPieChart from './pieChart/LoadingPieChar.pieChart';
import { PieComponent } from './pieChart/PieChart.pieChart';
import { NoDataPieChart } from './pieChart/NoData.pieChart';

interface PieChartComponentProps {
  uniqueKey: string;
  dataPreventative: number;
  dataCorrective: number;
  header: string;
  isLoading: boolean;
  isNoData?: boolean;
  enableDarkTheme?: boolean;
}

export const PieChartComponent: React.FC<PieChartComponentProps> = ({
  uniqueKey,
  dataPreventative,
  dataCorrective,
  header,
  isLoading,
  isNoData,
  enableDarkTheme = false,
}) => {
  return (
    <div>
      {isLoading ? (
        <LoadingPieChart />
      ) : isNoData ? (
        <NoDataPieChart enableDarkTheme={enableDarkTheme} />
      ) : (
        <PieComponent
          key={uniqueKey}
          dataPreventative={dataPreventative}
          dataCorrective={dataCorrective}
          header={header}
          enableDarkTheme={enableDarkTheme}
        />
      )}
    </div>
  );
};
