import { type Asset, type ASSET_TYPES_ENUM } from 'types/asset.types';
import { ASSET_TYPES } from 'utils/enums';

// get display from asset id
export const resolveAssetTypeDisplay = (assetType: ASSET_TYPES_ENUM) => {
  return (
    ASSET_TYPES.find((asset) => asset.id === assetType)?.display ?? assetType
  );
};

// get asset id from display
export const resolveAssetTypeId = (assetDisplay: string) => {
  return ASSET_TYPES.find((asset) => asset.display === assetDisplay)?.id ?? '';
};
type AssetType =
  | 'light_truck'
  | 'heavy_truck'
  | 'haul_truck'
  | 'dozer'
  | 'grader'
  | 'loader'
  | 'excavator'
  | 'drill'
  | 'bolter'
  | 'miscellaneous'
  | 'others';

export const assetTypeGroup = (assetType: AssetType) => {
  let returnTypes = [];
  switch (assetType) {
    case 'haul_truck':
      returnTypes = ['light_truck', 'heavy_truck', 'haul_truck'];
      break;
    case 'loader':
      returnTypes = ['loader'];
      break;
    case 'others':
      returnTypes = [
        'dozer',
        'grader',
        'excavator',
        'drill',
        'bolter',
        'miscellaneous',
      ];
      break;
    default:
      returnTypes = ['haul_truck'];
      break;
  }
  return returnTypes;
};

export const getSpecificAssetTypesFromMakeModel = (
  obj: any,
  type: AssetType
) => {
  const types = assetTypeGroup(type);
  const filteredEntries = Object.entries(obj).filter(
    ([key, value]: any): AssetType[] =>
      value.some((item: Asset) => types.includes(item.assetType))
  );
  return Object.fromEntries(filteredEntries);
};
