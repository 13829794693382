import { FlexBox } from 'components/Containers/FlexBox';
import { DeviceList } from './components/DeviceList';

export const DeviceListView = () => {
  return (
    <>
      <FlexBox
        sx={{
          width: '100%',
          justifyContent: 'space-between',
        }}
      >
        <div></div>
        <FlexBox
          sx={{
            display: 'flex',
          }}
        ></FlexBox>
      </FlexBox>
      <DeviceList />
    </>
  );
};
