import { useState } from 'react';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import {
  List,
  ListItem,
  ListItemText,
  Collapse,
  Checkbox,
} from '@mui/material';
import { ThemePalette } from 'mui.theme';

interface Props {
  enableDarkTheme: boolean;
  filterList: string[];
  title: string;
  checkedList: string[];
  action: any;
}

const FilterItem = ({
  enableDarkTheme,
  filterList,
  title,
  checkedList,
  action,
}: Props) => {
  const [itemOpen, setOpen] = useState(false);

  const handleClick = () => {
    setOpen((prev) => !prev);
  };

  const handleToggle = (value: string) => () => {
    let result: string[] = [...checkedList];
    const isItemSelected =
      checkedList.filter((item) => item === value).length > 0;

    if (isItemSelected) {
      // Remove since its checked already
      result = checkedList.filter((item) => item !== value);
    } else {
      // Add to checked list
      result = [...checkedList, value];
    }
    action(result);
  };

  function handleSingleClick(value: string) {
    return () => {
      action(value);
    };
  }

  return (
    <>
      <ListItem onClick={handleClick}>
        <ListItemText
          sx={{
            color: enableDarkTheme
              ? ThemePalette.typography.white
              : ThemePalette.typography.black,
          }}
          primary={title}
        />
        {itemOpen ? (
          <ExpandLess
            sx={{
              color: enableDarkTheme
                ? ThemePalette.typography.white
                : ThemePalette.typography.black,
            }}
          />
        ) : (
          <ExpandMore
            sx={{
              color: enableDarkTheme
                ? ThemePalette.typography.white
                : ThemePalette.typography.black,
            }}
          />
        )}
      </ListItem>
      <Collapse in={itemOpen} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {filterList.map((child, key) => (
            <ListItem key={key}>
              <Checkbox
                edge="start"
                checked={checkedList.includes(child)}
                tabIndex={-1}
                disableRipple
                sx={{
                  color: enableDarkTheme
                    ? ThemePalette.typography.cornflowerBlue
                    : ThemePalette.typography.primary,
                  '&.Mui-checked': {
                    color: enableDarkTheme
                      ? ThemePalette.typography.cornflowerBlue
                      : ThemePalette.typography.primary,
                  },
                  '&.Mui-disabled': {
                    color: ThemePalette.typography.secondary,
                  },
                }}
                onClick={
                  title === 'Asset Type'
                    ? handleSingleClick(child)
                    : handleToggle(child)
                }
              />
              <ListItemText
                sx={{
                  color: enableDarkTheme
                    ? ThemePalette.typography.white
                    : ThemePalette.typography.black,
                }}
                primary={child as React.ReactNode}
              />
            </ListItem>
          ))}
        </List>
      </Collapse>
    </>
  );
};

export default FilterItem;
