import { useState, useEffect } from 'react';
import { type Asset } from 'types/asset.types';
import FuelConsumption from './FuelConsumption';
import type { FuelNoneIdleEventItem } from 'types/fuelAnalytics';
import Colors from 'views/Settings/ExternalUsers/Squares/Helpers/Graphs/styles';
import {
  fuelNonIdleConsumptionLabels,
  getFuelLabelWrtUnitSystem,
} from 'utils/helpers/labels';
import { useAppSelector } from 'store/hook';

interface Props {
  assets: Asset[];
  fuelNoneIdleEvents: any;
  isLoading: boolean;
  enableDarkTheme: boolean;
  selectedDevice: string | undefined;
}

const FuelNoneIdleConsumption = ({
  assets,
  fuelNoneIdleEvents,
  isLoading,
  enableDarkTheme = false,
  selectedDevice,
}: Props) => {
  const unitSystem = useAppSelector((state) => state.persistedReducer).customer
    .unitSystem;
  const [chartData, setChartData] = useState<FuelNoneIdleEventItem[]>([]);

  useEffect(() => {
    setChartData(
      formatData(
        fuelNoneIdleEvents.sortedTop5NonEventIdlingData,
        fuelNoneIdleEvents.nonIdlingAverage
      )
    );
  }, [fuelNoneIdleEvents]);

  const getChartData = async (idling: string, assets: string[]) => {
    let data = chartData;

    if (idling === 'mostIdling') {
      data = fuelNoneIdleEvents.sortedTop5NonEventIdlingData;
    } else if (assets.length) {
      const filteredData = fuelNoneIdleEvents.data.reduce(
        (accumulator: any, dataEntry: any) => {
          if (assets.includes(dataEntry.device)) {
            accumulator.push(dataEntry);
          }
          return accumulator;
        },
        []
      );

      data = filteredData;
    }
    setChartData(formatData(data, fuelNoneIdleEvents.nonIdlingAverage));
  };

  return (
    <FuelConsumption
      title="Total Fuel Consumed While Idling"
      assets={assets}
      chartData={chartData}
      barKeys={[
        {
          name: 'Total Fuel Consumed',
          color: Colors.green,
          label: `Total Fuel Consumption Index In Non-Event Idling Time (${getFuelLabelWrtUnitSystem(
            unitSystem,
            fuelNonIdleConsumptionLabels.total_fuel_consumed
          )})`,
        },
        {
          name: 'Total Idling Time Minutes',
          color: Colors.lightBlue,
          label: `Total Fuel Consumption Index In Non-Event Idling Time (${getFuelLabelWrtUnitSystem(
            unitSystem,
            fuelNonIdleConsumptionLabels.total_idle_time_minutes
          )})`,
        },
        {
          name: 'CO2',
          color: Colors.dimGray,
          label: `CO2 Emission ${getFuelLabelWrtUnitSystem(
            unitSystem,
            fuelNonIdleConsumptionLabels.CO2
          )}`,
        },
      ]}
      lineKeys={[
        {
          name: 'Fleet Avg Idling Time',
          axisName: 'Total Idling Time Minutes',
          color: '#C77838',
          strokeWidth: 2,
          strokeDashArray: '3 3',
        },
      ]}
      xAxis="bumperNumber"
      getChartData={getChartData}
      isLoading={isLoading}
      enableDarkTheme={enableDarkTheme}
      data={fuelNoneIdleEvents}
      selectedDevice={selectedDevice}
    />
  );
};

export default FuelNoneIdleConsumption;

function formatData(data: FuelNoneIdleEventItem[], nonIdlingAverage: number) {
  return data.map((item: FuelNoneIdleEventItem) => {
    const updatedItem = {
      ...item,
      'Total Fuel Consumed': item.total_idle_fuel_consumption,
      'Total Idling Time Minutes': item.total_idle_time_minutes,
      'Fleet Avg Idling Time': item.avg_idle_time_minutes,
    };

    return updatedItem;
  });
}
