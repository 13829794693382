import { Box, Typography, FormControlLabel, Checkbox } from '@mui/material';
import { FlexBox } from 'components/Containers/FlexBox';
import { InteractiveModal } from 'components/Modals/InteractiveModal/BaseModal';
import { Selector } from 'components/Form/Selectors/Selector';
import { MultiSelector } from 'components/Form/Selectors/MultiSelector';
import AssetHandler from 'handlers/asset.handler';
import { useEffect, useState } from 'react';
import { modalInputFieldStyle } from 'styles/global.css';
import { createOrUpdateAssetRequestBodySchema } from 'utils/schemas/asset.schema';
import { validateYupSchema } from 'utils/validators/schema.validator';
import { SWTextField } from 'components/Form/Input/SWTextField';
import { type ICreateAssetPayload } from 'types/payloads/asset.payload.types';
import { ASSET_TYPES, FUEL_TYPES } from 'utils/enums';
import { type FUEL_TYPES_ENUM, type ASSET_TYPES_ENUM } from 'types/asset.types';
import { ThemePalette } from 'mui.theme';
import {
  handleSelectOperatorSingle,
  handleSelectOperatorAll,
  handleOperationalStatus,
} from 'views/Settings/ExternalUsers/AssetView/helpers/common';
import { useAppSelector } from 'store/hook';

interface Props {
  title: string;
  open: boolean;
  handleAbort: () => void;
  enableDarkTheme: boolean;
}

export const CreateAsset = ({
  title,
  handleAbort,
  open,
  enableDarkTheme,
}: Props) => {
  const assetHandler = new AssetHandler();
  const [isValidForm, setFormValidity] = useState(false);
  const [asset, setAsset] = useState<ICreateAssetPayload>({
    assetVin: '',
    assetType: 'light_truck',
    isActive: true,
    make: '',
    model: '',
    bumperNumber: '',
    engineHoursOffset: 0,
    odometerOffset: 0,
    fuelSource: 'Unknown',
    operators: [],
    externalId: null,
    assetNotes: [],
  });

  const { operators } = useAppSelector((state) => state.operatorReducer);
  const transformedOperators: Array<{ id: string; display: string }> =
    operators.map((operator) => {
      return {
        id: operator.operatorId,
        display: operator.operatorName,
      };
    });

  // Validate form as user fills out fields
  useEffect(() => {
    const validateForm = async () =>
      await validateYupSchema(asset, createOrUpdateAssetRequestBodySchema);

    void validateForm().then((isValid) => {
      setFormValidity(isValid);
    });
  }, [asset]);

  const handleIntegerFieldsValue = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    let { value, name } = event.target;
    if (!value || isNaN(parseInt(value))) {
      value = '0';
    }
    setAsset({
      ...asset,
      [name]: parseInt(value),
    });
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;

    setAsset({
      ...asset,
      [name]: value,
    });
  };

  const handleSubmit = async () => {
    await assetHandler.createAsset(asset);

    handleAbort();
  };
  /**
   * This is the form that will be rendered in the modal
   *
   * Asset VIN/ID
   */
  return (
    <InteractiveModal
      initialState={open}
      SuccessButtonProps={{
        disabled: !isValidForm,
        onClick: handleSubmit,
        label: 'ADD NEW ASSET',
      }}
      handleAbort={handleAbort}
      title={title}
      confirmCancellation
      enableDarkTheme={enableDarkTheme}
    >
      {/* Asset VIN/ID TextField */}
      <SWTextField
        key="assetVin"
        name="assetVin"
        label="Asset VIN/ID*"
        placeholder="Enter VIN/ID Number"
        TextFieldProps={{
          name: 'assetVin',
          value: asset.assetVin,
          onChange: handleChange,
          InputProps: {
            sx: modalInputFieldStyle,
          },
        }}
        enableDarkTheme={enableDarkTheme}
      />

      {/* Asset Type selector drop down single */}
      <Box key="assetType">
        <Typography
          variant="bodyBold"
          display="block"
          mb={'4px'}
          sx={{
            color: enableDarkTheme
              ? ThemePalette.typography.wheat
              : ThemePalette.typography.black,
          }}
        >
          Asset Type*
        </Typography>
        <Selector
          value={asset.assetType}
          onChange={(event) => {
            const selectedValue = event.target.value as ASSET_TYPES_ENUM;
            setAsset({
              ...asset,
              assetType: selectedValue,
            });
          }}
          selectorOptions={ASSET_TYPES}
          enableDarkTheme={enableDarkTheme}
        />
      </Box>

      <SWTextField
        key="make"
        name="make"
        label="Make*"
        placeholder="Enter Asset Make"
        TextFieldProps={{
          name: 'make',
          value: asset.make,
          onChange: handleChange,
          InputProps: {
            sx: modalInputFieldStyle,
          },
        }}
        enableDarkTheme={enableDarkTheme}
      />

      <SWTextField
        key="model"
        name="model"
        label="Model*"
        placeholder="Enter Asset Model"
        TextFieldProps={{
          name: 'model',
          value: asset.model,
          onChange: handleChange,
          InputProps: {
            sx: modalInputFieldStyle,
          },
        }}
        enableDarkTheme={enableDarkTheme}
      />

      <SWTextField
        key="bumperNumber"
        name="bumperNumber"
        label="Bumper ID"
        placeholder="Enter Bumper ID"
        TextFieldProps={{
          name: 'bumperNumber',
          value: asset.bumperNumber,
          onChange: handleChange,
          InputProps: {
            sx: modalInputFieldStyle,
          },
        }}
        enableDarkTheme={enableDarkTheme}
      />

      {/* <FlexBox></FlexBox> */}

      {/* Fuel Source selector drop down single */}
      <Box key="fuelSource">
        <Typography
          variant="bodyBold"
          display="block"
          mb={'4px'}
          sx={{
            color: enableDarkTheme
              ? ThemePalette.typography.wheat
              : ThemePalette.typography.black,
          }}
        >
          Fuel Source*
        </Typography>
        <Selector
          value={asset.fuelSource}
          onChange={(event) => {
            const selectedValue = event.target.value as FUEL_TYPES_ENUM;
            setAsset({
              ...asset,
              fuelSource: selectedValue,
            });
          }}
          selectorOptions={FUEL_TYPES}
          enableDarkTheme={enableDarkTheme}
        />
      </Box>

      <SWTextField
        key="odometerOffset"
        name="odometerOffset"
        label="Odometer Reading Offset (km)"
        placeholder="Enter Odometer Reading Offset"
        TextFieldProps={{
          name: 'odometerOffset',
          value: asset.odometerOffset,
          onChange: handleIntegerFieldsValue,
          InputProps: {
            sx: modalInputFieldStyle,
          },
        }}
        enableDarkTheme={enableDarkTheme}
      />

      <SWTextField
        key="engineHoursOffset"
        name="engineHoursOffset"
        label="Engine Hours Offset"
        placeholder="Enter Engine Hours Offset"
        TextFieldProps={{
          name: 'engineHoursOffset',
          value: asset.engineHoursOffset,
          onChange: handleIntegerFieldsValue,
          InputProps: {
            sx: modalInputFieldStyle,
          },
        }}
        enableDarkTheme={enableDarkTheme}
      />

      {/* External ID TextField */}
      <SWTextField
        key="externalId"
        name="externalId"
        label="External ID"
        placeholder="Enter Customer Identified ID"
        TextFieldProps={{
          name: 'externalId',
          value: asset.externalId,
          onChange: handleChange,
          InputProps: {
            sx: modalInputFieldStyle,
          },
        }}
        enableDarkTheme={enableDarkTheme}
      />

      {/* Operators multi selector */}
      <Box key="operators">
        <Typography
          variant="bodyBold"
          display="block"
          mb={'4px'}
          sx={{
            color: enableDarkTheme
              ? ThemePalette.typography.wheat
              : ThemePalette.typography.black,
          }}
        >
          Operator(s)
        </Typography>
        <MultiSelector
          isSelectionEmpty={true}
          messageIfSelectionEmpty="No operators available"
          value={asset.operators}
          renderValue={(selected) => {
            return `${selected?.length || 0} Operators(s) Selected`;
          }}
          handleSelectOne={(event) => {
            handleSelectOperatorSingle(event, asset, setAsset);
          }}
          handleSelectAll={() => {
            handleSelectOperatorAll(transformedOperators, asset, setAsset);
          }}
          /*
        This stubbed response be replaced with real customer accounts
        */
          selectorOptions={transformedOperators}
          enableDarkTheme={enableDarkTheme}
        />
      </Box>

      {/* Operational Status Check Box */}
      <Box key="operationalStatus">
        <FormControlLabel
          control={
            <Checkbox
              sx={{
                color: enableDarkTheme
                  ? ThemePalette.typography.cornflowerBlue
                  : ThemePalette.typography.primary,
                '&.Mui-checked': {
                  color: enableDarkTheme
                    ? ThemePalette.typography.cornflowerBlue
                    : ThemePalette.typography.primary,
                },
              }}
            />
          }
          checked={!asset.isActive}
          label="Asset is currently out of service"
          onChange={() => {
            handleOperationalStatus(asset, setAsset);
          }}
          sx={{
            pt: 2,
            color: enableDarkTheme
              ? ThemePalette.typography.wheat
              : ThemePalette.typography.black,
          }}
        />
      </Box>

      <SWTextField
        key="assetNotes"
        name="assetNotes"
        label="Asset Notes"
        placeholder="Asset Notes -- optional"
        TextFieldProps={{
          name: 'assetNotes',
          // value: asset.assetNotes,
          onChange: handleChange,
          multiline: true,
          minRows: 2,
          fullWidth: true,
          InputProps: {
            sx: {
              WebkitBoxShadow: '0 0 0 1000px white inset',
            },
          },
        }}
        enableDarkTheme={enableDarkTheme}
      />
    </InteractiveModal>
  );
};
