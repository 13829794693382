import React from 'react';
import { Treemap, ResponsiveContainer, Tooltip } from 'recharts';
import { CustomizedContent } from './TreeChartCellRenderer';
import type { AssetData } from '../utils';
import { Box, Typography } from '@mui/material';
import { ThemePalette } from 'mui.theme';

const COLORS = ['#4683E6'];

const CustomTooltip: React.FC<{
  active?: boolean;
  payload?: any;
  label?: string;
  enableDarkTheme?: boolean;
}> = ({ active, payload, label, enableDarkTheme }) => {
  if (active && payload && payload.length) {
    const hoverCell = payload[0].payload;
    return (
      <>
        <Box
          sx={{
            backgroundColor: enableDarkTheme
              ? ThemePalette.dark.pageBackground
              : ThemePalette.light.pageBackground,
            padding: '10px',
          }}
        >
          <Typography
            sx={{
              color: enableDarkTheme
                ? ThemePalette.typography.grey
                : ThemePalette.typography.black,
            }}
            variant="body2"
          >
            Component: {hoverCell.name}
          </Typography>
          <Typography
            sx={{
              color: enableDarkTheme
                ? ThemePalette.typography.grey
                : ThemePalette.typography.black,
            }}
            variant="body2"
          >
            Total Cost: {hoverCell.total_cost}{' '}
          </Typography>
          <Typography
            sx={{
              color: enableDarkTheme
                ? ThemePalette.typography.grey
                : ThemePalette.typography.black,
            }}
            variant="body2"
          >
            Total Hours: {hoverCell.total_hours}{' '}
          </Typography>
          <Typography
            sx={{
              color: enableDarkTheme
                ? ThemePalette.typography.grey
                : ThemePalette.typography.black,
            }}
            variant="body2"
          >
            Total Labour Cost: {hoverCell.total_labour_cost}{' '}
          </Typography>
          <Typography
            sx={{
              color: enableDarkTheme
                ? ThemePalette.typography.grey
                : ThemePalette.typography.black,
            }}
            variant="body2"
          >
            Total Part Cost: {hoverCell.total_part_cost}{' '}
          </Typography>
        </Box>
      </>
    );
  }

  return null;
};

export const ComponentsTreeChart = ({
  selectedCell,
  handleCellClick,
  data,
  enableDarkTheme,
}: {
  selectedCell: string[];
  handleCellClick: any;
  data: AssetData[];
  enableDarkTheme?: boolean;
}) => {
  return (
    <ResponsiveContainer width="100%" height="100%">
      <Treemap
        width={500}
        height={600}
        data={data}
        dataKey="total_cost"
        aspectRatio={4 / 3}
        stroke="#fff"
        content={
          // @ts-expect-error error exists in ts with chart library
          <CustomizedContent
            colors={COLORS}
            onClick={handleCellClick}
            selectedCell={selectedCell}
            enableDarkTheme={enableDarkTheme}
          />
        }
      >
        <Tooltip
          content={<CustomTooltip enableDarkTheme={enableDarkTheme} />}
        />
      </Treemap>
    </ResponsiveContainer>
  );
};
