import { createSlice } from '@reduxjs/toolkit';
import type { RootState } from 'store';

export interface ExportDataState {
  format: string;
  excel: {
    data: [];
    columnHeaders: [];
    fileDetails: {
      filename: string;
      sheet: string;
      title: string;
      subTitle: string;
    };
  };
  reports: {
    fuelUsage: [];
    utilization: [];
    production: [];
    loading: {
      fuelUsageLoading: boolean;
      utilizationLoading: boolean;
      productionLoading: boolean;
    };
    score: Record<string, unknown>;
  };
}

const initialState: ExportDataState = {
  format: '',
  excel: {
    data: [],
    columnHeaders: [],
    fileDetails: {
      filename: '',
      sheet: '',
      title: '',
      subTitle: '',
    },
  },
  reports: {
    fuelUsage: [],
    utilization: [],
    production: [],
    loading: {
      fuelUsageLoading: false,
      utilizationLoading: false,
      productionLoading: false,
    },
    score: {},
  },
};

export const exportDataSlice = createSlice({
  name: 'exportData',
  initialState,
  reducers: {
    setExcel(state, { payload }) {
      state.excel.data = payload.data;
      state.excel.columnHeaders = payload.columnHeaders;
    },
    setExcelFileDetails(state, { payload }) {
      state.excel.fileDetails = payload;
    },
    clearExcel(state) {
      state.excel.data = initialState.excel.data;
      state.excel.columnHeaders = initialState.excel.columnHeaders;
    },
    setExportFormat(state, { payload }) {
      state.format = payload;
    },
    setReport(state, { payload }) {
      state.reports = {
        ...state.reports,
        ...payload,
      };
    },
    setReportLoading(state, { payload }) {
      state.reports.loading = {
        ...state.reports.loading,
        ...payload,
      };
    },
    clearReport(state) {
      state.reports = {
        fuelUsage: [],
        utilization: [],
        production: [],
        loading: {
          fuelUsageLoading: false,
          utilizationLoading: false,
          productionLoading: false,
        },
        score: {},
      };
    },
  },
});

export const {
  setExcel,
  clearExcel,
  setExcelFileDetails,
  setExportFormat,
  setReport,
  setReportLoading,
  clearReport,
} = exportDataSlice.actions;

/* Define input selectors */
export const selectExcelExportData = (state: RootState) =>
  state.exportDataReducer.excel;

export const selectExportFormat = (state: RootState) =>
  state.exportDataReducer.format;

export default exportDataSlice.reducer;
