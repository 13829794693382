import * as React from 'react';
import { Base } from 'components/Containers/BaseContainer';
import { WorkOrdersList } from './modals/WorkOrdersList';
import { useFetchAllWorkOrders } from './Helper/fetchData';
import { type WorkOrdersSquare } from 'types/squares.type';

import { theme } from 'mui.theme';
import { SVG } from 'components/Asset/SVG';
import { ToolbarContainer } from 'components/Containers/ToolbarContainer';
import { prepareCsvFile } from '../Helpers/prepareFile';
import SquaresHandler from 'handlers/squaresHandlers/squares.handler';
import { AddToReports } from '../Helpers/squareHelper';
import { useAppSelector } from 'store/hook';
import { isDarkTheme } from 'utils/theme';
const { svgIcons } = theme.palette;

export const WorkOrdersView = () => {
  const themeState = useAppSelector((state) => state.authReducer).customer
    .theme;
  const enableDarkTheme = isDarkTheme(themeState);
  const fetchAllWorkOrders = useFetchAllWorkOrders();
  const [workOrders, setWorkOrders] = React.useState<WorkOrdersSquare[]>([]);

  React.useEffect(() => {
    const fetchWorkOrders = async () => {
      const orders = await fetchAllWorkOrders();
      setWorkOrders(orders);
    };

    void fetchWorkOrders();
  }, []);

  return (
    <Base enableDarkTheme={enableDarkTheme}>
      <ToolbarContainer
        enableDarkTheme={enableDarkTheme}
        style={{
          paddingBottom: 0,
        }}
      >
        <span style={{ marginLeft: 'auto', paddingRight: '10px' }}>
          <AddToReports
            graphId={'workOrders'}
            data={workOrders}
            isPdf={false}
          />{' '}
        </span>

        {/* Email Icon */}
        <a href="mailto:sample@test.com" style={{ paddingRight: '10px' }}>
          <SVG
            name="envelopeIcon"
            style={{
              ...svgIcons?.primary,
            }}
          />
        </a>
      </ToolbarContainer>
      <WorkOrdersList workOrders={workOrders} />
    </Base>
  );
};
