import { NOTIFICATION_LEVEL } from 'utils/enums';
import { getNestedValue } from './hashmaps';
import { composeUniqueKey } from './string.manipulation';

/**
 *
 * @param data array of objects
 * @param args array of keys
 * @returns {Record<string, number>}
 *
 * @example
 * const data = [
 *  { name: 'a', age: 1 },
 *  { name: 'b', age: 2 },
 *  { name: 'c', age: 3 },
 *  { name: 'd', age: 4 },
 *  { name: 'e', age: 5 },
 *  { name: 'f', age: 6 },
 *  { name: 'f', age: 6 },
 * ];
 *
 * const args = ['name', 'age'];
 * const uniqueCount = (data, args);
 * console.log(uniqueCount);
 *
 * // OUTPUT: {
 * //   a!1: 1,
 * //   b!2: 1,
 * //   c!3: 1,
 * //   d!4: 1,
 * //   e!5: 1,
 * //   f!6: 2,
 * // }
 */
export const uniqueCount = <T extends Record<string, unknown>>(
  data: T[],
  args: string[]
): Record<string, number> => {
  // unique by key or string of keys where the value is a string
  const response = data.reduce((map: Record<string, number>, asset: T) => {
    // arr of values for args
    const argValues = args.map((eachArg) => {
      // We know that the value is a string
      return getNestedValue(asset, eachArg) as unknown as string;
    });

    /*
        join arr of values to form a composite value
        > '!' unique separator
        > ignore casing
    */
    const uniqueBy = composeUniqueKey(argValues);

    map[uniqueBy] = (map[uniqueBy] ?? 0) + 1;

    return map;
  }, {});

  return response;
};

export const uniqueCountArray = <T extends Record<string, unknown>>(
  data: T[]
): Record<string, number> => {
  // TODO: Refactor entire method
  const notificationValues = {} as any;
  const clonedData = [...data];
  for (let i = 0; i < clonedData.length; i++) {
    const notifications = Object.values(
      clonedData[i].notifications as Record<string, unknown>
    ) as any;

    if (
      notifications.find(
        (notification: any) =>
          notification.rule.notificationLevel === NOTIFICATION_LEVEL[2].id
      )
    ) {
      notificationValues['3']
        ? ++notificationValues['3']
        : (notificationValues['3'] = 1);
    } else if (
      notifications.find(
        (notification: any) =>
          notification.rule.notificationLevel === NOTIFICATION_LEVEL[1].id
      )
    ) {
      notificationValues['2']
        ? ++notificationValues['2']
        : (notificationValues['2'] = 1);
    } else if (
      notifications.find(
        (notification: any) =>
          notification.rule.notificationLevel === NOTIFICATION_LEVEL[0].id
      )
    ) {
      notificationValues['1']
        ? ++notificationValues['1']
        : (notificationValues['1'] = 1);
    } else {
      notificationValues['0']
        ? ++notificationValues['0']
        : (notificationValues['0'] = 1);
    }
  }

  return notificationValues;
};

export const arraysEqual = (a: any, b: any): boolean => {
  return (
    a.length === b.length &&
    a.every((element: any, index: any) => element === b[index])
  );
};

export const valuesBelongToSameArray = (
  array: any,
  value1: string,
  value2: string
): boolean => {
  return array.some(
    (array: any) => array.includes(value1) && array.includes(value2)
  );
};
