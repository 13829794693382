// management requests are sent to the management api

import { api } from 'api';
import axios, { type AxiosInstance } from 'axios';
import { type Role } from 'components/Permission/permission.types';
import { useAppSelector } from 'store/hook';
import { isInternalUserOrDirector } from 'utils/validators/role.validation';

export default abstract class BaseAPI {
  protected readonly axiosInstance: AxiosInstance;
  protected readonly API: any;
  protected readonly accessToken: string;
  protected readonly role: Role;
  protected readonly customerId: string;

  constructor() {
    this.accessToken = useAppSelector((state) => state.authReducer).accessToken;
    this.role = useAppSelector((state) => state.authReducer).role as Role;
    this.customerId = useAppSelector(
      (state) => state.persistedReducer
    ).customer.id;

    // configure axios instance
    this.axiosInstance = axios.create({
      baseURL: `${process.env.REACT_APP_MANAGEMENT_API_URL ?? ''}/api/`,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${this.accessToken}`,
      },
      params: {
        // Add the selected customerId as query parameter if the user is an internal user
        ...(isInternalUserOrDirector(this.role) && {
          customerId: this.customerId,
        }),
      },
    });

    this.API = api(this.axiosInstance);
  }
}
