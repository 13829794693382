import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import '../styles.css';

interface PropType {
  gridRef: any;
  columnDefs: any;
  defaultColDef: Record<any, any>;
  rowData: any[];
  autoGroupColumnDef: Record<any, any>;
}
const ReportTable = ({
  gridRef,
  columnDefs,
  defaultColDef,
  rowData,
  autoGroupColumnDef,
}: PropType) => {
  const onFirstDataRendered = () => {
    if (gridRef.current) {
      gridRef.current.api.sizeColumnsToFit();
      gridRef.current.api.setDomLayout('autoHeight');
    }
  };
  return (
    <div className="ag-theme-alpine vehicle-grid" style={{ width: '98%' }}>
      <AgGridReact
        ref={gridRef}
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
        rowData={rowData}
        groupIncludeFooter={false}
        groupIncludeTotalFooter={false}
        autoGroupColumnDef={autoGroupColumnDef}
        onGridReady={onFirstDataRendered}
      />
    </div>
  );
};

export default ReportTable;
