import {
  type TGroupedMultiSelectOptions,
  type TMultiSelectOptions,
} from 'types/utils.types';

const tagsDetails = [
  {
    tag: 'j1708ambientairtemp',
    alias: 'ambientairtemp',
    tagGroup: 'atmospheric data',
  },
  {
    tag: 'cdlambientairtemperature',
    alias: 'ambientairtemperature',
    tagGroup: 'atmospheric data',
  },
  {
    tag: 'j1939ambientairtemperature',
    alias: 'ambientairtemperature',
    tagGroup: 'atmospheric data',
  },
  {
    tag: 'kj1939ambientairtemperature',
    alias: 'ambientairtemperature',
    tagGroup: 'atmospheric data',
  },
  {
    tag: 'cdlatmosphericpressure',
    alias: 'atmosphericpressure',
    tagGroup: 'atmospheric data',
  },
  {
    tag: 'j1708barometricpressure',
    alias: 'barometricpressure',
    tagGroup: 'atmospheric data',
  },
  {
    tag: 'j1939barometricpressure',
    alias: 'barometricpressure',
    tagGroup: 'atmospheric data',
  },
  {
    tag: 'cdlbrakefilterbypassstatus',
    alias: 'brakefilterbypassstatus',
    tagGroup: 'brakes and struts',
  },
  {
    tag: 'kj1939brakeoilpressureleftfront',
    alias: 'brakeoilpressureleftfront',
    tagGroup: 'brakes and struts',
  },
  {
    tag: 'j1708brakesecondarypressure',
    alias: 'brakesecondarypressure',
    tagGroup: 'brakes and struts',
  },
  {
    tag: 'j1708brakesystemairpressurelowwarningswitchstatusemergencyreservoirtrailer',
    alias:
      'brakesystemairpressurelowwarningswitchstatusemergencyreservoirtrailer',
    tagGroup: 'brakes and struts',
  },
  {
    tag: 'j1708brakesystemairpressurelowwarningswitchstatusprimaryreservoirpoweredvehicle',
    alias:
      'brakesystemairpressurelowwarningswitchstatusprimaryreservoirpoweredvehicle',
    tagGroup: 'brakes and struts',
  },
  {
    tag: 'j1708brakesystemairpressurelowwarningswitchstatussecondaryreservoirpoweredvehicle',
    alias:
      'brakesystemairpressurelowwarningswitchstatussecondaryreservoirpoweredvehicle',
    tagGroup: 'brakes and struts',
  },
  {
    tag: 'j1708brakesystemairpressurelowwarningswitchstatusservicereservoirtrailer',
    alias:
      'brakesystemairpressurelowwarningswitchstatusservicereservoirtrailer',
    tagGroup: 'brakes and struts',
  },
  {
    tag: 'kj1939footbrakeposition',
    alias: 'footbrakeposition',
    tagGroup: 'brakes and struts',
  },
  {
    tag: 'kj1939footbrakeposition2',
    alias: 'footbrakeposition2',
    tagGroup: 'brakes and struts',
  },
  {
    tag: 'kj1939brakeoilpressurerightrear',
    alias: 'kj1939brakeoilpressurerightrear',
    tagGroup: 'brakes and struts',
  },
  {
    tag: 'cdlleftfrontstrutpressure',
    alias: 'leftfrontstrutpressure',
    tagGroup: 'brakes and struts',
  },
  {
    tag: 'kj1939leftfrontsuspensionpressure',
    alias: 'leftfrontsuspensionpressure',
    tagGroup: 'brakes and struts',
  },
  {
    tag: 'cdlleftrearbrakeoiltemperature',
    alias: 'leftrearbrakeoiltemperature',
    tagGroup: 'brakes and struts',
  },
  {
    tag: 'cdlleftrearstrutpressure',
    alias: 'leftrearstrutpressure',
    tagGroup: 'brakes and struts',
  },
  {
    tag: 'kj1939leftrearsuspensionpressure',
    alias: 'leftrearsuspensionpressure',
    tagGroup: 'brakes and struts',
  },
  {
    tag: 'cdlparkingbrake',
    alias: 'parkingbrake',
    tagGroup: 'brakes and struts',
  },
  {
    tag: 'j1939parkingbrakeswitch',
    alias: 'parkingbrakeswitch',
    tagGroup: 'brakes and struts',
  },
  {
    tag: 'j1708parkingbrakeswitchstatus',
    alias: 'parkingbrakeswitchstatus',
    tagGroup: 'brakes and struts',
  },
  {
    tag: 'cdlrightfrontbrakeoiltemperature',
    alias: 'rightfrontbrakeoiltemperature',
    tagGroup: 'brakes and struts',
  },
  {
    tag: 'cdlrightfrontstrutpressure',
    alias: 'rightfrontstrutpressure',
    tagGroup: 'brakes and struts',
  },
  {
    tag: 'kj1939rightfrontsuspensionpressure',
    alias: 'rightfrontsuspensionpressure',
    tagGroup: 'brakes and struts',
  },
  {
    tag: 'cdlrightrearbrakeoiltemperature',
    alias: 'rightrearbrakeoiltemperature',
    tagGroup: 'brakes and struts',
  },
  {
    tag: 'cdlrightrearstrutpressure',
    alias: 'rightrearstrutpressure',
    tagGroup: 'brakes and struts',
  },
  {
    tag: 'kj1939rightrearsuspensionpressure',
    alias: 'rightrearsuspensionpressure',
    tagGroup: 'brakes and struts',
  },
  {
    tag: 'j1939roadsurfacetemperature',
    alias: 'roadsurfacetemperature',
    tagGroup: 'brakes and struts',
  },
  {
    tag: 'cdlservicebrakeposition',
    alias: 'servicebrakeposition',
    tagGroup: 'brakes and struts',
  },
  {
    tag: 'cdlbodypositionangle',
    alias: 'bodypositionangle',
    tagGroup: 'chassis information',
  },
  {
    tag: 'cdlbodyupchassis',
    alias: 'bodyupchassis',
    tagGroup: 'chassis information',
  },
  {
    tag: 'kj1939liveweight',
    alias: 'liveweight',
    tagGroup: 'chassis information',
  },
  {
    tag: 'obd2vin',
    alias: 'obd2vin',
    tagGroup: 'chassis information',
  },
  {
    tag: 'cdlpayloadstatus',
    alias: 'payloadstatus',
    tagGroup: 'chassis information',
  },
  {
    tag: 'j1939pitchangle',
    alias: 'pitchangle',
    tagGroup: 'chassis information',
  },
  {
    tag: 'kj1939pitchinclinometer',
    alias: 'pitchinclinometer',
    tagGroup: 'chassis information',
  },
  {
    tag: 'j1939rollangle',
    alias: 'rollangle',
    tagGroup: 'chassis information',
  },
  {
    tag: 'cdltruckpayload',
    alias: 'truckpayload',
    tagGroup: 'chassis information',
  },
  {
    tag: 'j1939alternatorcurrent',
    alias: 'alternatorcurrent',
    tagGroup: 'electrical',
  },
  {
    tag: 'j1939batterypotentialpowerinput',
    alias: 'batterypotentialpowerinput',
    tagGroup: 'electrical',
  },
  {
    tag: 'j1708batterypotentialvoltage',
    alias: 'batterypotentialvoltage',
    tagGroup: 'electrical',
  },
  {
    tag: 'j1939chargingsystempotentialvoltage',
    alias: 'chargingsystempotentialvoltage',
    tagGroup: 'electrical',
  },
  {
    tag: 'j1939keyswitchbatterypotential',
    alias: 'keyswitchbatterypotential',
    tagGroup: 'electrical',
  },
  {
    tag: 'j1939netbatterycurrent',
    alias: 'netbatterycurrent',
    tagGroup: 'electrical',
  },
  {
    tag: 'cdlsystembatteryvoltageall',
    alias: 'systembatteryvoltageall',
    tagGroup: 'electrical',
  },
  {
    tag: 'cdlsystemvoltage',
    alias: 'systemvoltage',
    tagGroup: 'electrical',
  },
  {
    tag: 'cdlairfilterrestriction',
    alias: 'airfilterrestriction',
    tagGroup: 'engine - filters',
  },
  {
    tag: 'j1708coolantfilterdifferentialpressure',
    alias: 'coolantfilterdifferentialpressure',
    tagGroup: 'engine - filters',
  },
  {
    tag: 'j1939engineairfilter1differentialpressure',
    alias: 'engineairfilter1differentialpressure',
    tagGroup: 'engine - filters',
  },
  {
    tag: 'j1939engineairfilter2differentialpressure',
    alias: 'engineairfilter2differentialpressure',
    tagGroup: 'engine - filters',
  },
  {
    tag: 'j1939engineairfilter3differentialpressure',
    alias: 'engineairfilter3differentialpressure',
    tagGroup: 'engine - filters',
  },
  {
    tag: 'j1939engineairfilter4differentialpressure',
    alias: 'engineairfilter4differentialpressure',
    tagGroup: 'engine - filters',
  },
  {
    tag: 'j1939enginecoolantfilterdifferentialpressure',
    alias: 'enginecoolantfilterdifferentialpressure',
    tagGroup: 'engine - filters',
  },
  {
    tag: 'j1939enginefuelfilterdegradation',
    alias: 'enginefuelfilterdegradation',
    tagGroup: 'engine - filters',
  },
  {
    tag: 'j1939enginefuelfilterdifferentialpressure',
    alias: 'enginefuelfilterdifferentialpressure',
    tagGroup: 'engine - filters',
  },
  {
    tag: 'j1939engineoilfilterdifferentialpressure',
    alias: 'engineoilfilterdifferentialpressure',
    tagGroup: 'engine - filters',
  },
  {
    tag: 'j1708particulatetrapinletpressure',
    alias: 'particulatetrapinletpressure',
    tagGroup: 'engine - filters',
  },
  {
    tag: 'j1708suctionsidefuelfilterdifferentialpressure',
    alias: 'suctionsidefuelfilterdifferentialpressure',
    tagGroup: 'engine - filters',
  },
  {
    tag: 'j1939acceleratorpedal2lowidleswitch',
    alias: 'acceleratorpedal2lowidleswitch',
    tagGroup: 'engine - other',
  },
  {
    tag: 'j1939acceleratorpedalposition1',
    alias: 'acceleratorpedalposition1',
    tagGroup: 'engine - other',
  },
  {
    tag: 'j1939acceleratorpedalposition2',
    alias: 'acceleratorpedalposition2',
    tagGroup: 'engine - other',
  },
  {
    tag: 'j1939actualenginepercenttorque',
    alias: 'actualenginepercenttorque',
    tagGroup: 'engine - other',
  },
  {
    tag: 'j1939actualenginepercenttorquehighresolution',
    alias: 'actualenginepercenttorquehighresolution',
    tagGroup: 'engine - other',
  },
  {
    tag: 'actualenginetiming',
    alias: 'actualenginetiming',
    tagGroup: 'engine - other',
  },
  {
    tag: 'j1939actualmaximumavailableenginepercenttorque',
    alias: 'actualmaximumavailableenginepercenttorque',
    tagGroup: 'engine - other',
  },
  {
    tag: 'j1939actualmaximumavailableretarderpercenttorque',
    alias: 'actualmaximumavailableretarderpercenttorque',
    tagGroup: 'engine - other',
  },
  {
    tag: 'j1939actualretarderpercenttorque',
    alias: 'actualretarderpercenttorque',
    tagGroup: 'engine - other',
  },
  {
    tag: 'j1708airinlettemp',
    alias: 'airinlettemp',
    tagGroup: 'engine - other',
  },
  {
    tag: 'j1708attentionwarningindicatorlampsstatus',
    alias: 'attentionwarningindicatorlampsstatus',
    tagGroup: 'engine - other',
  },
  {
    tag: 'j1708coolantlevel',
    alias: 'coolantlevel',
    tagGroup: 'engine - other',
  },
  {
    tag: 'cdldesiredenginespeed',
    alias: 'desiredenginespeed',
    tagGroup: 'engine - other',
  },
  {
    tag: 'j1939dtcamberwarninglamp',
    alias: 'dtcamberwarninglamp',
    tagGroup: 'engine - other',
  },
  {
    tag: 'j1939dtcflashamberwarninglamp',
    alias: 'dtcflashamberwarninglamp',
    tagGroup: 'engine - other',
  },
  {
    tag: 'j1939dtcflashmalfunctionindicatorlamp',
    alias: 'dtcflashmalfunctionindicatorlamp',
    tagGroup: 'engine - other',
  },
  {
    tag: 'j1939dtcflashprotectlamp',
    alias: 'dtcflashprotectlamp',
    tagGroup: 'engine - other',
  },
  {
    tag: 'j1939dtcflashredstoplamp',
    alias: 'dtcflashredstoplamp',
    tagGroup: 'engine - other',
  },
  {
    tag: 'j1939dtcmalfunctionindicatorlamp',
    alias: 'dtcmalfunctionindicatorlamp',
    tagGroup: 'engine - other',
  },
  {
    tag: 'j1939dtcprotectlamp',
    alias: 'dtcprotectlamp',
    tagGroup: 'engine - other',
  },
  {
    tag: 'j1939dtcredstoplamp',
    alias: 'dtcredstoplamp',
    tagGroup: 'engine - other',
  },
  {
    tag: 'kj1939engine1speed',
    alias: 'engine1speed',
    tagGroup: 'engine - other',
  },
  {
    tag: 'j1939engineaftercoolercoolantlevel',
    alias: 'engineaftercoolercoolantlevel',
    tagGroup: 'engine - other',
  },
  {
    tag: 'j1939engineairintakepressure',
    alias: 'engineairintakepressure',
    tagGroup: 'engine - other',
  },
  {
    tag: 'j1939enginechargeaircooleroutletpressure',
    alias: 'enginechargeaircooleroutletpressure',
    tagGroup: 'engine - other',
  },
  {
    tag: 'j1939enginecoolantlevel',
    alias: 'enginecoolantlevel',
    tagGroup: 'engine - other',
  },
  {
    tag: 'j1939enginecoolantthermostatmode',
    alias: 'enginecoolantthermostatmode',
    tagGroup: 'engine - other',
  },
  {
    tag: 'j1939enginecoolantthermostatopening',
    alias: 'enginecoolantthermostatopening',
    tagGroup: 'engine - other',
  },
  {
    tag: 'j1939enginedemandpercenttorque',
    alias: 'enginedemandpercenttorque',
    tagGroup: 'engine - other',
  },
  {
    tag: 'j1939engineintercoolercoolantlevel',
    alias: 'engineintercoolercoolantlevel',
    tagGroup: 'engine - other',
  },
  {
    tag: 'j1939engineintercoolerthermostatopening',
    alias: 'engineintercoolerthermostatopening',
    tagGroup: 'engine - other',
  },
  {
    tag: 'j1939engineoillevel',
    alias: 'engineoillevel',
    tagGroup: 'engine - other',
  },
  {
    tag: 'cdlenginepowerderate',
    alias: 'enginepowerderate',
    tagGroup: 'engine - other',
  },
  {
    tag: 'j1939engineprotectionsystemapproachingshutdown',
    alias: 'engineprotectionsystemapproachingshutdown',
    tagGroup: 'engine - other',
  },
  {
    tag: 'j1708engineretarderpercent',
    alias: 'engineretarderpercent',
    tagGroup: 'engine - other',
  },
  {
    tag: 'cdlenginerpm',
    alias: 'enginerpm',
    tagGroup: 'engine - other',
  },
  {
    tag: 'j1939enginerpm',
    alias: 'enginerpm',
    tagGroup: 'engine - other',
  },
  {
    tag: 'j1708enginespeed',
    alias: 'enginespeed',
    tagGroup: 'engine - other',
  },
  {
    tag: 'obd2engineruntime',
    alias: 'obd2engineruntime',
    tagGroup: 'engine - other',
  },
  {
    tag: 'obd2fueltanklevelinput',
    alias: 'obd2fueltanklevelinput',
    tagGroup: 'engine - other',
  },
  {
    tag: 'obd2networkdtc',
    alias: 'obd2networkdtc',
    tagGroup: 'engine - other',
  },
  {
    tag: 'obd2powertraindtc',
    alias: 'obd2powertraindtc',
    tagGroup: 'engine - other',
  },
  {
    tag: 'obd2supportedpids01to20',
    alias: 'obd2supportedpids01to20',
    tagGroup: 'engine - other',
  },
  {
    tag: 'obd2supportedpids21to40',
    alias: 'obd2supportedpids21to40',
    tagGroup: 'engine - other',
  },
  {
    tag: 'obd2supportedpids41to60',
    alias: 'obd2supportedpids41to60',
    tagGroup: 'engine - other',
  },
  {
    tag: 'obd2supportedpids61to80',
    alias: 'obd2supportedpids61to80',
    tagGroup: 'engine - other',
  },
  {
    tag: 'obd2vehiclespeed',
    alias: 'obd2vehiclespeed',
    tagGroup: 'engine - other',
  },
  {
    tag: 'j1708outputtorque',
    alias: 'outputtorque',
    tagGroup: 'engine - other',
  },
  {
    tag: 'j1708percentaccelerator',
    alias: 'percentaccelerator',
    tagGroup: 'engine - other',
  },
  {
    tag: 'j1708percentacceleratorposition',
    alias: 'percentacceleratorposition',
    tagGroup: 'engine - other',
  },
  {
    tag: 'cdlpercentengineload',
    alias: 'percentengineload',
    tagGroup: 'engine - other',
  },
  {
    tag: 'j1708percentengineload',
    alias: 'percentengineload',
    tagGroup: 'engine - other',
  },
  {
    tag: 'j1708pidoverrangewarningcondition',
    alias: 'pidoverrangewarningcondition',
    tagGroup: 'engine - other',
  },
  {
    tag: 'kj1939retarderposition',
    alias: 'retarderposition',
    tagGroup: 'engine - other',
  },
  {
    tag: 'cdlthrottleposition',
    alias: 'throttleposition',
    tagGroup: 'engine - other',
  },
  {
    tag: 'j1708underrangewarningcondition',
    alias: 'underrangewarningcondition',
    tagGroup: 'engine - other',
  },
  {
    tag: 'j1708unknownmid=00 00 00 80,pid=00 00 00 c2',
    alias: 'unknownmid=00 00 00 80,pid=00 00 00 c2',
    tagGroup: 'engine - other',
  },
  {
    tag: 'j1708airinletpressure',
    alias: 'airinletpressure',
    tagGroup: 'engine - pressures',
  },
  {
    tag: 'j1708crankcaseblowbypressure',
    alias: 'crankcaseblowbypressure',
    tagGroup: 'engine - pressures',
  },
  {
    tag: 'kj1939engine1oilpressure',
    alias: 'engine1oilpressure',
    tagGroup: 'engine - pressures',
  },
  {
    tag: 'j1939enginecoolantpressure',
    alias: 'enginecoolantpressure',
    tagGroup: 'engine - pressures',
  },
  {
    tag: 'j1939enginecoolantpumpdifferentialpressure',
    alias: 'enginecoolantpumpdifferentialpressure',
    tagGroup: 'engine - pressures',
  },
  {
    tag: 'j1939enginecrankcasepressure',
    alias: 'enginecrankcasepressure',
    tagGroup: 'engine - pressures',
  },
  {
    tag: 'j1939enginedpfintakepressure',
    alias: 'enginedpfintakepressure',
    tagGroup: 'engine - pressures',
  },
  {
    tag: 'j1939engineextendedcrankcaseblowbypressure',
    alias: 'engineextendedcrankcaseblowbypressure',
    tagGroup: 'engine - pressures',
  },
  {
    tag: 'j1939engineintakemanifold1absolutepressure',
    alias: 'engineintakemanifold1absolutepressure',
    tagGroup: 'engine - pressures',
  },
  {
    tag: 'j1939engineintakemanifold1pressure',
    alias: 'engineintakemanifold1pressure',
    tagGroup: 'engine - pressures',
  },
  {
    tag: 'j1939engineoillevelremotereservoir',
    alias: 'engineoillevelremotereservoir',
    tagGroup: 'engine - pressures',
  },
  {
    tag: 'j1708engineoilpressure',
    alias: 'engineoilpressure',
    tagGroup: 'engine - pressures',
  },
  {
    tag: 'j1939engineoilpressure',
    alias: 'engineoilpressure',
    tagGroup: 'engine - pressures',
  },
  {
    tag: 'cdlengineoilpressureabs',
    alias: 'engineoilpressureabs',
    tagGroup: 'engine - pressures',
  },
  {
    tag: 'cdlintakemanifold1airpressure',
    alias: 'intakemanifold1airpressure',
    tagGroup: 'engine - pressures',
  },
  {
    tag: 'cdlintakemanifold2airpressure',
    alias: 'intakemanifold2airpressure',
    tagGroup: 'engine - pressures',
  },
  {
    tag: 'cdlintakemanifoldairtemperature',
    alias: 'intakemanifoldairtemperature',
    tagGroup: 'engine - pressures',
  },
  {
    tag: 'kj1939engine1coolanttemperature',
    alias: 'engine1coolanttemperature',
    tagGroup: 'engine - temps',
  },
  {
    tag: 'kj1939engine1oiltemperature1',
    alias: 'engine1oiltemperature1',
    tagGroup: 'engine - temps',
  },
  {
    tag: 'j1939enginecoolantpumpoutlettemperature',
    alias: 'enginecoolantpumpoutlettemperature',
    tagGroup: 'engine - temps',
  },
  {
    tag: 'cdlenginecoolanttemp',
    alias: 'enginecoolanttemp',
    tagGroup: 'engine - temps',
  },
  {
    tag: 'j1708enginecoolanttemp',
    alias: 'enginecoolanttemp',
    tagGroup: 'engine - temps',
  },
  {
    tag: 'j1939enginecoolanttemperature',
    alias: 'enginecoolanttemperature',
    tagGroup: 'engine - temps',
  },
  {
    tag: 'j1939engineexhaustgastemperature',
    alias: 'engineexhaustgastemperature',
    tagGroup: 'engine - temps',
  },
  {
    tag: 'j1939enginefueltemperature1',
    alias: 'enginefueltemperature1',
    tagGroup: 'engine - temps',
  },
  {
    tag: 'j1939enginefueltemperature2',
    alias: 'enginefueltemperature2',
    tagGroup: 'engine - temps',
  },
  {
    tag: 'j1939engineintakemanifold1temperature',
    alias: 'engineintakemanifold1temperature',
    tagGroup: 'engine - temps',
  },
  {
    tag: 'j1708engineintercoolertemperature',
    alias: 'engineintercoolertemperature',
    tagGroup: 'engine - temps',
  },
  {
    tag: 'j1939engineintercoolertemperature',
    alias: 'engineintercoolertemperature',
    tagGroup: 'engine - temps',
  },
  {
    tag: 'j1708engineoiltemp',
    alias: 'engineoiltemp',
    tagGroup: 'engine - temps',
  },
  {
    tag: 'cdlengineoiltemperature',
    alias: 'engineoiltemperature',
    tagGroup: 'engine - temps',
  },
  {
    tag: 'cdlengineoiltemperature1',
    alias: 'engineoiltemperature1',
    tagGroup: 'engine - temps',
  },
  {
    tag: 'j1939engineoiltemperature1',
    alias: 'engineoiltemperature1',
    tagGroup: 'engine - temps',
  },
  {
    tag: 'j1708exhaustgastemp',
    alias: 'exhaustgastemp',
    tagGroup: 'engine - temps',
  },
  {
    tag: 'j1708intakemanifoldtemp',
    alias: 'intakemanifoldtemp',
    tagGroup: 'engine - temps',
  },
  {
    tag: 'cdlleftbankturbineinlettemperature',
    alias: 'leftbankturbineinlettemperature',
    tagGroup: 'engine - temps',
  },
  {
    tag: 'cdlleftbankturbineoutlettemperature',
    alias: 'leftbankturbineoutlettemperature',
    tagGroup: 'engine - temps',
  },
  {
    tag: 'j1939aftertreatment1fuelpressure1',
    alias: 'aftertreatment1fuelpressure1',
    tagGroup: 'fuel signals',
  },
  {
    tag: 'j1939aftertreatment1fuelpressure2',
    alias: 'aftertreatment1fuelpressure2',
    tagGroup: 'fuel signals',
  },
  {
    tag: 'j1939aftertreatment1fuelrate',
    alias: 'aftertreatment1fuelrate',
    tagGroup: 'fuel signals',
  },
  {
    tag: 'j1939aftertreatment1hydrocarbondoserintakefueltemperature',
    alias: 'aftertreatment1hydrocarbondoserintakefueltemperature',
    tagGroup: 'fuel signals',
  },
  {
    tag: 'j1708averagefuelrate',
    alias: 'averagefuelrate',
    tagGroup: 'fuel signals',
  },
  {
    tag: 'kj1939engine1fuelinjection',
    alias: 'engine1fuelinjection',
    tagGroup: 'fuel signals',
  },
  {
    tag: 'kj1939engine1fuelrate',
    alias: 'engine1fuelrate',
    tagGroup: 'fuel signals',
  },
  {
    tag: 'j1939enginefuelactuator1controlcommand',
    alias: 'enginefuelactuator1controlcommand',
    tagGroup: 'fuel signals',
  },
  {
    tag: 'j1939enginefueldeliverypressure',
    alias: 'enginefueldeliverypressure',
    tagGroup: 'fuel signals',
  },
  {
    tag: 'cdlenginefuellevelpercent',
    alias: 'enginefuellevelpercent',
    tagGroup: 'fuel signals',
  },
  {
    tag: 'j1939enginefuelrate',
    alias: 'enginefuelrate',
    tagGroup: 'fuel signals',
  },
  {
    tag: 'j1939engineinjectormeteringrail1pressure',
    alias: 'engineinjectormeteringrail1pressure',
    tagGroup: 'fuel signals',
  },
  {
    tag: 'j1939engineinstantaneousfueleconomy',
    alias: 'engineinstantaneousfueleconomy',
    tagGroup: 'fuel signals',
  },
  {
    tag: 'j1939enginetripfuel',
    alias: 'enginetripfuel',
    tagGroup: 'fuel signals',
  },
  {
    tag: 'cdlfilteredenginefuelpressureabs',
    alias: 'filteredenginefuelpressureabs',
    tagGroup: 'fuel signals',
  },
  {
    tag: 'cdlfuelconsumptionratelph',
    alias: 'fuelconsumptionratelph',
    tagGroup: 'fuel signals',
  },
  {
    tag: 'j1708fueldeliverypressure',
    alias: 'fueldeliverypressure',
    tagGroup: 'fuel signals',
  },
  {
    tag: 'cdlfuelfilterstatus',
    alias: 'fuelfilterstatus',
    tagGroup: 'fuel signals',
  },
  {
    tag: 'j1708fuellevel',
    alias: 'fuellevel',
    tagGroup: 'fuel signals',
  },
  {
    tag: 'j1939fuellevel1',
    alias: 'fuellevel1',
    tagGroup: 'fuel signals',
  },
  {
    tag: 'j1939fuellevel2',
    alias: 'fuellevel2',
    tagGroup: 'fuel signals',
  },
  {
    tag: 'kj1939fuellevelhighresolution',
    alias: 'fuellevelhighresolution',
    tagGroup: 'fuel signals',
  },
  {
    tag: 'cdlfuelrailpressuregauge',
    alias: 'fuelrailpressuregauge',
    tagGroup: 'fuel signals',
  },
  {
    tag: 'j1708fuelrateinstantaneous',
    alias: 'fuelrateinstantaneous',
    tagGroup: 'fuel signals',
  },
  {
    tag: 'j1708fueltemp',
    alias: 'fueltemp',
    tagGroup: 'fuel signals',
  },
  {
    tag: 'cdlfueltemperature',
    alias: 'fueltemperature',
    tagGroup: 'fuel signals',
  },
  {
    tag: 'cdlfueltransferpumpintakepressure',
    alias: 'fueltransferpumpintakepressure',
    tagGroup: 'fuel signals',
  },
  {
    tag: 'j1708powerspecificfueleconomy',
    alias: 'powerspecificfueleconomy',
    tagGroup: 'fuel signals',
  },
  {
    tag: 'j1708secondfuellevel',
    alias: 'secondfuellevel',
    tagGroup: 'fuel signals',
  },
  {
    tag: 'j1708tripfuel',
    alias: 'tripfuel',
    tagGroup: 'fuel signals',
  },
  {
    tag: 'j1708waterinfuelindicator',
    alias: 'waterinfuelindicator',
    tagGroup: 'fuel signals',
  },
  {
    tag: 'j1939waterinfuelindicator',
    alias: 'waterinfuelindicator',
    tagGroup: 'fuel signals',
  },
  {
    tag: 'j1939hydraulicoillevel',
    alias: 'hydraulicoillevel',
    tagGroup: 'hydraulic system',
  },
  {
    tag: 'cdlhydraulicoiltemp',
    alias: 'hydraulicoiltemp',
    tagGroup: 'hydraulic system',
  },
  {
    tag: 'j1939hydraulicpressure',
    alias: 'hydraulicpressure',
    tagGroup: 'hydraulic system',
  },
  {
    tag: 'j1708airconditionersystemstatus',
    alias: 'airconditionersystemstatus',
    tagGroup: 'other',
  },
  {
    tag: 'carbonmonoxideppm',
    alias: 'carbonmonoxideppm',
    tagGroup: 'other',
  },
  {
    tag: 'j1708generatoroilpressure',
    alias: 'generatoroilpressure',
    tagGroup: 'other',
  },
  {
    tag: 'j1708transmittersystemdiagnostic',
    alias: 'transmittersystemdiagnostic',
    tagGroup: 'other',
  },
  {
    tag: 'j1939washerfluidlevel',
    alias: 'washerfluidlevel',
    tagGroup: 'other',
  },
  {
    tag: 'j1708steerchanneltirepressure',
    alias: 'steerchanneltirepressure',
    tagGroup: 'steering',
  },
  {
    tag: 'j1708steeringaxletemperature',
    alias: 'steeringaxletemperature',
    tagGroup: 'steering',
  },
  {
    tag: 'cdlsteeringfluidtemperature',
    alias: 'steeringfluidtemperature',
    tagGroup: 'steering',
  },
  {
    tag: 'cdlsteeringpumpoilpressure',
    alias: 'steeringpumpoilpressure',
    tagGroup: 'steering',
  },
  {
    tag: 'connection_updated',
    alias: 'connection_updated',
    tagGroup: 'symbot data',
  },
  {
    tag: 'creation_date',
    alias: 'creation_date',
    tagGroup: 'symbot data',
  },
  {
    tag: 'edgeodometer',
    alias: 'edgeodometer',
    tagGroup: 'symbot data',
  },
  {
    tag: 'first_event',
    alias: 'first_event',
    tagGroup: 'symbot data',
  },
  {
    tag: 'gps altitude',
    alias: 'gps altitude',
    tagGroup: 'symbot data',
  },
  {
    tag: 'gps angle',
    alias: 'gps angle',
    tagGroup: 'symbot data',
  },
  {
    tag: 'gps fix quality',
    alias: 'gps fix quality',
    tagGroup: 'symbot data',
  },
  {
    tag: 'gps latitude',
    alias: 'gps latitude',
    tagGroup: 'symbot data',
  },
  {
    tag: 'gps longitude',
    alias: 'gps longitude',
    tagGroup: 'symbot data',
  },
  {
    tag: 'gps velocity',
    alias: 'gps velocity',
    tagGroup: 'symbot data',
  },
  {
    tag: 'gpsaltitude',
    alias: 'gpsaltitude',
    tagGroup: 'symbot data',
  },
  {
    tag: 'gpsangle',
    alias: 'gpsangle',
    tagGroup: 'symbot data',
  },
  {
    tag: 'gpsfixquality',
    alias: 'gpsfixquality',
    tagGroup: 'symbot data',
  },
  {
    tag: 'gpslatitude',
    alias: 'gpslatitude',
    tagGroup: 'symbot data',
  },
  {
    tag: 'gpslongitude',
    alias: 'gpslongitude',
    tagGroup: 'symbot data',
  },
  {
    tag: 'gpsvelocity',
    alias: 'gpsvelocity',
    tagGroup: 'symbot data',
  },
  {
    tag: 'cdlgroundspeed',
    alias: 'groundspeed',
    tagGroup: 'symbot data',
  },
  {
    tag: 'sb%memoryused',
    alias: 'sb%memoryused',
    tagGroup: 'symbot data',
  },
  {
    tag: 'sbaccelerationx',
    alias: 'sbaccelerationx',
    tagGroup: 'symbot data',
  },
  {
    tag: 'sbaccelerationy',
    alias: 'sbaccelerationy',
    tagGroup: 'symbot data',
  },
  {
    tag: 'sbaccelerationz',
    alias: 'sbaccelerationz',
    tagGroup: 'symbot data',
  },
  {
    tag: 'sbavailmemory',
    alias: 'sbavailmemory',
    tagGroup: 'symbot data',
  },
  {
    tag: 'sbbatteryvoltage',
    alias: 'sbbatteryvoltage',
    tagGroup: 'symbot data',
  },
  {
    tag: 'sbignitionstatus',
    alias: 'sbignitionstatus',
    tagGroup: 'symbot data',
  },
  {
    tag: 'sbltesignalstrength',
    alias: 'sbltesignalstrength',
    tagGroup: 'symbot data',
  },
  {
    tag: 'sbmemoryused',
    alias: 'sbmemoryused',
    tagGroup: 'symbot data',
  },
  {
    tag: 'sbnumberofpointstransmitted',
    alias: 'sbnumberofpointstransmitted',
    tagGroup: 'symbot data',
  },
  {
    tag: 'sbtemperature',
    alias: 'sbtemperature',
    tagGroup: 'symbot data',
  },
  {
    tag: 'sbtransmissionconnect',
    alias: 'sbtransmissionconnect',
    tagGroup: 'symbot data',
  },
  {
    tag: 'sbtransmissiondisconnect',
    alias: 'sbtransmissiondisconnect',
    tagGroup: 'symbot data',
  },
  {
    tag: 'symruntime',
    alias: 'symruntime',
    tagGroup: 'symbot data',
  },
  {
    tag: 'symstart',
    alias: 'symstart',
    tagGroup: 'symbot data',
  },
  {
    tag: 'symstop',
    alias: 'symstop',
    tagGroup: 'symbot data',
  },
  {
    tag: 'j1939enginetotalfuelused',
    alias: 'enginetotalfuelused',
    tagGroup: 'totals',
  },
  {
    tag: 'j1939enginetotalhoursofoperation',
    alias: 'enginetotalhoursofoperation',
    tagGroup: 'totals',
  },
  {
    tag: 'cdlgeneratorsethourmeter',
    alias: 'generatorsethourmeter',
    tagGroup: 'totals',
  },
  {
    tag: 'awstotalenginehours',
    alias: 'totalenginehours',
    tagGroup: 'totals',
  },
  {
    tag: 'cdltotalengineidletime',
    alias: 'totalengineidletime',
    tagGroup: 'totals',
  },
  {
    tag: 'cdltotalfuelconsumed',
    alias: 'totalfuelconsumed',
    tagGroup: 'totals',
  },
  {
    tag: 'cdltotalloadcycles',
    alias: 'totalloadcycles',
    tagGroup: 'totals',
  },
  {
    tag: 'cdltotalloadeddistance',
    alias: 'totalloadeddistance',
    tagGroup: 'totals',
  },
  {
    tag: 'cdltotaloperatinghoursall',
    alias: 'totaloperatinghoursall',
    tagGroup: 'totals',
  },
  {
    tag: 'cdltotaloperatinghoursengine',
    alias: 'totaloperatinghoursengine',
    tagGroup: 'totals',
  },
  {
    tag: 'j1708totalptohours',
    alias: 'totalptohours',
    tagGroup: 'totals',
  },
  {
    tag: 'kj1939totalvehiclehours',
    alias: 'totalvehiclehours',
    tagGroup: 'totals',
  },
  {
    tag: 'j1708axlesliderstatusaxlesliderlockstatus',
    alias: 'axlesliderstatusaxlesliderlockstatus',
    tagGroup: 'trans and final drive',
  },
  {
    tag: 'j1708axlesliderstatusaxlesliderlockswitchstatus',
    alias: 'axlesliderstatusaxlesliderlockswitchstatus',
    tagGroup: 'trans and final drive',
  },
  {
    tag: 'j1708clutchpressure',
    alias: 'clutchpressure',
    tagGroup: 'trans and final drive',
  },
  {
    tag: 'cdlcurrentgear',
    alias: 'currentgear',
    tagGroup: 'trans and final drive',
  },
  {
    tag: 'j1939frontaxleleftwheelspeed',
    alias: 'frontaxleleftwheelspeed',
    tagGroup: 'trans and final drive',
  },
  {
    tag: 'j1939frontaxlerightwheelspeed',
    alias: 'frontaxlerightwheelspeed',
    tagGroup: 'trans and final drive',
  },
  {
    tag: 'kj1939highresolutiontotalvehicledistance',
    alias: 'highresolutiontotalvehicledistance',
    tagGroup: 'trans and final drive',
  },
  {
    tag: 'j1939navigationbasedvehiclespeed',
    alias: 'navigationbasedvehiclespeed',
    tagGroup: 'trans and final drive',
  },
  {
    tag: 'j1939percentclutchslip',
    alias: 'percentclutchslip',
    tagGroup: 'trans and final drive',
  },
  {
    tag: 'j1708powertakeoffsetspeed',
    alias: 'powertakeoffsetspeed',
    tagGroup: 'trans and final drive',
  },
  {
    tag: 'j1708powertakeoffstatus',
    alias: 'powertakeoffstatus',
    tagGroup: 'trans and final drive',
  },
  {
    tag: 'j1939rearaxleleftwheelspeed',
    alias: 'rearaxleleftwheelspeed',
    tagGroup: 'trans and final drive',
  },
  {
    tag: 'j1939rearaxlerightwheelspeed',
    alias: 'rearaxlerightwheelspeed',
    tagGroup: 'trans and final drive',
  },
  {
    tag: 'cdlrightfinaldriveoiltemperature',
    alias: 'rightfinaldriveoiltemperature',
    tagGroup: 'trans and final drive',
  },
  {
    tag: 'cdlselectedgear2',
    alias: 'selectedgear2',
    tagGroup: 'trans and final drive',
  },
  {
    tag: 'kj1939torqueconverterlockupengaged',
    alias: 'torqueconverterlockupengaged',
    tagGroup: 'trans and final drive',
  },
  {
    tag: 'j1708torquelimitingfactor',
    alias: 'torquelimitingfactor',
    tagGroup: 'trans and final drive',
  },
  {
    tag: 'j1939transmissioncurrentgear',
    alias: 'transmissioncurrentgear',
    tagGroup: 'trans and final drive',
  },
  {
    tag: 'kj1939transmissioncurrentrange',
    alias: 'transmissioncurrentrange',
    tagGroup: 'trans and final drive',
  },
  {
    tag: 'cdltransmissionoilpressureabs',
    alias: 'transmissionoilpressureabs',
    tagGroup: 'trans and final drive',
  },
  {
    tag: 'cdltransmissionoiltemperature',
    alias: 'transmissionoiltemperature',
    tagGroup: 'trans and final drive',
  },
  {
    tag: 'kj1939transmissionoiltemperature1',
    alias: 'transmissionoiltemperature1',
    tagGroup: 'trans and final drive',
  },
  {
    tag: 'cdltransmissionoutputspeed',
    alias: 'transmissionoutputspeed',
    tagGroup: 'trans and final drive',
  },
  {
    tag: 'j1708transmissiontankairpressure',
    alias: 'transmissiontankairpressure',
    tagGroup: 'trans and final drive',
  },
  {
    tag: 'j1939wheelbasedvehiclespeed',
    alias: 'wheelbasedvehiclespeed',
    tagGroup: 'trans and final drive',
  },
  {
    tag: 'kj1939wheelbasedvehiclespeed',
    alias: 'wheelbasedvehiclespeed',
    tagGroup: 'trans and final drive',
  },
  {
    tag: 'j1708blowerbypassvalveposition',
    alias: 'blowerbypassvalveposition',
    tagGroup: 'turbo information',
  },
  {
    tag: 'j1708boostpressure',
    alias: 'boostpressure',
    tagGroup: 'turbo information',
  },
  {
    tag: 'cdlboostpressuregauge',
    alias: 'boostpressuregauge',
    tagGroup: 'turbo information',
  },
  {
    tag: 'kj1939engine1acceleratorpedalposition1',
    alias: 'engine1acceleratorpedalposition1',
    tagGroup: 'turbo information',
  },
  {
    tag: 'kj1939engine1turbochargerboostpressure',
    alias: 'engine1turbochargerboostpressure',
    tagGroup: 'turbo information',
  },
  {
    tag: 'j1939engineturbocharger1turbineintaketemperature',
    alias: 'engineturbocharger1turbineintaketemperature',
    tagGroup: 'turbo information',
  },
  {
    tag: 'j1939engineturbocharger2turbineintaketemperature',
    alias: 'engineturbocharger2turbineintaketemperature',
    tagGroup: 'turbo information',
  },
  {
    tag: 'j1939engineturbochargeroiltemperature',
    alias: 'engineturbochargeroiltemperature',
    tagGroup: 'turbo information',
  },
  {
    tag: 'cdlleftfinaldriveoiltemperature',
    alias: 'leftfinaldriveoiltemperature',
    tagGroup: 'turbo information',
  },
  {
    tag: 'cdlleftfrontbrakeoiltemperature',
    alias: 'leftfrontbrakeoiltemperature',
    tagGroup: 'turbo information',
  },
  {
    tag: 'cdlleftturboinletpressureabs',
    alias: 'leftturboinletpressureabs',
    tagGroup: 'turbo information',
  },
  {
    tag: 'cdlrightbankturbineinlettemperature',
    alias: 'rightbankturbineinlettemperature',
    tagGroup: 'turbo information',
  },
  {
    tag: 'cdlrightbankturbineoutlettemperature',
    alias: 'rightbankturbineoutlettemperature',
    tagGroup: 'turbo information',
  },
  {
    tag: 'cdlrightturboinletpressureabs',
    alias: 'rightturboinletpressureabs',
    tagGroup: 'turbo information',
  },
  {
    tag: 'cdlturbocharger1compressorinletpressure',
    alias: 'turbocharger1compressorinletpressure',
    tagGroup: 'turbo information',
  },
  {
    tag: 'cdlturbocharger2compressorinletpressure',
    alias: 'turbocharger2compressorinletpressure',
    tagGroup: 'turbo information',
  },
  {
    tag: 'cdlturbocharger3compressorinletpressure',
    alias: 'turbocharger3compressorinletpressure',
    tagGroup: 'turbo information',
  },
  {
    tag: 'cdlturbocharger4compressorinletpressure',
    alias: 'turbocharger4compressorinletpressure',
    tagGroup: 'turbo information',
  },
  {
    tag: 'cdlturbooutletpressureabs',
    alias: 'turbooutletpressureabs',
    tagGroup: 'turbo information',
  },
  {
    tag: 'j1708turbospeed',
    alias: 'turbospeed',
    tagGroup: 'turbo information',
  },
];

interface Props {
  selectorOptions: TGroupedMultiSelectOptions;
}

export const groupedData = ({
  selectorOptions,
}: Props): Array<{
  groupName: string;
  options: TGroupedMultiSelectOptions;
}> => {
  const grouped: Array<{
    groupName: string;
    options: TGroupedMultiSelectOptions;
  }> = [];
  selectorOptions.map(
    (option: { id: string; display: string; tagName: string }) => {
      const tagName = (option.tagName || option.id).split('_').join('');
      const findTag = tagsDetails.find((tagDetail) => {
        return tagDetail.tag === tagName;
      }) ?? {
        tag: '',
        alias: '',
        tagGroup: 'other',
      };

      const groupedData = grouped.find((group) => {
        return group.groupName === findTag.tagGroup;
      });

      if (groupedData) {
        groupedData.options.push(option);
      } else {
        grouped.push({
          groupName: findTag.tagGroup,
          options: [{ ...option }],
        });
      }
      return null;
    }
  );
  grouped?.sort((a, b) => a.groupName.localeCompare(b.groupName));

  return grouped;
};
