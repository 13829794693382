import { todaysDate } from 'utils/helpers/dateTime';
import { useAppDispatch } from 'store/hook';
import { setExcelFileDetails, setExportFormat } from 'store/exportData.slice';
import { useEffect } from 'react';

export const useCustomDropdownEffect = (
  exportTo: string,
  handleExport: (val: boolean) => void,
  setExportToLabel: (val: string) => void,
  setExportTo: (val: string) => void,
  dependencies: string[],
  bookDetails?: string[]
) => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    const filename =
      bookDetails && bookDetails?.length > 0
        ? bookDetails[0]
        : 'Fleet - Summary';

    const sheet =
      bookDetails && bookDetails?.length > 1 ? bookDetails[1] : 'Page 1';
    const title =
      bookDetails && bookDetails?.length > 2
        ? bookDetails[2]
        : 'Fleet Summary - ';
    if (exportTo === 'excel') {
      handleExport(true);
      dispatch(setExportFormat('excel'));
      dispatch(
        setExcelFileDetails({
          filename,
          sheet,
          title,
          subTitle: todaysDate(),
        })
      );
      setExportToLabel('Exporting to');
      setTimeout(() => {
        setExportToLabel('Export');
        setExportTo('');
      }, 6000);
    }
    if (exportTo === 'pdf') {
      handleExport(true);
      dispatch(setExportFormat('pdf'));
      dispatch(
        setExcelFileDetails({
          filename,
          sheet,
          title,
          subTitle: todaysDate(),
        })
      );
      setExportToLabel('Exporting to');
      setTimeout(() => {
        setExportToLabel('Export');
        setExportTo('');
      }, 6000);
    }
  }, dependencies);
};
