import { Typography, List, ListItem, Box, Paper } from '@mui/material';
import type { Asset } from 'types/asset.types';
import { SVG } from 'components/Asset/SVG';
import { TextButton } from 'components/Buttons/TextButton';

import { mapVehiclesAssets } from 'utils/helpers/mapAssetImage';
import { ThemePalette } from 'mui.theme';

interface Props {
  assets: Asset[];
  selectedAssetIds: string[];
  enableDarkTheme: boolean;
  handleCheckBoxSelected: any;
}

const FooterDisplay = ({
  assets,
  selectedAssetIds,
  enableDarkTheme,
  handleCheckBoxSelected,
}: Props) => {
  const selectedAssets = assets.filter((asset: Asset) =>
    selectedAssetIds.includes(asset.assetId)
  );
  return (
    <Box
      sx={{
        backgroundColor: enableDarkTheme
          ? ThemePalette.dark.pageBackground
          : ThemePalette.light.pageBackground,
        color: enableDarkTheme
          ? ThemePalette.typography.white
          : ThemePalette.typography.black,
        paddingBottom: '0',
      }}
    >
      <List
        style={{
          display: 'flex',
          flexDirection: 'row',
        }}
      >
        <ListItem>Selected Assets:</ListItem>
        {selectedAssets.map((asset) => {
          return (
            <ListItem key={asset.assetId}>
              <Paper
                elevation={3}
                sx={{
                  color: enableDarkTheme
                    ? ThemePalette.typography.white
                    : ThemePalette.typography.black,
                  backgroundColor: 'inherit',
                  height: '60px',
                  width: '180px',
                  border: `1px solid ${ThemePalette.border}`,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <TextButton
                  sx={{
                    minHeight: '19px',
                    minWidth: '19px',
                  }}
                  onClick={() => {
                    handleCheckBoxSelected(asset.assetId);
                  }}
                >
                  <Typography>X</Typography>
                </TextButton>

                <SVG
                  name={mapVehiclesAssets(asset.assetType)}
                  style={{
                    fill: '#E2C171',
                    paddingLeft: '20px',
                    width: '55px',
                  }}
                />
                <Typography
                  sx={{
                    paddingLeft: '20px',
                  }}
                >
                  {asset.bumperNumber}
                </Typography>
              </Paper>
            </ListItem>
          );
        })}
      </List>
    </Box>
  );
};

export default FooterDisplay;
